import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { oneclick } from '../../oneclick';
import { format, formatDistanceToNow } from 'date-fns';
import database_id from '../../utils/databaseConfig';

const UsersList = ({ onFilter, onAddUserClick, formData, handleChange, handleReset }) => {
    const prevFormDataRef = useRef(formData);

    useEffect(() => {
        if (JSON.stringify(formData) !== JSON.stringify(prevFormDataRef.current)) {
            onFilter(formData);
            prevFormDataRef.current = formData; // Update the ref with the new formData
        }
    }, [formData, onFilter]);

    return (
        <div className="card mb-4">
            <div className="card-header">
                <h4 className="card-title">Users List</h4>
            </div>

            <div className="card-filter">
                <form>
                    <div className="filter-form" style={{ display: 'flex' }}>
                        <div className="filter-item">
                            <select
                                name="status"
                                className="form-select"
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value="">All</option>
                                <option value="1">Active</option>
                                <option value="2">Banned</option>
                            </select>
                        </div>

                        <div className="filter-item">
                            <input
                                type="text"
                                autoComplete="off"
                                name="search"
                                placeholder="Search with User, Email"
                                className="form-control"
                                value={formData.search}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="filter-item">
                            <input
                                type="date"
                                className="form-control"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                autoComplete="off"
                            />
                        </div>
                        <div className="filter-item" style={{ display: 'flex', gap: '10px', 'flex-direction': 'row-reverse'}}>
                            <button className="i-btn info--btn btn--md" type="submit">
                                Search
                            </button>
                            <button type="button" className="i-btn danger--btn btn--md" onClick={handleReset}>
                                <span className="text-white">
                                    <i className="las la-sync"></i> Reset
                                </span>
                            </button>
                            <button className="i-btn primary--btn btn--md" type="button" onClick={onAddUserClick}>
                                Add New
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const UserTable = ({ users, onAddCredit, currentPage, totalPages, handlePageChange }) => {
    return (
        <div className="card-body px-0">
            <div className="responsive-table">
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Customer</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Add / Returned Credit</th>
                            <th>Joined At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td data-label="Customer">{user.id}</td>
                                <td data-label="Customer">{user.name}</td>
                                <td data-label="Email">{user.email}</td>
                                <td>
                                    <span className={`badge ${user.status == '1' ? 'badge--success' : 'badge--danger'}`}>
                                        {user.status == '1' ? 'Active' : 'Inactive'} {/* Show 'Active' if status is '1', otherwise 'Inactive' */}
                                    </span>
                                </td>
                                <td data-label="Add / Returned Credit">
                                    <button
                                        type="button"
                                        className="badge btn bg--success text-white createdupdate"
                                        onClick={() => onAddCredit(user.id)}
                                    >
                                        Add / Returned
                                    </button>
                                </td>
                                <td data-label="Joined At">
                                    {formatDistanceToNow(new Date(user.created_at), { addSuffix: true })}<br />
                                    {format(new Date(user.created_at), 'MMMM dd, yyyy, hh:mm a')}
                                </td>
                                <td data-label="Action">
                                    <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`http://localhost/getoneclick/admin/users/login/${user.id}`}
                                            className="i-btn btn--sm success--btn"
                                        >
                                            <i className="las la-sign-in-alt"></i>
                                        </a>
                                        <Link
                                            to={`/users/detail?id=${user.id}`}
                                            className="i-btn btn--sm primary--btn brand"
                                            title="Details"
                                        >
                                            <i className="las la-desktop"></i>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                {Array.from({ length: totalPages }, (_, index) => (
                    <Button
                        key={index + 1}
                        variant="outline-primary"
                        onClick={() => handlePageChange(index + 1)}
                        style={{ margin: '0 5px' }}
                        active={currentPage === index + 1}
                    >
                        {index + 1}
                    </Button>
                ))}
            </div>
        </div>
    );
};

const UserManagement = () => {
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        status: 'Active',
    });
    const [showAmount, setShowAmount] = useState(false);
    const [conditions, setConditions] = useState({});
    const [serch, setSerch] = useState({});

    const loginToken = localStorage.getItem('token');
    const tableSchema = database_id;

    useEffect(() => {
        const tableName = "users";
        const orderBy = "created_at DESC";

        const fetchData1 = (data) => {
            if (data) {
                setAllUsers(data.data);
                setShowAmount(true);
            } else {
                console.log("No user data found.");
            }
        };

        if (loginToken) {
            const conditionsData = {
                ...conditions
            };
            
            const searchData = { ...serch }; // Assuming serch already has the search fields populated
            console.log('data sending ravi :', tableSchema, tableName, conditions, orderBy, serch);
            oneclick.startLongPolling(tableSchema, tableName, conditionsData, orderBy, searchData, fetchData1, "");
        } else {
            console.warn('No login token found. User is not logged in.');
        }

        return () => {
            oneclick.stopLongPolling();
        };
    }, [loginToken, tableSchema, conditions, serch]);

    const [formData, setFormData] = useState({
        status: 'all',
        search: '',
        date: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10;

    useEffect(() => {
        setFilteredUsers(allUsers);
    }, [allUsers]);

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handleFilter = (filterData) => {
        let filtered = allUsers;

        if (filterData.status !== 'all') {
            filtered = filtered.filter(user => user.status === filterData.status);
            if(filterData.status == ""){

            }else if(filterData.status == "1"){
                const query = { "status": filterData.status }; // Initialize query with login token
                setConditions(query);
            }else if(filterData.status == "2"){
                const query = { "status": filterData.status }; // Initialize query with login token
                setConditions(query);
            }
            
        }

        if (filterData.search) {
            // filtered = filtered.filter(user =>
            //     user.name.toLowerCase().includes(filterData.search.toLowerCase()) ||
            //     user.email.toLowerCase().includes(filterData.search.toLowerCase())
            // );
            const query1 = { "name": filterData.search, "email": filterData.search,}; // Initialize query with login token
            setSerch(query1);
        }

        setFilteredUsers(filtered);
        setCurrentPage(1);
    };

    const handleReset = () => {
        setFormData({
            status: 'all',
            search: '',
            date: '',
        });
        setFilteredUsers(allUsers);
        setCurrentPage(1);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAddCredit = (userId) => {
        console.log(`Adding credit for user with ID: ${userId}`);
    };

    const handleAddUser = () => {
        if (newUser.password !== newUser.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        const newUserObject = {
            ...newUser,
            id: allUsers.length + 1,
            joinedAt: new Date().toLocaleString(),
            joinedAgo: 'Just now',
        };

        setAllUsers([...allUsers, newUserObject]);
        setFilteredUsers([...filteredUsers, newUserObject]);
        setShowModal(false);
        setNewUser({ name: '', email: '', password: '', confirmPassword: '', status: 'Active' });
    };

    const paginatedUsers = filteredUsers.slice(
        (currentPage - 1) * usersPerPage,
        currentPage * usersPerPage
    );

    return (
        <div>
            <UsersList
                onFilter={handleFilter}
                onAddUserClick={() => setShowModal(true)}
                formData={formData}
                handleChange={handleChange}
                handleReset={handleReset}
            />
            <UserTable
                users={paginatedUsers}
                onAddCredit={handleAddCredit}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
            />

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={newUser.name}
                                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={newUser.email}
                                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                value={newUser.password}
                                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                value={newUser.confirmPassword}
                                onChange={(e) => setNewUser({ ...newUser, confirmPassword: e.target.value })}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="form-label">Status</label>
                            <select
                                id="status"
                                className="form-select"
                                value={newUser.status}
                                onChange={(e) => setNewUser({ ...newUser, status: e.target.value })}
                            >
                                <option value="Active">Active</option>
                                <option value="Banned">Banned</option>
                            </select>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddUser}>
                        Add User
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UserManagement;
