import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import CreateTableModal from './Components/CreateTableModal';
import ImportDatabaseModal from './Components/ImportDatabaseModal';
import { getAllTables } from '../../services/apiService';
import Paginationnew from '../../utils/Paginationnew';
import { Tablesempty, Tablesdrop, exportDatabase } from '../../services/apiService';
import BulkActionModal from '../Contact_Management/Components/BulkActionModal';
import { decodeBase64, encodeBase64, getImageBaseUrl } from '../../services/apiService';

const RealTimeTables = () => {
  const location = useLocation();
  const { myData, server, username, password } = useParams(); // Retrieve the URL parameter
  const decodedMyData = decodeBase64(myData);
  const decodedServer = decodeBase64(server);
  const decodedUsername = decodeBase64(username);
  const decodedPassword = decodeBase64(password);

  const [data, setData] = useState({});
  const [exportloading, setexportloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showCreateTableModal, setShowCreateTableModal] = useState(false);
  const [showImportDatabaseModal, setShowImportDatabaseModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [contactsPerPage, setContactsPerPage] = useState(10); // Default to 10 contacts per page
  const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

  const [pagination, setPagination] = useState({ totalPages: 0, pageSize: 10 });

  console.log('location.state', decodedMyData, decodedServer, decodedUsername, decodedPassword); // Do something with your data

  const [allChecked, setAllChecked] = useState(false);

  const [Checkedrows, setCheckedrows] = useState([]); // Store selected row IDs
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false);


  const [selectedrow, setSelectedrow] = useState([]);

  const [loading, setLoading] = useState(false);

  const getTables = async () => {
    setLoading(true);
    try {
      // Log the decoded values for debugging
      console.log('Fetching tables with the following details:');
      console.log('Server:', decodedServer);
      console.log('Username:', decodedUsername);
      console.log('Database:', decodedMyData);
      console.log('Page:', currentPage);
      console.log('Items Per Page:', contactsPerPage);
      console.log('Search Term:', searchTerm);

      // Validate required parameters before making the request
      if (!decodedServer || !decodedUsername || !decodedPassword || !decodedMyData) {
        alert('Missing required parameters. Please check your server, username, password, or database name.');
        setLoading(false);
        return;
      }

      // Make the API request
      const response = await getAllTables(
        decodedServer,
        decodedUsername,
        decodedPassword,
        decodedMyData,
        currentPage,
        contactsPerPage,
        searchTerm
      );

      // Handle the response
      if (response && response.success) {
        console.log('TABLE RESPONSE:', response);
        setFilteredData(response.tables || []);
        setPagination({
          ...pagination,
          totalPages: response.pagination?.totalPages || 0,
        });
        setLoading(false);
      } else {
        // Provide detailed error messages
        const errorMessage = response?.message || 'Unknown error occurred while fetching tables.';
        console.error('Error fetching tables:', errorMessage);
        alert(`${decodedMyData} Tables not fetching. Please check. Error: ${errorMessage}`);
        setLoading(false);
      }
    } catch (error) {
      // Catch unexpected errors
      console.error('Unexpected error in getTables:', error);
      alert(`An error occurred: ${error.message}. Please check your server connection and try again.`);
      setLoading(false);
    }
  };


  useEffect(() => {
    setImageBaseUrl(getImageBaseUrl());
    getTables();
  }, [searchTerm, currentPage, contactsPerPage]);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);  // Reset to the first page on search change
  };

  const handleDrop = async (tableName) => {
    // Add logic for dropping the table
    if (window.confirm(`Are you sure you want to drop the table ${tableName}?`)) {
      // Call API or perform the necessary action to drop the table
      const responce = await Tablesdrop(decodedServer, decodedUsername, decodedPassword, decodedMyData, tableName);
      if (responce.success) {
        getTables();
        alert(`Table ${tableName} has been dropped.`);
      } else {
        alert(`Table ${tableName} has been failed dropped.`);
      }
    }
  };

  const handleContactsPerPage = (e) => {
    setContactsPerPage(e.target.value);
    setCurrentPage(1); // Reset to the first page when count changes
  };

  const handleRowCheck = (id) => {
    console.log('handleRowCheck :', id);
    // Update filteredContacts state
    const updatedRows = filteredData.map(row =>
      row.Table === id ? { ...row, isChecked: !row.isChecked } : row
    );
    setFilteredData(updatedRows);

    // Handle Checkedrows state based on whether the checkbox is checked or unchecked
    if (updatedRows.find(row => row.Table === id).isChecked) {
      // Add the ID to Checkedrows if the row is checked
      setCheckedrows(prevChecked => [...prevChecked, id]);
    } else {
      // Remove the ID from Checkedrows if the row is unchecked
      setCheckedrows(prevChecked => prevChecked.filter(checkedId => checkedId !== id));
    }

    // Check if all rows are checked to update 'Select All' checkbox state
    setAllChecked(updatedRows.every(row => row.isChecked));
  };

  const handleSelectAll = () => {
    // Toggle the allChecked state
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    // Update the rows' isChecked based on the new allChecked state
    const updatedRows = filteredData.map(row => ({ ...row, isChecked: newCheckedState }));
    setFilteredData(updatedRows);

    // Update Checkedrows state based on the new allChecked state
    if (newCheckedState) {
      // Select all row IDs if all are checked
      const rowsall = updatedRows.filter(row => row.isChecked).map(row => row.id);
      setCheckedrows(rowsall);
    } else {
      // Deselect all row IDs
      setCheckedrows([]);
    }
  };

  const handleexport = async () => {
    setexportloading(true);
    const responce = await exportDatabase(decodedServer, decodedUsername, decodedPassword, decodedMyData);
    if (responce.success) {
      // Create a temporary <a> element for downloading the file
      const downloadLink = document.createElement('a');
      downloadLink.href = responce.downloadUrl; // Use the URL returned in the response
      downloadLink.download = responce.downloadUrl.split('/').pop(); // Use the file name from the URL
      downloadLink.click(); // Trigger the download automatically
      setexportloading(false);
      setShowExportModal(false);
    } else {
      alert(responce.message);
    }
  };

  const handleEmpty = async (tableName) => {
    // Add logic for emptying the table
    if (window.confirm(`Are you sure you want to empty the table ${tableName}?`)) {
      // Call API or perform the necessary action to empty the table

      const responce = await Tablesempty(decodedServer, decodedUsername, decodedPassword, decodedMyData, tableName);
      if (responce.success) {
        alert(`Table ${tableName} has been emptied.`);
        getTables();
      } else {
        alert(`Table ${tableName} has been failed emptied.`);
      }
    }
  };

  return (
    <div className="dashboard-container">
      <div className="card mb-4">
        <div className="card-header">
          <div className="d-flex align-items-center">
            {/* Home icon with link */}
            <Link to="/real_time_database">
              <i className="fa fa-home" style={{ fontSize: '24px', marginRight: '10px' }}></i>
            </Link>

            {/* Databasename as a clickable link */}

            <h1 className="card-title" style={{ display: 'inline' }}>
              {decodedMyData ? `${decodedMyData}` : 'Your Database'}
            </h1>
          </div>
        </div>
        <div className="card-filter">
          <form>
            <div className="filter-form">
              <div className="filter-item">
                <input
                  type="text"
                  name="search"
                  placeholder="Type Table name"
                  className="form-control"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="filter-item col-lg-1">
                <select
                  id="contactsPerPage"
                  value={contactsPerPage}
                  onChange={handleContactsPerPage}
                  className="form-select"
                >
                  <option value="" disabled>Show per page</option>
                  <option value="1">1</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="filter-action">
                {Checkedrows.length > 0 ? (
                  <div className="statusUpdateBtn">
                    <a
                      className="i-btn success--btn btn--md bulkAction"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Bulk Actions"
                      onClick={() => setIsBulkActionModalOpen(true)} // Corrected: wrapped in a function
                    >
                      <svg
                        className="svg-inline--fa fa-gear"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="gear"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z"
                        />
                      </svg>
                      Action
                    </a>
                  </div>
                ) : (<></>)}
                <button type="button" className="i-btn danger--btn btn--md text-white" onClick={() => setShowConnectModal(true)}>
                  <i className="las la-sync"></i> Connect
                </button>
                <button type="button" className="i-btn primary--btn btn--md text-white" onClick={() => setShowCreateTableModal(true)}>
                  <i class="fa fa-plus"></i> Create Table
                </button>
                <Link
                  to={`/SqlEditor/${encodeBase64(decodedMyData)}/${encodeBase64(decodedServer)}/${encodeBase64(decodedUsername)}/${encodeBase64(decodedPassword)}/${encodeBase64("demo")}`} // Pass data in the URL
                  className="btn btn-primary"
                  style={{ height: '38px', marginRight: '10px' }}
                >
                  <img src={`${imageBaseUrl}/assets/file/images/logoIcon/b_sql.png`} alt="Structure Icon" /> SQL
                </Link>
                <button type="button" className="i-btn primary--btn btn--md text-white" onClick={() => setShowExportModal(true)}>
                  <img src={`${imageBaseUrl}/assets/file/images/logoIcon/b_tblexport.png`} alt="Structure Icon" /> Export
                </button>
                <button type="button" className="i-btn primary--btn btn--md text-white" onClick={() => setShowImportDatabaseModal(true)}>
                  <img src={`${imageBaseUrl}/assets/file/images/logoIcon/b_tblimport.png`} alt="Structure Icon" /> Import
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="card-body px-0">
          <div className="responsive-table">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="d-flex align-items-center">
                      <input
                        className="form-check-input mt-0 me-2 checkAll"
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleSelectAll}
                        value=""
                        aria-label="Checkbox for following text input" />
                      <span>Sl No.</span>
                    </div>
                  </th>
                  <th>Table</th>
                  <th>Rows</th>
                  <th>Type</th>
                  <th>Collation</th>
                  <th>Size</th>
                  <th>Overhead</th>
                  <th style={{ textAlign: 'right' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="8" style={{ textAlign: 'center' }}>
                      Loading...
                    </td>
                  </tr>
                ) : (
                  filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="d-none d-sm-flex align-items-center">
                          <input
                            className="form-check-input mt-0 me-2"
                            type="checkbox"
                            name="contactUid"
                            value={item.uid}
                            checked={item.isChecked}
                            onChange={() => handleRowCheck(item.Table)}
                            aria-label="Checkbox for following text input"
                          />
                          {index + 1} {/* Display the contact's index */}
                        </td>
                        <td>{item.Table}</td>
                        <td>{item.Rows}</td>
                        <td>{item.Type}</td>
                        <td>{item.Collation}</td>
                        <td>{(parseFloat(item.Size_MB) * 1024).toFixed(2)} KB</td> {/* Convert Size from MB to KB */}
                        <td>{item.Overhead_MB}</td>
                        <td style={{ textAlign: 'right' }}>
                          {/* View Button */}
                          <Link
                            to={`/real_time_database/columns/${encodeBase64(decodedMyData)}/${encodeBase64(decodedServer)}/${encodeBase64(decodedUsername)}/${encodeBase64(decodedPassword)}/${encodeBase64(item.Table)}`} // Pass data in the URL
                            className="btn btn-primary"
                            style={{ height: '38px', marginRight: '10px' }}
                          >
                            <i className="fas fa-eye"></i>
                          </Link>

                          {/* Drop Button */}
                          <Button
                            variant="danger"
                            style={{ height: '38px', marginRight: '10px' }}
                            onClick={() => handleDrop(item.Table)} // Trigger Drop action
                          >
                            <i className="fas fa-trash-alt"></i>
                          </Button>

                          {/* Empty Button */}
                          <Button
                            variant="warning"
                            style={{ height: '38px' }}
                            onClick={() => handleEmpty(item.Table)} // Trigger Empty action
                          >
                            <i className="fas fa-trash-restore-alt"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" style={{ textAlign: 'center' }}>No data available</td>
                    </tr>
                  )

                )}


              </tbody>
            </table>
            <Paginationnew
              currentPage={currentPage}
              totalPages={pagination.totalPages}
              handlePageChange={handlePageChange}
            />


          </div>
        </div>
        {isBulkActionModalOpen && (
          <BulkActionModal
            onClose={() => setIsBulkActionModalOpen(false)}
            selectedRows={Checkedrows}
            fetchData={getTables}
            setAllChecked={setAllChecked}
            type={'realtimetables'}
            ip={decodedServer}
            username={decodedUsername}
            password={decodedPassword}
            dbName={decodedMyData}
            setCheckedrows={setCheckedrows}
          />
        )}
        <CreateTableModal
          show={showCreateTableModal}
          handleClose={() => setShowCreateTableModal(false)}
          ip={decodedServer}
          username={decodedUsername}
          password={decodedPassword}
          dbName={decodedMyData}
          getTables={getTables}
          addcolumns={false}
          tableNameold={""}
        />
        <ImportDatabaseModal
          show={showImportDatabaseModal}
          handleClose={() => setShowImportDatabaseModal(false)}
          ip={decodedServer}
          username={decodedUsername}
          password={decodedPassword}
          dbName={decodedMyData}
          getTables={getTables}
        />

        <Modal show={showConnectModal} onHide={() => setShowConnectModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Connect to Database</Modal.Title>
          </Modal.Header>
          <Modal.Body>Connect Modal content here</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConnectModal(false)}>Close</Button>
            <Button variant="primary" onClick={() => alert('Connected!')}>Connect</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showExportModal} onHide={() => setShowExportModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Export Table</Modal.Title>
          </Modal.Header>
          <Modal.Body>Export Modal content here</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowExportModal(false)}>Close</Button>
            <Button variant="primary" onClick={() => handleexport()}>
              {exportloading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                "Export"
              )}
              {exportloading ? " Exporting..." : " Export"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default RealTimeTables;
