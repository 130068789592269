// PhonePePayment.js
import React from 'react';

const PhonePePayment = ({ amount }) => {
  const handlePayment = () => {
    alert('PhonePe payment integration coming soon!');
  };

  return <button onClick={handlePayment}>Pay {amount} with PhonePe</button>;
};

export default PhonePePayment;
