import React, { useState } from 'react';
import { Modal, Button, Spinner, Alert, Form } from 'react-bootstrap';
import { importdatabase, sendSQLFileContent } from '../../../services/apiService';

const ImportDatabaseModal = ({ show, handleClose, ip, username, password, dbName, getTables, tablename }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [sqlCode, setsqlCode] = useState("");

  // Handle file selection
  // Handle file selection
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    const response = await sendSQLFileContent(selectedFile);
    if(response.success){
      console.log('sendSQLFileContent :', response.sqlCode);
      setsqlCode(response.sqlCode);
    }else{
      console.log('sendSQLFileContent :', response.message);
    }
    // Check if the selected file is a .sql filesqlCode
    if (selectedFile && !selectedFile.name.endsWith('.sql')) {
      setError('Only .sql files are supported.');
      setFile(null); // Clear the file input
    } else {
      setError(null); // Clear any previous errors
      setFile(selectedFile);
    }
  };
  // Handle import action
  const handleImport = async () => {
    if (!file) {
      setError('Please select a file to import.');
      return;
    }

    setLoading(true);
    setError(null); // Clear any previous errors
    setSuccess(false); // Reset success message

    try {
      const response = await importdatabase(ip, username, password, dbName, sqlCode, tablename);
      if (response.success) {
        setSuccess(true);
        handleClose();
        getTables();
      } else {
        setError(response.message || 'Failed to import the database.');
      }
    } catch (err) {
      setError(err.message || 'Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Import Database</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Database imported successfully!</Alert>}

        <Form.Label>Select DB File</Form.Label>
        <Form.Control
          type="file"
          accept=".sql" // Adjust this to your file types
          onChange={handleFileChange}
        />
        <br></br>
        <p>Upload an SQL file to import the database.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button
          variant="primary"
          onClick={handleImport}
          disabled={loading}
        >
          {loading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Import'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportDatabaseModal;
