import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { useSpring, animated } from '@react-spring/web';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { oneclick } from '../../../oneclick';
import Select from 'react-select';
import database_id from '../../../utils/databaseConfig';

const DraggableSubmenuItem = ({ submenu, index, moveSubmenu }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'submenuItem',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveSubmenu(draggedItem.index, index);
                draggedItem.index = index;
            }
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'submenuItem',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const springProps = useSpring({
        opacity: isDragging ? 0.5 : 1,
        transform: isDragging ? 'scale(0.95)' : 'scale(1)',
        config: { tension: 250, friction: 20 }
    });

    drag(drop(ref));

    return (
        <animated.div
            ref={ref}
            style={{
                ...springProps,
                padding: '10px',
                border: '1px solid gray',
                marginBottom: '5px',
                backgroundColor: isDragging ? '#ffcccb' : '#f0f0f0',
                cursor: 'move',
                userSelect: 'none'
            }}
        >
            {submenu.name}
        </animated.div>
    );
};

const SubMenuOrderModal = ({ show, handleClose, submenus, allgroups, mainmenu, updateGroupOrder }) => {

    console.log('mainmenu:', submenus);
    const [groupList, setGroupList] = useState(submenus);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [filteredMenus, setFilteredMenus] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [filteredSubmenus, setFilteredSubmenus] = useState([]); // To store filtered submenus

    useEffect(() => {
        setGroupList(submenus);
    }, [submenus]);

    useEffect(() => {
        if (selectedGroup) {
            console.log('selectedGroup', selectedGroup.value);
            const menusForGroup = mainmenu.filter(menu => menu.groupId == selectedGroup.value);
            setFilteredMenus(menusForGroup);
            console.log('setFilteredorderMenus', menusForGroup);
        } else {
            setFilteredMenus([]);
        }
    }, [selectedGroup, mainmenu]);

    // Filter submenus based on selected menu
    useEffect(() => {
        if (selectedMenu) {
            console.log('selectedMenu', selectedMenu.value);
            const submenusForMenu = submenus.filter(submenu => submenu.menuId == selectedMenu.value); // Filter submenus based on menuId
            setFilteredSubmenus(submenusForMenu);
            console.log('setFilteredordersubMenus', submenusForMenu);
        } else {
            setFilteredSubmenus([]);
        }
    }, [selectedMenu, submenus]);

    const moveSubmenu = (fromIndex, toIndex) => {
        const updatedSubmenus = [...filteredSubmenus];
        const [movedSubmenu] = updatedSubmenus.splice(fromIndex, 1);
        updatedSubmenus.splice(toIndex, 0, movedSubmenu);
        setFilteredSubmenus(updatedSubmenus);
    };

    const handleSave = async () => {
        const tableSchema = database_id;
        const tableName = "submenu";

        const updatedSubmenuOrder = filteredSubmenus.map((submenu, index) => ({
            id: submenu.id,
            name: submenu.name,
            order_by: index + 1
        }));

        for (let submenu of updatedSubmenuOrder) {
            const Conditions = { "id": submenu.id };
            const data = {
                order_by: submenu.order_by
            };
            await oneclick.update(tableSchema, tableName, data, Conditions);
        }

        handleClose();
    };

    const handleReset = () => {
        setFilteredSubmenus([]);
        setFilteredMenus(groupList);
    };

    const groupOptions = allgroups.map(group => ({ value: group.id, label: group.name }));

    const menuOptions = filteredMenus.map(menu => ({
        value: menu.id,
        label: menu.name
    }));

    return (
        <Modal show={show} onHide={() => { handleClose(); handleReset(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Reorder SubMenu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Select Group</Form.Label>
                    <Select
                        options={groupOptions}
                        onChange={setSelectedGroup}
                        placeholder="Select a group"
                        isClearable
                    />
                </Form.Group>
                <Form.Group controlId="formSelectMenu">
                    <Form.Label>Select Menu</Form.Label>
                    <Select
                        options={menuOptions}
                        onChange={setSelectedMenu}
                        placeholder="Select a menu"
                        className='mb-4'
                        isClearable
                        isDisabled={!selectedGroup}
                    />
                </Form.Group>

                <DndProvider backend={HTML5Backend}>
                    {filteredSubmenus.map((submenu, index) => (
                        <DraggableSubmenuItem
                            key={submenu.id}
                            index={index}
                            submenu={submenu}
                            moveSubmenu={moveSubmenu}
                        />
                    ))}
                </DndProvider>

                <div style={{ marginTop: '20px' }}>
                    <h5>Current Submenu Order:</h5>
                    <ul>
                        {filteredSubmenus.map((submenu, index) => (
                            <li key={submenu.id}>
                                {`Name: ${submenu.name}, ID: ${submenu.id}, Position: ${index + 1}`}
                            </li>
                        ))}
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { handleClose(); handleReset(); }}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Order
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SubMenuOrderModal;
