import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import AddNewApi from './Components/AddNewApi';
import QRTableList from './Components/QRTableList';
import CloudTableList from './Components/CloudTableList';
import QRShow from './Components/QRShow';

const WhatsAppGateway = () => {
  const [activeTab, setActiveTab] = useState('wp-cloud-api');
  const [showAddNewApi, setShowAddNewApi] = useState(false);
  const [showqr, setShowQR] = useState(false);
  const [type, setType] = useState('cloud');
  const [searchinput, setSearchInput] = useState('');
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectqrdata, setSelectQRData] = useState(null);

  const [loadqrdata, setLoadQRData] = useState(false);

  const openWpTab = (event, tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">WhatsApp Api</h4>
            </div>
            <div className="card-filter">
              <form>
                <div className="filter-form">
                  <div className="filter-item">
                    <input
                      type="text"
                      autoComplete="off"
                      name="search"
                      value={searchinput}
                      placeholder="Search With Name"
                      className="form-control"
                      defaultValue=""
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <div className="filter-item">
                    <select name="status" className="form-select" value={filter} onChange={(e) => setFilter(e.target.value)}>
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="inctive">DeActive</option>
                    </select>
                  </div>
                  <div className="filter-item">
                    <select name="department" className="form-select" value={type} onChange={(e) => setType(e.target.value)}>
                      <option value="">Select Type</option>
                      <option value="qr">QR</option>
                      <option value="cloud">Cloud</option>
                    </select>
                  </div>
                  <div className="filter-action">
                    <button type="button" className="i-btn danger--btn btn--md">
                      Reset
                    </button>
                    <button
                      type="button"
                      className="i-btn primary--btn btn--md text-white"
                      onClick={() => setShowAddNewApi(true)}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body px-0">
              <div className="responsive-table">
                {/* Table  CloudTableList*/}
                {type === 'qr' ? (
                  <QRTableList searchinput={searchinput} filter={filter} currentPage={currentPage} setTotalPages={setTotalPages} setShowQR={setShowQR} setSelectQRData={setSelectQRData} loadqrdata={loadqrdata}/>
                ) : (
                  <CloudTableList searchinput={searchinput} filter={filter} currentPage={currentPage} setTotalPages={setTotalPages} />
                )}
              </div>
              <div
                className="pagination"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 20,
                  marginRight: 17,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      {showAddNewApi && <AddNewApi show={showAddNewApi} hide={() => setShowAddNewApi((false))} setLoadQRData={setLoadQRData}/>}
      {showqr && <QRShow show={showqr} hide={() => setShowQR(false)} data={selectqrdata} setLoadQRData={setLoadQRData}/>}
    </div>
  );
};



const ModalDelete = () => {
  return (
    <div className="modal fade" id="deleteModal" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-4">
            <h4 className="modal-title mb-4" id="deleteModalLabel">
              Delete WhatsApp Business Account
            </h4>
            <p>Are you sure you want to delete this account?</p>
            <div className="d-flex gap-3 justify-content-end mt-4">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalEdit = () => {
  return (
    <div className="modal fade" id="editModal" aria-labelledby="editModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-4">
            <h4 className="modal-title mb-4" id="editModalLabel">
              Edit WhatsApp Business Account
            </h4>
            <form>
              <div className="mb-3">
                <label htmlFor="editName" className="form-label">Business Portfolio Name</label>
                <input type="text" className="form-control" id="editName" />
              </div>
              <div className="d-flex gap-3 justify-content-end mt-4">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppGateway;
