import React, { useEffect, useState, useRef } from "react";
import ImageComponent from "../../../utils/ImageComponent";
import Ai_ResponceChat from "./Chating/Ai_ResponceChat";


const ChatContainer = ({ messages, profile_image, staffdata, newMessage, handleInputChange, handleSendMessage, loadOlderMessages, typing }) => {

    const chatContainerRef = useRef(null); // Create a reference to the chat container

    useEffect(() => {
        console.log('chatContainerRef :', chatContainerRef.current);
        if (chatContainerRef.current) {
            const container = chatContainerRef.current;

            const handleScroll = () => {
                console.log("Scrolling... :", container.scrollTop);
                const top = container.scrollTop;
                const previousHeight = 400 - container.scrollHeight;

                console.log("Scrolling... top:", container.scrollTop, '-', previousHeight);
                if (container.scrollTop === previousHeight) {
                    console.log("Scrolled to the top. Loading more data...");
                    loadOlderMessages(); // Your function to load older messages
                }
            };

            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [chatContainerRef.current]);

    return (
        <div className="col-lg-9 col-md-12 col-sm-12" style={{ paddingRight: '15px' }}>
            <div className="" style={{ background: 'white', borderRadius: '5px' }} >
                {messages.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center col-lg-12" style={{ height: '100%', minHeight: '400px', width: '100%' }}>
                        <div className="text-center chat-containeremty">
                            <p className="text-muted">What can I help with?</p>
                        </div>
                    </div>
                ) : (
                    <div className="messages chat-container" style={{ minHeight: '400px', display: 'flex' }} ref={chatContainerRef}>
                        <div class="content">

                            {messages.map((message, index) => (
                                message.sender === "user" ? (
                                    <div key={index} className={`messageuser col-lg-12 col-md-12 col-sm-12`}>
                                        <div className="d-flex userchat " style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                            <div className="col-lg-1 col-md-1 col-sm-1" style={{ width: '50px', height: '50px' }}>
                                                {profile_image ? (
                                                    <ImageComponent
                                                        fileid={profile_image || ''}
                                                        width={50}
                                                        height={50}
                                                        borderRadius={50}
                                                        boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                                    />
                                                ) : (
                                                    <img src="/assets/file/images/user/profile/6388307.png" alt="user" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                                )}

                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-10">
                                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                    <div className="message-bubbleuser">
                                                        {message.text}
                                                    </div>
                                                </div>
                                                <p style={{ textAlign: 'end' }}>{message.time}</p>
                                            </div>

                                        </div>
                                    </div>
                                ) : (

                                    <Ai_ResponceChat message={message} staffdata={staffdata} index={index} />  // Ai Responce Chat Component
                                )
                            ))}
                            {typing && (
                                <div className="message typing">

                                    <div className="d-flex senderchat col-lg-11">
                                        <div style={{ width: '50px', height: '50px' }}>
                                            <ImageComponent
                                                fileid={staffdata.profile_image}
                                                width={50}
                                                height={50}
                                                borderRadius={50}
                                                boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                            />
                                        </div>
                                        <div className="col-lg-10">
                                            <div>
                                                <div className="message-bubblesender">
                                                    <p>Typing...</p>
                                                </div>
                                            </div>
                                            <p style={{ marginLeft: '25px', textAlign: 'left' }}>{new Date().toLocaleString()}</p>
                                        </div>
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>
                )}


            </div>
            <div className="chat-input d-flex" style={{ alignItems: 'center' }}>
                <input
                    type="text"
                    value={newMessage}
                    onChange={handleInputChange}
                    placeholder="Type a message..."
                    className="form-control"
                    style={{
                        borderRadius: '20px',
                        padding: '10px',
                        border: '1px solid #ccc',
                        outline: 'none',
                        marginRight: '10px',
                    }}
                />
                <button
                    onClick={handleSendMessage}
                    className="btn btn-primary"
                    style={{
                        borderRadius: '50%',
                        padding: '10px 15px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#45a049'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = '#4CAF50'}
                >
                    <i className="fa fa-paper-plane" style={{ fontSize: '18px' }}></i>
                </button>
            </div>
        </div>
    );
}

export default ChatContainer;