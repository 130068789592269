import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import * as brandIcons from "@fortawesome/free-brands-svg-icons";

const allIcons = {
    ...solidIcons,
    ...brandIcons,
};

const IconPicker = ({ icon, setIcon }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const handleIconSelect = (iconName) => {
        setIcon(iconName);
        setIsPopupOpen(false);
    };

    const filteredIcons = Object.entries(allIcons).filter(
        ([key]) =>
            key.startsWith("fa") && // Ensure it's an icon
            key.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    value={icon}
                    readOnly
                    onClick={() => setIsPopupOpen(true)}
                />
                <span
                    className="input-group-text input-group-addon"
                    role="button"
                    onClick={() => setIsPopupOpen(!isPopupOpen)}
                >
                    <FontAwesomeIcon icon={solidIcons.faSearch} />
                </span>
            </div>

            {isPopupOpen && (
                <div className="icon-picker-popup">
                    <div className="icon-picker-search">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search icons..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="icon-picker-grid">
                        {filteredIcons.map(([key, value]) => (
                            <span
                                key={key}
                                className="icon-picker-item"
                                onClick={() => handleIconSelect(key)}
                            >
                                <FontAwesomeIcon icon={value} />
                                <p>{key.replace("fa", "").replace(/([A-Z])/g, " $1").trim()}</p>
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default IconPicker;
