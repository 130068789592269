import axios from 'axios';
import React, { useState } from 'react';
import apiUrl from './utils/apiConfig';
const API_BASE_URL = `${apiUrl}/api/files`; // Replace with your actual API endpoint

class FileSDK {

  constructor() {
    this.pollingInterval = null; // Interval ID for long polling
  }

  // Helper function to make POST requests using axios
  async postRequest(endpoint, data) {
    try {
      const response = await axios.post(`${API_BASE_URL}/${endpoint}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data; // Axios handles response parsing automatically
    } catch (error) {
      console.error('Error in postRequest:', error);
      throw error;
    }
  }

  async uploadFile(userId, root, urllink, file) {
    const token = localStorage.getItem('token'); // Get the token
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('root', root);
    formData.append('urllink', urllink);
    formData.append('file', file);


    try {
      const response = await axios.post(`${API_BASE_URL}/uploads`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, // Send token in the Authorization header
          // 'x-api-key': 'YOUR_USER_API_KEY', // Custom API key for user authentication
          'Cache-Control': 'no-store', // Disable caching for this request
        }
      });

      console.log('Upload response:', response.data);
      return response.data;
      //a(response.data.message || 'File uploaded successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
      //setMessage(error.response?.data?.error || 'An error occurred during file upload.');
    }
  }

  async uploadFilesdk(api_key, folderId, Bucket_id, status, file, onProgress) {
    const token = localStorage.getItem('token'); // Get the token
    const formData = new FormData();
    formData.append('api_key', api_key);
    formData.append('folderId', folderId);
    formData.append('Bucket_id', Bucket_id);  //status
    formData.append('status', status);
    formData.append('file', file);

    try {
      const response = await axios.post(`${API_BASE_URL}/uploadsdk`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, // Send token in the Authorization header
          // 'x-api-key': 'YOUR_USER_API_KEY', // Custom API key for user authentication
          'Cache-Control': 'no-store', // Disable caching for this request
        },
        // Use onUploadProgress to track upload progress
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          // Pass the progress value to the callback function
          if (onProgress) {
              onProgress(progress);
          }
        }
      });

      console.log('Upload response:', response.data);
      return response.data;
      //a(response.data.message || 'File uploaded successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
      //setMessage(error.response?.data?.error || 'An error occurred during file upload.');
    }
  }


  async uploadFoldersdk(api_key, folderId, Bucket_id, status, file, onProgress) {
    const token = localStorage.getItem('token'); // Get the token
    const formData = new FormData();
    formData.append('api_key', api_key);
    formData.append('folderId', folderId);
    formData.append('Bucket_id', Bucket_id);  //status
    formData.append('status', status);
    formData.append('file', file);

    try {
      const response = await axios.post(`${API_BASE_URL}/uploadFolderdk`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, // Send token in the Authorization header
          // 'x-api-key': 'YOUR_USER_API_KEY', // Custom API key for user authentication
          'Cache-Control': 'no-store', // Disable caching for this request
        },
        // Use onUploadProgress to track upload progress
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          // Pass the progress value to the callback function
          if (onProgress) {
              onProgress(progress);
          }
        }
      });

      console.log('Upload response:', response.data);
      return response.data;
      //a(response.data.message || 'File uploaded successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
      //setMessage(error.response?.data?.error || 'An error occurred during file upload.');
    }
  }

  handleError(error) {
    if (error.response) {
      console.error('Server responded with error:', error.response.data);
      console.error('Status code:', error.response.status);
    } else if (error.request) {
      console.error('No response received from server:', error.request);
    } else {
      console.error('Error during request setup:', error.message);
    }
    console.error('Error config:', error.config);
  }

  // Get files for a specific user and root
  async getFiles(userId, root) {
    return this.postRequest('getFiles', { userId, root });
  }

  // Start long polling for file retrieval
  async startLongPolling(userId, root, callback) {
    const fetchData = async () => {
      try {
        const data = await this.getFiles(userId, root);
        callback(data); // Pass the data to the callback
      } catch (error) {
        console.error('Error during long polling:', error);
      }
    };

    // Fetch data every 5 seconds to reduce server load
    this.pollingInterval = setInterval(fetchData, 5000);
  }

  // Stop long polling
  stopLongPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
      console.log('Long polling stopped.');
    }
  }
}

export const OneKlicksFileSDK = new FileSDK();
