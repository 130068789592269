import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { oneclick } from '../../../oneclick';
import { createDatabaseUser } from '../../../services/apiService';
import database_id from '../../../utils/databaseConfig';

const AddUserModal = ({ show, handleClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    if (isLoading) return; // Prevent multiple submissions
    setIsLoading(true);
  
    // Ensure all fields are filled out
    if (!username || !password || !confirmPassword) {
      setError('All fields are required.');
      setIsLoading(false); // Reset loading state
      return;
    }
  
    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      setIsLoading(false); // Reset loading state
      return;
    }
  
    try {
      // Fetch the table schema and token
      const tableSchema = database_id;
      const token = localStorage.getItem('token');
  
      if (!token) {
        alert('Token not found. Please log in again.');
        setIsLoading(false); // Reset loading state
        return;
      }
  
      // Fetch user details
      const conditionsUsers = { login_tockin: token };
      const userdata = await oneclick.read(tableSchema, "users", conditionsUsers, "", "");
  
      if (!userdata.data || userdata.data.length === 0) {
        alert('User data not found. Please log in again.');
        setIsLoading(false); // Reset loading state
        return;
      }
  
      const { id: user_id, company_id } = userdata.data[0] || {};
  
      // Validate user_id and company_id
      if (!user_id) {
        alert('User not found. Please log in again.');
        setIsLoading(false); // Reset loading state
        return;
      }
      if (!company_id) {
        alert('User company_id not found. Please log in again.');
        setIsLoading(false); // Reset loading state
        return;
      }
  
      // Create the database user
      const create = await createDatabaseUser(username, password, user_id, company_id);
  
      if (create.success) {
        // Success: Clear form and close modal
        console.log('User created successfully:', { username, password });
        alert(create.message);
  
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setError('');
        handleClose(); // Close the modal
      } else {
        alert(create.error || 'Failed to create the user. Please try again.');
      }
    } catch (error) {
      console.error('Error during submission:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      // Always reset loading state at the end
      setIsLoading(false);
    }
  };
  
  

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={handleUsernameChange}
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Form.Group>

          <Form.Group controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Form.Group>

          {error && <p style={{ color: 'red' }}>{error}</p>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {isLoading ? 'Creating...' : 'Create User'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUserModal;
