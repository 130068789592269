import React, { useState } from 'react';

const EmailsTemplate = () => {
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [emails, setEmails] = useState([]); // State to hold email data
  const [newEmail, setNewEmail] = useState({ name: '', sender: '', subject: '', totalContacts: '', scheduleTime: '', status: 'Active' });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., fetching emails based on search and status)
  };

  const handleDelete = (id) => {
    // Handle delete logic here
    console.log(`Deleted campaign with ID: ${id}`);
    setEmails(emails.filter(email => email.id !== id)); // Remove email from list
  };

  const handleAddNewEmail = (e) => {
    e.preventDefault();
    // Validate required fields
    if (!newEmail.name || !newEmail.sender || !newEmail.subject || !newEmail.totalContacts || !newEmail.scheduleTime) {
      alert('Please fill in all required fields.');
      return;
    }

    // Handle adding a new email
    setEmails([...emails, { ...newEmail, id: emails.length + 1 }]); // Add new email with unique ID
    setNewEmail({ name: '', sender: '', subject: '', totalContacts: '', scheduleTime: '', status: 'Active' }); // Reset form fields
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Email List</h4>
            </div>

            <div className="card-filter">
              <form onSubmit={handleFormSubmit}>
                <div className="filter-form">
                  <div className="filter-item">
                    <input
                      type="text"
                      autoComplete="off"
                      name="search"
                      placeholder="Search With Name"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <input type="hidden" name="channel" value="email" />

                  <div className="filter-item">
                    <select
                      name="status"
                      className="form-select"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="DeActive">DeActive</option>
                      <option value="Completed">Completed</option>
                      <option value="Ongoing">Ongoing</option>
                    </select>
                  </div>

                  <div className="filter-action">
                    <button className="i-btn info--btn btn--md" type="submit">
                      Search
                    </button>
                    <button
                      className="i-btn danger--btn btn--md"
                      type="button"
                      onClick={() => { setSearch(''); setStatus(''); }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-body px-0">
              <div className="responsive-table">
                <table>
                  
                  <tbody>
                    {emails.length > 0 ? (
                      emails.map((email) => (
                        <tr key={email.id}>
                          <td>{email.name}</td>
                          <td>{email.sender}</td>
                          <td>{email.subject}</td>
                          <td>{email.totalContacts}</td>
                          <td>{email.scheduleTime}</td>
                          <td>{email.status}</td>
                          <td>
                            <button
                              className="i-btn danger--btn btn--md"
                              data-bs-toggle="modal"
                              data-bs-target="#delete"
                              onClick={() => handleDelete(email.id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-muted text-center" colSpan="7">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* New Email Form directly in the page */}
              <div className="new-email-form mt-5">
                <h5>Add New Email</h5>
                <form onSubmit={handleAddNewEmail}>
                  <div className="form-group mb-3">
                    <label>Name <span style={{ color: 'red' }}>*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      value={newEmail.name}
                      onChange={(e) => setNewEmail({ ...newEmail, name: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Sender <span style={{ color: 'red' }}>*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      value={newEmail.sender}
                      onChange={(e) => setNewEmail({ ...newEmail, sender: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Subject <span style={{ color: 'red' }}>*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      value={newEmail.subject}
                      onChange={(e) => setNewEmail({ ...newEmail, subject: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Total Contacts <span style={{ color: 'red' }}>*</span></label>
                    <input
                      type="number"
                      className="form-control"
                      value={newEmail.totalContacts}
                      onChange={(e) => setNewEmail({ ...newEmail, totalContacts: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Schedule Time <span style={{ color: 'red' }}>*</span></label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={newEmail.scheduleTime}
                      onChange={(e) => setNewEmail({ ...newEmail, scheduleTime: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Status</label>
                    <select
                      className="form-select"
                      value={newEmail.status}
                      onChange={(e) => setNewEmail({ ...newEmail, status: e.target.value })}
                    >
                      <option value="Active">Active</option>
                      <option value="DeActive">DeActive</option>
                      <option value="Completed">Completed</option>
                      <option value="Ongoing">Ongoing</option>
                    </select>
                  </div>
                  <button type="submit" className="i-btn success--btn btn--md">Add Email</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmailsTemplate;
