import React, { useEffect, useState } from 'react';
import { Button, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import AddBranchModal from "./Components/AddBranchModal"; // Adjust path as needed
import { getcompanydata, getbranchdata } from "../../services/apiService";

const BranchList = () => {
    const [branches, setBranches] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [showAddModal, setShowAddModal] = useState(false); // Modal visibility
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Items per page
    const [filteredBranches, setFilteredBranches] = useState([]);
    
    const navigate = useNavigate(); // Initialize navigate

    // Fetching company and branch data
    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            const newcompanydata = await getcompanydata(token);
            setCompanyData(newcompanydata);
            const allBranchesData = await getbranchdata(newcompanydata.id);
            setBranches(allBranchesData.data);
            setFilteredBranches(allBranchesData.data);
        };
        fetchData();
    }, []);

    // Calculate total pages
    const totalPages = Math.ceil(filteredBranches.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    // Open AddBranchModal
    const handleAddBranch = () => {
        setShowAddModal(true);
    };

    // Close AddBranchModal
    const handleCloseModal = () => {
        setShowAddModal(false);
    };

    const handleEditClick = (branch) => {
        navigate('/branches/edit', { state: { branch } }); // Navigate to edit page
    };

    // Get current branches for the page
    const currentBranches = filteredBranches.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <div className="dashboard_container">
            <div className="inner-content">
                <section>
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h4 className="card-title">Branch List</h4>
                            <Button className="btn btn-primary" onClick={handleAddBranch}>Add New Branch</Button>
                        </div>

                        <div className="card-body px-0">
                            <div className="responsive-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Branch Name</th>
                                            <th>City</th>
                                            <th>No. Staff</th>
                                            <th>Start Time</th>
                                            <th>Close Time</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentBranches.map((branch, index) => (
                                            <tr key={branch.id}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{branch.name}</td>
                                                <td>{branch.city}</td>
                                                <td>{branch.staffCount}</td>
                                                <td>{branch.startTime}</td>
                                                <td>{branch.closeTime}</td>
                                                <td style={{gap: '10px',display: 'flex'}}>
                                                    <Button variant="primary" onClick={() => handleEditClick(branch)} >Edit</Button>
                                                    <Button variant="danger">Delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination Controls */}
                            

                            <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Button
                                        key={index + 1}
                                        variant="outline-primary"
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{ margin: '0 5px' }}
                                        active={currentPage === index + 1}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Add Branch Modal */}
                <AddBranchModal
                    show={showAddModal}
                    handleClose={handleCloseModal}
                    companyId={companyData.id} // Pass companyId
                />
            </div>
        </div>
    );
};

export default BranchList;
