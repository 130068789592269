import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ConnectDatabasecheck } from "../../../services/apiService";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons for visibility toggle
import { oneclick } from "../../../oneclick";
import { getuserdata } from "../../../services/apiService";
import database_id from "../../../utils/databaseConfig";

const ConnectDatabase = ({ show, handleClose }) => {
    const [dbDetails, setDbDetails] = useState({
        ip: "",
        username: "",
        password: "",
        dbName: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [systemIp, setSystemIp] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

    const handlePasswordToggle = () => {
        setPasswordVisible(!passwordVisible); // Toggle the password visibility
    };

    // Fetch system's public IP using a public API (or server-side if you prefer)
    useEffect(() => {
        fetch("https://api.ipify.org?format=json")
            .then((response) => response.json())
            .then((data) => {
                setSystemIp(data.ip); // Save the IP address
            })
            .catch((error) => {
                console.error("Error fetching IP:", error);
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDbDetails({ ...dbDetails, [name]: value });
    };

    const handleSubmit = async () => {
        // Check if required fields are filled based on IP address
        if (
            (!dbDetails.ip || !dbDetails.username || !dbDetails.dbName) || 
            (dbDetails.ip !== "localhost" && dbDetails.ip !== "127.0.0.1" && !dbDetails.password)
        ) {
            alert("Please fill in all fields.");
            return;
        }
    
        setIsLoading(true);
    
        try {
            // Check database connection
            const checkResponse = await ConnectDatabasecheck(
                dbDetails.ip, 
                dbDetails.username, 
                dbDetails.password, 
                dbDetails.dbName
            );
    
            if (checkResponse.success) {
                console.log("Database Connection Details:", dbDetails);
    
                // Get user and company details for database insertion
                const userId = await getuserdata("id");
                const companyId = await getuserdata("company_id");
    
                const tableSchema = database_id;
                const db_users = 'db_users';
                const db_databases = 'db_databases';
    
                // Data to update user
                const userData = {
                    user_id: userId,
                    company_id: companyId,
                    db_username: dbDetails.username,
                    password: dbDetails.password,
                    status: 'active',
                };
    
                // Update user
                const updateUser = await oneclick.create(tableSchema, db_users, userData, "");
    
                if (updateUser.success) {
                    const dbData = {
                        user_id: userId,
                        company_id: companyId,
                        dbuser_id: updateUser.insertedData.id,
                        db_name: dbDetails.dbName,
                        status: 'active',
                        server: dbDetails.ip,
                    };
    
                    // Update database connection data
                    const updateDb = await oneclick.create(tableSchema, db_databases, dbData, "");
    
                    if (updateDb.success) {
                        setTimeout(() => {
                            setIsLoading(false);
                            handleClose();
                        }, 1000);
                    } else {
                        alert(`Failed to update Db Data: ${updateDb.message}`);
                        setIsLoading(false);
                    }
                } else {
                    alert(`Failed to update user: ${updateUser.message}`);
                    setIsLoading(false);
                }
            } else {
                alert(`Failed to connect to the database: ${checkResponse.message}`);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error during submission:", error);
            alert("An error occurred while processing your request. Please try again.");
            setIsLoading(false);
        }
    };
    

    // Check if the entered IP is localhost or local IP
    const isLocalIp = dbDetails.ip === "localhost" || dbDetails.ip === "127.0.0.1";

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Connect to Database</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formIpAddress">
                        <Form.Label>IP Address</Form.Label>
                        <Form.Control
                            type="text"
                            name="ip"
                            value={dbDetails.ip}
                            onChange={handleInputChange}
                            placeholder="Enter IP Address"
                        />
                        {/* Conditionally render the message */}
                        {!isLocalIp && (
                            <Form.Text className="text-muted">
                                Give Me Remote Database Access: {systemIp}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group controlId="formUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            name="username"
                            value={dbDetails.username}
                            onChange={handleInputChange}
                            placeholder="Enter Username"
                        />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <div className="d-flex">
                            <Form.Control
                                type={passwordVisible ? "text" : "password"} // Toggle password visibility
                                name="password"
                                value={dbDetails.password}
                                onChange={handleInputChange}
                                placeholder="Enter Password"
                            />
                            <Button
                                variant="link"
                                class="form-control"
                                onClick={handlePasswordToggle}
                                style={{ position: "unset", right: "15px", top: "35px", width: "53px" }}
                            >
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />} {/* Toggle icon */}
                            </Button>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formDatabaseName">
                        <Form.Label>Database Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="dbName"
                            value={dbDetails.dbName}
                            onChange={handleInputChange}
                            placeholder="Enter Database Name"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? "Connecting..." : "Connect"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConnectDatabase;
