import React from "react";
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { changeDatabaseUserPassword } from "../../../services/apiService";
import { deleteUser } from "../../../services/apiService";

const UsersTable = ({ currentData, setUserData, passwordVisibility, togglePasswordVisibility, Link, totalPages, handlePageChange, currentPage, loading }) => {


    console.log('DatabaseTable currentData:', currentData);
    const server_ip = process.env.REACT_APP_WHM_IP;

    const handleChangePassword = (id, db_username) => {
        // Open a modal for password change or handle logic directly here.
        const newPassword = prompt(`Enter a new password for user ${db_username}`);
        if (newPassword) {
            // API call or logic to update the password
            updatePassword(id, newPassword, db_username);
        }
    };

    const updatePassword = async (id, newPassword, db_username) => {
        try {
            const response = await changeDatabaseUserPassword(newPassword, db_username, id,); // Replace with your API call
            if (response.success) {
                alert('Password updated successfully!');
            } else {
                console.error('Failed to update password:', response.message);
                alert('Failed to update the password.');
            }
        } catch (error) {
            console.error('Error updating password:', error);
            alert('An error occurred while updating the password.');
        }
    };


    const handleDelete = async (id, db_username) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                const response = await deleteUser(db_username, id); // Replace with your API call
                if (response.success) {
                    alert('Item deleted successfully!');
                    // Refresh the list or remove the item from state
                    setUserData(currentData.filter(item => item.id !== id));
                } else {
                    console.error('Failed to delete item:', response.message);
                    alert('Failed to delete the item.');
                }
            } catch (error) {
                console.error('Error deleting item:', error);
                alert('An error occurred while deleting the item.');
            }
        }
    };

    return (
        <div className="card-body px-0">
            <div className="responsive-table">
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>UserName</th>
                            <th>Server Ip</th>
                            <th>Status</th>
                            <th>Password</th>
                            <th style={{ textAlign: 'right' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading == true ? (
                            <tr>
                                <td colSpan="8" style={{ textAlign: 'center' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p>Loading data, please wait...</p>
                                </td>
                            </tr>
                        ) : currentData && currentData.length > 0 ? (
                            currentData.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.db_username}</td>
                                    <td>{item.server}</td>
                                    <td>
                                        <div
                                            className={`status-indicator ${item.status === 'active' ? 'active' : 'inactive'} blinking`}
                                            title={item.status === 'active' ? 'Server is active' : 'Server is inactive'}
                                        ></div>
                                    </td>
                                    {/* Password with view/hide toggle */}
                                    <td>
                                        {item.password ? (
                                            passwordVisibility[item.id] ? (
                                                item.password
                                            ) : (
                                                '••••••••'
                                            )
                                        ) : (
                                            'No Password'
                                        )}

                                        {item.password ? (
                                            <button
                                                onClick={() => togglePasswordVisibility(item.id)}
                                                style={{ marginLeft: '10px', cursor: 'pointer', border: 'none', background: 'none' }}
                                            >
                                                {passwordVisibility[item.id] ? (
                                                    <i className="fas fa-eye-slash"></i>
                                                ) : (
                                                    <i className="fas fa-eye"></i>
                                                )}
                                            </button>
                                        ) : (
                                            <></>
                                        )}
                                    </td>

                                    <td style={{ textAlign: 'right' }}>
                                        {/* Change password button */}
                                        <button
                                            className="btn btn-warning"
                                            style={{ height: '38px', marginRight: '10px' }}
                                            onClick={() => handleChangePassword(item.id, item.db_username)}
                                        >
                                            <i className="fas fa-key"></i>
                                        </button>
                                        <button className="btn btn-danger" style={{ height: '38px' }} onClick={() => handleDelete(item.id, item.db_username)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <h2>No databases available</h2>
                        )}
                    </tbody>


                </table>

                <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            variant="outline-primary"
                            onClick={() => handlePageChange(index + 1)}
                            style={{ margin: '0 5px' }}
                            active={currentPage === index + 1}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </div>
            </div>
            <div className="m-3"></div>
        </div>
    );
};

export default UsersTable