import React, { useState } from 'react';
import { createCurrency } from '../../../services/apiService';

const AddCurrencyModal = ({ generaldata, closeModal, isOpen }) => {
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [rate, setRate] = useState('');
  const [status, setStatus] = useState('1');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call the createCurrency function with the form data
      const response = await createCurrency(symbol, name, symbol, rate, status);
      
      if (response) {
        alert('Currency added successfully!');
        closeModal(); // Close modal on success
        // Optionally, you can call a prop function to refresh the currency list in the parent component
      }
    } catch (error) {
      console.error('Failed to add currency:', error);
      alert('Error adding currency. Please try again.');
    }
  };

  return (
    <div className={`modal fade ${isOpen ? 'show' : ''}`} id="createNewCurrency" tabIndex="-1" role="dialog" aria-modal="true" style={{ display: isOpen ? 'block' : 'none' , background: '#00000078' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="card">
                <div className="card-header bg--lite--violet">
                  <div className="card-title text-center text--light">Add New Currency</div>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name <sup className="text--danger">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Name"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="symbol" className="form-label">
                      Symbol <sup className="text--danger">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="symbol"
                      value={symbol}
                      onChange={(e) => setSymbol(e.target.value)}
                      placeholder="Enter Symbol"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="rate" className="form-label">
                      Exchange Rate <sup className="text--danger">*</sup>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">1 {generaldata.currency_name} =</span>
                      <input
                        type="text"
                        className="form-control"
                        id="rate"
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                        placeholder="0.00"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Status <sup className="text--danger">*</sup>
                    </label>
                    <select
                      className="form-control"
                      id="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      required
                    >
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="i-btn primary--btn btn--md" onClick={closeModal}>
                Cancel
              </button>
              <button type="submit" className="i-btn success--btn btn--md">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCurrencyModal;
