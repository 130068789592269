import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getuserdata } from '../../services/apiService'; // Ensure the API method supports FormData
import { oneclick } from '../../oneclick'; // Adjust import for your oneclick API service
import database_id from '../../utils/databaseConfig';

const CreateFolderModal = ({ showModal, handleClose, handleCreateFolder, path, folderId, handlePageChange }) => {
  const [folderName, setFolderName] = useState('');
  const [folderRoot, setFolderRoot] = useState('');
  const [currentFolderIddata, setCurrentFolderIddata] = useState({});
  const [currentFolderId, setCurrentFolderId] = useState('');


  // Assuming `path` is derived from currentPath or passed as a prop
  const pathString = Array.isArray(path) && path.length > 0
    ? ['Home', ...path.map(item => item.fileName)].join(' / ')  // Extract fileName from each object
    : 'Home';

  // Update folderRoot and currentFolderId whenever path or folderId changes
  useEffect(() => {
    getfolderdata();
    setCurrentFolderId(folderId);
  }, [pathString, folderId]);

  const getfolderdata = async () => {

    const api_key = await getuserdata("api_key");  // Assuming getuserdata is defined to fetch user info

    const tableSchema = database_id;
    const tableName = "cloud_storage";
    const companyId = await getuserdata("company_id");

    const conditions = {
      id: folderId,
      company_id: companyId,
    };

    const orderBy = "id DESC";
    const pagination = { page: 1 || 1, limit: 10 };

    const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, "", pagination, "");
    if (response.success) {
      setCurrentFolderIddata(response.data[0]);
      setFolderRoot(response.data[0].root + '/' + response.data[0].file_name);
    } 
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitting form with folderName:', folderName, 'and folderRoot:', folderRoot);

    // Call the function to create the folder with folderName and folderId
    handleCreateFolder(folderName, currentFolderId, folderRoot, uuidv4()); // Pass the currentFolderId instead of folderRoot

    handlePageChange(); // Navigate to the new folder after creation
    // Reset folder name after creation
    setFolderName('');
    handleClose();
  };

  return (
    <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" aria-labelledby="createFolderModalLabel" aria-hidden={!showModal} style={{ display: showModal ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="createFolderModalLabel">{folderId ? "Create Folder" : "Create Bucket"}</h5>
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="folder_name" className="form-label">{folderId ? "Folder Name" : "Bucket Name"}</label>
                <input
                  type="text"
                  className="form-control"
                  id="folder_name"
                  name="folder_name"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  required
                />
                <label htmlFor="folder_root" className="form-label">Current Path:</label>
                <input
                  type="text"
                  className="form-control"
                  id="folder_root"
                  name="folder_root"
                  value={folderRoot}
                  readOnly
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
              <button type="submit" className="btn btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;
