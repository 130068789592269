import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import StripePayment from './StripePayment';
import PayPalPayment from './PayPalPayment';
import RazorpayPayment from './RazorpayPayment';
import PhonePePayment from './PhonePePayment';

const PaymentGateway = ({ isModalOpen, closeModal, paymentDetails, onPaymentStatus, onPaymentdata }) => {
    const [selectedGateway, setSelectedGateway] = useState(null);

    const paymentOptions = [
        { id: 'stripe', label: 'Stripe', icon: 'https://img.icons8.com/color/48/000000/stripe.png' },
        { id: 'paypal', label: 'PayPal', icon: 'https://img.icons8.com/color/48/000000/paypal.png' },
        { id: 'razorpay', label: 'Razorpay', icon: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/razorpay-icon.png' },
        { id: 'phonepe', label: 'PhonePe', icon: 'https://i.pinimg.com/originals/db/42/53/db4253052cfc0f80ac281486c19f9d57.png' }
    ];

    const handleGatewaySelection = (gatewayId) => {
        setSelectedGateway(gatewayId);
    };

    const handlePaymentCallback = (success, response) => {
        onPaymentStatus(success);  // Update payment status in parent component
        onPaymentdata(response);    // Send payment data to parent component
        closeModal();               // Close modal after payment
        setSelectedGateway(null);   // Reset selected gateway
    };

    const renderPaymentComponent = () => {
        switch (selectedGateway) {
            case 'stripe':
                return <StripePayment amount={paymentDetails.amount} onPaymentCallback={handlePaymentCallback} />;
            case 'paypal':
                return <PayPalPayment amount={paymentDetails.amount} onPaymentCallback={handlePaymentCallback} />;
            case 'razorpay':
                return (
                    <RazorpayPayment
                        paymentDetails={paymentDetails}
                        onPaymentCallback={handlePaymentCallback}
                    />
                );
            case 'phonepe':
                return <PhonePePayment amount={paymentDetails.amount} onPaymentCallback={handlePaymentCallback} />;
            default:
                return null;
        }
    };

    return (
        <div>
            <Modal show={isModalOpen} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select a Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: '20px',
                            justifyItems: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {paymentOptions.map((option) => (
                            <div
                                key={option.id}
                                onClick={() => handleGatewaySelection(option.id)}
                                style={{ textAlign: 'center', cursor: 'pointer' }}
                            >
                                <img
                                    src={option.icon}
                                    alt={option.label}
                                    style={{ width: '60px', height: '60px' }}
                                />
                                <p>{option.label}</p>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Render payment component after selection */}
            {selectedGateway && <div style={{ marginTop: '20px' }}>{renderPaymentComponent()}</div>}
        </div>
    );
};

export default PaymentGateway;
