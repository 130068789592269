import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const AddPermissionsModal = ({ show, handleClose, companyId }) => {
    const generateTimestampId = () => {
        return new Date().getTime().toString(); // Generate a unique timestamp
    };

    const [newRole, setNewRole] = useState({
        permissions: [
            { id: generateTimestampId(), subName: '', permissions: [{ id: generateTimestampId(), name: '' }] }
        ]
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewRole((prev) => ({ ...prev, [name]: value }));
    };

    // Handle change for dynamic subName inputs
    const handleSubNameChange = (index, event) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[index].subName = event.target.value;
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    // Handle change for subName's permissions
    const handleChildPermissionChange = (permIndex, childIndex, event) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[permIndex].permissions[childIndex].name = event.target.value;
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    // Add new subName
    const handleAddPermissionSubName = () => {
        setNewRole((prev) => ({
            ...prev,
            permissions: [...prev.permissions, { id: generateTimestampId(), subName: '', permissions: [{ id: generateTimestampId(), name: '' }] }]
        }));
    };

    // Add new permission under a specific subName
    const handleAddChildPermission = (permIndex) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[permIndex].permissions.push({ id: generateTimestampId(), name: '' });
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    // Remove permission subName
    const handleRemovePermissionSubName = (index) => {
        const updatedPermissions = newRole.permissions.filter((_, i) => i !== index);
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    // Remove child permission
    const handleRemoveChildPermission = (permIndex, childIndex) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[permIndex].permissions = updatedPermissions[permIndex].permissions.filter((_, i) => i !== childIndex);
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handleSubmit = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "permissions";

            // Format data to match the desired structure
            const formattedPermissions = newRole.permissions.map((permission) => ({
                id: permission.id, // Use the timestamp ID generated earlier
                subName: permission.subName,
                permissions: permission.permissions.map((child) => ({
                    id: child.id, // Use the timestamp ID generated earlier
                    name: child.name
                }))
            }));

            const data = {
                company_id: companyId,
                name: newRole.permissionsName,
                type: newRole.permissionsNametype,
                permissions: JSON.stringify(formattedPermissions) // Send the permissions array
            };

            const update = await oneclick.create(tableSchema, tableName, data);
            if (update.success) {
                alert(update.message || 'Permissions added successfully.');
                handleClose(); // Close the modal on successful submit
                resetForm(); // Reset form data
            } else {
                console.error("Error updating permissions:", update.error);
                alert(update.error || 'Error updating permissions.');
            }
        } catch (error) {
            console.error('Error adding permissions:', error);
        }
    };

    // Function to reset form data
    const resetForm = () => {
        setNewRole({ permissions: [{ id: generateTimestampId(), subName: '', permissions: [{ id: generateTimestampId(), name: '' }] }] }); // Reset to initial state
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Permissions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Permissions Name (was Role Name) */}
                    <Form.Group controlId="permissionsName">
                        <Form.Label>Permissions Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="permissionsName"
                            value={newRole.permissionsName}
                            onChange={handleChange}
                            required
                        />
                        <Form.Label>Permissions Type</Form.Label>
                        <Form.Select
                            name="permissionsNametype"
                            value={newRole.permissionsNametype}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Permission</option> {/* Default empty option */}
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </Form.Select>
                    </Form.Group>
                    {/* Dynamic Permission Inputs with Sub Names and Child Permissions */}
                    {newRole.permissions.map((permission, permIndex) => (
                        <div key={permIndex} style={{ marginBottom: '20px' }}>
                            <hr />
                            <Form.Label>Permissions Sub Name</Form.Label>
                            <div className="d-flex align-items-center mb-2" style={{ gap: '10px' }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter permission sub name"
                                    value={permission.subName}
                                    onChange={(e) => handleSubNameChange(permIndex, e)}
                                />
                                {newRole.permissions.length > 1 && (
                                    <Button
                                        variant="danger"
                                        className="ml-2"
                                        onClick={() => handleRemovePermissionSubName(permIndex)}
                                    >
                                        <i className="fa fa-close"></i>
                                    </Button>
                                )}
                            </div>

                            <Form.Label>Child Permissions</Form.Label>
                            {permission.permissions.map((child, childIndex) => (
                                <div key={childIndex} className="d-flex align-items-center mb-2" style={{ gap: '10px' }}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter child permission"
                                        value={child.name}
                                        onChange={(e) => handleChildPermissionChange(permIndex, childIndex, e)}
                                    />
                                    {permission.permissions.length > 1 && (
                                        <Button
                                            variant="danger"
                                            className="ml-2"
                                            onClick={() => handleRemoveChildPermission(permIndex, childIndex)}
                                        >
                                            <i className="fa fa-close"></i>
                                        </Button>
                                    )}
                                </div>
                            ))}
                            <Button variant="success" className="mt-2" onClick={() => handleAddChildPermission(permIndex)}>
                                + Add Child Permission
                            </Button>
                        </div>
                    ))}

                    <Button variant="success" className="mt-2" onClick={handleAddPermissionSubName}>
                        + Add Permission Sub Name
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { resetForm(); handleClose(); }}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Add Permissions
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddPermissionsModal;
