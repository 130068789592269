import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import { sendEmail, general_settings } from '../../../services/apiService';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const EmailOtpInput = ({ onHide, selectedWebsite }) => {
    const [otpValue, setOtpValue] = useState('');
    const cleanWebsite = selectedWebsite.website.replace(/^https?:\/\//, '');
    const [selectedEmail, setSelectedEmail] = useState(`admin@${cleanWebsite}`);
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resendOtp, setResendOtp] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [settings, setSettings] = useState({});
    const [error, setError] = useState('');
    const [otpVerified, setOtpVerified] = useState(false);
    const [timeLeft, setTimeLeft] = useState(600); // 10-minute timer (in seconds)
    const [canResendOtp, setCanResendOtp] = useState(false); // State for enabling resend OTP
    const timerRef = useRef(null);

    const [smtpConfig, setSmtpConfig] = useState({
        host: '',
        port: '',
        secure: false,
        auth: {
            user: '',
            pass: '',
        },
    });

    // Fetch general settings (company data, including SMTP config)
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await general_settings();
                const companySettings = response[0] || {};
                setSettings(companySettings);
                setCompanyData(companySettings);
            } catch (err) {
                console.error('Error fetching settings:', err);
            }
        };
        fetchSettings();
    }, []);

    // Update smtpConfig when companyData is fetched
    useEffect(() => {
        if (companyData && companyData.smtp_config) {
            try {
                const parsedConfig = JSON.parse(companyData.smtp_config);
                setSmtpConfig({
                    host: parsedConfig.host || '',
                    port: parsedConfig.port || '',
                    secure: parsedConfig.secure || false,
                    auth: {
                        user: parsedConfig.auth?.user || '',
                        pass: parsedConfig.auth?.pass || '',
                    },
                });
            } catch (error) {
                console.error('Error parsing smtp_config:', error);
            }
        }
    }, [companyData]);

    const allowedDomains = [
        `admin@${cleanWebsite}`,
        `support@${cleanWebsite}`,
        `info@${cleanWebsite}`,
    ];

    const handleSendOtp = async () => {
        setLoading(true);
        setError('');
        try {
            const otp = generateOtp();
            const emailSubject = 'Your One-Time Password (OTP)';
            const emailBody = `
                Hello,
                Your OTP for completing your action is: ${otp}.
                This OTP is valid for the next 10 minutes. If you did not request this, please ignore this email.
                Best regards,
                OneKlicks
            `;

            const response = await sendEmail(smtpConfig, selectedEmail, emailSubject, emailBody);
            if (response.success) {
                const tableSchema = database_id;
                const data = { 
                    otp: otp,
                };
                const condition = { id: selectedWebsite.id };

                await oneclick.update(tableSchema, "seowebsites", data, condition);
                setOtpSent(true);
                setResendOtp(false);
                setCanResendOtp(false); // Disable Resend OTP initially
                setTimeLeft(600); // Reset timer for 10 minutes
            } else {
                setError('Failed to send OTP. Try again.');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            setError('Error sending OTP. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        setResendOtp(true);
        await handleSendOtp();
    };

    const verifyOtp = async () => {
        // Assuming the database verification logic goes here
        const tableSchema = database_id;
        const data = { status: "active" };
        const condition = { id: selectedWebsite.id, otp: otpValue };

        console.log('Updating database with condition:', condition);
        const updatedata = await oneclick.update(tableSchema, "seowebsites", data, condition);
        console.log('Update response:', updatedata);

        if (updatedata.success) {
            setOtpVerified(true);
            setError('');
            setCanResendOtp(false); // Disable resend OTP after successful verification
            onHide(); // Close the modal after successful verification
        } else {
            setError('Verification failed. Could not OTP.');
            console.error('Database update failed, no data returned:', updatedata);
        }
    };

    const generateOtp = () => {
        return Math.floor(100000 + Math.random() * 900000).toString();
    };

    // Timer for OTP expiration
    useEffect(() => {
        if (otpSent && timeLeft > 0) {
            timerRef.current = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev <= 1) {
                        clearInterval(timerRef.current);
                        setCanResendOtp(true); // Enable Resend OTP when time is up
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } else if (timeLeft <= 0) {
            clearInterval(timerRef.current);
            setOtpSent(false);
            setError('OTP expired. Please request a new one.');
            setCanResendOtp(true); // Enable Resend OTP when time is up
        }
        return () => clearInterval(timerRef.current);
    }, [otpSent, timeLeft]);

    return (
        <Form.Group controlId="otpInput">
            <Form.Label>Email OTP</Form.Label>
            {error && <Alert variant="danger">{error}</Alert>}
            {otpVerified && <Alert variant="success">OTP verified successfully!</Alert>}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Form.Control
                    as="select"
                    value={selectedEmail}
                    onChange={(e) => setSelectedEmail(e.target.value)}
                    required
                    style={{ flex: 1, marginRight: '10px' }}
                >
                    {allowedDomains.map((domain, index) => (
                        <option key={index} value={domain}>
                            {domain}
                        </option>
                    ))}
                </Form.Control>
                <Button variant="primary" onClick={handleSendOtp} disabled={loading || otpSent}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Send OTP'}
                </Button>
            </div>

            {otpSent && (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <Form.Control
                            type="text"
                            value={otpValue}
                            onChange={(e) => setOtpValue(e.target.value)}
                            placeholder="Enter OTP"
                            required
                            style={{ flex: 2 }}
                            disabled={otpVerified || timeLeft <= 0}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: '10px', flexDirection: 'row-reverse' }}>
                        <Button variant="secondary" onClick={verifyOtp} className="btn--primary" disabled={otpVerified}>
                            Verify OTP
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={handleResendOtp}
                            className="btn--primary"
                            disabled={!canResendOtp || otpVerified} // Enable button based on canResendOtp state
                        >
                            {resendOtp ? 'Resending...' : 'Resend OTP'}
                        </Button>
                    </div>

                    <p>
                        {timeLeft > 0
                            ? `OTP expires in ${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' : ''}${timeLeft % 60}`
                            : 'OTP expired'}
                    </p>
                </>
            )}
        </Form.Group>
    );
};

export default EmailOtpInput;
