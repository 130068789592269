import React, { useState, useEffect } from "react";
import { oneclick } from "../../oneclick";
import GatewayInfo from './SmsComponents/GatewayInfo';
import GatewayDelete from './SmsComponents/GatewayDelete';
import Editgateway from './SmsComponents/Editgateway';
import AddGateway from './SmsComponents/AddGateway';
import database_id from "../../utils/databaseConfig";

const SmsGateways = ({ }) => {
    const [credentials, setcredentials] = useState([]);
    const [showaddsmsgeteway, setshowaddsmsgeteway] = useState(false);

    const gaetdata = async () => {
        try {
            const tableSchema = database_id;
            const tableName = 'sms_gateways';
            const orderBy = "id ASC";
            const pagination = { page: 1, limit: 200 };
            const response = await oneclick.read(tableSchema, tableName, "", orderBy, "", pagination, "");
            if (response.success) {
                setcredentials(response.data); // Update state with data array
            } else {
                console.error("Failed to fetch credentials:", response.message);
            }
        } catch (error) {
            console.error("Error fetching credentials:", error);
        }
    };

    useEffect(() => {
        gaetdata(); // Fetch data on mount
    }, []); // Empty array to avoid re-fetching unnecessarily

    useEffect(() => {
        console.log("Updated credentials:", credentials); // Log when `credentials` changes
    }, [credentials]);

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="row gy-4">
                        {/* Sidebar */}
                        <div className="col-lg-auto">
                            <div className="vertical-tab card sticky-item">
                                <div
                                    className="nav flex-column nav-pills gap-2"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    <a
                                        className="nav-link active"
                                        href="http://localhost/getoneclick/admin/sms/gateways/sms-api"
                                    >
                                        SMS API Gateway
                                        <span><i className="las la-angle-right"></i></span>
                                    </a>
                                    <a
                                        className="nav-link"
                                        href="http://localhost/getoneclick/admin/sms/gateways/android"
                                    >
                                        Android Gateway
                                        <span><i className="las la-angle-right"></i></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Main Content */}
                        <div className="col">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">SMS Gateway List</h4>
                                    <a
                                        href="#" onClick={() => setshowaddsmsgeteway(true)}
                                        className="i-btn primary--btn btn--md text-white"
                                        title="Add New Gateway"
                                    >
                                        <i className="fa-solid fa-plus"></i> Add New
                                    </a>
                                </div>
                                <div className="card-body px-0">
                                    <div className="responsive-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Gateway Name</th>
                                                    <th>Gateway type</th>
                                                    <th>Default</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-label="Gateway Name">
                                                        <span className="text-dark">Demo SMS Gateway</span>
                                                    </td>
                                                    <td data-label="Gateway type">
                                                        <span className="text-dark">NEXMO</span>
                                                    </td>
                                                    <td className="text-center" data-label="Default">
                                                        <div className="d-flex justify-content-md-start justify-content-end">
                                                            <i className="las la-check-double text--success" style={{ fontSize: '32px' }}></i>
                                                        </div>
                                                    </td>
                                                    <td data-label="Status">
                                                        <span className="badge badge--success">Active</span>
                                                    </td>
                                                    <td data-label="Action">
                                                        <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                                                            <a
                                                                className="i-btn info--btn btn--sm gateway-details"
                                                                data-sms_credentials='{"api_key":"demo_api_key","sender_id":"demo_sender_id","api_secret":"demo_api_secret"}'
                                                                data-bs-placement="top"
                                                                title="Gateway Information"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#gatewayInfo"
                                                            >
                                                                <i className="las la-info-circle"></i>
                                                            </a>
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="i-btn success--btn btn--sm edit-gateway"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editgateway"
                                                                data-id="11"
                                                                data-gateway_type="101NEXMO"
                                                                data-gateway_name="Demo SMS Gateway"
                                                                data-gateway_credentials='{"api_key":"demo_api_key","sender_id":"demo_sender_id","api_secret":"demo_api_secret"}'
                                                                data-gateway_status="1"
                                                            >
                                                                <i className="las la-pen"></i>
                                                            </a>
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="i-btn danger--btn btn--sm delete"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#gatewayDelete"
                                                                data-delete_id="11"
                                                            >
                                                                <i className="las la-trash"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="m-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modals  */}
            <GatewayInfo />

            <GatewayDelete />

            <Editgateway />

            {showaddsmsgeteway && (
                <AddGateway credentials={credentials} onClose={() => setshowaddsmsgeteway(false)} />
            )}


        </div>
    );
}

export default SmsGateways;
