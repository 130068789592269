import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { oneclick } from "../../../oneclick";
import { getuserdata, checkSubdomain, checkdomain, create_subdomain, addDomain } from "../../../services/apiService";
import database_id from "../../../utils/databaseConfig";

const AddingNewApplication = ({ setShowaddnewapp, getdata, appData, edit }) => {
    const [newApp, setNewApp] = useState({
        appName: appData?.appName || '', // Use appData if available, else default to empty string
        uid: appData?.uid || '',
        type: appData?.type || '',
        description: appData?.description || '',
        status: appData?.status || '',
        subdomain: appData?.domain || '',
        otherTechnology: appData?.otherTechnology || '',
    });

    const [readOnly, setReadOnly] = useState(false);
    
    useEffect(() => {
        if (edit === "edit") {
            setReadOnly(false);
        } else if (edit === "view") {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
    }, [edit]); // Runs when 'edit' changes
    
    const [loging, setloding] = useState(false);
    const [webTechnology, setWebTechnology] = useState(appData?.type || '');
    const [otherTechnology, setOtherTechnology] = useState('');
    const [availability, setAvailability] = useState(null); // null: no check yet, true: available, false: unavailable
    const [fulldomain, setFulldomain] = useState(null); // null: no check yet, true: available, false: unavailable
    const [message, setMessage] = useState(null); // null: no check yet, true: available, false: unavailable
    const [domaintype, setDomaintype] = useState("");

    const webTechnologies = ['Ecommerce', 'Blogs', 'Professional Web', 'Other'];

    const handleAddApp = async (e) => {
        e.preventDefault();
        setloding(true);


        if (domaintype == "add_domain") {
            const response = await addDomain(newApp.subdomain);
            if (response.success) {

            }
        } else if (domaintype == "subdomain") {
            const subdomain = newApp.subdomain;
            const rootdomain = "oneklicks.com";
            const response = await create_subdomain(subdomain, rootdomain);
            if (response.success) {
            }
        } else {

        }

        // Add application logic, such as sending data to API or updating state.
        console.log(newApp);
        const tableSchema = database_id;
        const tableName = "liveapplications";
        const data = {
            user_id: await getuserdata("id"),
            company_id: await getuserdata("company_id"),
            uid: uuidv4(),
            appName: newApp.appName,
            type: newApp.type,
            description: newApp.description,
            othertype: newApp.otherTechnology,
            domain: fulldomain,
            status: newApp.status
        };


        const update = await oneclick.create(tableSchema, tableName, data, "");

        if (update.success) {
            // Close the modal
            alert(update.message);
            setShowaddnewapp(false);
            setloding(false);
            getdata();
        } else {
            alert(update.message);
            setloding(false);
        }
    };

    const handleInputChange = async (e) => {
        const { name, value } = e.target;

        // Ensure the subdomain value is always in lowercase
        const lowerCaseValue = name === "subdomain" ? value.toLowerCase() : value;

        setNewApp((prev) => ({
            ...prev,
            [name]: lowerCaseValue,
        }));



        if (name === "subdomain" && value.trim() !== "") {

            // Check if the input contains a dot (indicating it's a full domain, not just a subdomain)
            if (value.trim().includes('.')) {
                setDomaintype("add_domain");
                // If it's a full domain like ravindra.com, ravindra.in, etc.
                console.log("Full domain detected:", value.trim());

                const response = await checkdomain(value.trim());

                console.log('response :', response);

                if (response.success == true) {
                    console.log('checkdomain true:', response.success);
                    setAvailability(response.success); // Use response.success
                    setMessage("This Domain Already Exists in Our System.");
                } else {
                    console.log('checkdomain false:', response.success);
                    setFulldomain(value.trim());
                    setAvailability(response.success); // Use response.success
                    setMessage("Please add the IP 178.212.35.129 and DNS A record for the domain.");
                }

                // Set the message to instruct the user to add DNS records for a full domain

            } else {
                setDomaintype("subdomain");
                // Proceed with subdomain checking if it's not a full domain
                setMessage(null);
                const check = await checkSubdomain(value.trim());
                if (check) {
                    setAvailability(check.success);
                    setFulldomain(check.fullDomain);

                    console.log('setAvailability:', check.success);
                }
            }
        }
    };


    const handleTechnologyChange = (e) => {
        const value = e.target.value;
        setWebTechnology(value);
        setNewApp((prev) => ({
            ...prev,
            type: value,
        }));
    };

    return (
        <div className="modal fade show" style={{ display: 'block', background: '#00000078' }} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleAddApp}>
                        <div className="modal-header">
                            {readOnly == true ? (
                                <h5 className="modal-title">{newApp.appName}</h5>
                            ) : (
                                <h5 className="modal-title">Add New Application</h5>
                            )}
                            
                            <button type="button" className="btn-close" onClick={() => setShowaddnewapp(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="appName" className="form-label">Application Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="appName"
                                    name="appName"
                                    readOnly={readOnly}
                                    value={newApp.appName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            {readOnly && (
                                <div className="mb-3">
                                    <label htmlFor="appName" className="form-label">Application UID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="appName"
                                        name="appName"
                                        readOnly={readOnly}
                                        value={newApp.uid}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            )}


                            <div className="mb-3">
                                <label htmlFor="subdomain" className="form-label">
                                    Select Subdomain & Domain <span style={{ fontWeight: "normal", color: "#6c757d" }}>(optional)</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subdomain"
                                    name="subdomain"
                                    readOnly={readOnly}
                                    value={newApp.subdomain}
                                    onChange={handleInputChange}
                                />

                                {message !== null ? (
                                    <>
                                        <span>{message}</span>
                                    </>
                                ) : (
                                    <>
                                        {availability == true && (
                                            <>
                                                <span>{fulldomain}</span>
                                                <br></br>
                                                <small style={{ color: "green" }}>Subdomain is available!</small>
                                            </>
                                        )}
                                        {availability == false && (
                                            <small style={{ color: "red" }}>Subdomain is already taken.</small>
                                        )}

                                    </>
                                )}
                            </div>


                            <div className="mb-3">
                                <label htmlFor="type" className="form-label">Application Type</label>
                                <select
                                    className="form-control"
                                    id="type"
                                    name="type"
                                    readOnly={readOnly}
                                    value={webTechnology}
                                    onChange={handleTechnologyChange}
                                    required
                                >
                                    <option value="">Select Type</option>
                                    {webTechnologies.map((tech) => (
                                        <option key={tech} value={tech}>{tech}</option>
                                    ))}
                                </select>
                            </div>

                            {webTechnology === 'Other' && (
                                <div className="mb-3">
                                    <label htmlFor="otherTechnology" className="form-label">Specify Other Type</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="otherTechnology"
                                        name="otherTechnology"
                                        readOnly={readOnly}
                                        value={otherTechnology}
                                        onChange={(e) => setOtherTechnology(e.target.value)}
                                    />
                                </div>
                            )}

                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    readOnly={readOnly}
                                    value={newApp.description}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="status" className="form-label">Status</label>
                                <select
                                    className="form-control"
                                    id="status"
                                    name="status"
                                    readOnly={readOnly}
                                    value={newApp.status}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="Active">Active</option>
                                    <option value="DeActive">DeActive</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="i-btn primary--btn btn--md" onClick={() => setShowaddnewapp(false)}>Cancel</button>
                            <button
                                type="submit"
                                className="i-btn btn--md btn--primary"
                                disabled={loging}
                            >
                                {loging ? "Loading..." : "Add Application"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddingNewApplication;
