import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { updateBranchDetails, getBranchDetails } from "../../services/apiService"; // API service
import { oneclick } from '../../oneclick';
import database_id from '../../utils/databaseConfig';

const EditBranch = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const branchData = location.state?.branch;

    const [branch, setBranch] = useState({
        id: "",
        name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        pin_code: "",
        logo: "",
        gst: "",
        phone: "",
        email: "",
        bank_name: "",
        bank_account_no: "",
        bank_account_name: "",
        bank_ifsc_code: "",
        latitude: "",           // New Field
        longitude: "",          // New Field
        terms_conditions: "",   // New Field
        policies: "",           // New Field
        startTime: "",
        closeTime: ""
    });

    useEffect(() => {
        if (branchData) {
            setBranch(branchData);
        } else {
            const fetchBranchDetails = async () => {
                const branchId = location.state?.id;
                const fetchedBranchData = await getBranchDetails(branchId);
                setBranch(fetchedBranchData);
            };
            fetchBranchDetails();
        }
    }, [branchData, location.state?.id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const tableSchema = database_id;
            const tableName = "branches";
            const data = {
                name: branch.name,
                address1: branch.address1,
                address2: branch.address2,
                city: branch.city,
                state: branch.state,
                pin_code: branch.pin_code,
                logo: branch.logo,
                gst: branch.gst,
                phone: branch.phone,
                email: branch.email,
                bank_name: branch.bank_name,
                bank_account_no: branch.bank_account_no,
                bank_account_name: branch.bank_account_name,
                bank_ifsc_code: branch.bank_ifsc_code,
                latitude: branch.latitude,           // New Field
                longitude: branch.longitude,         // New Field
                terms_conditions: branch.terms_conditions,   // New Field
                policies: branch.policies,           // New Field
                startTime: branch.startTime,
                closeTime: branch.closeTime
            };
            
            const conditions = {
                "id": branch.id,
                "company_id": branch.company_id
            };
            const update = await oneclick.update(tableSchema, tableName, data, conditions);
            if(update.success){
                navigate('/hrm&management/branches');
                alert(update.message || 'Record updated successfully.');
            }else{
                console.error("Error updating branch:");
                alert(update.error || 'Error updating branch.');
            }
            //await updateBranchDetails(branch.id, branch);

            
        } catch (error) {
            console.error("Error updating branch:", error);
        }
    };

    const handleChange = (e) => {
        setBranch({ ...branch, [e.target.name]: e.target.value });
    };

    return (
        <div className="container mt-5">
            <h2>Edit Branch</h2>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formBranchName">
                            <Form.Label>Branch Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={branch.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formAddress1">
                            <Form.Label>Address 1</Form.Label>
                            <Form.Control
                                type="text"
                                name="address1"
                                value={branch.address1}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formAddress2">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control
                                type="text"
                                name="address2"
                                value={branch.address2}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={branch.city}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formState">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                                type="text"
                                name="state"
                                value={branch.state}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formPinCode">
                            <Form.Label>Pin Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="pin_code"
                                value={branch.pin_code}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {/* New Latitude and Longitude Fields */}
                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formLatitude">
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control
                                type="text"
                                name="latitude"
                                value={branch.latitude}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formLongitude">
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control
                                type="text"
                                name="longitude"
                                value={branch.longitude}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={branch.phone}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={branch.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {/* Bank Details */}
                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formBankName">
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="bank_name"
                                value={branch.bank_name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formBankAccountNo">
                            <Form.Label>Bank Account Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="bank_account_no"
                                value={branch.bank_account_no}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formBankAccountName">
                            <Form.Label>Bank Account Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="bank_account_name"
                                value={branch.bank_account_name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formBankIfscCode">
                            <Form.Label>Bank IFSC Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="bank_ifsc_code"
                                value={branch.bank_ifsc_code}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {/* New Terms and Conditions & Policies Fields */}
                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formTermsConditions">
                            <Form.Label>Terms & Conditions</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="terms_conditions"
                                value={branch.terms_conditions}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formPolicies">
                            <Form.Label>Policies</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="policies"
                                value={branch.policies}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formStartTime">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                                type="text"
                                name="startTime"
                                value={branch.startTime}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group controlId="formCloseTime">
                            <Form.Label>Close Time</Form.Label>
                            <Form.Control
                                type="text"
                                name="closeTime"
                                value={branch.closeTime}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" type="submit">
                    Save Changes
                </Button>
            </Form>
        </div>
    );
};

export default EditBranch;
