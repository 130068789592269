import React, { useState, useEffect } from 'react';
import { decodeBase64, getuserdata, encodeBase64 } from '../../../services/apiService';
import { oneclick } from '../../../oneclick';
import { formatDistanceToNow, parseISO, format } from 'date-fns';
import database_id from '../../../utils/databaseConfig';

const Sidebar = ({ staffid, setChatid, setChatdataload }) => {
    const [staffdata, setStaffdata] = useState({});
    const [userprofile, setUserprofile] = useState({});
    const [chatdata, setChatdata] = useState([]);

    // Fetch staff data from API
    const getStaffData = async () => {
        setUserprofile(await getuserdata());
        try {
            const tableSchema = database_id;
            const tableName = "ai_staff";
            const conditions = { id: decodeBase64(staffid), company_id: await getuserdata("company_id") };
            const orderBy = "id DESC";
            const pagination = { page: 1, limit: 10 };

            const response = await oneclick.read(
                tableSchema,
                tableName,
                conditions,
                orderBy,
                "",
                pagination,
                {}
            );

            if (!response || !response.success || !response.data || response.data.length === 0) {
                throw new Error("No staff data found.");
            }

            setStaffdata(response.data[0]);
        } catch (error) {
            console.error("Error in getStaffData:", error);
            alert(`Failed to fetch staff data: ${error.message}`);
        }
    };

    const handleChat = async (id) => {
        setChatid(id);
        setChatdataload({ id });
    
        // Get the current URL path
        const currentUrl = window.location.pathname; // e.g. /ai/chat/MTA=/false
    
        // Replace the last segment of the URL (after the last slash) with the actual chat ID
        const newUrl = currentUrl.replace(/\/[^/]+$/, `/${encodeBase64(id)}`); // Replaces the last segment with the ID
    
        // Update the browser's URL without refreshing the page
        window.history.pushState({ chatId: id }, '', newUrl); // Update the URL with new chat ID
    };

    // Fetch chat data from API
    const getchat = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "ai_staff_chat";
            const conditions = { sender_id: await getuserdata("id"), receiver_id: decodeBase64(staffid) };
            const orderBy = "id DESC";
            const pagination = { page: 1, limit: 10 };

            const response = await oneclick.read(
                tableSchema,
                tableName,
                conditions,
                orderBy,
                "",
                pagination,
                {}
            );

            if (!response || !response.success || !response.data || response.data.length === 0) {
                throw new Error("No chat data found.");
            }

            setChatdata(response.data);
        } catch (error) {
            console.error("Error in getchat:", error);
            alert(`Failed to fetch chat data: ${error.message}`);
        }
    };

    // Function to group chats by date
    const groupChatsByDate = (chats) => {
        const groupedChats = {};

        chats.forEach((chat) => {
            const chatDate = new Date(chat.updated_at);
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            let dateLabel;
            if (isSameDay(chatDate, today)) {
                dateLabel = 'Today';
            } else if (isSameDay(chatDate, yesterday)) {
                dateLabel = 'Yesterday';
            } else {
                dateLabel = format(chatDate, 'MM/dd/yyyy'); // Use MM/dd/yyyy format
            }

            if (!groupedChats[dateLabel]) {
                groupedChats[dateLabel] = [];
            }

            groupedChats[dateLabel].push(chat);
        });

        return groupedChats;
    };

    // Helper function to check if two dates are the same day
    const isSameDay = (date1, date2) => {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    };

    useEffect(() => {
        getStaffData();
        getchat();
    }, []);

    const groupedChats = groupChatsByDate(chatdata);

    return (
        <div className="flex-col flex-1 transition-opacity duration-500 relative -mr-2 pr-2 overflow-y-auto">
            <div className="group/sidebar">
                <button
                    className="btn btn-light"
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                >
                    <i className="bi bi-three-dots-vertical"></i> New Chat
                </button>
            </div>

            {/* Display grouped chat messages */}
            <div className="chat-container mt-3">
                {Object.keys(groupedChats).length > 0 ? (
                    Object.keys(groupedChats).map((dateLabel, index) => (
                        <div key={index} className="chat-group">
                            <div className="chat-date-label">{dateLabel}</div>
                            {groupedChats[dateLabel].map((chat, chatIndex) => (
                                <div key={chatIndex} className="chat-message" onClick={() => handleChat(chat.id)}>
                                    <div className="chat-message-content">{chat.message}</div>
                                    <div className="chat-timestamp">
                                        {formatDistanceToNow(parseISO(chat.updated_at), { addSuffix: true })}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <div>No chat messages found.</div>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
