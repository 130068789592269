import React, { useState } from 'react';

const DateRangeDropdown = ({ onDateRangeChange }) => {
    const [selectedRange, setSelectedRange] = useState('');

    const dateRanges = [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Last Week', value: 'last_week' },
        { label: 'This Month', value: 'this_month' },
        { label: 'Last Month', value: 'last_month' },
        { label: 'Last 6 Months', value: 'last_6_months' },
        { label: 'This Year', value: 'this_year' },
    ];

    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedRange(value);
        onDateRangeChange(value);
    };

    return (
        <div>
            <select value={selectedRange} className="btn btn-primary" onChange={handleChange} style={{height: '38px',padding: '0px 7px'}}>
                <option value="">Select Date Range</option>
                {dateRanges.map((range) => (
                    <option key={range.value} value={range.value}>
                        {range.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DateRangeDropdown;
