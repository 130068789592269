import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome styles
import mockData from '../../utils/mockData';
import { search } from '../../utils/search';
import { paginate } from '../../utils/pagination';
import CreateTableModal from './Components/CreateTableModal';
import ImportDatabaseModal from './Components/ImportDatabaseModal';
import { Button, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import ConfirmationModal from '../../utils/ConfirmationModal';
import Paginationnew from '../../utils/Paginationnew';
import BulkActionModal from '../Contact_Management/Components/BulkActionModal';
import RowEditeModal from './Components/RowEditeModal';
import RowInsertModal from './Components/RowInsertModal';
import TableStructureModal from './Components/TableStructureModal';
import { getTableColumns, updateColumn, encodeBase64, decodeBase64, getImageBaseUrl, bulkDeleteRows, exportDatabase } from '../../services/apiService';

const RealTimeColumns = () => {
    const location = useLocation();
    const [addColumnnew, setColumnsnew] = useState(true);
    const { encodedatabasename, encodeserver, encodeusername, encodepassword, encodetablename } = useParams(); // Retrieve the URL parameter
    const databasename = decodeBase64(encodedatabasename);
    const server = decodeBase64(encodeserver);
    const username = decodeBase64(encodeusername);
    const password = decodeBase64(encodepassword);
    const tablename = decodeBase64(encodetablename);
    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl


    const { database, table } = useParams(); // Retrieve the URL parameter
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(mockData);
    const [showConnectModal, setShowConnectModal] = useState(false);
    const [showCreateTableModal, setShowCreateTableModal] = useState(false);
    const [showImportDatabaseModal, setShowImportDatabaseModal] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);

    const csrf_token = 'your_csrf_token_here'; // Define the CSRF token here
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [deletedRows, setDeletedRows] = useState(new Set());
    const [previousRows, setPreviousRows] = useState({});
    const [updatedRows, setUpdatedRows] = useState({});
    const [newColumnName, setNewColumnName] = useState('');
    const [fileToImport, setFileToImport] = useState(null);

    // Modal state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [isBulkDelete, setIsBulkDelete] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalPages, settotalPages] = useState(0);
    const [currentData, setcurrentData] = useState(1);
    const [contactsPerPage, setContactsPerPage] = useState(10); // Default to 10 contacts per page
    const [columnName, setcolumnName] = useState("");
    const [orderby, setorderby] = useState("");
    const [leftSelected, setLeftSelected] = useState("");
    const [rightSelected, setRightSelected] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);


    const getallcoloumns = async () => {
        setLoading(true);
        try {
            console.log('getallcoloumns :', searchQuery);

            // Calling the API to get table columns
            const response = await getTableColumns(server, username, password, databasename, tablename, columnName, searchQuery, currentPage, contactsPerPage, orderby);

            // Check if the response is successful
            if (response.success) {
                // Set the columns and rows if the response is successful
                setColumns(response.columns);
                setRows(response.columnsdata);
                settotalPages(response.pagination.totalPages);
                setLoading(false);
            } else {
                // Handle the case where the response is not successful
                console.error('Error: Response success is false', response);
                alert('Failed to fetch data. Please try again.');
                setLoading(false);
            }
        } catch (error) {
            // Catch and handle errors that might occur during the API call
            console.error('Error fetching columns:', error);
            alert('An error occurred while fetching the data. Please check your connection and try again.');
            setLoading(false);
        }
    };


    useEffect(() => {
        setImageBaseUrl(getImageBaseUrl());
        getallcoloumns();
        console.log('orderby :', orderby);
    }, [searchQuery, currentPage, contactsPerPage, orderby]);

    const handleLeftSelect = (event) => {
        setLeftSelected(event.target.value);
    };

    const handleRightSelect = (event) => {
        setRightSelected(event.target.value);
        const order = `${leftSelected} ${event.target.value}`;
        setorderby(order);
    };


    const handleDelete = (id) => {
        const newData = data.filter(item => item.id !== id);
        setData(newData);
    };
    const handleView = (item) => alert(`Viewing details for table: ${item.tableName}`);
    const handleShowConnectModal = () => setShowConnectModal(true);
    const handleCloseConnectModal = () => setShowConnectModal(false);
    const handleShowCreateTableModal = () => setShowCreateTableModal(true);
    const handleCloseCreateTableModal = () => setShowCreateTableModal(false);
    const handleShowImportDatabaseModal = () => setShowImportDatabaseModal(true);
    const handleCloseImportDatabaseModal = () => setShowImportDatabaseModal(false);
    const handleShowExportModal = () => setShowExportModal(true);
    const handleCloseExportModal = () => setShowExportModal(false);


    const handleContactsPerPage = (e) => {
        setContactsPerPage(e.target.value);
        setCurrentPage(1); // Reset to the first page when count changes handlesharecolumn
    };

    const handlesharecolumn = (e) => {
        setcolumnName(e.target.value);
        setCurrentPage(1); // Reset to the first page when count changes handlesharecolumn
    };


    useEffect(() => {
        getallcoloumns();
    }, [searchQuery]);


    const getLastUpdatedAt = () => {
        const lastRow = document.querySelector('#table-body tr:last-child');
        return lastRow ? lastRow.getAttribute('data-updated-at') : '';
    };

    const updateTable = (updatedRowsData) => {
        let currentRows = {};
        updatedRowsData.forEach((row) => {
            const rowId = row.id;
            currentRows[rowId] = row;

            const existingRow = document.querySelector(`#table-body tr[data-row-id="${row.id}"]`);
            const isUpdated = JSON.stringify(previousRows[rowId]) !== JSON.stringify(row);

            if (isUpdated) {
                if (existingRow) {
                    existingRow.replaceWith(createTableRow(row)); // Replace the existing row
                } else {
                    document.querySelector('#table-body').appendChild(createTableRow(row)); // Add new row
                }
            }
            updatedRows[row.id] = row.updated_at;
        });

        // Detect deleted rows
        Object.keys(previousRows).forEach((rowId) => {
            if (!currentRows[rowId] && !deletedRows.has(rowId)) {
                const rowToDelete = document.querySelector(`#table-body tr[data-row-id="${rowId}"]`);
                if (rowToDelete) rowToDelete.remove();
                deletedRows.add(rowId);
            }
        });

        setPreviousRows(currentRows);
    };

    const createTableRow = (row) => {
        const tr = document.createElement('tr');
        tr.setAttribute('data-row-id', row.id);
        tr.setAttribute('data-updated-at', row.updated_at);

        const checkboxTd = document.createElement('td');
        checkboxTd.innerHTML = `<input type="checkbox" name="selected_rows[]" value="${row.id}">`;
        tr.appendChild(checkboxTd);

        columns.forEach((column) => {
            const td = document.createElement('td');
            td.classList.add('editable');
            td.setAttribute('data-column', column);
            td.setAttribute('data-row-id', row.id);
            td.textContent = row[column];
            tr.appendChild(td);
        });

        const actionTd = document.createElement('td');
        actionTd.innerHTML = `
            <a href="/user/columnedit/${row.id}" class="btn btn-primary">Edit</a>
            <button type="button" class="btn btn-danger delete-row" data-row-id="${row.id}" onClick={() => handleDeleteRow(row.id)}>Delete</button>
        `;
        tr.appendChild(actionTd);

        return tr;
    };


    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleDeleteRow = async (rowId) => {
        console.log('handleDeleteRow :', rowId);
        setSelectedRows(rowId);
        const response = await bulkDeleteRows(server, username, password, databasename, tablename, selectedRows);
        if (response.success) {
            alert(response.message);
            getallcoloumns();
        } else {
            alert(response.message);
        }
    };

    const [editerowdata, setediterowdata] = useState({});
    const [editerowdataid, setediterowdataid] = useState({});
    const [showroweditemodel, setshowroweditemodel] = useState(false);
    const [showInsertModal, setShowInsertModal] = useState(false);

    const handleInsertModalClose = () => {
        setShowInsertModal(false);
    };

    const handleInsertModalOpen = () => {
        setShowInsertModal(true);
    };
    const handleEdite = (data, id) => {
        setediterowdataid(id);
        setediterowdata(data);
        setshowroweditemodel(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await axios.post(`/user/columndelete/${currentRowId}`, {
                _token: csrf_token,
            });
            if (response.data.success) {
                const rowToDelete = document.querySelector(`#table-body tr[data-row-id="${currentRowId}"]`);
                if (rowToDelete) rowToDelete.remove();
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert('Error deleting row:', error);
        } finally {
            setIsModalOpen(false);
        }
    };

    const cancelDelete = () => {
        setIsModalOpen(false);
        setCurrentRowId(null);
    };

    const handleBulkDelete = () => {
        const selectedRows = Array.from(document.querySelectorAll('input[name="selected_rows[]"]:checked')).map(
            (input) => input.value
        );

        if (selectedRows.length === 0) {
            alert('Please select at least one row to delete.');
            return;
        }

        setIsBulkDelete(true);
        setSelectedRows(selectedRows);
        setIsModalOpen(true);
    };

    const confirmBulkDelete = async () => {
        try {
            const response = await axios.post('/your-bulk-delete-url', {
                _token: csrf_token,
                rows: selectedRows,
            });
            if (response.data.success) {
                selectedRows.forEach((rowId) => {
                    const rowToDelete = document.querySelector(`#table-body tr[data-row-id="${rowId}"]`);
                    if (rowToDelete) rowToDelete.remove();
                });
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert('Error in bulk delete:', error);
        } finally {
            setIsModalOpen(false);
            setSelectedRows([]);
        }
    };



    // State to track the editable row and column
    const [editableRow, setEditableRow] = useState(null);
    const [editableColumn, setEditableColumn] = useState(null);
    const [editValue, setEditValue] = useState("");
    const [editoldValue, setEditoldValue] = useState("");

    // List of non-editable columns
    const nonEditableColumns = ["COLUMN_NAME", "id", "created_at", "updated_at"];

    const handleDoubleClick = (rowId, columnName, value) => {
        if (!nonEditableColumns.includes(columnName)) {
            setEditableRow(rowId);
            setEditableColumn(columnName);
            setEditoldValue(value);
            setEditValue(value);
        }
    };
    const handleBlurOrEnter = (rowId, columnName, value) => {
        if (editValue == editoldValue) {

        } else {
            if (editValue == "-1") {

            } else {
                handleSaveEdit(rowId, columnName, value);
            }

        }
        setEditableRow(null);
        setEditableColumn(null);
    };
    const handleSaveEdit = async (rowId, columnName, value) => {
        const responce = await updateColumn(server, username, password, databasename, tablename, columnName, rowId, value);
        if (responce.success) {
            alert(responce.message);
            setEditableRow(null);
            setEditableColumn(null);
            setEditValue("");
            getallcoloumns();
        } else {
            alert(responce.message);
        }

    };

    const [textareaWidth, setTextareaWidth] = useState(200);  // Default width of the textarea
    const [textareaHeight, setTextareaHeight] = useState(50); // Default height of the textarea 
    const [maxWidthWidth, setmaxWidthWidth] = useState(200);
    const [allChecked, setAllChecked] = useState(false);

    const [Checkedrows, setCheckedrows] = useState([]); // Store selected row IDs
    const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false);

    const handleResize = (event, cellId, inputtypee) => {
        console.log('handleResize :', true);
        const textarea = event.target;
        const parentDiv = document.querySelector(`${inputtypee}[data-cell-id="${cellId}"]`);

        console.log('handleResize :', parentDiv.offsetWidth);
        setTextareaWidth(parentDiv.offsetWidth);
        setTextareaHeight(parentDiv.offsetHeight);
        setmaxWidthWidth(parentDiv.offsetWidth + 30);

        console.log('handleResize hight and widht :', textareaWidth, textareaHeight);
    };


    const handleSelectAll = () => {
        // Toggle the allChecked state
        const newCheckedState = !allChecked;
        setAllChecked(newCheckedState);

        // Update the rows' isChecked based on the new allChecked state
        const updatedRows = rows.map(row => ({ ...row, isChecked: newCheckedState }));
        setRows(updatedRows);

        // Update Checkedrows state based on the new allChecked state
        if (newCheckedState) {
            // Select all row IDs if all are checked
            const rowsall = updatedRows.filter(row => row.isChecked).map(row => row.id);
            setCheckedrows(rowsall);
        } else {
            // Deselect all row IDs
            setCheckedrows([]);
        }
    };

    const handleRowCheck = (id) => {
        console.log('handleRowCheck :', id);
        // Update filteredContacts state
        const updatedRows = rows.map(row =>
            row.id === id ? { ...row, isChecked: !row.isChecked } : row
        );
        setRows(updatedRows);

        // Handle Checkedrows state based on whether the checkbox is checked or unchecked
        if (updatedRows.find(row => row.id === id).isChecked) {
            // Add the ID to Checkedrows if the row is checked
            setCheckedrows(prevChecked => [...prevChecked, id]);
        } else {
            // Remove the ID from Checkedrows if the row is unchecked
            setCheckedrows(prevChecked => prevChecked.filter(checkedId => checkedId !== id));
        }

        // Check if all rows are checked to update 'Select All' checkbox state
        setAllChecked(updatedRows.every(row => row.isChecked));
    };

    const [exportloading, setexportloading] = useState(false);
    const handleexport = async () => {
        setexportloading(true);
        const responce = await exportDatabase(server, username, password, databasename, tablename);
        if (responce.success) {
            // Create a temporary <a> element for downloading the file
            const downloadLink = document.createElement('a');
            downloadLink.href = responce.downloadUrl; // Use the URL returned in the response
            downloadLink.download = responce.downloadUrl.split('/').pop(); // Use the file name from the URL
            downloadLink.click(); // Trigger the download automatically
            setexportloading(false);
            setShowExportModal(false);
        } else {
            alert(responce.message);
        }
    };




    return (
        <div className="dashboard-container">
            <div className="card mb-4">
                <div className="card-header">
                    <div className="d-flex align-items-center">
                        {/* Home icon with link */}
                        <Link to="/real_time_database">
                            <i className="fa fa-home" style={{ fontSize: '24px', marginRight: '10px' }}></i>
                        </Link>

                        {/* Databasename as a clickable link */}
                        <Link to={`/real_time_database/tables/${encodeBase64(databasename)}/${encodeBase64(server)}/${encodeBase64(username)}/${encodeBase64(password || 'No Password')}`} style={{ marginRight: '5px', textDecoration: 'none', color: '#000' }}>
                            <h1 className="card-title" style={{ display: 'inline' }}>
                                {databasename ? `${databasename}` : 'Your Database'}
                            </h1>
                        </Link>

                        {/* Table name, if available */}
                        {tablename && (
                            <span> → {tablename}</span>
                        )}
                    </div>
                </div>
                <div className="card-filter">
                    <form id="search-form" >
                        <div className="filter-form">
                            <div className="filter-item col-lg-1">
                                <select
                                    id="contactsPerPage"
                                    value={columnName}
                                    onChange={handlesharecolumn}
                                    className="form-select"
                                >
                                    <option value="" disabled>Select Column</option>
                                    {columns.map((column, index) => (
                                        <option value={column.COLUMN_NAME}>{column.COLUMN_NAME}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="filter-item col-lg-1">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Type anything"
                                    className="form-control"
                                    id="search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)} />
                            </div>
                            <div className="filter-item col-lg-1">
                                <select
                                    id="contactsPerPage"
                                    value={contactsPerPage}
                                    onChange={handleContactsPerPage}
                                    className="form-select"
                                >
                                    <option value="" disabled>Show per page</option>
                                    <option value="1">1</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="filter-action">
                                <button type="button" className="i-btn primary--btn btn--md text-white" onClick={() => setModalIsOpen(true)}>
                                    <img src={`${imageBaseUrl}/assets/file/images/logoIcon/b_props.png`} alt="Structure Icon" /> Structure
                                </button>

                                <button type="button" className="i-btn primary--btn btn--md text-white" onClick={() => setShowCreateTableModal(true)}>
                                    <i className="material-icons"></i> Add Column
                                </button>
                                <Link
                                    to={`/SqlEditor/${encodeBase64(databasename)}/${encodeBase64(server)}/${encodeBase64(username)}/${encodeBase64(password)}/${encodeBase64(tablename)}`} // Pass data in the URL
                                    className="btn btn-primary"
                                    style={{ height: '38px', marginRight: '10px' }}
                                >
                                    <img src={`${imageBaseUrl}/assets/file/images/logoIcon/b_sql.png`} alt="Structure Icon" /> SQL
                                </Link>
                                <a
                                    href="#"
                                    className="i-btn primary--btn btn--md text-white"
                                    onClick={handleInsertModalOpen}
                                >
                                    <img src={`${imageBaseUrl}/assets/file/images/logoIcon/b_insrow.png`} alt="Structure Icon" /> Insert
                                </a>
                                <button type="button" className="i-btn primary--btn btn--md text-white" onClick={() => setShowExportModal(true)}>
                                    <img src={`${imageBaseUrl}/assets/file/images/logoIcon/b_tblexport.png`} alt="Structure Icon" /> Export
                                </button>
                                <button type="button" className="i-btn primary--btn btn--md text-white" onClick={() => setShowImportDatabaseModal(true)}>
                                    <img src={`${imageBaseUrl}/assets/file/images/logoIcon/b_tblimport.png`} alt="Structure Icon" /> Import
                                </button>
                            </div>
                            <div className="filter-action col-lg-12">
                                {Checkedrows.length > 0 ? (
                                    <div className="statusUpdateBtn col-lg-2">
                                        <a
                                            className="i-btn success--btn btn--md bulkAction"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Bulk Actions"
                                            onClick={() => setIsBulkActionModalOpen(true)} // Corrected: wrapped in a function
                                        >
                                            <svg
                                                className="svg-inline--fa fa-gear"
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="gear"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z"
                                                />
                                            </svg>
                                            Action
                                        </a>
                                    </div>
                                ) : (<></>)}

                                <div className="selector-container form-control d-flex col-lg-4" style={{ width: '300px', padding: '0' }}>
                                    {/* Left Selector */}
                                    <select
                                        id="contactsPerPage"
                                        value={leftSelected}
                                        onChange={(e) => setLeftSelected(e.target.value)}
                                        className="form-select"
                                    >
                                        <option value="" disabled>Select Column</option>
                                        {columns.map((column, index) => (
                                            <option value={column.COLUMN_NAME}>{column.COLUMN_NAME}</option>
                                        ))}
                                    </select>

                                    {/* Right Selector */}
                                    <select
                                        id="contactsPerPage"
                                        value={rightSelected}
                                        onChange={handleRightSelect}
                                        className="form-select"
                                    >
                                        <option value="ASC">ASC</option>
                                        <option value="DESC">DESC</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <div className="card-body px-0">
                    <div className="responsive-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="d-flex align-items-center">
                                            <input
                                                className="form-check-input mt-0 me-2 checkAll"
                                                type="checkbox"
                                                checked={allChecked}
                                                onChange={handleSelectAll}
                                                value=""
                                                aria-label="Checkbox for following text input" />
                                            <span>Sl No.</span>
                                        </div>
                                    </th>
                                    {columns
                                        .filter(column => column.COLUMN_NAME !== 'id') // Filter out the column with name 'id'
                                        .map((column, index) => (
                                            <th key={index}>{column.COLUMN_NAME}</th>
                                        ))}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody id="table-body">
                                {loading ? (
                                    <tr>
                                        <td colSpan="8" style={{ textAlign: 'center' }}>
                                            Loading...
                                        </td>
                                    </tr>
                                ) : (
                                    rows.length > 0 ? (
                                        rows.map((row) => (
                                            <tr key={row.id} data-row-id={row.id}>
                                                <td className="d-none d-sm-flex align-items-center">
                                                    <input
                                                        className="form-check-input mt-0 me-2"
                                                        type="checkbox"
                                                        name="contactUid"
                                                        value={row.id}
                                                        checked={row.isChecked}
                                                        onChange={() => handleRowCheck(row.id)}
                                                        aria-label="Checkbox for following text input"
                                                    />
                                                    {row.id} {/* Display the 'id' value in the checkbox column */}
                                                </td>

                                                {columns
                                                    .filter(column => column.COLUMN_NAME !== 'id')  // Filter out the 'id' column from the data columns
                                                    .map((column, index) => {
                                                        const columnName = column.COLUMN_NAME;
                                                        const columnType = column.DATA_TYPE;

                                                        // Map SQL data types to input types
                                                        let inputType = "text";
                                                        if (["bigint", "int", "tinyint"].includes(columnType)) {
                                                            inputType = "number";
                                                        } else if (columnType === "timestamp") {
                                                            inputType = "datetime-local";
                                                        }

                                                        return (
                                                            <td
                                                                key={index}
                                                                onDoubleClick={() => handleDoubleClick(row.id, columnName, row[columnName])}
                                                                className={`table-cell new-cell ${row.id}-${columnName}`}
                                                                data-cell-id={`${row.id}-${columnName}`}
                                                                style={{
                                                                    width: editableRow === row.id && editableColumn === columnName ? `${textareaWidth}px` : '200px',
                                                                    height: editableRow === row.id && editableColumn === columnName ? `${textareaHeight}px` : 'auto',
                                                                    position: editableRow === row.id && editableColumn === columnName ? 'absolute' : 'relative',
                                                                    maxWidth: editableRow === row.id && editableColumn === columnName ? `${maxWidthWidth}px` : '200px',
                                                                }}
                                                            >
                                                                {editableRow === row.id && editableColumn === columnName ? (
                                                                    inputType === "number" ? (
                                                                        <input
                                                                            type="number"
                                                                            className="popup-textarea"
                                                                            value={editValue}
                                                                            onChange={(e) => setEditValue(e.target.value)}
                                                                            onBlur={(e) => handleBlurOrEnter(row.id, columnName, e.target.value)}
                                                                            data-cell-id={`${row.id}-${columnName}`}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    handleBlurOrEnter(row.id, columnName, e.target.value);
                                                                                }
                                                                            }}
                                                                            onMouseDown={(e) => handleResize(e, `${row.id}-${columnName}`, 'input')}
                                                                            onMouseEnter={(e) => handleResize(e, `${row.id}-${columnName}`, 'input')}  // Mouse hover event
                                                                            onMouseLeave={(e) => handleResize(e, `${row.id}-${columnName}`, 'input')}  // Mouse leave event
                                                                            autoFocus
                                                                            style={{
                                                                                maxWidth: editableRow === row.id && editableColumn === columnName ? `${maxWidthWidth}px` : '200px',
                                                                            }}
                                                                        />
                                                                    ) : inputType === "datetime-local" ? (
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="popup-textarea"
                                                                            value={editValue}
                                                                            onChange={(e) => setEditValue(e.target.value)}
                                                                            onBlur={(e) => handleBlurOrEnter(row.id, columnName, e.target.value)}
                                                                            data-cell-id={`${row.id}-${columnName}`}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    handleBlurOrEnter(row.id, columnName, e.target.value);
                                                                                }
                                                                            }}
                                                                            onMouseDown={(e) => handleResize(e, `${row.id}-${columnName}`, 'input')}
                                                                            onMouseEnter={(e) => handleResize(e, `${row.id}-${columnName}`, 'input')}  // Mouse hover event
                                                                            onMouseLeave={(e) => handleResize(e, `${row.id}-${columnName}`, 'input')}  // Mouse leave event
                                                                            autoFocus
                                                                            style={{
                                                                                maxWidth: editableRow === row.id && editableColumn === columnName ? `${maxWidthWidth}px` : '200px',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <textarea
                                                                            className="popup-textarea"
                                                                            value={editValue}
                                                                            onChange={(e) => setEditValue(e.target.value)}
                                                                            onBlur={(e) => handleBlurOrEnter(row.id, columnName, e.target.value)}
                                                                            data-cell-id={`${row.id}-${columnName}`}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    handleBlurOrEnter(row.id, columnName, e.target.value);
                                                                                }
                                                                            }}
                                                                            onMouseDown={(e) => handleResize(e, `${row.id}-${columnName}`, 'textarea')}
                                                                            onMouseEnter={(e) => handleResize(e, `${row.id}-${columnName}`, 'textarea')}  // Mouse hover event
                                                                            onMouseLeave={(e) => handleResize(e, `${row.id}-${columnName}`, 'textarea')}  // Mouse leave event
                                                                            autoFocus
                                                                            style={{
                                                                                maxWidth: editableRow === row.id && editableColumn === columnName ? `${maxWidthWidth}px` : '200px',
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    row[columnName] !== null ? row[columnName] : "N/A"
                                                                )}


                                                            </td>
                                                        );
                                                    })}


                                                <td>
                                                    <div className="d-flex align-items-center gap-1">
                                                        <Button className="i-btn primary--btn btn--sm" onClick={() => handleEdite(row, row.id)}>
                                                            <i className="las la-pen"></i>
                                                        </Button>
                                                        <Button className="i-btn danger--btn btn--sm" onClick={() => handleDeleteRow(row.id)}>
                                                            <i className="las la-trash"></i>
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr >
                                            <td style={{ textAlign: 'center' }} colSpan={columns.length + 2}>No rows available</td>
                                        </tr>
                                    )
                                )}

                            </tbody>
                        </table>

                    </div>
                    <Paginationnew
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />

                </div>
                <RowInsertModal
                    show={showInsertModal}
                    handleClose={handleInsertModalClose}
                    columns={columns}
                    tablename={tablename}  // Replace with your table name
                    ip={server}
                    username={username}
                    password={password}
                    dbName={databasename}
                    getallcoloumns={getallcoloumns}
                    onSubmit={(data) => console.log(data)} // Callback after insertion
                />
                <TableStructureModal
                    isOpen={modalIsOpen}
                    onClose={() => setModalIsOpen(false)}
                    columns={columns}
                />
                {showroweditemodel && (
                    <RowEditeModal
                        show={showroweditemodel}
                        handleClose={() => setshowroweditemodel(false)}
                        fields={editerowdata}
                        columns={columns}
                        tablename={tablename}
                        rowid={editerowdataid}
                        ip={server}
                        username={username}
                        password={password}
                        dbName={databasename}
                        getallcoloumns={getallcoloumns}
                    />
                )}
                {isBulkActionModalOpen && (
                    <BulkActionModal
                        onClose={() => setIsBulkActionModalOpen(false)}
                        selectedRows={Checkedrows}
                        fetchData={getallcoloumns}
                        setAllChecked={setAllChecked}
                        type={'realtimetablescolumns'}
                        ip={server}
                        username={username}
                        password={password}
                        dbName={databasename}
                        setCheckedrows={setCheckedrows}
                        tablenamenew={tablename}
                    />
                )}
                <CreateTableModal
                    show={showCreateTableModal}
                    handleClose={handleCloseCreateTableModal}
                    ip={server}
                    username={username}
                    password={password}
                    dbName={databasename}
                    getTables={getallcoloumns}
                    addcolumns={addColumnnew}
                    tableNameold={tablename}

                />

                <ImportDatabaseModal
                    show={showImportDatabaseModal}
                    handleClose={handleCloseImportDatabaseModal}
                    ip={server}
                    username={username}
                    password={password}
                    dbName={databasename}
                    getTables={getallcoloumns}
                    tablename={tablename}
                />
                <Modal show={showConnectModal} onHide={handleCloseConnectModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Connect to Database</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Connect Modal content here</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseConnectModal}>Close</Button>
                        <Button variant="primary" onClick={() => alert('Connected!')}>Connect</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showExportModal} onHide={handleCloseExportModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Export Table</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Export Modal content here</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseExportModal}>Close</Button>
                        <Button variant="primary" onClick={() => handleexport()}>
                            {exportloading ? (
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            ) : (
                                "Export"
                            )}
                            {exportloading ? " Exporting..." : " Export"}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default RealTimeColumns;
