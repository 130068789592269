import { useEffect, useState } from 'react';
import { getImageBaseUrl } from '../services/apiService';
import { oneclick } from '../oneclick';
import database_id from './databaseConfig';

// Function to check if a file exists by trying to fetch it
const checkFileExists = async (url) => {
    try {
        const response = await fetch(url);
        return response.ok; // Return true if the response is ok (status 200)
    } catch (error) {
        return false; // Return false if an error occurs (e.g., 404 or ENOENT)
    }
};

const useImageUrlBuild = (fileid) => {
    const [fileUrl, setFileUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Define a default image URL for missing files
    const defaultImageUrl = `https://pic.onlinewebfonts.com/thumbnails/icons_328197.svg`;

    useEffect(() => {
        const fetchFileUrl = async () => {
            setLoading(true);

            // Return early if no fileid is provided
            if (!fileid) {
                setFileUrl(defaultImageUrl); // Set default image if no fileid is provided
                setLoading(false);
                return;
            }

            try {
                const tableSchema = database_id;
                const tableNameFiles = "files";
                const conditions = { id: fileid };

                const baseURL = getImageBaseUrl();
                const allFiles11 = await oneclick.read(tableSchema, tableNameFiles, conditions, "", "");
                const allFiles = allFiles11.data;


                if (allFiles.length > 0) {
                    const fileInfo = allFiles[0];
                    let constructedUrl = '';

                    const rootSegments = fileInfo.root.split('/'); // Split the root path
                    const remainingRoot = rootSegments.slice(3).join('/'); // Remove the first few segments

                    if (fileInfo.status === "public") {
                        constructedUrl = `${baseURL}/${rootSegments[1]}/${rootSegments[2]}//${remainingRoot}/${fileInfo.fileName}`;
                    } else if (fileInfo.status === "private") {
                        const token = localStorage.getItem('token');
                        constructedUrl = `${baseURL}/${rootSegments[1]}/${rootSegments[2]}/${token}/${remainingRoot}/${fileInfo.fileName}`;
                    }

                    // Check if the file actually exists on the server
                    const fileExists = await checkFileExists(constructedUrl);
                    if (fileExists) {
                        setFileUrl(constructedUrl); // Set the valid URL
                    } else {
                        setFileUrl(defaultImageUrl); // File not found, use default image
                        //setError('File not found in the file system');
                    }
                } else {
                    setFileUrl(defaultImageUrl); // Set default image if file not found in database
                    //setError('File record not found in the database');
                }
            } catch (err) {
                console.error('Error constructing file URL:', err);
                setFileUrl(defaultImageUrl); // Set default image in case of error
                //setError('Error fetching file URL');
            } finally {
                setLoading(false);
            }
        };

        fetchFileUrl();
    }, [fileid]); // Effect runs when fileid changes

    return { fileUrl, loading, error }; // Return URL, loading state, and error
};

export default useImageUrlBuild;
