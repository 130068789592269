import React from 'react';

function SmsCampaigns() {
  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">SMS Campaigns</h4>
            </div>

            <div className="card-filter">
              <form action="http://localhost/getoneclick/admin/campaigns/search" method="post">
                <input type="hidden" name="_token" value="gmRCaCzjB6Q13HtAZPOABlD8x06Q0ynqo1nnJ3fh" />
                <div className="filter-form">
                  <div className="filter-item">
                    <input
                      type="text"
                      autoComplete="off"
                      name="search"
                      placeholder="Search With Name"
                      className="form-control"
                      id="search"
                      defaultValue=""
                    />
                  </div>

                  <input type="hidden" name="channel" value="sms" />

                  <div className="filter-item">
                    <select name="status" className="form-select" id="">
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="DeActive">DeActive</option>
                      <option value="Completed">Completed</option>
                      <option value="Ongoing">Ongoing</option>
                    </select>
                  </div>

                  <div className="filter-action">
                    <button className="i-btn info--btn btn--md" type="submit">
                      <i className="fas fa-search"></i> Search
                    </button>
                    <button className="i-btn danger--btn btn--md" type="button">
                      <a
                        className="i-btn danger--btn btn--md"
                        href="http://localhost/getoneclick/admin/campaigns/sms"
                      >
                        <i className="fas fa-sync"></i> Reset
                      </a>
                    </button>
                    <a
                      href="http://localhost/getoneclick/admin/campaigns/sms/create"
                      className="i-btn primary--btn btn--md"
                    >
                      <i className="fas fa-plus"></i> Add New
                    </a>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-body px-0">
              <div className="responsive-table">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Channel</th>
                      <th>Total Contacts</th>
                      <th>Schedule Time</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-muted text-center" colSpan="100%">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="m-3"></div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="delete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <form action="http://localhost/getoneclick/admin/campaigns/delete" method="POST">
              <input type="hidden" name="_token" value="gmRCaCzjB6Q13HtAZPOABlD8x06Q0ynqo1nnJ3fh" />
              <input type="hidden" name="id" value="" />
              <div className="modal_body2">
                <div className="modal_icon2">
                  <i className="las la-trash"></i>
                </div>
                <div className="modal_text2 mt-3">
                  <h6>Are you sure to delete this Campaign</h6>
                </div>
              </div>
              <div className="modal_button2 modal-footer">
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <button type="button" className="i-btn primary--btn btn--md" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button type="submit" className="i-btn danger--btn btn--md">
                    Delete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmsCampaigns;
