import React, { useState, useEffect } from "react";


const GatewayInfo = ({ }) => {

    return (
        <div className="modal fade" id="gatewayInfo" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog nafiz">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="card">
                            <div className="card-header bg--lite--violet">
                                <div className="card-title text-center text--light">Gateway Information</div>
                            </div>
                            <div className="card-body">
                                <div className="driver-info"></div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_button2 modal-footer">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <button type="button" className="i-btn primary--btn btn--md" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GatewayInfo;