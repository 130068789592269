import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { insertRecord } from "../../../services/apiService"; // You would create this service to handle the insert

const RowInsertModal = ({ show, handleClose, onSubmit, columns, tablename, ip, username, password, dbName, getallcoloumns }) => {
    const [formData, setFormData] = useState({});

    // Handle form data change
    const handleInputChange = (columnName, value) => {
        setFormData((prev) => ({ ...prev, [columnName]: value }));
    };

    const [focusedField, setFocusedField] = useState(null); // Track the focused field


    const handleFocus = (columnName) => {
        setFocusedField(columnName); // Set focused field when input is focused
    };

    const handleBlur = () => {
        setFocusedField(null); // Clear focused field when input loses focus
    };


    // Handle the form submission
    const handleSubmit = async () => {
        try {
            // Send data to API for insertion
            const response = await insertRecord(ip, username, password, dbName, tablename, formData);

            if (response.success) {
                alert('Record inserted successfully');
                handleClose();
                getallcoloumns();
            }
        } catch (error) {
            console.error('Error inserting record:', error);
            alert('Error inserting record');
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Insert New Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="dynamic-form">
                    {columns.map((column, index) => {
                        if (column.COLUMN_NAME === "id") return null; // Skip 'id' column

                        return (
                            <div key={index} className="form-row d-flex" style={{ marginBottom: "10px" }}>
                                <div className="col-lg-6 col-sm-6">
                                    <label className="form-label">
                                        {column.COLUMN_NAME}
                                        {column.IS_NULLABLE === "NO" && <span style={{ color: "red" }}>*</span>} {/* Display mandatory indicator */}
                                    </label>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div style={{ height: "40px" }}>
                                        {column.DATA_TYPE === "varchar" || column.DATA_TYPE === "text" || column.DATA_TYPE === "longtext" ? (
                                            <textarea
                                                className="form-input form-control"
                                                value={formData[column.COLUMN_NAME] || ""}
                                                placeholder={`Enter ${column.COLUMN_NAME}`}
                                                onChange={(e) => handleInputChange(column.COLUMN_NAME, e.target.value)}
                                                onFocus={() => handleFocus(column.COLUMN_NAME)}
                                                onBlur={handleBlur}
                                                style={{
                                                    position: "absolute",
                                                    height: focusedField === column.COLUMN_NAME ? "" : "40px",
                                                    resize: "both",
                                                    overflow: "auto",
                                                    width: focusedField === column.COLUMN_NAME ? "" : "208px",
                                                    zIndex: focusedField === column.COLUMN_NAME ? 99 : 1,
                                                }}
                                            />
                                        ) : column.DATA_TYPE === "timestamp" ? (
                                            <input
                                                type="datetime-local"
                                                className="form-input form-control"
                                                value={formData[column.COLUMN_NAME] || ""}
                                                placeholder={`Enter ${column.COLUMN_NAME}`}
                                                onChange={(e) => handleInputChange(column.COLUMN_NAME, e.target.value)}
                                            />
                                        ) : (
                                            <input
                                                type={
                                                    column.DATA_TYPE === "int" || column.DATA_TYPE === "tinyint"
                                                        ? "number"
                                                        : column.DATA_TYPE === "timestamp"
                                                            ? "datetime-local"
                                                            : "text" // Default input type
                                                }
                                                className="form-input form-control"
                                                value={formData[column.COLUMN_NAME] || ""}
                                                placeholder={`Enter ${column.COLUMN_NAME}`}
                                                onChange={(e) => handleInputChange(column.COLUMN_NAME, e.target.value)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default RowInsertModal;