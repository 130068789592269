import React, { useState, useEffect } from "react";


const Editgateway = ({ }) => {

    return (
        <div className="modal fade" id="editgateway" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit SMS Gateway</h5>
                        <button type="button" className="i-btn bg--lite--danger text--danger btn--sm" data-bs-dismiss="modal">
                            <i className="las la-times"></i>
                        </button>
                    </div>
                    <form action="http://localhost/getoneclick/admin/sms/gateways/update" method="post">
                        <input type="hidden" name="_token" value="gmRCaCzjB6Q13HtAZPOABlD8x06Q0ynqo1nnJ3fh" />
                        <input type="hidden" name="id" value="" />
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-item mb-3">
                                        <label htmlFor="name" className="form-label">From Name <sup className="text--danger">*</sup></label>
                                        <input type="text" className="form-control" id="name" name="name" placeholder="Enter From Name" required />
                                    </div>
                                    <div className="form-item mb-3">
                                        <label htmlFor="gateway_type_edit" className="form-label">Gateway type <sup className="text--danger">*</sup></label>
                                        <select className="form-select text-uppercase select-gateway-type gateway_type" name="type" required id="gateway_type_edit"></select>
                                    </div>
                                    <div className="row mb-3 newdataadd"></div>
                                    <div className="row mb-3 oldData"></div>
                                    <div className="form-item mb-3">
                                        <label htmlFor="status" className="form-label">Status <sup className="text--danger">*</sup></label>
                                        <select className="form-select" name="status" id="status" required>
                                            <option className="active" value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="i-btn primary--btn btn--md">Submit</button>
                            <button type="button" className="i-btn bg--lite--danger text--danger btn--md" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Editgateway;