
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { oneclick } from '../../oneclick';
import { getuserdata } from '../../services/apiService';
import UpdateContactModal from './Components/UpdateContactModal';
import DeleteGroupModal from './Components/DeleteGroupModal';
import BulkActionModal from './Components/BulkActionModal';
import database_id from '../../utils/databaseConfig';





const Dashboard = () => {
  // Demo data for contacts
  const demoContacts = Array.from({ length: 30 }, (v, i) => ({
    id: i + 1,
    name: `Contact ${i + 1}`,
    group: i % 2 === 0 ? 'Demo Group' : 'Test Group',
    smsContact: `123456${i + 1}`,
    whatsappNumber: `123456${i + 1}`,
    email: `contact${i + 1}@mail.com`,
    status: i % 2 === 0 ? 'Active' : 'Inactive',
  }));
  const navigate = useNavigate();

  const [filteredContacts, setFilteredContacts] = useState(demoContacts);
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage, setContactsPerPage] = useState(10); // Default to 10 contacts per page
  const [totalContacts, setTotalContacts] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [Filterbygroup, setFilterbygroup] = useState('all');
  const [Filterbytype, setFilterbytype] = useState('all');
  const [Filterbystatus, setFilterbystatus] = useState('all');
  const [statusFiltercon, setstatusFiltercon] = useState({});
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false);
  const [isUpdateGroupModalOpen, setIsUpdateGroupModalOpen] = useState(false);

  const [allChecked, setAllChecked] = useState(false);

  const [Checkedrows, setCheckedrows] = useState([]); // Store selected row IDs


  const [selectedrow, setSelectedrow] = useState([]);


  const getdata = async () => {
    const tableSchema = database_id;
    const tableName = "groups";
    const tableNamecontact = "contacts";

    const groupcond = {
      company_id: await getuserdata("company_id"),  // Common condition based on company_id
    }


    // Corrected state update logic for condition based on filters
    let condition = {
      company_id: await getuserdata("company_id"),  // Common condition based on company_id
    };

    // Add group_id filter if it's not 'all'
    if (Filterbygroup && Filterbygroup !== 'all') {
      condition.group_id = Filterbygroup;
    }

    // Only add status to condition if it's not 'all'
    if (Filterbystatus && Filterbystatus !== "all") {
      console.log("Filterbystatus:", Filterbystatus);
      condition.status = Filterbystatus; // Add status filter if it's not 'all'
    }

    let serchdata;
    if (Filterbytype === "all" || Filterbytype === "none") {
      console.log('serchdata:', Filterbytype, searchTerm);
      // Search all fields
      serchdata = {
        whatsapp_contact: searchTerm,
        email_contact: searchTerm,
        sms_contact: searchTerm,
        last_name: searchTerm,
        first_name: searchTerm
      };
    } else if (Filterbytype === "sms_contact") {
      serchdata = { sms_contact: searchTerm };
    } else if (Filterbytype === "email_contact") {
      serchdata = { email_contact: searchTerm };
    } else if (Filterbytype === "whatsapp_contact") {
      console.log('serchdata:', Filterbytype, searchTerm);
      serchdata = { whatsapp_contact: searchTerm };
    }


    const orderBy = "id DESC";  // Uncomment if needed
    const pagination = { page: currentPage, limit: contactsPerPage };  // Define page size

    const margedata = [
      {
        "target_table": "groups",
        "target_column": "id",
        "target_value": "group_id",
        "target_label": "groups"
      }
    ];

    // Get the response
    const dataresponce = await oneclick.read(tableSchema, tableName, groupcond, orderBy, "", "", "");
    const datacontact = await oneclick.read(tableSchema, tableNamecontact, condition, orderBy, serchdata, pagination, margedata);
    console.log('dataresponce:', dataresponce);
    setGroupData(dataresponce.data);
    setContactData(datacontact.data);
    setTotalContacts(datacontact.totalCount);
  };


  useEffect(() => {
    console.log('Filterbytype:', Filterbytype);
    getdata();
  }, [searchTerm, Filterbygroup, Filterbystatus, Filterbytype, contactsPerPage, currentPage]);

  const handleCreateModal = (event) => {

    navigate('/contactaddnew');
  };


  const handleFilterbysearchTerm = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  }

  const handleRowCheck = (id) => {
    // Update filteredContacts state
    const updatedRows = contactData.map(row =>
      row.id === id ? { ...row, isChecked: !row.isChecked } : row
    );
    setContactData(updatedRows);

    // Handle Checkedrows state based on whether the checkbox is checked or unchecked
    if (updatedRows.find(row => row.id === id).isChecked) {
      // Add the ID to Checkedrows if the row is checked
      setCheckedrows(prevChecked => [...prevChecked, id]);
    } else {
      // Remove the ID from Checkedrows if the row is unchecked
      setCheckedrows(prevChecked => prevChecked.filter(checkedId => checkedId !== id));
    }

    // Check if all rows are checked to update 'Select All' checkbox state
    setAllChecked(updatedRows.every(row => row.isChecked));
  };

  const handleSelectAll = () => {
    // Toggle the allChecked state
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    // Update the rows' isChecked based on the new allChecked state
    const updatedRows = contactData.map(row => ({ ...row, isChecked: newCheckedState }));
    setContactData(updatedRows);

    // Update Checkedrows state based on the new allChecked state
    if (newCheckedState) {
      // Select all row IDs if all are checked
      const rowsall = updatedRows.filter(row => row.isChecked).map(row => row.id);
      setCheckedrows(rowsall);
    } else {
      // Deselect all row IDs
      setCheckedrows([]);
    }
  };

  const handleFilterbygroup = (e) => {
    setFilterbygroup(parseInt(e.target.value));
    setCurrentPage(1);
  }
  const handleFilterbystatus = (e) => {
    setFilterbystatus(parseInt(e.target.value));
    setCurrentPage(1);
  }
  const handleFilterbytype = (e) => {
    console.log('handleFilterbytype:', e.target.value);
    const data = e.target.value;
    setFilterbytype(data);
    setCurrentPage(1);
  }

  const handleContactsPerPage = (e) => {
    setContactsPerPage(e.target.value);
    setCurrentPage(1); // Reset to the first page when count changes
  };


  const handeleediterow = (data) => {
    setIsUpdateGroupModalOpen(true);
    setSelectedrow(data);
  }

  const handeleDeleteGroup = (data) => {
    setIsDeleteGroupModalOpen(true);
    setSelectedrow(data);
  }

  const totalPages = Math.ceil(totalContacts / contactsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);


  return (
    <div className="dashboard_container">
      <section>
        <div className="card mb-4">
          <div className="card-header">
            <h4 className="card-title">Manage Contacts</h4>
          </div>

          <div className="card-filter" style={{ display: 'flex' }}>
            <form action="http://localhost/getoneclick/admin/contacts/search" method="GET">
              <div className="filter-form">
                {/* Status Filter */}
                <div className="filter-item">
                  <select name="status" className="form-select"
                    value={Filterbystatus}
                    onChange={handleFilterbystatus}
                  >
                    <option selected disabled>Search By Status</option>
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="2">Banned</option>
                  </select>
                </div>

                {/* Contact Type Filter */}
                <div className="filter-item">
                  <select name="type" className="form-select"
                    value={Filterbytype}
                    onChange={handleFilterbytype}
                  >
                    <option selected disabled>Search By Contact Type</option>
                    <option value="all">All</option>
                    <option value="sms_contact">SMS Contact</option>
                    <option value="whatsapp_contact">WhatsApp Number</option>
                    <option value="email_contact">Email address</option>
                    <option value="none">None</option>
                  </select>
                </div>

                {/* Group Filter */}
                <div className="filter-item">
                  <select name="group" className="form-select"
                    value={Filterbygroup}
                    onChange={handleFilterbygroup}
                  >
                    <option selected disabled>Search By Group</option>
                    <option value="all">All</option>
                    {groupData.length === 0 ? (
                      <option disabled value="">
                        No Groups Available
                      </option>
                    ) : (
                      groupData.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                {/* Search Field */}
                <div className="filter-item">
                  <input type="text"
                    value={searchTerm}
                    onChange={handleFilterbysearchTerm}
                    autoComplete="off" name="search" placeholder="Type Name, Contact Number or Mail Address" className="form-control" id="search" />
                </div>

                <div className="filter-item col-lg-1">
                  <select
                    id="contactsPerPage"
                    value={contactsPerPage}
                    onChange={handleContactsPerPage}
                    className="form-select"
                  >
                    <option value="" disabled>Show per page</option>
                    <option value="1">1</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>


                {/* Filter Actions */}
                <div className="filter-item" style={{ display: 'flex', gap: '10px' }}>
                  <button className="i-btn info--btn btn--md" type="submit">
                    <svg class="svg-inline--fa fa-magnifying-glass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style={{ width: '19px' }}>
                      <path fill="currentColor" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"></path></svg>
                    Search</button>
                  <a className="i-btn danger--btn btn--md text-white" href="http://localhost/getoneclick/admin/contacts/index"><i className="las la-sync"></i>Reset</a>
                  {Checkedrows.length > 0 ? (
                    <div className="statusUpdateBtn">
                      <a
                        className="i-btn success--btn btn--md bulkAction"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Bulk Actions"
                        onClick={() => setIsBulkActionModalOpen(true)} // Corrected: wrapped in a function
                      >
                        <svg
                          className="svg-inline--fa fa-gear"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="gear"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z"
                          />
                        </svg>
                        Action
                      </a>
                    </div>
                  ) : (<></>)}
                  <button className="i-btn primary--btn info--btn btn--md" onClick={handleCreateModal}>
                    <svg className="svg-inline--fa fa-plus" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="" style={{ width: '19px' }}><path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path></svg>Add New</button>
                </div>
              </div>
            </form>
          </div>

          {/* Table of Contacts */}
          <div className="card-body px-0">
            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="d-flex align-items-center">
                        <input
                          className="form-check-input mt-0 me-2 checkAll"
                          type="checkbox"
                          checked={allChecked}
                          onChange={handleSelectAll}
                          value=""
                          aria-label="Checkbox for following text input" />
                        <span>Sl No.</span>
                      </div>
                    </th>
                    <th>Contact Name</th>
                    <th>Group</th>
                    <th>SMS Contact</th>
                    <th>WhatsApp Number</th>
                    <th>Email address</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {contactData.map((contact, index) => (
                    <tr key={contact.uid}>
                      <td className="d-none d-sm-flex align-items-center">
                        <input
                          className="form-check-input mt-0 me-2"
                          type="checkbox"
                          name="contactUid"
                          value={contact.uid}
                          checked={contact.isChecked}
                          onChange={() => handleRowCheck(contact.id)}
                          aria-label="Checkbox for following text input"
                        />
                        {index + 1} {/* Display the contact's index */}
                      </td>
                      <td className="text-capitalize" data-label="Contact Name">
                        {contact.first_name} {contact.last_name}
                      </td>
                      <td data-label="Group">
                        <a
                          href={`http://localhost/getoneclick/admin/contacts/groups/index/${contact.group_id}`}
                          className="badge badge--primary p-2"
                        >
                          View: {contact.groups.name}
                        </a>
                      </td>
                      <td data-label="SMS Contact">{contact.sms_contact}</td>
                      <td data-label="WhatsApp Number">{contact.whatsapp_contact}</td>
                      <td data-label="Email address">{contact.email_contact}</td>
                      <td data-label="Status">
                        <span
                          className={`badge badge--${contact.status == '1' ? 'success' : 'danger'
                            }`}
                        >
                          {contact.status == '1' ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                      <td data-label="Action">
                        <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                          <a
                            className="i-btn info--btn btn--sm contact-details"
                            data-bs-toggle="modal"
                            data-bs-target="#contactInfo"
                          >
                            <i className="las la-info-circle"></i>
                          </a>
                          <a
                            className="i-btn primary--btn btn--sm contact"
                            onClick={() => handeleediterow(contact)}
                          >
                            <i className="las la-pen"></i>
                          </a>
                          <a
                            className="i-btn danger--btn btn--sm delete"
                            onClick={() => handeleDeleteGroup(contact)}
                          >
                            <i className="las la-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </table>

            </div>
            <div className="pagination-container">

              <ul className="pagination">
                {pageNumbers.map((number) => (
                  <li
                    key={number}
                    className={`page-item ${currentPage === number ? 'active' : ''}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(number)}
                    >
                      {number}
                    </button>
                  </li>
                ))}
              </ul>


            </div>
          </div>

          {isUpdateGroupModalOpen && (
            <UpdateContactModal
              onClose={() => setIsUpdateGroupModalOpen(false)}
              contactData={selectedrow}
              groups={groupData}
              fetchData={getdata}
            />
          )}

          {isDeleteGroupModalOpen && (
            <DeleteGroupModal
              onClose={() => setIsDeleteGroupModalOpen(false)}
              data={selectedrow}
              fetchData={getdata}
              type={'contacts'}
            />
          )}

          {isBulkActionModalOpen && (
            <BulkActionModal onClose={() => setIsBulkActionModalOpen(false)} selectedRows={Checkedrows} fetchData={getdata} setAllChecked={setAllChecked} type={'contacts'}/>
          )}

        </div>
      </section>
    </div>
  );
}


export default Dashboard;
