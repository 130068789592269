import React, { useState, useEffect } from 'react';
import { uploadFile, getuserdata } from '../../services/apiService'; // Ensure the API method supports FormData
import FolderTree from './FolderTree'; // Import the recursive folder component
import { ToastContainer, toast } from 'react-toastify';
import { OneKlicksFileSDK } from '../../OneKlicksFileSDK';
import { oneclick } from '../../oneclick'; // Adjust import for your oneclick API service
import database_id from '../../utils/databaseConfig';

const UploadFolder = ({ showModal, handleClose, onUploadFile, path, folderId, loginToken, newBucket_id, onFolderClickredirect, handlePageChange }) => {


    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0); // Progress state to track upload progress
    const [stats, setStats] = useState(false);
    const [currentFolderIddata, setCurrentFolderIddata] = useState({});
    const [folderRoot, setFolderRoot] = useState('');
    const [currentFolderId, setCurrentFolderId] = useState('');
    const [fileStatus, setFileStatus] = useState('private'); // New state for file status

    // Assuming `path` is derived from currentPath or passed as a prop
    const pathString = Array.isArray(path) && path.length > 0
        ? ['Home', ...path.map(item => item.fileName)].join(' / ')  // Extract fileName from each object
        : 'Home';

    // Update folderRoot and currentFolderId whenever path or folderId changes
    useEffect(() => {
        getfolderdata();
        setCurrentFolderId(folderId);
    }, [pathString, folderId]);

    const getfolderdata = async () => {

        const api_key = await getuserdata("api_key");  // Assuming getuserdata is defined to fetch user info

        const tableSchema = database_id;
        const tableName = "cloud_storage";
        const companyId = await getuserdata("company_id");

        const conditions = {
            id: folderId,
            company_id: companyId,
        };

        const orderBy = "id DESC";
        const pagination = { page: 1 || 1, limit: 10 };

        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, "", pagination, "");
        if (response.success) {
            setCurrentFolderIddata(response.data[0]);
            setFolderRoot(response.data[0].root + '/' + response.data[0].file_name);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const companyId = await getuserdata("company_id");  // Assuming getuserdata is defined to fetch user info
        const api_key = await getuserdata("api_key");  // Assuming getuserdata is defined to fetch user info
        if (!companyId) {
            toast.error('Company ID is missing.');
            return;
        }

        if (!api_key) {
            toast.error('API key is missing.');
            return;
        }

        if (selectedFile) {
            // Create FormData to handle file and other params
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('login_token', loginToken);  // Fix the typo from 'login_tockin'
            formData.append('folderId', currentFolderId);
            formData.append('root', pathString);  // Updated path string
            formData.append('company_id', companyId);
            formData.append('Bucket_idold', newBucket_id);
            formData.append('stats', stats);
            formData.append('fileStatus', fileStatus);

            // Call the upload file API with progress tracking
            const response = await OneKlicksFileSDK.uploadFoldersdk(api_key, currentFolderId, newBucket_id, fileStatus, selectedFile, (progress) => {
                setProgress(progress); // Update progress state on upload progress
            });

            console.log('Upload response:', response);

            if (response.success) {
                toast.success('File uploaded successfully!');
                if (currentFolderId) {
                    onFolderClickredirect();
                } else {
                    handlePageChange();
                }
                handleClose(); // Close the modal after successful upload
            } else {
                toast.error('File upload failed!');
            }
        }
    };

    const newhandleClose = () => {
        handleClose();
        setProgress(0); // Reset progress state on modal close
    }

    return (
        <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" aria-labelledby="uploadFileModalLabel" aria-hidden={!showModal} style={{ display: showModal ? 'block' : 'none' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="uploadFileModalLabel">Upload Zip File</h5>
                        <button type="button" className="btn-close" onClick={newhandleClose} aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="file" className="form-label">Select Zip File</label>
                                <input type="file" className="form-control" id="file" accept=".zip" name="file" onChange={(e) => setSelectedFile(e.target.files[0])} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="folder_root" className="form-label">Current Path:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="folder_root"
                                    name="folder_root"
                                    value={folderRoot}
                                    readOnly
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="file_status" className="form-label">File Status:</label>
                                <select
                                    id="file_status"
                                    className="form-select"
                                    value={fileStatus}
                                    onChange={(e) => setFileStatus(e.target.value)} // Update file status on change
                                >
                                    <option value="private">Private</option>
                                    <option value="public">Public</option>
                                </select>
                            </div>
                            {progress > 0 && (
                                <>
                                    {progress === 100 && (
                                        <label htmlFor="file" className="form-label">Extracting ZIP file...</label>
                                    )}
                                    <div className="progress">
                                        <div
                                            className="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar"
                                            style={{ width: `${progress}%` }}
                                            aria-valuenow={progress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {progress}%
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={newhandleClose}>Close</button>
                            <button type="submit" className="btn btn-primary">Upload</button>
                        </div>
                    </form>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default UploadFolder;
