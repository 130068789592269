import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import axios from 'axios';
import './VoiceAssistant.css';
import { OpenAIResponse } from '../services/apiService';


const VoiceAssistant = () => {
    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            alert('Your browser does not support speech recognition.');
            console.error("Speech recognition not supported in this browser.");
        }

        // Load voices and set default
        const loadVoices = () => {
            const availableVoices = window.speechSynthesis.getVoices();
            setVoices(availableVoices);

            // Set default to the voice with id 10 if available
            const defaultVoice = availableVoices[10];

            if (defaultVoice) {
            setSelectedVoice(defaultVoice); // Set preferred default voice
            } else if (availableVoices.length > 0) {
            setSelectedVoice(availableVoices[0]); // Fallback to the first available voice
            }
        };

        // Load voices when they change
        loadVoices();
        window.speechSynthesis.onvoiceschanged = loadVoices;
    }, [browserSupportsSpeechRecognition]);


    const getAIResponse = async (input) => {
        try {
            const response = await OpenAIResponse(input, "", "", "");
            console.log("OpenAI Response:", response); // Debugging
            return response.reply || "No response received.";
        } catch (error) {
            console.error("Error fetching AI response:", error);
            return "I'm sorry, I couldn't process that request.";
        }
    };

    const speak = (text) => {
        if (!window.speechSynthesis) {
            console.error("Speech synthesis not supported in this browser.");
            return;
        }

        if (isSpeaking) {
            console.log("Already speaking. Skipping overlapping speech.");
            return;
        }

        setIsSpeaking(true);
        const speech = new SpeechSynthesisUtterance(text);
        speech.lang = 'en-US';

        if (selectedVoice) {
            speech.voice = selectedVoice; // Use the selected voice
        }

        speech.onend = () => setIsSpeaking(false);
        speech.onerror = () => setIsSpeaking(false);

        window.speechSynthesis.speak(speech);
    };

    useEffect(() => {
        const handleAIResponse = async () => {
            if (transcript) {
                const aiResponse = await getAIResponse(transcript);
                speak(aiResponse);
                resetTranscript();
            }
        };

        handleAIResponse();
    }, [transcript, resetTranscript]);

    const handleSendMessage = async () => {
        if (userInput.trim()) {
            const aiResponse = await getAIResponse(userInput);
            speak(aiResponse);
            setUserInput('');
        }
    };

    return (
        <div className="voice-assistant-widget">
            <div
                className={`voice-assistant-toggle ${isOpen ? 'open' : ''}`}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                💬
            </div>
            {isOpen && (
                <div className="voice-assistant-content">
                    <h4>Voice Assistant</h4>
                    <button onClick={SpeechRecognition.startListening}>🎙️ Start Listening</button>
                    <button onClick={resetTranscript}>Reset</button>
                    <p>{transcript || 'Speak a command...'}</p>

                    {/* Voice Selection */}
                    {/* <select
                        value={voices.indexOf(selectedVoice)}
                        onChange={(e) => setSelectedVoice(voices[e.target.value])}
                    >
                        {voices.map((voice, index) => (
                            <option key={index} value={index} selected={index === 10}>
                                {voice.name} ({voice.lang})
                            </option>
                        ))}
                    </select> */}

                    {/* Chat Input */}
                    <input
                        type="text"
                        className='form-control'
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSendMessage();
                            }
                        }}
                        placeholder="Type a message..."
                    />
                    <button onClick={handleSendMessage}>Send</button>
                </div>
            )}
        </div>
    );
};

export default VoiceAssistant;
