import React, { useState, useEffect } from 'react';
import { getImageBaseUrl, sendEmail } from '../../services/apiService'; // Import sendEmail function
import { oneclick } from '../../oneclick';
import database_id from '../../utils/databaseConfig';

const CompanyInfoCard = ({ companyData }) => {
  const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl
  const [showEmailSettings, setShowEmailSettings] = useState(false); // State for showing email settings form
  const [smtpConfig, setSmtpConfig] = useState(() => {
    // Decode the JSON string into an object
    const parsedConfig = companyData?.smtp_config ? JSON.parse(companyData.smtp_config) : {};
    
    return {
      host: parsedConfig.host || '', // Host from smtp_config or empty string
      port: parsedConfig.port || '', // Port from smtp_config or empty string
      secure: parsedConfig.secure || false, // Secure field initialization
      auth: {
        user: parsedConfig.auth?.user || '', // User can enter their email here
        pass: parsedConfig.auth?.pass || '', // User can enter their password here
      },
    };
  });
  
  const [emailDetails, setEmailDetails] = useState({
    email: companyData?.email || '',
    subject: 'Test Email',
    text: 'This is a test email sent using Postman.',
  });

  useEffect(() => {
    // Set image base URL when the component mounts
    setImageBaseUrl(getImageBaseUrl());
  }, []);

  // Toggle email settings form visibility
  const toggleEmailSettings = () => {
    setShowEmailSettings(!showEmailSettings);
  };

  // Function to handle sending the test email
  const handleSendTestEmail = async () => {
    try {
      await sendEmail(smtpConfig, emailDetails.email, emailDetails.subject, emailDetails.text);
      alert('Test email sent successfully!'); // Notify user of success
    } catch (error) {
      alert('Failed to send test email.'); // Notify user of failure
      console.error('Error sending test email:', error);
    }
  };

  // Function to handle saving SMTP settings
  const handleSaveSettings = async () => {
    const tableSchema = database_id;
    const tablecompanies = "companies";
    const tabledata = {
      'smtp_config': JSON.stringify(smtpConfig)
    };
    const conditions = { "id": companyData.id };
    
    try {
      const updatedata = await oneclick.update(tableSchema, tablecompanies, tabledata, conditions);
      if (updatedata.success) {
        localStorage.setItem('smtpConfig', JSON.stringify(smtpConfig)); // Save to local storage
        alert('SMTP settings saved successfully!'); // Notify user of success
        toggleEmailSettings(); // Optionally hide the settings form after saving
      } else {
        console.error("Error updating branch:");
        alert(updatedata.error || 'Error SMTP settings saved.');
      }
    } catch (error) {
      console.error("Error updating SMTP settings:", error);
      alert('Failed to save SMTP settings.');
    }
  };

  return (
    <div className="col-xl-3 col-lg-4">
      <div className="card profile-card">
        <div className="card-header">
          <h4 className="card-title">Company Information</h4>
        </div>
        <div className="card-body">
          <div className="d-flex p-2 bg--lite--violet align-items-center">
            <div className="avatar avatar--lg">
              <img src={`${imageBaseUrl}/assets/file/images/${companyData.logo}`} alt="Company Logo" />
            </div>
            <div className="pl-3">
              <h5 className="text--light m-0 p-0">{companyData.name}</h5>
            </div>
          </div>
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <span className="font-weight-bold">{companyData.phone}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <span className="font-weight-bold">{companyData.email}</span>
            </li>
          </ul>
          <div className="d-flex justify-content-between mt-3">
            <h5>Email Settings</h5>
            <button className="btn btn-secondary" onClick={toggleEmailSettings}>View</button>
          </div>
          {showEmailSettings && (
            <div className="mt-3">
              <form>
                <div className="mb-3">
                  <label htmlFor="smtpServer" className="form-label">SMTP Server</label>
                  <input
                    type="text"
                    id="smtpServer"
                    className="form-control"
                    value={smtpConfig.host}
                    onChange={(e) => setSmtpConfig({ ...smtpConfig, host: e.target.value })}
                    placeholder="Enter SMTP Server"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="smtpPort" className="form-label">SMTP Port</label>
                  <input
                    type="number"
                    id="smtpPort"
                    className="form-control"
                    value={smtpConfig.port}
                    onChange={(e) => setSmtpConfig({ ...smtpConfig, port: e.target.value })}
                    placeholder="Enter SMTP Port"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    value={smtpConfig.auth.user}
                    onChange={(e) => setSmtpConfig({ ...smtpConfig, auth: { ...smtpConfig.auth, user: e.target.value } })}
                    placeholder="Enter Your Email"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    value={smtpConfig.auth.pass}
                    onChange={(e) => setSmtpConfig({ ...smtpConfig, auth: { ...smtpConfig.auth, pass: e.target.value } })}
                    placeholder="Enter Your Password"
                  />
                </div>
                <button type="button" className="btn btn-success" onClick={handleSendTestEmail}>Test</button>
                <button type="button" className="btn btn-success ms-2" onClick={handleSaveSettings}>Save Settings</button>
                <button type="button" className="btn btn-secondary ms-2" onClick={toggleEmailSettings}>Cancel</button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoCard;
