import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { updateRecord } from "../../../services/apiService";

// Function to format date to MySQL-friendly format
const formatDate = (date) => {
  const newDate = new Date(date);
  return newDate.toISOString().slice(0, 19).replace('T', ' '); // Convert to 'YYYY-MM-DD HH:MM:SS' format
};

const RowEditeModal = ({ show, handleClose, fields, columns, tablename, rowid, ip, username, password, dbName, getallcoloumns, onSubmit }) => {
    // Map COLUMN_NAME from columns to values from fields
    const initialFormData = columns.reduce((acc, column) => {
        let fieldValue = fields[column.COLUMN_NAME];
        // If it's a datetime column, format it
        if (column.DATA_TYPE === 'timestamp' && fieldValue) {
            fieldValue = formatDate(fieldValue);
        }
        acc[column.COLUMN_NAME] = fieldValue !== null && fieldValue !== undefined ? fieldValue : "";
        return acc;
    }, {});

    const [formData, setFormData] = useState(initialFormData);
    const [focusedField, setFocusedField] = useState(null); // Track the focused field

    const handleInputChange = (columnName, value) => {
        setFormData((prev) => ({ ...prev, [columnName]: value }));
    };

    const handleSubmit = async () => {
        try {
            // Adjust formData to convert empty strings for integer fields to null
            const updatedFormData = Object.keys(formData).reduce((acc, key) => {
                if (columns.find(col => col.COLUMN_NAME === key && (col.DATA_TYPE === 'int' || col.DATA_TYPE === 'tinyint' || col.DATA_TYPE === 'timestamp')) 
                    && formData[key] === "") {
                    acc[key] = null; // Set to null for integer fields with empty values timestamp
                } else {
                    acc[key] = formData[key];
                }
                return acc;
            }, {});
    
            // Sending PUT request to the backend to update the row
            const response = await updateRecord(ip, username, password, dbName, tablename, rowid, updatedFormData);
            
            if (response.success) {
                alert('Record updated successfully');
                
                handleClose();
                getallcoloumns();
            }
        } catch (error) {
            console.error('Error updating record:', error);
            alert('Error updating record');
        }
    };

    const handleFocus = (columnName) => {
        setFocusedField(columnName); // Set focused field when input is focused
    };

    const handleBlur = () => {
        setFocusedField(null); // Clear focused field when input loses focus
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{`Edit -> ${tablename} -> ${rowid}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="dynamic-form">
                    {columns.map((column, index) => (
                        <div key={index} className="form-row d-flex" style={{ marginBottom: '10px' }}>
                            <div className="col-lg-6 col-sm-6">
                                <label className="form-label">{column.COLUMN_NAME}</label>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div style={{ height: '40px' }}>
                                    {column.DATA_TYPE === "varchar" || column.DATA_TYPE === "text" || column.DATA_TYPE === "longtext" ? (
                                        <textarea
                                            readOnly={column.COLUMN_NAME === "id"}
                                            className="form-input form-control"
                                            value={formData[column.COLUMN_NAME] || ""}
                                            placeholder={`Enter ${column.COLUMN_NAME}`}
                                            onChange={(e) => handleInputChange(column.COLUMN_NAME, e.target.value)}
                                            onFocus={() => handleFocus(column.COLUMN_NAME)}
                                            onBlur={handleBlur}
                                            style={{
                                                position: "absolute",
                                                height: focusedField === column.COLUMN_NAME ? "" : "40px",
                                                resize: "both",
                                                overflow: "auto",
                                                width: focusedField === column.COLUMN_NAME ? "" : "208px",
                                                zIndex: focusedField === column.COLUMN_NAME ? 99 : 1,
                                            }}
                                        />
                                    ) : column.DATA_TYPE === "timestamp" ? (
                                        <input
                                            type="datetime-local"
                                            readOnly={column.COLUMN_NAME === "id"}
                                            className="form-input form-control"
                                            value={formData[column.COLUMN_NAME] || ""}
                                            placeholder={`Enter ${column.COLUMN_NAME}`}
                                            onChange={(e) => handleInputChange(column.COLUMN_NAME, e.target.value)}
                                        />
                                    ) : (
                                        <input
                                            type={
                                                column.DATA_TYPE === "int" || column.DATA_TYPE === "tinyint"
                                                    ? "number"
                                                    : column.DATA_TYPE === "timestamp"
                                                        ? "datetime-local"
                                                        : "text" // Default input type
                                            }
                                            readOnly={column.COLUMN_NAME === "id"}
                                            className="form-input form-control"
                                            value={formData[column.COLUMN_NAME] || ""}
                                            placeholder={`Enter ${column.COLUMN_NAME}`}
                                            onChange={(e) => handleInputChange(column.COLUMN_NAME, e.target.value)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RowEditeModal;
