import React, { useState } from "react";
import { useCodeMirror } from "@uiw/react-codemirror";
import { useLocation, useParams } from 'react-router-dom';
import { sql } from "@codemirror/lang-sql";
import { oneDark } from "@codemirror/theme-one-dark";
import axios from "axios";
import { decodeBase64, executeSql } from "../../services/apiService";
import JsonCodeDisplay from "../../utils/JsonCodeDisplay";

const SqlEditor = () => {
    const location = useLocation();
    const { encodedatabasename, encodeserver, encodeusername, encodepassword, encodetablename } = useParams(); // Retrieve the URL parameter
    const databasename = decodeBase64(encodedatabasename);
    const server = decodeBase64(encodeserver);
    const username = decodeBase64(encodeusername);
    const password = decodeBase64(encodepassword);
    const tablename = decodeBase64(encodetablename);
    const [sqlCode, setSqlCode] = useState("");
    const [executionResult, setExecutionResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const editorRef = React.useRef();

    const { setContainer } = useCodeMirror({
        container: editorRef.current,
        value: sqlCode,
        extensions: [sql()],
        theme: oneDark,
        onChange: (value) => {
            setSqlCode(value);
        },
    });

    // Attach the container to the editor once the component is mounted
    React.useEffect(() => {
        if (editorRef.current) {
            setContainer(editorRef.current);
        }
    }, [editorRef, setContainer]);

    // Function to handle SQL query execution
    const executeSqlnew = async () => {
        setIsLoading(true);
        setError(null);
        setExecutionResult(null);

        try {
            const response = await executeSql(server, username, password, databasename, sqlCode); // Replace with your API endpoint
            if(response.success){
                alert(response.message);
                setExecutionResult(response.result); // Expected to return result or success message
            }else{
                alert(response.message);
            }
        } catch (err) {
            setError(err.response?.data?.message || "Failed to execute SQL query.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
            <h6 style={{marginBottom: '20px'}}>SQL Code Editor → {databasename}{tablename == "demo" ? ``: ` → ${tablename}`}</h6>
            <dr></dr>

            {/* SQL Code Editor */}
            <div
                ref={editorRef}
                style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    fontSize: "14px",
                    minHeight: "200px",
                    background: "#2a313d",
                }}
            />

            {/* Execute Button */}
            <button
                onClick={executeSqlnew}
                disabled={isLoading || !sqlCode.trim()}
                style={{
                    marginTop: "10px",
                    padding: "10px 20px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                }}
            >
                {isLoading ? "Executing..." : "Execute SQL"}
            </button>

            {/* Error Message */}
            {error && (
                <div style={{ color: "red", marginTop: "10px" }}>
                    <strong>Error:</strong> {error}
                </div>
            )}

            {/* Execution Results */}
            {executionResult && (
                <div style={{ marginTop: "20px" }}>
                    <h3>Execution Result:</h3>
                    {typeof executionResult === "object" ? (
                        <pre
                            style={{
                                background: "black",
                                padding: "10px",
                                borderRadius: "5px",
                                overflowX: "auto",
                                color: "white",
                            }}
                        >
                            <JsonCodeDisplay executionResult={executionResult} />
                        </pre>
                    ) : (
                        <p>{executionResult}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default SqlEditor;
