import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (token) => {
    if (!token) return true;

    try {
        const { exp } = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return exp < currentTime; // Return true if expired
    } catch (error) {
        console.error('Failed to decode token:', error);
        return true;
    }
};
