import React, { useState } from 'react';
import AddEmailModal from './Components/AddNewMail';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import AddNewWebsiteModal from '../seo/Component/AddNewWebsiteModal';
import { Link, useNavigate } from 'react-router-dom';

const EmailsList = () => {
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [emailToDelete, setEmailToDelete] = useState(null);
  const [emailToEdit, setEmailToEdit] = useState(null);
  const [editedEmail, setEditedEmail] = useState({ username: '', mail: '', status: '', domain: '' });
  const [newEmail, setNewEmail] = useState({ username: '', domain: '', status: '' });
  const [emailData, setEmailData] = useState([]);
  const [showWebsiteModal, setShowWebsiteModal] = useState(false);
  const [showWebsitesTable, setShowWebsitesTable] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleDelete = (id) => {
    console.log(`Delete campaign with id: ${id}`);
    setEmailToDelete(null);
  };

  const handleEdit = (id) => {
    const emailToEdit = emailData.find(email => email.id === id);
    setEditedEmail(emailToEdit);
    setEmailToEdit(id);
  };

  const handleEditSubmit = () => {
    console.log(`Edit campaign with id: ${emailToEdit}`, editedEmail);
    setEmailToEdit(null);
  };

  const handleLogin = (mail) => {
    console.log(`Login with email: ${mail}`);
  };

  const handleAddEmail = (email) => {
    const newEmailEntry = { id: emailData.length + 1, ...email, mail: `${email.username}@${email.domain}` };
    setEmailData([...emailData, newEmailEntry]);
    setNewEmail({ username: '', domain: '', status: '' }); // Reset newEmail state

    // Close the modal manually
    const modal = document.getElementById('addNew');
    const modalInstance = window.bootstrap.Modal.getInstance(modal); // Use window.bootstrap here
    if (modalInstance) {
      modalInstance.hide();
    }
  };

  const filteredEmails = emailData.filter(email =>
    (status ? email.status === status : true) &&
    (search ? email.username.toLowerCase().includes(search.toLowerCase()) : true)
  );

  const indexOfLastEmail = currentPage * itemsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - itemsPerPage;
  const currentEmails = filteredEmails.slice(indexOfFirstEmail, indexOfLastEmail);
  const totalPages = Math.ceil(filteredEmails.length / itemsPerPage);

  const handeleshowallwebsites = () => {
    navigate('/seo_data_add/true'); // If no 'from' state exists, navigate to dashboard
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Email List</h4>
            </div>

            <div className="card-filter">
              <form onSubmit={handleFormSubmit}>
                <div className="filter-form">
                  <div className="filter-item">
                    <input
                      type="text"
                      autoComplete="off"
                      name="search"
                      placeholder="Search With Username"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <div className="filter-item">
                    <select
                      name="status"
                      className="form-select"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="DeActive">DeActive</option>
                      <option value="Completed">Completed</option>
                      <option value="Ongoing">Ongoing</option>
                    </select>
                  </div>

                  <div className="filter-action">
                    <button onClick={() => handeleshowallwebsites()} className="i-btn btn--primary btn--md">
                      <i className="las la-plus"></i> View All Websites
                    </button>
                    <button
                      type="button"
                      className="i-btn danger--btn btn--md"
                      onClick={() => { setSearch(''); setStatus(''); setCurrentPage(1); }}
                    >
                      Reset
                    </button>

                    {/* Add New Button */}
                    <button
                      type="button"
                      className="i-btn primary--btn btn--md text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#addNew"
                    >
                      Add New
                    </button>
                    <button onClick={() => setShowWebsiteModal(true)} className="i-btn btn--primary btn--md">
                      <i className="las la-plus"></i> Add New Website
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-body px-0">
              <div className="responsive-table">
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Username</th>
                      <th>Mail</th>
                      <th>Status</th>
                      <th>Domain</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEmails.length > 0 ? (
                      currentEmails.map(email => (
                        <tr key={email.id}>
                          <td>{email.id}</td>
                          <td>{email.username}</td>
                          <td>{email.mail}</td>
                          <td>{email.status}</td>
                          <td>{email.domain}</td>
                          <td>
                            <div className="button-group" style={{ display: 'flex', gap: '10px' }}>
                              <button
                                className="i-btn primary--btn btn--md"
                                onClick={() => handleEdit(email.id)}
                                data-bs-toggle="modal"
                                data-bs-target="#edit"
                              >
                                Edit
                              </button>
                              <button
                                className="i-btn info--btn btn--md"
                                onClick={() => handleLogin(email.mail)}
                              >
                                Login
                              </button>
                              <button
                                className="i-btn danger--btn btn--md"
                                data-bs-toggle="modal"
                                data-bs-target="#delete"
                                onClick={() => setEmailToDelete(email.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-muted text-center" colSpan="6">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    type="button"
                    className={`btn btn-outline-primary ${currentPage === index + 1 ? 'active' : ''}`}
                    style={{ margin: '0px 5px' }}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add New Website Modal */}
      <AddNewWebsiteModal showWebsiteModal={showWebsiteModal} setShowWebsiteModal={() => setShowWebsiteModal(false)} />
      {/* Modal for adding new email */}
      <AddEmailModal newEmail={newEmail} setNewEmail={setNewEmail} onAddEmail={handleAddEmail} />

      {/* Modal for editing email */}
      <div className="modal fade" id="edit" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editModalLabel">Edit Email</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <select
                value={editedEmail.username}
                disabled // Prevent editing the username
                className="form-select mb-2"
              >
                <option value={editedEmail.username}>{editedEmail.username}</option>
              </select>
              <select
                value={editedEmail.domain}
                onChange={(e) => setEditedEmail({ ...editedEmail, domain: e.target.value })}
                className="form-select mb-2"
              >
                <option value="example.com">example.com</option>
                <option value="another.com">another.com</option>
              </select>
              <select
                value={editedEmail.status}
                onChange={(e) => setEditedEmail({ ...editedEmail, status: e.target.value })}
                className="form-select mb-2"
              >
                <option value="Active">Active</option>
                <option value="DeActive">DeActive</option>
                <option value="Completed">Completed</option>
                <option value="Ongoing">Ongoing</option>
              </select>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleEditSubmit}>Save Changes</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for deleting email */}
      <div className="modal fade" id="delete" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Email</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this email?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-danger" onClick={() => handleDelete(emailToDelete)}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailsList;
