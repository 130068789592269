import React, { useState } from "react";

const EmailGateway = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    address: "",
    status: "1",
    gatewayType: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Data: ", formData);
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="table_heading d-flex align--center justify--between">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="http://localhost/getoneclick/admin/mail/gateways">
                    Mail Configuration
                  </a>
                </li>
              </ol>
            </nav>
          </div>

          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Email Gateway List</h4>
              <div className="d-flex justify-content-end">
                <form
                  action="javascript:void(0)"
                  className="testDefaultGateway"
                  method="post"
                >
                  <div className="input-group w-100">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Type Mail Address"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <button
                      className="i-btn info--btn btn--md"
                      id="basic-addon2"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Test Default Gateway
                    </button>
                  </div>
                </form>
                <a
                  href="#" onClick={(e) => e.preventDefault()}
                  className="ms-3 i-btn primary--btn btn--md text-white"
                  data-bs-toggle="modal"
                  data-bs-target="#addgateway"
                  title="Add New Gateway"
                >
                  Add New
                </a>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="responsive-table">
                <table>
                  <thead>
                    <tr>
                      <th>Sl No.</th>
                      <th>Gateway type</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Status</th>
                      <th>Default</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td className="d-none d-sm-flex align-items-center">1</td>
                      <td data-label="Name">SMTP</td>
                      <td data-label="Name">Getone Click</td>
                      <td data-label="Address">info@getone.click</td>
                      <td data-label="Status">
                        <span className="badge badge--success">Active</span>
                      </td>
                      <td className="text-center" data-label="Default">
                        <div className="d-flex justify-content-md-start justify-content-end">
                          <i
                            className="las la-check-double text--success"
                            style={{ fontSize: "32px" }}
                          ></i>
                        </div>
                      </td>
                      <td data-label="Action">
                        <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                          <a
                            className="i-btn info--btn btn--sm gateway-details"
                            data-bs-placement="top"
                            title="Gateway Information"
                            data-bs-toggle="modal"
                            data-bs-target="#gatewayInfo"
                          >
                            <i className="las la-info-circle"></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="i-btn success--btn btn--sm edit-gateway"
                            data-bs-toggle="modal"
                            data-bs-target="#editgateway"
                            data-id="12"
                          >
                            <i className="las la-pen"></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="i-btn danger--btn btn--sm delete"
                            data-bs-toggle="modal"
                            data-bs-target="#gatewayDelete"
                          >
                            <i className="las la-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="m-3"></div>
            </div>
          </div>
        </div>

        {/* Modal: Gateway Information */}
        <div
          className="modal fade"
          id="gatewayInfo"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog nafiz">
            <div className="modal-content">
              <div className="modal-body">
                <div className="card">
                  <div className="card-header bg--lite--violet">
                    <div className="card-title text-center text--light">
                      Gateway Information
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="driver-info"></div>
                  </div>
                </div>
              </div>
              <div className="modal_button2 modal-footer">
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <button
                    type="button"
                    className="i-btn primary--btn btn--md"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal: Delete Gateway */}
        <div
          className="modal fade"
          id="gatewayDelete"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <form action="#" method="GET">
                <div className="modal_body2">
                  <div className="modal_icon2">
                    <i className="las la-trash"></i>
                  </div>
                  <div className="modal_text2 mt-4">
                    <h5>Are you sure to delete this gateway</h5>
                  </div>
                </div>
                <div className="modal_button2 modal-footer">
                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <button
                      type="button"
                      className="i-btn danger--btn btn--md"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="i-btn primary--btn btn--md">
                      Delete
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmailGateway;
