import React, { useState } from 'react';

const SubscriptionPlanForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        duration: '',
        description: '',
        amount: '',
        status: '1', // Default to Active
        allowCarryForward: false,
        allowAdminCreds: false,
        allowAdminSms: false,
        smsCreditAdmin: '',
        allowAdminEmail: false,
        emailCreditAdmin: '',
        allowAdminWhatsapp: false,
        whatsappCreditAdmin: '',
        whatsappDeviceLimit: 0,
        allowUserAndroid: false,
        userAndroidGatewayLimit: 0,
        smsGateway: false,
        smsCreditUser: '',
        mailMultiGateway: false,
        mailGatewaySelect: '',
        emailCreditUser: '',
        allowUserWhatsapp: false,
        userWhatsappDeviceLimit: 0,
        whatsappCreditUser: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // Handle form submission logic here
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <input type="hidden" name="_token" value="YAKTGtBrrXFPPud2uymPDNRURdNpHLutkPNzgKPN" />
                                <div className="form-wrapper">
                                    <div className="form-wrapper-title">
                                        <h6>Add A New Subscription Plan</h6>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-sm-6">
                                            <label htmlFor="name" className="form-label">Name <sup className="text--danger">*</sup></label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="form-control"
                                                placeholder="Enter Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3 col-sm-6">
                                            <label htmlFor="duration" className="form-label">Duration <sup className="text--danger">*</sup></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="duration"
                                                    name="duration"
                                                    placeholder="Enter Duration"
                                                    value={formData.duration}
                                                    onChange={handleChange}
                                                />
                                                <span className="input-group-text" id="basic-addon2">Days</span>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-sm-12">
                                            <label htmlFor="description" className="form-label">Plan Description</label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                placeholder="Type plan description"
                                                value={formData.description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-xl-4 col-sm-6 mb-3">
                                            <label htmlFor="amount" className="form-label">Amount <sup className="text--danger">*</sup></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="amount"
                                                    name="amount"
                                                    placeholder="Enter Amount"
                                                    value={formData.amount}
                                                    onChange={handleChange}
                                                />
                                                <span className="input-group-text" id="basic-addon2">USD</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-sm-6 mb-3">
                                            <label htmlFor="status" className="form-label">Status <sup className="text--danger">*</sup></label>
                                            <select
                                                className="form-select"
                                                name="status"
                                                id="status"
                                                value={formData.status}
                                                onChange={handleChange}
                                            >
                                                <option value="1">Active</option>
                                                <option value="2">Inactive</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4 col-sm-12 mb-3">
                                            <label htmlFor="allow_carry_forward" className="form-label">Carry Forward <sup className="text--danger">*</sup></label>
                                            <div className="switch-container">
                                                <label className="form-check-label" htmlFor="allow_carry_forward">Turn On/Off <strong>Manual Renewals</strong></label>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        value="true"
                                                        name="allowCarryForward"
                                                        id="allow_carry_forward"
                                                        checked={formData.allowCarryForward}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-wrapper border--dark">
                                                        <div className="form-wrapper-title">User: SMS Section</div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-xxl-6">
                                                                <div className="row">
                                                                    <div className="mb-3 col-lg-12 user_android">
                                                                        <div className="switch-container">
                                                                            <label className="form-check-label" htmlFor="allow_user_android">Allow users to add <strong>Android Gateways</strong>
                                                                                <br />
                                                                                <p className="relative-note"><sup className="text--danger">*</sup>Enable unlimited Android Gateways if you set the value to '0'</p>
                                                                            </label>
                                                                            <label className="switch">
                                                                                <input
                                                                                    className="allow_user_sms"
                                                                                    type="checkbox"
                                                                                    value="true"
                                                                                    name="allowUserAndroid"
                                                                                    id="allow_user_android"
                                                                                    checked={formData.allowUserAndroid}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 mb-3">
                                                                        <label htmlFor="user_android_gateway_limit" className="form-label">Android Gateway Limit <sup className="text--danger">*</sup></label>
                                                                        <div className="input-group">
                                                                            <input
                                                                                value={formData.userAndroidGatewayLimit}
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="user_android_gateway_limit"
                                                                                name="userAndroidGatewayLimit"
                                                                                placeholder="Users can Add upto"
                                                                                aria-label="Android Gateway Limit"
                                                                                onChange={handleChange}
                                                                            />
                                                                            <span className="input-group-text" id="basic-addon2">Android Gateway Limit</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-xxl-6">
                                                                <div className="row">
                                                                    <div className="mb-3 col-lg-12">
                                                                        <div className="switch-container">
                                                                            <label className="form-check-label text-capitalize" htmlFor="sms_gateway">Allow users to make multiple <strong>SMS Gateways</strong>
                                                                                <br />
                                                                                <p className="relative-note"><sup className="text--danger">*</sup>Choose the amount of gateways users can create from each SMS gateway type</p>
                                                                            </label>
                                                                            <label className="switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value="true"
                                                                                    name="smsGateway"
                                                                                    id="sms_gateway"
                                                                                    className="sms_gateway allow_user_sms"
                                                                                    checked={formData.smsGateway}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 sms_gateway_options d-block">
                                                                        <label htmlFor="sms_gateways" className="form-label">SMS Gateway Selection <sup className="text--danger">*</sup></label>
                                                                        <div className="row g-3">
                                                                            <div className="col-md-9">
                                                                                <select
                                                                                    className="form-select"
                                                                                    name="mailGatewaySelect"
                                                                                    id="sms_gateways"
                                                                                    value={formData.mailGatewaySelect}
                                                                                    onChange={handleChange}
                                                                                >
                                                                                    <option value="" selected="" disabled="">Select One</option>
                                                                                    <option value="101NEXMO">NEXMO</option>
                                                                                    <option value="102TWILIO">TWILIO</option>
                                                                                    <option value="103MESSAGE_BIRD">MESSAGE BIRD</option>
                                                                                    <option value="104TEXT_MAGIC">TEXT MAGIC</option>
                                                                                    <option value="105CLICKA_TELL">CLICKA TELL</option>
                                                                                    <option value="106INFOBIP">INFOBIP</option>
                                                                                    <option value="107SMS_BROADCAST">SMS BROADCAST</option>
                                                                                    <option value="108MSG91">MSG91</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <button type="button" className="btn btn--md i-btn primary--btn" onClick={() => console.log('Gateway added')}>Add Gateway</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mt-3">
                                                                <div className="row">
                                                                    <div className="col-lg-6 mb-3">
                                                                        <label htmlFor="sms_credit_user" className="form-label">User SMS Credit <sup className="text--danger">*</sup></label>
                                                                        <input
                                                                            value={formData.smsCreditUser}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="sms_credit_user"
                                                                            name="smsCreditUser"
                                                                            placeholder="SMS Credit"
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-6 mb-3">
                                                                        <label htmlFor="mail_multi_gateway" className="form-label">Mail Multi Gateway <sup className="text--danger">*</sup></label>
                                                                        <div className="switch-container">
                                                                            <label className="form-check-label" htmlFor="mail_multi_gateway">Allow users to add <strong>Mail Gateways</strong>
                                                                                <br />
                                                                                <p className="relative-note"><sup className="text--danger">*</sup>Enable unlimited Mail Gateways if you set the value to '0'</p>
                                                                            </label>
                                                                            <label className="switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value="true"
                                                                                    name="mailMultiGateway"
                                                                                    id="mail_multi_gateway"
                                                                                    checked={formData.mailMultiGateway}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 mb-3 mail_gateway_options d-block">
                                                                        <label htmlFor="email_credit_user" className="form-label">User Email Credit <sup className="text--danger">*</sup></label>
                                                                        <input
                                                                            value={formData.emailCreditUser}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="email_credit_user"
                                                                            name="emailCreditUser"
                                                                            placeholder="Email Credit"
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-wrapper border--dark">
                                                        <div className="form-wrapper-title">User: Whatsapp Section</div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-xxl-6">
                                                                <div className="row">
                                                                    <div className="mb-3 col-lg-12">
                                                                        <div className="switch-container">
                                                                            <label className="form-check-label" htmlFor="allow_user_whatsapp">Allow users to add <strong>Whatsapp Gateways</strong>
                                                                                <br />
                                                                                <p className="relative-note"><sup className="text--danger">*</sup>Enable unlimited Whatsapp Gateways if you set the value to '0'</p>
                                                                            </label>
                                                                            <label className="switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value="true"
                                                                                    name="allowUserWhatsapp"
                                                                                    id="allow_user_whatsapp"
                                                                                    checked={formData.allowUserWhatsapp}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 mb-3">
                                                                        <label htmlFor="user_whatsapp_device_limit" className="form-label">Whatsapp Device Limit <sup className="text--danger">*</sup></label>
                                                                        <div className="input-group">
                                                                            <input
                                                                                value={formData.userWhatsappDeviceLimit}
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="user_whatsapp_device_limit"
                                                                                name="userWhatsappDeviceLimit"
                                                                                placeholder="Users can Add upto"
                                                                                aria-label="Whatsapp Device Limit"
                                                                                onChange={handleChange}
                                                                            />
                                                                            <span className="input-group-text" id="basic-addon2">Devices Limit</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-xxl-6">
                                                                <div className="row">
                                                                    <div className="mb-3 col-lg-12">
                                                                        <div className="switch-container">
                                                                            <label className="form-check-label text-capitalize" htmlFor="whatsapp_gateway">Allow users to make multiple <strong>Whatsapp Gateways</strong>
                                                                                <br />
                                                                                <p className="relative-note"><sup className="text--danger">*</sup>Choose the amount of gateways users can create from each Whatsapp gateway type</p>
                                                                            </label>
                                                                            <label className="switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value="true"
                                                                                    name="whatsappGateway"
                                                                                    id="whatsapp_gateway"
                                                                                    className="whatsapp_gateway allow_user_whatsapp"
                                                                                    checked={formData.whatsappGateway}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <span className="slider"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 whatsapp_gateway_options d-block">
                                                                        <label htmlFor="whatsapp_gateways" className="form-label">Whatsapp Gateway Selection <sup className="text--danger">*</sup></label>
                                                                        <div className="row g-3">
                                                                            <div className="col-md-9">
                                                                                <select
                                                                                    className="form-select"
                                                                                    name="mailGatewaySelect"
                                                                                    id="whatsapp_gateways"
                                                                                    value={formData.mailGatewaySelect}
                                                                                    onChange={handleChange}
                                                                                >
                                                                                    <option value="" selected="" disabled="">Select One</option>
                                                                                    <option value="201WATSM">WATSM</option>
                                                                                    <option value="202WA_SENDER">WA SENDER</option>
                                                                                    <option value="203SENDER_WA">SENDER WA</option>
                                                                                    <option value="204SENDER_DIAL">SENDER DIAL</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <button type="button" className="btn btn--md i-btn primary--btn" onClick={() => console.log('Gateway added')}>Add Gateway</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mt-3">
                                                                <div className="row">
                                                                    <div className="col-lg-6 mb-3">
                                                                        <label htmlFor="whatsapp_credit_user" className="form-label">User Whatsapp Credit <sup className="text--danger">*</sup></label>
                                                                        <input
                                                                            value={formData.whatsappCreditUser}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="whatsapp_credit_user"
                                                                            name="whatsappCreditUser"
                                                                            placeholder="Whatsapp Credit"
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-6 mb-3">
                                                                        <label htmlFor="whatsapp_device_limit" className="form-label">Whatsapp Device Limit <sup className="text--danger">*</sup></label>
                                                                        <input
                                                                            value={formData.whatsappDeviceLimit}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="whatsapp_device_limit"
                                                                            name="whatsappDeviceLimit"
                                                                            placeholder="Device Limit"
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <button type="submit" className="i-btn primary--btn btn--md text--light">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SubscriptionPlanForm;
