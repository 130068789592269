// src/context/SocketContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import apiUrl from '../utils/apiConfig';

// Create a Context for the socket
const SocketContext = createContext(null);

// SocketProvider Component
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize the socket connection
    const socketInstance = io(apiUrl); // Replace with your server URL

    socketInstance.on('connect', () => {
      console.log('Socket connected:', socketInstance.id);
      setSocket(socketInstance); // Set the socket in state
    });

    socketInstance.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    // Cleanup on component unmount
    return () => {
      socketInstance.disconnect(); // Disconnect the socket on unmount
      console.log('Socket disconnected');
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

// Custom hook to use the socket
export const useSocket = () => {
  return useContext(SocketContext);
};