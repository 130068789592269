import React from "react";
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installe
import { encodeBase64 } from "../../../services/apiService";
import Paginationnew from "../../../utils/Paginationnew";

const DatabaseTable = ({ currentData, passwordVisibility, togglePasswordVisibility, Link, totalPages, handlePageChange, currentPage, loading }) => {

    const server_ip = process.env.REACT_APP_WHM_IP;
    const handleDelete = (dbName) => {
        if (window.confirm(`Are you sure you want to delete the database: ${dbName}?`)) {
            // Add your delete logic here, such as calling an API to delete the database
        }
    };




    const handleview = ({ name }) => {
        alert('Username is empty. Please ensure the username is set for this user.');
    }

    return (
        <div className="card-body px-0">
            <div className="responsive-table">
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>DB ID</th>
                            <th>DB Name</th>
                            <th>Server Ip</th>
                            <th>Status</th>
                            <th>User</th>
                            <th>Password</th>
                            <th style={{ textAlign: 'right' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading == true ? (
                            <tr>
                                <td colSpan="8" style={{ textAlign: 'center' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p>Loading data, please wait...</p>
                                </td>
                            </tr>
                        ) : currentData && currentData.length > 0 ? (
                            currentData.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{index + 1}</td> {/* Display sequential numbers: 1, 2, 3, 4... */}
                                    <td>{item.db_uid}</td>
                                    <td>{item.db_name}</td>
                                    <td>{item.server}</td>
                                    <td>
                                        <div
                                            className={`status-indicator ${item.status === 'active' ? 'active' : 'inactive'} blinking`}
                                            title={item.status === 'active' ? 'Server is active' : 'Server is inactive'}
                                        ></div>
                                    </td>
                                    <td>{item.db_user_data ? item.db_user_data.db_username : 'No Username'}</td>
                                    <td>
                                        {item.db_user_data ? (
                                            passwordVisibility[item.id] ? (
                                                item.db_user_data.password
                                            ) : (
                                                '••••••••'
                                            )
                                        ) : (
                                            'No Password'
                                        )}
                                        {item.db_user_data && (
                                            <button
                                                onClick={() => togglePasswordVisibility(item.id)}
                                                style={{ marginLeft: '10px', cursor: 'pointer', border: 'none', background: 'none' }}
                                            >
                                                {passwordVisibility[item.id] ? (
                                                    <i className="fas fa-eye-slash"></i>
                                                ) : (
                                                    <i className="fas fa-eye"></i>
                                                )}
                                            </button>
                                        )}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {item.db_user_data && item.db_user_data.db_username ? (
                                            <Link
                                                to={`/real_time_database/tables/${encodeBase64(item.db_name)}/${encodeBase64(item.server)}/${encodeBase64(item.db_user_data.db_username)}/${encodeBase64(item.db_user_data.password || 'No Password')}`}
                                                className="btn btn-primary"
                                                style={{ height: '38px', marginRight: '10px' }}
                                            >
                                                <i className="fas fa-eye"></i>
                                            </Link>
                                        ) : (
                                            <button className="btn btn-primary" style={{ height: '38px', marginRight: '10px' }} onClick={() => handleview(item.db_name)}>
                                                <i className="fas fa-eye"></i>
                                            </button>
                                        )}
                                        <button className="btn btn-danger" style={{ height: '38px' }} onClick={() => handleDelete(item.id)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" style={{ textAlign: 'center' }}>
                                    <h2>No databases available</h2>
                                </td>
                            </tr>
                        )}
                    </tbody>



                </table>


            </div>
            <Paginationnew
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
            />
            <div className="m-3"></div>
        </div>
    );
};

export default DatabaseTable