import React, { useState } from 'react';

// Sample data for invoices
const invoiceData = [
  { id: 1, invoiceNumber: 'INV-001', customer: 'John Doe', amount: 250, status: 'unpaid' },
  { id: 2, invoiceNumber: 'INV-002', customer: 'Jane Smith', amount: 150, status: 'unpaid' },
  { id: 3, invoiceNumber: 'INV-003', customer: 'Robert Johnson', amount: 300, status: 'unpaid' },
  { id: 4, invoiceNumber: 'INV-004', customer: 'Emily Davis', amount: 400, status: 'unpaid' },
  { id: 5, invoiceNumber: 'INV-005', customer: 'Michael Brown', amount: 200, status: 'unpaid' },
  { id: 6, invoiceNumber: 'INV-006', customer: 'Jennifer Wilson', amount: 350, status: 'unpaid' },
  { id: 7, invoiceNumber: 'INV-007', customer: 'David Taylor', amount: 450, status: 'unpaid' },
  { id: 8, invoiceNumber: 'INV-008', customer: 'Mary Anderson', amount: 500, status: 'unpaid' },
  { id: 9, invoiceNumber: 'INV-009', customer: 'James White', amount: 600, status: 'unpaid' },
  { id: 10, invoiceNumber: 'INV-010', customer: 'Patricia Harris', amount: 700, status: 'unpaid' },
  { id: 11, invoiceNumber: 'INV-011', customer: 'Robert Martin', amount: 800, status: 'unpaid' },
  { id: 12, invoiceNumber: 'INV-012', customer: 'Linda Thompson', amount: 900, status: 'unpaid' },
];

const UnPaidInvoiceList = () => {
  const [status, setStatus] = useState('unpaid'); // Default to 'unpaid'
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const invoicesPerPage = 10; // Number of invoices to display per page

  // Filtered invoices based on status and search query
  const filteredInvoices = invoiceData.filter((invoice) =>
    (status === 'all' || invoice.status === status) &&
    (invoice.invoiceNumber.toLowerCase().includes(search.toLowerCase()) ||
     invoice.customer.toLowerCase().includes(search.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);

  // Get current invoices to display
  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setCurrentPage(1); // Reset to first page on status change
  };

  const handleReset = () => {
    setStatus('unpaid'); // Reset to 'unpaid'
    setSearch('');
    setCurrentPage(1); // Reset to first page
  };

  // Updated button handlers with alert for testing
  const handleView = (id) => {
    alert(`Viewing invoice ${id}`);
  };

  const handlePay = (id) => {
    alert(`Paying invoice ${id}`);
  };

  const handleDownload = (id) => {
    alert(`Downloading invoice ${id}`);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="card mb-4">
          <div className="card-header">
            <h4 className="card-title">Unpaid Invoice List</h4>
          </div>
          <div className="card-filter">
            <form>
              <div className="filter-form">
                <div className="filter-item">
                  <select
                    name="status"
                    className="form-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="all">All</option>
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                  </select>
                </div>
                <div className="filter-item">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search by Invoice Number or Customer Name"
                    className="form-control"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="filter-action">
                  <button
                    className="i-btn danger--btn btn--md text-white"
                    type="button"
                    onClick={handleReset}
                  >
                    <i className="las la-sync"></i> Reset
                  </button>
                  <button className="i-btn primary--btn btn--md text-white" type="button">
                    <i className="fa fa-plus"></i> Add New
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="card-body px-0">
            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Invoice Number</th>
                    <th>Customer Name</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentInvoices.length > 0 ? (
                    currentInvoices.map((invoice, index) => (
                      <tr key={invoice.id}>
                        <td data-label="Sl.No">{indexOfFirstInvoice + index + 1}</td>
                        <td data-label="Invoice Number">{invoice.invoiceNumber}</td>
                        <td data-label="Customer Name">{invoice.customer}</td>
                        <td data-label="Amount">₹{invoice.amount}</td>
                        <td data-label="Status">
                          <span className={`badge badge--${invoice.status === 'paid' ? 'success' : 'danger'}`}>
                            {invoice.status}
                          </span>
                        </td>
                        <td data-label="Action">
                          <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                            <button className="i-btn primary--btn btn--sm" onClick={() => handleView(invoice.id)}>
                              View
                            </button>
                            <button className="i-btn success--btn btn--sm" onClick={() => handlePay(invoice.id)}>
                              Pay
                            </button>
                            <button className="i-btn info--btn btn--sm" onClick={() => handleDownload(invoice.id)}>
                              Download
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No unpaid invoices found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pagination */}
          <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                type="button"
                className={`btn btn-outline-primary ${currentPage === index + 1 ? 'active' : ''}`}
                style={{ margin: '0px 5px' }}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default UnPaidInvoiceList;
