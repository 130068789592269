import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CompanyInfoCard from './CompanyInfoCard';
import { getcompanydata } from '../../services/apiService';

const CompanySetup = () => {
    const [companyData, setCompanyData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        gst: '',
        bankName: '',
        bankAccountName: '',
        bankAccountNumber: '',
        bankIfscCode: '',
        image: null
    });

    // Fetch existing data on component mount
    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token'); // Retrieve token from localStorage

            try {
                const response = await getcompanydata(token);
                console.log('getcompanydata:', response);
                setFormData(response);
                setCompanyData(response);
            } catch (error) {
                console.error('Error fetching company info:', error);
            }
        };


        fetchData();
    }, []);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        try {
            await axios.post('/api/updateCompany', formDataToSend); // Replace with your endpoint
            alert('Company information updated successfully');
        } catch (error) {
            console.error('Error updating company info:', error);
            alert('Error updating company information');
        }
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="row g-4">
                    <CompanyInfoCard companyData={companyData} />
                    <div className="col-xl-9 col-lg-8">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Update Information</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-wrapper mb-0">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="name" className="form-label">Company Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Enter Name"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="email" className="form-label">Company Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="Enter Email"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="phone" className="form-label">Company Phone</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phone"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    placeholder="Enter Phone"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="company_type" className="form-label">Company is Multi Branch</label>
                                                <select
                                                    name="company_type"
                                                    id="company_type"
                                                    className="form-control"
                                                    value={formData.company_type}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="" disabled>Select One</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 col-12 col-md-12">
                                                <label htmlFor="address1" className="form-label">Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="address1"
                                                    name="address1"
                                                    value={formData.address1}
                                                    onChange={handleChange}
                                                    placeholder="Enter Address"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-12">
                                                <label htmlFor="address2" className="form-label">Address1</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="address2"
                                                    name="address2"
                                                    value={formData.address2}
                                                    onChange={handleChange}
                                                    placeholder="Enter Address1"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="city" className="form-label">City</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="city"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    placeholder="Enter City"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="state" className="form-label">State</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="state"
                                                    name="state"
                                                    value={formData.state}
                                                    onChange={handleChange}
                                                    placeholder="Enter State"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="zip" className="form-label">Zip</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="zip"
                                                    name="zip"
                                                    value={formData.zip}
                                                    onChange={handleChange}
                                                    placeholder="Enter Zip"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="country" className="form-label">Country</label>
                                                <select
                                                    id="country"
                                                    name="country"
                                                    className="form-control"
                                                    value={formData.country}
                                                    onChange={handleChange}
                                                >
                                                    <option value="AF">Afghanistan</option>
                                                    <option value="AL">Albania</option>
                                                    {/* Add more options as needed */}
                                                </select>
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="gst" className="form-label">GST or Tax ID</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="gst"
                                                    name="gst"
                                                    value={formData.gst}
                                                    onChange={handleChange}
                                                    placeholder="Enter GST"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="image" className="form-label">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="image"
                                                    name="image"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <h5 style={{ marginBottom: '10px' }}>Bank Details</h5>
                                        <div className="row">
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="bank_name" className="form-label">Bank Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="bank_name"
                                                    name="bankName"
                                                    value={formData.bankName}
                                                    onChange={handleChange}
                                                    placeholder="Enter Bank Name"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="bank_account_name" className="form-label">Bank Account Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="bank_account_name"
                                                    name="bankAccountName"
                                                    value={formData.bankAccountName}
                                                    onChange={handleChange}
                                                    placeholder="Enter Bank Account Name"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="bank_account_number" className="form-label">Bank Account Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="bank_account_number"
                                                    name="bankAccountNumber"
                                                    value={formData.bankAccountNumber}
                                                    onChange={handleChange}
                                                    placeholder="Enter Bank Account Number"
                                                />
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                                <label htmlFor="bank_ifsc_code" className="form-label">Bank Account IFSC Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="bank_ifsc_code"
                                                    name="bankIfscCode"
                                                    value={formData.bankIfscCode}
                                                    onChange={handleChange}
                                                    placeholder="Enter IFSC Code"
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" className="i-btn primary--btn btn--md text-light">
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CompanySetup;
