import React, { useState, useEffect } from "react";
import { getuserdata } from "../../../services/apiService";
import { oneclick } from "../../../oneclick";
import database_id from "../../../utils/databaseConfig";

const UpdateAttributeModel = ({ handleUpdateClose, data, fetchData }) => {
    const [attributeName, setAttributeName] = useState(data.attribute_name || '');
    const [attributeType, setAttributeType] = useState(data.attribute_type || '1'); // Default to 'Date'

    useEffect(() => {
        // Reset form values when the modal opens
        setAttributeName(data.attribute_name);
        setAttributeType(data.attribute_type);
    }, [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare the data for the update
        const tableSchema = database_id;
        const tableName = 'attribute';
        const condition = { id: data.id };

        const updatedData = {
            attribute_name: attributeName,
            attribute_type: attributeType,
        };

        try {
            // Update the data
            const response = await oneclick.update(tableSchema, tableName, updatedData, condition);

            if (response.success) {
                // Success - handle success (e.g., show a message or close the modal)
                alert('Attribute updated successfully!');
                fetchData();
                handleUpdateClose();  // Close the modal after successful update
            } else {
                alert('Error updating attribute');
            }
        } catch (error) {
            console.error('Error updating attribute:', error);
            alert('Error updating attribute');
        }
    };

    return (
        <div className="modal fade show d-block" id="updateAttribute" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Update Group Information</h5>
                        <button type="button" className="i-btn bg--lite--danger text--danger btn--sm" onClick={handleUpdateClose}>
                            <i className="las la-times"></i>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-item mb-3">
                                        <label htmlFor="attribute_name" className="form-label">
                                            Attribute Name <sup className="text--danger">*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="attribute_name"
                                            name="attribute_name"
                                            placeholder="Enter Attribute Name"
                                            value={attributeName}
                                            onChange={(e) => setAttributeName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-item mb-3">
                                        <label htmlFor="attribute_type" className="form-label">
                                            Attribute Type <sup className="text--danger">*</sup>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="attribute_type"
                                            id="attribute_type"
                                            value={attributeType}
                                            onChange={(e) => setAttributeType(e.target.value)}
                                            required
                                        >
                                            <option value="" disabled>
                                                --Select One Type--
                                            </option>
                                            <option value="1">Date</option>
                                            <option value="2">Boolean</option>
                                            <option value="3">Number</option>
                                            <option value="4">Text</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <div className="d-flex align-items-center gap-3">
                                <button type="button" className="i-btn danger--btn btn--md" onClick={handleUpdateClose}>
                                    Cancel
                                </button>
                                <button type="submit" className="i-btn primary--btn btn--md">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateAttributeModel;
