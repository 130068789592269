import React, { useState } from 'react';

const LoginPageContentForm = () => {
  const [formData, setFormData] = useState({
    adminBg: null,
    adminCard: null,
    heading: '',
    subHeading: '',
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('_token', 'JY3trr9nM6W48gliidWmcyKnfJ6EQAqNc5C4oefq');
    formDataToSend.append('admin_bg', formData.adminBg);
    formDataToSend.append('admin_card', formData.adminCard);
    formDataToSend.append('heading', formData.heading);
    formDataToSend.append('sub_heading', formData.subHeading);

    try {
      const response = await fetch('https://getone.click/admin/general/setting/frontend/section/store', {
        method: 'POST',
        body: formDataToSend,
      });
      if (response.ok) {
        // Handle success
        console.log('Form submitted successfully');
      } else {
        // Handle error
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Login Page Content</h4>
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="card-body">
                <div className="form-wrapper">
                  <h4 className="form-wrapper-title mb-4">Admin Section</h4>
                  <div className="row g-4">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-item">
                          <label htmlFor="admin_bg" className="form-label">
                            Background Image
                          </label>
                          <sup className="text--danger">*</sup>
                          <input
                            type="file"
                            name="adminBg"
                            id="admin_bg"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-item">
                          <label htmlFor="admin_card" className="form-label">
                            Card Image
                          </label>
                          <input
                            type="file"
                            name="adminCard"
                            id="admin_card"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-wrapper">
                  <h4 className="form-wrapper-title mb-4">User Section</h4>
                  <div className="row g-4">
                    <div className="row">
                      <div className="mb-3 col-lg-12">
                        <label htmlFor="heading" className="form-label">
                          Heading
                        </label>
                        <sup className="text--danger">*</sup>
                        <input
                          type="text"
                          name="heading"
                          id="heading"
                          className="form-control"
                          value={formData.heading}
                          onChange={handleChange}
                          placeholder="Enter Heading"
                          required
                        />
                      </div>
                      <div className="mb-3 col-lg-12">
                        <label htmlFor="sub_heading" className="form-label">
                          Sub Heading
                        </label>
                        <sup className="text--danger">*</sup>
                        <textarea
                          className="form-control"
                          id="sub_heading"
                          name="subHeading"
                          rows="5"
                          placeholder="Enter Sub Heading"
                          value={formData.subHeading}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="i-btn primary--btn btn--lg">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPageContentForm;
