import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS

const EmailTemplateCreate = () => {
    const [formData, setFormData] = useState({
        name: '',
        provider: '',
        body: '',
    });
    const [showTextEditor, setShowTextEditor] = useState(false);
    const [dynamicVariables, setDynamicVariables] = useState([
        { label: 'Recipient Name', value: '{name}' },
        { label: 'Recipient Email', value: '{email}' },
        { label: 'Company Name', value: '{company}' },
    ]);
    const [newVariable, setNewVariable] = useState({ label: '', value: '' });
    const [isLoading, setIsLoading] = useState(false);
    const quillRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEditorChange = (content) => {
        setFormData({ ...formData, body: content });
    };

    const handleProviderChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, provider: value });
        setShowTextEditor(value === '2');
    };

    const insertDynamicVariable = (variable) => {
        const editor = quillRef.current.getEditor(); // Access the Quill editor
        const range = editor.getSelection(); // Get the cursor position
        if (range && variable) {
            editor.insertText(range.index, ` ${variable} `); // Insert variable at the cursor
        }
    };

    const handleAddVariable = () => {
        if (newVariable.label && newVariable.value) {
            setDynamicVariables([...dynamicVariables, newVariable]);
            setNewVariable({ label: '', value: '' });
        }
    };

    const handleRemoveVariable = (index) => {
        const updatedVariables = dynamicVariables.filter((_, i) => i !== index);
        setDynamicVariables(updatedVariables);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!formData.name || !formData.provider || !formData.body) {
            alert("Please fill in all required fields.");
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch('/api/email-template', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            
            if (response.ok) {
                const result = await response.json();
                alert('Template created successfully!');
                console.log(result);
            } else {
                alert('Failed to create template');
            }
        } catch (error) {
            console.error('Error creating template:', error);
            alert('An error occurred while creating the template.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="dashboard_container">
            <section className="mt-3 rounded_box">
                <div className="container-fluid p-0 pb-2">
                    <form onSubmit={handleSubmit}>
                        <div className="card mb-3">
                            <h6 className="card-header">Template Basic Information</h6>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <label className="form-label" htmlFor="name">
                                            Name <sup className="text-danger">*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            name="name"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Write Template Name"
                                        />
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <label className="form-label" htmlFor="provider">
                                            Select Provider <sup className="text-danger">*</sup>
                                        </label>
                                        <select
                                            className="form-control"
                                            name="provider"
                                            id="provider"
                                            value={formData.provider}
                                            onChange={handleProviderChange}
                                        >
                                            <option value="">Select A Provider</option>
                                            <option value="1">Bee Pro</option>
                                            <option value="2">Texteditor</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {showTextEditor && (
                            <div className="card mb-3">
                                <h6 className="card-header">Template Body</h6>
                                <div className="card-body">
                                    <label className="form-label" htmlFor="body">
                                        Message Body <sup className="text-danger">*</sup>
                                    </label>
                                    <ReactQuill
                                        ref={quillRef}
                                        theme="snow"
                                        value={formData.body}
                                        onChange={handleEditorChange}
                                        modules={{
                                            toolbar: [
                                                [{ 'header': [1, 2, false] }],
                                                [{ 'font': [] }],
                                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                                [{ 'color': [] }, { 'background': [] }],
                                                ['bold', 'italic', 'underline', 'strike'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                [{ 'align': [] }],
                                                ['link', 'image', 'video'],
                                                ['clean'],
                                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                                ['blockquote'],
                                                ['code-block'],
                                            ],
                                        }}
                                        formats={[
                                            'header', 'font', 'size',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet',
                                            'link', 'image', 'align'
                                        ]}
                                    />

                                    <div className="mt-2">
                                        <label htmlFor="dynamic-variables">Insert Dynamic Variables:</label>
                                        <select
                                            id="dynamic-variables"
                                            value=""
                                            onChange={(e) => insertDynamicVariable(e.target.value)}
                                        >
                                            <option value="">Select a variable</option>
                                            {dynamicVariables.map((variable, index) => (
                                                <option key={index} value={variable.value}>
                                                    {variable.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mt-3">
                                        <h6>Add Custom Dynamic Variable</h6>
                                        <input
                                            type="text"
                                            placeholder="Variable Label"
                                            value={newVariable.label}
                                            onChange={(e) => setNewVariable({ ...newVariable, label: e.target.value })}
                                            className="form-control mb-2"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Variable Value"
                                            value={newVariable.value}
                                            onChange={(e) => setNewVariable({ ...newVariable, value: e.target.value })}
                                            className="form-control mb-2"
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddVariable}
                                        >
                                            Add Variable
                                        </button>
                                    </div>

                                    <div className="mt-3">
                                        <h6>Custom Dynamic Variables List</h6>
                                        <ul className="list-group">
                                            {dynamicVariables.map((variable, index) => (
                                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                    {variable.label} ({variable.value})
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleRemoveVariable(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="col-12 mt-3">
                            <button type="submit" className="btn btn-success" disabled={isLoading}>
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default EmailTemplateCreate;
