import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { oneclick } from '../../oneclick';
import { format, formatDistanceToNow } from 'date-fns';
import { isValid } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getImageBaseUrl } from '../../services/apiService';
import database_id from '../../utils/databaseConfig';

const UserDashboard = () => {
    const location = useLocation();
    const { userId } = useParams(); // Assuming the URL parameter is named userId
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        pricingPlan: '1',
        status: '1',
    });
    const [conditions, setConditions] = useState({
        id: userId 
    });
    const [serch, setSerch] = useState({});
    const [Users, setAllUsers] = useState([]);

    const loginToken = localStorage.getItem('token');
    const tableSchema = database_id;

    const [imageBaseUrl, setImageBaseUrl] = useState(''); // Image base URL state


    useEffect(() => {
        setImageBaseUrl(getImageBaseUrl());
        const tableName = "users";
        const orderBy = "created_at DESC";

        const fetchData1 = (data) => {
            if (data) {
                setAllUsers(data[0]);
                // Set form data with user data
                setFormData({
                    name: data[0].name || '',
                    email: data[0].email || '',
                    address: data[0].address || '',
                    city: data[0].city || '',
                    state: data[0].state || '',
                    zip: data[0].zip || '',
                    pricingPlan: data[0].pricing_plan || '1',
                    status: data[0].status || '1',
                });
            } else {
                console.log("No user data found.");
            }
        };

        if (loginToken) {
            oneclick.startLongPolling(tableSchema, tableName, conditions, orderBy, "", fetchData1, "");
        } else {
            console.warn('No login token found. User is not logged in.');
        }

        return () => {
            oneclick.stopLongPolling();
        };
    }, [loginToken, tableSchema, conditions]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your submit logic here
        console.log(formData);
    };

    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(prev => !prev);
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="row g-4">
                    <div className="col-xxl-3 col-xl-4">
                        <div className="card h-100">
                            <div className="card-header">
                                <h4 className="card-title">User Information</h4>
                            </div>
                            <div className="card-body">
                                <div className="d-flex align-items-center gap-3">
                                    <div className="user--profile--image avatar-xl">
                                        <img src={`${imageBaseUrl}/assets/file/images/user/profile/${Users.image}`}
                                            alt="Profile"
                                            className="rounded w-100 h-100"
                                        />
                                    </div>
                                    <div>
                                        <h5 className="mb-1">{Users.name}</h5>
                                        <span>
                                            {(() => {
                                                const createdAt = Users.created_at; // Assuming Users.created_at is a date string
                                                const dateObject = new Date(createdAt); // Create a Date object

                                                // Check if the date is valid before formatting
                                                if (isValid(dateObject)) {
                                                    return format(dateObject, 'MMMM dd, yyyy, hh:mm a'); // Format the date
                                                } else {
                                                    return 'Invalid date'; // Fallback for invalid date
                                                }
                                            })()}
                                        </span>
                                    </div>
                                </div>
                                <ul className="list-group mt-4">
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap gap-2">
                                        <p><strong>SMS Credit:</strong></p>
                                        <span>{Users.credit} Credit</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap gap-2">
                                        <p><strong>Email Credit:</strong></p>
                                        <span>{Users.email_credit} Credit</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap gap-2">
                                        <p><strong>WhatsApp Credit:</strong></p>
                                        <span>{Users.whatsapp_credit} Credit</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap gap-2">
                                        <p><strong>Email:</strong></p>
                                        <span>{Users.email}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap gap-2">
                                        <p><strong>Status:</strong></p>
                                        <span className={`badge ${Users.status == '1' ? 'badge--success' : 'badge--danger'}`}>
                                            {Users.status == '1' ? 'Active' : 'Inactive'} {/* Show 'Active' if status is '1', otherwise 'Inactive' */}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-9 col-xl-8">
                        <div className="row g-4 dash-cards">
                            {/* SMS Statistics */}
                            <div className="col-md-6 col-xxl-6">
                                <div className="card">
                                    <div className="info-card">
                                        <div className="info-card-top">
                                            <div className="info-card-header">
                                                <h4>SMS Statistics</h4>
                                                <span>
                                                    <i className="las la-comment"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="info-card-content">
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--info text--info">
                                                        <i className="las la-address-book"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Total</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--success text--success">
                                                        <i className="las la-check-double"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Success</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--warning text--warning ">
                                                        <i className="las la-hourglass-half"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Pending</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--danger text--danger">
                                                        <i className="las la-times-circle"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Failed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Email Statistics */}
                            <div className="col-md-6 col-xxl-6">
                                <div className="card">
                                    <div className="info-card">
                                        <div className="info-card-top">
                                            <div className="info-card-header">
                                                <h4>Email Statistics</h4>
                                                <span>
                                                    <i className="las la-envelope-open"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="info-card-content">
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--info text--info">
                                                        <i className="las la-address-book"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Total</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--success text--success">
                                                        <i className="las la-check-double"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Success</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--warning text--warning ">
                                                        <i className="las la-hourglass-half"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Pending</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--danger text--danger">
                                                        <i className="las la-times-circle"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Failed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* WhatsApp Statistics */}
                            <div className="col-md-6 col-xxl-6">
                                <div className="card">
                                    <div className="info-card">
                                        <div className="info-card-top">
                                            <div className="info-card-header">
                                                <h4>WhatsApp Statistics</h4>
                                                <span>
                                                    <i className="lab la-whatsapp"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="info-card-content">
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--info text--info">
                                                        <i className="las la-address-book"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Total</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--success text--success">
                                                        <i className="las la-check-double"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Success</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--warning text--warning ">
                                                        <i className="las la-hourglass-half"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Pending</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-inner-card">
                                                <div className="info-inner-content">
                                                    <span className="bg--lite--danger text--danger">
                                                        <i className="las la-times-circle"></i>
                                                    </span>
                                                    <div>
                                                        <h4>0</h4>
                                                        <p>Failed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                {/* User Settings Form */}
                <div className="col" style={{marginTop: '15px'}}>
                    <div className="card">
                        <div className="card-header mb-20">
                            <h4 className="card-title">Update your profile</h4>
                            <div>
                                <button
                                    className="i-btn primary--btn btn--md"
                                    type="button"
                                    onClick={toggleCollapse}
                                    aria-expanded={!isCollapsed}
                                    aria-controls="collapseExample"
                                >
                                    Update Here
                                </button>
                            </div>
                        </div>
                        <div className={`collapse ${!isCollapsed ? 'show' : ''}`} id="collapseExample">
                            <div className="card-body pt-0">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-wrapper">
                                        <div className="row g-4">
                                            <div className="col-lg-6">
                                                <div className="form-item">
                                                    <label htmlFor="name" className="form-label">Name <sup className="text--danger">*</sup></label>
                                                    <input type="text" name="name" id="name" className="form-control" value={formData.name} onChange={handleChange} placeholder="Enter Name" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-item">
                                                    <label htmlFor="email" className="form-label">Email <sup className="text--danger">*</sup></label>
                                                    <input type="email" name="email" id="email" className="form-control" value={formData.email} onChange={handleChange} required />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-item">
                                                    <label htmlFor="address" className="form-label">Address <sup className="text--danger">*</sup></label>
                                                    <input type="text" name="address" id="address" className="form-control" value={formData.address} onChange={handleChange} placeholder="Enter Address" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-item">
                                                    <label htmlFor="city" className="form-label">City <sup className="text--danger">*</sup></label>
                                                    <input type="text" name="city" id="city" className="form-control" value={formData.city} onChange={handleChange} placeholder="Enter City" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-item">
                                                    <label htmlFor="state" className="form-label">State <sup className="text--danger">*</sup></label>
                                                    <input type="text" name="state" id="state" className="form-control" value={formData.state} onChange={handleChange} placeholder="Enter State" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-item">
                                                    <label htmlFor="zip" className="form-label">Zip <sup className="text--danger">*</sup></label>
                                                    <input type="text" name="zip" id="zip" className="form-control" value={formData.zip} onChange={handleChange} placeholder="Enter Zip" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-item">
                                                    <label htmlFor="pricing_plan" className="form-label">User's Pricing Plan <sup className="text--danger">*</sup></label>
                                                    <select className="form-select" name="pricing_plan" id="pricing_plan" value={formData.pricingPlan} onChange={handleChange}>
                                                        <option value="1">Free</option>
                                                        <option value="2">Demo Plan Two</option>
                                                        <option value="3">Demo Plan One</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-item">
                                                    <label htmlFor="status" className="form-label">Status <sup className="text--danger">*</sup></label>
                                                    <select className="form-select" name="status" id="status" value={formData.status} onChange={handleChange}>
                                                        <option value="1">Active</option>
                                                        <option value="2">Banned</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" className="i-btn primary--btn btn--lg">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default UserDashboard;
