import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import io from 'socket.io-client';
import CreateFolderModal from './CreateFolderModal';
import UploadFileModal from './UploadFileModal';
import UploadFolder from './UploadFolder';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSocket } from '../../context/SocketContext';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchFolderContents, fetchPaginatedItems, CreateFolder, getuserdata, DeleteFolder, uploadFile, bulkDeleteFolders, downloadFolder } from '../../services/apiService';
import { oneclick } from '../../oneclick';
import Paginationnew from '../../utils/Paginationnew';
import BulkActionModal from '../Contact_Management/Components/BulkActionModal';
import database_id from '../../utils/databaseConfig';

const CloudStorage = ({
    title,
    paginatedItems = [],
    searchQuery,
    onSearch,
    onReset,
    onCreateFolder,
    onUploadFile,
    onDeleteFile,
}) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams] = useSearchParams();
    const folderId = searchParams.get("folderId");
    const Bucket_id = searchParams.get("Bucket_id");
    const [newBucket_id, setNewBucket_id] = useState("");
    const socket = useSocket(); // Get the socket from context
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false); //
    const [ShowUploadFolderModal, setShowUploadFolderModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState(searchQuery || '');
    const [paginatedItemsState, setPaginatedItemsState] = useState(paginatedItems);
    const [currentPath, setCurrentPath] = useState([{ name: 'Home', id: 1 }]);
    const [currentPathid, setCurrentPathid] = useState(['0']); // Initialize with root path
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showloading, setShowloading] = useState("");
    const [serch, setSerch] = useState({});
    const loginToken = localStorage.getItem('token');

    const [showModal, setShowModal] = useState(false); // Track modal visibility
    const [fileContent, setFileContent] = useState(null); // File data to display
    const [fileType, setFileType] = useState(null); // Type of the file (image, video, etc.)
    const [fileUrl, setFileUrl] = useState(null); // Type of the file (image, video, etc.)

    // Function to open the modal and set file details
    const onView = (item) => {
        console.log('Viewing file:', item);
    
        // Define extensions for images and videos
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
        const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv'];

        
    
        // Check if file is an image
        const isImage = imageExtensions.some((ext) => item.file_name.toLowerCase().endsWith(ext));
    
        // Check if file is a video
        const isVideo = videoExtensions.some((ext) => item.file_name.toLowerCase().endsWith(ext));
    
        const fileUrl = process.env.REACT_APP_IMAGE_BASE_LOCALURL+'/uploads/users/'+ item.userid +'/'+item.root +'/'+item.file_name;

        console.log('File URL:', fileUrl);
        setFileUrl(fileUrl);
        // Set the file content (URL of the file) and file type (image or video)
        setFileContent(item.url);  // Assuming item has a URL to the file
        setFileType(isImage ? 'image' : (isVideo ? 'video' : '')); // Set file type based on extension
        setShowModal(true); // Open modal
    };

    // Close the modal
    const handleClose = () => {
        setShowModal(false);
        setFileContent(null);
        setFileType(null);
    };

    useEffect(() => {
        const fetchFolderContents = async () => {
            try {
                if (!folderId) {
                    if (loginToken) {
                        handlePageChange(currentPage, {}, 0);
                    }
                } else {
                    setCurrentFolderId(folderId);
                    setCurrentPath(['/']);
                    const tableSchema = database_id;
                    const tableName = "cloud_storage";
                    const companyId = await getuserdata("company_id");

                    const conditions = {
                        id: folderId,
                        company_id: companyId,
                    };
                    const orderBy = "id DESC";
                    const pagination = { page: 1 || 1, limit: 10 };

                    const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, "", pagination, "");
                    if (response.success) {
                        onFolderClick(response.data[0]);
                        if (response.data[0].child_id == "0") {
                            setNewBucket_id(response.data[0].Bucket_id);
                        }
                        // Update folder path and IDs
                    } else {
                        toast.error("Failed to fetch folder details.");
                    }
                }
            } catch (error) {
                console.error("Error in useEffect:", error);
                toast.error("An error occurred while loading folder contents.");
            }
        };

        console.log('Bucket_id:', Bucket_id);
        console.log('folderId:', folderId);
        fetchFolderContents(); // Call the async function
    }, [folderId, searchParams]);



    const handleFolderDelete = async (item) => {
        try {
            // Show a confirmation dialog before deleting
            const confirmDelete = window.confirm(`Are you sure you want to delete the folder: ${item.file_name}?`);
            if (!confirmDelete) {
                return; // User canceled the deletion
            }

            // Perform the folder deletion using the DeleteFolder API
            const response = await DeleteFolder(loginToken, item.id);

            if (response.success) {
                // If deletion is successful, show a success toast
                toast.success(response.message);

                // Depending on the current folder, either redirect or change the page
                if (currentFolderId) {
                    onFolderClickredirect(); // Redirect to the folder's new location (or home)
                } else {
                    // If currentFolderId is null/undefined, update the page
                    handlePageChange(currentPage, {}, currentFolderId);
                }
            } else {
                // Show error toast if the folder couldn't be deleted
                toast.error("Failed to delete the folder.");
            }
        } catch (error) {
            // Catch any unexpected errors during the process
            console.error('Error deleting folder:', error);
            toast.error("An error occurred while deleting the folder.");
        }
    };

    const updateFolderPath = (folderRoot) => {
        // Remove "Home" from the beginning and handle the dynamic root
        const root = folderRoot.startsWith('Home/')
            ? folderRoot.replace('Home/', '').trim()
            : folderRoot.trim();
        return folderRoot;
    };

    const handleCreateFolder = async (folderName, folderId, folderRoot, Bucket_idnew) => {
        setLoading(true);
        try {
            const updatedRoot = updateFolderPath(folderRoot);
            console.log('updatedRoot:', updatedRoot);
            if (!newBucket_id) {
                const company_id = await getuserdata("company_id");
                const create = await CreateFolder(loginToken, folderName, folderId, updatedRoot, Bucket_idnew, company_id, "");
                console.log('Create Folder:', create);
                setShowCreateFolderModal(false);
                toast.success(create.message);
                if (currentFolderId) {
                    onFolderClickredirect();
                } else {
                    handlePageChange(currentPage, serch, currentFolderId);
                }
            } else {
                const company_id = await getuserdata("company_id");
                const create = await CreateFolder(loginToken, folderName, folderId, updatedRoot, "", company_id, newBucket_id);
                setShowCreateFolderModal(false);
                toast.success(create.message);
            }
        } catch (error) {
            console.error('Error creating folder:', error);
            toast.error('Failed to create folder.');
        } finally {
            setLoading(false);
        }
    };

    const handleFileUpload = async (file, currentFolderId, path, setProgress, stats) => {
        setLoading(true);
        try {
            // Fetch company_id
            const companyId = await getuserdata("company_id");
            if (!companyId) {
                toast.error('Company ID is missing.');
                return;
            }

            const formData = new FormData();
            formData.append('login_tockin', loginToken);
            formData.append('folderId', currentFolderId);
            formData.append('root', path);
            formData.append('company_id', companyId);
            formData.append('Bucket_idold', newBucket_id);
            formData.append('stats', stats);


            formData.append('file', file);



            // Upload file with progress tracking
            await uploadFile(formData, (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(progress); // Update progress dynamically
            });

            // Indicate upload success
            setProgress(100);
            toast.success('File uploaded successfully!');
            if (currentFolderId) {
                onFolderClickredirect();
            } else {
                handlePageChange(currentPage, {}, currentFolderId);
            }
            setShowUploadFileModal(false); // Hide the modal after successful upload
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Failed to upload file. Please try again.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    const handleSearch = (data) => {
        setSearchTerm(data);

        const trimmedData = data?.trim(); // Handle potential whitespace issues

        let searchQuery = {};
        if (trimmedData) {
            searchQuery = { file_name: trimmedData, uid: trimmedData }; // Create query object
            setSerch(searchQuery);
            handlePageChange(1, searchQuery, currentFolderId); // Ensure this is only called once
        } else {
            setSerch({});
            handlePageChange(1, searchQuery, currentFolderId); // Ensure this is only called once
        }

        console.log('Updated search query:', trimmedData ? { file_name: trimmedData, uid: trimmedData } : {});

    };

    const handleReset = () => {
        setSearchTerm('');
        if (onReset) {
            onReset();
        }
    };

    // Utility function to update path
    const updatePath = (prevPath, newItem) => {
        if (!Array.isArray(prevPath)) {
            console.error("prevPath is not an array", prevPath);
            return [newItem]; // return new item as an array if prevPath is invalid
        }
        return [...prevPath, newItem];
    };

    const onFolderClick = async (item, currentFolderIddata) => {
        console.log('Bucket_id:', newBucket_id);
        console.log('onFolderClick:', item);
        setLoading(true);
        try {
            // Reset previous state
            setPaginatedItemsState([]);
            setTotalPages(0);
            setTotalRecords(0);

            // API Request
            const tableSchema = database_id;
            const tableName = "cloud_storage";
            let conditions = {};
            if (currentFolderIddata) {
                conditions = { child_id: currentFolderIddata };
            } else {
                conditions = { child_id: item.id };
            }
            const orderBy = "id DESC";
            const pagination = { page: currentPage || 1, limit: 10 };
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, "", pagination, "");
            console.log('Response:', response);

            if (response.success) {
                setPaginatedItemsState(response.data || []);
                setTotalPages(response.totalPages || 0);
                setTotalRecords(response.totalrecords || 0);
            } else {
                setPaginatedItemsState([]);
                setTotalPages(0);
                setTotalRecords(0);
            }

            // Update URL
            const updateBrowserUrl = (folderId) => {
                try {
                    console.log('item.child_id:', item.child_id);
                    if (item.root === "Home") {
                        setNewBucket_id(item.Bucket_id);
                        console.log('updateBrowserUrl:', true);
                        const newUrl = `${window.location.pathname}?Bucket_id=${item.Bucket_id}&folderId=${encodeURIComponent(folderId)}`;
                        window.history.pushState({ path: newUrl }, '', newUrl);
                    } else {
                        console.log('updateBrowserUrl:', false);
                        const newUrl = `${window.location.pathname}?Bucket_id=${newBucket_id}&folderId=${encodeURIComponent(folderId)}`;
                        window.history.pushState({ path: newUrl }, '', newUrl);
                    }
                } catch (err) {
                    console.error('Failed to update URL:', err);
                }
            };

            updateBrowserUrl(item.id);

            setCurrentPath((prevPath) => {
                // Add the new folder (item) to the path.
                return [...prevPath, { name: item.file_name, id: item.id }];
            });
            setCurrentFolderId(item.id);

            console.log('Current Path:', currentPath);
        } catch (error) {
            console.error('Error fetching folder contents:', error);
            toast.error(`Failed to fetch contents for folder: ${item.file_name}. Please try again.`);
        } finally {
            setLoading(false);
        }
    };

    const onFolderClickredirect = async () => {
        console.log('Bucket_id:', Bucket_id);
        setLoading(true);
        const tableSchema = database_id;
        const tableName = "cloud_storage";
        const companyId = await getuserdata("company_id");

        const conditions = {
            id: currentFolderId,
            company_id: companyId,
        };
        const orderBy = "id DESC";
        const pagination = { page: currentPage, limit: 10 };

        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, "", pagination, "");
        const item = response.data[0];

        console.log('onFolderClickredirect:', item);

        try {
            // Reset previous state
            setPaginatedItemsState([]);
            setTotalPages(0);
            setTotalRecords(0);

            // API Request
            const tableSchema = database_id;
            const tableName = "cloud_storage";
            const conditions = { child_id: item.id };


            const orderBy = "id DESC";
            const pagination = { page: currentPage, limit: 10 };

            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, "", pagination, "");
            console.log('Response:', response);

            if (response.success) {
                setPaginatedItemsState(response.data || []);
                setTotalPages(response.totalPages || 0);
                setTotalRecords(response.totalrecords || 0);
            } else {
                setPaginatedItemsState([]);
                setTotalPages(0);
                setTotalRecords(0);
            }

        } catch (error) {
            console.error('Error fetching folder contents:', error);
            toast.error(`Failed to fetch contents for folder: ${item.file_name}. Please try again.`);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChangenew = (pageNumber) => {
        setCurrentPage(pageNumber);
        handlePageChange(pageNumber, serch);
    }

    const handlePageChange = async (pageNumber, searchQuery, currentFolderIddata) => {
        console.log('handlePageChange:', currentFolderIddata);
        setLoading(true);
        try {
            if (loginToken) {
                const tableSchema = database_id;
                const tableName = "cloud_storage";
                let conditions = {};
                if (currentFolderIddata) {
                    conditions = {
                        child_id: currentFolderId,
                    }
                } else {
                    conditions = {
                        company_id: await getuserdata("company_id"),
                        file_type: "bucket"
                    }
                }
                const orderBy = "id DESC";
                const pagination = { page: pageNumber, limit: 10 };
                const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, searchQuery, pagination, "");
                console.log('handlePageChange Response:', response);
                if (response.success) {
                    setPaginatedItemsState(response.data);
                    setTotalPages(response.totalPages);
                    setTotalRecords(response.totalrecords);
                }
            }
        } catch (error) {
            console.error('Error fetching page:', error);
            toast.error('Failed to fetch page data.');
        } finally {
            setLoading(false);
        }
    };

    const handleBreadCrumbClick = async (index, id) => {
        // Create a new path that includes only the breadcrumbs up to the clicked index
        console.log('Current Path:', currentPath);
        const newPath = currentPath.slice(0, index + 1);
        console.log('New Path:', newPath);
        const newPathid = id;
        console.log('New Path:', newPathid);

        // Update state to reflect the new path
        setCurrentPath(newPath);
        setCurrentPathid(newPathid);

        if (loginToken) {
            console.log('folderid:', newPathid);
            try {
                if (newPathid == 0) {
                    // Handle the case for the root or top-level folder
                    setCurrentFolderId("");
                    handlePageChange(1, {}, 0);

                    // Update URL
                    try {
                        const newUrl = window.location.pathname;
                        window.history.pushState({ path: newUrl }, '', newUrl);
                    } catch (err) {
                        console.error('Failed to update URL:', err);
                    }
                } else {
                    // Fetch folder contents for the selected folder
                    const tableSchema = database_id;
                    const tableName = "cloud_storage";
                    const conditions = {
                        company_id: await getuserdata("company_id"),
                        child_id: newPathid
                    };
                    const orderBy = "id DESC";
                    const pagination = { page: 1, limit: 10 };
                    const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, "", pagination, "");

                    if (response.success) {
                        setPaginatedItemsState(response.data);
                        setTotalPages(response.totalPages);
                        setTotalRecords(response.totalrecords);
                    }

                    // Update URL with the new folder ID
                    try {
                        const newUrl = `${window.location.pathname}?Bucket_id=${Bucket_id}&folderId=${encodeURIComponent(newPathid)}`;
                        window.history.pushState({ path: newUrl }, '', newUrl);
                    } catch (err) {
                        console.error('Failed to update URL:', err);
                    }

                    setCurrentFolderId(newPathid);
                }
            } catch (error) {
                console.error('Error fetching paginated items:', error);
                toast.error('Failed to fetch folder contents.');
            }
        }
    };

    const isImage = (filePath) => {
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
        return imageExtensions.some((ext) => filePath.toLowerCase().endsWith(ext));
    };

    const isVideo = (filePath) => {
        const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv'];
        return videoExtensions.some((ext) => filePath.toLowerCase().endsWith(ext));
    };


    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const humanFileSize1 = (size) => {
        if (size == 0) return '0 Bytes';
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    };

    const [allChecked, setAllChecked] = useState(false);

    const [Checkedrows, setCheckedrows] = useState([]); // Store selected row IDs


    const [selectedrow, setSelectedrow] = useState([]);

    const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false);


    const handleSelectAll = () => {
        // Toggle the allChecked state
        const newCheckedState = !allChecked;
        setAllChecked(newCheckedState);

        // Update the rows' isChecked based on the new allChecked state
        const updatedRows = paginatedItemsState.map(row => ({ ...row, isChecked: newCheckedState }));
        setPaginatedItemsState(updatedRows);

        // Update Checkedrows state based on the new allChecked state
        if (newCheckedState) {
            // Select all row IDs if all are checked
            const rowsall = updatedRows.filter(row => row.isChecked).map(row => row.id);
            setCheckedrows(rowsall);
        } else {
            // Deselect all row IDs
            setCheckedrows([]);
        }
    };

    const handleRowCheck = (id) => {
        // Update paginatedItemsState with the toggled checkbox state
        const updatedRows = paginatedItemsState.map(row =>
            row.id === id ? { ...row, isChecked: !row.isChecked } : row
        );
        setPaginatedItemsState(updatedRows);

        // Find the toggled row's new checked state
        const isRowChecked = updatedRows.find(row => row.id === id).isChecked;

        // Update Checkedrows state
        setCheckedrows(prevChecked =>
            isRowChecked
                ? [...new Set([...prevChecked, id])] // Add ID if checked, ensuring no duplicates
                : prevChecked.filter(checkedId => checkedId !== id) // Remove ID if unchecked
        );

        // Update 'Select All' checkbox state based on all rows
        setAllChecked(updatedRows.every(row => row.isChecked));
    };


    const handlebulkdelet = async () => {
        // Checked rows
        const folderIds = Checkedrows;
        if (!folderIds || folderIds.length === 0) {
            alert('No folders selected for deletion.');
            return;
        }

        const confirmDelete = window.confirm(
            `Are you sure you want to delete the following folders?\nIDs: ${folderIds.join(', ')}`
        );

        if (!confirmDelete) {
            // User clicked "Cancel"
            alert('Bulk delete cancelled.');
            return;
        }

        const api_key = await getuserdata("api_key");
        console.log('API Key:', api_key);

        if (!api_key) {
            alert('API Key Missing');
            return;
        }

        const responce = await bulkDeleteFolders(api_key, folderIds);
        if (responce.success) {
            alert('Folders deleted successfully!');
            handlePageChange(currentPage, serch, currentFolderId);
            setAllChecked(false);
            setCheckedrows([]);
        } else {
            alert('Failed to delete some folders. Please try again.');
        }

    };


    const handeleuploadfolderclose = () => {
        console.log('handeleuploadfolderclose:', true);
        setShowUploadFolderModal(false);
        setShowUploadFileModal(false);
    }

    const handledownloadFolder = async (item) => {

        console.log('Download Folder:', item);
        setShowloading(item.id);
        try {
            const api_key = await getuserdata("api_key");
            // Make the API request to fetch the download link
            const response = await downloadFolder(api_key, item.id);

            console.log('Download Response:', response);

            // Check if the response is successful
            if (response.success) {
                // Get the download link from the response
                const downloadLink = response.downloadLink;

                // Create an invisible <a> element and trigger the download
                const link = document.createElement('a');
                link.href = downloadLink;
                link.download = downloadLink.substring(downloadLink.lastIndexOf('/') + 1); // Optionally set the filename (last part of URL)
                document.body.appendChild(link); // Append link to the document
                link.click(); // Trigger the download
                document.body.removeChild(link); // Remove the link after download

                console.log('Download started automatically for', item.file_name);
                setShowloading("");
            } else {
                // Show an error message if the folder details couldn't be fetched
                toast.error("Failed to fetch folder details.");
                setShowloading("");
            }
        } catch (error) {
            console.error("Error during download:", error);
            toast.error("An error occurred while downloading the folder.");
            setShowloading("");
        }
    };


    return (
        <section>
            <div className="card mb-4">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>
                <div className="card-filter">
                    <form >
                        <div className="filter-form">
                            <div className="filter-item">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Type file name & folder name"
                                    className="form-control"
                                    id="search"
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </div>
                            <div className="filter-action">
                                <Button variant="info" type="submit" disabled={loading}>
                                    <i className="fas fa-search"></i> Search
                                </Button>
                                <Button variant="danger" onClick={handleReset} disabled={loading}>
                                    <i className="las la-sync"></i> Reset
                                </Button>
                                <Button variant="primary" onClick={() => setShowCreateFolderModal(true)} disabled={loading}>
                                    <i className="fa fa-folder"></i> {currentFolderId ? "Create Folder" : "Create Bucket"}
                                </Button>
                                {currentFolderId ? (
                                    <Button variant="primary" onClick={() => setShowUploadFileModal(true)} disabled={loading}>
                                        <i className="fa fa-upload"></i> Upload File
                                    </Button>
                                ) : (<></>
                                )}
                                {currentFolderId ? (
                                    <Button variant="primary" onClick={() => setShowUploadFolderModal(true)} disabled={loading}>
                                        <i className="fa fa-upload"></i> Upload Folder
                                    </Button>
                                ) : (<></>
                                )}
                                {Checkedrows.length > 0 ? (
                                    <div className="statusUpdateBtn">
                                        <a
                                            className="i-btn success--btn btn--md bulkAction"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Bulk Actions"
                                            onClick={() => handlebulkdelet()} // Corrected: wrapped in a function
                                        >
                                            <svg
                                                className="svg-inline--fa fa-gear"
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="gear"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z"
                                                />
                                            </svg>
                                            Delete
                                        </a>
                                    </div>
                                ) : (<></>)}

                            </div>
                        </div>
                    </form>
                </div>
                <div className="breadcrumb" style={{ padding: '0px 0px 0px 23px' }}>
                    {/* Home breadcrumb item */}
                    <span>
                        <a
                            href="#"
                            onClick={() => handleBreadCrumbClick(0, 0)}
                            style={{ fontSize: '18px' }}
                        >
                            Home
                        </a>
                    </span>

                    {/* Iterate through currentPath, skipping the first element (Home) */}
                    {currentPath.slice(1) // Exclude 'Home' (first element)
                        .sort((a, b) => a.id - b.id) // Sort by id in ascending order
                        .map((part, index) => (
                            <span key={part.id}>
                                {' / '}
                                <a
                                    href="#"
                                    className={part.id}
                                    onClick={() => handleBreadCrumbClick(index + 1, part.id)} // Pass index and id
                                    style={{ fontSize: '18px' }}
                                >
                                    {part.name} {/* Render the file name */}
                                </a>
                            </span>
                        ))}
                </div>

                <CreateFolderModal
                    showModal={showCreateFolderModal}
                    handleClose={() => setShowCreateFolderModal(false)}
                    handleCreateFolder={handleCreateFolder}
                    path={currentPath.slice(1)}
                    folderId={currentFolderId}
                    handlePageChange={currentFolderId ? onFolderClickredirect : handlePageChange}

                />
                <UploadFileModal
                    showModal={showUploadFileModal}
                    handleClose={handeleuploadfolderclose}
                    onUploadFile={handleFileUpload}
                    path={currentPath.slice(1)}
                    folderId={currentFolderId}
                    loginToken={loginToken}
                    newBucket_id={newBucket_id}
                    onFolderClickredirect={onFolderClickredirect}
                    handlePageChange={handlePageChange}

                />

                <UploadFolder
                    showModal={ShowUploadFolderModal}
                    handleClose={handeleuploadfolderclose}
                    onUploadFile={handleFileUpload}
                    path={currentPath.slice(1)}
                    folderId={currentFolderId}
                    loginToken={loginToken}
                    newBucket_id={newBucket_id}
                    onFolderClickredirect={onFolderClickredirect}
                    handlePageChange={handlePageChange}

                />

                <div className="card-body px-0">
                    <div className="responsive-table">
                        <Table>
                            <thead>
                                <tr>
                                    <th data-label="Group">
                                        <div className="d-flex align-items-center">
                                            <input
                                                className="form-check-input mt-0 me-2 checkAll"
                                                type="checkbox"
                                                checked={allChecked}
                                                onChange={handleSelectAll}
                                                value=""
                                                aria-label="Checkbox for following text input" />
                                            <span>Sl No.</span>
                                        </div>
                                    </th>
                                    {currentFolderId ? (<></>) : (<th data-label="Group">Bucket Id</th>)}
                                    <th data-label="Group">UID</th>
                                    <th data-label="Group">Name</th>
                                    {currentFolderId ? (<th data-label="Group">Path</th>) : (<></>)}
                                    {currentFolderId ? (<th data-label="Group">Type</th>) : (<></>)}
                                    <th data-label="Group">Size</th>
                                    <th data-label="Group">Last Modified</th>
                                    <th data-label="Group">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedItemsState.length === 0 ? (
                                    <tr>
                                        <td colSpan={7} style={{ textAlign: 'center' }}>
                                            No Data Available
                                        </td>
                                    </tr>
                                ) : (
                                    paginatedItemsState.map((item, index) => (
                                        <tr key={index}>
                                            <td data-label="ID" className="d-none d-sm-flex align-items-center" style={{ border: 'none' }}>
                                                <input
                                                    className="form-check-input mt-0 me-2"
                                                    type="checkbox"
                                                    name="contactUid"
                                                    value={item.id}
                                                    checked={item.isChecked}
                                                    onChange={() => handleRowCheck(item.id)}
                                                    aria-label="Checkbox for following text input"
                                                />
                                                {index + 1} {/* Display the contact's index */}
                                            </td>
                                            {currentFolderId ? (<></>) : (
                                                <td data-label="Bucket Id">
                                                    {item.type === 'folder' ? (
                                                        <a href="#" onClick={() => onFolderClick(item)}>
                                                            {item.Bucket_id}
                                                        </a>
                                                    ) : (
                                                        item.Bucket_id
                                                    )}
                                                </td>
                                            )}
                                            <td data-label="Uid">
                                                {item.type === 'folder' ? (
                                                    <a href="#" onClick={() => onFolderClick(item)}>
                                                        {item.uid || "--"}
                                                    </a>
                                                ) : (
                                                    item.uid || "--"
                                                )}
                                            </td>
                                            <td data-label="File Name">{item.file_name}</td>
                                            {currentFolderId ? (<td data-label="Root">{item.root}</td>) : (<></>)}
                                            {currentFolderId ? (<td data-label="Type">{item.type}</td>) : (<></>)}
                                            <td data-label="Size">{humanFileSize1(item.size)}</td>
                                            <td data-label="Updated At">{formatDate(item.updated_at)}</td>
                                            <td style={{ padding: '8px' }} data-label="Action">
                                                {item.type === 'folder' ? (
                                                    <>
                                                        <Button variant="primary" onClick={() => onFolderClick(item)} style={{ height: '38px' }}>
                                                            Open
                                                        </Button>
                                                        <Button variant="primary" onClick={() => handledownloadFolder(item)} className="btn btn-primary" style={{ marginLeft: '10px' }}>
                                                            {showloading == item.id ? ("Loading...") : ("Download")}
                                                        </Button>
                                                        <Button variant="danger" onClick={() => handleFolderDelete(item)} style={{ marginLeft: '10px' }}>
                                                            Delete
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {(isImage(item.file_name) || isVideo(item.file_name)) && (
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => onView(item)}
                                                                style={{ marginRight: '10px', height: '38px' }}
                                                            >
                                                                View
                                                            </Button>
                                                        )}
                                                        <a href={process.env.REACT_APP_IMAGE_BASE_LOCALURL+'/uploads/users/'+ item.userid +'/'+item.root +'/'+item.file_name} download={item.name} className="btn btn-primary" style={{ height: '38px' }}>
                                                            Download
                                                        </a>
                                                        <Button variant="danger" onClick={() => handleFolderDelete(item)} style={{ marginLeft: '10px' }}>
                                                            Delete
                                                        </Button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <Paginationnew currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChangenew} />
                    <div className="m-3"></div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>

            {/* Modal to show file */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>File Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {fileType === 'image' ? (
                        <img src={fileUrl} alt="File" style={{ width: '100%', height: 'auto' }} />
                    ) : fileType === 'video' ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={fileUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <p>Unsupported file type</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default CloudStorage;