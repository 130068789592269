import React, { useState } from 'react';

const Settings = () => {
    const [formData, setFormData] = useState({
        site_name: 'Getone Click',
        copyright: 'Get24Host',
        primary_color: '260055',
        secondary_color: '260055',
        cron_pop_up: false,
        debug_mode: false,
        maintenance_mode: false,
        landing_page: true,
        maintenance_mode_message: 'Please be advised that there will be scheduled downtime across our network from 12.00AM to 2.00AM',
    });
    const [dialCode, setDialCode] = useState('91'); // default dial code

    const handleCountryChange = (event) => {
        const selectedDialCode = event.target.value;
        setDialCode(selectedDialCode);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // You can perform form submission logic here, such as sending data to the server.
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">General Setting</h4>
                            <div className="d-flex align-items-center flex-wrap gap-3">
                                <div>
                                    Last time cron job run <i className="las la-arrow-right"></i>
                                    <span className="text--success"> 2024-08-14 03:31 PM</span>
                                </div>
                                <a
                                    href="#" onClick={(e) => e.preventDefault()}
                                    data-bs-toggle="modal"
                                    data-bs-target="#cronjob"
                                    className="i-btn primary--btn btn--sm"
                                >
                                    <i className="las la-key"></i> Setup Cron Jobs
                                </a>
                            </div>
                        </div>

                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-wrapper">
                                    <h6 className="form-wrapper-title">Site Setting</h6>
                                    <div className="row g-4">
                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="site_name" className="form-label">
                                                    Site Name <sup className="text--danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="site_name"
                                                    id="site_name"
                                                    className="form-control"
                                                    value={formData.site_name}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Site Name"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="copyright" className="form-label">
                                                    Copyright Text <sup className="text--danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="copyright"
                                                    name="copyright"
                                                    className="form-control"
                                                    value={formData.copyright}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Copyright Text"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="primary_color" className="form-label">
                                                    Primary Color <sup className="text--danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control color-code"
                                                    name="primary_color"
                                                    id="primary_color"
                                                    value={formData.primary_color}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="secondary_color" className="form-label">
                                                    Secondary Color <sup className="text--danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control color-code"
                                                    name="secondary_color"
                                                    id="secondary_color"
                                                    value={formData.secondary_color}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="switch-container">
                                                <label className="form-check-label" htmlFor="cron_pop_up">
                                                    Turn On/Off In Dashboard Popup notification
                                                </label>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        name="cron_pop_up"
                                                        id="cron_pop_up"
                                                        checked={formData.cron_pop_up}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="switch-container">
                                                <label className="form-check-label" htmlFor="debug_mode">
                                                    Debug Mode For Developing Purpose
                                                </label>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        name="debug_mode"
                                                        id="debug_mode"
                                                        checked={formData.debug_mode}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="switch-container">
                                                <label className="form-check-label" htmlFor="maintenance_mode">
                                                    Maintenance Mode For Site Maintenance
                                                </label>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        name="maintenance_mode"
                                                        id="maintenance_mode"
                                                        checked={formData.maintenance_mode}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>

                                        {formData.maintenance_mode && (
                                            <div className="col-12">
                                                <div className="form-item">
                                                    <label htmlFor="maintenance_mode_message" className="form-label">
                                                        Maintenance Mode Message
                                                        <sup className="text--danger">*</sup>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="maintenance_mode_message"
                                                        id="maintenance_mode_message"
                                                        className="form-control"
                                                        value={formData.maintenance_mode_message}
                                                        onChange={handleInputChange}
                                                        placeholder="Write some message for maintenance mode page"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="form-wrapper">
                                    <h6 className="form-wrapper-title">Others Setting</h6>
                                    <div className="row g-4">
                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="timelocation" className="form-label">
                                                    Time Zone <sup className="text--danger">*</sup>
                                                </label>
                                                <select className="form-control" id="timelocation" name="timelocation" required>
                                                    <option value="'Africa/Abidjan'">Africa/Abidjan</option>
                                                    <option value="'Africa/Accra'">Africa/Accra</option>
                                                    <option value="'Africa/Addis_Ababa'">Africa/Addis_Ababa</option>
                                                    <option value="'Africa/Algiers'">Africa/Algiers</option>
                                                    <option value="'Africa/Asmara'">Africa/Asmara</option>
                                                    <option value="'Africa/Bamako'">Africa/Bamako</option>
                                                    <option value="'Africa/Bangui'">Africa/Bangui</option>
                                                    <option value="'Africa/Banjul'">Africa/Banjul</option>
                                                    <option value="'Africa/Bissau'">Africa/Bissau</option>
                                                    <option value="'Africa/Blantyre'">Africa/Blantyre</option>
                                                    <option value="'Africa/Brazzaville'">Africa/Brazzaville</option>
                                                    <option value="'Africa/Bujumbura'">Africa/Bujumbura</option>
                                                    <option value="'Africa/Cairo'">Africa/Cairo</option>
                                                    <option value="'Africa/Casablanca'">Africa/Casablanca</option>
                                                    <option value="'Africa/Ceuta'">Africa/Ceuta</option>
                                                    <option value="'Africa/Conakry'">Africa/Conakry</option>
                                                    <option value="'Africa/Dakar'">Africa/Dakar</option>
                                                    <option value="'Africa/Dar_es_Salaam'">Africa/Dar_es_Salaam</option>
                                                    <option value="'Africa/Djibouti'">Africa/Djibouti</option>
                                                    <option value="'Africa/Douala'">Africa/Douala</option>
                                                    <option value="'Africa/El_Aaiun'">Africa/El_Aaiun</option>
                                                    <option value="'Africa/Freetown'">Africa/Freetown</option>
                                                    <option value="'Africa/Gaborone'">Africa/Gaborone</option>
                                                    <option value="'Africa/Harare'">Africa/Harare</option>
                                                    <option value="'Africa/Johannesburg'">Africa/Johannesburg</option>
                                                    <option value="'Africa/Juba'">Africa/Juba</option>
                                                    <option value="'Africa/Kampala'">Africa/Kampala</option>
                                                    <option value="'Africa/Khartoum'">Africa/Khartoum</option>
                                                    <option value="'Africa/Kigali'">Africa/Kigali</option>
                                                    <option value="'Africa/Kinshasa'">Africa/Kinshasa</option>
                                                    <option value="'Africa/Lagos'">Africa/Lagos</option>
                                                    <option value="'Africa/Libreville'">Africa/Libreville</option>
                                                    <option value="'Africa/Lome'">Africa/Lome</option>
                                                    <option value="'Africa/Luanda'">Africa/Luanda</option>
                                                    <option value="'Africa/Lubumbashi'">Africa/Lubumbashi</option>
                                                    <option value="'Africa/Lusaka'">Africa/Lusaka</option>
                                                    <option value="'Africa/Malabo'">Africa/Malabo</option>
                                                    <option value="'Africa/Maputo'">Africa/Maputo</option>
                                                    <option value="'Africa/Maseru'">Africa/Maseru</option>
                                                    <option value="'Africa/Mbabane'">Africa/Mbabane</option>
                                                    <option value="'Africa/Mogadishu'">Africa/Mogadishu</option>
                                                    <option value="'Africa/Monrovia'">Africa/Monrovia</option>
                                                    <option value="'Africa/Nairobi'">Africa/Nairobi</option>
                                                    <option value="'Africa/Ndjamena'">Africa/Ndjamena</option>
                                                    <option value="'Africa/Niamey'">Africa/Niamey</option>
                                                    <option value="'Africa/Nouakchott'">Africa/Nouakchott</option>
                                                    <option value="'Africa/Ouagadougou'">Africa/Ouagadougou</option>
                                                    <option value="'Africa/Porto-Novo'">Africa/Porto-Novo</option>
                                                    <option value="'Africa/Sao_Tome'">Africa/Sao_Tome</option>
                                                    <option value="'Africa/Tripoli'">Africa/Tripoli</option>
                                                    <option value="'Africa/Tunis'">Africa/Tunis</option>
                                                    <option value="'Africa/Windhoek'">Africa/Windhoek</option>
                                                    <option value="'America/Adak'">America/Adak</option>
                                                    <option value="'America/Anchorage'">America/Anchorage</option>
                                                    <option value="'America/Anguilla'">America/Anguilla</option>
                                                    <option value="'America/Antigua'">America/Antigua</option>
                                                    <option value="'America/Araguaina'">America/Araguaina</option>
                                                    <option value="'America/Argentina/Buenos_Aires'">America/Argentina/Buenos_Aires</option>
                                                    <option value="'America/Argentina/Catamarca'">America/Argentina/Catamarca</option>
                                                    <option value="'America/Argentina/Cordoba'">America/Argentina/Cordoba</option>
                                                    <option value="'America/Argentina/Jujuy'">America/Argentina/Jujuy</option>
                                                    <option value="'America/Argentina/La_Rioja'">America/Argentina/La_Rioja</option>
                                                    <option value="'America/Argentina/Mendoza'">America/Argentina/Mendoza</option>
                                                    <option value="'America/Argentina/Rio_Gallegos'">America/Argentina/Rio_Gallegos</option>
                                                    <option value="'America/Argentina/Salta'">America/Argentina/Salta</option>
                                                    <option value="'America/Argentina/San_Juan'">America/Argentina/San_Juan</option>
                                                    <option value="'America/Argentina/San_Luis'">America/Argentina/San_Luis</option>
                                                    <option value="'America/Argentina/Tucuman'">America/Argentina/Tucuman</option>
                                                    <option value="'America/Argentina/Ushuaia'">America/Argentina/Ushuaia</option>
                                                    <option value="'America/Aruba'">America/Aruba</option>
                                                    <option value="'America/Asuncion'">America/Asuncion</option>
                                                    <option value="'America/Atikokan'">America/Atikokan</option>
                                                    <option value="'America/Bahia'">America/Bahia</option>
                                                    <option value="'America/Bahia_Banderas'">America/Bahia_Banderas</option>
                                                    <option value="'America/Barbados'">America/Barbados</option>
                                                    <option value="'America/Belem'">America/Belem</option>
                                                    <option value="'America/Belize'">America/Belize</option>
                                                    <option value="'America/Blanc-Sablon'">America/Blanc-Sablon</option>
                                                    <option value="'America/Boa_Vista'">America/Boa_Vista</option>
                                                    <option value="'America/Bogota'">America/Bogota</option>
                                                    <option value="'America/Boise'">America/Boise</option>
                                                    <option value="'America/Cambridge_Bay'">America/Cambridge_Bay</option>
                                                    <option value="'America/Campo_Grande'">America/Campo_Grande</option>
                                                    <option value="'America/Cancun'">America/Cancun</option>
                                                    <option value="'America/Caracas'">America/Caracas</option>
                                                    <option value="'America/Cayenne'">America/Cayenne</option>
                                                    <option value="'America/Cayman'">America/Cayman</option>
                                                    <option value="'America/Chicago'">America/Chicago</option>
                                                    <option value="'America/Chihuahua'">America/Chihuahua</option>
                                                    <option value="'America/Ciudad_Juarez'">America/Ciudad_Juarez</option>
                                                    <option value="'America/Costa_Rica'">America/Costa_Rica</option>
                                                    <option value="'America/Creston'">America/Creston</option>
                                                    <option value="'America/Cuiaba'">America/Cuiaba</option>
                                                    <option value="'America/Curacao'">America/Curacao</option>
                                                    <option value="'America/Danmarkshavn'">America/Danmarkshavn</option>
                                                    <option value="'America/Dawson'">America/Dawson</option>
                                                    <option value="'America/Dawson_Creek'">America/Dawson_Creek</option>
                                                    <option value="'America/Denver'">America/Denver</option>
                                                    <option value="'America/Detroit'">America/Detroit</option>
                                                    <option value="'America/Dominica'">America/Dominica</option>
                                                    <option value="'America/Edmonton'">America/Edmonton</option>
                                                    <option value="'America/Eirunepe'">America/Eirunepe</option>
                                                    <option value="'America/El_Salvador'">America/El_Salvador</option>
                                                    <option value="'America/Fort_Nelson'">America/Fort_Nelson</option>
                                                    <option value="'America/Fortaleza'">America/Fortaleza</option>
                                                    <option value="'America/Glace_Bay'">America/Glace_Bay</option>
                                                    <option value="'America/Goose_Bay'">America/Goose_Bay</option>
                                                    <option value="'America/Grand_Turk'">America/Grand_Turk</option>
                                                    <option value="'America/Grenada'">America/Grenada</option>
                                                    <option value="'America/Guadeloupe'">America/Guadeloupe</option>
                                                    <option value="'America/Guatemala'">America/Guatemala</option>
                                                    <option value="'America/Guayaquil'">America/Guayaquil</option>
                                                    <option value="'America/Guyana'">America/Guyana</option>
                                                    <option value="'America/Halifax'">America/Halifax</option>
                                                    <option value="'America/Havana'">America/Havana</option>
                                                    <option value="'America/Hermosillo'">America/Hermosillo</option>
                                                    <option value="'America/Indiana/Indianapolis'">America/Indiana/Indianapolis</option>
                                                    <option value="'America/Indiana/Knox'">America/Indiana/Knox</option>
                                                    <option value="'America/Indiana/Marengo'">America/Indiana/Marengo</option>
                                                    <option value="'America/Indiana/Petersburg'">America/Indiana/Petersburg</option>
                                                    <option value="'America/Indiana/Tell_City'">America/Indiana/Tell_City</option>
                                                    <option value="'America/Indiana/Vevay'">America/Indiana/Vevay</option>
                                                    <option value="'America/Indiana/Vincennes'">America/Indiana/Vincennes</option>
                                                    <option value="'America/Indiana/Winamac'">America/Indiana/Winamac</option>
                                                    <option value="'America/Inuvik'">America/Inuvik</option>
                                                    <option value="'America/Iqaluit'">America/Iqaluit</option>
                                                    <option value="'America/Jamaica'">America/Jamaica</option>
                                                    <option value="'America/Juneau'">America/Juneau</option>
                                                    <option value="'America/Kentucky/Louisville'">America/Kentucky/Louisville</option>
                                                    <option value="'America/Kentucky/Monticello'">America/Kentucky/Monticello</option>
                                                    <option value="'America/Kralendijk'">America/Kralendijk</option>
                                                    <option value="'America/La_Paz'">America/La_Paz</option>
                                                    <option value="'America/Lima'">America/Lima</option>
                                                    <option value="'America/Los_Angeles'">America/Los_Angeles</option>
                                                    <option value="'America/Lower_Princes'">America/Lower_Princes</option>
                                                    <option value="'America/Maceio'">America/Maceio</option>
                                                    <option value="'America/Managua'">America/Managua</option>
                                                    <option value="'America/Manaus'">America/Manaus</option>
                                                    <option value="'America/Marigot'">America/Marigot</option>
                                                    <option value="'America/Martinique'">America/Martinique</option>
                                                    <option value="'America/Matamoros'">America/Matamoros</option>
                                                    <option value="'America/Mazatlan'">America/Mazatlan</option>
                                                    <option value="'America/Menominee'">America/Menominee</option>
                                                    <option value="'America/Merida'">America/Merida</option>
                                                    <option value="'America/Metlakatla'">America/Metlakatla</option>
                                                    <option value="'America/Mexico_City'">America/Mexico_City</option>
                                                    <option value="'America/Miquelon'">America/Miquelon</option>
                                                    <option value="'America/Moncton'">America/Moncton</option>
                                                    <option value="'America/Monterrey'">America/Monterrey</option>
                                                    <option value="'America/Montevideo'">America/Montevideo</option>
                                                    <option value="'America/Montserrat'">America/Montserrat</option>
                                                    <option value="'America/Nassau'">America/Nassau</option>
                                                    <option value="'America/New_York'">America/New_York</option>
                                                    <option value="'America/Nome'">America/Nome</option>
                                                    <option value="'America/Noronha'">America/Noronha</option>
                                                    <option value="'America/North_Dakota/Beulah'">America/North_Dakota/Beulah</option>
                                                    <option value="'America/North_Dakota/Center'">America/North_Dakota/Center</option>
                                                    <option value="'America/North_Dakota/New_Salem'">America/North_Dakota/New_Salem</option>
                                                    <option value="'America/Nuuk'">America/Nuuk</option>
                                                    <option value="'America/Ojinaga'">America/Ojinaga</option>
                                                    <option value="'America/Panama'">America/Panama</option>
                                                    <option value="'America/Paramaribo'">America/Paramaribo</option>
                                                    <option value="'America/Phoenix'">America/Phoenix</option>
                                                    <option value="'America/Port-au-Prince'">America/Port-au-Prince</option>
                                                    <option value="'America/Port_of_Spain'">America/Port_of_Spain</option>
                                                    <option value="'America/Porto_Velho'">America/Porto_Velho</option>
                                                    <option value="'America/Puerto_Rico'">America/Puerto_Rico</option>
                                                    <option value="'America/Punta_Arenas'">America/Punta_Arenas</option>
                                                    <option value="'America/Rankin_Inlet'">America/Rankin_Inlet</option>
                                                    <option value="'America/Recife'">America/Recife</option>
                                                    <option value="'America/Regina'">America/Regina</option>
                                                    <option value="'America/Resolute'">America/Resolute</option>
                                                    <option value="'America/Rio_Branco'">America/Rio_Branco</option>
                                                    <option value="'America/Santarem'">America/Santarem</option>
                                                    <option value="'America/Santiago'">America/Santiago</option>
                                                    <option value="'America/Santo_Domingo'">America/Santo_Domingo</option>
                                                    <option value="'America/Sao_Paulo'">America/Sao_Paulo</option>
                                                    <option value="'America/Scoresbysund'">America/Scoresbysund</option>
                                                    <option value="'America/Sitka'">America/Sitka</option>
                                                    <option value="'America/St_Barthelemy'">America/St_Barthelemy</option>
                                                    <option value="'America/St_Johns'">America/St_Johns</option>
                                                    <option value="'America/St_Kitts'">America/St_Kitts</option>
                                                    <option value="'America/St_Lucia'">America/St_Lucia</option>
                                                    <option value="'America/St_Thomas'">America/St_Thomas</option>
                                                    <option value="'America/St_Vincent'">America/St_Vincent</option>
                                                    <option value="'America/Swift_Current'">America/Swift_Current</option>
                                                    <option value="'America/Tegucigalpa'">America/Tegucigalpa</option>
                                                    <option value="'America/Thule'">America/Thule</option>
                                                    <option value="'America/Tijuana'">America/Tijuana</option>
                                                    <option value="'America/Toronto'">America/Toronto</option>
                                                    <option value="'America/Tortola'">America/Tortola</option>
                                                    <option value="'America/Vancouver'">America/Vancouver</option>
                                                    <option value="'America/Whitehorse'">America/Whitehorse</option>
                                                    <option value="'America/Winnipeg'">America/Winnipeg</option>
                                                    <option value="'America/Yakutat'">America/Yakutat</option>
                                                    <option value="'Antarctica/Casey'">Antarctica/Casey</option>
                                                    <option value="'Antarctica/Davis'">Antarctica/Davis</option>
                                                    <option value="'Antarctica/DumontDUrville'">Antarctica/DumontDUrville</option>
                                                    <option value="'Antarctica/Macquarie'">Antarctica/Macquarie</option>
                                                    <option value="'Antarctica/Mawson'">Antarctica/Mawson</option>
                                                    <option value="'Antarctica/McMurdo'">Antarctica/McMurdo</option>
                                                    <option value="'Antarctica/Palmer'">Antarctica/Palmer</option>
                                                    <option value="'Antarctica/Rothera'">Antarctica/Rothera</option>
                                                    <option value="'Antarctica/Syowa'">Antarctica/Syowa</option>
                                                    <option value="'Antarctica/Troll'">Antarctica/Troll</option>
                                                    <option value="'Antarctica/Vostok'">Antarctica/Vostok</option>
                                                    <option value="'Arctic/Longyearbyen'">Arctic/Longyearbyen</option>
                                                    <option value="'Asia/Aden'">Asia/Aden</option>
                                                    <option value="'Asia/Almaty'">Asia/Almaty</option>
                                                    <option value="'Asia/Amman'">Asia/Amman</option>
                                                    <option value="'Asia/Anadyr'">Asia/Anadyr</option>
                                                    <option value="'Asia/Aqtau'">Asia/Aqtau</option>
                                                    <option value="'Asia/Aqtobe'">Asia/Aqtobe</option>
                                                    <option value="'Asia/Ashgabat'">Asia/Ashgabat</option>
                                                    <option value="'Asia/Atyrau'">Asia/Atyrau</option>
                                                    <option value="'Asia/Baghdad'">Asia/Baghdad</option>
                                                    <option value="'Asia/Bahrain'">Asia/Bahrain</option>
                                                    <option value="'Asia/Baku'">Asia/Baku</option>
                                                    <option value="'Asia/Bangkok'">Asia/Bangkok</option>
                                                    <option value="'Asia/Barnaul'">Asia/Barnaul</option>
                                                    <option value="'Asia/Beirut'">Asia/Beirut</option>
                                                    <option value="'Asia/Bishkek'">Asia/Bishkek</option>
                                                    <option value="'Asia/Brunei'">Asia/Brunei</option>
                                                    <option value="'Asia/Chita'">Asia/Chita</option>
                                                    <option value="'Asia/Choibalsan'">Asia/Choibalsan</option>
                                                    <option value="'Asia/Colombo'">Asia/Colombo</option>
                                                    <option value="'Asia/Damascus'">Asia/Damascus</option>
                                                    <option value="'Asia/Dhaka'">Asia/Dhaka</option>
                                                    <option value="'Asia/Dili'">Asia/Dili</option>
                                                    <option value="'Asia/Dubai'">Asia/Dubai</option>
                                                    <option value="'Asia/Dushanbe'">Asia/Dushanbe</option>
                                                    <option value="'Asia/Famagusta'">Asia/Famagusta</option>
                                                    <option value="'Asia/Gaza'">Asia/Gaza</option>
                                                    <option value="'Asia/Hebron'">Asia/Hebron</option>
                                                    <option value="'Asia/Ho_Chi_Minh'">Asia/Ho_Chi_Minh</option>
                                                    <option value="'Asia/Hong_Kong'">Asia/Hong_Kong</option>
                                                    <option value="'Asia/Hovd'">Asia/Hovd</option>
                                                    <option value="'Asia/Irkutsk'">Asia/Irkutsk</option>
                                                    <option value="'Asia/Jakarta'">Asia/Jakarta</option>
                                                    <option value="'Asia/Jayapura'">Asia/Jayapura</option>
                                                    <option value="'Asia/Jerusalem'">Asia/Jerusalem</option>
                                                    <option value="'Asia/Kabul'">Asia/Kabul</option>
                                                    <option value="'Asia/Kamchatka'">Asia/Kamchatka</option>
                                                    <option value="'Asia/Karachi'">Asia/Karachi</option>
                                                    <option value="'Asia/Kathmandu'">Asia/Kathmandu</option>
                                                    <option value="'Asia/Khandyga'">Asia/Khandyga</option>
                                                    <option value="'Asia/Kolkata'">Asia/Kolkata</option>
                                                    <option value="'Asia/Krasnoyarsk'">Asia/Krasnoyarsk</option>
                                                    <option value="'Asia/Kuala_Lumpur'">Asia/Kuala_Lumpur</option>
                                                    <option value="'Asia/Kuching'">Asia/Kuching</option>
                                                    <option value="'Asia/Kuwait'">Asia/Kuwait</option>
                                                    <option value="'Asia/Macau'">Asia/Macau</option>
                                                    <option value="'Asia/Magadan'">Asia/Magadan</option>
                                                    <option value="'Asia/Makassar'">Asia/Makassar</option>
                                                    <option value="'Asia/Manila'">Asia/Manila</option>
                                                    <option value="'Asia/Muscat'">Asia/Muscat</option>
                                                    <option value="'Asia/Nicosia'">Asia/Nicosia</option>
                                                    <option value="'Asia/Novokuznetsk'">Asia/Novokuznetsk</option>
                                                    <option value="'Asia/Novosibirsk'">Asia/Novosibirsk</option>
                                                    <option value="'Asia/Omsk'">Asia/Omsk</option>
                                                    <option value="'Asia/Oral'">Asia/Oral</option>
                                                    <option value="'Asia/Phnom_Penh'">Asia/Phnom_Penh</option>
                                                    <option value="'Asia/Pontianak'">Asia/Pontianak</option>
                                                    <option value="'Asia/Pyongyang'">Asia/Pyongyang</option>
                                                    <option value="'Asia/Qatar'">Asia/Qatar</option>
                                                    <option value="'Asia/Qostanay'">Asia/Qostanay</option>
                                                    <option value="'Asia/Qyzylorda'">Asia/Qyzylorda</option>
                                                    <option value="'Asia/Riyadh'">Asia/Riyadh</option>
                                                    <option value="'Asia/Sakhalin'">Asia/Sakhalin</option>
                                                    <option value="'Asia/Samarkand'">Asia/Samarkand</option>
                                                    <option value="'Asia/Seoul'">Asia/Seoul</option>
                                                    <option value="'Asia/Shanghai'">Asia/Shanghai</option>
                                                    <option value="'Asia/Singapore'">Asia/Singapore</option>
                                                    <option value="'Asia/Srednekolymsk'">Asia/Srednekolymsk</option>
                                                    <option value="'Asia/Taipei'">Asia/Taipei</option>
                                                    <option value="'Asia/Tashkent'">Asia/Tashkent</option>
                                                    <option value="'Asia/Tbilisi'">Asia/Tbilisi</option>
                                                    <option value="'Asia/Tehran'">Asia/Tehran</option>
                                                    <option value="'Asia/Thimphu'">Asia/Thimphu</option>
                                                    <option value="'Asia/Tokyo'">Asia/Tokyo</option>
                                                    <option value="'Asia/Tomsk'">Asia/Tomsk</option>
                                                    <option value="'Asia/Ulaanbaatar'">Asia/Ulaanbaatar</option>
                                                    <option value="'Asia/Urumqi'">Asia/Urumqi</option>
                                                    <option value="'Asia/Ust-Nera'">Asia/Ust-Nera</option>
                                                    <option value="'Asia/Vientiane'">Asia/Vientiane</option>
                                                    <option value="'Asia/Vladivostok'">Asia/Vladivostok</option>
                                                    <option value="'Asia/Yakutsk'">Asia/Yakutsk</option>
                                                    <option value="'Asia/Yangon'">Asia/Yangon</option>
                                                    <option value="'Asia/Yekaterinburg'">Asia/Yekaterinburg</option>
                                                    <option value="'Asia/Yerevan'">Asia/Yerevan</option>
                                                    <option value="'Atlantic/Azores'">Atlantic/Azores</option>
                                                    <option value="'Atlantic/Bermuda'">Atlantic/Bermuda</option>
                                                    <option value="'Atlantic/Canary'">Atlantic/Canary</option>
                                                    <option value="'Atlantic/Cape_Verde'">Atlantic/Cape_Verde</option>
                                                    <option value="'Atlantic/Faroe'">Atlantic/Faroe</option>
                                                    <option value="'Atlantic/Madeira'">Atlantic/Madeira</option>
                                                    <option value="'Atlantic/Reykjavik'">Atlantic/Reykjavik</option>
                                                    <option value="'Atlantic/South_Georgia'">Atlantic/South_Georgia</option>
                                                    <option value="'Atlantic/St_Helena'">Atlantic/St_Helena</option>
                                                    <option value="'Atlantic/Stanley'">Atlantic/Stanley</option>
                                                    <option value="'Australia/Adelaide'">Australia/Adelaide</option>
                                                    <option value="'Australia/Brisbane'">Australia/Brisbane</option>
                                                    <option value="'Australia/Broken_Hill'">Australia/Broken_Hill</option>
                                                    <option value="'Australia/Darwin'">Australia/Darwin</option>
                                                    <option value="'Australia/Eucla'">Australia/Eucla</option>
                                                    <option value="'Australia/Hobart'">Australia/Hobart</option>
                                                    <option value="'Australia/Lindeman'">Australia/Lindeman</option>
                                                    <option value="'Australia/Lord_Howe'">Australia/Lord_Howe</option>
                                                    <option value="'Australia/Melbourne'">Australia/Melbourne</option>
                                                    <option value="'Australia/Perth'">Australia/Perth</option>
                                                    <option value="'Australia/Sydney'">Australia/Sydney</option>
                                                    <option value="'Europe/Amsterdam'">Europe/Amsterdam</option>
                                                    <option value="'Europe/Andorra'">Europe/Andorra</option>
                                                    <option value="'Europe/Astrakhan'">Europe/Astrakhan</option>
                                                    <option value="'Europe/Athens'">Europe/Athens</option>
                                                    <option value="'Europe/Belgrade'">Europe/Belgrade</option>
                                                    <option value="'Europe/Berlin'">Europe/Berlin</option>
                                                    <option value="'Europe/Bratislava'">Europe/Bratislava</option>
                                                    <option value="'Europe/Brussels'">Europe/Brussels</option>
                                                    <option value="'Europe/Bucharest'">Europe/Bucharest</option>
                                                    <option value="'Europe/Budapest'">Europe/Budapest</option>
                                                    <option value="'Europe/Busingen'">Europe/Busingen</option>
                                                    <option value="'Europe/Chisinau'">Europe/Chisinau</option>
                                                    <option value="'Europe/Copenhagen'">Europe/Copenhagen</option>
                                                    <option value="'Europe/Dublin'">Europe/Dublin</option>
                                                    <option value="'Europe/Gibraltar'">Europe/Gibraltar</option>
                                                    <option value="'Europe/Guernsey'">Europe/Guernsey</option>
                                                    <option value="'Europe/Helsinki'">Europe/Helsinki</option>
                                                    <option value="'Europe/Isle_of_Man'">Europe/Isle_of_Man</option>
                                                    <option value="'Europe/Istanbul'">Europe/Istanbul</option>
                                                    <option value="'Europe/Jersey'">Europe/Jersey</option>
                                                    <option value="'Europe/Kaliningrad'">Europe/Kaliningrad</option>
                                                    <option value="'Europe/Kirov'">Europe/Kirov</option>
                                                    <option value="'Europe/Kyiv'">Europe/Kyiv</option>
                                                    <option value="'Europe/Lisbon'">Europe/Lisbon</option>
                                                    <option value="'Europe/Ljubljana'">Europe/Ljubljana</option>
                                                    <option value="'Europe/London'">Europe/London</option>
                                                    <option value="'Europe/Luxembourg'">Europe/Luxembourg</option>
                                                    <option value="'Europe/Madrid'">Europe/Madrid</option>
                                                    <option value="'Europe/Malta'">Europe/Malta</option>
                                                    <option value="'Europe/Mariehamn'">Europe/Mariehamn</option>
                                                    <option value="'Europe/Minsk'">Europe/Minsk</option>
                                                    <option value="'Europe/Monaco'">Europe/Monaco</option>
                                                    <option value="'Europe/Moscow'">Europe/Moscow</option>
                                                    <option value="'Europe/Oslo'">Europe/Oslo</option>
                                                    <option value="'Europe/Paris'">Europe/Paris</option>
                                                    <option value="'Europe/Podgorica'">Europe/Podgorica</option>
                                                    <option value="'Europe/Prague'">Europe/Prague</option>
                                                    <option value="'Europe/Riga'">Europe/Riga</option>
                                                    <option value="'Europe/Rome'">Europe/Rome</option>
                                                    <option value="'Europe/Samara'">Europe/Samara</option>
                                                    <option value="'Europe/San_Marino'">Europe/San_Marino</option>
                                                    <option value="'Europe/Sarajevo'">Europe/Sarajevo</option>
                                                    <option value="'Europe/Saratov'">Europe/Saratov</option>
                                                    <option value="'Europe/Simferopol'">Europe/Simferopol</option>
                                                    <option value="'Europe/Skopje'">Europe/Skopje</option>
                                                    <option value="'Europe/Sofia'">Europe/Sofia</option>
                                                    <option value="'Europe/Stockholm'">Europe/Stockholm</option>
                                                    <option value="'Europe/Tallinn'">Europe/Tallinn</option>
                                                    <option value="'Europe/Tirane'">Europe/Tirane</option>
                                                    <option value="'Europe/Ulyanovsk'">Europe/Ulyanovsk</option>
                                                    <option value="'Europe/Vaduz'">Europe/Vaduz</option>
                                                    <option value="'Europe/Vatican'">Europe/Vatican</option>
                                                    <option value="'Europe/Vienna'">Europe/Vienna</option>
                                                    <option value="'Europe/Vilnius'">Europe/Vilnius</option>
                                                    <option value="'Europe/Volgograd'">Europe/Volgograd</option>
                                                    <option value="'Europe/Warsaw'">Europe/Warsaw</option>
                                                    <option value="'Europe/Zagreb'">Europe/Zagreb</option>
                                                    <option value="'Europe/Zurich'">Europe/Zurich</option>
                                                    <option value="'Indian/Antananarivo'" selected="">Indian/Antananarivo</option>
                                                    <option value="'Indian/Chagos'">Indian/Chagos</option>
                                                    <option value="'Indian/Christmas'">Indian/Christmas</option>
                                                    <option value="'Indian/Cocos'">Indian/Cocos</option>
                                                    <option value="'Indian/Comoro'">Indian/Comoro</option>
                                                    <option value="'Indian/Kerguelen'">Indian/Kerguelen</option>
                                                    <option value="'Indian/Mahe'">Indian/Mahe</option>
                                                    <option value="'Indian/Maldives'">Indian/Maldives</option>
                                                    <option value="'Indian/Mauritius'">Indian/Mauritius</option>
                                                    <option value="'Indian/Mayotte'">Indian/Mayotte</option>
                                                    <option value="'Indian/Reunion'">Indian/Reunion</option>
                                                    <option value="'Pacific/Apia'">Pacific/Apia</option>
                                                    <option value="'Pacific/Auckland'">Pacific/Auckland</option>
                                                    <option value="'Pacific/Bougainville'">Pacific/Bougainville</option>
                                                    <option value="'Pacific/Chatham'">Pacific/Chatham</option>
                                                    <option value="'Pacific/Chuuk'">Pacific/Chuuk</option>
                                                    <option value="'Pacific/Easter'">Pacific/Easter</option>
                                                    <option value="'Pacific/Efate'">Pacific/Efate</option>
                                                    <option value="'Pacific/Fakaofo'">Pacific/Fakaofo</option>
                                                    <option value="'Pacific/Fiji'">Pacific/Fiji</option>
                                                    <option value="'Pacific/Funafuti'">Pacific/Funafuti</option>
                                                    <option value="'Pacific/Galapagos'">Pacific/Galapagos</option>
                                                    <option value="'Pacific/Gambier'">Pacific/Gambier</option>
                                                    <option value="'Pacific/Guadalcanal'">Pacific/Guadalcanal</option>
                                                    <option value="'Pacific/Guam'">Pacific/Guam</option>
                                                    <option value="'Pacific/Honolulu'">Pacific/Honolulu</option>
                                                    <option value="'Pacific/Kanton'">Pacific/Kanton</option>
                                                    <option value="'Pacific/Kiritimati'">Pacific/Kiritimati</option>
                                                    <option value="'Pacific/Kosrae'">Pacific/Kosrae</option>
                                                    <option value="'Pacific/Kwajalein'">Pacific/Kwajalein</option>
                                                    <option value="'Pacific/Majuro'">Pacific/Majuro</option>
                                                    <option value="'Pacific/Marquesas'">Pacific/Marquesas</option>
                                                    <option value="'Pacific/Midway'">Pacific/Midway</option>
                                                    <option value="'Pacific/Nauru'">Pacific/Nauru</option>
                                                    <option value="'Pacific/Niue'">Pacific/Niue</option>
                                                    <option value="'Pacific/Norfolk'">Pacific/Norfolk</option>
                                                    <option value="'Pacific/Noumea'">Pacific/Noumea</option>
                                                    <option value="'Pacific/Pago_Pago'">Pacific/Pago_Pago</option>
                                                    <option value="'Pacific/Palau'">Pacific/Palau</option>
                                                    <option value="'Pacific/Pitcairn'">Pacific/Pitcairn</option>
                                                    <option value="'Pacific/Pohnpei'">Pacific/Pohnpei</option>
                                                    <option value="'Pacific/Port_Moresby'">Pacific/Port_Moresby</option>
                                                    <option value="'Pacific/Rarotonga'">Pacific/Rarotonga</option>
                                                    <option value="'Pacific/Saipan'">Pacific/Saipan</option>
                                                    <option value="'Pacific/Tahiti'">Pacific/Tahiti</option>
                                                    <option value="'Pacific/Tarawa'">Pacific/Tarawa</option>
                                                    <option value="'Pacific/Tongatapu'">Pacific/Tongatapu</option>
                                                    <option value="'Pacific/Wake'">Pacific/Wake</option>
                                                    <option value="'Pacific/Wallis'">Pacific/Wallis</option>
                                                    <option value="'UTC'">UTC</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="country_code" className="form-label">
                                                    Country Code <sup className="text--danger">*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="country--dial--code">
                                                            {dialCode}
                                                        </span>
                                                    </div>
                                                    <select name="country_code" className="form-select" id="country_code" defaultValue="91" onChange={handleCountryChange}>
                                                        <option value="93">Afghanistan</option>
                                                        <option value="358">Aland Islands</option>
                                                        <option value="355">Albania</option>
                                                        <option value="213">Algeria</option>
                                                        <option value="1684">AmericanSamoa</option>
                                                        <option value="376">Andorra</option>
                                                        <option value="244">Angola</option>
                                                        <option value="1264">Anguilla</option>
                                                        <option value="672">Antarctica</option>
                                                        <option value="1268">Antigua and Barbuda</option>
                                                        <option value="54">Argentina</option>
                                                        <option value="374">Armenia</option>
                                                        <option value="297">Aruba</option>
                                                        <option value="61">Australia</option>
                                                        <option value="43">Austria</option>
                                                        <option value="994">Azerbaijan</option>
                                                        <option value="1242">Bahamas</option>
                                                        <option value="973">Bahrain</option>
                                                        <option value="880">Bangladesh</option>
                                                        <option value="1246">Barbados</option>
                                                        <option value="375">Belarus</option>
                                                        <option value="32">Belgium</option>
                                                        <option value="501">Belize</option>
                                                        <option value="229">Benin</option>
                                                        <option value="1441">Bermuda</option>
                                                        <option value="975">Bhutan</option>
                                                        <option value="591">Plurinational State of Bolivia</option>
                                                        <option value="387">Bosnia and Herzegovina</option>
                                                        <option value="267">Botswana</option>
                                                        <option value="55">Brazil</option>
                                                        <option value="246">British Indian Ocean Territory</option>
                                                        <option value="673">Brunei Darussalam</option>
                                                        <option value="359">Bulgaria</option>
                                                        <option value="226">Burkina Faso</option>
                                                        <option value="257">Burundi</option>
                                                        <option value="855">Cambodia</option>
                                                        <option value="237">Cameroon</option>
                                                        <option value="1">Canada</option>
                                                        <option value="238">Cape Verde</option>
                                                        <option value=" 345">Cayman Islands</option>
                                                        <option value="236">Central African Republic</option>
                                                        <option value="235">Chad</option>
                                                        <option value="56">Chile</option>
                                                        <option value="86">China</option>
                                                        <option value="61">Christmas Island</option>
                                                        <option value="61">Cocos (Keeling) Islands</option>
                                                        <option value="57">Colombia</option>
                                                        <option value="269">Comoros</option>
                                                        <option value="242">Congo</option>
                                                        <option value="243">The Democratic Republic of the Congo</option>
                                                        <option value="682">Cook Islands</option>
                                                        <option value="506">Costa Rica</option>
                                                        <option value="225">Cote d'Ivoire</option>
                                                        <option value="385">Croatia</option>
                                                        <option value="53">Cuba</option>
                                                        <option value="357">Cyprus</option>
                                                        <option value="420">Czech Republic</option>
                                                        <option value="45">Denmark</option>
                                                        <option value="253">Djibouti</option>
                                                        <option value="1767">Dominica</option>
                                                        <option value="1849">Dominican Republic</option>
                                                        <option value="593">Ecuador</option>
                                                        <option value="20">Egypt</option>
                                                        <option value="503">El Salvador</option>
                                                        <option value="240">Equatorial Guinea</option>
                                                        <option value="291">Eritrea</option>
                                                        <option value="372">Estonia</option>
                                                        <option value="251">Ethiopia</option>
                                                        <option value="500">Falkland Islands (Malvinas)</option>
                                                        <option value="298">Faroe Islands</option>
                                                        <option value="679">Fiji</option>
                                                        <option value="358">Finland</option>
                                                        <option value="33">France</option>
                                                        <option value="594">French Guiana</option>
                                                        <option value="689">French Polynesia</option>
                                                        <option value="241">Gabon</option>
                                                        <option value="220">Gambia</option>
                                                        <option value="995">Georgia</option>
                                                        <option value="49">Germany</option>
                                                        <option value="233">Ghana</option>
                                                        <option value="350">Gibraltar</option>
                                                        <option value="30">Greece</option>
                                                        <option value="299">Greenland</option>
                                                        <option value="1473">Grenada</option>
                                                        <option value="590">Guadeloupe</option>
                                                        <option value="1671">Guam</option>
                                                        <option value="502">Guatemala</option>
                                                        <option value="44">Guernsey</option>
                                                        <option value="224">Guinea</option>
                                                        <option value="245">Guinea-Bissau</option>
                                                        <option value="595">Guyana</option>
                                                        <option value="509">Haiti</option>
                                                        <option value="379">Holy See (Vatican City State)</option>
                                                        <option value="504">Honduras</option>
                                                        <option value="852">Hong Kong</option>
                                                        <option value="36">Hungary</option>
                                                        <option value="354">Iceland</option>
                                                        <option value="91" selected="">India</option>
                                                        <option value="62">Indonesia</option>
                                                        <option value="98">Iran, Islamic Republic of Persian Gulf</option>
                                                        <option value="964">Iraq</option>
                                                        <option value="353">Ireland</option>
                                                        <option value="44">Isle of Man</option>
                                                        <option value="972">Israel</option>
                                                        <option value="39">Italy</option>
                                                        <option value="1876">Jamaica</option>
                                                        <option value="81">Japan</option>
                                                        <option value="44">Jersey</option>
                                                        <option value="962">Jordan</option>
                                                        <option value="77">Kazakhstan</option>
                                                        <option value="254">Kenya</option>
                                                        <option value="686">Kiribati</option>
                                                        <option value="850">Democratic People's Republic of Korea</option>
                                                        <option value="82">Republic of South Korea</option>
                                                        <option value="965">Kuwait</option>
                                                        <option value="996">Kyrgyzstan</option>
                                                        <option value="856">Laos</option>
                                                        <option value="371">Latvia</option>
                                                        <option value="961">Lebanon</option>
                                                        <option value="266">Lesotho</option>
                                                        <option value="231">Liberia</option>
                                                        <option value="218">Libyan Arab Jamahiriya</option>
                                                        <option value="423">Liechtenstein</option>
                                                        <option value="370">Lithuania</option>
                                                        <option value="352">Luxembourg</option>
                                                        <option value="853">Macao</option>
                                                        <option value="389">Macedonia</option>
                                                        <option value="261">Madagascar</option>
                                                        <option value="265">Malawi</option>
                                                        <option value="60">Malaysia</option>
                                                        <option value="960">Maldives</option>
                                                        <option value="223">Mali</option>
                                                        <option value="356">Malta</option>
                                                        <option value="692">Marshall Islands</option>
                                                        <option value="596">Martinique</option>
                                                        <option value="222">Mauritania</option>
                                                        <option value="230">Mauritius</option>
                                                        <option value="262">Mayotte</option>
                                                        <option value="52">Mexico</option>
                                                        <option value="691">Federated States of Micronesia</option>
                                                        <option value="373">Moldova</option>
                                                        <option value="377">Monaco</option>
                                                        <option value="976">Mongolia</option>
                                                        <option value="382">Montenegro</option>
                                                        <option value="1664">Montserrat</option>
                                                        <option value="212">Morocco</option>
                                                        <option value="258">Mozambique</option>
                                                        <option value="95">Myanmar</option>
                                                        <option value="264">Namibia</option>
                                                        <option value="674">Nauru</option>
                                                        <option value="977">Nepal</option>
                                                        <option value="31">Netherlands</option>
                                                        <option value="599">Netherlands Antilles</option>
                                                        <option value="687">New Caledonia</option>
                                                        <option value="64">New Zealand</option>
                                                        <option value="505">Nicaragua</option>
                                                        <option value="227">Niger</option>
                                                        <option value="234">Nigeria</option>
                                                        <option value="683">Niue</option>
                                                        <option value="672">Norfolk Island</option>
                                                        <option value="1670">Northern Mariana Islands</option>
                                                        <option value="47">Norway</option>
                                                        <option value="968">Oman</option>
                                                        <option value="92">Pakistan</option>
                                                        <option value="680">Palau</option>
                                                        <option value="970">Palestinian Territory</option>
                                                        <option value="507">Panama</option>
                                                        <option value="675">Papua New Guinea</option>
                                                        <option value="595">Paraguay</option>
                                                        <option value="51">Peru</option>
                                                        <option value="63">Philippines</option>
                                                        <option value="872">Pitcairn</option>
                                                        <option value="48">Poland</option>
                                                        <option value="351">Portugal</option>
                                                        <option value="1939">Puerto Rico</option>
                                                        <option value="974">Qatar</option>
                                                        <option value="40">Romania</option>
                                                        <option value="7">Russia</option>
                                                        <option value="250">Rwanda</option>
                                                        <option value="262">Reunion</option>
                                                        <option value="590">Saint Barthelemy</option>
                                                        <option value="290">Saint Helena</option>
                                                        <option value="1869">Saint Kitts and Nevis</option>
                                                        <option value="1758">Saint Lucia</option>
                                                        <option value="590">Saint Martin</option>
                                                        <option value="508">Saint Pierre and Miquelon</option>
                                                        <option value="1784">Saint Vincent and the Grenadines</option>
                                                        <option value="685">Samoa</option>
                                                        <option value="378">San Marino</option>
                                                        <option value="239">Sao Tome and Principe</option>
                                                        <option value="966">Saudi Arabia</option>
                                                        <option value="221">Senegal</option>
                                                        <option value="381">Serbia</option>
                                                        <option value="248">Seychelles</option>
                                                        <option value="232">Sierra Leone</option>
                                                        <option value="65">Singapore</option>
                                                        <option value="421">Slovakia</option>
                                                        <option value="386">Slovenia</option>
                                                        <option value="677">Solomon Islands</option>
                                                        <option value="252">Somalia</option>
                                                        <option value="27">South Africa</option>
                                                        <option value="211">South Sudan</option>
                                                        <option value="500">South Georgia and the South Sandwich Islands</option>
                                                        <option value="34">Spain</option>
                                                        <option value="94">Sri Lanka</option>
                                                        <option value="249">Sudan</option>
                                                        <option value="597">Suricountry</option>
                                                        <option value="47">Svalbard and Jan Mayen</option>
                                                        <option value="268">Swaziland</option>
                                                        <option value="46">Sweden</option>
                                                        <option value="41">Switzerland</option>
                                                        <option value="963">Syrian Arab Republic</option>
                                                        <option value="886">Taiwan</option>
                                                        <option value="992">Tajikistan</option>
                                                        <option value="255">Tanzania</option>
                                                        <option value="66">Thailand</option>
                                                        <option value="670">Timor-Leste</option>
                                                        <option value="228">Togo</option>
                                                        <option value="690">Tokelau</option>
                                                        <option value="676">Tonga</option>
                                                        <option value="1868">Trinidad and Tobago</option>
                                                        <option value="216">Tunisia</option>
                                                        <option value="90">Turkey</option>
                                                        <option value="993">Turkmenistan</option>
                                                        <option value="1649">Turks and Caicos Islands</option>
                                                        <option value="688">Tuvalu</option>
                                                        <option value="256">Uganda</option>
                                                        <option value="380">Ukraine</option>
                                                        <option value="971">United Arab Emirates</option>
                                                        <option value="44">United Kingdom</option>
                                                        <option value="1">United States</option>
                                                        <option value="598">Uruguay</option>
                                                        <option value="998">Uzbekistan</option>
                                                        <option value="678">Vanuatu</option>
                                                        <option value="58">Venezuela</option>
                                                        <option value="84">Vietnam</option>
                                                        <option value="1284">British Virgin Islands</option>
                                                        <option value="1340">U.S. Virgin Islands</option>
                                                        <option value="681">Wallis and Futuna</option>
                                                        <option value="967">Yemen</option>
                                                        <option value="260">Zambia</option>
                                                        <option value="263">Zimbabwe</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="currency_name" className="form-label">
                                                    Currency <sup className="text--danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="currency_name"
                                                    id="currency_name"
                                                    className="form-control"
                                                    defaultValue="USD"
                                                    placeholder="Enter Currency Name"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="currency_symbol" className="form-label">
                                                    Currency Symbol <sup className="text--danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="currency_symbol"
                                                    id="currency_symbol"
                                                    className="form-control"
                                                    defaultValue="$"
                                                    placeholder="Enter Currency Symbol"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="registration_status" className="form-label">
                                                    User Registration <sup className="text--danger">*</sup>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="registration_status"
                                                    name="registration_status"
                                                    required
                                                    defaultValue="1"
                                                >
                                                    <option value="1">ON</option>
                                                    <option value="2">OFF</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="email_verification_status" className="form-label">
                                                    Email Verification Status <sup className="text--danger">*</sup>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="email_verification_status"
                                                    name="email_verification_status"
                                                    required
                                                    defaultValue="2"
                                                >
                                                    <option value="1">ON</option>
                                                    <option value="2">OFF</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="sign_up_bonus" className="form-label">
                                                    Signup Bonus Status <sup className="text--danger">*</sup>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="sign_up_bonus"
                                                    name="sign_up_bonus"
                                                    required
                                                    defaultValue="1"
                                                >
                                                    <option value="1">ON</option>
                                                    <option value="2">OFF</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="plan_id" className="form-label">
                                                    Signup Plan <sup className="text--danger">*</sup>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="plan_id"
                                                    name="plan_id"
                                                    required
                                                    defaultValue="1"
                                                >
                                                    <option value="3">Demo Plan One</option>
                                                    <option value="2">Demo Plan Two</option>
                                                    <option value="1">Free</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="sms_gateway" className="form-label">
                                                    SMS Gateway <sup className="text--danger">*</sup>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="sms_gateway"
                                                    name="sms_gateway"
                                                    required
                                                    defaultValue="2"
                                                >
                                                    <option value="1">Api Gateway</option>
                                                    <option value="2">Android Gateway</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="whatsapp_word_count" className="form-label">
                                                    WhatsApp Word Count <sup className="text--danger">*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        1 Credit
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="whatsapp_word_count"
                                                        name="whatsapp_word_count"
                                                        defaultValue="320"
                                                        className="form-control"
                                                        placeholder="Enter number of words"
                                                        aria-label="whatsapp_word_count"
                                                        aria-describedby="basic-addon1"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="sms_word_text_count" className="form-label">
                                                    SMS Word Count Plain Text <sup className="text--danger">*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        1 Credit
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="sms_word_text_count"
                                                        name="sms_word_text_count"
                                                        defaultValue="160"
                                                        className="form-control"
                                                        placeholder="Enter number of words"
                                                        aria-label="sms_word_text_count"
                                                        aria-describedby="basic-addon1"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="form-item">
                                                <label htmlFor="sms_word_unicode_count" className="form-label">
                                                    SMS Word Count Unicode <sup className="text--danger">*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        1 Credit
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="sms_word_unicode_count"
                                                        name="sms_word_unicode_count"
                                                        defaultValue="70"
                                                        className="form-control"
                                                        placeholder="Enter number of words"
                                                        aria-label="sms_word_unicode_count"
                                                        aria-describedby="basic-addon1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="i-btn primary--btn btn--lg">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Settings;
