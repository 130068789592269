import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { getcompanydata } from "../../services/apiService";
import AddMenuModal from './Component/AddMenuModal';
import EditPermissionsModal from './Component/EditPermissionsModal';
import EditMenuModal from './Component/EditMenuModal';
import { oneclick } from '../../oneclick';
import Sidebar from '../partials/sidebar';
import ImageComponent from '../../utils/ImageComponent';
import GroupAddModal from './Component/GroupAddModal';
import MenuAddModal from './Component/MenuAddModal';
import { OneKlicksFileSDK } from '../../OneKlicksFileSDK';
import SubMenuAddModal from './Component/SubMenuAddModal';
import EditGroupModal from './Component/EditGroupModal';
import GroupOrderModal from './Component/GroupOrderModal';
import database_id from '../../utils/databaseConfig';

const MenuGroups = () => {
    const [permissionsData, setPermissionsData] = useState([]);
    const [permissionsData1, setPermissionsData1] = useState([]);
    const [newpermissionsData, setNewPermissionsData] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [showAddGroupModal, setShowAddGroupModal] = useState(false);
    const [showEditGroupModal, setShowEditGroupModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});
    const [showAddManuModal, setShowAddMenuModal] = useState(false);
    const [showAddSubMenuModal, setShowAddSubMenuModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(50);
    const [searchTerm, setSearchTerm] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState({});
    const [existingMenuData, setexistingMenuData] = useState({});
    const [groups, setGroups] = useState([]); // State to hold groups
    const [menus, setMenu] = useState([]); // State to hold groups
    const navigate = useNavigate(); // Initialize navigate
    const [recommendedTogglePlan, setRecommendedTogglePlan] = useState(null); // For tracking the recommended toggle
    const [showRecommendedModal, setShowRecommendedModal] = useState(false); // For recommended confirmation modal

    // Function to fetch company and permissions data
    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            

            const tableSchema = database_id;
            const tableNamePermissions = "menudata";
            const tableNameMenulist = "menulist";
            const orderBy = "id DESC";

            const allMenu = await oneclick.read(tableSchema, tableNameMenulist, "", orderBy, "");
            setMenu(allMenu.data);
            const allPermissions111 = await oneclick.read(tableSchema, "permissions", "", orderBy, "");
            const allPermissions1 = allPermissions111.data;
            // Fetch permissions
            const allPermissions11 = await oneclick.read(tableSchema, tableNamePermissions, "", orderBy, "");
            const allPermissions = allPermissions11.data;
            console.log('allPermissions:', allPermissions);
            setNewPermissionsData(allPermissions1);

        } catch (error) {
            console.error('Error fetching data:', error); // Log the error
            alert('Error fetching permissions data.'); // Optional: Notify user
        }
    };

    const fetchGroups = async () => {
        const tableSchema = database_id;
        const tableName = "menugroup";
        const orderBy = "order_by ASC"; // Specify the ordering criteria
    
        try {
            const response = await oneclick.read(tableSchema, tableName, "", orderBy, ""); // Fetch the groups from your API
            if (response && response.data) {
                // Assuming response.data contains the groups array
                const sortedGroups = response.data.sort((a, b) => a.order_by - b.order_by);
                setGroups(sortedGroups);
                setPermissionsData(response.data); // Store fetched permissions
                setPermissionsData1(response.data); // Store fetched permissions
                console.log("permissionsData:", permissionsData);
            } else {
                console.error("No data found in response.");
            }
        } catch (error) {
            console.error("Error fetching groups:", error); // Handle any errors that occur during the fetch
        }
    };


    // Fetch data on component mount
    useEffect(() => {
        fetchGroups();
        fetchData();
    }, []);

    // Handle opening the edit modal
    const handleEditPermission = (permission) => {
        setexistingMenuData(permission);
        setShowEditModal(true);
        console.log('handleEditPermission:', selectedPermission);
    };

    // Handle opening the edit modal
    const handleEditGroup = (data) => {
        setSelectedGroup(data);
        setShowEditGroupModal(true);
        console.log('handleEditPermission:', selectedPermission);
    };

    // Close modal function and refresh data
    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setSelectedPermission(); // Reset selected permission
        fetchData(); // Re-fetch permissions data
    };

    // Close Add Permissions Modal and refresh data
    const handleCloseAddModal = () => {
        setShowAddModal(false);
        fetchData(); // Re-fetch permissions data
    };

    // Close Add Permissions Modal and refresh data  setShowEditGroupModal
    const handleCloseAddGroupModal = () => {
        setShowAddGroupModal(false);
        fetchData(); // Re-fetch permissions data
    };

    const handleCloseEditGroupModal = () => {
        setShowEditGroupModal(false);
        fetchData(); // Re-fetch permissions data
        
    };

    // Close Add Permissions Modal and refresh data
    const handleCloseAddMenuModal = () => {
        setShowAddMenuModal(false);
        fetchData(); // Re-fetch permissions data
    };

    // Close Add Permissions Modal and refresh data
    const handleCloseAddSubMenuModal = () => {
        setShowAddSubMenuModal(false);
        fetchData(); // Re-fetch permissions data
    };

    const handleGroupSubmit = async ({ groupName, grouptype, selectedPermissions, selectedPermissionsName }) => {
        console.log('New Group Added:', groupName);
        const tableSchema = database_id;
        const tableName = "menugroup";
        const data = {
            name: groupName,
            type: grouptype,
            permissions: JSON.stringify(selectedPermissions), // Convert selectedPermissions to JSON stringpermissions
            permissionsName: JSON.stringify(selectedPermissionsName), // Convert selectedPermissions to JSON stringpermissions 
        };

        try {
            // Call the create method to add the new group
            const update = await oneclick.create(tableSchema, tableName, data);

            if (update.success) {
                alert(update.message || 'Group added successfully.');
                handleCloseAddGroupModal(); // Close the modal on successful submit
                fetchGroups();
                // Optionally, you can call a function to refresh the list of groups or perform other actions here
            } else {
                console.error("Error updating permissions:", update.error);
                alert(update.error || 'Error updating permissions.');
            }
        } catch (error) {
            console.error('Error adding group:', error);
            alert('An error occurred while adding the group. Please try again.');
        }
    };

    const handleMenuSubmit = async ({ menuName, selectedGroup, imageFile, link, selectedPermissions }) => {
        console.log('New Menu Added:', menuName, 'Selected Group:', selectedGroup);

        const tableSchema = database_id;
        const tableName = "menulist";
        let imageid;
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        const ConditionsUsers = { "login_tockin": token };
        const emaployeData11 = await oneclick.read(tableSchema, "users", ConditionsUsers, "", "");
        const user_id = emaployeData11.data[0].id;

        if (imageFile) {
            const root = "images/icon"; // Replace with the appropriate root path
            const uploadResult = await OneKlicksFileSDK.uploadFile(user_id, root, imageFile);
            console.log('MENU ICON UPLOAD :', uploadResult);
            imageid = uploadResult.file.id; // Return the upload result
        }

        const datanew = {
            'name': menuName,
            'groupId': selectedGroup, // Assuming you have a groupId field in your menulist table
            'link': link, // Add link to the data
            'icon': imageid,
            'permissions': JSON.stringify(selectedPermissions) // Convert selectedPermissions to JSON string
        };

        console.log('MENU CREATE DATA :', datanew);

        try {
            // If your API supports FormData for file uploads, use it
            const update = await oneclick.create(tableSchema, tableName, datanew);
            if (update.success) {
                alert('Menu added successfully.');
                handleCloseAddMenuModal(); // Close the modal on successful submit
                fetchGroups();
            } else {
                console.error("Error adding menu:", update.error);
                alert(update.error || 'Error adding menu.');
            }
        } catch (error) {
            console.error("Unexpected error:", error);
            alert('An unexpected error occurred. Please try again.');
        }
    };

    const handleAddSubMenu = async ({ selectedGroup, selectedMenu, subMenuName, subMenuLink, selectedPermissions }) => {
        console.log('handleAddSubMenu:', selectedGroup, selectedMenu, subMenuName, subMenuLink, selectedPermissions);

        const tableSchema = database_id;
        const tableName = "submenu";
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        const ConditionsUsers = { "login_tockin": token };
        const emaployeData11 = await oneclick.read(tableSchema, "users", ConditionsUsers, "", "");
        const user_id = emaployeData11.data[0].id;


        const datanew = {
            'name': subMenuName,
            'groupId': selectedGroup, // Assuming you have a groupId field in your menulist table
            'menuId': selectedMenu, // Add link to the data
            'subMenuLink': subMenuLink,
            'permissions': JSON.stringify(selectedPermissions) // Convert selectedPermissions to JSON string
        };

        console.log('MENU CREATE DATA :', datanew);

        try {
            // If your API supports FormData for file uploads, use it
            const update = await oneclick.create(tableSchema, tableName, datanew);
            if (update.success) {
                alert('Menu added successfully.');
                handleCloseAddMenuModal(); // Close the modal on successful submit
                fetchGroups();
            } else {
                console.error("Error adding menu:", update.error);
                alert(update.error || 'Error adding menu.');
            }
        } catch (error) {
            console.error("Unexpected error:", error);
            alert('An unexpected error occurred. Please try again.');
        }
    };

    // Function to handle form submission
    const handleGroupUpdate = async (updatedGroup) => {
        console.log('Updated Group Data:', updatedGroup);

        const tableSchema = database_id;
        const tableName = "menugroup";
        const data = {
            name: updatedGroup.groupName,
            type: updatedGroup.grouptype,
            permissions: Array.isArray(updatedGroup.permissions) 
                ? updatedGroup.permissions.join(', ') // Only join if it's an array
                : updatedGroup.permissions, // If it's not an array, use it directly
            permissionsName: Array.isArray(updatedGroup.selectedPermissionsName) 
                ? updatedGroup.selectedPermissionsName.join(', ') // Only join if it's an array
                : updatedGroup.selectedPermissionsName // If it's not an array, use it directly
        }
        const Conditions = { "id": updatedGroup.groupId };

        try {
            // If your API supports FormData for file uploads, use it
            const update = await oneclick.update(tableSchema, tableName, data, Conditions);
            if (update.success) {
                alert('Group Update successfully.');
                handleCloseAddMenuModal(); // Close the modal on successful submit
                fetchGroups();
            } else {
                console.error("Error Group Update:", update.error);
                alert(update.error || 'Error Group Update.');
            }
        } catch (error) {
            console.error("Unexpected error:", error);
            alert('An unexpected error occurred. Please try again.');
        }
        
        // Here you would typically make an API call to update the group in the backend

        // Close the modal after submission
    };



    // Handle search
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        // Filter permissions based on permission name
        const filtered = permissionsData.filter(permission =>
            permission.name.toLowerCase().includes(value)
        );
        setPermissionsData(filtered);
        setCurrentPage(1); // Reset to first page when searching
    };

    // Calculate total pages
    const totalPages = Math.ceil(permissionsData.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

   // Sort permissionsData by order_by numerically
    const sortedPermissionsData = [...permissionsData].sort((a, b) => a.order_by - b.order_by);

    // Get current permissions for the page
    const currentPermissions = sortedPermissionsData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Handle permission deletion
    const handleDeletePermission = async (id) => {
        if (window.confirm("Are you sure you want to delete this permission?")) {
            const token = localStorage.getItem('token');
            const tableSchema = database_id;
            const tableName = "permissions";
            const condition = { 'id': id };

            try {
                const update = await oneclick.delete(tableSchema, tableName, condition);
                if (update.success) {
                    fetchData(); // Re-fetch permissions data
                } else {
                    alert('Error deleting permission: ' + update.message);
                }
            } catch (error) {
                console.error('Error deleting permission:', error);
                alert('Error deleting permission.');
            }
        }
    };

    const formatPermissions = (permissions) => {
        // Check if permissions is a stringified array (starts with '[' and ends with ']')
        if (typeof permissions === 'string' && permissions.startsWith('[') && permissions.endsWith(']')) {
            try {
                // Parse the string to an array
                const parsedPermissions = JSON.parse(permissions);
    
                // If it's an array, format it
                if (Array.isArray(parsedPermissions)) {
                    // Trim and join with commas
                    return parsedPermissions.map(item => item.trim()).join(', ');
                }
            } catch (error) {
                console.error('Error parsing permissions:', error);
                return permissions; // Return the original value if parsing fails
            }
        }
    
        return permissions; // Return as is if it's not a stringified array
    };
    

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        fetchGroups();
    };

    const updateGroupOrder = (newOrder) => {
        setGroups(newOrder);
        console.log('Updated group order:', newOrder);
    };

    const handleRecommendedChange = (plan) => {
        console.log('handleRecommendedChange:', plan);
        setRecommendedTogglePlan(plan); // Set the plan for the recommended change
        setShowRecommendedModal(true); // Show the confirmation modal
    };

    const handleRecommendedConfirm = async () => {
        try {

            const tableSchema = database_id;
            const tableName = "menugroup";
            // Update locally first for immediate UI feedback
            const updatedPlanedata = groups.map(plan =>
                plan.id === recommendedTogglePlan.id ? { ...plan, status: !plan.status } : plan
            );
            setPermissionsData(updatedPlanedata);
    
            // Prepare data for the API request
            const data = { status: recommendedTogglePlan.status == 1 ? 2 : 1 }; // 1 for true, 2 for false
            const condition = { id: recommendedTogglePlan.id };

    
            // Make the API call to update the database
            await oneclick.update(tableSchema, tableName, data, condition);
    
            // Close the modal after the API call is successful
            setShowRecommendedModal(false);
        } catch (error) {
            console.error("Failed to update recommendation status:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleCloseRecommendedModal = () => setShowRecommendedModal(false);

    return (
        <div className="dashboard_container">
            <div className="inner-content">
                <section>
                    <div className="card mb-4">
                        <h4 className="card-title" style={{ marginTop: '10px', marginLeft: '18px' }}>Sidebar Menu Group List</h4>
                        <div className="card-header d-flex justify-content-between align-items-center">

                            <div style={{ flex: 1, marginRight: '10px' }}>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Search with User, Email"
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </div>
                            <Button className="btn btn-primary" onClick={() => setShowAddGroupModal(true)}>Add New Group</Button>
                            <Button className="btn btn-primary" onClick={handleOpenModal}>Reorder Groups</Button>
                            {/* <Button className="btn btn-primary" onClick={() => setShowAddMenuModal(true)}>Add New Menu</Button>
                            <Button className="btn btn-primary" onClick={() => setShowAddSubMenuModal(true)}>Add New SubMenu</Button> */}
                        </div>

                        <div className="card-body px-0">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="responsive-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sl No.</th>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th>OrderBy</th>
                                                    <th>Permissions</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {permissionsData.length === 0 ? ( // Check if there are no permissions
                                                    <tr>
                                                        <td colSpan={5} style={{ textAlign: 'center' }}>
                                                            No data found
                                                        </td>
                                                    </tr>
                                                ) : currentPermissions.map((permission, index) => (
                                                    <tr key={permission.id}>
                                                        {/* Serial Number (Sl No.) */}
                                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                        
                                                        {/* Permission Name */}
                                                        <td>{permission.name}</td>

                                                        <td>{permission.type}</td>
                                                        
                                                        {/* Order By (order_by column) */}
                                                        <td>{permission.order_by?.toLocaleString()}</td>
                                                        
                                                        {/* Formatted Permissions */}
                                                        <td>{formatPermissions(permission.permissionsName)}</td>

                                                        <td>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={permission.status == 1} // checked if recommended_status is 1
                                                                    onChange={() => handleRecommendedChange(permission)}
                                                                />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </td>

                                                        
                                                        {/* Edit and Delete Buttons */}
                                                        <td style={{ gap: '10px', display: 'flex' }}>
                                                            <Button variant="primary" onClick={() => handleEditGroup(permission)}>Edit</Button>
                                                            <Button variant="danger" onClick={() => handleDeletePermission(permission.id)} className="btn-danger">Delete</Button>
                                                        </td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    {/* You can add additional content or pagination controls here */}
                                </div>
                            </div>


                            {/* Pagination Controls */}
                            <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Button
                                        key={index + 1}
                                        variant="outline-primary"
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{ margin: '0 5px' }}
                                        active={currentPage === index + 1}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Modals */}
            <AddMenuModal
                show={showAddModal}
                handleClose={handleCloseAddModal}
                permissionsdata={newpermissionsData}
            />
            <GroupAddModal
                show={showAddGroupModal}
                handleClose={handleCloseAddGroupModal}
                onSubmit={handleGroupSubmit}
                permissions={newpermissionsData} // Pass the permissions to the modal
            />
            <MenuAddModal
                show={showAddManuModal}
                handleClose={handleCloseAddMenuModal}
                onSubmit={handleMenuSubmit}
                groups={groups} // Pass the groups to the modal
                permissions={newpermissionsData} // Pass the permissions to the modal
            />
            <SubMenuAddModal
                show={showAddSubMenuModal}
                handleClose={handleCloseAddSubMenuModal}
                onSubmit={handleAddSubMenu}
                groups={groups}
                allMenus={menus}
                permissions={newpermissionsData}
            />
            <EditGroupModal
                show={showEditGroupModal}
                handleClose={handleCloseEditGroupModal}
                onSubmit={handleGroupUpdate}
                permissions={newpermissionsData} // Pass your permissions data here
                groupData={selectedGroup} // Pass the group data to edit
            />
            <GroupOrderModal
                show={isModalOpen}
                handleClose={handleCloseModal}
                groups={groups}
                updateGroupOrder={updateGroupOrder}
            />

            {/* Recommended Modal */}
            <Modal show={showRecommendedModal} onHide={handleCloseRecommendedModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Toggle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to {recommendedTogglePlan?.recommended ? 'turn off' : 'turn on'} the recommended status for this plan?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseRecommendedModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleRecommendedConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default MenuGroups;
