import React, { useEffect, useState } from 'react';
import { Button, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import AddStaffModal from "./Components/AddStaffModal"; // Adjust path as needed
import AddRoleModal from "./Components/AddRoleModal"; // Adjust path as needed
import { getcompanydata, getbranchdata } from "../../services/apiService";
import { oneclick } from '../../oneclick';
import ImageComponent from '../../utils/ImageComponent';
import UserInfo from './Components/UserInfo';
import database_id from '../../utils/databaseConfig';

const Staff = () => {
    const [branches, setBranches] = useState([]);
    const [branches11, setBranches11] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [showAddModal, setShowAddModal] = useState(false); // Modal visibility
    const [showUserModal, setShowUserModal] = useState(false); // Modal visibility
    const [showAddModal11, setShowAddModal11] = useState(false); // Modal visibility
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Items per page
    const [filteredBranches, setFilteredBranches] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); // Search input state

    const navigate = useNavigate(); // Initialize navigate

    const [selectedUser, setSelectedUser] = useState(null); // State to store selected user

    // Fetching company and branch data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const newcompanydata = await getcompanydata(token);
                setCompanyData(newcompanydata);
                console.log('COMPANY DATA :', newcompanydata);

                const tableSchema = database_id;
                const tableNameUsers = "users";
                const tableNameBranches = "branches";
                const tableNameEmployees = "employees"; // New table

                const conditions = { "company_id": newcompanydata.id };
                const orderBy = "id DESC";
                const allBranches11 = await oneclick.read(tableSchema, tableNameBranches, conditions, orderBy, "");
                const allBranches = allBranches11.data;
                setBranches11(allBranches);

                const allUsersData11 = await oneclick.read(tableSchema, tableNameUsers, conditions, orderBy, "");
                const allUsersData = allUsersData11.data;
                console.log('CHECK STAFF DATA:', allUsersData);

                const usersWithBranches = await Promise.all(allUsersData.map(async user => {
                    const employeeConditions = { "userid": user.id };
                    let employeeData = [];

                    try {
                        const employeeData11 = await oneclick.read(tableSchema, tableNameEmployees, employeeConditions, "", "");
                        employeeData = employeeData11.data;
                    } catch (error) {
                        console.error(`Error fetching employee data for user ${user.id}:`, error);
                    }

                    const employeeInfo = employeeData.length > 0 ? employeeData[0] : null;
                    console.log('employeeInfo:', employeeInfo);

                    if (user.branch_id) {
                        let branchData = [];
                        try {
                            const conditions1 = { "id": user.branch_id };
                            const  branchData11 = await oneclick.read(tableSchema, tableNameBranches, conditions1, "", "");
                            branchData = branchData11.data;
                        } catch (error) {
                            console.error(`Error fetching branch data for branch_id ${user.branch_id}:`, error);
                        }

                        const branchName = branchData.length > 0 ? branchData[0].name : 'Unknown Branch';
                        const branchCity = branchData.length > 0 ? branchData[0].city : 'Unknown City';

                        return {
                            ...user,
                            branch_name: branchName,
                            branch_city: branchCity,
                            employee_info: employeeInfo
                        };
                    } else {
                        return {
                            ...user,
                            branch_name: 'Unknown Branch',
                            branch_city: 'Unknown City',
                            employee_info: employeeInfo
                        };
                    }
                }));

                setBranches(usersWithBranches);
                setFilteredBranches(usersWithBranches);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Handle search input change
    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value);

        // Filter based on search term (user name or email)
        const filtered = branches.filter(branch =>
            branch.name.toLowerCase().includes(value.toLowerCase()) ||
            branch.email.toLowerCase().includes(value.toLowerCase()) ||
            branch.staff_id.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredBranches(filtered);
    };

    // Calculate total pages
    const totalPages = Math.ceil(filteredBranches.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };



    // Open AddBranchModal  
    const handleAddBranch = () => {
        setShowAddModal(true);
    };
    
    const handleUserModal = (branch,data1) => {
        
        if(data1){
            setSelectedUser({
                id: branch.id,
                picture: branch.employee_info.passport_photo,
                name: branch.employee_info.full_name,
                phone: branch.employee_info.phone_number,
                email: branch.employee_info.email,
                status: branch.staff_status,
                onStatusChange: (newStatus) => {
                    branch.staff_status = newStatus; // Update the status
                },
            });
            setShowUserModal(true);
        }else{
            alert('This is not found on Employee data')
        }

        
    };
    const handleCloseUserModal = () => {
        console.log('close the data');
        setShowUserModal(false);
    };

    const handleAddRole = () => {
        setShowAddModal11(true);
    };

    const handleCloseModal = () => {
        setShowAddModal(false);
    };

    const handleCloseModal11 = () => {
        setShowAddModal11(false);
    };

    const handleEditClick = (employee, companyid) => {
        navigate(`/hrm&management/staff/edit/${employee.id}/${companyid}`);
    };

    const currentBranches = filteredBranches.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <div className="dashboard_container">
            <div className="inner-content">
                <section>
                    <div className="card mb-4">
                        <h4 className="card-title" style={{marginTop: '10px',marginLeft: '18px'}}>Staff List</h4>
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div style={{ flex: 1, marginRight: '10px' }}>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Search with User, Email"
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <Button className="btn btn-primary" onClick={handleAddBranch}>Add New Staff</Button>
                                <Button className="btn btn-primary" onClick={handleAddRole}>Add New Role</Button>
                            </div>
                        </div>


                        <div className="card-body px-0">
                            <div className="responsive-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Image</th>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Branch Name</th>
                                            <th>City</th>
                                            <th>Checkin Time</th>
                                            <th>Check Out Time</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentBranches.map((branch, index) => (
                                            <tr key={branch.id}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>
                                                    {/* Check if passport_photo exists */}
                                                    {branch.employee_info && branch.employee_info.passport_photo ? (
                                                        <ImageComponent fileid={branch.employee_info.passport_photo} width={50} height={50} borderRadius={50} boxShadow={'1px 1px 1px black'}/>
                                                    ) : (
                                                        <span>No Image</span>
                                                    )}
                                                </td>
                                                <td>{branch.staff_id ? branch.staff_id : 'Not Add'}</td>
                                                <td>{branch.name}</td>
                                                <td>{branch.branch_name}</td>
                                                <td>{branch.branch_city}</td>
                                                <td>{branch.employee_info ? branch.employee_info.checkin_time : 'No Update'}</td>
                                                <td>{branch.employee_info ? branch.employee_info.checkout_time : 'No Update'}</td>
                                                <td>
                                                    <button  style={{
                                                        background: branch.staff_status === 'active' ? 'green' :
                                                            branch.staff_status === 'pending' ? 'orange' :
                                                            branch.staff_status === 'resign' ? 'blue' :
                                                            branch.staff_status === 'terment' ? 'red' :
                                                            branch.staff_status === 'black' ? 'black' : 'gray',
                                                        padding: '4px 7px',
                                                        borderRadius: '8px',
                                                        border: 'none',
                                                        color: 'white'
                                                    }}
                                                        className={`status-button ${branch.staff_status}`} // Dynamically add the class based on status
                                                        onClick={() => handleUserModal(branch,branch.employee_info ? branch.employee_info.checkin_time : '')}
                                                    >
                                                        {branch.staff_status}
                                                    </button>
                                                </td>
                                                <td style={{ gap: '10px', display: 'flex' }}>
                                                    <Button variant="primary" onClick={() => handleEditClick(branch, companyData.id)}>Edit</Button>
                                                    <Button variant="danger">Delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination Controls */}
                            <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Button
                                        key={index + 1}
                                        variant="outline-primary"
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{ margin: '0 5px' }}
                                        active={currentPage === index + 1}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

           
             {/* Add New UserInfo Modal */}
             {showUserModal && <UserInfo show={showUserModal} handleClose={handleCloseUserModal} userdata={selectedUser} companyData={companyData}/>}

             {/* Add New Staff Modal */}
            {showAddModal && <AddStaffModal show={showAddModal} handleClose={handleCloseModal} companyId={companyData.id} branches={branches11}/>}

            {/* Add New Role Modal */}
            {showAddModal11 && <AddRoleModal show={showAddModal11} handleClose={handleCloseModal11} companyId={companyData.id} branches={branches11}/>}
        </div>
    );
};

export default Staff;
