import React, { useEffect, useState } from 'react';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const AddEmailModal = ({ newEmail, setNewEmail, onAddEmail }) => {
  const [websitedata, setwebsitedata] = useState([]);
  const [userdata, setuserdata] = useState([]);

  // Fetch SEO data from API
  useEffect(() => {
    fetchSeoData();
    fetchUserData();
  }, []);

  const fetchSeoData = async () => {
    try {
      const tableSchema = database_id;
      const token = localStorage.getItem('token');
      const conditionsUsers = { "login_tockin": token };

      const employeeData11 = await oneclick.read(tableSchema, "users", conditionsUsers, "", "");
      const { id: user_id, company_id } = employeeData11.data[0] || {};

      if (!user_id) {
        alert('User not found. Please log in again.');
        return;
      }

      const conditionswebsites = {
        company_id: company_id,
        branch_id: '',  // Customize branch_id logic if needed
        status: 'Active'
      };

      const orderBy = "id DESC";

      // Fetch website data
      const datawebsite = await oneclick.read(tableSchema, "seowebsites", conditionswebsites, orderBy, "");
      console.log('Website Data Fetched:', datawebsite.data); // Log fetched data
      setwebsitedata(datawebsite.data);
    } catch (error) {
      console.error('Error fetching SEO data:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const tableSchema = database_id;
      const token = localStorage.getItem('token');
      const conditionsUsers = { "login_tockin": token };

      const employeeData11 = await oneclick.read(tableSchema, "users", conditionsUsers, "", "");
      const { id: user_id, company_id } = employeeData11.data[0] || {};

      if (!user_id) {
        alert('User not found. Please log in again.');
        return;
      }

      const conditionuserslist = {
        company_id: company_id,
      };

      const orderBy = "id DESC";

      // Fetch website data
      const datawebsite = await oneclick.read(tableSchema, "employees", conditionuserslist, orderBy, "");
      console.log('Users Data Fetched:', datawebsite.data); // Log fetched data
      setuserdata(datawebsite.data);
    } catch (error) {
      console.error('Error fetching SEO data:', error);
    }
  };

  const handleUsernameChange = (e) => {
    const selectedUsername = e.target.value;
    setNewEmail((prevEmail) => ({
      ...prevEmail,
      username: selectedUsername,
    }));
    console.log('Username updated:', selectedUsername); // Log username change
  };

  const handleDomainChange = (e) => {
    const selectedDomain = e.target.value;
    setNewEmail((prevEmail) => ({
      ...prevEmail,
      domain: selectedDomain,
    }));
    console.log('Domain updated:', selectedDomain); // Log domain change
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewEmail((prevEmail) => ({
      ...prevEmail,
      password, // Update the password in the state
    }));
    console.log('Password updated:', password); // Log password change
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', newEmail); // Log form data
    onAddEmail(newEmail); // Call the parent function to handle the email addition
  };

  return (
    <div className="modal fade" id="addNew" tabIndex="-1" aria-labelledby="addNewLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addNewLabel">Add New Email</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Username</label>
                <select
                  className="form-select"
                  id="username"
                  name="username"
                  value={newEmail.username}
                  onChange={handleUsernameChange}
                  required
                >
                  <option value="" disabled>Select User</option>
                  {userdata.length > 0 ? (
                    userdata.map((website) => {
                      // Split the name by spaces, lowercase, and join with dots
                      const formattedName = website.full_name
                        .toLowerCase() // Convert to lowercase
                        .split(' ')    // Split by spaces
                        .join('.');    // Join with dots
                      return (
                        <option key={website.id} value={formattedName}>
                          {website.full_name} {/* Display only the domain name */}
                        </option>
                      );
                    })
                  ) : (
                    <option disabled>No domains available</option>
                  )}
                  {/* Add more usernames as needed  userdata*/}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="domain" className="form-label">Domain</label>
                <select
                  className="form-select"
                  id="domain"
                  name="domain"
                  value={newEmail.domain}
                  onChange={handleDomainChange}
                  required
                >
                  <option value="" disabled>Select Domain</option>
                  {websitedata.length > 0 ? (
                    websitedata.map((website) => {
                      // Extract the hostname from the URL
                      const url = new URL(website.website);
                      let domainOnly = url.hostname; // This will give 'www.domain.com' or 'sub.domain.com'

                      // Remove 'www.' if it exists
                      if (domainOnly.startsWith('www.')) {
                        domainOnly = domainOnly.substring(4); // Remove the first 4 characters ('www.')
                      }
                      return (
                        <option key={website.id} value={domainOnly}>
                          {domainOnly} {/* Display only the domain name */}
                        </option>
                      );
                    })
                  ) : (
                    <option disabled>No domains available</option>
                  )}
                </select>
              </div>


              <div className="mb-3">
                <label className="form-label">Email</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={newEmail.username} // Only allow editing of the username
                    onChange={handleUsernameChange}
                  />
                  <span className="input-group-text">@{newEmail.domain || 'example.com'}</span>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={newEmail.password} // Ensure the password is in the state
                  onChange={handlePasswordChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="status" className="form-label">Status</label>
                <select
                  className="form-select"
                  name="status"
                  value={newEmail.status}
                  onChange={(e) => setNewEmail({ ...newEmail, status: e.target.value })}
                  required
                >
                  <option value="" disabled>Select Status</option>
                  <option value="Active">Active</option>
                  <option value="DeActive">DeActive</option>
                  <option value="Completed">Completed</option>
                  <option value="Ongoing">Ongoing</option>
                </select>
              </div>

              <button type="submit" className="btn btn-primary">Add Email</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmailModal;
