import React, { useState } from 'react';

const AdminTemplates = () => {
    const [templates, setTemplates] = useState([
        {
            id: 1,
            name: 'Demo Admin SMS Template',
            message: 'This template can be used when creating a new message for SMS or Whatsapp'
        }
    ]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [newTemplate, setNewTemplate] = useState({ name: '', message: '' });
    const [editTemplate, setEditTemplate] = useState(null);

    const handleCreateSubmit = (e) => {
        e.preventDefault();
        setTemplates([...templates, { ...newTemplate, id: templates.length + 1 }]);
        setShowCreateModal(false);
        setNewTemplate({ name: '', message: '' });
    };

    const handleEdit = (template) => {
        setEditTemplate(template);
    };

    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        setTemplates(templates.map(t => (t.id === editTemplate.id ? editTemplate : t)));
        setEditTemplate(null);
    };

    const handleDelete = (id) => {
        setTemplates(templates.filter(template => template.id !== id));
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="row gy-4">
                        {/* Sidebar */}
                        <div className="col-lg-auto">
                            <div className="vertical-tab card sticky-item">
                                <div className="nav flex-column nav-pills gap-2" aria-orientation="vertical">
                                    <a className="nav-link active" href="/admin/sms/templates/admin-templates">
                                        Admin
                                        <span><i className="las la-angle-right"></i></span>
                                    </a>
                                    <a className="nav-link" href="/admin/sms/templates/user-templates">
                                        User
                                        <span><i className="las la-angle-right"></i></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Template Table */}
                        <div className="col">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h6>Admin Templates</h6>
                                    <button 
                                        className="i-btn primary--btn btn--md text-white" 
                                        onClick={() => setShowCreateModal(true)}
                                    >
                                        <i className="las la-plus"></i> Create Template
                                    </button>
                                </div>
                                <div className="card-body px-0">
                                    <div className="responsive-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Message</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {templates.map((template) => (
                                                    <tr key={template.id}>
                                                        <td>{template.name}</td>
                                                        <td>{template.message}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                                                                <button 
                                                                    onClick={() => handleEdit(template)} 
                                                                    className="i-btn primary--btn btn--sm template text-white" 
                                                                    data-bs-toggle="modal" 
                                                                    data-bs-target="#updatebrand"
                                                                >
                                                                    <i className="las la-pen"></i>
                                                                </button>
                                                                <button 
                                                                    onClick={() => handleDelete(template.id)} 
                                                                    className="i-btn danger--btn btn--sm delete"
                                                                >
                                                                    <i className="las la-trash"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Create Template Modal */}
            {showCreateModal && (
                <div className="modal fade show d-block" id="createTemplate" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={handleCreateSubmit}>
                                <div className="modal-body">
                                    <div className="card">
                                        <div className="card-header bg--lite--violet">
                                            <div className="card-title text-center text--light">Add New Template</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label">Name <sup className="text--danger">*</sup></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="name" 
                                                    value={newTemplate.name} 
                                                    onChange={(e) => setNewTemplate({ ...newTemplate, name: e.target.value })} 
                                                    placeholder="Enter Name" 
                                                    required 
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="message" className="form-label">Message <sup className="text--danger">*</sup></label>
                                                <textarea 
                                                    rows="5" 
                                                    className="form-control" 
                                                    id="message" 
                                                    value={newTemplate.message} 
                                                    onChange={(e) => setNewTemplate({ ...newTemplate, message: e.target.value })} 
                                                    placeholder="Enter Message" 
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="i-btn primary--btn btn--md" onClick={() => setShowCreateModal(false)}>Cancel</button>
                                    <button type="submit" className="i-btn success--btn btn--md">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Update Template Modal */}
            {editTemplate && (
                <div className="modal fade show d-block" id="updatetemplate" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={handleUpdateSubmit}>
                                <div className="modal-body">
                                    <div className="card">
                                        <div className="card-header bg--lite--violet">
                                            <div className="card-title text-center text--light">Update Template</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label">Name <sup className="text--danger">*</sup></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="name" 
                                                    value={editTemplate.name} 
                                                    onChange={(e) => setEditTemplate({ ...editTemplate, name: e.target.value })} 
                                                    placeholder="Enter Name" 
                                                    required 
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="message" className="form-label">Message <sup className="text--danger">*</sup></label>
                                                <textarea 
                                                    rows="5" 
                                                    className="form-control" 
                                                    id="message" 
                                                    value={editTemplate.message} 
                                                    onChange={(e) => setEditTemplate({ ...editTemplate, message: e.target.value })} 
                                                    placeholder="Enter Message" 
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="i-btn primary--btn btn--md" onClick={() => setEditTemplate(null)}>Cancel</button>
                                    <button type="submit" className="i-btn success--btn btn--md">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminTemplates;
