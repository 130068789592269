import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import AddStaffModal from "./Components/AddStaffModal"; // Adjust path as needed
import AddRoleModal from "./Components/AddRoleModal"; // Adjust path as needed
import AddTrackerModal from './Components/AddStaffTracker';
import { getcompanydata } from "../../services/apiService";
import { oneclick } from '../../oneclick';
import ImageComponent from '../../utils/ImageComponent';
import EditTrackerModal from './Components/EditTrackerModal';
import DateRangeDropdown from '../../utils/DateRangeDropdown';
import database_id from '../../utils/databaseConfig';

const Tracker = () => {
    const [branches, setBranches] = useState([]);
    const [allusers, setAllUsers] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [showAddModal, setShowAddModal] = useState(false); // Modal visibility
    const [showAddModal11, setShowAddModal11] = useState(false); // Modal visibility
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Items per page
    const [filteredBranches, setFilteredBranches] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); // Search input state
    const [dateRange, setDateRange] = useState('');

    const navigate = useNavigate(); // Initialize navigate

    // Fetching company and branch data
    useEffect(() => {
        fetchData(dateRange);
    }, [dateRange]);

    const fetchData = async (dateRange) => {
        try {
            const token = localStorage.getItem('token');
            const newcompanydata = await getcompanydata(token);
            setCompanyData(newcompanydata);

            const tableNameUsers = "users";
            const tableNameTracker = "tracker";
            const tableSchema = database_id;
            const conditions = { "company_id": newcompanydata.id };

            // Fetch Tracker Data
            const trackerData11 = await oneclick.read(tableSchema, tableNameTracker, conditions, "", "");
            const trackerData = trackerData11.data;

            console.log('Tracker Data:', trackerData);

            const trackerUserIds = trackerData.map(tracker => tracker.user_id);

            // If tracker data is empty, fetch all users
            let userConditions = { ...conditions };
            if (trackerUserIds.length > 0) {
                userConditions = { ...conditions, "id": trackerUserIds };
            }

            const allUsersData11 = await oneclick.read(tableSchema, tableNameUsers, userConditions, "id DESC", "");
            const allUsersData = allUsersData11.data;

            console.log('Users Data:', allUsersData);

            // Combine user data with employee and branch data, including tracker data
            const usersWithAllData = await Promise.all(allUsersData.map(async user => {
                // Fetch Employee data for the user
                let employeeInfo = null;
                try {
                    const employeeConditions = { "userid": user.id, ...conditions };
                    const employeeData11 = await oneclick.read(tableSchema, "employees", employeeConditions, "", "");
                    employeeInfo = employeeData11.data.length > 0 ? employeeData11.data[0] : null;
                } catch (error) {
                    console.error(`Error fetching employee data for user ${user.id}:`, error);
                }

                // Fetch Branch Data (assuming 'branch_id' exists in 'employeeInfo')
                let branchInfo = null;
                if (employeeInfo) {
                    try {
                        const branchConditions = { "id": employeeInfo.branch_id, ...conditions };
                        const branchData11 = await oneclick.read(tableSchema, "branches", branchConditions, "", "");
                        branchInfo = branchData11.data.length > 0 ? branchData11.data[0] : null;
                    } catch (error) {
                        console.error(`Error fetching branch data for employee ${employeeInfo.id}:`, error);
                    }
                }

                // Fetch Employee Activity Data based on the selected date range
                let employeeActivity = [];
                if (employeeInfo) {
                    const activityConditions = { "employee_id": employeeInfo.id };
                    if (dateRange) {
                        const dateRangeConditions = getDateRangeConditions(dateRange);
                        Object.assign(activityConditions, dateRangeConditions);
                    }

                    try {
                        const employeeActivity11 = await oneclick.read(tableSchema, "employee_activity", activityConditions, "", "data_date DESC");
                        employeeActivity = employeeActivity11.data;
                    } catch (error) {
                        console.error(`Error fetching activity data for employee ${employeeInfo.id}:`, error);
                    }
                }

                return {
                    ...user,
                    tracker: trackerData.find(tracker => tracker.user_id === user.id),
                    employee: employeeInfo,
                    branch: branchInfo,
                    employee_activity: employeeActivity,
                };
            }));

            // Set branches and filtered branches
            setBranches(usersWithAllData);
            setFilteredBranches(usersWithAllData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Function to get date range conditions based on the selected range
    const getDateRangeConditions = (range) => {
        const today = new Date();
        let startDate, endDate;

        switch (range) {
            case 'today':
                startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                break;
            case 'yesterday':
                startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                break;
            case 'last_week':
                startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
                endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                break;
            case 'this_month':
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                break;
            case 'last_month':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), 1);
                break;
            case 'last_6_months':
                startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                break;
            case 'this_year':
                startDate = new Date(today.getFullYear(), 0, 1);
                endDate = new Date(today.getFullYear() + 1, 0, 1);
                break;
            default:
                return {};
        }

        return {
            data_date: { $gte: startDate.toISOString(), $lt: endDate.toISOString() }
        };
    };

    // Handle search input change
    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value);

        // Filter based on search term (user name or email)
        const filtered = branches.filter(branch =>
            branch.name.toLowerCase().includes(value.toLowerCase()) ||
            branch.email.toLowerCase().includes(value.toLowerCase()) ||
            branch.staff_id.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredBranches(filtered);
    };

    // Calculate total pages
    const totalPages = Math.ceil(filteredBranches.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    // Open AddBranchModal  
    const handleAddBranch = () => {
        setShowAddModal(true);
    };

    const handleAddRole = () => {
        setShowAddModal11(true);
    };

    const handleCloseModal = () => {
        setShowAddModal(false);
        fetchData();
    };

    const handleCloseModal11 = () => {
        setShowAddModal11(false);
        fetchData();
    };

    const [selecttrackerdata, setselecttrackerdata] = useState({});
    const [selecttrackeremployee, setselecttrackeremployee] = useState({});
    const [selecttrackername, setselecttrackername] = useState({});
    const [selecttrackercompanyid, setselecttrackercompanyid] = useState({});

    const handleEditClick = (tracker, employee, name, companyid) => {
        setselecttrackerdata(tracker);
        setselecttrackeremployee(employee);
        setselecttrackername(name);
        setselecttrackercompanyid(companyid);
        setShowAddModal(true);
    };

    const currentBranches = filteredBranches.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const timeAgo = (timestamp) => {
        if (!timestamp) return 'No Update';
        
        const currentTime = new Date();
        const timeDifference = Math.floor((currentTime - new Date(timestamp)) / 1000); // difference in seconds
    
        if (timeDifference < 60) return `${timeDifference} seconds ago`;
        if (timeDifference < 3600) return `${Math.floor(timeDifference / 60)} minutes ago`;
        if (timeDifference < 86400) return `${Math.floor(timeDifference / 3600)} hours ago`;
    
        return new Date(timestamp).toLocaleString(); // Fallback to full date
    };

    return (
        <div className="dashboard_container">
            <div className="inner-content">
                <section>
                    <div className="card mb-4">
                        <h4 className="card-title" style={{ marginTop: '10px', marginLeft: '18px' }}>Employee Tracking</h4>
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div style={{ flex: 1, marginRight: '10px' }}>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Search with User, Email"
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <DateRangeDropdown onDateRangeChange={setDateRange} />
                                <Button className="btn btn-primary" onClick={handleAddRole}>Add Tracker</Button>
                            </div>
                        </div>

                        <div className="card-body px-0">
                            <div className="responsive-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Image</th>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Checkin Time</th>
                                            <th>To Brack</th>
                                            <th>Work Hours</th>
                                            <th>Check Out Time</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentBranches.map((branch, index) => (
                                            <tr key={branch.id} style={{
                                                background: branch.employee_activity?.check_in ? '#00800042' :
                                                    branch.employee_activity?.check_out ? '#ff000029' :
                                                        branch.employee_activity?.brack ? '#80808073' :
                                                            branch.staff_status === 'black' ? 'black' : '#80808073',
                                            }}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>
                                                    {branch.employee && branch.employee.passport_photo ? (
                                                        <ImageComponent
                                                            fileid={branch.employee.passport_photo}
                                                            width={50}
                                                            height={50}
                                                            borderRadius={50}
                                                            boxShadow={'1px 1px 1px black'}
                                                        />
                                                    ) : (
                                                        <span>No Image</span>
                                                    )}
                                                </td>
                                                <td>{branch.staff_id || 'Not Added'}</td>
                                                <td>{branch.name || 'Unknown'}</td>
                                                <td>{branch.employee_activity ? branch.employee_activity.check_in : 'No Update'}</td>
                                                <td>{timeAgo(branch.employee_activity?.brack)}</td>
                                                <td>{timeAgo(branch.employee_activity?.check_in)}</td>
                                                <td>{branch.employee_activity ? branch.employee_activity.check_out : 'No Update'}</td>
                                                <td></td>

                                                <td style={{ gap: '10px', display: 'flex' }}>
                                                    <Button variant="primary" onClick={() => handleEditClick(branch, companyData.id)}>View</Button>
                                                    <Button variant="primary" onClick={() => handleEditClick(branch.branch_tracker, branch, branch.name, companyData.id)}>Edit</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination Controls */}
                            <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Button
                                        key={index + 1}
                                        variant="outline-primary"
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{ margin: '0 5px' }}
                                        active={currentPage === index + 1}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Add New Staff Modal */}
            {showAddModal && <EditTrackerModal show={showAddModal} handleClose={handleCloseModal} trackerData={selecttrackerdata} branches={selecttrackeremployee} username={selecttrackername} companyId={companyData.id} />}

            {/* Add New Role Modal */}
            {showAddModal11 && <AddTrackerModal show={showAddModal11} handleClose={handleCloseModal11} companyId={companyData.id} branches={allusers} />}
        </div>
    );
};

export default Tracker;
