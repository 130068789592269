import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for API calls
import { CircularProgress } from '@mui/material'; // Loading spinner from Material UI
import { Button, TextField, Card, CardHeader, CardContent, CardActions, Typography, Box } from '@mui/material'; // Material UI components

// DomainCard component to display individual domain information
const DomainCard = ({ domain }) => {
    return (
        <Card sx={styles.domainCard}>
            <Box sx={styles.cardHeader}>
                <Typography variant="h6">
                    <strong>{domain.split('.')[0]}</strong>
                    <span>.{domain.split('.')[1]}</span>
                </Typography>
                <Typography sx={styles.price}>₹649/yr</Typography>
            </Box>

            <CardActions sx={styles.actions}>
                <Button
                    variant="outlined"
                    color="secondary"
                    aria-label={`Add ${domain} to favorites`}
                    startIcon={<i className="fas fa-heart" />}
                    sx={styles.favoriteButton}
                >
                    Favorite
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    aria-label={`Add ${domain} to cart`}
                    startIcon={<i className="fas fa-cart-plus" />}
                >
                    Add to Cart
                </Button>
            </CardActions>
        </Card>
    );
};

// Main DomainByying component
function DomainByying() {
    const [domains, setDomains] = useState([]); // State to hold the domain listings
    const [searchQuery, setSearchQuery] = useState(''); // State to hold the current search query
    const [loading, setLoading] = useState(false); // State to handle loading
    const [showInfo, setShowInfo] = useState(true); // State to control the visibility of text and image

    // Function to fetch domain suggestions
    const fetchDomains = async (query) => {
        setLoading(true);
        const apiKey = 'gHzYe7CgCkrB_Mv9ef2ApE6N94x7n6zKSvP'; // Replace with your GoDaddy API key
        const apiSecret = '5bSBDM4Jjzkd24wDoDYREq'; // Replace with your GoDaddy API secret https://api.ote-godaddy.com/v1/domains/available?domain=ravindra.com&checkType=FAST&forTransfer=false

        try {
            const response = await axios.get(`https://api.godaddy.com/v1/domains/available?domain=${query}&checkType=FAST&forTransfer=false`, {
                headers: {
                    'Authorization': `sso-key ${apiKey}:${apiSecret}`
                }
            });

            const availableDomains = response.data; // Adjust based on the response structure
            setDomains([{
                domain: query,
                available: availableDomains.available
            }]); // Customize this according to your needs
            setShowInfo(false);
        } catch (error) {
            console.error("Error fetching domains", error);
        } finally {
            setLoading(false);
        }
    };

    // Effect to fetch domains on query change
    useEffect(() => {
        const tldRegex = /\.(com|net|org)$/i; // Regular expression to check for TLDs
        const baseQuery = searchQuery.replace(tldRegex, ''); // Remove the TLD if it exists

        if (baseQuery && searchQuery.length >= 3) { // Start searching after 3 characters
            fetchDomains(baseQuery);
        } else {
            setDomains([]); // Clear domains if the search is too short
            setShowInfo(true);
        }
    }, [searchQuery]); // Runs whenever searchQuery changes

    const handleSearchClick = () => {
        if (searchQuery.length >= 3) {
            const tldRegex = /\.(com|net|org)$/i;
            const baseQuery = searchQuery.replace(tldRegex, ''); // Remove TLD if exists
            fetchDomains(baseQuery);
        }
    };

    const handleReset = () => {
        setDomains([]); // Clear the domain listings
        setSearchQuery(''); // Reset the search input
        setShowInfo(true); // Show the info section again
    };

    return (
        <Box sx={styles.container}>
            <Card sx={styles.card}>
                <CardHeader title="Buy a Domain" />
                <CardContent>
                    <Box sx={styles.searchSection}>
                        <TextField
                            label="Search with Domain"
                            variant="outlined"
                            fullWidth
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Box sx={styles.actions}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearchClick}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Search'}
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleReset}
                                startIcon={<i className="fas fa-sync"></i>}
                            >
                                Reset
                            </Button>
                        </Box>
                    </Box>

                    {showInfo && (
                        <Box sx={styles.infoSection}>
                            <Typography variant="body1" gutterBottom>
                                Looking to establish your online presence? Buying a domain is the first step!
                                Whether you want a unique name for your business, blog, or personal project,
                                we have a variety of domains available for you. Use the search box above to
                                find the perfect domain name that fits your needs.
                            </Typography>
                            <img
                                src="/assets/file/images/url-domain-types.png"
                                alt="Domain Buying"
                                style={styles.image}
                            />
                        </Box>
                    )}
                </CardContent>

                <CardContent>
                    <Box>
                        {domains.length > 0 ? (
                            <Box sx={styles.domainList}>
                                {domains.map((domain, index) => (
                                    <DomainCard key={index} domain={domain.domain} />
                                ))}
                            </Box>
                        ) : (
                            <Typography variant="body2" color="error">
                                No domains found. Please search again.
                            </Typography>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

// Styles using Material-UI's system
const styles = {
    container: {
        padding: '20px',
        minHeight: '100vh',
        backgroundColor: '#f4f4f4',
    },
    card: {
        maxWidth: '600px',
        margin: 'auto',
    },
    searchSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginBottom: '20px',
    },
    infoSection: {
        textAlign: 'center',
        margin: '20px 0',
    },
    image: {
        width: '100%',
        maxWidth: '400px',
        borderRadius: '8px',
    },
    domainList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    domainCard: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    price: {
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#007bff',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
    favoriteButton: {
        marginRight: '10px',
    },
};

export default DomainByying;
