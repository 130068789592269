import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import apiUrl from '../utils/apiConfig';

const SocketDataRetriever = () => {
    const [userId, setUserId] = useState(''); // For user ID input
    const [urls, setUrls] = useState(['']); // Initialize with one empty URL field
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        // Connect to the Socket.IO server
        const newSocket = io(apiUrl); // Change to your server URL
        setSocket(newSocket);

        // Clean up the socket connection when the component unmounts
        return () => {
            newSocket.disconnect();
        };
    }, []);

    const handleInputChange = (event, index) => {
        const newUrls = [...urls];
        newUrls[index] = event.target.value;
        setUrls(newUrls);
    };

    const handleAddUrl = () => {
        setUrls([...urls, '']); // Add a new empty URL field
    };

    const handleSubmit = () => {
        if (socket) {
            urls.forEach((url) => {
                if (url) {
                    // Emit an event with the URL and user ID to the server
                    socket.emit('fetchData', { url, userId });

                    // Listen for the response from the server
                    socket.on('dataResponse', (data) => {
                        console.log(`Data received for ${userId}:`, data);
                    });
                }
            });
        }
    };

    return (
        <div>
            <h1>Socket.IO Data Retriever</h1>
            <input
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="Enter User ID"
            />
            {urls.map((url, index) => (
                <div key={index}>
                    <input
                        type="text"
                        value={url}
                        onChange={(event) => handleInputChange(event, index)}
                        placeholder="Enter URL"
                    />
                </div>
            ))}
            <button onClick={handleAddUrl}>Add URL</button>
            <button onClick={handleSubmit}>Fetch Data</button>
        </div>
    );
};

export default SocketDataRetriever;
