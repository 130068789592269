import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

const EditGroupModal = ({ show, handleClose, onSubmit, permissions, groupData }) => {
    const [groupName, setGroupName] = useState('');
    const [grouptype, setGroupType] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]); // State for selected permissions
    const [selectedPermissionsName, setSelectedPermissionsName] = useState([]); // State for selected permission names

    // Populate the form with existing data when the modal opens
    useEffect(() => {
        if (groupData) {
            setGroupName(groupData.name || ''); // Set the initial group name
            setGroupType(groupData.type || '');
            const initialSelectedPermissions = groupData.permissions || []; // Get permissions from groupData
            setSelectedPermissions(initialSelectedPermissions); // Set the initial selected permissions
            const initialSelectedPermissionNames = groupData.permissionsName || [];
            setSelectedPermissionsName(initialSelectedPermissionNames);
        }
    }, [groupData]);

    const handlePermissionTypesChange = (selectedOptions) => {
        const selectedNames = selectedOptions.map(option => option.label); // Get the names of each permission
        const selectedIds = selectedOptions.map(option => option.value); // Get the IDs of selected permissions

        setSelectedPermissions(selectedIds);
        setSelectedPermissionsName(selectedNames);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (groupName.trim() === '') {
            alert('Group name is required!');
            return;
        }

        // Prepare data to be submitted
        const submittedData = {
            groupId: groupData.id, // Add group ID for updating
            groupName,
            grouptype,
            permissions: selectedPermissions,
            selectedPermissionsName
        };

        // Call the onSubmit function passed from the parent
        onSubmit(submittedData); // Pass the updated group data
        handleClose(); // Close the modal
    };

    // Create options for the select dropdown
    const permissionOptions = permissions.flatMap(data =>
        JSON.parse(data.permissions).map(subPermission => ({
            value: subPermission.id, // Make sure to get the correct ID here
            label: `${data.name} - ${subPermission.subName}`,
            permission: subPermission.permissions
        }))
    );

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formGroupName">
                        <Form.Label>Group Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter group name"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="permissionsName">
                        <Form.Label>Group Type</Form.Label>
                        <Form.Select
                            name="permissionsNametype"
                            value={grouptype}
                            onChange={(e) => setGroupType(e.target.value)} // Corrected to set groupType
                            required
                        >
                            <option value="">Select Permission</option> {/* Default empty option */}
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="formSelectPermissions">
                        <Form.Label>Select Permissions</Form.Label>
                        <Select
                            options={permissionOptions}
                            isMulti
                            onChange={handlePermissionTypesChange}
                            value={permissionOptions.filter(option => selectedPermissions.includes(option.value))} // Preselect existing permissions
                            placeholder="Select Permission Types"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Update Group
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditGroupModal;
