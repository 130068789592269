import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

const SubMenuEditModal = ({ 
    show, 
    handleClose, 
    onSubmit, 
    groups, 
    allMenus = [],  // Set default to empty array if undefined
    permissions, 
    submenuData // Pass existing submenu data if editing
}) => {
    console.log('SubMenuEditModal allMenus:', allMenus);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [filteredMenus, setFilteredMenus] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [subMenuName, setSubMenuName] = useState('');
    const [subMenuLink, setSubMenuLink] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedPermissionsName, setSelectedPermissionsName] = useState([]);

    // Pre-populate fields with submenuData if editing
    useEffect(() => {
        if (submenuData) {
            const { group, menu } = submenuData;
            setSelectedGroup(group ? { value: group.id, label: group.name } : null);
            setSelectedMenu(menu ? { value: menu.id, label: menu.name } : null);
            setSubMenuName(submenuData.name || '');
            setSubMenuLink(submenuData.subMenuLink || '');
            const initialSelectedPermissions = submenuData.permissions || [];
            setSelectedPermissions(initialSelectedPermissions);
            const initialSelectedPermissionNames = submenuData.permissionsName || [];
            setSelectedPermissionsName(initialSelectedPermissionNames);
        }
    }, [submenuData]);

    // Filter menus based on selected group
    useEffect(() => {
        if (selectedGroup) {
            console.log('SubMenuEditModal selectedGroup:', selectedGroup.value);
            const menusForGroup = (allMenus || []).filter(menu => menu.groupId == selectedGroup.value);
            console.log('SubMenuEditModal menusForGroup:', menusForGroup);
            setFilteredMenus(menusForGroup);
        } else {
            setFilteredMenus([]);
        }
    }, [selectedGroup, allMenus]);

    // Generate permission options for select dropdown
    const permissionOptions = permissions.flatMap(data =>
        JSON.parse(data.permissions).map(subPermission => ({
            value: subPermission.subName,
            label: `${data.name} - ${subPermission.subName}`,
            permission: subPermission.permissions
        }))
    );

    // Handle permission changes
    const handlePermissionTypesChange = (selectedOptions) => {
        const selectedNames = selectedOptions.map(option => option.label);
        const selectedIds = selectedOptions.flatMap(option => 
            Array.isArray(option.permission) ? option.permission.map(perm => perm.id) : []
        );
        setSelectedPermissions(selectedIds);
        setSelectedPermissionsName(selectedNames);
    };

    // Form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedGroup || !selectedMenu || subMenuName.trim() === '' || subMenuLink.trim() === '' || selectedPermissions.length === 0) {
            alert('All fields are required!');
            return;
        }

        const dataToSubmit = {
            selectedmenuid: submenuData.id,
            selectedGroup: selectedGroup.value,
            selectedMenu: selectedMenu.value,
            subMenuName,
            subMenuLink,
            selectedPermissions,
            selectedPermissionsName
        };

        if (submenuData && submenuData.id) {
            dataToSubmit.id = submenuData.id; // Include the ID if editing
        }

        onSubmit(dataToSubmit);
        handleClose();
    };

    const groupOptions = groups.map(group => ({
        value: group.id,
        label: group.name
    }));

    const menuOptions = filteredMenus.map(menu => ({
        value: menu.id,
        label: menu.name
    }));

    // For showing default selected permission names in the dropdown
    const defaultPermissionValues = permissionOptions.filter(option =>
        selectedPermissionsName.includes(option.label)
    );

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{submenuData ? 'Edit SubMenu' : 'Add New SubMenu'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formSelectGroup">
                        <Form.Label>Select Group</Form.Label>
                        <Select
                            options={groupOptions}
                            onChange={setSelectedGroup}
                            value={selectedGroup}
                            placeholder="Select a group"
                            isClearable
                        />
                    </Form.Group>
                    <Form.Group controlId="formSelectMenu">
                        <Form.Label>Select Menu</Form.Label>
                        <Select
                            options={menuOptions}
                            onChange={setSelectedMenu}
                            value={selectedMenu}
                            placeholder="Select a menu"
                            isClearable
                            isDisabled={!selectedGroup}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSubMenuName">
                        <Form.Label>SubMenu Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter sub menu name"
                            value={subMenuName}
                            onChange={(e) => setSubMenuName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formSubMenuLink">
                        <Form.Label>SubMenu Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter sub menu link"
                            value={subMenuLink}
                            onChange={(e) => setSubMenuLink(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formSelectPermissions">
                        <Form.Label>Select Permissions</Form.Label>
                        <Select
                            options={permissionOptions}
                            isMulti
                            onChange={handlePermissionTypesChange}
                            value={defaultPermissionValues} // Show default selected permissions
                            placeholder="Select Permission Types"
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        {submenuData ? 'Save Changes' : 'Add SubMenu'}
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SubMenuEditModal;
