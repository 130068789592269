import React, { useEffect, useState } from 'react';
import { HeroSectionContent, HeroSectionUsers, HeroSectionsocialIconsContent, getImageBaseUrl } from '../../../services/apiService';
import './HeroSection.css';
import { getColors } from '../../../config/colorConfig';
import '../../../assets/theme/global/css/line-awesome.min.css';
import '../../../assets/theme/global/css/font_bootstrap-icons.css';
import '../../../assets/theme/frontend/css/all.min.css';
import '../../../assets/theme/frontend/css/default.css';
import '../../../assets/theme/frontend/css/style.css';
import '../../../assets/theme/frontend/css/media.css';
import BannerIcon from './SmsIcon/BannerIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Modal from './VideoModel'; // Adjust the import path as necessary

const defaultImage = require('../../../assets/file/images/default.jpg');

const HeroSection = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const [featureContent, setFeatureContent] = useState([]);
    const [socialIconsContent, setsocialIconsContent] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl



    useEffect(() => {
        setImageBaseUrl(getImageBaseUrl());
        const loadData = async () => {
            try {
                const content = await HeroSectionContent();
                const users = await HeroSectionUsers();
                const socialIconsnew = await HeroSectionsocialIconsContent();

                setFeatureContent(content);
                setUsers(users);
                setsocialIconsContent(socialIconsnew);
                console.log('Herosection content data:', content);
            } catch (error) {
                console.error('Herosection Failed to load data:', error);
                setError('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const [firstContent] = featureContent;
    const { section_value: { heading = '', sub_heading = '', btn_name = '', btn_url = '', video_btn_name = '', video_url = '', banner_image = '', background_image = '', description = '' } = {} } = firstContent || {};



    return (
        <div className="banner" id="home">
            <div className="container-fluid fluid-wrapper">
                <div className="row gx-5 gy-xl-0 gy-5 align-items-center">
                    <div className="col-xl-7">
                        <div className="banner-left">
                            <h3>{sub_heading}</h3>
                            <h1 >
                                {heading}
                            </h1>
                            <p>{description}</p>
                            <div className="d-flex align-items-center flex-wrap gap-xl-5 gap-4 banner-action">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={btn_url}
                                    className="ig-btn btn--lg btn--primary btn--capsule banner-btn"
                                >
                                    {btn_name}
                                </a>
                                <a
                                    href="#"
                                    onClick={openModal}
                                    data-dimbox-ratio="16x9"
                                    className="d-flex align-items-center gap-3 banner-action-item banner-btn"
                                >
                                    <span className="video-pay">
                                        <i className="fa-solid fa-play"></i>
                                    </span>
                                    {video_btn_name}
                                </a>
                                <Modal show={isModalOpen} onClose={closeModal} videoUrl={video_url} />
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5">
                        <div className="banner-right">
                            <div className="banner_img">
                                <img src={`${imageBaseUrl}/assets/file/images/frontend/${banner_image}`} alt="Background" />
                                <div className="banner-card banner-right-card">
                                    <div className="avatar-group">
                                        {users.slice(0, 2).map((user, index) => (
                                            <div className="avatar-group-item" key={index}>
                                                <img
                                                    src={user.image ? `${imageBaseUrl}/assets/file/images/user/profile/${user.image}` : defaultImage}
                                                    alt={user.name || 'User Avatar'}
                                                    className="w-100 h-100"
                                                />
                                            </div>
                                        ))}
                                        <div className="avatar-group-item">
                                            <span>{users.length}+ </span>
                                        </div>
                                    </div>
                                    <p style={{ marginBottom: '0px' }}>100+ API's</p>
                                </div>
                                <div className="banner-right-card-two">
                                    <img
                                        src="https://i.ibb.co/MGmsssB/Screenshot-1.png"
                                        alt="Screenshot-1"
                                    />
                                </div>
                                <div className="banner-icon email-icon">
                                    <img src="https://i.ibb.co/8XJJRcx/message.png" alt="message" />
                                </div>
                                <BannerIcon />
                                <div className="banner-icon custom-icon">
                                    <img src={`${imageBaseUrl}/assets/file/images/frontend/8686102.png`} alt="custom icon" />
                                </div>
                                <div className="banner-icon custom-icon" style={{ bottom: '50%', right: '-28%', width: '57px', position: 'absolute', }}>
                                    <img src={`${imageBaseUrl}/assets/file/images/frontend/18-187491_api-transparent-logo-hd-png-download-removebg-preview.png`} alt="custom icon" />
                                </div>
                                <div className="banner-icon" style={{ bottom: '-2%', right: '56%', width: '57px', }}>
                                    <img src={`${imageBaseUrl}/assets/file/images/frontend/files-logo-27812AB161-seeklogo.com.png`} alt="Files Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="gradient-bg">
                <img src={`${imageBaseUrl}/assets/file/images/frontend/${background_image}`} alt="Background" />
            </div>

            <div className="social-media">
                {socialIconsContent.map((icon, index) => (
                    <a key={index} target="_blank" rel="noopener noreferrer" href={icon.section_value.url} className="social-media-item">
                        {/* <FontAwesomeIcon icon={icon.section_value.social_icon} /> */}
                        <span className="" dangerouslySetInnerHTML={{ __html: icon.section_value.social_icon }} />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default HeroSection;
