import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useParams, Link } from 'react-router-dom';
import sectionData from './frontend_section.json';
import { oneclick } from "../../oneclick";
import database_id from "../../utils/databaseConfig";
import apiUrl from "../../utils/apiConfig";
import { OneKlicksFileSDK } from "../../OneKlicksFileSDK";
import { getuserdata } from "../../services/apiService";
import { a } from "react-spring";
import ImageComponent from "../../utils/ImageComponent";
import useImageUrlBuild from "../../utils/ImageUrlBuild";
import { getImageBaseUrl, encodeBase64 } from "../../services/apiService";
import ElementModel from "./ElementModel";

function AboutSection() {
  const [deleteId, setDeleteId] = useState(null);
  const location = useLocation();
  const { section_name } = useParams();
  const [sectionValues, setSectionValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formfildes, setformfildes] = useState({});
  const [fixedContent, setFixedContent] = useState({});
  const [element_content, setelement_content] = useState({});
  const [elementContents, setElementContents] = useState({});
  const [formData, setFormData] = useState({});
  const [formValue, setFormValue] = useState({});
  const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl
  const [showElementModel, setShowElementModel] = useState(false);

  console.log('get_section_name', location, section_name);

  const handleDeleteClick = async (id) => {
    const tableSchema = database_id;
    const tableName = 'frontend_sections';
    const condition = { id: id };
    const deletedata = await oneclick.delete(tableSchema, tableName, condition);
    if (deletedata.success) {
      console.log('Data deleted successfully:', deletedata);
      alert('Data deleted successfully');
      fetchData();
    } else {
      console.error('Error deleting data:', deletedata);
      alert('Error deleting data :' + deletedata.message);
    }
  };


  // Handle form input changes dynamically
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFixedContent((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const tableSchema = database_id;
      const tableName = 'frontend_sections';
      const conditions = { section_key: `${section_name}.fixed_content` };
      const conditionselement = { section_key: `${section_name}.element_content` };
      const orderBy = "id ASC";
      const search = {};
      const pagination = { page: 1, limit: 100 };
      const margedata = [];

      const responce = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
      const responceelements = await oneclick.read(tableSchema, tableName, conditionselement, orderBy, search, pagination, margedata);

      if (responce.success) {
        console.log('responce fetchData', responce.data[0]);
        const updatedata = responce.data[0].section_value;
        setFixedContent(JSON.parse(updatedata));
        setFormValue(JSON.parse(updatedata));
      }

      if (responceelements.success) {
        setElementContents(responceelements.data);
      }

      const filteredSection = Object.entries(sectionData).filter(([key]) => key === section_name);
      const section = filteredSection[0][1];
      console.log('section datanew', section);

      // Set the form fields (fixed_content) dynamically
      setformfildes(section.fixed_content);

      // Set the section values for later use or display
      setSectionValues(section);

      // Initialize form data state for fixed_content fields
      setFormData(section.fixed_content);
      setelement_content(section.element_content);
      console.log('setelement_content', element_content);

      console.log('sectionValues', section.fixed_content);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setImageBaseUrl(getImageBaseUrl());

    fetchData();
    console.log('sectionValues', sectionValues);
  }, [section_name]);

  // Dynamically render form fields for fixed_content
  // Dynamically render form fields for fixed_content
  const renderFormFields = () => {
    console.log('FIXED CONTENT FIELDS:', formData);
    let fomedatavalues = fixedContent || {};  // Default to empty object



    console.log('fomedatavalues:', fomedatavalues);
    console.log('Type of fomedatavalues:', typeof fomedatavalues);
    console.log('Keys in fomedatavalues:', Object.keys(fomedatavalues));

    return Object.keys(formfildes).map((fieldName) => {
      console.log('fieldName:', fieldName);

      const valueFromData = fomedatavalues[fieldName] || '';  // Get the value or fallback to empty string
      console.log('valueFromData for ' + fieldName + ' :', valueFromData);

      const fieldType = formfildes[fieldName];  // Assuming formfildes contains field types

      return (
        fieldName === "images" ? (
          <>
            {/* If fieldName is "images", render nested images (about_image, about_breadcrumb_image, etc.) */}
            {formData.images ? (
              Object.keys(formData.images).map((imageKey, index) => {
                const imageValue = fomedatavalues[imageKey] || "";
                const imagesize = formData.images[imageKey].size || "1600x1000"; // Default size
                return (
                  <div key={index} className="col-md-6">
                    <div className="image-field">
                      <label htmlFor={`image_${index}`}>{imageKey.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}</label>
                      <input
                        type="file"
                        className="form-control"
                        id={imageKey}
                        name={imageKey}
                        value={''}
                        onChange={handlefileChange}
                      />
                      <p style={{ fontSize: '12px' }}>
                        {`File formats supported: jpeg, jpg, png. The image will be resized to ${imagesize} pixels.`}
                      </p>
                      <ImageComponent fileid={imageValue} width={250} height={200} borderRadius={0} boxShadow="" color="" imagesize={`${imagesize}`} />
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No images available</p>
            )}
          </>
        ) : (
          <div key={fieldName} className="col-md-6">
            <label htmlFor={fieldName}>{fieldName.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}</label>
            <input
              type={fieldType === "text" ? "text" : fieldType}
              className="form-control"
              id={fieldName}
              name={fieldName}
              value={valueFromData}
              onChange={handleInputChange}
            />
          </div>
        )
      );
    });
  };




  if (loading) {
    return <p>Loading...</p>;
  }

  const handleupdate = async () => {
    if (typeof fixedContent === 'object') {
      const jsonString = JSON.stringify(fixedContent, null, 2); // Convert object to string with formatting
      console.log('Stringified JSON:', jsonString);
      const tableSchema = database_id;
      const tableName = 'frontend_sections';
      const data = { section_value: jsonString };
      const condition = { section_key: `${section_name}.fixed_content` };
      const updatedata = await oneclick.update(tableSchema, tableName, data, condition);
      if (updatedata.success) {
        console.log('Data updated successfully:', updatedata);
        alert('Data updated successfully');
      } else {
        console.error('Error updating data:', updatedata);
        alert('Error updating data :' + updatedata.message);
      }
    } else {
      console.log('fixedContent is already a string or not an object:', fixedContent);
    }
  };

  const handlefileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];
    const reader = new FileReader();
    const userId = await getuserdata('id');
    const root = 'frontend/section';
    const urllink = '';
    const upload = await OneKlicksFileSDK.uploadFile(userId, root, urllink, file);

    if (upload) {
      console.log('File uploaded successfully:', upload.file.id);
    } else {
      console.error('Error uploading file:', upload);
    }

    reader.onloadend = () => {
      setFixedContent((prevData) => ({
        ...prevData,
        [name]: upload.file.id,
      }));
    };

    reader.readAsDataURL(file);
  };



  return (
    <div className="dashboard_container">
      <section>
        <div className="card mb-3">
          <div className="card-header">
            <h4 className="card-title">{sectionValues.name}</h4>
          </div>
          <div className="card-body">
            <div class="form-wrapper">

              <input type="hidden" name="content_type" value="fixed_content" />
              <div class="row g-4 mb-4">
                {renderFormFields()}
              </div>
              <div class="row g-4" style={{ display: 'flex', flexDirection: 'row-reverse', paddingRight: '12px' }}>
                <button type="submit" class="i-btn primary--btn btn--md text-white" onClick={() => handleupdate()}>Update</button>
              </div>

            </div>
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-header">
            <h4 className="card-title">Element Contents</h4>
            <Link
              to={`/frontend_sections/elementblade/${encodeBase64(sectionValues.name)}/${encodeBase64(JSON.stringify(element_content))}/${section_name}/${encodeBase64('nodata')}/${encodeBase64('nodata')}`}
              className="i-btn primary--btn btn--md text-white"
            >
              <FontAwesomeIcon icon={faPlus} /> Add New
            </Link>
          </div>

          <div className="card-body px-0">
            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <>
                      {console.log('element_content show', element_content)}
                      {Object.keys(element_content).map((key, index) => {
                        // Check if the key is 'images' and handle its nested structure
                        if (key === 'images' && element_content[key]) {
                          return Object.keys(element_content[key]).map((imageKey, idx) => (
                            <th key={idx}>
                              {imageKey.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                            </th>
                          ));
                        } else {
                          // Handle other keys normally
                          return (
                            <th key={index}>
                              {key
                                .replace(/_/g, " ")
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                            </th>
                          );
                        }
                      })}

                    </>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {elementContents.length > 0 ? (
                    elementContents.map((element, index) => {
                      const elementData = JSON.parse(element.section_value);

                      return (
                        <tr key={index}>
                          <td data-label="No.">{index + 1}</td>

                          {/* Generate columns dynamically */}
                          {Object.keys(element_content).map((key, idx) => {
                            if (key === "images") {
                              const images = Object.keys(element_content.images).map((imageKey) => {
                                const imageValue = elementData[imageKey] || "";
                                const imageSize = element_content.images[imageKey]?.size || "1600x1000";
                                return { imageKey, imageValue, imageSize };
                              });

                              return (
                                <td key={idx} data-label="Images">
                                  {images.map(({ imageKey, imageValue, imageSize }) =>
                                    /\.(svg|png|jpe?g|gif|webp)$/i.test(imageValue) ? (
                                      <img
                                        key={imageKey}
                                        src={`${imageBaseUrl}/assets/file/images/frontend/${imageValue}`}
                                        alt={imageValue}
                                        style={{ maxWidth: "100px" }}
                                      />
                                    ) : (
                                      <ImageComponent
                                        key={imageKey}
                                        fileid={imageValue}
                                        width={100}
                                        height={100}
                                        borderRadius={50}
                                        boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                        color=""
                                        imagesize={imageSize}
                                      />
                                    )
                                  )}
                                </td>
                              );
                            }

                            return (
                              <td key={idx} data-label={key}>
                                {key === "icon" ? (
                                  <span dangerouslySetInnerHTML={{ __html: elementData[key] }} />
                                ) : (
                                  elementData[key]
                                )}
                              </td>
                            );
                          })}

                          {/* Action Buttons */}
                          <td data-label="Action">
                            <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                              <Link
                                to={`/frontend_sections/elementblade/${encodeBase64(sectionValues.name)}/${encodeBase64(JSON.stringify(element_content))}/${section_name}/${encodeBase64(JSON.stringify(elementData))}/${element.id}`}
                                className="i-btn primary--btn btn--sm"
                              >
                                <i className="la la-pencil-alt"></i>
                              </Link>
                              <button
                                className="i-btn danger--btn btn--sm remove-element"
                                onClick={() => handleDeleteClick(element.id)}
                              >
                                <i className="las la-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={Object.keys(element_content).length + 2}>No data available</td>
                    </tr>
                  )}
                </tbody>

              </table>

            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default AboutSection;
