import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTachometerAlt, FaCloud } from 'react-icons/fa';
import { Collapse } from 'react-bootstrap';
import { getImageBaseUrl, general_settings, getuser } from '../../../services/apiService';
import { oneclick } from '../../../oneclick';
import DynamicSidebar from '../../../utils/DynamicSidebar';
import database_id from '../../../utils/databaseConfig';

const Sidebar = ({ applicationid }) => {
  const token = localStorage.getItem('token');
  const location = useLocation();
  const [settings, setSettings] = useState({});
  const [userdat, setUserdat] = useState({});

  const getdata = async () => {
    const Setting = await general_settings();
    const userResponse = await getuser(token);
    const userData = userResponse; // Adjust based on your response structure
    // Fetch additional user-related data
    const tableSchema = database_id;

    // Fetch user details based on login token
    const userCondition = { login_tockin: token };
    const userDetailsResponse = await oneclick.read(tableSchema, "users", userCondition, '', "", "");
    const userDetails = userDetailsResponse.data[0]; // Adjust based on your response structure

    // Fetch employee data
    const employeeCondition = { userid: userDetails.id };
    const employeesResponse = await oneclick.read(tableSchema, "employees", employeeCondition, '', "", "");
    const employeeData = employeesResponse.data[0] ? employeesResponse.data[0] : ""; // Adjust based on your response structure

    // Fetch role data for the employee
    let roleData;
    if (employeeData) {
      const roleCondition = { id: employeeData.role_id };
      const rolesResponse = await oneclick.read(tableSchema, "roles", roleCondition, '', "", "");
      roleData = rolesResponse.data[0]; // Adjust based on your response structure
    } else {
      roleData = "";
    }


    //this all data send setUser(userdata); hear
    setUserdat({ ...userData, employeeData }); // Merge user data with employee and role data
    setSettings(Setting[0] || {});
  };
  // Parse the permission JSON if it exists
  const permissions = typeof userdat?.roles?.permission === 'string'
    ? JSON.parse(userdat.roles.permission)
    : userdat?.roles?.permission || {};

  const roles = typeof userdat?.roles === 'string'
    ? JSON.parse(userdat.roles)
    : userdat?.roles || {};
  console.log('roles :', roles);
  // Access the environment variable
  const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl
  const [allmenudata, setallmenudata] = useState('');

  useEffect(() => {
    // Set image base URL when the component mounts
    setImageBaseUrl(getImageBaseUrl());
    getsidebardata();
    getdata();
  }, []);

  const getsidebardata = async () => {
    const tableSchema = database_id;
    const tableName = "menudata";

    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    const orderBy = 'order_by ASC'
    const menudata = await oneclick.read(tableSchema, tableName, "", orderBy, "");
    console.log('menudata load:', menudata);
    setallmenudata(menudata.data);

  }

  const [open, setOpen] = useState({
    cloudHost: false,
    cloudvps: false,
    cloudDedicatedHost: false,
    aiblogewriter: false,
    Ai_Devalapers: false,
    SMS_Messages: false,
    WhatsApp_Messages: false,
    Email_Messages: false,
    Credit_Log: false
  });
  const [openMenu, setOpenMenu] = useState(null);

  useEffect(() => {
    if (location.pathname === '/DesignPage/Headers' || location.pathname === '/DesignPage/TopBars' || location.pathname === '/DesignPage/Navbar') {
      setOpenMenu('Header');
    }
    if (location.pathname === '/shared-hosting-create' || location.pathname === '/shared-hosting-history') {
      setOpenMenu('cloudHost');
    }
    if (location.pathname === '/settings_&_administrator' || location.pathname === '/settings_&_administrator') {
      setOpenMenu('settings_&_administrator');
    }
    if (location.pathname === '/hrm&management' || location.pathname === '/hrm&management') {
      setOpenMenu('hrm&management');
    }
    if (location.pathname === '/frontend_sections ' || location.pathname === '/frontend_sections') {
      setOpenMenu('frontend_sections');
    }

  }, [location]);

  // Function to toggle the dropdown
  const handleToggle = (key) => {
    setOpen((prevState) => ({ ...prevState, [key]: !prevState[key] }));
    setOpenMenu((prevMenu) => (prevMenu === key ? null : key));
  };

  // Ensure background color is applied
  const sidebarBgColor = `#${settings.primary_color || 'ffffff'}`; // Defaults to white (#ffffff) if not set

  return (
    <>

      <aside className="sidebar" id="sidebar" style={{ background: sidebarBgColor }}>
        <div className="sidebar-top" style={{ background: sidebarBgColor }}>
          <div className="site-logo">
            <Link to="/dashboard" className="ajax-link">
              <img
                src={`${imageBaseUrl}/assets/file/images/logoIcon/${settings.panel_logo}`}
                alt="Site Logo"
                className="logo-lg"
                style={{ maxWidth: '248px', marginLeft: '-40px', marginTop: '23px', verticalAlign: 'middle' }}
              />
              <img
                src={`${imageBaseUrl}/assets/file/images/logoIcon/${settings.panel_logo}`}
                alt="Site Icon"
                className="logo-sm"
              />
            </Link>
          </div>
          <div className="menu-search-container" style={{ background: sidebarBgColor, marginTop: '25px' }}>
            <input
              className="form-control menu-search"
              placeholder="Search Here"
              type="search"
              name="search"
              id="searchMenu"
            />
          </div>
        </div>

        <div className="sidebar-menu-container" data-simplebar="true">
          <ul className="sidebar-menu">


            <li className="sidebar-menu-title">Dashboard</li>

            <li className={`sidebar-menu-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
              <Link to="/dashboard" className="sidebar-menu-link">
                <span><FaTachometerAlt /></span>
                <p>Overview</p>
              </Link>
            </li>



            <li className="sidebar-menu-title">Layouts</li>

            <li className="sidebar-menu-item">
              <button
                className={`ravi sidebar-menu-link ${openMenu === 'Header' ? 'active' : ''}  ${location.pathname === '/DesignPage/Headers' ? 'active' : ''} ${location.pathname === '/DesignPage/TopBars' ? 'active' : ''} ${location.pathname === '/DesignPage/Navbar' ? 'active' : ''}`}
                onClick={() => handleToggle('Header')}
              >
                <span><FaCloud /></span>
                <p>Header <small><i className={`las la-angle-${openMenu === 'Header' ? 'up' : 'down'}`}></i></small></p>
              </button>

              <Collapse in={openMenu === 'Header'}>
                <div className="side-menu-dropdown">
                  <ul className="sub-menu">
                    <li className={`sub-menu-item ${location.pathname === '/DesignPage/TopBars' ? 'active' : ''}`}>
                      <Link to={`/DesignPage/TopBars/${applicationid}`} className="sidebar-menu-link">
                        <p>TopBars</p>
                      </Link>
                    </li>
                    <li className={`sub-menu-item ${location.pathname === '/DesignPage/Headers' ? 'active' : ''}`}>
                      <Link to={`/DesignPage/Headers${applicationid}`} className="sidebar-menu-link">
                        <p>Headers</p>
                      </Link>
                    </li>
                    <li className={`sub-menu-item ${location.pathname === '/DesignPage/Navbar' ? 'active' : ''}`}>
                      <Link to={`/DesignPage/Navbar${applicationid}`} className="sidebar-menu-link">
                        <p>Navbar</p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li>

            

          </ul>
        </div>
      </aside>
    </>

  );
};

export default Sidebar;
