import { useState, useRef } from 'react';
import { FaEdit } from 'react-icons/fa'; // Edit icon (you can use any icon of your choice)
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const ProjectCard = ({ projuct }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(projuct.name);
    const inputRef = useRef(null);

    const handleBlur = () => {
        // Save the changes when clicking outside of the input
        setIsEditing(false);
    };

    const handleChange = async (e) => {
        // Update the project name as it's typed
        setName(e.target.value);
        const tableSchema = database_id;
        const tableName = 'projucts';
        const data = { name: e.target.value };
        const condition = { id: projuct.id };
        const response = await oneclick.update(tableSchema, tableName, data, condition);
        if (response.success) {
            setName(e.target.value);
        }
    };

    const handleClickEdit = () => {
        setIsEditing(true);

    };

    return (
        <div className="" style={{ position: 'relative', padding: '30px' }}>
            <div className='d-flex justify-content-between'>
                <div className="card-header-title">
                    <h4 className="card-title">
                        {isEditing ? (
                            <input
                                ref={inputRef}
                                type="text"
                                value={name}
                                onChange={handleChange}
                                onBlur={handleBlur} // Save when clicking outside
                                autoFocus
                                className="form-control"
                            />
                        ) : (
                            <>
                                {name}
                                <FaEdit
                                    onClick={handleClickEdit}
                                    style={{ cursor: 'pointer', marginLeft: '2px', height: '11px', top: '21px', position: 'absolute' }}
                                />
                            </>
                        )}
                    </h4>
                </div>
                <div className="card-header-action">
                    <h6 className="card-title">Project Status :&nbsp;
                        <span
                            className={`badge badge--${projuct.status === "Active"
                                ? "success"
                                : projuct.status === "Proposal"
                                    ? "info"
                                    : projuct.status === "Starting"
                                        ? "warning"
                                        : projuct.status === "Design"
                                            ? "primary"
                                            : "danger"
                                }`}
                        >
                            {projuct.status || "No status"}
                        </span></h6>
                </div>
            </div>

        </div>
    );
};

export default ProjectCard;
