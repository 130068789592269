import React, { useState } from 'react';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { oneclick } from '../../../oneclick';
import { DomaincnameVerification } from '../../../services/apiService';
import database_id from '../../../utils/databaseConfig';

const CNAMEVerification = ({ onHide, selectedWebsite }) => {
    const [generatedCNAME, setGeneratedCNAME] = useState('');
    const [pointToValue, setPointToValue] = useState('');
    const [verificationMessage, setVerificationMessage] = useState('');
    const [loading, setLoading] = useState(false); // Loading state

    const companyDomain = "oneklicks";

    // Function to generate a random string for CNAME
    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    // Function to create the full CNAME record
    const generateCNAME = async () => {
        const part1 = generateRandomString(32); // First unique part
        const part2 = generateRandomString(32); // Second unique part

        // Ensure the selected website has no 'https://' prefix
        const cleanWebsite = selectedWebsite.website.replace(/^https?:\/\//, '');

        // Create CNAME record using the cleaned website domain
        const cnameRecord = `${companyDomain}`;
        setGeneratedCNAME(cnameRecord); // Store generated CNAME for display

        // Generate dynamic Point To value
        const pointPart1 = generateRandomString(32); // First part of Point To
        const pointPart2 = generateRandomString(32); // Second part of Point To
        const pointTo = `38.242.198.29`;  // Dynamic Point To using company domain
        setPointToValue(pointTo); // Set Point To value

        const tableSchema = database_id;
        const data = { 
            cname: cnameRecord,
            pointvalue: pointTo
        };
        const condition = { id: selectedWebsite.id };

        await oneclick.update(tableSchema, "seowebsites", data, condition);

        return cnameRecord; // Return the generated CNAME
    };

    const handleVerifyCNAME = async () => {
        console.log('A record verification START');
        setLoading(true); // Set loading to true when verification starts
        const cleanWebsite = selectedWebsite.website.replace(/^https?:\/\//, '');
        const name = `${companyDomain}.${cleanWebsite}`; // First, generate the CNAME
        const pointTo = `38.242.198.29`;  // Dynamic Point To using company domain
    
        try {
            // Wait for the CNAME to be generated before calling the verification API
            const response = await DomaincnameVerification(name, pointTo);
            const result = await response;
    
            if (result.matchesExpectedIp) {
                const tableSchema = database_id;
    
                // Prepare data and condition for the database update
                const data = { status: "active" };
                const condition = { id: selectedWebsite.id };
    
                // Update the database
                console.log('Updating database with condition:', condition);
                const updatedata = await oneclick.update(tableSchema, "seowebsites", data, condition);
                console.log('Update response:', updatedata);
                // Check if the update was successful
                if (updatedata.success) {
                    setVerificationMessage('A record verification successful!');
                    onHide(); // Close the modal after successful verification
                } else {
                    setVerificationMessage('Verification failed. Could not A record.');
                    console.error('Database update failed, no data returned:', updatedata);
                }
            } else {
                setVerificationMessage('A record verification failed.');
            }
        } catch (error) {
            setVerificationMessage('Error during verification. Please try again later.');
        } finally {
            setLoading(false); // Reset loading state at the end
        }
    };

    
    // Function to copy text to clipboard
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard!');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <div style={{ border: '1px solid #00000033', borderRadius: '5px' }}>
            <h3 style={{ background: 'gainsboro', padding: '9px' }}>A record Domain Verification</h3>
            <div style={{ padding: '12px' }}>
                <p style={{ marginBottom: '10px' }}>To verify your domain using an A record, please follow the steps below:</p>
                <li style={{ marginBottom: '10px' }}>Sign in to your DNS provider, typically the registrar of your domain.</li>
                <li style={{ marginBottom: '10px' }}>Navigate to the section where DNS records are managed.</li>
                <li style={{ marginBottom: '10px' }}>Add the following A record:</li>
                <li>
                    <strong>Name:</strong>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            className='form-control'
                            value={generatedCNAME}
                            readOnly
                            style={{ marginRight: '10px', flex: 1 }}
                        />
                        <Button variant="outline-secondary" onClick={() => copyToClipboard(generatedCNAME)}>
                            Copy
                        </Button>
                    </div>
                </li>
                <li>
                    <strong>Point To:</strong>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            className='form-control'
                            value={pointToValue}
                            readOnly
                            style={{ marginRight: '10px', flex: 1 }}
                        />
                        <Button variant="outline-secondary" onClick={() => copyToClipboard(pointToValue)}>
                            Copy
                        </Button>
                    </div>
                </li>
                <li>
                    <strong>TTL:</strong>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            className='form-control'
                            value="3600"
                            readOnly
                            style={{ marginRight: '10px', flex: 1 }}
                        />
                        <Button variant="outline-secondary" onClick={() => copyToClipboard('3600')}>
                            Copy
                        </Button>
                    </div>
                    <span style={{ marginBottom: '10px' }}>(or lower)</span>
                </li>
                <li style={{ marginBottom: '10px' }}>Save your A record and click "Next Step" to continue.</li>
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button variant="primary" onClick={generatedCNAME ? handleVerifyCNAME : generateCNAME} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : (generatedCNAME ? 'Verify A record' : 'Generate A record')}
                    </Button>
                </div>
            </div>

            {generatedCNAME && <Alert variant="info" className="mt-3">Name: <code>{generatedCNAME}</code></Alert>}
            {pointToValue && <Alert variant="info" className="mt-3">Point To: <code>{pointToValue}</code></Alert>}
            {verificationMessage && <Alert variant="info" className="mt-3">{verificationMessage}</Alert>}
        </div>
    );
};

export default CNAMEVerification;
