import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import axios from 'axios';
import AddNewWebsiteModal from './Component/AddNewWebsiteModal';
import { oneclick } from '../../oneclick';
import { useLocation, useParams } from 'react-router-dom';
import DomainVerificationForm from './Component/DomainVerificationForm';
import CNAMEVerification from './Component/CNAMEVerification';
import EmailOtpInput from './Component/EmailOtpInput';
import DataDisplayComponent from '../Test/DataDisplayComponent';
import SeoDataModal from './Component/SeoDataFormModal';
import database_id from '../../utils/databaseConfig';
import Paginationnew from '../../utils/Paginationnew';

const appmode = process.env.REACT_APP_MODE;
const app_url = appmode === 'LIVE' ? process.env.REACT_APP_IMAGE_BASE_LIVEURL : process.env.REACT_APP_IMAGE_BASE_LOCALURL;
const API_URL = `${app_url}api/seodata/seo`;

const SeodataAdd = () => {
    const location = useLocation();
    const { showwebsites } = useParams(); // Retrieve the URL parameter
    const [showWebsitesTable, setShowWebsitesTable] = useState(showwebsites == "true" ? true : false);
    const [seoData, setSeoData] = useState([]);
    const [seowebData, setSeoWebData] = useState([]);
    const [formValues, setFormValues] = useState({ id: '', website: '', pageName: '', title: '', status: '' });
    const [isEdit, setIsEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showWebsiteModal, setShowWebsiteModal] = useState(false);

    const [currentRowData, setCurrentRowData] = useState(null); // Store current row data for the modal
    const [searchQuery, setSearchQuery] = useState('');
    const [searchWebsiteQuery, setSearchWebsiteQuery] = useState('');
    const [companyData, setCompanyData] = useState([]);
    const [carrountPage, setCarrountPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    // New state to handle selected website for the modal
    const [selectedWebsite, setSelectedWebsite] = useState('');
    const [selectedWebsitedata, setSelectedWebsitedata] = useState('');
    const [showWebsiteNameModal, setShowWebsiteNameModal] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('');

    const [verificationMethod, setVerificationMethod] = useState(''); // For verification method (text file, CNAME, OTP)
    const [cnameValue, setCnameValue] = useState(''); // CNAME input state
    const [otpValue, setOtpValue] = useState(''); // OTP input state
    const [file, setFile] = useState(null); // File input state
    const allowedDomain = '@websitename.com'; // Set your allowed domain here

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    // Fetch SEO data from API
    useEffect(() => {
        fetchSeoData();
    }, []);

    const fetchSeoData = async () => {
        try {
            const tableSchema = database_id;
            const token = localStorage.getItem('token');
            const conditionsUsers = { "login_tockin": token };

            const employeeData11 = await oneclick.read(tableSchema, "users", conditionsUsers, "", "");
            const { id: user_id, company_id } = employeeData11.data[0] || {};

            if (!user_id) {
                alert('User not found. Please log in again.');
                return;
            }

            const conditionsEmployee = { "userid": user_id };
            const employeeData = await oneclick.read(tableSchema, "employees", conditionsEmployee, "", "");

            const conditionswebsites = {
                company_id: employeeData?.data[0]?.company_id || company_id,
                branch_id: employeeData?.data[0]?.branch_id || '',
            };

            const orderBy = "id DESC";


            const params = {
                tableSchema: database_id,
                tableName: 'seowebsites',
                conditions: conditionswebsites,
                orderBy: 'id DESC',
                search: "",
                pagination: { page: carrountPage || 1, limit: 10 },
                margedata: [],
                databaseid: ''
            };

            oneclick.liveRead(params, (data, error) => {
                if (error) {
                    console.error('Error fetching live data:', error);
                } else {
                    setSeoWebData(data.data); // Update state with the fetched data
                }
            });


        } catch (error) {
            console.error('Error fetching SEO data:', error);
        }
    };



    // Handle add or edit
    const handleAddOrEdit = (data) => {
        if (data) {
            setFormValues(data);
            setIsEdit(true);
        } else {
            setFormValues({ id: '', website: '', pageName: '', title: '', status: '' });
            setIsEdit(false);
        }
        setShowModal(true);
    };

    // Handle delete
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                // Perform delete operation here
                await oneclick.delete(API_URL, id); // Assuming the delete API is structured this way
                alert('Deleted successfully');
                // Optionally refetch data or update local state
            } catch (error) {
                console.error('Error deleting SEO data:', error);
                alert('Error deleting data');
            }
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEdit) {
                // Update existing SEO data
                await oneclick.update(API_URL, formValues.id, formValues);
            } else {
                // Add new SEO data
                await oneclick.create(API_URL, formValues);
            }
            alert(`${isEdit ? 'Updated' : 'Added'} successfully`);
            setShowModal(false);
            // Optionally refetch data or update local state
        } catch (error) {
            console.error('Error saving SEO data:', error);
            alert('Error saving data');
        }
    };

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
    };

    // Handle modal close
    const handleModalClose = () => {
        setShowModal(false);
        setFormValues({ id: '', website: '', pageName: '', title: '', status: '' });
    };

    // Handle search input change for SEO data
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    // Handle search input change for Website data
    const handleWebsiteSearchChange = (e) => {
        setSearchWebsiteQuery(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    // Handle button click to open modal showing the website name and status
    const handleShowWebsiteName = (website, status, data) => {
        setSelectedWebsite(website);
        setCurrentStatus(status);
        setSelectedWebsitedata(data);
        setShowWebsiteNameModal(true);
    };

    // Handle modal close
    const handleWebsiteNameModalClose = () => {
        setShowWebsiteNameModal(false);
        setSelectedWebsite('');
        setCurrentStatus('');
        setVerificationMethod('');
        setSelectedWebsitedata('');
        setCnameValue('');
        setOtpValue('');
        setFile(null);
    };

    // Handle file input change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Generate default CNAME values
    const generateCNAME = () => {
        // Example CNAME structure; modify as needed
        return `www.${selectedWebsite} CNAME your.target.domain.com`;
    };

    // Handle form submission for verification
    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('website', selectedWebsite);
        formData.append('verificationMethod', verificationMethod);

        if (verificationMethod === 'file' && file) {
            formData.append('file', file);
        } else if (verificationMethod === 'cname') {
            formData.append('cname', cnameValue);
        } else if (verificationMethod === 'otp') {
            if (otpValue.endsWith(allowedDomain)) {
                formData.append('otp', otpValue);
            } else {
                alert(`Email must be from ${allowedDomain}`); // Alert if domain is incorrect
                return;
            }
        }

        try {
            const response = await axios.post(`${API_URL}/verify`, formData);
            console.log('Verification response:', response.data);
        } catch (error) {
            console.error('Verification error:', error);
        }
    };

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="dashboard_container">
            <section className="mt-3">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Search Engine Optimization Data</h4>
                    </div>
                    <div className="card-header">
                        <div style={{ marginTop: '10px' }}>
                            {showWebsitesTable ? (
                                <input
                                    type="text"
                                    placeholder="Search Websites"
                                    className='form-control'
                                    value={searchWebsiteQuery}
                                    onChange={handleWebsiteSearchChange}
                                    style={{ marginLeft: '10px' }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder="Search SEO Data"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            )}
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            {showWebsitesTable ? (
                                <>
                                    <button onClick={() => setShowWebsitesTable(false)} className="i-btn btn--primary btn--md">
                                        <i className="las la-arrow-left"></i> View All SEO Data
                                    </button>
                                    <button onClick={() => setShowWebsiteModal(true)} className="i-btn btn--primary btn--md">
                                        <i className="las la-plus"></i> Add New Website
                                    </button>
                                    <button onClick={() => handleAddOrEdit()} className="i-btn btn--primary btn--md">
                                        <i className="las la-plus"></i> Add New SEO Data
                                    </button>
                                </>

                            ) : (
                                <>
                                    <button onClick={() => setShowWebsitesTable(true)} className="i-btn btn--primary btn--md">
                                        <i className="las la-plus"></i> View All Websites
                                    </button>
                                    <button onClick={() => setShowWebsiteModal(true)} className="i-btn btn--primary btn--md">
                                        <i className="las la-plus"></i> Add New Website
                                    </button>
                                    <button onClick={() => handleAddOrEdit()} className="i-btn btn--primary btn--md">
                                        <i className="las la-plus"></i> Add New SEO Data
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="card-body px-0">
                        <div className="responsive-table">
                            {showWebsitesTable ? (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Website Name</th>
                                            <th>Web Technology</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {seowebData.length > 0 ? (
                                            seowebData.map((data) => (
                                                <tr key={data.id}>
                                                    <td>{data.id}</td>
                                                    <td>{data.website}</td>
                                                    <td>{data.technology}</td>
                                                    <td>
                                                        {/* Button to show status */}
                                                        <button
                                                            className={`i-btn btn--md ${data.status == 'active' ? 'badge--success' : 'btn--primary'}`}
                                                            onClick={() => handleShowWebsiteName(data.website, data.status, data)}
                                                        >
                                                            {data.status === 'Pending' ? 'Pending' : 'Verified'}
                                                        </button>
                                                    </td>
                                                    <td style={{ display: 'flex' }}>
                                                        {data.status === 'Pending' && (
                                                            <button
                                                                className="i-btn btn--md badge--success"
                                                                onClick={() => handleShowWebsiteName(data.website, data.status, data)} style={{ marginRight: '5px' }}
                                                            >
                                                                <i className="las la-edit"></i> Verify
                                                            </button>
                                                        )}
                                                        <button
                                                            className="i-btn btn--md btn--warning"
                                                            onClick={() => handleAddOrEdit(data)}
                                                        >
                                                            <i className="las la-edit"></i> Edit
                                                        </button>
                                                        <button
                                                            className="i-btn btn--md btn--danger"
                                                            onClick={() => handleDelete(data.id)}
                                                        >
                                                            <i className="las la-trash"></i> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="text-center">No Websites Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Website</th>
                                            <th>Page Name</th>
                                            <th>Title</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {currentSeoData.length > 0 ? (
                                            currentSeoData.map((data) => (
                                                <tr key={data.id}>
                                                    <td>{data.id}</td>
                                                    <td>{data.website}</td>
                                                    <td>{data.pageName}</td>
                                                    <td>{data.title}</td>
                                                    <td>{data.status}</td>
                                                    <td>
                                                        <button
                                                            className="i-btn btn--md btn--warning"
                                                            onClick={() => handleAddOrEdit(data)}
                                                        >
                                                            <i className="las la-edit"></i> Edit
                                                        </button>
                                                        <button
                                                            className="i-btn btn--md btn--danger"
                                                            onClick={() => handleDelete(data.id)}
                                                        >
                                                            <i className="las la-trash"></i> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center">No SEO Data Found</td>
                                            </tr>
                                        )} */}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <Paginationnew currentPage={carrountPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </section>


            {/* Modal to show selected website name and status */}
            <Modal show={showWebsiteNameModal} onHide={handleWebsiteNameModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Website Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Website: {selectedWebsite}</p>
                    <p>Status: {currentStatus === 'pending' ? 'Pending' : 'Free'}</p>

                    <Form onSubmit={handleVerificationSubmit}>
                        <Form.Group controlId="verificationMethod" style={{ marginBottom: '10px' }}>
                            <Form.Label>Select Verification Method</Form.Label>
                            <Form.Control as="select" onChange={(e) => setVerificationMethod(e.target.value)}>
                                <option value="">Choose...</option>
                                <option value="file">Upload Text File</option>
                                <option value="cname">A Record</option>
                                <option value="otp">Email OTP</option>
                            </Form.Control>
                        </Form.Group>

                        {/* selectedWebsite */}
                        {verificationMethod === 'file' && (
                            <DomainVerificationForm onHide={handleWebsiteNameModalClose} selectedWebsite={selectedWebsitedata} />
                        )}

                        {verificationMethod === 'cname' && (
                            <CNAMEVerification onHide={handleWebsiteNameModalClose} selectedWebsite={selectedWebsitedata} />
                        )}

                        {verificationMethod === 'otp' && (
                            <EmailOtpInput onHide={handleWebsiteNameModalClose} selectedWebsite={selectedWebsitedata} />
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleWebsiteNameModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Add or Edit SEO Data Modal */}
            <SeoDataModal showModal={showModal} handleClose={handleModalClose} isEdit={isEdit} initialData={""} />


            {/* Add New Website Modal */}
            <AddNewWebsiteModal showWebsiteModal={showWebsiteModal} setShowWebsiteModal={() => setShowWebsiteModal(false)} fetchSeoData={fetchSeoData}/>

            {/* <DataDisplayComponent/> */}
        </div>
    );
};

export default SeodataAdd;
