import React, { useState } from 'react';
import { oneclick } from '../../../oneclick';
import { OneKlicksFileSDK } from '../../../OneKlicksFileSDK';
import { getuserdata } from '../../../services/apiService';
import { a } from 'react-spring';
import database_id from '../../../utils/databaseConfig';

const AddAiTeam = ({ show, hide, fetchEmailData, emailData }) => {
    const [filterdeportment, setfilterdeportment] = useState('');
    const [departmentData, setDepartmentData] = useState({
        name: '',
        description: '',
        image: null,
        status: '',
    });
    const [successModalVisible, setSuccessModalVisible] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDepartmentData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setDepartmentData((prevData) => ({
            ...prevData,
            image: file,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', departmentData.name);
        formData.append('description', departmentData.description);
        formData.append('image', departmentData.image);
        formData.append('status', departmentData.status);

        const userId = await getuserdata('id');
        const root = 'ai/team/logos';

        try {
            const uploadimage = await OneKlicksFileSDK.uploadFile(userId, root, formData.get('image'));
            console.log('Image uploaded:', uploadimage.file.id);

            const tableSchema = database_id;
            const tableName = 'ai_teams';
            const data = {
                userId: await getuserdata('id'),
                company_id: await getuserdata('company_id'),
                name: formData.get('name'),
                department_id: filterdeportment,
                description: formData.get('description'),
                image: uploadimage.file.id,
                status: formData.get('status'),
                created_at	: new Date().toISOString(),
                updated_at	: new Date().toISOString(),
            };
            const conditions = {
                company_id: data.company_id,
                name: data.name,
            };
            const uploaddata = await oneclick.create(tableSchema, tableName, data, conditions);
            console.log('Team added:', uploaddata);
            if (uploaddata.success) {
                setSuccessModalVisible(true); // Show success modal
            } else {
                alert('Error adding team: ' + uploaddata.message); // Concatenate the message
                console.error('Error adding team:', uploaddata.message);
            }
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const hideSuccessModal = () => {
        setSuccessModalVisible(false);
        // Optionally reset the form
        hide();
        fetchEmailData(); // Fetch email data after adding/updating department
        setDepartmentData({
            name: '',
            description: '',
            image: null,
            status: '',
        });
    };

    return (
        <div>
            <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="addNewLabel" aria-hidden={!show}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addNewLabel">Add Team</h5>
                            <button type="button" className="btn-close" onClick={hide}><i className="fa fa-close"></i></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Select Department</label>
                                    <div className="filter-item">
                                        <select
                                            name="status"
                                            className="form-select"
                                            value={filterdeportment}
                                            onChange={(e) => setfilterdeportment(e.target.value)}
                                        >
                                            <option value="">Select Department</option>
                                            {emailData.length > 0 ? (
                                                emailData.map((data) => {
                                                    return (
                                                        <option key={data.id} value={data.id}>
                                                            {data.name} {/* Display only the domain name */}
                                                        </option>
                                                    );
                                                })
                                            ) : (
                                                <option disabled>No available</option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Team Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={departmentData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <textarea
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        value={departmentData.description}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        name="image"
                                        onChange={handleImageChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="status" className="form-label">Status</label>
                                    <select
                                        className="form-select"
                                        name="status"
                                        value={departmentData.status}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled>Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <div className="modal-footer" style={{ paddingRight: '0px' }}>
                                    <button type="submit" className="btn btn-primary">Add Department</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            {successModalVisible && (
                <div className="modal fade show" style={{ display: 'block' }} aria-labelledby="successModalLabel" aria-hidden="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="successModalLabel">Success</h5>
                                <button type="button" className="btn-close" onClick={hideSuccessModal}></button>
                            </div>
                            <div className="modal-body">
                                Department added successfully!
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={hideSuccessModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddAiTeam;