// src/user/DesignPage/components/TopBar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import settingIcon from '../../../assets/file/images/setting.png'; // Adjust the path as necessary
import shart from '../../../assets/file/images/sort.png';
import { getImageBaseUrl } from '../../../services/apiService';
import './TopBar.css';

const TopBar = ({ toggleSidebar, isSidebarVisible }) => {

    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

    useEffect(() => {
        // Set image base URL when the component mounts
        setImageBaseUrl(getImageBaseUrl());
    }, []);


    return (
        <div className="top-bar">
            <button className="hamburger-icon" onClick={toggleSidebar}>
                <img
                    src={isSidebarVisible ? shart : settingIcon} // Corrected conditional
                    alt="Site Logo"
                    className="logo-lg"
                    style={{
                        maxWidth: '40px',
                        filter: 'invert(1) sepia(1) saturate(0) brightness(100)'
                    }}
                />
                {/* <i className={isSidebarVisible ? "fas fa-times" : "fas fa-bars"}></i>   style="max-width: 40px;filter: invert(1) sepia(1) saturate(0) brightness(100);"*/}
            </button>
            <h1>Design Page</h1>
            <div className="top-bar-actions">
                <button className="btn btn-primary">Save</button>
                <button className="btn btn-secondary">Preview</button>
            </div>
        </div>
    );
};

export default TopBar;
