import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from "react-bootstrap";

// Table structure modal component
const TableStructureModal = ({ isOpen, onClose, columns }) => {

    const [editableColumn, setEditableColumn] = useState(null);
    const [inputValues, setInputValues] = useState({});
    const [checkedColumns, setCheckedColumns] = useState({});

    // Handle input changes for the "Name" column
    const handleInputChange = (e, index) => {
        const value = e.target.value;
        setInputValues(prevValues => ({
            ...prevValues,
            [index]: value
        }));
    };

    // Handle checkbox toggle for each row
    const handleCheckboxChange = (e, index) => {
        setCheckedColumns(prevChecked => ({
            ...prevChecked,
            [index]: e.target.checked
        }));
    };

    // Handle "Save Changes" button click
    const handleSubmit = () => {
        // Here you can handle the logic to save changes to your columns
        console.log(inputValues);
        console.log(checkedColumns);
    };

    return (
        <Modal 
            show={isOpen} 
            onHide={onClose} 
            centered 
            style={{ maxWidth: '80%' }} // Set the modal width to 80%
        >
            <Modal.Header closeButton>
                <Modal.Title>Table Structure</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'auto' }}>
                    <table style={{ width: '100%', border: '1px solid #ccc', marginTop: '20px', tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Null</th>
                                <th>Key</th>
                                <th>Default</th>
                                <th>Extra</th>
                                <th>Select</th> {/* New column for the checkbox */}
                            </tr>
                        </thead>
                        <tbody>
                            {columns.length === 0 ? (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                        No Data Available
                                    </td>
                                </tr>
                            ) : (
                                columns.map((column, index) => (
                                    <tr key={index}>
                                        <td>
                                            {editableColumn === index ? (
                                                <input
                                                    type="text"
                                                    value={inputValues[index] || column.COLUMN_NAME}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    style={{ width: '100%' }}
                                                />
                                            ) : (
                                                column.COLUMN_NAME
                                            )}
                                        </td>
                                        <td>{column.DATA_TYPE}</td>
                                        <td>{column.IS_NULLABLE}</td>
                                        <td>{column.COLUMN_KEY}</td>
                                        <td>{column.COLUMN_DEFAULT}</td>
                                        <td>{column.EXTRA}</td>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                checked={checkedColumns[index] || false}
                                                onChange={(e) => handleCheckboxChange(e, index)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TableStructureModal;
