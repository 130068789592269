// src/user/DesignPage/DesignPage.js
import React, { useState } from 'react';
import Canvas from './components/Canvas';
import { useLocation, useParams } from 'react-router-dom';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TopBar from './components/TopBar';
import Sidebar from './components/sidebar';
import './DesignPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const DesignPage = () => {
    const location = useLocation();
    const { applicationid } = useParams(); // Retrieve the URL parameter
    const [components, setComponents] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Sidebar visibility
    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

    const [elements, setElements] = useState([
        {
            id: '1',
            name: 'Row',
            style: {
                width: '200px',
                height: '100px',
                backgroundColor: '#ff0000',  // Sample background color
                borderRadius: '10px',  // Sample border radius
            }
        }
    ]);
    const [selectedElement, setSelectedElement] = useState(null);

    const updateElement = (id, newStyle) => {
        // Update the style of the selected element dynamically
        setElements((prevElements) => {
            return prevElements.map((element) =>
                element.id === id ? { ...element, style: newStyle } : element
            );
        });
    };


    const handleAddComponent = (component) => {
        setComponents([...components, component]);
        setSelectedComponent(component);
    };

    const handleSelectComponent = (component) => {
        setSelectedComponent(component);
    };

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible); // Toggle sidebar visibility
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="">
                <div className="row layout">
                    {/* Left Sidebar */}
                    {isSidebarVisible && (
                        <Sidebar applicationid={applicationid}/>
                    )}
                    <div className={`${isSidebarVisible ? 'col-lg-10' : 'col-lg-12'} canvas`}>
                        <TopBar toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} /> {/* Pass toggle function to TopBar */}
                        <div className='row col-lg-12'>
                            {/* Center Content */}
                            <div className={`col ${isSidebarVisible ? 'middle-content' : 'col-12'}`}>
                                {/* <Canvas components={components} onSelectComponent={handleSelectComponent} /> */}
                                <Canvas elements={elements} setElements={setElements} setSelectedElement={setSelectedElement} />
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </DndProvider>

    );
};

export default DesignPage;
