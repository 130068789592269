import axios from 'axios';
import io from 'socket.io-client'; // Import Socket.IO client
import { useSocket } from '../context/SocketContext';
import { oneclick } from '../oneclick';
import apiUrl from '../utils/apiConfig';
import database_id from '../utils/databaseConfig';




const appmode = process.env.REACT_APP_MODE;
const app_url = apiUrl;
console.log('app_url :', app_url);
const API_URL = `${app_url}/api/`;
console.log('API_URL :', API_URL);

const token = localStorage.getItem('token'); // Example: get token from localStorage
const api_key = process.env.REACT_APP_API_KEY;
// Check if token exists and add Authorization header
const headers = token ? { 'Authorization': `Bearer ${token}` } : {'x-api-key': api_key};


//console.log('Socket:', socket); // Check what `socket` is


export const getImageBaseUrl = () => {
  const appmode = process.env.REACT_APP_MODE;
  // console.log('api url', appmode === 'LIVE' ? process.env.REACT_APP_IMAGE_BASE_LIVEURL : process.env.REACT_APP_IMAGE_BASE_LOCALURL);
  return apiUrl;

};


export const encodeBase64 = (text) => {
  // Escape characters before Base64 encoding
  const encodedText = encodeURIComponent(text);
  return btoa(encodedText);
};

export const decodeBase64 = (encodedText) => {
  // Decode Base64 and then decode the URI components
  const decodedText = atob(encodedText);
  return decodeURIComponent(decodedText);
};



export const fetchFolderContentssocket = async (socket, page, folderName, loginToken) => {
  //onst socket = useSocket(); // Access the socket from context
  console.log('socket RUN DATA :', socket);
  return new Promise((resolve, reject) => {
    // Emit event to fetch folder contents

    // Emit the event with the correct data
    socket.emit('fetch-folder-contents', {
      page: page,
      login_tockin: loginToken,
      foldername: folderName
    });

    // Listen for the response from the server
    socket.on('folder-contents-response', (data) => {
      if (data.error) {
        console.error('Error fetching folder contents:', data.error);
        reject(data.error);
      } else {
        resolve(data.items);
      }
    });

    // Handle errors
    socket.on('error', (error) => {
      console.error('Socket error:', error);
      reject(error);
    });
  });
};


export const fetchPaginatedItemsSocket = (socket, pageNumber, loginToken, foldername) => {
  console.log('Socket connected 22:', socket);
  return new Promise((resolve, reject) => {
    if (!socket || typeof socket.emit !== 'function') {
      return reject(new Error('Socket is not properly initialized'));
    }

    if (!socket.connected) {
      return reject(new Error('Socket is not connected'));
    }

    // Emit the event with the correct data
    socket.emit('fetch-paginated-items', {
      page: pageNumber,
      login_tockin: loginToken,
      foldername: ""
    });

    // Listen for the response from the server
    const onResponse = (data) => {
      if (data.error) {
        console.error('Error fetching paginated items:', data.error);
        reject(data.error);
      } else {
        console.log('FILE DATA GET:', data);  // Logging received data
        resolve(data.items);
      }

      // Cleanup the listener after response
      socket.off('paginated-items-response', onResponse);
    };

    socket.on('paginated-items-response', onResponse);

    // Handle socket errors
    socket.on('error', (error) => {
      console.error('Socket error:', error);
      reject(error);
    });
  });
};


export const OpenAIResponse = async (message, aiid, userid, type, team, training) => {
  try {
    const response = await axios.post(`${API_URL}ai/send-message`, {
      message, aiid, userid, type, team, training
    }, {
      headers: headers, 
    }); // Attach the headers to the request
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const whatsappqrcreater = async (clientId) => {
  try {
    const response = await axios.post(`${API_URL}whatsapp/initialize-whatsapp`, {
      clientId
    }, {  headers, // Attach the headers to the request
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const whatsappqrstatus = async (clientId) => {
  try {
    const response = await axios.post(`${API_URL}whatsapp/check-status`, {
      clientId
    }, {  headers, // Attach the headers to the request
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const fetchFeatureContent = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/frontendSection/frontend-sections/feature.fixed_content`);
    console.log('Feature Content all:', response.data); // Log the content to check

    // Assuming response.data is an array, you can iterate and log the `id` for each item.
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        console.log('ID:', item.id); // Print the ID
      });
    } else {
      // If response.data is not an array, just log the `id`
      console.log('ID:', response.data.id);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching feature content:', error);
    throw error;
  }
};

export const fetchFeatureElements = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/frontendSection/frontend-sections/feature.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const ChooseUsContent = async () => {
  try {
    const response = await axios.get(`${apiUrl}frontendSection/frontend-sections/cta.fixed_content`);
    console.log('url :', apiUrl); // Log the content to check

    // Assuming response.data is an array, you can iterate and log the `id` for each item.
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        console.log('ID:', item.id); // Print the ID
      });
    } else {
      // If response.data is not an array, just log the `id`
      console.log('ID:', response.data.id);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching feature content:', error);
    throw error;
  }
};

export const ChooseUsElements = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/cta.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const processContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/process.fixed_content`);
    console.log('url :', API_URL); // Log the content to check

    // Assuming response.data is an array, you can iterate and log the `id` for each item.
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        console.log('ID:', item.id); // Print the ID
      });
    } else {
      // If response.data is not an array, just log the `id`
      console.log('ID:', response.data.id);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching feature content:', error);
    throw error;
  }
};

export const processElement = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/process.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const AboutSectionContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/about.fixed_content`);
    console.log('url :', API_URL); // Log the content to check

    // Assuming response.data is an array, you can iterate and log the `id` for each item.
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        console.log('ID:', item.id); // Print the ID
      });
    } else {
      // If response.data is not an array, just log the `id`
      console.log('ID:', response.data.id);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching feature content:', error);
    throw error;
  }
};

export const AboutSectionElement = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/about.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const ClientsContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/clients.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const HeroSectionContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/banner.fixed_content`);
    console.log('banner.fixed_content :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const HeroSectionsocialIconsContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/social_icon.element_content`);
    console.log('icon data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const OverviewContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/over_view.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const TeamSectionContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/mumbers.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const TeamSectionElements = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/mumbers.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const FAQSectionContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/faq.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const FAQSectionElements = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/faq.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const PricingPlansContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/pricing_plan.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const PricingPlansElement = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/pricing-plans`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const HeroSectionUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}users/users`,{headers});
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const ClientsCounte = async () => {
  try {
    const response = await axios.get(`${API_URL}users/user-stats`,{headers});
    console.log('user count :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const fetchSettings = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/general-settings`, {headers});
    console.log('general_settings data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const general_settings = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/general-settings`);
    console.log('general_settings data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}users/login`, {
      email,
      password,
    });

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const refreshToken = async (usertoken, userId) => {
  try {
    const response = await axios.post(`${API_URL}users/refresh-token`, {
      usertoken, userId
    },{headers});

    return response.data; // Assuming the API returns a new token
  } catch (error) {
    throw error.response ? error.response.data : new Error('Token refresh failed');
  }
};

export const UserForgotPassword = async (email) => {
  try {
    const response = await axios.post(`${API_URL}users/forgotpassword`, {
      email,
    },{headers});

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const UserVerify = async (email, otp) => {
  try {
    const response = await axios.post(`${API_URL}users/verifyOtp`, {
      email,
      otp
    },{headers});

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const updatepassword = async (email, newPassword) => {
  try {
    const response = await axios.post(`${API_URL}users/update-password`, {
      email,
      newPassword
    },{headers});

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const registerUser = async (name, email, password) => {
  try {
    const response = await axios.post(`${API_URL}users/registerUser`, {
      name,
      email,
      password
    },{headers});

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const getuser = async (login_tockin) => {
  try {
    const response = await axios.post(`${API_URL}users/getUser`, {
      login_tockin
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${login_tockin}`, // Send token in the Authorization header
        // 'x-api-key': 'YOUR_USER_API_KEY', // Custom API key for user authentication
        'Cache-Control': 'no-store', // Disable caching for this request
      },
    });
    console.log('user data :', response.data);
    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const alllanguages = async () => {
  try {
    const response = await axios.get(`${API_URL}users/languages`,{headers});
    console.log('languages data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const getcurrencies = async () => {
  try {
    const response = await axios.get(`${API_URL}currency/currencies`,{headers});
    console.log('currencies data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const getallcurrencies = async (page = 1, limit = 20, search = '') => {
  try {
    const response = await axios.get(`${API_URL}currency/allcurrencies`, {
      params: { page, limit, search },  // Pass page and limit as query parameters
      headers
    });
    console.log('Currencies all data:', response.data);
    return response.data;  // Make sure this includes pagination info (data, totalPages, etc.)
  } catch (error) {
    console.error('Error fetching currencies:', error);
    throw error;
  }
};

export const createCurrency = async (code, name, symbol, rate, status) => {
  try {
    const response = await axios.post(`${API_URL}currency/createCurrency`, {
      code, name, symbol, rate, status
    },{
      headers, // Attach the headers to the request
    });
    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const updateCurrency = async (currencyData) => {
  const response = await axios.post(`${API_URL}currency/updateCurrency`, {currencyData}, {
    headers, // Attach the headers to the request
  }); // Adjust the URL according to your routes
  return response.data;
};

export const deleteCurrency = async (currencyId) => {
  const response = await axios.delete(`${API_URL}currency/currency/${currencyId}` ,{
    headers, // Attach the headers to the request
  }); // Use DELETE method
  return response.data;
};


export const updateUserCurrency = async (login_tockin, currencyCode, symbol) => {
  try {
    const response = await axios.post(`${API_URL}users/currency`, {
      login_tockin,
      currencyCode,
      symbol
    },{
      headers, // Attach the headers to the request
    });
    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const convertCurrency = async (priceInUSD, currencyCode) => {
  try {
    const response = await axios.post(`${API_URL}currency/getConvterCurrencies`, {
      priceInUSD,
      currencyCode,
    },{
      headers, // Attach the headers to the request
    });
    return response.data.convertedPrice;
  } catch (error) {
    console.error('Error converting currency:', error);
    return priceInUSD; // Return original price if conversion fails
  }
};

export const convertUSDCurrency = async (amount, fromCurrency) => {
  try {
    const response = await axios.post(`${API_URL}currency/getConvertedUSDCurrency`, {
      amount,
      fromCurrency,
      toCurrency: "USD", // Explicitly define the toCurrency key
    },{
      headers, // Attach the headers to the request
    });
    return response.data.convertedPrice;
  } catch (error) {
    console.error('Error converting currency:', error);
    return amount; // Return the original amount if conversion fails
  }
};

export const hostpricedata = async (planId, currencyCode, billingtype, coupon, regionfee) => {
  try {
    const response = await axios.post(`${API_URL}cloud/hostpricedata`, {
      planId, currencyCode, billingtype, coupon, regionfee
    },{
      headers: headers, // Attach the headers to the request
    });
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return planId; // Return original price if conversion fails   
  }
};

export const checkdomainusename = async (type, value) => {
  try {
    const response = await axios.post(`${API_URL}cloud/check-availability`, {
      type, value
    },{
      headers, // Attach the headers to the request
    });
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

export const getcompanydata = async (login_tockin) => {
  const token = localStorage.getItem('token'); // Retrieve token from localStorage

  try {
    const response = await axios.get(`${API_URL}company/company`, {
      params: {
        loginToken: login_tockin
      },
      headers: {
        'Authorization': `Bearer ${token}` // Include token in headers
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching company data:', error);
    throw error; // Rethrow error for further handling if needed
  }
};

//getBranchDetails
export const getbranchdata = async (company_id) => {

  try {
    const response = await axios.get(`${API_URL}hrm/branches?company_id=${company_id}`, {
      params: {
        company_id: company_id
      },
      headers: {
        'Authorization': `Bearer ${token}` // Include token in headers
      }
    });
    console.log('sudha first getdata', response);
    return response;
  } catch (error) {
    console.error('Error fetching company data:', error);
    throw error; // Rethrow error for further handling if needed
  }
};

// Update branch details by ID
export const updateBranchDetails = async (id, branchData) => {
  try {
    const response = await axios.put(`${API_URL}hms/branches/${id}`, {branchData},{
      headers, // Attach the headers to the request
    });
    return response.data; // Return updated branch data
  } catch (error) {
    throw new Error(error.response.data.message || 'Error updating branch');
  }
};


// Update branch details by ID hashPassword

export const hashPassword = async (password) => {
  try {
    const response = await axios.post(`${API_URL}realtime/hashPassword`,{password}  ,{
      headers, // Attach the headers to the request
    });
    return response.data; // Return updated branch data
  } catch (error) {
    throw new Error(error.response.data.message || 'Error updating DomainVerification');
  }
};

export const DomainVerification = async (domain, filename) => {
  try {
    const response = await axios.post(`${API_URL}seodata/verify`,{domain, filename}  ,{
      headers, // Attach the headers to the request
    });
    return response.data; // Return updated branch data
  } catch (error) {
    throw new Error(error.response.data.message || 'Error updating DomainVerification');
  }
};

// Update branch details by ID
export const getPagedata = async (domain) => {
  try {
    const response = await axios.post(`${API_URL}seodata/getPagedata`,{domain}  ,{
      headers, // Attach the headers to the request
    });
    return response.data; // Return updated branch data
  } catch (error) {
    throw new Error(error.response.data.message || 'Error updating DomainVerification');
  }
};

// Function to verify domain A records against an expected IP
export const DomaincnameVerification = async (domain, expectedIp) => {
  console.log('DomaincnameVerification:', domain, expectedIp); // Log the domain and expected IP

  try {
    // Send a POST request to verify the domain and expected IP
    const response = await axios.post(`${API_URL}seodata/verify-domain`, { domain, expectedIp },{
      headers, // Attach the headers to the request
    });
    return response.data; // Return the response data containing A records and match result
  } catch (error) {
    // Handle errors and throw a more descriptive error message
    throw new Error(error.response?.data?.message || 'Error verifying domain A records');
  }
};


//getBranchDetails
export const getBranchDetails = async (company_id) => {

  try {
    const response = await axios.get(`${API_URL}hrm/branches?company_id=${company_id}`, {
      params: {
        company_id: company_id
      },
      headers, // Attach the headers to the request
    });
    console.log('sudha first getdata', response);
    return response;
  } catch (error) {
    console.error('Error fetching company data:', error);
    throw error; // Rethrow error for further handling if needed
  }
};



const apiClient = axios.create({
  baseURL: { API_URL }, // Adjust the base URL as needed
  headers, // Attach the headers to the request
});


export const uploadFile = (formData, onUploadProgress) => {
  const token = localStorage.getItem('token'); // Assuming token is in localStorage (adjust if needed)

  if (!token) {
    throw new Error('Authentication token is required.');
  }

  console.log('formData:', formData); // Log the FormData object

  return axios.post(`${API_URL}files/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // This is automatically handled by FormData, so it's not strictly required
      'Authorization': `Bearer ${token}`, // Include the authentication token in the header
    },
    onUploadProgress, // Handle progress updates
  })
  .then(response => {
    return response.data; // Return data on success
  })
  .catch(error => {
    // Log the error response if available, otherwise, log a general error
    console.error('File upload error:', error);
    throw error.response?.data || error; // Return error data if available
  });
};

export const bulkDeleteFolders = async (api_key, folderIds) => {
  try {
    const response = await axios.post(`${API_URL}files/bulkDeleteFolders`, {
      api_key,
      folderIds
    },{
      headers, // Attach the headers to the request
    });
    console.log('bulkDeleteFolders:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

export const downloadFolder = async (api_key, folderId) => {
  try {
    const response = await axios.post(`${API_URL}files/downloadFolder`, {
      api_key,
      folderId
    },{
      headers, // Attach the headers to the request
    });
    console.log('downloadFolder:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

export const getFolderList = async (login_tockin) => {
  try {
    const response = await axios.post(`${API_URL}files/list-folders`, {
      login_tockin
    },{
      headers, // Attach the headers to the request
    });
    console.log('FOLDERDATA:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};
export const CreateFolder = async (login_tockin, foldername, folderid, root, Bucket_id, company_id, Bucket_idold) => {
  try {
    const response = await axios.post(`${API_URL}files/create-folder`, {
      login_tockin,
      foldername,
      folderid,
      root,
      Bucket_id,
      company_id,
      Bucket_idold
    },{
      headers, // Attach the headers to the request
    });
    console.log('CreateFolder:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

export const DeleteFolder = async (login_tockin, folderid) => {
  try {
    const response = await axios.post(`${API_URL}files/delete-folder`, {
      login_tockin,
      folderid
    },{
      headers, // Attach the headers to the request
    });
    console.log('delete-folderr:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

export const Createacct = async (whmIP, whmUsername, whmPassword, whmHost, domain, username, password, newPlan, email) => {
  console.log('Createacct :', whmIP, whmUsername, whmPassword, whmHost, domain, username, password, newPlan, email);
  try {
    const response = await axios.post(`${API_URL}cpanel/createacct`, {
      whmIP, whmUsername, whmPassword, whmHost, domain, username, password, newPlan, email
    },{
      headers, // Attach the headers to the request
    });
    console.log('Createacct:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

export const sendEmail = async (smtpConfig, email, subject, text) => {
  console.log('sendEmail:', smtpConfig, email, subject, text);
  try {
    const response = await axios.post(`${API_URL}email/send-email`, {
        smtpConfig, email, subject, text,
      },{
        headers: headers,
      }
    );
    console.log('sendEmail:', response);
    const result = await response.data;
    return result;
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export const createemail = async (email, password) => {
  try {
    
    const response = await axios.post(`${API_URL}email/create-email`, {
      email, password
    },{
      headers: headers, // Attach the headers to the request
    });
    console.log('createemail :', response.data.email);
    return response.data.email;
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export const uploadFilelink = async (userId, root, status, urllink) => {
  try {
    
    const response = await axios.post(`${API_URL}files/uploadslink`, {
      userId, root, status, urllink
    },{
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export const suspendCpanelAccount = async (whmHost, whmUsername, whmPassword, username, reason) => {
  try {
    const response = await axios.post(`${API_URL}cpanel/suspend-account`, {
      whmHost, whmUsername, whmPassword, username, reason
    },{
      headers, // Attach the headers to the request
    });
    console.log('Createacct:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

export const fetchFolderContents = async (folderName, loginToken) => {
  try {
    const response = await fetch(`${API_URL}files/cloud-storage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`, // If your API requires authorization
      },
      body: JSON.stringify({
        foldername: folderName,
        login_tockin: loginToken
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching folder contents:', error);
    throw error;
  }
};

export const fetchPaginatedItems = async (pageNumber, loginToken) => {
  try {
    const response = await fetch(`${API_URL}files/cloud-storage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginToken}`,
      },
      body: JSON.stringify({
        page: pageNumber,
        login_tockin: loginToken
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('FILE DATA GET :', data);  // Note that we log `data` here instead of `response.data`
    return data.items;
  } catch (error) {
    console.error('Error fetching page:', error);
    throw error;
  }
};


export const addNewBranch = async (branchData) => {
  try {
    const response = await axios.post(`${API_URL}hrm/branches`, branchData ,{
      headers, // Attach the headers to the request
    });
    return response.data;
  } catch (error) {
    console.error('Error adding new branch:', error);
    throw error; // Rethrow the error to handle it in the component
  }
};


export const createDatabase = async (dbName, user_id, company_id) => {
  console.log('Received data for database creation:', dbName, user_id, company_id);

  const response = await axios.post(`${API_URL}realtime/createDatabase`, {
    dbName: dbName,
    user_id: user_id,
    company_id: company_id
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const createDatabaseUser = async (dbUser, dbPassword, user_id, company_id) => {
  console.log('createDatabaseUser:', dbUser, dbPassword);

  const response = await axios.post(`${API_URL}realtime/createDatabaseUser`, {
    dbUser: dbUser,
    dbPassword: dbPassword,
    user_id: user_id,
    company_id: company_id
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const connectUserToDatabase = async (dbName, dbUser, privileges, dbUser_id, dbName_id) => {
  console.log('connectUserToDatabase:', dbName, dbUser, privileges, dbUser_id, dbName_id);

  const response = await axios.post(`${API_URL}realtime/connectUserToDatabase`, {
    dbName: dbName,
    dbUser: dbUser,
    privileges: privileges,
    dbUser_id: dbUser_id,
    dbName_id: dbName_id
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const changeDatabaseUserPassword = async (newPassword, db_username, id) => {
  console.log('changeDatabaseUserPassword:', newPassword, db_username, id);

  const response = await axios.post(`${API_URL}realtime/changeDatabaseUserPassword`, {
    dbUser: db_username,
    newPassword: newPassword,
    id: id
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const getuserdata = async (datatype) => {
  try {
    // Validate environment variable
    const tableSchema = database_id;
    if (!tableSchema) {
      console.error('Environment variable REACT_APP_TABLE_SCHEMA is missing.');
      return null;
    }

    // Validate datatype
    if (!datatype) {
      console.error('Datatype is required.');
      return null;
    }

    // Get token from localStorage
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found. Please log in again.');
      return null;
    }

    // Fetch user details from the database
    const conditionsUsers = { login_tockin: token };
    const userdata = await oneclick.read(tableSchema, "users", conditionsUsers, "", "");

    if (!userdata.success || !userdata.data || userdata.data.length === 0) {
      console.error('Failed to fetch user data or no user data found.');
      return null;
    }

    // Extract user details
    const useralldata = userdata.data[0] || {};

    if (!useralldata.id || !useralldata.company_id) {
      console.error('Invalid user data. Please log in again.');
      return null;
    }

    // Validate and return the requested data
    if (datatype in useralldata) {
      return useralldata[datatype];
    } else {
      console.warn(`Datatype "${datatype}" not found in user data.`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};



export const deleteUser = async (db_username, id) => {
  console.log('deleteUser:', db_username, id);

  const response = await axios.post(`${API_URL}realtime/deleteUser`, {
    dbUser: db_username,
    id: id
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const ConnectDatabasecheck = async (ip, username, password, dbName) => {
  const response = await axios.post(`${API_URL}realtime/test-connection`, {
    ip: ip,
    username: username,
    password: password,
    dbName: dbName
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const getAllTables = async (ip, username, password, dbName, page, pageSize, search) => {
  const response = await axios.post(`${API_URL}realtime/getAllTables`, {
    ip,
    username,
    password,
    dbName,
    page,
    pageSize,
    search
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const bulkEmptyOrDropTables = async (ip, username, password, dbName, tables, action) => {
  try {
    // Send POST request to the backend API
    const response = await axios.post(`${API_URL}realtime/bulkEmptyOrDropTables`, {
      ip,
      username,
      password,
      dbName,
      tables,
      action
    },{
      headers, // Attach the headers to the request
    });
    // Return the API response data
    return response.data;
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error making API call:', error);
    throw error;  // You can also return a custom error message if needed
  }
};

export const exportDatabase = async (ip, username, password, dbName, tableName) => {
  try {
    // Send POST request to the backend API
    const response = await axios.post(`${API_URL}realtime/exportDatabase`, {
      ip, username, password, dbName, tableName
    },{
      headers, // Attach the headers to the request
    });
    // Return the API response data
    return response.data;
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error making API call:', error);
    throw error;  // You can also return a custom error message if needed
  }
};


export const createTable = async (ip, username, password, dbName, tableName, columns, addColumn ) => {
  try {
    // Send POST request to the backend API
    const response = await axios.post(`${API_URL}realtime/createTable`, {
      ip,
      username,
      password,
      database: dbName,
      tableName,
      columns,
      addColumn
    },{
      headers, // Attach the headers to the request
    });
    // Return the API response data
    return response.data;
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('Error making API call:', error);
    throw error;  // You can also return a custom error message if needed
  }
};

export const Tablesempty = async (ip, username, password, dbName, tableName) => {
  const response = await axios.post(`${API_URL}realtime/empty`, {
    ip,
    username,
    password,
    dbName,
    tableName
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const Tablesdrop = async (ip, username, password, dbName, tableName) => {
  const response = await axios.post(`${API_URL}realtime/drop`, {
    ip,
    username,
    password,
    dbName,
    tableName
  },{
    headers, // Attach the headers to the request
  });
  return response.data;
};

export const importdatabase = async (ip, username, password, dbName, sqlCode, tablename) => {

  // Append the necessary fields to the FormData object
  try {
    // Send the POST request with the FormData object and appropriate headers
    const response = await axios.post(`${API_URL}realtime/import-database`, {
      ip, username, password, dbName, sqlCode, tablename
    },{
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error importing database:', error);
    throw error; // Rethrow the error or handle it as needed
  }
};


export const sendSQLFileContent = async (database_file) => {
  const formData = new FormData();

  formData.append('database_file', database_file); // Attach the file here

  try {
    // Send the POST request with the FormData object and appropriate headers
    const response = await axios.post(`${API_URL}realtime/sendSQLFileContent`, formData, {
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error importing database:', error);
    throw error; // Rethrow the error or handle it as needed
  }
};


//getTableColumns

export const getTableColumns = async (ip, username, password, database, tableName, columnName, searchValue, page, pageSize, orderby) => {

  // Append the necessary fields to the FormData object
  console.log('getTableColumns:', ip, username, password, database, tableName);
  try {
    // Send the POST request with the FormData object and appropriate headers
    const response = await axios.post(`${API_URL}realtime/getTableColumns`, {
      ip, username, password, database, tableName, columnName, searchValue, page, pageSize, orderby
    },{
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error importing database:', error);
    throw error; // Rethrow the error or handle it as needed
  }
};


export const updateColumn = async (ip, username, password, database, tableName, columnName, rowId, newValue) => {

  try {
    // Send the POST request with the FormData object and appropriate headers
    const response = await axios.post(`${API_URL}realtime/updateColumn`, {
      ip, username, password, database, tableName, columnName, rowId, newValue
    },{
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error importing database:', error);
    throw error; // Rethrow the error or handle it as needed
  }
};


export const bulkDeleteRows = async (ip, username, password, dbname, tablename, rowsIds) => {

  try {
    // Send the POST request with the FormData object and appropriate headers
    const response = await axios.post(`${API_URL}realtime/bulkDeleteRows`, {
      ip, username, password, dbname, tablename, rowsIds
    },{
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error importing database:', error);
    throw error; // Rethrow the error or handle it as needed
  }
};


export const updateRecord = async (ip, username, password, dbName, tablename, rowid, formData) => {

  try {
    // Send the POST request with the FormData object and appropriate headers
    const response = await axios.post(`${API_URL}realtime/updateRecord`, {
      ip, username, password, dbName, tablename, rowid, formData
    },{
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error importing database:', error);
    throw error; // Rethrow the error or handle it as needed
  }
};


export const insertRecord  = async (ip, username, password, dbName, tablename, data) => {

  try {
    // Send the POST request with the FormData object and appropriate headers
    const response = await axios.post(`${API_URL}realtime/insertRecord`, {
      ip, username, password, dbName, tablename, data
    },{
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error importing database:', error);
    throw error; // Rethrow the error or handle it as needed
  }
};

export const executeSql  = async (ip, username, password, dbName, sqlCode) => {

  try {
    // Send the POST request with the FormData object and appropriate headers
    const response = await axios.post(`${API_URL}realtime/executeSql`, {
      ip, username, password, dbName, sqlCode
    },{
      headers, // Attach the headers to the request
    });

    return response.data;
  } catch (error) {
    console.error('Error importing database:', error);
    throw error; // Rethrow the error or handle it as needed
  }
};


export const checkSubdomain = async (subdomain ) => {
  try {
    const response = await axios.get(`${API_URL}subdomainRoutes/check-subdomain`, {
      params: {
        subdomain: subdomain
      },
      headers: headers, // Attach headers to the request
    });
    
    console.log('Subdomain check response:', response.data);
    return response.data;  // Return the response data, which should contain success, message, etc.
  } catch (error) {
    console.error('Error fetching subdomain data:', error);

    // Error handling, you can modify this as per your needs
    if (error.response) {
      // Server responded with an error
      console.error('Response error:', error.response.data);
      return { success: false, message: 'Failed to check subdomain, please try again later.' };
    } else if (error.request) {
      // Request was made but no response received
      console.error('Request error:', error.request);
      return { success: false, message: 'No response received from server.' };
    } else {
      // Other errors
      console.error('General error:', error.message);
      return { success: false, message: 'An error occurred while checking the subdomain.' };
    }
  }
};

export const checkdomain = async (domain ) => {
  try {
    const response = await axios.post(`${API_URL}subdomainRoutes/check-domain`, {
      domainname: domain
    },{
      headers: headers,
    }
  );
    
    console.log('Domain check response:', response.data);
    return response.data;  // Return the response data, which should contain success, message, etc.
  } catch (error) {
    console.error('Error fetching Domain data:', error);

    // Error handling, you can modify this as per your needs
    if (error.response) {
      // Server responded with an error
      console.error('Response error:', error.response.data);
      return { success: false, message: 'Failed to check Domain, please try again later.' };
    } else if (error.request) {
      // Request was made but no response received
      console.error('Request error:', error.request);
      return { success: false, message: 'No response received from server.' };
    } else {
      // Other errors
      console.error('General error:', error.message);
      return { success: false, message: 'An error occurred while checking the Domain.' };
    }
  }
};

export const create_subdomain = async (subdomain, rootdomain) => {
  try {
    const response = await axios.post(`${API_URL}subdomainRoutes/create-subdomain`, {
      subdomain: subdomain,
      rootdomain: rootdomain
    },{
      headers: headers,
    }
  );
    
    console.log('Domain check response:', response.data);
    return response.data;  // Return the response data, which should contain success, message, etc.
  } catch (error) {
    console.error('Error fetching Domain data:', error);

    // Error handling, you can modify this as per your needs
    if (error.response) {
      // Server responded with an error
      console.error('Response error:', error.response.data);
      return { success: false, message: 'Failed to check Domain, please try again later.' };
    } else if (error.request) {
      // Request was made but no response received
      console.error('Request error:', error.request);
      return { success: false, message: 'No response received from server.' };
    } else {
      // Other errors
      console.error('General error:', error.message);
      return { success: false, message: 'An error occurred while checking the Domain.' };
    }
  }
};


export const addDomain = async (newDomain) => {
  try {
    const response = await axios.post(`${API_URL}subdomainRoutes/addDomain`, {
      newDomain: newDomain,
    },{
      headers: headers,
    }
  );
    
    console.log('Domain check response:', response.data);
    return response.data;  // Return the response data, which should contain success, message, etc.
  } catch (error) {
    console.error('Error fetching Domain data:', error);

    // Error handling, you can modify this as per your needs
    if (error.response) {
      // Server responded with an error
      console.error('Response error:', error.response.data);
      return { success: false, message: 'Failed to check Domain, please try again later.' };
    } else if (error.request) {
      // Request was made but no response received
      console.error('Request error:', error.request);
      return { success: false, message: 'No response received from server.' };
    } else {
      // Other errors
      console.error('General error:', error.message);
      return { success: false, message: 'An error occurred while checking the Domain.' };
    }
  }
};

