import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { oneclick } from '../../../oneclick';
import { createDatabase } from '../../../services/apiService';
import database_id from '../../../utils/databaseConfig';

const AddDatabaseModal = ({ show, handleClose }) => {
  const [dbName, setDbName] = useState('');
  const [serverOption, setServerOption] = useState('local');
  const [isLoading, setIsLoading] = useState(false);
  const [serverDetails, setServerDetails] = useState({
    serverIp: '',
    serverPort: '3306',
    serverUsername: '',
    serverPassword: '',
  });

  const handleDbNameChange = (e) => {
    setDbName(e.target.value);
  };

  const handleServerOptionChange = (e) => {
    setServerOption(e.target.value);
  };

  const handleServerDetailChange = (e) => {
    const { name, value } = e.target;
    setServerDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (isLoading) return; // Prevent multiple submissions
    setIsLoading(true);
    try {
      // Validate the database name
      if (!dbName) {
        alert('Please enter a database name.');
        setIsLoading(false); // Reset loading state
        return;
      }
  
      // Fetch the table schema and token
      const tableSchema = database_id;
      const token = localStorage.getItem('token');
  
      if (!token) {
        alert('Token not found. Please log in again.');
        setIsLoading(false); // Reset loading state
        return;
      }
  
      // Fetch user details
      const conditionsUsers = { login_tockin: token };
      const userdata = await oneclick.read(tableSchema, "users", conditionsUsers, "", "");
      const { id: user_id, company_id } = userdata.data[0] || {};
  
      // Validate user_id and company_id
      if (!user_id) {
        alert('User not found. Please log in again.');
        setIsLoading(false); // Reset loading state
        return;
      }
      if (!company_id) {
        alert('User company_id not found. Please log in again.');
        setIsLoading(false); // Reset loading state
        return;
      }
  
      // Log data being sent for debugging
      console.log('Sending data to create database:', { dbName, user_id, company_id });
  
      // Call createDatabase API
      const create = await createDatabase(dbName, user_id, company_id);
  
      // Handle API response
      if (create.success) {
        setIsLoading(false); // Reset loading state
        alert(create.message);
        setDbName('');
        handleClose(); // Close the modal after submitting
      } else {
        alert(create.error || 'An error occurred while creating the database.');
      }
    } catch (error) {
      setIsLoading(false); // Reset loading state
      // Catch unexpected errors
      console.error('Error in handleSubmit:', error);
  
      if (error.response) {
        alert(`Server Error: ${error.response.data?.message || error.response.statusText}`);
      } else if (error.request) {
        alert('No response from server. Please check your connection.');
      } else {
        alert(`Unexpected Error: ${error.message}`);
      }
    }
  };
  

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Database</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="dbName">
            <Form.Label>Database Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter database name"
              value={dbName}
              onChange={handleDbNameChange}
            />
          </Form.Group>



          {serverOption === 'custom' && (
            <>
              <Form.Group controlId="serverIp">
                <Form.Label>Server IP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter server IP"
                  name="serverIp"
                  value={serverDetails.serverIp}
                  onChange={handleServerDetailChange}
                />
              </Form.Group>

              <Form.Group controlId="serverPort">
                <Form.Label>Server Port</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter server port"
                  name="serverPort"
                  value={serverDetails.serverPort}
                  onChange={handleServerDetailChange}
                />
              </Form.Group>

              <Form.Group controlId="serverUsername">
                <Form.Label>DB Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter server username"
                  name="serverUsername"
                  value={serverDetails.serverUsername}
                  onChange={handleServerDetailChange}
                />
              </Form.Group>

              <Form.Group controlId="serverPassword">
                <Form.Label>DB Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter server password"
                  name="serverPassword"
                  value={serverDetails.serverPassword}
                  onChange={handleServerDetailChange}
                />
              </Form.Group>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create Database
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDatabaseModal;
