import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, Link } from 'react-router-dom';
import ImageComponent from "../../utils/ImageComponent";
import Sidebar from "./Components/Sidebar";
import { decodeBase64, getuserdata, encodeBase64, OpenAIResponse } from "../../services/apiService";
import { oneclick } from "../../oneclick";
import database_id from "../../utils/databaseConfig";
import CodeEditor from "../../utils/CodeEditorNew";
import { fetchOpenAIResponse } from "../../utils/OpenAIResponse";
import { a } from "react-spring";
import { set } from "date-fns";
import Ai_ResponceChat from "./Components/Chating/Ai_ResponceChat";
import ChatContainer from "./Components/ChatContainer";

const ChatWithAi = () => {
    const location = useLocation();
    const [userprofile, setUserprofile] = useState({});
    const [profile_image, setProfile_image] = useState('');
    const { staffid, chatingid, screen } = useParams(); // Retrieve the URL parameter
    const [chatId, setChatid] = useState('');
    const [typing, setTyping] = useState(false);
    const [showmenu, setShowmenu] = useState(false);
    const [messages, setMessages] = useState([]); // Store messages
    const [newMessage, setNewMessage] = useState(""); // Track new message
    const [staffdata, setStaffdata] = useState({}); // Store staff data
    const [isLoading, setIsLoading] = useState(false); // Added loading state
    const chatContainerRef = useRef(null); // Create a reference to the chat container
    const [currentPage, setCurrentPage] = useState(1); // Track current page for pagination
    const [scrollTop, setScrollTop] = useState(0); // State to track the scroll top position

    // Handle message input change
    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    // Fetch staff data from API
    const getStaffData = async () => {
        setUserprofile(await getuserdata());
        setProfile_image(await getuserdata('profile_image'));
        try {
            const tableSchema = database_id;
            const tableName = "ai_staff";
            const conditions = { id: decodeBase64(staffid), company_id: await getuserdata("company_id") };
            const orderBy = "id DESC";
            const pagination = { page: 1, limit: 10 };

            const response = await oneclick.read(
                tableSchema,
                tableName,
                conditions,
                orderBy,
                "",
                pagination,
                {}
            );

            if (!response || !response.success || !response.data || response.data.length === 0) {
                throw new Error("No staff data found.");
            }

            setStaffdata(response.data[0]);
        } catch (error) {
            console.error("Error in getStaffData:", error);
            alert(`Failed to fetch staff data: ${error.message}`);
        }
    };

    useEffect(() => {
        getStaffData();
        console.log('chatingid :', chatingid);
        if (chatingid == 'false') {

        } else {
            setChatid(decodeBase64(chatingid));
            const newchatid = decodeBase64(chatingid);
            console.log('newchatid :', newchatid);
            setChatdataload({ newchatid });
        }
    }, []);

    useEffect(() => {
        console.log('chatContainerRef :', chatContainerRef.current);
        if (chatContainerRef.current) {
            const container = chatContainerRef.current;

            const handleScroll = () => {
                console.log("Scrolling... :", container.scrollTop);
                const top = container.scrollTop;
                const previousHeight = 400 - container.scrollHeight;

                console.log("Scrolling... top:", container.scrollTop, '-', previousHeight);
                if (container.scrollTop === previousHeight) {
                    console.log("Scrolled to the top. Loading more data...");
                    loadOlderMessages(); // Your function to load older messages
                }
            };

            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [chatContainerRef.current]);


    const loadOlderMessages = async () => {
        if (isLoading) return; // Prevent duplicate calls
        console.log("Loading older messages...");
        
        try {
            setIsLoading(true); // Set loading state
    
            const nextPage = currentPage + 1;
    
            // Save the current scroll position and height
            const container = chatContainerRef.current;
            const previousScrollHeight = container.scrollHeight;
            const previousScrollTop = container.scrollTop;
    
            // Fetch older messages
            const tableSchema = database_id;
            const tableName = "ai_staff_chat_history";
            const conditions = { chat_id: chatId };
            const orderBy = "id DESC";
            const pagination = { page: nextPage, limit: 200 };
    
            const response = await oneclick.read(
                tableSchema,
                tableName,
                conditions,
                orderBy,
                "",
                pagination,
                {}
            );
    
            if (!response || !response.success || response.data.length === 0) {
                console.log("No more chat data available.");
                return;
            }
    
            const newMessages = response.data.reverse(); // Reverse the data if required
            setMessages((prevMessages) => [...newMessages, ...prevMessages]); // Append older messages
    
            setCurrentPage(nextPage);
    
            // Restore the scroll position
            setTimeout(() => {
                const newScrollHeight = container.scrollHeight;
                container.scrollTop = previousScrollTop + (newScrollHeight - previousScrollHeight);
            }, 0); // Allow DOM to update before restoring
        } catch (error) {
            console.error("Error loading older messages:", error);
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };
    

    const setChatdataload = async ({ id }) => {
        console.log('setChatdataload id :', id);
        try {
            setMessages([]);
            if (chatContainerRef.current) {
                // Reset the scroll position to the top
                chatContainerRef.current.scrollTop = 0;
            }
            const tableSchema = database_id;
            const tableName = "ai_staff_chat_history";
            const conditions = { chat_id: id };
            const orderBy = "id DESC";
            const pagination = { page: currentPage, limit: 200 };

            const response = await oneclick.read(
                tableSchema,
                tableName,
                conditions,
                orderBy,
                "",
                pagination,
                {}

            );
            if (!response || !response.success) {
                throw new Error("No chat data found.");
            }

            const reversedData = response.data.reverse();
            setMessages(reversedData); // Update messages with reversed data
        } catch (error) {
            console.error("Error in getChatData:", error);
        }
    };

    // Handle sending a message
    const handleSendMessage = async () => {
        if (!newMessage.trim()) return; // Prevent sending empty messages

        const currentTime = new Date().toLocaleString();
        const userMessage = newMessage.trim();

        // Optimistically update UI with the user's message
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: userMessage, sender: 'user', time: currentTime }
        ]);
        setIsLoading(true); // Show loading state

        try {
            let chatHistoryData = {};
            if (chatId === '') {
                const chat_id = await saveStaffChat(userMessage); // Save staff chat if no existing chat
                saveChatHistory(chat_id);
            } else {
                saveChatHistory("");
            }


            setTyping(true); // Show typing indicator

            // Handle AI response
            await handleResponse(userMessage, currentTime);
        } catch (error) {
            console.error('Error in handleSendMessage:', error);
            alert(`Error: ${error.message}`);
        } finally {
            setNewMessage('');
            setIsLoading(false); // Hide loading state
            setTyping(false); // Hide typing indicator
        }
    };

    // Save staff chat
    const saveStaffChat = async (message) => {
        try {
            const tableSchema = database_id;
            const tableName = 'ai_staff_chat';
            const staffChatData = {
                sender_id: await getuserdata('id'),
                receiver_id: staffdata.id,
                message: message
            };
            const response = await oneclick.create(tableSchema, tableName, staffChatData, "");

            console.log('Staff Chat Response:', response);

            if (!response.success) {
                throw new Error('Failed to save staff chat');
            } else {
                setChatid(response.insertedData.id); // Set chat ID for future messages
                return response.insertedData.id;
            }
        } catch (error) {
            console.error('Error saving staff chat:', error);
            alert(`Error saving staff chat: ${error.message}`);
        }
    };

    // Save chat history
    const saveChatHistory = async (id) => {
        try {
            const tableSchema = database_id;
            const tableName = 'ai_staff_chat_history';
            const userMessage = newMessage.trim();
            const currentTime = new Date().toLocaleString();

            const chatHistoryData = {
                chat_id: id == "" ? chatId : id,
                sender_id: await getuserdata('id'),
                sender: 'user',
                receiver_id: staffdata.id,
                message: userMessage,
                text: userMessage,
                status: 'sent',
                time: currentTime
            };
            const response = await oneclick.create(tableSchema, tableName, chatHistoryData, "");

            if (!response.success) {
                throw new Error('Failed to save chat history');
            } else {

            }
        } catch (error) {
            console.error('Error saving chat history:', error);
            alert(`Error saving chat history: ${error.message}`);
        }
    };

    const saveChataiHistory = async (id, text) => {
        try {
            const tableSchema = database_id;
            const tableName = 'ai_staff_chat_history';
            const userMessage = newMessage.trim();
            const currentTime = new Date().toLocaleString();

            const chatHistoryData = {
                chat_id: id == "" ? chatId : id,
                sender_id: staffdata.id,
                sender: `${staffdata.first_name} ${staffdata.last_name}`,
                receiver_id: await getuserdata('id'),
                message: text,
                text: text,
                status: 'sent',
                time: currentTime
            };
            const response = await oneclick.create(tableSchema, tableName, chatHistoryData, "");

            if (!response.success) {
                throw new Error('Failed to save chat history');
            } else {

            }
        } catch (error) {
            console.error('Error saving chat history:', error);
            alert(`Error saving chat history: ${error.message}`);
        }
    };



    // Handle AI response
    const handleResponse = async (userMessage, currentTime) => {
        if (staffdata.status === "offline") {
            const offlineMessage = "Sorry, I am currently offline. Please try again later.";
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: offlineMessage, sender: `${staffdata.first_name} ${staffdata.last_name}`, time: currentTime }
            ]);
            saveChataiHistory(chatId, offlineMessage);
            return;
        }

        const dbResponse = await fetchDatabase(userMessage);
        if (dbResponse) {
            const dbTime = new Date().toLocaleString();
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: dbResponse, sender: "database", time: dbTime }
            ]);
            saveChataiHistory(chatId, dbResponse);
            return; // Exit if database provides a response
        }

        try {
            const user_id = await getuserdata('id');
            const aiResponse = await OpenAIResponse(userMessage, staffdata.id, user_id, "", "", false);
            const aiTime = new Date().toLocaleString();
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: aiResponse.reply, sender: "ai", time: aiTime }
            ]);
            saveChataiHistory(chatId, aiResponse.reply);
        } catch (error) {
            console.error("Error communicating with OpenAI:", error);
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: "There was an issue processing your request. Please try again later.", sender: "ai", time: currentTime }
            ]);
            saveChataiHistory(chatId, "There was an issue processing your request. Please try again later.");
        }
    };

    // Fetch data from the database
    const fetchDatabase = async (query) => {
        const apiUrl = "https://your-backend-api.com/query"; // Replace with your backend API URL
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ query }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            return data && data.result ? data.result : null;
        } catch (error) {
            console.error("Error querying database:", error);
            return null;
        }
    };

    // // Fetch response from OpenAI API
    // const fetchOpenAIResponse = async (userMessage) => {
    //     const apiKey = process.env.REACT_APP_OPENAI_APIKEY; // Replace with your OpenAI API key
    //     const response = await fetch("https://api.openai.com/v1/chat/completions", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${apiKey}`,
    //         },
    //         body: JSON.stringify({
    //             model: "gpt-3.5-turbo",
    //             messages: [
    //                 {
    //                     role: "system",
    //                     content: "You are a programming assistant. Provide coding examples in languages like PHP, React.js, Python, etc., based on user queries.",
    //                 },
    //                 { role: "user", content: userMessage },
    //             ],
    //             max_tokens: 300, // Increased token limit for code
    //             temperature: 0.7, // Adjust for creativity
    //             top_p: 1,
    //             n: 1,
    //             stop: null,
    //         }),
    //     });

    //     const data = await response.json();
    //     if (data.choices && data.choices[0] && data.choices[0].message) {
    //         return data.choices[0].message.content.trim();
    //     } else {
    //         throw new Error("No response from OpenAI");
    //     }
    // };

    const [isFullscreen, setIsFullscreen] = useState(false);

    // Fullscreen Toggle Function
    const toggleFullScreen = () => {
        const elem = document.documentElement; // Fullscreen for the entire page

        if (!document.fullscreenElement) {
            // Request fullscreen
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { // Firefox
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { // IE/Edge
                elem.msRequestFullscreen();
            }
        } else {
            // Exit fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        }
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center col-lg-12">
                                <div className="col-lg-6 col-md-6 col-sm-6 d-flex align-items-center">
                                    <div style={{ padding: '0px', width: '50px', marginRight: '5px' }}>
                                        <ImageComponent
                                            fileid={staffdata.profile_image}
                                            width={50}
                                            height={50}
                                            borderRadius={50}
                                            boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                        />
                                    </div>
                                    <div>
                                        <h4 className="card-title mb-0">{staffdata.first_name} {staffdata.last_name}</h4>
                                        {typing ? (
                                            <p className="mb-0">typing...</p>
                                        ) : staffdata.status === "offline" ? (
                                            <p className="mb-0">
                                                <span
                                                    style={{
                                                        display: "inline-block",
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor: "red",
                                                        borderRadius: "50%",
                                                        marginRight: "5px",
                                                    }}
                                                ></span>
                                                Offline
                                            </p>
                                        ) : (
                                            <p className="mb-0">
                                                <span
                                                    style={{
                                                        display: "inline-block",
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor: "green",
                                                        borderRadius: "50%",
                                                        marginRight: "5px",
                                                    }}
                                                ></span>
                                                Online
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 text-end">
                                    <div className="dropdown" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                        <Link to={`/ai/${screen == 'full' ? 'chatfullscreen' : 'chat'}/${staffid}/${screen == 'full' ? 'meni' : 'full'}/${encodeBase64(chatId)}`} className="btn btn-light" style={{ marginRight: '10px' }}>
                                            {screen == 'full' ? (
                                                <i className="bi bi-arrows-fullscreen"></i>
                                            ) : (
                                                <i className="bi bi-fullscreen-exit"></i>
                                            )}

                                        </Link>
                                        <button
                                            className="btn btn-light"
                                            type="button"
                                            id="dropdownMenuButton"
                                            onClick={() => setShowmenu(!showmenu)}
                                            aria-expanded="false"
                                        >
                                            <i className="bi bi-three-dots-vertical"></i>
                                        </button>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton"
                                            style={{ display: showmenu ? 'block' : 'none', marginTop: '60px' }}
                                        >
                                            <li><a className="dropdown-item" href="#">View Profile</a></li>
                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                            <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="card-filter" style={{ padding: '14px 20px' }}>
                            <div className="col-lg-12 d-flex">
                                <div className="col-lg-3">
                                    <div className="col-lg-12">
                                        <h6>Chat History</h6>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="col-lg-12">
                                        <h6>Chating..</h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="card-body px-0" style={{ padding: '14px 20px' }}>
                            <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                                <div className="col-lg-3 hide-on-mobile">
                                    <Sidebar staffid={staffid} setChatid={setChatid} setChatdataload={setChatdataload} />
                                </div>
                                <ChatContainer messages={messages} profile_image={profile_image} staffdata={staffdata} newMessage={newMessage} handleInputChange={handleInputChange} handleSendMessage={handleSendMessage} loadOlderMessages={loadOlderMessages} typing={typing}/>
                                {/* <div className="col-lg-9 col-md-12 col-sm-12" style={{ paddingRight: '15px' }}>
                                    <div className="" style={{ background: 'white', borderRadius: '5px' }} >
                                        {messages.length === 0 ? (
                                            <div className="d-flex justify-content-center align-items-center col-lg-12" style={{ height: '100%', minHeight: '400px', width: '100%' }}>
                                                <div className="text-center chat-containeremty">
                                                    <p className="text-muted">What can I help with?</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="messages chat-container" style={{ minHeight: '400px', display: 'flex' }} ref={chatContainerRef}>
                                                <div class="content">

                                                    {messages.map((message, index) => (
                                                        message.sender === "user" ? (
                                                            <div key={index} className={`messageuser col-lg-12 col-md-12 col-sm-12`}>
                                                                <div className="d-flex userchat " style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                                    <div className="col-lg-1 col-md-1 col-sm-1" style={{ width: '50px', height: '50px' }}>
                                                                        {profile_image ? (
                                                                            <ImageComponent
                                                                                fileid={profile_image || ''}
                                                                                width={50}
                                                                                height={50}
                                                                                borderRadius={50}
                                                                                boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                                                            />
                                                                        ) : (
                                                                            <img src="/assets/file/images/user/profile/6388307.png" alt="user" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                                                        )}

                                                                    </div>
                                                                    <div className="col-lg-10 col-md-10 col-sm-10">
                                                                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                                            <div className="message-bubbleuser">
                                                                                {message.text}
                                                                            </div>
                                                                        </div>
                                                                        <p style={{ textAlign: 'end' }}>{message.time}</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        ) : (

                                                            <Ai_ResponceChat message={message} staffdata={staffdata} index={index} />  // Ai Responce Chat Component
                                                        )
                                                    ))}
                                                    {typing && (
                                                        <div className="message typing">

                                                            <div className="d-flex senderchat col-lg-11">
                                                                <div style={{ width: '50px', height: '50px' }}>
                                                                    <ImageComponent
                                                                        fileid={staffdata.profile_image}
                                                                        width={50}
                                                                        height={50}
                                                                        borderRadius={50}
                                                                        boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-10">
                                                                    <div>
                                                                        <div className="message-bubblesender">
                                                                            <p>Typing...</p>
                                                                        </div>
                                                                    </div>
                                                                    <p style={{ marginLeft: '25px', textAlign: 'left' }}>{new Date().toLocaleString()}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        )}


                                    </div>
                                    <div className="chat-input d-flex" style={{ alignItems: 'center' }}>
                                        <input
                                            type="text"
                                            value={newMessage}
                                            onChange={handleInputChange}
                                            placeholder="Type a message..."
                                            className="form-control"
                                            style={{
                                                borderRadius: '20px',
                                                padding: '10px',
                                                border: '1px solid #ccc',
                                                outline: 'none',
                                                marginRight: '10px',
                                            }}
                                        />
                                        <button
                                            onClick={handleSendMessage}
                                            className="btn btn-primary"
                                            style={{
                                                borderRadius: '50%',
                                                padding: '10px 15px',
                                                backgroundColor: '#4CAF50',
                                                color: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                                transition: 'background-color 0.3s',
                                            }}
                                            onMouseEnter={(e) => e.target.style.backgroundColor = '#45a049'}
                                            onMouseLeave={(e) => e.target.style.backgroundColor = '#4CAF50'}
                                        >
                                            <i className="fa fa-paper-plane" style={{ fontSize: '18px' }}></i>
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default ChatWithAi;