import React, { useEffect } from 'react';
import apiUrl from '../apiConfig';

const RazorpayPayment = ({ paymentDetails, onPaymentCallback }) => {
    const handlePayment = () => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                key: 'rzp_test_KQuQ0EyrEIylvV', // Replace with your Razorpay key ID
                amount: paymentDetails.amount * 100, // Razorpay requires amount in paise
                currency: "INR",
                name: "OneKlicks",
                description: "Cloud Hosting",
                image: `${apiUrl}/assets/file/images/logoIcon/667cfa61cbda01719466593.png`, // Logo URL
                handler: function (response) {
                    // Notify success and send response back
                    onPaymentCallback(true, response);
                    alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
                },
                modal: {
                    onDismiss: () => {
                        // Notify cancellation and send empty response
                        onPaymentCallback(false, {});
                        alert('Payment was not completed. Your account status is pending.');
                    },
                },
                prefill: {
                    name: paymentDetails.username,
                    email: paymentDetails.email,
                },
                theme: {
                    color: "#3399cc",
                },
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        };

        script.onerror = () => {
            alert("Failed to load Razorpay SDK. Please try again.");
        };
    };

    useEffect(() => {
        handlePayment(); // Start payment process on mount
    }, []); // Only run once

    return null; // Do not render anything since payment happens automatically
};

export default RazorpayPayment;
