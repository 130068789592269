import React, { useEffect, useState } from 'react';
import { oneclick } from '../oneclick';
import database_id from '../utils/databaseConfig';

const BuyOrRenewPlans = () => {
    const [userdata, setUserdata] = useState(null); // Initialize to null for better loading check
    const loginToken = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            const tableSchema = database_id;
            const tableName = "users";
            const conditions = {
                "login_tockin": loginToken, // Using the loginToken stored in localStorage
            };
            const orderBy = "created_at DESC"; // Uncomment if needed

            try {
                // Correctly passing the parameters to the read function
                const response = await oneclick.read(tableSchema, tableName, conditions, orderBy);
                console.log("User data fetched:", response);
                setUserdata(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        // Check if loginToken exists before fetching data
        if (loginToken) {
            fetchData();
        } else {
            console.warn('No login token found. User is not logged in.');
        }
    }, [loginToken]); // Dependency on loginToken

    return (
        <div>
            <h1>Buy or Renew Plans</h1>
            {userdata ? (
                <div>
                    <p>Welcome, {userdata.name || 'User'}!</p>
                    <p>Your current plan: {userdata.plan || 'No plan assigned'}</p>
                    {/* Add other user data fields here */}
                </div>
            ) : (
                <p>Loading user data...</p>
            )}
        </div>
    );
};

export default BuyOrRenewPlans;