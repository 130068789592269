import React, { useState, useEffect } from 'react';
import CreateGroupModal from './Components/CreateGroupModal';
import UpdateGroupModal from './Components/UpdateGroupModal';
import DeleteGroupModal from './Components/DeleteGroupModal';
import BulkActionModal from './Components/BulkActionModal';
import { getuserdata } from '../../services/apiService';
import { oneclick } from '../../oneclick';
import { Link } from 'react-router-dom';
import ActionButton from '../../utils/ActionButton';
import database_id from '../../utils/databaseConfig';

const ManageContactGroups = () => {
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isUpdateGroupModalOpen, setIsUpdateGroupModalOpen] = useState(false);
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false);

  const [filteredContacts, setFilteredContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage, setContactsPerPage] = useState(10); // Default to 10 contacts per page
  const [totalContacts, setTotalContacts] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('0'); // '0' for All
  const [allChecked, setAllChecked] = useState(false);

  const [Checkedrows, setCheckedrows] = useState([]); // Store selected row IDs


  const [selectedrow, setSelectedrow] = useState([]);

  const fetchData = async () => {
    const tableSchema = database_id;
    const tableName = 'groups';

    let condition;
    if (statusFilter === '0') {
      condition = { company_id: await getuserdata('company_id') };
    } else {
      condition = { company_id: await getuserdata('company_id'), status: statusFilter };
    }

    const search = { name: searchTerm };
    const pagination = { page: currentPage, limit: contactsPerPage };

    const response = await oneclick.read(
      tableSchema,
      tableName,
      condition,
      'id DESC',
      search,
      pagination,
      ''
    );

    setFilteredContacts(response.data);
    setTotalContacts(response.totalCount);
  };

  useEffect(() => {
    fetchData();
    console.log('Updated Checkedrows:', Checkedrows);
  }, [searchTerm, statusFilter, currentPage, contactsPerPage]);

  // Log Checkedrows after the state has been updated
  useEffect(() => {
    console.log('Checkedrows:', Checkedrows);
  }, [Checkedrows]);  // This will log whenever Checkedrows is updated

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleStatusFilter = (e) => setStatusFilter(e.target.value);

  const handleRowCheck = (id) => {
    // Update filteredContacts state
    const updatedRows = filteredContacts.map(row =>
      row.id === id ? { ...row, isChecked: !row.isChecked } : row
    );
    setFilteredContacts(updatedRows);

    // Handle Checkedrows state based on whether the checkbox is checked or unchecked
    if (updatedRows.find(row => row.id === id).isChecked) {
      // Add the ID to Checkedrows if the row is checked
      setCheckedrows(prevChecked => [...prevChecked, id]);
    } else {
      // Remove the ID from Checkedrows if the row is unchecked
      setCheckedrows(prevChecked => prevChecked.filter(checkedId => checkedId !== id));
    }

    // Check if all rows are checked to update 'Select All' checkbox state
    setAllChecked(updatedRows.every(row => row.isChecked));
  };

  const handleSelectAll = () => {
    // Toggle the allChecked state
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    // Update the rows' isChecked based on the new allChecked state
    const updatedRows = filteredContacts.map(row => ({ ...row, isChecked: newCheckedState }));
    setFilteredContacts(updatedRows);

    // Update Checkedrows state based on the new allChecked state
    if (newCheckedState) {
      // Select all row IDs if all are checked
      const rowsall = updatedRows.filter(row => row.isChecked).map(row => row.id);
      setCheckedrows(rowsall);
    } else {
      // Deselect all row IDs
      setCheckedrows([]);
    }
  };

  const handleContactsPerPage = (e) => {
    setContactsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to the first page when count changes handeleDeleteGroup
  };

  const handeleediterow = (data) => {
    setIsUpdateGroupModalOpen(true);
    setSelectedrow(data);
  }

  const handeleDeleteGroup = (data) => {
    setIsDeleteGroupModalOpen(true);
    setSelectedrow(data);
  }

  const totalPages = Math.ceil(totalContacts / contactsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="dashboard_container">
      <section>
        <div className="card mb-4">
          <div className="card-header">
            <h4 className="card-title">Manage Contact Groups</h4>
          </div>

          <div className="card-filter">
            <form>
              <div className="filter-form">
                <div className="filter-item col-lg-1">
                  <select
                    name="status"
                    className="form-select"
                    value={statusFilter}
                    onChange={handleStatusFilter}
                  >
                    <option value="0">All</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                  </select>
                </div>
                <div className="filter-item col-lg-1">
                  <select
                    id="contactsPerPage"
                    value={contactsPerPage}
                    onChange={handleContactsPerPage}
                    className="form-select"
                  >
                    <option value="" disabled>Show per page</option>
                    <option value="1">1</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="filter-item ">
                  <input
                    type="text"
                    autoComplete="off"
                    name="search"
                    placeholder="Search with group or contact name"
                    className="form-control"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>

                {Checkedrows.length > 0 ? (
                  <div className="statusUpdateBtn">
                    <a
                      className="i-btn success--btn btn--md bulkAction"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Bulk Actions"
                      onClick={() => setIsBulkActionModalOpen(true)} // Corrected: wrapped in a function
                    >
                      <svg
                        className="svg-inline--fa fa-gear"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="gear"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z"
                        />
                      </svg>
                      Action
                    </a>
                  </div>
                ) : (<></>)}


                <div className="filter-action">
                  <button
                    className="i-btn info--btn btn--md"
                    type="button"
                    onClick={() => setSearchTerm('')}
                  >
                    <svg className="svg-inline--fa fa-magnifying-glass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"></path></svg>
                    Search
                  </button>
                  <button
                    className="i-btn danger--btn btn--md"
                    type="button"
                    onClick={() => {
                      setSearchTerm('');
                      setStatusFilter('0');
                      setCurrentPage(1);
                    }}
                  >
                    <i className="las la-sync"></i>
                    Reset
                  </button>
                  <button
                    className="i-btn primary--btn btn--md text-white"
                    onClick={() => setIsCreateGroupModalOpen(true)}
                    type="button"
                  >
                    <svg className="svg-inline--fa fa-plus" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path></svg>
                    Add New
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <div className="card-body px-0">
        <div className="responsive-table">
          <table>
            <thead>
              <tr>
                <th>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="form-check-input mt-0 me-2"
                      aria-label="Select all rows"
                      checked={allChecked}
                      onChange={handleSelectAll}
                    />
                    <span>Sl No.</span>
                  </div>
                </th>
                <th>Group Name</th>
                <th>Contacts</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredContacts.length > 0 ? (
                filteredContacts.map((contact, index) => (
                  <tr key={contact.id}>
                    <td className="d-none d-sm-flex align-items-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={contact.isChecked}
                        onChange={() => handleRowCheck(contact.id)}
                      />
                      {index + 1 + (currentPage - 1) * contactsPerPage}
                    </td>
                    <td className=" text-capitalize " data-label="Group Name">{contact.name}</td>
                    <td data-label=" Contact" >
                      <Link href="#" className="badge badge--primary p-2">
                        View Contacts (0)
                      </Link>
                    </td>
                    <td data-label="Status">
                      <span
                        className={`badge badge--${contact.status == '1' ? 'success' : 'danger'
                          }`}
                      >
                        {contact.status == '1' ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td data-label="Action">
                      <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                        <button className="i-btn info--btn btn--sm">Info</button>
                        <button
                          className="i-btn primary--btn btn--sm"
                          onClick={() => handeleediterow(contact)}
                        >
                          Edit
                        </button>
                        <button
                          className="i-btn danger--btn btn--sm"
                          onClick={() => handeleDeleteGroup(contact)}
                          // onClick={() => setIsDeleteGroupModalOpen(true)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No contacts found</td>
                </tr>
              )}
            </tbody>
          </table>


          <div className="pagination-container">

            <ul className="pagination">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${currentPage === number ? 'active' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(number)}
                  >
                    {number}
                  </button>
                </li>
              ))}
            </ul>


          </div>
        </div>
      </div>

      {isCreateGroupModalOpen && (
        <CreateGroupModal
          onClose={() => setIsCreateGroupModalOpen(false)}
          fetchData={fetchData}
        />
      )}
      {isUpdateGroupModalOpen && (
        <UpdateGroupModal
          onClose={() => setIsUpdateGroupModalOpen(false)}
          data={selectedrow}
          fetchData={fetchData}
        />
      )}
      {isDeleteGroupModalOpen && (
        <DeleteGroupModal
          onClose={() => setIsDeleteGroupModalOpen(false)}
          data={selectedrow}
          fetchData={fetchData}
          type={'group'}
        />
      )}
      {isBulkActionModalOpen && (
        <BulkActionModal onClose={() => setIsBulkActionModalOpen(false)} selectedRows={Checkedrows} fetchData={fetchData} setAllChecked={setAllChecked} type={'group'}/>
      )}
    </div>
  );
};

export default ManageContactGroups;
