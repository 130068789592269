import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import apiUrl from '../../utils/apiConfig';

const DataFetchComponent = () => {
    const [socket, setSocket] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Establish a WebSocket connection
        const socketConnection = io(apiUrl); // Replace with your server URL

        // Set socket state
        setSocket(socketConnection);

        // Listen for the 'userId' event to get the socket ID
        socketConnection.on('userId', (socketId) => {
            console.log('User connected with socket ID:', socketId);
        });

        // Listen for the 'GetDataRead' event for the server response
        socketConnection.on('GetDataRead', (response) => {
            setLoading(false);
            if (response.success) {
                setData(response.data);
            } else {
                setError(response.error);
            }
        });

        // Cleanup the WebSocket connection on component unmount
        return () => {
            socketConnection.disconnect();
        };
    }, []);

    const fetchData = () => {
        setLoading(true);
        setError(null);

        // Data to send to the server
        const requestData = {
            tableSchema: 'apioneklicks_new',
            tableName: 'users',
            conditions: {  },
            search: {  },
            pagination: { page: 1, limit: 10 },
            margedata: [
                {
                    
                }
            ]
        };

        // Send a request to the server
        socket.emit('GetDataRead', requestData);
    };

    return (
        <div>
            <h2>Data Fetch Example</h2>

            <button onClick={fetchData} disabled={loading}>
                {loading ? 'Loading...' : 'Fetch Data'}
            </button>

            {error && <div style={{ color: 'red' }}>{error}</div>}

            <ul>
                {data.length > 0 ? (
                    data.map((item, index) => (
                        <li key={index}>{JSON.stringify(item)}</li>
                    ))
                ) : (
                    <p>No data available.</p>
                )}
            </ul>
        </div>
    );
};

export default DataFetchComponent;
