import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included

const MessageList = () => {
  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Message List</h4>
            </div>

            <div className="card-filter">
              <form action="http://localhost/getoneclick/admin/sms/search?index" method="GET">
                <div className="filter-form">
                  <div className="filter-item">
                    <select name="status" className="form-select">
                      <option value="all">All</option>
                      <option value="pending">Pending</option>
                      <option value="schedule">Schedule</option>
                      <option value="fail">Fail</option>
                      <option value="delivered">Delivered</option>
                      <option value="processing">Processing</option>
                    </select>
                  </div>

                  <div className="filter-item">
                    <input type="text" autoComplete="off" name="search" placeholder="Search with User, Email or To Recipient number" className="form-control" id="search" />
                  </div>

                  <div className="filter-item">
                    <input type="text" className="form-control datepicker-here" name="date" placeholder="From Date-To Date" id="date" />
                  </div>

                  <div className="filter-action">
                    <button className="i-btn info--btn btn--md" type="submit">
                      <svg className="svg-inline--fa fa-magnifying-glass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"></path>
                      </svg> Search
                    </button>
                    <button className="i-btn danger--btn btn--md">
                      <a className="text-white" href="http://localhost/getoneclick/admin/sms">
                        <i className="las la-sync"></i> Reset
                      </a>
                    </button>

                    <div className="statusUpdateBtn d-none">
                      <a className="i-btn success--btn btn--md statusupdate" data-bs-toggle="tooltip" data-bs-placement="top" title="Status Update" data-bs-target="#smsstatusupdate">
                        <svg className="svg-inline--fa fa-gear" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gear" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z"></path>
                        </svg> Action
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-body px-0">
              <div className="responsive-table">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className="d-flex align-items-center">
                          <input className="form-check-input mt-0 me-2 checkAll" type="checkbox" />
                          <label className="form-check-label">All</label>
                        </div>
                      </th>
                      <th>Sms ID</th>
                      <th>To</th>
                      <th>Status</th>
                      <th>Message</th>
                      <th>Send Date</th>
                      <th>Send Time</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Add rows dynamically here */}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-footer">
              <div className="pagination">
                <ul className="pagination m-0">
                  <li className="page-item disabled"><a className="page-link" href="#">Previous</a></li>
                  <li className="page-item active"><a className="page-link" href="#">1</a></li>
                  <li className="page-item"><a className="page-link" href="#">2</a></li>
                  <li className="page-item"><a className="page-link" href="#">Next</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Status Update Modal */}
      <div className="modal fade" id="smsstatusupdate" tabIndex="-1" aria-labelledby="smsstatusupdateLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="smsstatusupdateLabel">Status Update</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="status" className="form-label">Update Status</label>
                  <select className="form-select" id="status">
                    <option value="pending">Pending</option>
                    <option value="delivered">Delivered</option>
                    <option value="fail">Fail</option>
                  </select>
                </div>
                <div className="mb-3">
                  <button type="submit" className="btn btn-primary">Update Status</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* SMS Details Modal */}
      <div className="modal fade" id="smsdetails" tabIndex="-1" aria-labelledby="smsdetailsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="smsdetailsLabel">SMS Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Details of the SMS will be displayed here.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <div className="modal fade" id="delete" tabIndex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteLabel">Delete Confirmation</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this item?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageList;
