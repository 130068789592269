import { io } from 'socket.io-client';
import { encodeBase64 } from './services/apiService';
import apiUrl from './utils/apiConfig';

console.log('app_url :', apiUrl);
const API_URL = `${apiUrl}/api/`;
console.log('API_URL :', API_URL);

const API_BASE_URL = `${API_URL}realtime`; // Replace with your actual API endpoint
const databaseid = "";

const api_key = process.env.REACT_APP_API_KEY;

class OneClickSDK {
  constructor() {
    this.pollingInterval = null; // Interval ID for long polling
    this.socket = io(apiUrl, {
      transports: ['websocket'], // Ensure WebSocket connection
      reconnection: true,        // Reconnect automatically if the connection is lost
    });

    this.setupSocketListeners();
    this.liveRead(this);
  }

  // Set up Socket.IO listeners
  setupSocketListeners() {
    this.socket.on('connect', () => {
      console.log('Connected to Socket.IO server with ID:', this.socket.id);
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server.');
    });
  }

  async postRequest(endpoint, data) {
    const token = localStorage.getItem('token'); // Get the token
  
    let headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store', // Disable caching for this request
    };
  
    if (token) {
      headers['Authorization'] = `Bearer ${token}`; // Send token in the Authorization header
    } else {
      headers['x-api-key'] = api_key; // Send API key if token is not available
    }
  
  
    try {
      const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });
  
      // Check if the response status is OK
      if (!response.ok) {
        if (response.status === 401) {
          // Unauthorized, likely due to invalid or expired token
          console.warn('Unauthorized: Invalid token or user not found.');
          localStorage.removeItem('token'); // Clear invalid token from storage
          throw new Error('Session expired. Please log in again.');
        }
  
        // Handle other HTTP errors
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || `HTTP error! Status: ${response.status}`);
      }
  
      // Return the JSON response if no error occurred
      return await response.json();
    } catch (error) {
      console.error('Error in postRequest:', error.message);
      throw error;
    }
  }
  

  // CRUD operations
  async create(tableSchema, tableName, data, conditions) {
    return this.postRequest('dynamicCreate', { tableSchema, tableName, data, conditions, databaseid });
  }

  async read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata) {
    return this.postRequest('dynamicRead', {
      tableSchema,
      tableName,
      conditions,
      orderBy,
      search,
      pagination,
      margedata,
      databaseid,
    });
  }

  async update(tableSchema, tableName, data, condition) {
    return this.postRequest('dynamicUpdate', { tableSchema, tableName, data, condition, databaseid });
  }

  async delete(tableSchema, tableName, condition) {
    return this.postRequest('dynamicDelete', { tableSchema, tableName, condition, databaseid });
  }

  async count(tableSchema, tableName, conditions) {
    return this.postRequest('dynamicCount', { tableSchema, tableName, conditions, databaseid });
  }

  async createDatabase(databaseName) {
    return this.postRequest('createDatabase', { databaseName });
  }

  // Long polling
  async startLongPolling(tableSchema, tableName, conditions, orderBy, search, callback, pagination, margedata) {
    if (typeof callback !== 'function') {
      console.error('Callback is not a function:', callback);
      return; // Exit early if callback is not valid
    }

    const fetchData = async () => {
      try {
        const data = await this.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
        callback(data); // Pass the data to the callback
      } catch (error) {
        console.error('Error during long polling:', error);
      }
    };

    // Fetch data every 1 second
    this.pollingInterval = setInterval(fetchData, 1000);
  }

  stopLongPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
      console.log('Long polling stopped.');
    }
  }

  // New liveRead function using Socket.IO
  liveRead(params, callback) {
    console.log('params :', params);
    if (!params || typeof callback !== 'function') {
      console.error('Invalid parameters or callback for liveRead');
      return;
    }

    // Emit event to request live data
    this.socket.emit('GetDataRead', params);

    // Listen for the response
    this.socket.on('DataReadResult', (response) => {
      if (response.success) {
        callback(response.data);
      } else {
        console.error('Error in liveRead:', response.error);
        callback(null, response.error);
      }
    });
  }

  // Set interval to call liveRead every second
  startliveReadLiveReadPolling(params, callback) {
    if (typeof callback !== 'function') {
      console.error('Callback is not a function:', callback);
      return; // Exit early if callback is not valid
    }

    // Call liveRead every second
    setInterval(() => {
      this.liveRead(params, callback);
    }, 1000); // 1000ms = 1 second
  }
  // Close the WebSocket connection when no longer needed
  closeSocketConnection() {
    this.socket.disconnect();
    console.log('Socket.IO connection closed.');
  }
}

export const oneclick = new OneClickSDK();
