import React, { useEffect, useState, useRef } from "react";
import { decodeBase64, getuserdata, OpenAIResponse } from "../../../services/apiService";
import ImageComponent from "../../../utils/ImageComponent";
import Ai_ResponceChat from "./Chating/Ai_ResponceChat";
import { use } from "react";
import database_id from "../../../utils/databaseConfig";
import { oneclick } from "../../../oneclick";
import { set } from "date-fns";


const AiTrainingChat = ({ teamdata, staff, department_id, teamimage }) => {
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isRecording, setIsRecording] = useState(false);
    const recognitionRef = useRef(null);
    const [typing, setTyping] = useState(false);
    const [staffdata, setStaffdata] = useState({});
    const [profile_image, setProfile_image] = useState('');
    const [user, setUser] = useState({});
    const [team, setTeam] = useState({});
    const [teamid, setTeamid] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chatId, setChatid] = useState('');
    const [showstaff, setShowstaff] = useState(false);
    const [selectedstaff, setSelectedstaff] = useState('');

    useEffect(() => {
        // Check if the Web Speech API is supported
        fetchtriningdata();
        if ("webkitSpeechRecognition" in window) {
            const recognition = new window.webkitSpeechRecognition(); // Use window to access global objects
            recognition.lang = "en-US";
            recognition.continuous = false;
            recognition.interimResults = false;

            recognition.onresult = (event) => {
                setNewMessage(event.results[0][0].transcript);
                setIsRecording(false);
            };

            recognition.onerror = () => setIsRecording(false);
            recognition.onend = () => setIsRecording(false);

            recognitionRef.current = recognition;
        } else {
            console.warn("Speech recognition is not supported in this browser.");
        }
        console.log('chatContainerRef :', chatContainerRef.current);
        if (chatContainerRef.current) {
            const container = chatContainerRef.current;

            const handleScroll = () => {
                console.log("Scrolling... :", container.scrollTop);
                const previousHeight = 400 - container.scrollHeight;

                console.log("Scrolling... top:", container.scrollTop, '-', previousHeight);
                if (container.scrollTop === previousHeight) {
                    console.log("Scrolled to the top. Loading more data...");
                    const pageNumber = currentPage + 1;
                    fetchtriningdata(pageNumber); // Your function to load older messages
                }
            };

            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [chatContainerRef.current]);

    const fetchtriningdata = async (pageNumber) => { 
        const tableSchema = database_id;
        const tableName = 'ai_training';
        const conditions = {
            team_id: decodeBase64(teamdata),
            department_id: department_id,
        }
        const orderBy = "id DESC";
        const search = "";
        const pagination = { page: pageNumber || currentPage, pageSize: 100 };
        const margedata = [];

        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

        if (response.success) { 
            const newMessages = response.data.reverse(); // Reverse the data if required
            setMessages(newMessages);
        } else {
            alert('Failed to fetch training data');
        }

    };

    const handleInputChange = (e) => {
        const textvalue = e.target.value;
        if(textvalue === '@'){
            setShowstaff(true);
        }else{
            setShowstaff(false);
        }
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === "") return;

        const currentTime = new Date().toLocaleString();
        const userMessage = newMessage.trim();

        if (!newMessage.trim()) return; // Prevent sending empty messages

        // Optimistically update UI with the user's message
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: userMessage, sender: 'user', time: currentTime, type: "text" }
        ]);
        setTyping(true);
        setIsLoading(true); // Show loading state

        try {
            
            const chat_id = await saveStaffChat(userMessage); // Save staff chat if no existing chat
            await saveStaffChat(userMessage); // Save staff chat if no existing chat

            // Handle AI response
            await handleResponse(userMessage, currentTime);
        } catch (error) {
            console.error('Error in handleSendMessage:', error);
            alert(`Error: ${error.message}`);
        } finally {
            setNewMessage('');
            setSelectedstaff('');
            setIsLoading(false); // Hide loading state
            setTyping(false); // Hide typing indicator
        }
    };

    // Save staff chat
    const saveStaffChat = async (message) => {
        try {
            const tableSchema = database_id;
            const tableName = 'ai_training';
            const staffChatData = {
                department_id: department_id,
                team_id: decodeBase64(teamdata),
                sender_id: await getuserdata('id'),
                sender: 'user',
                receiver_id: staffdata.id,
                message: message,
                text: message,
                company_id: await getuserdata('company_id'),
                user_id: await getuserdata('id'),
                status: 'sent',
                time: new Date().toLocaleString(),
            };
            const response = await oneclick.create(tableSchema, tableName, staffChatData, "");

            console.log('Staff Chat Response:', response);

            if (!response.success) {
                throw new Error('Failed to save staff chat');
            } else {
                setChatid(response.insertedData.id); // Set chat ID for future messages
                return response.insertedData.id;
            }
        } catch (error) {
            console.error('Error saving staff chat:', error);
            alert(`Error saving staff chat: ${error.message}`);
        }
    };


    // Handle AI response
    const handleResponse = async (userMessage, currentTime) => {
        if (staffdata.status === "offline") {
            const offlineMessage = "Sorry, I am currently offline. Please try again later.";
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: offlineMessage, sender: `${staffdata.first_name} ${staffdata.last_name}`, time: currentTime }
            ]);
            saveChataiHistory(chatId, offlineMessage);
            return;
        }
    
        const dbResponse = await fetchDatabase(userMessage);
        console.log('Database Response:', dbResponse);  // Log the response
        if (dbResponse) {
            const dbTime = new Date().toLocaleString();
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: dbResponse, sender: "database", time: dbTime, type: "text" }
            ]);
            saveChataiHistory(chatId, dbResponse);
            return; // Exit if database provides a response
        }
    
        try {
            const user_id = await getuserdata('id');
            const aiResponse = await OpenAIResponse(userMessage, selectedstaff, user_id, "", decodeBase64(teamdata), true);
            console.log('AI Response:', aiResponse);  // Log the AI response
            const aiTime = new Date().toLocaleString();
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: aiResponse.reply, sender_id: selectedstaff || "AI", sender: "ai", time: aiTime, type: "text" }
            ]);
            saveChataiHistory(selectedstaff, aiResponse.reply);
            if (!selectedstaff) {
                setSelectedstaff('');
            }
        } catch (error) {
            console.error("Error communicating with OpenAI:", error);
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: "There was an issue processing your request. Please try again later.", sender: "ai", time: currentTime }
            ]);
            saveChataiHistory(selectedstaff, "There was an issue processing your request. Please try again later.");
        }
    };
    

    const saveChataiHistory = async (staffid, text) => {
        try {
            const tableSchema = database_id;
            const tableName = 'ai_training';

            const chatHistoryData = {
                department_id: department_id,
                team_id: decodeBase64(teamdata),
                sender_id: staffid || 'AI',
                sender: 'AI',
                receiver_id: staffdata.id,
                message: text,
                text: text,
                company_id: await getuserdata('company_id'),
                user_id: await getuserdata('id'),
                status: 'delivered',
                time: new Date().toLocaleString(),
            };
            const response = await oneclick.create(tableSchema, tableName, chatHistoryData, "");

            if (!response.success) {
                throw new Error('Failed to save chat history');
            } else {

            }
        } catch (error) {
            console.error('Error saving chat history:', error);
            alert(`Error saving chat history: ${error.message}`);
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file);
            setMessages((prev) => [...prev, { type: "file", content: fileURL, fileName: file.name }]);
        }
    };

    const handleVoiceInput = () => {
        if (recognitionRef.current) {
            if (isRecording) {
                recognitionRef.current.stop();
            } else {
                recognitionRef.current.start();
            }
            setIsRecording((prev) => !prev);
        }
    };

    // Fetch data from the database
    const fetchDatabase = async (query) => {
        const apiUrl = "https://your-backend-api.com/query"; // Replace with your backend API URL
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ query }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            return data && data.result ? data.result : null;
        } catch (error) {
            console.error("Error querying database:", error);
            return null;
        }
    };

    const handleselectstaff = (email) => {
        console.log('handleselectstaff :', email);
        setSelectedstaff(email.id);
        setStaffdata(email);
        const newdata = newMessage + email.first_name + ' ' + email.last_name + ' ';
        setNewMessage(newdata);
        setShowstaff(false);
    };

    return (
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div style={{ background: 'white', borderRadius: '5px' }}>
                <div
                    className="messages chat-container"
                    style={{ minHeight: '400px', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
                    ref={chatContainerRef}
                >
                    {messages.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center col-lg-12" style={{ height: '100%', minHeight: '400px', width: '100%' }}>
                            <div className="text-center chat-containeremty">
                                <p className="text-muted">What can I help with?</p>
                            </div>
                        </div>
                    ) : (
                        <div className="messages chat-container" style={{ minHeight: '400px', display: 'flex' }} ref={chatContainerRef}>
                            <div class="content">

                                {messages.map((message, index) => (
                                    message.sender === "user" ? (
                                        <div key={index} className={`messageuser col-lg-12 col-md-12 col-sm-12`}>
                                            <div className="d-flex userchat " style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                <div className="col-lg-1 col-md-1 col-sm-1" style={{ width: '50px', height: '50px' }}>
                                                    {profile_image ? (
                                                        <ImageComponent
                                                            fileid={profile_image || ''}
                                                            width={50}
                                                            height={50}
                                                            borderRadius={50}
                                                            boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                                        />
                                                    ) : (
                                                        <img src="/assets/file/images/user/profile/6388307.png" alt="user" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                                    )}

                                                </div>
                                                <div className="col-lg-10 col-md-10 col-sm-10">
                                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                        <div className="message-bubbleuser">
                                                            {message.text}
                                                        </div>
                                                    </div>
                                                    <p style={{ textAlign: 'end' }}>{message.time}</p>
                                                </div>

                                            </div>
                                        </div>
                                    ) : (

                                        <Ai_ResponceChat message={message} staffdata={staffdata} index={index} teamimage={teamimage}/>  // Ai Responce Chat Component
                                    )
                                ))}
                                {typing && (
                                    <div className="message typing">

                                        <div className="d-flex senderchat col-lg-11">
                                            <div style={{ width: '50px', height: '50px' }}>
                                                <ImageComponent
                                                    fileid={staffdata.profile_image}
                                                    width={50}
                                                    height={50}
                                                    borderRadius={50}
                                                    boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                                />
                                            </div>
                                            <div className="col-lg-10">
                                                <div>
                                                    <div className="message-bubblesender">
                                                        <p>Typing...</p>
                                                    </div>
                                                </div>
                                                <p style={{ marginLeft: '25px', textAlign: 'left' }}>{new Date().toLocaleString()}</p>
                                            </div>
                                        </div>

                                    </div>
                                )}

                            </div>
                        </div>
                    )}

                </div>
            </div>
            {showstaff && (
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {staff.length < 0 ? (
                        <div className="justify-content-center align-items-center">
                            <div className="text-center chat-containeremty">
                                <p className="text-muted" style={{fontSize: '16px'}}>No staff available</p>
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: '100%' }}>
                            <div className="chat-containernewdata" style={{ width: '100%' }}>
                                {staff.map((email, index) => (
                                    <div className="card md-3" style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleselectstaff(email)} key={index}>
                                        <div className="card-body d-flex justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <ImageComponent
                                                        fileid={email.images?.id || 0} // Fallback if `email.images` is undefined
                                                        width={40}
                                                        height={40}
                                                        borderRadius={50}
                                                        boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                                    />
                                                </div>
                                                <div>
                                                    <h4 className="card-title" style={{ marginLeft: '10px', fontSize: '15px' }}>{email.first_name} {email.last_name}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className="chat-input d-flex align-items-center" style={{ marginTop: '10px', padding: '10px', background: 'white' }}>
                <button
                    onClick={handleVoiceInput}
                    className="btn btn-primary"
                    style={{
                        borderRadius: '50%',
                        padding: '10px 15px',
                        backgroundColor: isRecording ? 'red' : '#4CAF50',
                        color: 'white',
                        marginRight: '10px',
                    }}
                >
                    <i className="fa fa-microphone" style={{ fontSize: '18px' }}></i>
                </button>
                <input
                    type="text"
                    value={newMessage}
                    onChange={handleInputChange}
                    placeholder="Type a message..."
                    className="form-control"
                    style={{
                        borderRadius: '20px',
                        padding: '10px',
                        border: '1px solid #ccc',
                        marginRight: '10px',
                    }}
                />
                <label className="btn btn-primary" style={{ borderRadius: '50%', padding: '10px 15px', marginRight: '10px' }}>
                    <i className="fa fa-paperclip" style={{ fontSize: '18px' }}></i>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />
                </label>
                <button
                    onClick={handleSendMessage}
                    className="btn btn-primary"
                    style={{
                        borderRadius: '50%',
                        padding: '10px 15px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                    }}
                >
                    <i className="fa fa-paper-plane" style={{ fontSize: '18px' }}></i>
                </button>
            </div>
        </div>
    );
};

export default AiTrainingChat;
