import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { createemail } from '../../services/apiService';
import { Password } from '@mui/icons-material';

// Function to generate random profile details (name, age, etc.)
const generateRandomProfileData = () => {
    const firstNames = [
        "Aarav", "Vivaan", "Aditya", "Vihaan", "Arjun", "Sai", "Ishaan", "Reyansh", "Ayaan", "Krishna",
        "Saanvi", "Ananya", "Isha", "Aadhya", "Shruti", "Riya", "Priya", "Diya", "Neha", "Madhuri",
        "Kavya", "Meera", "Lakshmi", "Pooja", "Nikita", "Simran", "Tanya", "Aarti", "Shrishti", "Avantika",
        "Siddharth", "Raghav", "Vikas", "Karan", "Rohit", "Deepak", "Vineet", "Manish", "Ravi", "Rajesh",
        "Ankur", "Nikhil", "Ashwin", "Amit", "Aman", "Rahul", "Anil", "Sandeep", "Suraj", "Kunal",
        "Madhav", "Raghav", "Yash", "Tanmay", "Kartik", "Ashutosh", "Manoj", "Harsh", "Abhinav", "Sameer",
        "Rupesh", "Vikram", "Shivendra", "Naveen", "Saurabh", "Gaurav", "Pranav", "Vishal", "Abhishek", "Tarun",
        "Harshit", "Yogesh", "Rajat", "Sachin", "Raghavendra", "Aakash", "Subham", "Devansh", "Manan", "Rudra",
        "Avi", "Milan", "Anuj", "Tanmay", "Shivansh", "Pankaj", "Bharat", "Pradeep", "Arvind", "Tejas",
        "Vinay", "Raman", "Shubham", "Sandeep", "Sushant", "Dinesh", "Vidyut", "Avi", "Anirudh", "Dhiraj",
        "Aryan", "Yogendra", "Om", "Mohit", "Kunal", "Prakash", "Satyam", "Jatin", "Rahul", "Gaurav"
    ];

    const lastNames = [
        "Sharma", "Patel", "Gupta", "Yadav", "Reddy", "Singh", "Verma", "Kumar", "Mishra", "Jadhav",
        "Desai", "Chaudhary", "Gupta", "Rao", "Bhat", "Basu", "Mehta", "Joshi", "Chauhan", "Khan",
        "Shukla", "Jain", "Karthik", "Pillai", "Nair", "Singh", "Nandan", "Khatri", "Rathore", "Kashyap",
        "Agrawal", "Shukla", "Patil", "Solanki", "Sharma", "Thakur", "Pandey", "Bora", "Chand", "Bansal",
        "Sethi", "Bedi", "Mann", "Singh", "Chopra", "Tiwari", "Rai", "Garg", "Yadav", "Vyas",
        "Kohli", "Ahuja", "Bhagat", "Tiwari", "Soni", "Chauhan", "Vaidya", "Bansal", "Saxena", "Nair",
        "Rathi", "Kaur", "Sheikh", "Vikram", "Rawat", "Rathi", "Das", "Rani", "Agarwal", "Verma",
        "Panda", "Jha", "Bharti", "Kaushal", "Jadhav", "Nagpal", "Kale", "Prasad", "Kumawat", "Joshi",
        "Deshmukh", "Baweja", "Bhushan", "Taneja", "Kapoor", "Nehra", "Malhotra", "Poddar", "Goswami",
        "Tiwari", "Vyas", "Mittal", "Chand", "Singhal", "Agarwal", "Chadha", "Kapoor", "Shah", "Goel"
    ];
    const departments = ["Engineering", "Product", "Design", "Sales", "HR", "Marketing"];
    const teams = ["Team A", "Team B", "Team C", "Team D", "Team E"];
    const statuses = ["Active", "Inactive"];
    const skinTones = ["Fair", "Wheatish", "Dark"];
    const hobbiesList = ["Reading", "Traveling", "Painting", "Gaming", "Cooking", "Photography", "Sports"];
    const goalsList = ["Lead a team", "Learn a new skill", "Achieve work-life balance", "Get promoted"];
    const favoritesList = ["Coffee", "Pizza", "Movies", "Books", "Music", "Tech Gadgets", "Fitness"];
    const heights = [150, 155, 160, 165, 170, 175, 180, 185]; // Heights in cm
    const weights = [50, 55, 60, 65, 70, 75, 80, 85]; // Weights in kg
    const profileColors = ["Pale", "Beige", "Olive", "Brown", "Tan"];

    const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
    const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];
    const randomDepartment = departments[Math.floor(Math.random() * departments.length)];
    const randomTeam = teams[Math.floor(Math.random() * teams.length)];
    const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
    const age = Math.floor(Math.random() * (30 - 18 + 1)) + 18;  // Random age between 22 and 50

    const randomElement = (array) => array[Math.floor(Math.random() * array.length)];

    return {
        firstName: randomElement(firstNames),
        lastName: randomElement(lastNames),
        age: Math.floor(Math.random() * (50 - 22 + 1)) + 22, // Random age between 22 and 50
        height: randomElement(heights),
        weight: randomElement(weights),
        skinTone: randomElement(skinTones),
        hobbies: randomElement(hobbiesList),
        goals: randomElement(goalsList),
        favorites: randomElement(favoritesList),
        department: randomElement(departments),
        team: randomElement(teams),
        status: randomElement(statuses),
        profileColor: randomElement(profileColors)
    };
};

// Function to call OpenAI's API and generate a profile image
const generateProfileImage = async (firstName, lastName, gender, age, height, weight, profileColor, mode) => {
    try {
        if (mode == "test") {
            return "https://oaidalleapiprodscus.blob.core.windows.net/private/org-ZzyZZmGUjLHa50qMSeihsW4q/user-YN2iMJX09X5jIQhi4fDL9szo/img-UUfs0R32VlFPrwadbggP6ds2.png?st=2024-12-21T16%3A29%3A13Z&se=2024-12-21T18%3A29%3A13Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-12-20T19%3A32%3A51Z&ske=2024-12-21T19%3A32%3A51Z&sks=b&skv=2024-08-04&sig=cOO1gj2ya7%2BZwGMoraNQysDcLUdnlc436nYLd9in63E%3D";
        } else {
            // Determine the attire and hair style based on gender
            const attire = gender === "Female" ? "traditional attire" : "formal attire";
            const hairStyle = gender === "Female" ? "long black hair" : "short black hair";
            const clothing = gender === "Female" ? "in traditional attire" : "wearing formal attire";

            // Add physical features (height, weight, and profile color)
            const physicalDescription = `Age: ${age}, Height: ${height} cm, Weight: ${weight} kg, Skin Tone: ${profileColor}, clear skin, well-groomed.`;


            
            // Generate the prompt based on profile data
            const prompt = gender === "Female"
                ? `${firstName} ${lastName}, an Indian woman, wearing ${clothing}, smiling confidently, with ${hairStyle}, ${physicalDescription}, in a bright professional office setting, realistic portrait, well-lit, sharp details, high-definition, professional appearance.`
                : `${firstName} ${lastName}, an Indian man, wearing ${clothing}, smiling confidently, with ${hairStyle}, ${physicalDescription}, in a bright office environment, realistic portrait, well-lit, sharp details, high-definition, professional appearance.`;
            const response = await axios.post('https://api.openai.com/v1/images/generations', {
                prompt,
                n: 1,
                size: '1024x1024',
            }, {
                headers: {
                    'Authorization': `Bearer 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`
                }
            });

            // Assuming OpenAI returns the image URL in the response data
            const imageUrl = response.data.data[0].url;

            return imageUrl;
        }

    } catch (error) {
        console.error("Error fetching profile image from OpenAI:", error);
        return 'https://via.placeholder.com/150'; // Fallback image in case of error
    }
};


const generateUniqueId = (existingIds) => {
    let newId;
    do {
        newId = `STAFF-${Math.floor(Math.random() * 900000) + 100000}`; // Generate a 6-digit ID
    } while (existingIds.has(newId)); // Ensure the ID is not already in the set
    existingIds.add(newId); // Add the new ID to the set
    return newId;
};

export const generateRandomProfiles = async (count, female_count, male_count, department, team, status) => {
    const generatedProfiles = [];
    let femaleProfilesGenerated = 0;
    let maleProfilesGenerated = 0;
    let totalGenerated = 0;


    while (totalGenerated < count) {
        let gender = ''; // Randomly select gender

        const existingIds = new Set(); // Maintain a set of unique IDs

        // Ensure gender constraints are respected
        if (femaleProfilesGenerated < female_count) {
            gender = 'Female';
            femaleProfilesGenerated++;
        } else if (maleProfilesGenerated < male_count) {
            gender = 'Male';
            maleProfilesGenerated++;
        } else {
            break; // If gender-specific quotas are met, break the loop
        }

        console.log('femaleProfilesGenerated :', femaleProfilesGenerated);
        console.log('maleProfilesGenerated :', maleProfilesGenerated);
        console.log('totalGenerated :', totalGenerated);


        // If we have generated all the profiles we need, break the loop
        if (totalGenerated >= count) {
            break;
        }

        // const profileData = 'https://oaidalleapiprodscus.blob.core.windows.net/private/org-ZzyZZmGUjLHa50qMSeihsW4q/user-YN2iMJX09X5jIQhi4fDL9szo/img-UUfs0R32VlFPrwadbggP6ds2.png?st=2024-12-21T16%3A29%3A13Z&se=2024-12-21T18%3A29%3A13Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-12-20T19%3A32%3A51Z&ske=2024-12-21T19%3A32%3A51Z&sks=b&skv=2024-08-04&sig=cOO1gj2ya7%2BZwGMoraNQysDcLUdnlc436nYLd9in63E%3D';
        const profileData = generateRandomProfileData(); // Assuming this function is already defined

        // Ensure gender is set correctly
        profileData.gender = gender;

        // Generate the profile image using OpenAI API
        let profileImage;
        try {
            // Attempt to generate the profile image using OpenAI API
            profileImage = await generateProfileImage(
                profileData.firstName,
                profileData.lastName,
                profileData.gender,
                profileData.age,
                profileData.height,  // If available
                profileData.weight,  // If available
                profileData.profileColor, // If available
                process.env.REACT_APP_OPENAI_PROFILECREATE
            );
        } catch (error) {
            console.error("Failed to generate profile image:", error);
            // Use a default placeholder image if image generation fails
            profileImage = "https://via.placeholder.com/150?text=Default+Profile";
        }

        const profileId = generateUniqueId(existingIds); // Generate a unique ID

        // Create the profile object
        const profile = {
            id: profileId,
            first_name: profileData.firstName,
            last_name: profileData.lastName,
            age: profileData.age,
            gender: profileData.gender,
            email: await createemail(`${profileData.firstName.toLowerCase()}${profileData.lastName.charAt(0).toLowerCase()}${profileId.toLowerCase()}`, profileId),
            Password: profileId,
            position: 'Software Engineer', // Customize if needed
            department: department || profileData.department,
            team: team || profileData.team,
            status: status || profileData.status,
            profile_image: profileImage,
            height: profileData.height,
            weight: profileData.weight,
            skin_tone: profileData.skinTone,
            hobbies: profileData.hobbies,
            goals: profileData.goals,
            favorites: profileData.favorites,
            created_by: 'Malle Ravindra',
            phone_number: `970565607${totalGenerated + 1}`,
        };

        generatedProfiles.push(profile);
        totalGenerated++;

        // Ensure the loop runs until we have generated the required number of profiles
        if (totalGenerated >= count) {
            break;
        }
    }

    return generatedProfiles;
};