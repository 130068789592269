import React, { useState, useEffect } from "react";
import { getuserdata } from "../../../services/apiService";
import { oneclick } from "../../../oneclick";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import database_id from "../../../utils/databaseConfig";

const CustomAttributesForm = ({ attributes, setAttributes }) => {
    
    const [filteredAttributes, setFilteredAttributes] = useState([]);

    // Fetch data from the backend API
    const fetchData = async () => {
        const tableSchema = database_id;
        const tableName = "attribute";
        const company_id = await getuserdata("company_id");

        const condition = { company_id };
        const data = await oneclick.read(
            tableSchema,
            tableName,
            condition,
            "id DESC",
            "",
            ""
        );

        setFilteredAttributes(data.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filteredAttributes.length > 0) {
            const initialAttributes = {};
            filteredAttributes.forEach((attribute) => {
                // Store both 'value' and 'type' for each attribute
                initialAttributes[attribute.attribute_name] = {
                    value: "",
                    type: attribute.attribute_type || "",  // Ensure 'type' is set correctly
                };
            });
            setAttributes(initialAttributes);
        }
    }, [filteredAttributes, setAttributes]);

    // Handle input changes and update the respective attribute's value
    const handleChange = (e) => {
        const { name, value, id } = e.target;

        // Update the 'value' while preserving the 'type' for each attribute
        setAttributes((prevAttributes) => ({
            ...prevAttributes,
            [name]: {
                value: value,
                type: id,  // Keep the original 'type'
            },
        }));

        console.log("Updated Attributes:", attributes); // To see the updated state
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted Attributes:", attributes); // Submit logic or API call here
    };

    // Render the appropriate input based on attribute type
    const renderInput = (attribute) => {
        const { attribute_name, attribute_type } = attribute;
        switch (attribute_type) {
            case "1":
                return (
                    <input
                        type="date"
                        className="form-control mb-3 flatpicker flatpickr-input"
                        id={attribute_type}
                        name={attribute_name}
                        value={attributes[attribute_name]?.value || ""}
                        onChange={handleChange}
                        placeholder={`Choose Contact ${attribute_name}`}
                    />
                );
            case "2":
                return (
                    <select
                        className="form-select mb-3"
                        id={attribute_type}
                        name={attribute_name}
                        value={attributes[attribute_name]?.value || ""}
                        onChange={handleChange}
                        required
                    >
                        <option selected disabled>
                            -- Select An Option --
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                );
            case "3":
                return (
                    <input
                        type="number"
                        className="form-control mb-3"
                        id={attribute_type}
                        name={attribute_name}
                        value={attributes[attribute_name]?.value || ""}
                        onChange={handleChange}
                        placeholder={`Enter Contact ${attribute_name}`}
                    />
                );
            default:
                return (
                    <input
                        type="text"
                        className="form-control mb-3"
                        id={attribute_type}
                        name={attribute_name}
                        value={attributes[attribute_name]?.value || ""}
                        onChange={handleChange}
                        placeholder={`Enter Contact ${attribute_name}`}
                    />
                );
        }
    };

    return (
        <div>
            {filteredAttributes.length !== 0 ? (
                <form onSubmit={handleSubmit} className="form-wrapper my-3 border--dark" style={{ borderRadius: "5px" }}>
                    <div className="row">
                        <div className="form-wrapper-title">
                            Custom Attribute Data
                            <Link
                                to="/contact_attribute" // Use the 'to' prop instead of 'href'
                                rel="noopener noreferrer"
                            >
                                <i style={{ fontSize: "15px" }} className="text--primary las la-external-link-alt"></i>
                            </Link>
                        </div>
                        {filteredAttributes.map((attribute) => (
                            <div className="form-item col-lg-6 mb-3" key={attribute.attribute_name}>
                                <label htmlFor={attribute.attribute_name} className="form-label">
                                    {attribute.attribute_name
                                        ? attribute.attribute_name.replace(/_/g, " ")
                                        : "Unknown Key"}
                                </label>
                                {renderInput(attribute)}
                            </div>
                        ))}
                    </div>
                </form>
            ) : (
                <div className="form-item my-3">
                    <Link
                        to="/contact_attribute" // Use the 'to' prop instead of 'href'
                        rel="noopener noreferrer"
                    >
                        <i style={{ fontSize: "15px" }} className="text--primary las la-external-link-alt"></i>
                        Add More Attributes
                    </Link>
                </div>
            )}
        </div>
    );
};

export default CustomAttributesForm;
