import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connectUserToDatabase } from '../../../services/apiService';

const ConnectModal = ({ show, handleClose, databases, users }) => {
    const [selectedDatabase, setSelectedDatabase] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState(users);  // To store the filtered users based on the selected database IP
    const [isLoading, setIsLoading] = useState(false);

    // List of privileges
    const privileges = [
        "ALTER", "ALTER ROUTINE", "CREATE", "CREATE ROUTINE", "CREATE TEMPORARY TABLES", "CREATE VIEW",
        "DELETE", "DROP", "EVENT", "EXECUTE", "INDEX", "INSERT", "LOCK TABLES", "REFERENCES", "SELECT",
        "SHOW VIEW", "TRIGGER", "UPDATE"
    ];

    // Selected privileges state
    const [selectedPrivileges, setSelectedPrivileges] = useState([]);

    // Toggle "ALL PRIVILEGES"
    const handleAllPrivileges = () => {
        if (selectedPrivileges.includes("ALL PRIVILEGES")) {
            // Deselect all
            setSelectedPrivileges([]);
        } else {
            // Select all privileges
            setSelectedPrivileges(["ALL PRIVILEGES", ...privileges]);
        }
    };

    // Handle individual privilege checkbox change
    const handleCheckboxChange = (privilege) => {
        if (selectedPrivileges.includes(privilege)) {
            setSelectedPrivileges(selectedPrivileges.filter(item => item !== privilege));
        } else {
            setSelectedPrivileges([...selectedPrivileges, privilege]);
        }
    };

    // Check if "ALL PRIVILEGES" is selected
    const isAllSelected = selectedPrivileges.includes("ALL PRIVILEGES");

    // Handle database selection
    const handleDatabaseChange = (e) => {
        const selectedId = e.target.value;
        const selectedDb = databases.find(db => db.id.toString() === selectedId);
        setSelectedDatabase(selectedDb); // Store the entire object
        
        // Filter users by the IP of the selected database
        const filteredUsersByIp = users.filter(user => user.server === selectedDb.server);
        setFilteredUsers(filteredUsersByIp); // Update filtered users based on the selected database's IP
    };

    // Handle user selection
    const handleUserChange = (e) => {
        const selectedId = e.target.value;
        const selectedUsr = filteredUsers.find(user => user.id.toString() === selectedId);
        setSelectedUser(selectedUsr); // Store the entire object
    };

    // Handle submit logic
    const handleSubmit = async () => {
        if (isLoading) return; // Prevent multiple submissions
        setIsLoading(true);

        if (!selectedDatabase || !selectedUser || selectedPrivileges.length === 0) {
            alert('Please select a database, user, and privileges.');
            setIsLoading(false);
            return;
        }

        const updatedata = await connectUserToDatabase(
            selectedDatabase.db_name, 
            selectedUser.db_username, 
            selectedPrivileges, 
            selectedUser.id, 
            selectedDatabase.id
        );

        if (updatedata.success) {
            alert(updatedata.message);
            handleClose();
        } else {
            alert(updatedata.error);
        }

        setIsLoading(false);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Connect to Database</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Database Selection */}
                    <Form.Group controlId="selectDatabase">
                        <Form.Label>Select Database</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedDatabase ? selectedDatabase.id : ""}
                            onChange={handleDatabaseChange}
                        >
                            <option value="">Select a database</option>
                            {databases && databases.length > 0 ? (
                                databases.map((db, index) => (
                                    <option key={index} value={db.id}>
                                        {db.db_name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>No databases available</option>
                            )}
                        </Form.Control>
                    </Form.Group>

                    {/* User Selection */}
                    <Form.Group controlId="selectUser">
                        <Form.Label>Select User</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedUser ? selectedUser.id : ""}
                            onChange={handleUserChange}
                            style={{ marginBottom: '10px' }}
                        >
                            <option value="">Select a user</option>
                            {filteredUsers && filteredUsers.length > 0 ? (
                                filteredUsers.map((user, index) => (
                                    <option key={index} value={user.id}>
                                        {user.db_username}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>No users available for this database</option>
                            )}
                        </Form.Control>
                    </Form.Group>

                    {/* All Privileges Checkbox */}
                    <Form.Check
                        type="checkbox"
                        label="ALL PRIVILEGES"
                        value="ALL PRIVILEGES"
                        checked={isAllSelected}
                        onChange={handleAllPrivileges}
                        style={{ fontWeight: "bold", marginBottom: "1rem" }}
                    />

                    {/* Privileges Selection */}
                    <Row>
                        <Col md={6}>
                            {privileges.slice(0, Math.ceil(privileges.length / 2)).map((privilege, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    label={privilege}
                                    value={privilege}
                                    checked={selectedPrivileges.includes(privilege)}
                                    onChange={() => handleCheckboxChange(privilege)}
                                />
                            ))}
                        </Col>
                        <Col md={6}>
                            {privileges.slice(Math.ceil(privileges.length / 2)).map((privilege, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    label={privilege}
                                    value={privilege}
                                    checked={selectedPrivileges.includes(privilege)}
                                    onChange={() => handleCheckboxChange(privilege)}
                                />
                            ))}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? 'Connecting...' : 'Connect'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConnectModal;
