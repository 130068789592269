import React, { useState, useEffect } from 'react';
import { getuserdata } from '../../services/apiService';
import { oneclick } from '../../oneclick';
import CustomAttributesForm from './Components/CustomAttributesForm';
import CSVDownload from './Components/CSVDownload';
import database_id from '../../utils/databaseConfig';

const AddContactForm = () => {
  const [formData, setFormData] = useState({
    group_id: "",
    first_name: "",
    last_name: "",
    whatsapp_contact: "",
    sms_contact: "",
    email_contact: "",
    status: "1",
    file: null, // Store the file here
  });



  const [searchTerm, setSearchTerm] = useState('');
  const [statusFiltercon, setstatusFiltercon] = useState({});
  const [statusFilter, setStatusFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [groupdata, setGroupData] = useState({});
  const [attributes, setAttributes] = useState({});
  const [uploadtype, setuploadtype] = useState("single");


  useEffect(() => {
    getdata();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [fileName, setFileName] = useState(""); // State to store the file name

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        file: file, // Store the file in formData
      }));
      setFileName(file.name); // Update fileName state
    }
  };


  const normalizeKeys = (attributes) => {
    const normalized = {};
    Object.keys(attributes).forEach((key) => {
      const newKey = key
        .toLowerCase() // Convert to lowercase
        .replace(/ /g, "_") // Replace spaces with underscores
        .replace(/[^\w]/g, ""); // Remove non-alphanumeric characters

      normalized[newKey] = attributes[key];
    });
    return normalized;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data uploadtype: ", uploadtype);

    const tableSchema = database_id;
    const tableName = "contacts";
    const user_id = await getuserdata("id");
    const company_id = await getuserdata("company_id");

    if (uploadtype === "single") {
      const normalizedAttributes = normalizeKeys(attributes);

      const { v4: uuidv4 } = require("uuid");
      const data = {
        uid: uuidv4(),
        user_id: user_id,
        company_id: company_id,
        group_id: formData.group_id,
        attributes: JSON.stringify(normalizedAttributes),
        whatsapp_contact: formData.whatsapp_contact,
        email_contact: formData.email_contact,
        sms_contact: formData.sms_contact,
        last_name: formData.last_name,
        first_name: formData.first_name,
        status: formData.status,
      };

      const createresponse = await oneclick.create(tableSchema, tableName, data, "");

      if (createresponse.success) {
        alert(createresponse.message);

        const tableName = "contacts_attributes";  // Table for inserted data
        const user_id = await getuserdata("id");
        const company_id = await getuserdata("company_id");
        const contacts_id = createresponse.insertedData.id;

        // Assuming 'normalizedAttributes' is an object like:
        // {
        //   "data_of_barth": { "value": "2024-12-11", "type": "1" },
        //   "city": { "value": "Lingasamudhram", "type": "4" },
        //   "phone_number": { "value": "09182349165", "type": "3" },
        //   "gender": { "value": "", "type": "2" }
        // }

        // Iterate through the attributes and create a data object for each one
        for (const [attributeName, attributeData] of Object.entries(normalizedAttributes)) {
          const data = {
            user_id: user_id,
            company_id: company_id,
            contact_id: contacts_id,
            name: attributeName,  // Use the attribute name as 'name'
            value: attributeData.value,  // Use the value from the attribute data
            type: attributeData.type,  // Use the type from the attribute data
          };

          // Call your oneclick API to insert the data into the table
          const insertResponse = await oneclick.create(tableSchema, tableName, data, "");

          // Optionally, log the response for debugging purposes
          console.log("Insert Response:", insertResponse);
        }


        // Reset formData and attributes
        setFormData({
          group_id: "",
          whatsapp_contact: "",
          email_contact: "",
          sms_contact: "",
          last_name: "",
          first_name: "",
          status: "",
        });
        setAttributes({});
      } else {
        alert(createresponse.message);
      }
    } else if (uploadtype === "csv") {
      try {
        // Ensure formData.file is available
        const file = formData.file;

        if (!file) {
          alert("Please select a CSV file.");
          return;
        }

        // Read the CSV file
        const reader = new FileReader();
        reader.onload = async (event) => {
          const csvData = event.target.result;

          // Parse CSV data into JSON (assuming comma-separated CSV)
          const csvRows = csvData.split("\n").map((row) => row.split(",")); // Using comma as separator
          const [headers, ...rows] = csvRows;

          // Iterate over each row and upload the data one by one
          for (let row of rows) {
            const rowData = {};
            headers.forEach((header, index) => {
              rowData[header.trim()] = row[index]?.trim() || "";
            });

            const { v4: uuidv4 } = require("uuid");
            // Normalize and prepare data for API
            const data = {
              uid: uuidv4(),
              user_id: user_id,
              company_id: company_id,
              group_id: formData.group_id,
              whatsapp_contact: rowData["Whatsapp Contact"],
              email_contact: rowData["Email Contact"],
              sms_contact: rowData["Sms Contact"],
              last_name: rowData["Last Name"],
              first_name: rowData["First Name"],
              status: 2, // You can set a default status
            };

            // Make a single API call per row
            const singleResponse = await oneclick.create(tableSchema, tableName, data, "");

            if (singleResponse.success) {
              console.log("Data uploaded successfully:", singleResponse.message);
            } else {
              console.error("Error uploading data:", singleResponse.message);
            }
          }

          // Reset formData
          setFormData({
            group_id: "",
            file: null,  // Ensure file input is reset
          });

          alert("CSV data uploaded successfully.");
        };

        reader.onerror = (error) => {
          console.error("Error reading CSV file:", error);
          alert("Failed to read CSV file.");
        };

        reader.readAsText(file);
      } catch (error) {
        console.error("Error uploading CSV:", error);
        alert("Failed to upload CSV file.");
      }
    }
  };





  const getdata = async () => {
    const tableSchema = database_id;
    const tableName = "groups";
    const condition = {
      user_id: await getuserdata("id"),
    };

    // Corrected state update logic
    if (statusFilter === '0') {  // '0' for 'All' status
      setstatusFiltercon({
        name: searchTerm,
      });
    } else {
      setstatusFiltercon({
        name: searchTerm,
        status: statusFilter === '1' ? 'Active' : 'Inactive',  // '1' for Active, '2' for Inactive
      });
    }

    const orderBy = "id DESC";  // Uncomment if needed
    const pagination = { page: currentPage, limit: 20 };  // Define page size

    // Get the response
    const dataresponce = await oneclick.read(
      tableSchema,
      tableName,
      condition,
      orderBy,
      statusFiltercon,
      pagination,
      ""
    );
    console.log('dataresponce:', dataresponce);
    setGroupData(dataresponce.data);
  };


  return (
    <div className="container-fluid p-0">
      <div className="card">
        <div className="card-body">
          <form
            id="contact_store"
            action="http://localhost/getoneclick/admin/contacts/store"
            method="POST"
            onSubmit={handleSubmit}
          >
            <input
              type="hidden"
              name="_token"
              value="Dall9tMsI4DHqdv8hrMG7UmSfPcTQWSurpCxP37Z"
            />
            <div className="form-wrapper">
              <div className="form-wrapper-title">
                <h6>Add Contacts</h6>
              </div>

              <div className="form-item mb-4">
                <label htmlFor="group_id" className="form-label">
                  Select a Group <sup className="text--danger">*</sup>{" "}
                  <a
                    href="http://localhost/getoneclick/admin/contacts/groups/index"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      style={{ fontSize: "15px" }}
                      className="text--primary las la-external-link-alt"
                    ></i>
                  </a>
                </label>
                <select
                  className="form-select"
                  name="group_id"
                  id="group_id"
                  value={formData.group_id}
                  onChange={handleChange}
                  required
                >
                  <option disabled value="">
                    {groupdata.length > 0 ? "Select One" : "No Data Available"}
                  </option>
                  {groupdata.length > 0 &&
                    groupdata.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                </select>


              </div>

              <div className="file-tab">
                <ul
                  className="nav nav-tabs gap-2 mb-4"
                  id="myTabContent"
                  role="tablist"
                >
                  <li className="nav-item single-contact" role="presentation">
                    <button
                      className="nav-link active"
                      id="single-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#single-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="single-tab-pane"
                      aria-selected="true"
                      onClick={() => setuploadtype("single")}
                    >
                      <i className="las la-id-card"></i> Store Single Contact
                    </button>
                  </li>
                  <li className="nav-item upload-excel" role="presentation">
                    <button
                      className="nav-link"
                      id="upload-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#upload-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="upload-tab-pane"
                      aria-selected="false"
                      onClick={() => setuploadtype("csv")}
                    >
                      <i className="las la-copy"></i> Import Csv/Excel Files
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="single-tab-pane"
                    role="tabpanel"
                    aria-labelledby="single-tab"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="form-item col-lg-6 mb-3">
                        <label htmlFor="first_name" className="form-label">
                          First Name <sup className="text--danger">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="first_name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                          placeholder="Enter Contact First Name"
                          required={uploadtype === "single" ? true : false}
                        />
                      </div>
                      <div className="form-item col-lg-6 mb-3">
                        <label htmlFor="last_name" className="form-label">
                          Last Name <sup className="text--danger">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="last_name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                          placeholder="Enter Contact Last Name"
                          required={uploadtype === "single" ? true : false}
                        />
                      </div>
                      <div className="form-item col-lg-6 mb-3">
                        <label htmlFor="whatsapp_contact" className="form-label">
                          WhatsApp Number <sup className="text--danger">*</sup>
                          <p className="relative-note">
                            Provide the country code along with the number
                          </p>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="whatsapp_contact"
                          name="whatsapp_contact"
                          value={formData.whatsapp_contact}
                          onChange={handleChange}
                          placeholder="Enter Contact WhatsApp number"
                          required={uploadtype === "single" ? true : false}
                        />
                      </div>
                      <div className="form-item col-lg-6 mb-3">
                        <label htmlFor="sms_contact" className="form-label">
                          SMS Number <sup className="text--danger">*</sup>
                          <p className="relative-note">
                            Provide the country code along with the number
                          </p>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="sms_contact"
                          name="sms_contact"
                          value={formData.sms_contact}
                          onChange={handleChange}
                          placeholder="Enter Contact SMS number"
                          required={uploadtype === "single" ? true : false}
                        />
                      </div>
                      <div className="form-item col-lg-6 mb-3">
                        <label htmlFor="email_contact" className="form-label">
                          Email Account <sup className="text--danger">*</sup>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email_contact"
                          name="email_contact"
                          value={formData.email_contact}
                          onChange={handleChange}
                          placeholder="Enter Contact Email Account"
                          required={uploadtype === "single" ? true : false}
                        />
                      </div>
                      <div className="form-item col-lg-6">
                        <label htmlFor="status" className="form-label">
                          Status <sup className="text--danger">*</sup>
                        </label>
                        <select
                          className="form-select"
                          name="status"
                          id="status"
                          value={formData.status}
                          onChange={handleChange}
                          required={uploadtype === "single" ? true : false}
                        >
                          <option value="1">Active</option>
                          <option value="2">Banned</option>
                        </select>
                      </div>
                      <CustomAttributesForm attributes={attributes} setAttributes={setAttributes} />
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="upload-tab-pane"
                    role="tabpanel"
                    aria-labelledby="upload-tab"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="col-lg-8 mb-4">
                        <div className="d-flex gap-3 flex-column">
                          <div>
                            <div className="form-text mb-3">
                              <CSVDownload />
                            </div>
                            <div className="upload-filed">
                              <input
                                type="file"
                                id="file_upload"
                                name="file"
                                onChange={handleFileUpload}
                              />
                              <label htmlFor="file_upload" className="uplaod-file">
                                <div className="d-flex align-items-center gap-3">
                                  <span className="upload-drop-file">📂</span>
                                  <span className="upload-browse">
                                    Upload CSV/Excel File Here
                                  </span>
                                </div>
                              </label>
                              {fileName && ( // Conditionally render file name if available
                                <div className="file-name">
                                  <strong>File Uploaded: </strong> {fileName}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-end justify-content-end gap-3">

              <button type="submit" className="i-btn primary--btn btn--md" >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddContactForm;
