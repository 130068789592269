import React, { useEffect, useState } from "react";
import { QRCodeCanvas } from 'qrcode.react';
import { io } from "socket.io-client";
import apiUrl from "../../../utils/apiConfig";
import { oneclick } from "../../../oneclick";
import database_id from "../../../utils/databaseConfig";

const QRShow = ({ show, hide, data, setLoadQRData }) => {
    const [qrCodeData, setQRCodeData] = useState(null);
    const [status, setStatus] = useState('Connecting...');
    const [loading, setLoading] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState(false);

    useEffect(() => {
        console.log('data.device_name:', data.device_name);
        const socket = io(apiUrl === 'http://localhost:3001' ? 'http://localhost:3001' : 'https://api.oneklicks.com');

        socket.on(`statusUpdate_${data.device_name}`, (statusData) => {
            if (statusData.clientId === data.device_name) {
                setStatus(statusData.status);
                if (statusData.status === 'ready') {
                    setConnectionStatus(true);
                    updateStatus(statusData.status, data.device_name, statusData.whatsappNumber);
                }
            }
        });

        if (data && data.device_name) {
            createQRCode(socket);
        }

        return () => {
            socket.off(`qrCode_${data.device_name}`);
            socket.off(`statusUpdate_${data.device_name}`);
            socket.disconnect();
        };
    }, [data.device_name]);


    const formatPhoneNumber = (chatId) => {
        // Remove the '@c.us' suffix and the country code '91'
        const phoneNumber = chatId.replace('@c.us', '').slice(2); // Remove the '91' country code
        return phoneNumber;
    };

    const updateStatus = async (status, device_name, whatsappNumber) => {
        const tableSchema = database_id;
        const tableName = "whatsapp_qr_device";
        const data = { status, whatsappNumber: formatPhoneNumber(whatsappNumber) };
        const condition = { device_name };
        const updateStatus = await oneclick.update(tableSchema, tableName, data, condition);
        if (updateStatus.success) {
            console.log("Status Updated Successfully");
            setLoadQRData(true);
            hide();
        } else {
            console.error("Error in updateStatus:", updateStatus.message);  
        }
    };

    const createQRCode = (socket) => {
        setLoading(true);
        setStatus('Generating QR code...');
        socket.emit('initialize-whatsapp', { clientId: data.device_name });

        socket.on(`qrCode_${data.device_name}`, (response) => {
            console.log(`qrCode_${data.device_name} :`, response);
            if (response && response.clientId === data.device_name) {
                setQRCodeData(response.qrCode);
                setStatus('Connected');
                setLoading(false);
            }
        });
    };

    return (
        <div
            className={`modal fade ${show ? "show" : ""}`}
            style={{ display: show ? "block" : "none" }}
            tabIndex="-1"
            aria-labelledby="addNewLabel"
            aria-hidden={!show}
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addNewLabel">
                            WhatsApp QR Code
                        </h5>
                        <button type="button" className="btn-close" onClick={hide}>
                            <i className="fa fa-close"></i>
                        </button>
                    </div>
                    <div className="modal-body" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        textAlign: 'center'
                    }}>
                        <p>{status}</p>
                        {loading ? (
                            <div>Loading QR Code...</div>
                        ) : (
                            connectionStatus ? (
                                <img src="https://cdn.dribbble.com/users/1751799/screenshots/5512482/check02.gif" alt="whatsapp" />
                            ) : (
                                <QRCodeCanvas value={qrCodeData} size={256} />
                            )
                        )}
                    </div>
                    <div className="modal-footer">
                        <ul className="information-list border-0 p-0">
                            <li>
                                <p>1. Open WhatsApp on your phone</p>
                            </li>
                            <li>
                                <p>2. Tap Menu or Settings and select Linked Devices</p>
                            </li>
                            <li>
                                <p>3. Point your phone to this screen to capture the code</p>
                            </li>
                        </ul>
                        <div className="mt-4">
                            <img src="https://static.whatsapp.net/rsrc.php/v3/yB/r/7Y1jh45L_8V.png" alt="whatsapp" />
                        </div>
                        <button type="button" className="btn btn-secondary" onClick={hide}>
                            Close
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => createQRCode(io(apiUrl === 'http://localhost:3001' ? 'http://localhost:3001' : 'https://api.oneklicks.com'))}>
                            Generate New QR Code
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QRShow;
