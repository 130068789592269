import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decodeBase64 } from "../../services/apiService";
import { oneclick } from "../../oneclick";
import database_id from "../../utils/databaseConfig";
import ProjectCard from "./Compontes/ProjectTitle";
import Paginationnew from "../../utils/Paginationnew";
import ProjuctOverview from "./Compontes/Overview";


const ProjuctDeatails = () => {
    const [projuct, setProjuct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAddProjuctModal, setShowAddProjuctModal] = useState(false);
    const [selectTabe, setSelectTabe] = useState('overview');
    const { id } = useParams();
    const decodedId = decodeBase64(id);

    const loadData = async () => {
        try {
            const tableSchema = database_id;
            const tableName = 'projucts';
            const conditions = { id: decodedId };
            const orderBy = "id DESC";
            const search = {};
            const pagination = { page: 1, limit: 1 };
            const margedata = [
                {
                    target_label: "clients",
                    target_table: "clients",
                    target_column: "id",
                    target_value: "user_id"
                }
            ];
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
            console.log('Projuct data:', response);
            setProjuct(response.data[0]);
        } catch (error) {
            console.error('Failed to load data:', error);
            setError('Failed to load data');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {

        loadData();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
            <div className="dashboard_container">
                <section>
                    <div className="container-fluid p-0">
                        <div className="card">
                            <ProjectCard projuct={projuct} />

                            <div className="card-filter d-flex align-items-center" style={{ gap: '10px' }}>
                                <button
                                    type="button"
                                    className={`i-btn btn--md ${selectTabe == 'overview' ?  'text-white primary--btn' : 'text-black'}`}
                                    onClick={() => setSelectTabe('overview')}
                                >
                                    Overview
                                </button>

                                <button
                                    type="button"
                                    className={`i-btn btn--md ${selectTabe == 'users' ?  'text-white primary--btn' : 'text-black'}`}
                                    onClick={() => setSelectTabe('users')}
                                >
                                    Users
                                </button>
                                <button
                                    type="button"
                                    className={`i-btn btn--md ${selectTabe == 'milestones' ?  'text-white primary--btn' : 'text-black'}`}
                                    onClick={() => setSelectTabe('milestones')}
                                >
                                    Milestones
                                </button>
                                <button
                                    type="button"
                                    className={`i-btn btn--md ${selectTabe == 'tasks' ?  'text-white primary--btn' : 'text-black'}`}
                                    onClick={() => setSelectTabe('tasks')}
                                >
                                    Tasks
                                </button>
                                <button
                                    type="button"
                                    className={`i-btn btn--md ${selectTabe == 'notes' ?  'text-white primary--btn' : 'text-black'}`}
                                    onClick={() => setSelectTabe('notes')}
                                >
                                    Notes
                                </button>
                                <button
                                    type="button"
                                    className={`i-btn btn--md ${selectTabe == 'invoices' ?  'text-white primary--btn' : 'text-black'}`}
                                    onClick={() => setSelectTabe('invoices')}
                                >
                                    Invoices
                                </button>
                                <button
                                    type="button"
                                    className={`i-btn btn--md ${selectTabe == 'settings' ?  'text-white primary--btn' : 'text-black'}`}
                                    onClick={() => setSelectTabe('settings')}
                                >
                                    Settings
                                </button>
                            </div>

                            <div className="card-body px-0">
                                <div className="responsive-table">
                                    {selectTabe == 'overview' && (
                                        <ProjuctOverview projuct={projuct}/>
                                    )}
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </div>
    );
};

export default ProjuctDeatails;