import React, { useState, useEffect } from "react";
import { getuserdata } from "../../../services/apiService";
import { oneclick } from "../../../oneclick";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const CustomAttributesEditeForm = ({ filteredAttributesDATA, attributes, setAttributes }) => {
    const [filteredAttributes, setFilteredAttributes] = useState([]);

    const fetchData = async () => {
        setFilteredAttributes(filteredAttributesDATA);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (filteredAttributes.length > 0) {
            const initialAttributes = {};
            filteredAttributes.forEach((attribute) => {
                initialAttributes[attribute.name] = {
                    value: attribute.value,
                    type: attribute.type || "",
                };
            });
            setAttributes(initialAttributes);
        }
    }, [filteredAttributes, setAttributes]);

    const handleChange = (e) => {
        const { name, value, id } = e.target;
        setAttributes((prevAttributes) => ({
            ...prevAttributes,
            [name]: {
                id: id,
                value: value,
            },
        }));
    };

    const renderInput = (attribute) => {
        const { id, name, type } = attribute;
        switch (type) {
            case "1":
                return (
                    <input
                        type="date"
                        className="form-control mb-3 flatpicker flatpickr-input"
                        id={id}
                        name={name}
                        value={attributes[name]?.value || ""}
                        onChange={handleChange}
                        placeholder={`Choose Contact ${name}`}
                    />
                );
            case "2":
                return (
                    <select
                        className="form-select mb-3"
                        id={id}
                        name={name}
                        value={attributes[name]?.value || ""}
                        onChange={handleChange}
                    >
                        <option selected disabled>
                            -- Select An Option --
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                );
            case "3":
                return (
                    <input
                        type="number"
                        className="form-control mb-3"
                        id={id}
                        name={name}
                        value={attributes[name]?.value || ""}
                        onChange={handleChange}
                        placeholder={`Enter Contact ${name}`}
                    />
                );
            default:
                return (
                    <input
                        type="text"
                        className="form-control mb-3"
                        id={id}
                        name={name}
                        value={attributes[name]?.value || ""}
                        onChange={handleChange}
                        placeholder={`Enter Contact ${name}`}
                    />
                );
        }
    };

    return (
        <div>
            {filteredAttributes.length !== 0 ? (
                <div className="form-wrapper my-3 border--dark" style={{ borderRadius: "5px" }}>
                    <div className="row">
                        <div className="form-wrapper-title">
                            Update Attribute Data
                            <Link
                                to="/contact_attribute"
                                rel="noopener noreferrer"
                            >
                                <i style={{ fontSize: "15px" }} className="text--primary las la-external-link-alt"></i>
                            </Link>
                        </div>
                        {filteredAttributes.map((attribute) => (
                            <div className="form-item col-lg-6 mb-3" key={attribute.name}>
                                <label htmlFor={attribute.name} className="form-label">
                                    {attribute.name
                                        ? attribute.name.replace(/_/g, " ")
                                        : "Unknown Key"}
                                </label>
                                {renderInput(attribute)}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="form-item my-3">
                    <Link
                        to="/contact_attribute"
                        rel="noopener noreferrer"
                    >
                        <i style={{ fontSize: "15px" }} className="text--primary las la-external-link-alt"></i>
                        Add More Attributes
                    </Link>
                </div>
            )}
        </div>
    );
};

export default CustomAttributesEditeForm;
