import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { sendEmail } from '../../../services/apiService'; // Import sendEmail function
import ImageComponent from '../../../utils/ImageComponent';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const UserInfo = ({ show, handleClose, userdata, companyData }) => {
    const [newRole, setNewRole] = useState({ userstatus: '' }); // State to hold the new role information
    const [enteredOtp, setEnteredOtp] = useState(''); // State to hold the user's entered OTP
    const [otpSent, setOtpSent] = useState(false); // State to check if OTP has been sent
    const [otpTimer, setOtpTimer] = useState(0); // State for the OTP timer
    const [otpResent, setOtpResent] = useState(false); // State to check if OTP was resent
    const [successMessage, setSuccessMessage] = useState(''); // State to hold the success message

    // Handle role selection and OTP sending
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewRole((prev) => ({ ...prev, [name]: value }));

        if (value && !otpSent) {
            sendNewOtp(); // Send OTP if role is selected
        }
    };

    // Function to send OTP
    const sendNewOtp = async () => {
        const generatedOtp = Math.floor(100000 + Math.random() * 900000).toString(); // Generate a 6-digit OTP
        await sendOtpEmail(generatedOtp);
    };

    // Send OTP via email
    const sendOtpEmail = async (otp) => {
        try {
            const parsedConfig = companyData?.smtp_config ? JSON.parse(companyData.smtp_config) : {};
            const mailconfig = {
                host: parsedConfig.host,
                port: parsedConfig.port,
                secure: false,
                auth: {
                    user: parsedConfig.auth.user,
                    pass: parsedConfig.auth.pass,
                },
            };
            const email = companyData.email;
            const subject = 'Your OTP for User Verification';
            const text = `Hello ${userdata.name},\n\nYour OTP for verification is: ${otp}.\n\nThank you,\n${companyData.name}`;

            const response = await sendEmail(mailconfig, email, subject, text);
            if (response.success) {
                alert('OTP sent successfully!');
                setOtpSent(true);
                localStorage.setItem('otp', otp); // Save OTP to local storage for verification
                startOtpTimer(); // Start the OTP timer
            } else {
                alert('Failed to send OTP.');
            }
        } catch (error) {
            alert('Failed to send OTP.');
        }
    };

    // Start OTP timer
    const startOtpTimer = () => {
        setOtpTimer(60); // Set timer for 60 seconds
        setOtpResent(false); // Reset resend flag

        const timer = setInterval(() => {
            setOtpTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    return 0; // Stop timer when it reaches 0
                }
                return prev - 1; // Decrease timer
            });
        }, 1000); // Update every second
    };

    // Verify the entered OTP
    const handleSubmit = async () => {
        const savedOtp = localStorage.getItem('otp'); // Get the OTP from local storage
        if (enteredOtp !== savedOtp) {
            alert('Invalid OTP. Please try again.');
            return;
        }
        const tableSchema = database_id;
        const tablecompanies = "users";
        const tabledata = {
        'staff_status': newRole.userstatus
        };
        const conditions = { "id": userdata.id };
        const updatedata = await oneclick.update(tableSchema, tablecompanies, tabledata, conditions);
        console.log('Submitting new role:', newRole);
        localStorage.removeItem('otp'); // Remove OTP from local storage after verification
        setSuccessMessage('Your role has been updated successfully!'); // Set success message
        setTimeout(() => {
            setSuccessMessage(''); // Clear message after 3 seconds
            handleClose(); // Close modal after submission
        }, 3000);
    };

    // Handle resend OTP
    const handleResendOtp = async () => {
        if (otpTimer === 0) {
            sendNewOtp(); // Resend OTP
        } else {
            alert('You can only resend the OTP after the timer expires.');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>User Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center">
                    {/* Left Side: User Photo */}
                    <div className="me-3">
                        <ImageComponent fileid={userdata.picture} width={80} height={80} borderRadius={50} boxShadow={'1px 1px 1px black'} />
                    </div>
                    {/* Right Side: User Information */}
                    <div>
                        <h5>{userdata.name}</h5>
                        <p>Email: {userdata.email}</p>
                        <p>Phone: {userdata.phone}</p>
                    </div>
                </div>
                <Form className="mt-3">
                    {/* Status Selector */}
                    <Form.Group controlId="roleBranch">
                        <Form.Label>Select Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="userstatus"
                            value={newRole.userstatus}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a Status</option>
                            <option key="active" value="active">Active</option>
                            <option key="pending" value="pending">Pending</option>
                            <option key="resign" value="resign">Resign</option>
                            <option key="terment" value="terment">Terminated</option>
                            <option key="black" value="black">Block</option>
                        </Form.Control>
                    </Form.Group>
                    {/* OTP Input Field */}
                    {otpSent && (
                        <Form.Group controlId="otpInput" className="mt-3">
                            <Form.Label>Enter OTP</Form.Label>
                            <Form.Control
                                type="text"
                                value={enteredOtp}
                                onChange={(e) => setEnteredOtp(e.target.value)}
                                placeholder="Enter the OTP sent to your email"
                                required
                            />
                            <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p>Timer: {otpTimer > 0 ? `${otpTimer} seconds remaining` : 'OTP expired!'}</p>
                                <Button variant="link" onClick={handleResendOtp} disabled={otpTimer > 0}>
                                    Resend OTP
                                </Button>
                            </div>

                        </Form.Group>
                    )}
                    {/* Success Message */}
                    {successMessage && (
                        <Alert variant="success" className="mt-3">
                            {successMessage}
                        </Alert>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Verify & Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserInfo;
