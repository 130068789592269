// BugReport.js
import React, { useState } from 'react';
import axios from 'axios';

const AiBugReport = () => {
    const [bugDescription, setBugDescription] = useState('');
    const [fixSuggestion, setFixSuggestion] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleBugSubmit = async () => {
        if (!bugDescription.trim()) return;

        setIsLoading(true);

        try {
            const response = await axios.post('http://localhost:3000/api/bugfix/detect-bug', {
                bugDescription,
            });

            setFixSuggestion(response.data.fix);
        } catch (error) {
            console.error('Error fetching fix:', error);
            setFixSuggestion('Sorry, we could not detect or fix the bug.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bug-report">
            <h3>Report a Bug</h3>
            <textarea
                value={bugDescription}
                onChange={(e) => setBugDescription(e.target.value)}
                placeholder="Describe the bug..."
            />
            <button onClick={handleBugSubmit} disabled={isLoading}>
                {isLoading ? 'Fixing...' : 'Submit Bug'}
            </button>

            {fixSuggestion && (
                <div className="fix-suggestion">
                    <h4>Fix Suggestion:</h4>
                    <p>{fixSuggestion}</p>
                </div>
            )}
        </div>
    );
};

export default AiBugReport;
