import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';


const AddRoleModal = ({ show, handleClose, companyId, branches }) => {
    const [newRole, setNewRole] = useState({
        roleName: '',
        branchId: '', // Added branchId to the state
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewRole((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        try {
            // Make an API call to submit data
            //   await addNewRole(newRole);
            const tableSchema = database_id;
            const tableName = "roles";
            const data = {
                company_id: companyId,
                branch_id: newRole.branchId,
                name: newRole.roleName
            };
            const update = await oneclick.create(tableSchema, tableName, data);
            if(update.success){
                alert(update.message || 'Role added successfully.');
                handleClose(); // Close the modal on successful submit
            }else{
                console.error("Error updating branch:");
                alert(update.error || 'Error updating branch.');
            }
            
        } catch (error) {
            console.error('Error adding role:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="roleName">
                        <Form.Label>Role Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="roleName"
                            value={newRole.roleName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Branch Selector */}
                    <Form.Group controlId="roleBranch">
                        <Form.Label>Select Branch</Form.Label>
                        <Form.Control
                            as="select"
                            name="branchId"
                            value={newRole.branchId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a branch</option>
                            {branches.map((branch) => (
                                <option key={branch.id} value={branch.id}>
                                    {branch.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Add Role
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddRoleModal;
