import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import CustomAttributesForm from './CustomAttributesForm';
import CustomAttributesEditeForm from './CustomAttributesEditeForm';
import { oneclick } from '../../../oneclick';
import { getuserdata } from '../../../services/apiService';
import database_id from '../../../utils/databaseConfig';

const UpdateContactModal = ({ onClose, contactData, groups, fetchData }) => {
  // State to handle form inputs
  const [firstName, setFirstName] = useState(contactData?.first_name || '');
  const [lastName, setLastName] = useState(contactData?.last_name || '');
  const [whatsappContact, setWhatsappContact] = useState(contactData?.whatsapp_contact || '');
  const [smsContact, setSmsContact] = useState(contactData?.sms_contact || '');
  const [emailContact, setEmailContact] = useState(contactData?.email_contact || '');
  const [status, setStatus] = useState(contactData?.status || '1');
  const [groupId, setGroupId] = useState(contactData?.group_id || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attributes, setAttributes] = useState({});

  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [loading, setLoading] = useState(true);  // For loading state
  const [error, setError] = useState(null);      // For error state


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable submit button during submission

    const formData = {
      _token: process.env.REACT_APP_CSRF_TOKEN, // You should set this from your environment or state
      uid: contactData?.uid,
      first_name: firstName,
      last_name: lastName,
      whatsapp_contact: whatsappContact,
      sms_contact: smsContact,
      email_contact: emailContact,
      status,
      group_id: groupId
    };

    const normalizeKeys = (attributes) => {
      const normalized = {};
      Object.keys(attributes).forEach((key) => {
        const newKey = key
          .toLowerCase() // Convert to lowercase
          .replace(/ /g, "_") // Replace spaces with underscores
          .replace(/[^\w]/g, ""); // Remove non-alphanumeric characters

        normalized[newKey] = attributes[key];
      });
      return normalized;
    };

    // Make the API request to update contact (POST request)
    try {
      const tableSchema = database_id;
      const tableName = "contacts";
      const user_id = await getuserdata("id");
      const company_id = await getuserdata("company_id");
      const condition = { id: contactData.id };
      const normalizedAttributes = normalizeKeys(attributes);

      const data = {
        group_id: formData.group_id,
        attributes: JSON.stringify(normalizedAttributes),
        whatsapp_contact: formData.whatsapp_contact,
        email_contact: formData.email_contact,
        sms_contact: formData.sms_contact,
        last_name: formData.last_name,
        first_name: formData.first_name,
        status: formData.status,
      };

      const updateresponce = await oneclick.update(tableSchema, tableName, data, condition);

      console.log('updateresponce:', updateresponce);
      if (updateresponce.success) {
        alert('Contact updated successfully');

        const tableName = "contacts_attributes";  // Table for inserted/updated data
        const user_id = await getuserdata("id");
        const company_id = await getuserdata("company_id");
        const contacts_id = contactData.id;

        const normalizedAttributes = normalizeKeys(attributes);

        // Ensure that filteredAttributes is properly checked for empty or null values
        if (filteredAttributes && filteredAttributes.length > 0) {
          // Update logic
          for (const [attributeName, attributeData] of Object.entries(normalizedAttributes)) {
            if (attributeData.id) {
              const data = {
                value: attributeData.value,  // Use the value from the attribute data
              };

              const updateCondition = { id: attributeData.id };  // Ensure to match the row using `id`
              try {
                const updateResponse = await oneclick.update(tableSchema, tableName, data, updateCondition);
                console.log(`Update Response for ${attributeName}:`, updateResponse);
              } catch (error) {
                console.error(`Error updating ${attributeName}:`, error);
                alert(`Failed to update attribute ${attributeName}`);
              }
            }
          }
        } else {
          // Create logic (in case of new attributes)
          for (const [attributeName, attributeData] of Object.entries(normalizedAttributes)) {
            const data = {
              user_id: user_id,
              company_id: company_id,
              contact_id: contacts_id,
              name: attributeName,  // Use the attribute name as 'name'
              value: attributeData.value,  // Use the value from the attribute data
              type: attributeData.type,  // Use the type from the attribute data
            };

            try {
              // Call your oneclick API to insert the data into the table
              const insertResponse = await oneclick.create(tableSchema, tableName, data, "");
              console.log("Insert Response:", insertResponse);
            } catch (error) {
              console.error(`Error inserting attribute ${attributeName}:`, error);
              alert(`Failed to create attribute ${attributeName}`);
            }
          }
        }

        onClose(); // Close the modal after successful update
        fetchData(); // Refresh data if needed (optional)
      } else {
        alert('Failed to update contact');
      }

    } catch (error) {
      console.error('Error updating contact:', error);
      alert('Error updating contact');
    } finally {
      setIsSubmitting(false); // Re-enable submit button
    }
  };

  const facedata = async () => {
    const tableSchema = database_id;
    const tableName = "contacts_attributes";
    const tableNameattributes = "attribute";
    const company_id = await getuserdata("company_id");

    const condition = { contact_id: contactData.id };
    const conditionattributes = { company_id: company_id };
    const data = await oneclick.read(
      tableSchema,
      tableName,
      condition,
      "id ASC",
      "",
      ""
    );

    if (data?.data) {
      setFilteredAttributes(data.data); // Update the state with fetched dat
    } else {
      setError("No data found.");
    }
  }

  useEffect(() => {
    facedata();
    console.log('setFilteredAttributes:', filteredAttributes);
  }, [contactData?.id]);

  return (
    <div className="modal fade show" id="updateContact" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style={{ display: 'block', background: '#0000007d' }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Contact Information</h5>
            <button type="button" className="i-btn bg--lite--danger text--danger btn--sm" onClick={onClose}>
              <i className="las la-times"></i>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <label className="form-label" htmlFor="group_id">Select a Group</label>
                      <select className="form-select" name="group_id" id="group_id" value={groupId} onChange={(e) => setGroupId(e.target.value)} required>
                        <option value="" disabled>Select One</option>
                        {groups.map(group => (
                          <option key={group.id} value={group.id}>{group.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="first_name" className="form-label"> Contact First Name <sup className="text--danger">*</sup></label>
                      <input type="text" className="form-control" id="first_name" name="first_name" placeholder=" Enter First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="last_name" className="form-label"> Contact Last Name <sup className="text--danger">*</sup></label>
                      <input type="text" className="form-control" id="last_name" name="last_name" placeholder=" Enter Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="whatsapp_contact" className="form-label"> WhatsApp Number</label>
                      <input type="text" className="form-control" id="whatsapp_contact" name="whatsapp_contact" placeholder=" Enter WhatsApp Number" value={whatsappContact} onChange={(e) => setWhatsappContact(e.target.value)} />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="sms_contact" className="form-label"> SMS Number</label>
                      <input type="text" className="form-control" id="sms_contact" name="sms_contact" placeholder=" Enter SMS Number" value={smsContact} onChange={(e) => setSmsContact(e.target.value)} />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="email_contact" className="form-label"> Email address</label>
                      <input type="text" className="form-control" id="email_contact" name="email_contact" placeholder=" Enter Email Address" value={emailContact} onChange={(e) => setEmailContact(e.target.value)} />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="status" className="form-label"> Status <sup className="text--danger">*</sup></label>
                      <select className="form-select" name="status" id="status" value={status} onChange={(e) => setStatus(e.target.value)} required>
                        <option value="1"> Active</option>
                        <option value="2"> Banned</option>
                      </select>
                    </div>
                  </div>
                  {/* Add Extra Attributes CustomAttributesForm*/}
                  {filteredAttributes.length !== 0 ? (
                    <CustomAttributesEditeForm filteredAttributesDATA={filteredAttributes} attributes={attributes} setAttributes={setAttributes} />
                  ) : (
                    <CustomAttributesForm attributes={attributes} setAttributes={setAttributes} />
                  )}

                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="d-flex align-items-center gap-3">
                <button type="button" className="i-btn danger--btn btn--md" onClick={onClose}>Cancel</button>
                <button type="submit" className="i-btn primary--btn btn--md" disabled={isSubmitting}>
                  {isSubmitting ? 'Updating...' : 'Update Contact'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateContactModal;
