import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { oneclick } from '../../../oneclick'; // Import the oneclick API service
import database_id from '../../../utils/databaseConfig';

const UpdateGroupModal = ({ onClose, data, fetchData }) => {
  const [groupName, setGroupName] = useState(data.name);
  const [status, setStatus] = useState(data.status);

  // Handle form field changes
  const handleGroupNameChange = (e) => setGroupName(e.target.value);
  const handleStatusChange = (e) => setStatus(e.target.value);

  // Update the group via the API
  const handleUpdate = async () => {
    const updatedData = {
      name: groupName,
      status: status === 'active' ? '1' : '2', // Assuming '1' for Active and '2' for Inactive
    };

    const tableSchema = database_id;
    const tableName = 'groups';
    const condition = { id: data.id }; // Update based on the group id

    try {
      const response = await oneclick.update(tableSchema, tableName, updatedData, condition);
      if (response.success) {
        fetchData(); // Fetch updated data after successful update
        onClose(); // Close the modal after updating
      } else {
        alert('Failed to update group. Please try again.');
      }
    } catch (error) {
      console.error('Error updating group:', error);
      alert('An error occurred while updating the group.');
    }
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="updateGroupName">
            <Form.Label>Group Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Update group name"
              value={groupName}
              onChange={handleGroupNameChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="updateStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control as="select" value={status} onChange={handleStatusChange} required>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateGroupModal;
