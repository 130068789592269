import React, { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import { faCoffee, faUser, faHeart } from '@fortawesome/free-solid-svg-icons'; // Example icons
import { decodeBase64, getuserdata } from "../../services/apiService";
import IconPicker from "../Settings/Component/IconPicker";
import { OneKlicksFileSDK } from "../../OneKlicksFileSDK";
import { oneclick } from "../../oneclick";
import database_id from "../../utils/databaseConfig";

const ElementModel = ({ frontendSectionElement }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { section_name, elementdata, code, data, id } = useParams();
  const [name, setPagename] = useState(decodeBase64(section_name));
  const [sectionDatastring, setElementdatastring] = useState(decodeBase64(elementdata));
  const [presintdata, setPresintdata] = useState(decodeBase64(data));
  const [sectionData, setElementdata] = useState(JSON.parse(sectionDatastring));
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (frontendSectionElement) {
      setFormData(prevState => ({
        ...prevState,
        id: frontendSectionElement.id
      }));
    }
    if (presintdata) {
      setFormData(JSON.parse(presintdata));
    }
  }, [frontendSectionElement, data]);

  const handleInputChange = async (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files[0];
      // Validate file type and size (optional)
      if (file && !['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        alert("Invalid file type. Please upload a jpeg, png, or jpg image.");
        return;
      }
      if (file && file.size > 5 * 1024 * 1024) { // 5MB limit
        alert("File size too large. Please upload an image smaller than 5MB.");
        return;
      }
      const userId = await getuserdata('id');
      const root = 'frontend/elements';
      const urllink = '';
      const uploadimage = await OneKlicksFileSDK.uploadFile(userId, root, urllink, file);
      console.log('uploadimage', uploadimage);
      if (uploadimage.success) {
        console.log('Data updated successfully:', uploadimage);
        alert('Data updated successfully');
        setFormData({
          ...formData,
          images: {
            ...formData.images,
            [name]: uploadimage.file.id
          }
        });
      } else {
        console.error('Error updating data:', uploadimage);
        alert('Error updating data :' + uploadimage.message);
      }

    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleIconSelect = (icon) => {
    setFormData({
      ...formData,
      icon: icon // Store the icon class name directly
    });
  };

  const validateForm = () => {
    let formErrors = {};
    Object.keys(formData).forEach(key => {
      if (key !== 'images' && !formData[key]) {
        formErrors[key] = 'This field is required.';
      }
    });
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Submit the form data
      const jsonString = JSON.stringify(formData, null, 2); // Convert object to string with formatting
      console.log('Stringified JSON:', jsonString);
      console.log('formData', jsonString);
      const tableSchema = database_id;
      const tableName = 'frontend_sections';
      const data = {
        section_key: `${code}.element_content`,
        section_value: jsonString
      };
      let datacreate = {};
      if (presintdata) {
        console.log('presintdata', presintdata);
        const condition = {
          id: id
        }
        datacreate = await oneclick.update(tableSchema, tableName, data, condition);
        console.log('datacreate', datacreate);
        if (datacreate.success) {
          console.log('Data updated successfully:', datacreate);
          alert('Data updated successfully');
          navigate(`/frontend_sections/${code}`);
        } else {
          console.error('Error updating data:', datacreate);
          alert('Error updating data :' + datacreate.message);
        }
      } else {
        datacreate = await oneclick.create(tableSchema, tableName, data, '');
        console.log('datacreate', datacreate);
        if (datacreate.success) {
          console.log('Data updated successfully:', datacreate);
          alert('Data updated successfully');
          navigate(`/frontend_sections/${code}`);
        } else {
          console.error('Error updating data:', datacreate);
          alert('Error updating data :' + datacreate.message);
        }
      }
    }
  };

  return (
    <section>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{name} Elements</h4>
        </div>

        <div className="card-body">
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-wrapper">
              <div className="row g-4">
                {Object.keys(sectionData).map((key, index) => {
                  if (key === "images") {
                    // Handle images separately
                    return Object.keys(sectionData[key]).map((imageKey) => (
                      <div className="col-md-6" key={imageKey}>
                        <div>
                          <label htmlFor={imageKey} className="form-label">
                            {imageKey.replace(/_/g, " ").toUpperCase()} <sup className="text--danger">*</sup>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id={imageKey}
                            name={imageKey}
                            onChange={handleInputChange}
                            required
                          />
                          <small>
                            Supported file formats: jpeg, jpg, png. <br />
                            <a href="#" target="__blank">View image</a>
                          </small>
                          {errors[imageKey] && <div className="text-danger">{errors[imageKey]}</div>}
                        </div>
                      </div>
                    ));
                  } else {
                    // Handle other input fields
                    return (
                      <div className={`col-md-${key === "texteditor" ? "12" : "6"}`} key={key}>
                        <div className="position-relative">
                          <label htmlFor={key} className="form-label">
                            {key.replace(/_/g, " ").toUpperCase()} <sup className="text--danger">*</sup>
                          </label>
                          {key === "icon" ? (
                            <IconPicker icon={formData[key]} setIcon={handleIconSelect} />
                          ) : key === "texteditor" ? (
                            <CKEditor
                              editor={ClassicEditor}
                              data={formData[key] || ""}
                              onChange={(event, editor) => {
                                setFormData({
                                  ...formData,
                                  [key]: editor.getData(),
                                });
                              }}
                            />
                          ) : key === "textarea" ? (
                            <textarea
                              className="form-control"
                              name={key}
                              value={formData[key] || ""}
                              onChange={handleInputChange}
                              required
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              id={key}
                              name={key}
                              value={formData[key] || ""}
                              onChange={handleInputChange}
                              required
                            />
                          )}
                          {errors[key] && <div className="text-danger">{errors[key]}</div>}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <button type="submit" className="i-btn primary--btn btn--md ">
                Submit
              </button>

            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ElementModel;
