import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { oneclick } from '../../../oneclick';
import bcrypt from 'bcryptjs';
import database_id from '../../../utils/databaseConfig';

const AddStaffModal = ({ show, handleClose, companyId, branches }) => {
  const [newStaff, setNewStaff] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
    branchId: '', // Added branchId to the state
    company_id: companyId || '', // Set company_id from props or default to empty string
  });


  useEffect(() => {
    // If the companyId prop changes, update the company_id in newStaff
    setNewStaff((prev) => ({ ...prev, company_id: companyId || '' }));
  }, [companyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewStaff((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      // Hash the password
      const saltRounds = 10;
      const hashed = await bcrypt.hash(newStaff.password, saltRounds);
  
      // Prepare data
      const tableSchema = database_id;
      const tableName = "users";
      const data = {
        name: newStaff.fullName,
        email: newStaff.email,
        phone_number: newStaff.phoneNumber,
        company_id: companyId,
        password: hashed,
        branch_id: newStaff.branchId,
      };

      const conditions = { email: newStaff.email, phone_number: newStaff.phoneNumber };
      const orderBy = "created_at DESC";
  
      // Call the API to create/update the user
      const update = await oneclick.create(tableSchema, tableName, data, conditions);
      
      const tableName11 = "employees";
      const data11 = {
        userid: update.insertedData.id,
        full_name: newStaff.fullName,
        email: newStaff.email,
        phone_number: newStaff.phoneNumber,
        company_id: companyId,
        branch_id: newStaff.branchId,
      };

      const updateemply = await oneclick.create(tableSchema, tableName11, data11, "");
  
      
  
      console.log('Check Staff User Data:', update);
  
      if (update.success === true) {
        // If successful, show success message
        
  
        // Refresh the page data (optional)
        //refreshPageData(); // Call a function that refetches the data
  
        // Reset form fields by setting newStaff back to its initial values
        setNewStaff({
          fullName: '',
          email: '',
          phone_number: '',
          password: '',
          branch_id: ''
        });

        alert(update.message || 'Staff added successfully.');
        // Reload the page to refresh data and reset the form
        window.location.reload(); 
  
        // Close the modal
        handleClose(); 
      } else {
        // If the response indicates failure, show the error message
        alert(update.message || 'Error: Unable to update branch.');
        console.error("Error updating branch:", update);
      }
    } catch (error) {
      // Log and handle any errors during the process
      console.error('Error adding staff:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Staff</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="staffFullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              value={newStaff.fullName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="staffEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={newStaff.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="staffPhoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              name="phoneNumber"
              value={newStaff.phoneNumber}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="staffPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={newStaff.password}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {/* Branch Selector */}
          <Form.Group controlId="staffBranch">
            <Form.Label>Select Branch</Form.Label>
            <Form.Control
              as="select"
              name="branchId"
              value={newStaff.branchId}
              onChange={handleChange}
              required
            >
              <option value="">Select a branch</option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Add Staff
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddStaffModal;
