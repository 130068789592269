import React, { useState, useEffect } from 'react';
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import { decodeBase64 } from '../../services/apiService';
import { oneclick } from '../../oneclick';
import database_id from '../../utils/databaseConfig';

const HostingDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { planedata } = useParams();
    const [planDatastring, setPlanDatastring] = useState(decodeBase64(planedata));
    const [emailPrefix, setEmailPrefix] = useState('');
    const [hostingplanedata, setHostingplanedata] = useState({});
    const [hostingDetails, setHostingDetails] = useState({});
    const [emailPassword, setEmailPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [activeTab, setActiveTab] = useState('Information');
    const [activeAction, setActiveAction] = useState(null);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const fetchData = async () => {
        // Fetch data from API
        const tableSchema = database_id;
        const tableName = 'hostings';
        const conditions = { id: planDatastring };
        const orderBy = 'id DESC';
        const search = '';
        const pagination = { page: 1, limit: 100 };
        const margedata = [
            {
                target_label: "plandata",
                target_table: "frontend_sections",
                target_column: "id",
                target_value: "plan_id"
            }
        ];
        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
        if (response.success) {
            const hostingData = response.data[0].plandata.section_value;
            console.log('Hosting details:', JSON.parse(hostingData));
            setHostingplanedata(JSON.parse(hostingData));
            setHostingDetails(response.data[0]);
        } else {
            console.error('Error fetching hosting details:', response.error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleActionClick = (actionName) => {
        setActiveAction(actionName);
    };

    const handleEmailCreate = (e) => {
        e.preventDefault();

        // Simulate email creation logic
        const isSuccess = Math.random() > 0.5; // Simulate success or failure randomly
        if (isSuccess) {
            setSuccessMessage('Email account created successfully!');
            setErrorMessage('');
        } else {
            setSuccessMessage('');
            setErrorMessage('Email account creation failed: Some error occurred.');
        }
    };


    const features = [
        { id: 'cPanelEmailAccounts', label: 'Email Accounts', imgSrc: '/assets/file/images/email_accounts.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Email_Accounts' },
        { id: 'cPanelForwarders', label: 'Forwarders', imgSrc: '/assets/file/images/forwarders.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Email_Forwarders' },
        { id: 'cPanelAutoResponders', label: 'Autoresponders', imgSrc: '/assets/file/images/autoresponders.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Email_AutoResponders' },
        { id: 'cPanelFileManager', label: 'File Manager', imgSrc: '/assets/file/images/file_manager.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=FileManager_Home' },
        { id: 'cPanelBackup', label: 'Backup', imgSrc: '/assets/file/images/backup.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Backups_Home' },
        { id: 'cPanelSubdomains', label: 'Subdomains', imgSrc: '/assets/file/images/subdomains.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Domains_SubDomains' },
        { id: 'cPanelAddonDomains', label: 'Addon Domains', imgSrc: '/assets/file/images/addon_domains.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Domains_AddonDomains' },
        { id: 'cPanelCronJobs', label: 'Cron Jobs', imgSrc: '/assets/file/images/cron_jobs.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Cron_Home' },
        { id: 'cPanelMySQLDatabases', label: 'MySQL® Databases', imgSrc: '/assets/file/images/mysql_databases.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Database_MySQL' },
        { id: 'cPanelPhpMyAdmin', label: 'phpMyAdmin', imgSrc: '/assets/file/images/php_my_admin.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Database_phpMyAdmin' },
        { id: 'cPanelAwstats', label: 'Awstats', imgSrc: '/assets/file/images/awstats.png', link: 'clientarea.php?action=productdetails&id=3612&dosinglesignon=1&app=Stats_AWStats' }
    ];

    const handleSubmit = (e) => {
        e.preventDefault();

        // Mock function to create email (replace with real API call as needed)
        const createEmailAccount = () => {
            // Simulate success or failure
            return Math.random() > 0.5;
        };

        const result = createEmailAccount();

        if (result) {
            setSuccessMessage('Email account created successfully!');
            setErrorMessage('');
            setEmailPrefix('');
            setEmailPassword('');
        } else {
            setErrorMessage('Email account creation failed: please try again.');
            setSuccessMessage('');
        }
    };

    return (
        <div className='row d-flex'>

            <div className="col-lg-9 col-md-12">

                <div className="header-lined">
                    <h1>{hostingDetails.domain}</h1>
                    <hr />

                </div>

                <div style={{ backgroundColor: '#080245', marginBottom: '25px', marginTop: '5px', color: '#fff', border: '1px solid #fff', borderRadius: '5px' }}>
                    <div className="row" style={{ padding: '15px', paddingLeft: '25px', paddingRight: '0px' }}>
                        <span style={{ fontSize: '1.25rem', color: '#ceff08' }}>Nameservers</span>
                        <hr />
                        <p>Nameserver 1: in.shoutcoder.com</p>
                        <p>Nameserver 2: us.shoutcoder.com</p>
                    </div>
                </div>

                {/* Left Column */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="panel panel-default card mb-3" id="cPanelPackagePanel">
                            <div className="panel-heading card-header">
                                <h3 className="panel-title card-title m-0">Package/Domain</h3>
                            </div>
                            <div className="panel-body card-body text-center" style={{ background: 'white' }}>
                                <em>Shared Hosting - US</em>
                                <h4 style={{ margin: 0, color: '#d1180c' }}>{hostingDetails.plan_name}</h4>
                                <a href={`http://${hostingDetails.domain}`} target="_blank" rel="noopener noreferrer">www.{hostingDetails.domain}</a>
                                <div className='text-center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <a href={`http://${hostingDetails.domain}`} className="i-btn primary--btn btn--lg" target="_blank" rel="noopener noreferrer" >Visit Website</a>
                                </div>
                            </div>
                        </div>

                        {/* Addons and Extras */}
                        <div className="panel panel-default card mb-3" id="cPanelExtrasPurchasePanel">
                            <div className="panel-heading card-header">
                                <h3 className="panel-title card-title m-0">Addons and Extras</h3>
                            </div>
                            <div className="panel-body card-body text-center mx-auto" style={{ background: 'white' }}>
                                <form method="post" action="/cart.php?a=add" className="form-inline">
                                    <select name="aid" className="form-control custom-select w-100 input-sm form-control-sm mr-2">
                                        <option value="3">Dedicated IP</option>
                                    </select>
                                    <button type="submit" className="btn btn-default btn-sm btn-block mt-1" style={{ background: '#ab180c', border: '0px' }}>
                                        <i className="fas fa-shopping-cart" ></i> Purchase and Activate
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="col-md-6">
                        <div className="panel card panel-default mb-3" id="cPanelUsagePanel">
                            <div className="panel-heading card-header">
                                <h3 className="panel-title card-title m-0">Usage Statistics</h3>
                            </div>
                            <div className="panel-body card-body text-center" style={{ background: 'white' }}>
                                <div className="row">
                                    {/* Disk Usage */}
                                    <div className="col-md-6">

                                        <strong>Disk Usage</strong><br /><br />
                                        27374 M / {hostingplanedata.Disk_Space}
                                    </div>
                                    {/* Bandwidth Usage */}
                                    <div className="col-md-6">
                                        <strong>Bandwidth Usage</strong><br /><br />
                                        1252.59 M / {hostingplanedata.Data_Transfer}
                                    </div>
                                </div>
                                <div className="text-info limit-near">
                                    Last Updated Thursday, November 14th, 2024 (08:05)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Quick Shortcuts */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-default card mb-3" id="cPanelPackagePanel">
                            <div className="panel-heading card-header">
                                <h3 className="panel-title card-title m-0">Quick Shortcuts</h3>
                            </div>
                            <div className="panel-body card-body" style={{ background: 'white' }}>
                                <div className="row no-gutters">
                                    {features.map((feature) => (
                                        <div className="col-sm-6 col-md-3 text-center mt-2" key={feature.id}>
                                            <a href={feature.link}>
                                                <img src={feature.imgSrc} className="img-fluid" width="50" height="50" alt={feature.label} />
                                                <br />{feature.label}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Create Email Form */}
                <div className="panel card panel-default mb-3" id="cPanelQuickEmailPanel">
                    <div className="panel-heading card-header">
                        <h3 className="panel-title card-title m-0">Quick Create Email Account</h3>
                    </div>
                    <div className="panel-body card-body">

                        {/* Success Message */}
                        {successMessage && (
                            <div className="alert alert-success text-center email-create-feedback" id="emailCreateSuccess">
                                {successMessage}
                            </div>
                        )}

                        {/* Error Message */}
                        {errorMessage && (
                            <div className="alert alert-danger text-center email-create-feedback" id="emailCreateFailed">
                                {errorMessage}
                            </div>
                        )}

                        <form id="frmCreateEmailAccount" onSubmit={handleEmailCreate}>
                            <input type="hidden" name="id" value="3612" />
                            <input type="hidden" name="email_quota" value="250" />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-group mb-1">
                                        <input
                                            type="text"
                                            id="cpanel-email-prefix"
                                            name="email_prefix"
                                            className="form-control"
                                            placeholder="Yourname"
                                            value={emailPrefix}
                                            onChange={(e) => setEmailPrefix(e.target.value)}
                                        />
                                        <div className="input-group-addon input-group-append">
                                            <span className="input-group-text">
                                                <small>@politicalhunter.in</small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <input
                                        type="password"
                                        id="cpanel-email-password"
                                        name="email_pw"
                                        className="form-control mb-1"
                                        placeholder="Desired Password"
                                        value={emailPassword}
                                        onChange={(e) => setEmailPassword(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        <i className="fas fa-plus" id="btnCreateLoader"></i> Create
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="panel card panel-default mb-3" id="cPanelBillingOverviewPanel">
                    <div className="panel-heading card-header">
                        <h3 className="panel-title card-title m-0">Billing Overview</h3>
                    </div>
                    <div className="panel-body card-body">
                        <div className="row">
                            {/* Left Column */}
                            <div className="col-md-5">
                                <div className="row" id="recurringAmount">
                                    <div className="col-xs-6 col-6 text-right">
                                        Recurring Amount
                                    </div>
                                    <div className="col-xs-6 col-6">
                                        ₹129.00 INR
                                    </div>
                                </div>
                                <div className="row" id="billingCycle">
                                    <div className="col-xs-6 col-6 text-right">
                                        Billing Cycle
                                    </div>
                                    <div className="col-xs-6 col-6">
                                        Monthly
                                    </div>
                                </div>
                                <div className="row" id="paymentMethod">
                                    <div className="col-xs-6 col-6 text-right">
                                        Payment Method
                                    </div>
                                    <div className="col-xs-6 col-6">
                                        UPI / NetBanking / Card / Wallet
                                    </div>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div className="col-md-7">
                                <div className="row" id="registrationDate">
                                    <div className="col-xs-6 col-6 col-xl-5 text-right">
                                        Registration Date
                                    </div>
                                    <div className="col-xs-6 col-6 col-xl-7">
                                        Saturday, July 1st, 2023
                                    </div>
                                </div>
                                <div className="row" id="nextDueDate">
                                    <div className="col-xs-6 col-6 col-xl-5 text-right">
                                        Next Due Date
                                    </div>
                                    <div className="col-xs-6 col-6 col-xl-7">
                                        Sunday, December 1st, 2024
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <div className="col-lg-3 col-md-12">


                {/* Overview Panel */}
                <div
                    className="panel panel-sidebar panel-default card-light panel-actions"
                    menuitemname="Service Details Overview"
                >
                    <div className="panel-heading">
                        <h3 className="panel-title">
                            <i className="fas fa-star"></i>&nbsp;Overview
                            <i className="fas fa-chevron-up panel-minimise pull-right"></i>
                        </h3>
                    </div>
                    <div className="list-group list-group-tab-nav">
                        <a
                            href="#tabOverview"
                            className="list-group-item active"
                            data-toggle="tab"
                            id="Primary_Sidebar-Service_Details_Overview-Information"
                        >
                            Information
                        </a>
                    </div>
                </div>

                {/* Actions Panel */}
                <div
                    className="panel panel-sidebar panel-default panel-actions"
                    menuitemname="Service Details Actions"
                >
                    <div className="panel-heading">
                        <h3 className="panel-title">
                            <i className="fas fa-wrench"></i>&nbsp;Actions
                            <i className="fas fa-chevron-up panel-minimise pull-right"></i>
                        </h3>
                    </div>
                    <div className="list-group list-group-tab-nav">
                        <a
                            href="#"
                            className="list-group-item"
                            data-active="1"
                            data-identifier="cpanel"
                            data-serviceid="3612"
                            id="Primary_Sidebar-Service_Details_Actions-cpanel"
                            onClick={() => handleActionClick('cpanel')}
                        >
                            <span className="loading" style={{ display: 'none' }}>
                                <i className="fas fa-spinner fa-spin"></i>
                            </span>
                            <i className="fas fa-sign-in fa-fw sidebar-menu-item-icon"></i> Log in to cPanel
                        </a>
                        <a
                            href="#"
                            className="list-group-item"
                            data-active="1"
                            data-identifier="webmail"
                            data-serviceid="3612"
                            id="Primary_Sidebar-Service_Details_Actions-webmail"
                            onClick={() => handleActionClick('webmail')}
                        >
                            <span className="loading" style={{ display: 'none' }}>
                                <i className="fas fa-spinner fa-spin"></i>
                            </span>
                            <i className="fas fa-sign-in fa-fw sidebar-menu-item-icon"></i> Log in to Webmail
                        </a>
                        <a
                            href="#tabChangepw"
                            className="list-group-item"
                            data-toggle="tab"
                            id="Primary_Sidebar-Service_Details_Actions-Change_Password"
                            onClick={() => handleActionClick('Change_Password')}
                        >
                            <i className="fas fa-key fa-fw sidebar-menu-item-icon"></i> Change Password
                        </a>
                        <a
                            href="/index.php?m=cptools&serviceId=3612"
                            className="list-group-item"
                            id="Primary_Sidebar-Service_Details_Actions-cptools_reset"
                            onClick={() => handleActionClick('Reset cPanel')}
                        >
                            Reset cPanel
                        </a>
                        <a
                            href="/upgrade.php?type=package&id=3612"
                            className="list-group-item"
                            id="Primary_Sidebar-Service_Details_Actions-Upgrade_Downgrade"
                            onClick={() => handleActionClick('Upgrade_Downgrade')}
                        >
                            <i className="fas fa-level-up fa-fw sidebar-menu-item-icon"></i> Upgrade/Downgrade
                        </a>
                    </div>
                </div>

            </div>



        </div>





    );
};

export default HostingDetails;






