import React from 'react';
import { Button } from 'react-bootstrap';

const Paginationnew = ({ currentPage, totalPages, handlePageChange }) => {
    // Function to handle page changes
    // Function to handle page changes
    const onPageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            handlePageChange(page);
        }
    };

    // Calculate page range (5 pages around the current page)
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    return (
        <div className="pagination" style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', marginRight: '17px' }}>
            {/* Previous Button (hide if currentPage is 1) */}
            {currentPage !== 1 && (
                <Button
                    variant="outline-primary"
                    onClick={() => onPageChange(currentPage - 1)}
                    style={{ margin: '0 5px' }}
                    disabled={currentPage === 1}
                >
                    Back
                </Button>
            )}



            {/* Page Number Buttons (show only 3 pages: startPage to endPage) */}
            {totalPages > 1 &&
                Array.from({ length: totalPages }, (_, index) => {
                    const pageNumber = index + 1;
                    if (pageNumber >= startPage && pageNumber <= endPage) {
                        return (
                            <Button
                                key={pageNumber}
                                variant="outline-primary"
                                onClick={() => onPageChange(pageNumber)}
                                style={{ margin: '0 5px' }}
                                active={currentPage === pageNumber}
                            >
                                {pageNumber}
                            </Button>
                        );
                    }
                    return null;
                })}


            {/* Next Button (show if totalPages > 3) */}
            {totalPages > 3 && (
                <Button
                    variant="outline-primary"
                    onClick={() => onPageChange(currentPage + 1)}
                    style={{ margin: '0 5px' }}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            )}
        </div>
    );
};

export default Paginationnew;
