import React, { useState, useEffect } from 'react';
import { oneclick } from '../../oneclick';
import { v4 as uuidv4 } from 'uuid'; // Import uuid
import { getuserdata } from '../../services/apiService';
import database_id from '../../utils/databaseConfig';

const GenerateApiKey = () => {
  const [userdata, setUserdata] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const loginToken = localStorage.getItem('token');

  // Access the tableSchema from environment variables
  const tableSchema = database_id;

  useEffect(() => {
    
    fetchData();
  }, [loginToken, tableSchema]);

  const fetchData = async () => {
    const tableName = "users";
    const conditions = {
      id: await getuserdata("id"),
    };
    const orderBy = "id DESC";

    // Ensure the callback is a valid function

    const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, "", "", "");
    console.log("User data fetched:", response);
    if(response.success){
      setUserdata(response.data[0]);
      console.log("User data api key :", response.data[0].api_key);
      setApiKey(response.data[0].api_key);
    }
  }

  const generateApiKey = async () => {
    if (window.confirm("Your old API key will no longer work. Are you sure you want to update the API key?")) {
      const newApiKey = uuidv4(); // Generate UUID v4 key
      setApiKey(newApiKey);
  
      const tableSchema = database_id;
      const tableName = "users";
      const data = {
        "api_key": newApiKey,
      };
      const conditions = {
        "login_tockin": loginToken,
      };
  
      try {
        // Update the user's API key in the database
        const response = await oneclick.update(tableSchema, tableName, data, conditions);
        console.log("User data updated:", response);
        setUserdata(response);
      } catch (error) {
        console.error('Error updating API key:', error);
      }
    } else {
      console.log("User canceled the API key update.");
    }
  };
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    alert('API Key copied to clipboard!');
  };

  return (
    <div className="dashboard_container">
      <div className="page-title-wrapper">
        <div className="page-title-left">
          <h2 className="page-title">Documentation</h2>
        </div>
        <div className="page-title-right">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="http://localhost/getoneclick/admin/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">Generate API Key</li>
            </ol>
          </nav>
        </div>
      </div>
      <section>
        <div className="container-fluid p-0 mb-3 pb-2">
          <div className="row d-flex align--center rounded">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Generate Api Key</h4>
                </div>
                <div className="card-body">
                  <div className="row pb-3 g-3">
                    <div className="col-md-8 col-sm-12">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="apikey"
                          value={apiKey || 'No API key available'}
                          readOnly
                          aria-label="API Key"
                          aria-describedby="basic-addon2"
                        />
                        <span
                          className="input-group-text bg--info pointer"
                          onClick={copyToClipboard}
                          id="basic-addon2"
                        >
                          <i className="me-1 las la-copy fs-5"></i> Copy
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <button
                        className="i-btn primary--btn btn--md w-100 border-0 rounded text-white p-2"
                        id="keygen"
                        onClick={generateApiKey}
                      >
                        <i className="me-1 las la-key fs-5"></i> Generate API Key
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GenerateApiKey;
