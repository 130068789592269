import React, { useState } from 'react';

function WhatsAppCampaigns() {
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [campaigns, setCampaigns] = useState([]); // You would fetch this data from an API

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleStatusChange = (e) => setStatus(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform the search or filtering here
  };

  const handleReset = () => {
    setSearch('');
    setStatus('');
    // Reset the data here
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">WhatsApp Campaigns</h4>
            </div>

            <div className="card-filter">
              <form onSubmit={handleSubmit}>
                <div className="filter-form">
                  <div className="filter-item">
                    <input
                      type="text"
                      autocomplete="off"
                      name="search"
                      placeholder="Search With Name"
                      className="form-control"
                      id="search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </div>

                  <div className="filter-item">
                    <select
                      name="status"
                      className="form-select"
                      id=""
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="DeActive">DeActive</option>
                      <option value="Completed">Completed</option>
                      <option value="Ongoing">Ongoing</option>
                    </select>
                  </div>

                  <div className="filter-action">
                    <button className="i-btn info--btn btn--md" type="submit">
                      {/* You can replace this SVG with an icon component */}
                      <span>🔍</span> Search
                    </button>
                    <button className="i-btn danger--btn btn--md" type="button" onClick={handleReset}>
                      {/* You can replace this SVG with an icon component */}
                      <span>🔄</span> Reset
                    </button>
                    <a href="/campaigns/whatsapp/create" className="i-btn primary--btn btn--md">
                      {/* You can replace this SVG with an icon component */}
                      <span>➕</span> Add New
                    </a>
                  </div>
                </div>
              </form>
            </div>

            <div className="card-body px-0">
              <div className="responsive-table">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Channel</th>
                      <th>Total Contacts</th>
                      <th>Schedule Time</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.length === 0 ? (
                      <tr>
                        <td className="text-muted text-center" colspan="100%">No Data Found</td>
                      </tr>
                    ) : (
                      campaigns.map((campaign, index) => (
                        <tr key={index}>
                          {/* Render campaign data */}
                          <td>{campaign.name}</td>
                          <td>{campaign.channel}</td>
                          <td>{campaign.totalContacts}</td>
                          <td>{campaign.scheduleTime}</td>
                          <td>{campaign.status}</td>
                          <td>
                            {/* Add actions here */}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="m-3"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Deletion */}
      <div className="modal fade" id="delete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <form action="/campaigns/delete" method="POST">
              <div className="modal_body2">
                <div className="modal_icon2">
                  {/* You can replace this icon with a component */}
                  <span>🗑️</span>
                </div>
                <div className="modal_text2 mt-3">
                  <h6>Are you sure to delete this Campaign?</h6>
                </div>
              </div>
              <div className="modal_button2 modal-footer">
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <button type="button" className="i-btn primary--btn btn--md" data-bs-dismiss="modal">Cancel</button>
                  <button type="submit" className="i-btn danger--btn btn--md">Delete</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatsAppCampaigns;
