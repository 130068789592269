import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { oneclick } from '../../../oneclick';
import { getuserdata } from '../../../services/apiService';
import database_id from '../../../utils/databaseConfig';
import ImageComponent from '../../../utils/ImageComponent';

const SeoDataModal = ({ showModal, handleClose, isEdit, initialData }) => {
    const [formValues, setFormValues] = useState({
        website: '',
        faviconold: '',
        favicon: '',
        pageName: '',
        title: '',
        description: '',
        keywords: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: '',
        ogUrl: '',
        twitterCard: '',
        twitterTitle: '',
        twitterDescription: '',
        twitterImage: '',
        googleAnalytics: '',
        fbPixel: '',
        customScripts: '',
        customFields: [] // For dynamically added custom fields
    });
    const [websites, setWebsites] = useState([]);
    const [seoData, setSeoData] = useState({});
    const [favicon, setFavicon] = useState(null);

    const fetchWebsites = async () => {
        try {
            const tableSchema = database_id;
            if (!tableSchema) {
                throw new Error("Table schema is not defined in environment variables.");
            }

            const tableName = 'seowebsites';
            if (!tableName) {
                throw new Error("Table name is missing.");
            }

            const companyId = await getuserdata("company_id");
            if (!companyId) {
                throw new Error("User company_id is not available.");
            }

            const conditions = { company_id: companyId, status: 'active' };
            const orderBy = 'id DESC';
            const search = {};
            const margedata = [
                {
                    target_label: "files",
                    target_table: "files",
                    target_column: "id",
                    target_value: "favicon",
                }
            ]

            const response = await oneclick.read(
                tableSchema,
                tableName,
                conditions,
                orderBy,
                search,
                "",
                margedata
            );

            if (response.success) {
                if (Array.isArray(response.data)) {
                    setWebsites(response.data);
                } else {
                    throw new Error("API response data is not in the expected format (array).");
                }
            } else {
                throw new Error(`API call failed with message: ${response.message || "Unknown error"}`);
            }
        } catch (error) {
            console.error("Error fetching websites:", error);
            alert(`Failed to fetch websites: ${error.message}`);
        }
    };

    useEffect(() => {
        fetchWebsites();
    }, []);

    useEffect(() => {
        if (isEdit && initialData) {
            setFormValues(initialData);
        }
    }, [isEdit, initialData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleWebsiteChange = (e) => {
        const selectedWebsite = e.target.value;
        console.log('Selected website:', e.target.selectedOptions[0].getAttribute('data-file'));
        const faviconnew = e.target.selectedOptions[0].getAttribute('data-file');  // Get the favicon from the selected option
        const websiteData = e.target.selectedOptions[0].getAttribute('data'); // You might want to use this for something else
    
        // Set both `favicon` and `website` in a single state update
        setFormValues((prev) => ({
            ...prev,
            website: selectedWebsite,
            faviconold: faviconnew,  // Update favicon in the state
        }));
    
        // If you need to store `websiteData` in another state or use it, you can set it here:
        setFavicon(websiteData); // If this is required, otherwise you can remove this line
    };

    const handleAddCustomField = () => {
        setFormValues((prev) => ({
            ...prev,
            customFields: [...prev.customFields, { fieldName: '', fieldValue: '' }],
        }));
    };

    const handleCustomFieldChange = (index, e) => {
        const { name, value } = e.target;
        const updatedCustomFields = [...formValues.customFields];
        updatedCustomFields[index][name] = value;
        setFormValues((prev) => ({ ...prev, customFields: updatedCustomFields }));
    };

    const handleRemoveCustomField = (index) => {
        const updatedCustomFields = formValues.customFields.filter((_, i) => i !== index);
        setFormValues((prev) => ({ ...prev, customFields: updatedCustomFields }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const tableSchema = database_id;
        const tableName = 'seodata';

        // Prepare the data to be sent to the backend
        const data = {
            website: formValues.website,
            pageName: formValues.pageName,
            title: formValues.title,
            description: formValues.description,
            keywords: formValues.keywords,
            ogTitle: formValues.ogTitle,
            ogDescription: formValues.ogDescription,
            ogImage: formValues.ogImage,
            ogUrl: formValues.ogUrl,
            twitterCard: formValues.twitterCard,
            twitterTitle: formValues.twitterTitle,
            twitterDescription: formValues.twitterDescription,
            twitterImage: formValues.twitterImage,
            googleAnalytics: formValues.googleAnalytics,
            fbPixel: formValues.fbPixel,
            customScripts: formValues.customScripts,
            customFields: formValues.customFields,
        };

        try {
            // Send data using oneclick.create
            const response = await oneclick.create(tableSchema, tableName, data, "");

            // Check if the response was successful
            if (response.success) {
                alert("SEO Data added successfully.");
                handleClose(); // Close modal after successful submission
            } else {
                // Handle failure case
                alert(`Failed to add SEO Data: ${response.message || "Unknown error"}`);
            }
        } catch (error) {
            // Handle any errors during the API call
            console.error("Error submitting SEO data:", error);
            alert("An error occurred while submitting the SEO data.");
        }
    };


    const formatWebsite = (url) => {
        try {
            const parsedUrl = new URL(url);
            const domain = parsedUrl.hostname;
            return domain.startsWith("www.") ? domain.slice(4) : domain;
        } catch (error) {
            console.error("Invalid URL:", url);
            return url;
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];  // Get the file from the input
        const { name } = e.target; // Get the name of the input field (favicon in this case)

        // Update the formValues state to store the file object
        setFormValues((prev) => ({
            ...prev,
            [name]: file,  // Store the file object, not just the value
        }));
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit SEO Data' : 'Add New SEO Data'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formWebsite">
                        <Form.Label>Website</Form.Label>
                        <Form.Select
                            name="website"
                            value={formValues.website}
                            data={formValues.websiteicon}
                            onChange={handleWebsiteChange}
                            required
                        >
                            <option value="">Select a website</option>
                            {websites.map((site, index) => (
                                <option key={index} value={site.id} data={site.favicon} data-file={site.files?.fileName}>
                                    {formatWebsite(site.website)}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <div className="d-flex">
                        <Form.Group controlId="formPageName" className={`${favicon ? 'col-lg-6' : 'col-lg-12'}`}>
                            <Form.Label>Favicon</Form.Label>
                            <Form.Control
                                type="file"
                                name="favicon"
                                value={formValues.favicon}
                                onChange={handleFileChange}
                                required
                            />
                        </Form.Group>
                        {favicon && <ImageComponent fileid={favicon} width={100} height={100} borderRadius={5} boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" />}
                    </div>
                    <Form.Group controlId="formPageName">
                        <Form.Label>Page Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="pageName"
                            value={formValues.pageName}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={formValues.title}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            value={formValues.description}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="keywords">
                        <Form.Label>Keywords</Form.Label>
                        <Form.Control
                            type="text"
                            name="keywords"
                            value={formValues.keywords}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    {/* Open Graph */}
                    <Form.Group controlId="ogTitle">
                        <Form.Label>Open Graph Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="ogTitle"
                            value={formValues.ogTitle}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="ogDescription">
                        <Form.Label>Open Graph Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="ogDescription"
                            value={formValues.ogDescription}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="ogImage">
                        <Form.Label>Open Graph Image URL</Form.Label>
                        <Form.Control
                            type="text"
                            name="ogImage"
                            value={formValues.ogImage}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="ogUrl">
                        <Form.Label>Open Graph URL</Form.Label>
                        <Form.Control
                            type="text"
                            name="ogUrl"
                            value={formValues.ogUrl}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    {/* Twitter Card */}
                    <Form.Group controlId="twitterCard">
                        <Form.Label>Twitter Card</Form.Label>
                        <Form.Select
                            name="twitterCard"
                            value={formValues.twitterCard}
                            onChange={handleInputChange}
                        >
                            <option value="summary_large_image">Summary with Large Image</option>
                            <option value="summary">Summary</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="twitterTitle">
                        <Form.Label>Twitter Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="twitterTitle"
                            value={formValues.twitterTitle}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="twitterDescription">
                        <Form.Label>Twitter Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="twitterDescription"
                            value={formValues.twitterDescription}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="twitterImage">
                        <Form.Label>Twitter Image</Form.Label>
                        <Form.Control
                            type="file"
                            name="twitterImage"
                            value={formValues.twitterImage}
                            onChange={handleFileChange}
                        />
                    </Form.Group>

                    {/* Google Analytics */}
                    <Form.Group controlId="googleAnalytics">
                        <Form.Label>Google Analytics ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="googleAnalytics"
                            value={formValues.googleAnalytics}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    {/* Facebook Pixel */}
                    <Form.Group controlId="fbPixel">
                        <Form.Label>Facebook Pixel ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="fbPixel"
                            value={formValues.fbPixel}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    {/* Custom Scripts */}
                    <Form.Group controlId="customScripts">
                        <Form.Label>Custom Scripts</Form.Label>
                        <Form.Control
                            type="text"
                            name="customScripts"
                            value={formValues.customScripts}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    {/* Dynamic Custom Fields */}
                    <div>
                        <h5>Custom SEO Fields</h5>
                        {formValues.customFields.map((field, index) => (
                            <div key={index} className="d-flex mb-2">
                                <Form.Control
                                    type="text"
                                    name="fieldName"
                                    value={field.fieldName}
                                    placeholder="Field Name"
                                    onChange={(e) => handleCustomFieldChange(index, e)}
                                    className="mr-2"
                                    required
                                />
                                <Form.Control
                                    type="text"
                                    name="fieldValue"
                                    value={field.fieldValue}
                                    placeholder="Field Value"
                                    onChange={(e) => handleCustomFieldChange(index, e)}
                                    required
                                />
                                <Button
                                    variant="danger"
                                    onClick={() => handleRemoveCustomField(index)}
                                    className="ml-2"
                                >
                                    <i class="fa fa-close"></i>
                                </Button>
                            </div>
                        ))}

                    </div>

                    <div className="d-flex justify-content-between  mt-3">
                        <Button variant="secondary" onClick={handleAddCustomField}>
                            Add Custom Field
                        </Button>
                        <Button variant="primary" type="submit">
                            {isEdit ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SeoDataModal;
