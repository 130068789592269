import React, { useState } from "react";

const GoogleAuthCredentials = () => {
  // State management for form fields
  const [clientId, setClientId] = useState("####");
  const [clientSecret, setClientSecret] = useState("####");
  const [clientStatus, setClientStatus] = useState("2");
  const [redirectUri, setRedirectUri] = useState("https://getone.click/auth/google/callback");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    const formData = {
      g_client_id: clientId,
      g_client_secret: clientSecret,
      g_client_status: clientStatus,
      callback_google_url: redirectUri,
    };

    console.log("Form submitted:", formData);

    // You can submit the formData to the server as needed
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(redirectUri).then(
      () => {
        alert("URL copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy URL: ", err);
      }
    );
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Google Auth Credentials Setup
                <sup className="pointer" title="To setup google auth">
                  <a href="https://console.cloud.google.com/">
                    <svg
                      className="svg-inline--fa fa-circle-info"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-info"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="20"  // set the width here
                      height="20" // set the height here
                    >
                      <path
                        fill="currentColor"
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32 0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                      ></path>
                    </svg>
                  </a>
                </sup>
              </h4>
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="_token" value="JY3trr9nM6W48gliidWmcyKnfJ6EQAqNc5C4oefq" />
                <div className="form-wrapper">
                  <div className="row g-4">
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="g_client_id" className="form-label">
                        Client Id <sup className="text--danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="g_client_id"
                        id="g_client_id"
                        className="form-control"
                        value={clientId}
                        onChange={(e) => setClientId(e.target.value)}
                        placeholder="Enter Google Client Id"
                        required
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="g_client_secret" className="form-label">
                        Client Secret<sup className="text--danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="g_client_secret"
                        id="g_client_secret"
                        className="form-control"
                        value={clientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                        placeholder="Enter Google Secret Key"
                        required
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="g_client_status" className="form-label">
                        Status<sup className="text--danger">*</sup>
                      </label>
                      <select
                        className="form-control"
                        id="g_client_status"
                        name="g_client_status"
                        value={clientStatus}
                        onChange={(e) => setClientStatus(e.target.value)}
                        required
                      >
                        <option value="1">ON</option>
                        <option value="2">OFF</option>
                      </select>
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="callback_google_url" className="form-label">
                        Authorized redirect URIs
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          id="callback_google_url"
                          className="form-control"
                          value={redirectUri}
                          readOnly
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                        <span
                          className="input-group-text bg--success pointer"
                          onClick={copyToClipboard}
                          id="basic-addon2"
                        >
                          Copy URL
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="i-btn primary--btn btn--lg">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GoogleAuthCredentials;
