import React, { useEffect, useState } from 'react';
import { oneclick } from '../../../oneclick';
import { OneKlicksFileSDK } from '../../../OneKlicksFileSDK';
import { getuserdata } from '../../../services/apiService';
import ImageComponent from '../../../utils/ImageComponent';
import database_id from '../../../utils/databaseConfig';

const EditAIDepartment = ({ show, hide, initialDepartmentData, fetchEmailData }) => {
    console.log('initialDepartmentData:', initialDepartmentData);
  const [departmentData, setDepartmentData] = useState({
    name: '',
    description: '',
    image: null,
    status: '',
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  // Set initial department data when editing
  useEffect(() => {
    if (initialDepartmentData) {
      setDepartmentData({
        name: initialDepartmentData.name,
        description: initialDepartmentData.description,
        image: null, // Keep this as null to handle new image uploads
        status: initialDepartmentData.status,
      });
    }
  }, [initialDepartmentData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDepartmentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setDepartmentData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', departmentData.name);
    formData.append('description', departmentData.description);
    formData.append('status', departmentData.status);
    
    const userId = await getuserdata('id');
    const root = 'ai/departments/logos';
    
    try {
      let imageId;
      if (departmentData.image) {
        // Upload new image only if a new one is selected
        const uploadimage = await OneKlicksFileSDK.uploadFile(userId, root, departmentData.image);
        console.log('Image uploaded:', uploadimage.file.id);
        imageId = uploadimage.file.id;
      } else {
        // Use the existing image ID if no new image is selected
        imageId = initialDepartmentData.image; // Assuming initialDepartmentData.image holds the existing image ID
      }

      const tableSchema = database_id;
      const tableName = 'departments';
      const data = {
        name: departmentData.name,
        description: departmentData.description,
        image: imageId,
        status: departmentData.status,
        updated_at	: new Date().toISOString(),
      };
      const conditions = {
        id: initialDepartmentData.id, // Use the department ID for updating 
      };
      const uploaddata = await oneclick.update(tableSchema, tableName, data, conditions);
      console.log('Department added:', uploaddata);
      if (uploaddata.success) {
        setSuccessModalVisible(true); // Show success modal
      } else {
        alert('Error adding department: ' + uploaddata.message);
        console.error('Error adding department:', uploaddata.message);
      }
    } catch (error) {
      console.error('Error adding department:', error);
    }
  };

  const hideSuccessModal = () => {
    setSuccessModalVisible(false);
    hide();
    fetchEmailData(); // Fetch email data after adding/updating department
    // Optionally reset the form
    setDepartmentData({
      name: '',
      description: '',
      image: null,
      status: '',
    });
  };

  return (
    <div>
      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="addNewLabel" aria-hidden={!show}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addNewLabel">{initialDepartmentData ? 'Edit Department' : 'Add Department'}</h5>
              <button type="button" className="btn-close" onClick={hide}><i className="fa fa-close"></i></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Department Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={departmentData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    value={departmentData.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">Image</label>
                  {initialDepartmentData && initialDepartmentData.image && (
                    <div>
                        <ImageComponent fileid={initialDepartmentData.images.id} width={150} height={100} borderRadius={5} boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" />
                      
                      <div>Current Image</div>
                    </div>
                  )}
                  <input
                    type="file"
                    className="form-control"
                    id="image"
                    name="image"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="status" className="form-label">Status</label>
                  <select
                    className="form-select"
                    name="status"
                    value={departmentData.status}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>
                <div className="modal-footer" style={{ paddingRight: '0px' }}>
                  <button type="submit" className="btn btn-primary">{initialDepartmentData ? 'Update Department' : 'Add Department'}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Success Modal */}
      {successModalVisible && (
        <div className="modal fade show" style={{ display: 'block' }} aria-labelledby="successModalLabel" aria-hidden="false">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="successModalLabel">Success</h5>
                <button type="button" className="btn-close" onClick={hideSuccessModal}></button>
              </div>
              <div className="modal-body">
                Department {initialDepartmentData ? 'updated' : 'added'} successfully!
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={hideSuccessModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAIDepartment;