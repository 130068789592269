import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const AddTrackerModal = ({ show, handleClose, companyId, branches }) => {
    const [newTracker, setNewTracker] = useState({
        roleName: '',
        branchId: '',
        deviceType: '',
        ipTracking: 'no',
        screenshotTracking: 'no',
        screenshotInterval: '',
        locationTracking: 'no',
    });
    const [selectbranchid, Setselectbranchid] = useState('');

    const handleChange = async (e) => {
        const { name, value } = e.target;

        // Show alert when a staff is selected
        if (name === 'branchId' && value) {
            const selectedBranch = branches.find(branch => branch.id === parseInt(value));
            if (selectedBranch) {
                //alert(`Selected Staff ID: ${selectedBranch.id}`);
                const tableSchema = database_id;
                const tableName = "employees";
                const conditions = {
                    'userid': selectedBranch.id
                }
                const hetbranchid = await oneclick.read(tableSchema, tableName, conditions, "", "");
                if(hetbranchid.success){
                    Setselectbranchid(hetbranchid.data[0].id);
                    setNewTracker((prev) => ({ ...prev, [name]: value }));
                }else{
                    alert(`Selected Staff ID : ${selectedBranch.name} Not Update All Data`);
                }
                
            }
        }

        
    };

    const handleSubmit = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "tracker";
            const data = {
                company_id: companyId,
                branch_id: selectbranchid,
                user_id: newTracker.branchId,
                name: newTracker.roleName,
                device_type: newTracker.deviceType,
                ip_tracking: newTracker.ipTracking,
                screenshot_tracking: newTracker.screenshotTracking,
                screenshot_interval: newTracker.screenshotInterval,
                location_tracking: newTracker.locationTracking,
            };

            const update = await oneclick.create(tableSchema, tableName, data);
            if (update.success) {
                alert(update.message || 'Tracker added successfully.');
                handleClose(); // Close the modal on successful submit
            } else {
                console.error("Error updating tracker:", update.error);
                alert(update.error || 'Error updating tracker.');
            }
        } catch (error) {
            console.error('Error adding tracker:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Assignment Tracker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Branch Selector */}
                    <Form.Group controlId="roleBranch">
                        <Form.Label>Select Staff</Form.Label>
                        <Form.Control
                            as="select"
                            name="branchId"
                            value={newTracker.branchId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a Staff</option>
                            {branches.map((branch) => (
                                <option key={branch.id} value={branch.id}>
                                    {branch.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    {/* Device Type */}
                    <Form.Group controlId="deviceType">
                        <Form.Label>Device Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="deviceType"
                            value={newTracker.deviceType}
                            onChange={handleChange}
                        >
                            <option value="">Select Device Type</option>
                            <option value="android">Android</option>
                            <option value="ios">iOS</option>
                            <option value="windows">Windows</option>
                            <option value="mac">Mac</option>
                        </Form.Control>
                    </Form.Group>

                    {/* IP Tracking */}
                    <Form.Group controlId="ipTracking">
                        <Form.Label>IP Tracking</Form.Label>
                        <Form.Control
                            as="select"
                            name="ipTracking"
                            value={newTracker.ipTracking}
                            onChange={handleChange}
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </Form.Control>
                    </Form.Group>

                    {/* Screenshot Tracking */}
                    <Form.Group controlId="screenshotTracking">
                        <Form.Label>Screenshot Tracking</Form.Label>
                        <Form.Control
                            as="select"
                            name="screenshotTracking"
                            value={newTracker.screenshotTracking}
                            onChange={handleChange}
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </Form.Control>
                    </Form.Group>

                    {/* Screenshot Interval (Show only if screenshot tracking is "yes") */}
                    {newTracker.screenshotTracking === 'yes' && (
                        <Form.Group controlId="screenshotInterval">
                            <Form.Label>Screenshot Interval (minutes)</Form.Label>
                            <Form.Control
                                as="select"
                                name="screenshotInterval"
                                value={newTracker.screenshotInterval}
                                onChange={handleChange}
                            >
                                <option value="1">1</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                            </Form.Control>
                        </Form.Group>
                    )}

                    {/* Location Tracking */}
                    <Form.Group controlId="locationTracking">
                        <Form.Label>Location Tracking</Form.Label>
                        <Form.Control
                            as="select"
                            name="locationTracking"
                            value={newTracker.locationTracking}
                            onChange={handleChange}
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Add Tracker
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddTrackerModal;
