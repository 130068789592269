import React, { useEffect, useState } from 'react';


const PayRoll = () => {

    return(
        <div>
            
        </div>
    )
};

export default PayRoll;