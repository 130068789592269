import React, { useState } from "react";
import { oneclick } from "../../../oneclick";
import database_id from "../../../utils/databaseConfig";

const DeleteAttributeModel = ({ handleDeleteClose, data, fetchData }) => {
    const handleDelete = async () => {
        console.log("DeleteAttributeModel :", data);
        if (!data || !data.id) {
            alert('No attribute selected for deletion.');
            return; // Exit the function if data or data.id is not present
        }
    
        const tableSchema = database_id;
        const tableName = 'attribute';
        const condition = { id: data.id };
    
        try {
            // Delete the attribute
            const response = await oneclick.delete(tableSchema, tableName, condition);
    
            if (response.success) {
                // Success - handle success (e.g., show a success message or refresh data)
                alert('Attribute deleted successfully!');
                fetchData();  // Refresh the data to reflect the deletion
                handleDeleteClose();  // Close the modal after successful delete
            } else {
                alert('Error deleting attribute');
            }
        } catch (error) {
            console.error('Error deleting attribute:', error);
            alert('Error deleting attribute');
        }
    };
    

    return (
        <div className="modal fade show d-block" id="deleteAttribute" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Delete Attribute</h5>
                        <button type="button" className="i-btn bg--lite--danger text--danger btn--sm" onClick={handleDeleteClose}>
                            <i className="las la-times"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Are you sure you want to delete this attribute?</p>
                    </div>

                    <div className="modal-footer">
                        <div className="d-flex align-items-center gap-3">
                            <button type="button" className="i-btn danger--btn btn--md" onClick={handleDeleteClose}>
                                Cancel
                            </button>
                            <button type="button" className="i-btn primary--btn btn--md" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteAttributeModel;
