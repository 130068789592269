
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getuserdata } from '../../../services/apiService';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';


const CreateGroupModal = ({ onClose, fetchData }) => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        const groupName = e.target.groupName.value;
        const status = e.target.status.value;

        try {
            const tableSchema = database_id;
            const tableName = "groups";
            const { v4: uuidv4 } = require('uuid');

            // Await the result of getuserdata
            const userId = await getuserdata("id");
            if (!userId) {
                console.error('Failed to get user ID. Aborting group creation.');
                return;
            }

            const data = {
                uid: uuidv4(),
                user_id: userId, // Use the resolved user ID
                name: groupName,
                company_id: await getuserdata("company_id"),
                status: status
            };

            const condition = {
                name: groupName
            }

            // Call the API to create the group
            const response = await oneclick.create(tableSchema, tableName, data, condition);

            if(response.success){
                console.log('Group created:', response.data);
                fetchData();
                onClose(); // Close modal after success
            }else{
                alert(response.message);
            }
        } catch (error) {
            console.error('Error creating group:', error.response?.data || error.message);
        }
    };


    return (
        <Modal show onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="groupName">
                        <Form.Label>Group Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter group name" required />
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" required>
                            <option value="1">Active</option>
                            <option value="2">Inactive</option>
                        </Form.Control>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
export default CreateGroupModal;
