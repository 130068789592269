import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const EditRoleModal = ({ show, handleClose, roleData, branches }) => {
    const [updatedRole, setUpdatedRole] = useState({
        id: roleData.id,
        roleName: roleData.name,
        branchId: roleData.branch_id,
        permissions: roleData.permission ? JSON.parse(roleData.permission) : [],
    });

    const [availablePermissions, setAvailablePermissions] = useState([]);

    useEffect(() => {
        const fetchPermissions = async () => {
            const tableSchema = database_id;
            const tableName = "permissions";
            const orderBy = "id DESC";
            const response = await oneclick.read(tableSchema, tableName, "", orderBy);
            const responseData = response.data;

            if (responseData) {
                const parsedPermissions = responseData.map(permissionGroup => ({
                    id: permissionGroup.id,
                    subName: permissionGroup.name,
                    permissions: permissionGroup.permissions ? JSON.parse(permissionGroup.permissions) : [],
                }));
                setAvailablePermissions(parsedPermissions);
            }
        };

        if (show) {
            fetchPermissions();
            setUpdatedRole(prev => ({
                ...prev,
                permissions: roleData.permission ? JSON.parse(roleData.permission) : [],
            }));
        }
    }, [show, roleData.permission]);

    const handleSelectAllPermissions = (e, permissionGroupId) => {
        const checked = e.target.checked;
        const permissionGroup = availablePermissions.find(group => group.id === permissionGroupId);
        const allChildren = permissionGroup?.permissions.flatMap(sub => {
            return sub.permissions ? sub.permissions.map(child => child.id) : []; // Get child IDs
        }) || []; // Default to empty array if permissionGroup is not found

        setUpdatedRole(prev => ({
            ...prev,
            permissions: checked
                ? [...new Set([...prev.permissions, ...allChildren])]
                : prev.permissions.filter(p => !allChildren.includes(p)),
        }));
    };

    const handleSubPermissionChange = (subPermission) => {
        const allChildren = subPermission.permissions.map(child => child.id); // Get IDs of all children
        setUpdatedRole(prev => ({
            ...prev,
            permissions: prev.permissions.includes(subPermission.id)
                ? prev.permissions.filter(p => !allChildren.includes(p)) // Deselect all children by ID
                : [...new Set([...prev.permissions, subPermission.id, ...allChildren])], // Select all children by ID
        }));
    };

    const handlePermissionChange = (childPermissionId) => {
        setUpdatedRole(prev => {
            const isSelected = prev.permissions.includes(childPermissionId);
            const permissions = isSelected
                ? prev.permissions.filter(p => p !== childPermissionId) // Deselect if already selected
                : [...prev.permissions, childPermissionId]; // Select only the clicked childPermission
            return { ...prev, permissions };
        });
    };

    const isMasterChecked = (permissionGroupId) => {
        const permissionGroup = availablePermissions.find(group => group.id === permissionGroupId);
        if (!permissionGroup) return false;

        const allChildren = permissionGroup.permissions.flatMap(sub => sub.permissions.map(child => child.id));

        return allChildren.every(perm => updatedRole.permissions.includes(perm));
    };

    const isSubChecked = (subPermission) => {
        const allChildren = subPermission.permissions.map(child => child.id); // Get IDs of all children
        return updatedRole.permissions.includes(subPermission.id) || allChildren.some(child => updatedRole.permissions.includes(child));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedRole(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {

        // Check if any permissions are selected
        if (updatedRole.permissions.length === 0) {
            alert('Please select at least one permission.');
            return; // Exit the function if no permissions are selected
        }
        console.log('updatedRole', updatedRole);
        const tableSchema = database_id;
        const tableName = "roles";
        const data ={
            'permission': JSON.stringify(updatedRole.permissions),
        }
        const conditions = {
            'id': updatedRole.id
        }
        try {
            await oneclick.update(tableSchema, tableName, data, conditions);
            handleClose(); // Close the modal after successful update
        } catch (error) {
            console.error("Failed to update role:", error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="roleName">
                        <Form.Label>Role Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="roleName"
                            value={updatedRole.roleName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="branchId">
                        <Form.Label>Branch</Form.Label>
                        <Form.Control
                            as="select"
                            name="branchId"
                            value={updatedRole.branchId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Branch</option>
                            {branches.map(branch => (
                                <option key={branch.id} value={branch.id}>{branch.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <div>
                        <label className="form-label">Permissions</label>
                        {availablePermissions.map((permissionGroup) => (
                            <div key={permissionGroup.id}>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`master-checkbox-${permissionGroup.id}`}
                                        onChange={(e) => handleSelectAllPermissions(e, permissionGroup.id)}
                                        checked={isMasterChecked(permissionGroup.id)}
                                    />
                                    <label className="form-check-label" htmlFor={`master-checkbox-${permissionGroup.id}`}>
                                        {permissionGroup.subName}
                                    </label>
                                </div>
                                <div className='row' style={{ marginLeft: '19px' }}>
                                    {permissionGroup.permissions.map((subPermission) => (
                                        <div key={subPermission.id}>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`sub-checkbox-${permissionGroup.id}-${subPermission.id}`}
                                                    checked={isSubChecked(subPermission)} // Check if the subgroup is selected
                                                    onChange={() => handleSubPermissionChange(subPermission)} // Update based on the subPermission
                                                />
                                                <label htmlFor={`sub-checkbox-${permissionGroup.id}-${subPermission.id}`} className="form-check-label">
                                                    {subPermission.subName}
                                                </label>
                                            </div>
                                            <div className='row' style={{ marginLeft: '19px' }}>
                                                {subPermission.permissions.map((childPermission) => (
                                                    <div className="form-check" key={childPermission.id} style={{ width: 'auto' }}>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id={`child-checkbox-${subPermission.id}-${childPermission.id}`}
                                                            checked={updatedRole.permissions.includes(childPermission.id)} // Check if the permission ID is selected
                                                            onChange={() => handlePermissionChange(childPermission.id)} // Use ID for child permission
                                                        />
                                                        <label htmlFor={`child-checkbox-${subPermission.id}-${childPermission.id}`} className="form-check-label">
                                                            {childPermission.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditRoleModal;




