import React from 'react';

const ContactInfoSection = () => {
    return (
        <div className='row row2 d-flex justify-content-center' style={{ marginTop: '20px' }}>
            {/* Phone Section */}
            <div className='d-flex justify-content-center'>
                <section style={{ color: '#4A4A4A', textAlign: 'center', fontSize: 'large', padding: '10px' }}>
                    <div className='row d-flex align-items-center'>
                        <div className='' style={{ paddingRight: '0px',width: 'max-content' }}>
                            <img
                                src='https://images.vexels.com/media/users/3/141217/isolated/preview/a2503907aa82c79fc1e8a82f58d722ed-smartphone-round-icon.png?w=360'
                                style={{ marginTop: '2px', height: '50px', width: '50px' }}
                                alt="Phone Icon"
                            />
                        </div>
                        <div className='' style={{ textAlign: 'left', paddingLeft: '10px',width: 'max-content' }}>
                            <p><strong style={{ color: '#5271ff' }}>Phone</strong></p>
                            <p><strong className='strong-text'>123-456-7890</strong></p>
                        </div>
                    </div>
                </section>
            </div>

            {/* Mail Section */}
            <div className='d-flex justify-content-center'>
                <section style={{ color: '#4A4A4A', textAlign: 'center', fontSize: 'large', padding: '10px' }}>
                    <div className='row d-flex align-items-center'>
                        <div className='' style={{ paddingRight: '0px',width: 'max-content' }}>
                            <img
                                src='https://cdn-icons-png.flaticon.com/512/6932/6932624.png'
                                style={{ marginTop: '2px', height: '46px', width: '46px' }}
                                alt="Mail Icon"
                            />
                        </div>
                        <div className='' style={{ textAlign: 'left', paddingLeft: '10px',width: 'max-content' }}>
                            <p><strong style={{ color: '#5271ff' }}>Mail</strong></p>
                            <p><strong className='strong-text'>ravi80181@gmail.com</strong></p>
                        </div>
                    </div>
                </section>
            </div>

            {/* Address Section */}
            <div className='d-flex justify-content-center'>
                <section style={{ color: '#4A4A4A', textAlign: 'center', fontSize: 'large', padding: '10px' }}>
                    <div className='row d-flex align-items-center'>
                        <div className='col-lg-3' style={{ paddingRight: '0px',width: 'max-content' }}>
                            <img
                                src='https://cdn-icons-png.freepik.com/256/14026/14026648.png?semt=ais_hybrid'
                                style={{ marginTop: '2px', height: '46px', width: '46px' }}
                                alt="Address Icon"
                            />
                        </div>
                        <div className='col-lg-9' style={{ textAlign: 'left', paddingLeft: '10px',width: 'max-content' }}>
                            <p><strong style={{ color: '#5271ff' }}>Address</strong></p>
                            <p><strong className='strong-text'>123 Anywhere St., Any City</strong></p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default ContactInfoSection;
