import React, { useState, useRef } from "react";
import { getuserdata } from "../../../services/apiService";
import { oneclick } from "../../../oneclick";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import database_id from "../../../utils/databaseConfig";

const AddGateway = ({ credentials, onClose }) => {
    const [selectedType, setSelectedType] = useState("");
    const [oldType, setOldType] = useState("");
    const [driverInfo, setDriverInfo] = useState({}); // Holds old or new inputs based on the selected type

    // Create a reference for the modal
    const modalRef = useRef(null);


    const handleGatewayTypeChange = (e) => {
        const newType = e.target.value;
        setSelectedType(newType);

        // Find credentials for the selected type
        const selectedCredential = credentials.find(
            (credential) => credential.gateway_code === newType
        );

        if (newType !== oldType) {
            // Reset driver info for new type
            // Example JSON string
            const jsonString = selectedCredential?.credential || {};

            const driverInfonew = JSON.parse(jsonString);

            setDriverInfo(driverInfonew);
            console.log('selectedCredential?.credential :', selectedCredential?.credential);
        } else {
            // Keep old driver info for same type

            setDriverInfo(driverInfo);
            console.log('selectedCredential?.credential :', driverInfo);
        }
    };

    const formatLabel = (key) => {
        return key
            .replace(/_/g, " ") // Replace underscores with spaces
            .toLowerCase() // Convert all text to lowercase
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
    };

    const handleInputChange = (e, key) => {
        setDriverInfo({
            ...driverInfo,
            [key]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        const { v4: uuidv4 } = require('uuid');

        const tableSchema = database_id;
        const tableName = 'gateways';
        const data = {
            uid: uuidv4(),
            user_id: await getuserdata("id"),
            company_id: await getuserdata("company_id"),
            type: selectedType,
            sms_gateways: JSON.stringify(driverInfo),
            name: e.target.name.value,
            status: e.target.status.value,
        }

        try {
            const responce = await oneclick.create(tableSchema, tableName, data, "");

            // Check if the request was successful
            if (responce.success) {
                console.log("Update successful:", responce.success);
                // Optionally reset the form or show a success message here
                onClose()
            } else {
                console.error("Failed to update gateway:", responce.success);
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error("Error during update:", error);
            // Handle network or other errors
        }
    };


    return (
        <div class="modal fade show" id="addgateway" tabindex="-1" aria-labelledby="exampleModalLabel" style={{ display: 'block' }} aria-modal="true" role="dialog"
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add SMS Gateway</h5>
                        <button
                            type="button"
                            className="i-btn bg--lite--danger text--danger btn--sm"
                            onClick={onClose}
                        >
                            <i className="las la-times"></i>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-item mb-3">
                                        <label htmlFor="name" className="form-label">
                                            Gateway Name <sup className="text--danger">*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Gateway Name"
                                            required
                                        />
                                    </div>

                                    <div className="form-item mb-3">
                                        <label htmlFor="add_gateway_type" className="form-label">
                                            Gateway Type <sup className="text--danger">*</sup>
                                        </label>
                                        <select
                                            className="form-select gateway_type"
                                            name="type"
                                            id="add_gateway_type"
                                            value={selectedType}
                                            onChange={handleGatewayTypeChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                Select a gateway type
                                            </option>
                                            {credentials.map((credential) => (
                                                <option
                                                    key={credential.gateway_code}
                                                    value={credential.gateway_code}
                                                >
                                                    {credential.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="row mb-3">
                                        {Object.entries(driverInfo).map(([key, value]) => (
                                            <div key={key} className="form-item mb-3 col-lg-6">
                                                <label
                                                    htmlFor={key}
                                                    className="form-label text-capitalize"
                                                >
                                                    {formatLabel(key)}{" "}
                                                    <sup className="text--danger">*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={key}
                                                    name={`driver_information[${key}]`}
                                                    value={value}
                                                    onChange={(e) => handleInputChange(e, key)}
                                                    placeholder={`Enter ${key.replace("_", " ")}`}
                                                    required
                                                />
                                            </div>
                                        ))}
                                    </div>

                                    <div className="form-item mb-3">
                                        <label htmlFor="status" className="form-label">
                                            Status <sup className="text--danger">*</sup>
                                        </label>
                                        <select className="form-select" name="status" id="status" required>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="i-btn danger--btn btn--md"
                                onClick={onClose}
                            >
                                Close
                            </button>
                            <button type="submit" className="i-btn primary--btn btn--md">
                                Submit
                            </button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddGateway;
