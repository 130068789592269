import React, { useState, useEffect } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import Paginationnew from "../../utils/Paginationnew";
import AddingNewApplication from "./Components/AddingNewApplication";
import { oneclick } from "../../oneclick";
import { getuserdata } from "../../services/apiService";
import database_id from "../../utils/databaseConfig";

const ApplicationsList = () => {
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [showAddNewApp, setShowaddnewapp] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const navigate = useNavigate();
    const location = useLocation();

    const [appData, setAppData] = useState([]);
    const [appEdit, setAppEdit] = useState("");
    const [selectappData, setselectappData] = useState([]);
    const [currentApps, setCurrentApps] = useState([]);

    // Get data function to fetch the app data
    const getdata = async () => {
        console.log("getdata : true");
        const tableSchema = database_id;
        const tableName = "liveapplications";
        const conditions = {
            company_id: await getuserdata("company_id")
        };
        const orderBy = "id DESC";
        const pagination = { page: currentPage, limit: itemsPerPage };

        const dataall = await oneclick.read(tableSchema, tableName, conditions, orderBy, { uid: search, appName: search }, pagination, "");

        if (dataall.success) {
            console.log('dataall : ', dataall);
            setAppData(dataall.data);
        } else {
            console.log('getdata : ', dataall.message);
        }
    };

    useEffect(() => {
        getdata();
    }, [search, status, currentPage]);

    // Update current apps based on pagination
    useEffect(() => {
        const indexOfLastApp = currentPage * itemsPerPage;
        const indexOfFirstApp = indexOfLastApp - itemsPerPage;
        const currentData = appData.slice(indexOfFirstApp, indexOfLastApp);
        setCurrentApps(currentData);
    }, [appData, currentPage]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset to page 1 when search changes
    };

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleEdit = (data) => {
        setselectappData(data);
        setShowaddnewapp(true);
        setAppEdit("edit");
    };

    const handleDesign = (data) => {
        navigate(`/DesignPage/${data.uid}`);
    };

    

    const handleView = (data) => {
        setselectappData(data);
        setShowaddnewapp(true);
        setAppEdit("view");
    };

    const handleDelete = (id) => {
        console.log("Delete app", id);
    };

    return (
        <>
            <div className="dashboard_container">
                <section>
                    <div className="container-fluid p-0">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Applications List</h4>
                            </div>

                            <div className="card-filter">
                                <div className="filter-form">
                                    <div className="filter-item">
                                        <input
                                            type="text"
                                            placeholder="Search by App Name or Description"
                                            className="form-control"
                                            value={search}
                                            onChange={handleSearchChange}
                                        />
                                    </div>

                                    <div className="filter-item">
                                        <select
                                            className="form-select"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="DeActive">DeActive</option>
                                        </select>
                                    </div>
                                    <button
                                        type="button"
                                        className="i-btn primary--btn btn--md text-white"
                                        onClick={() => setShowaddnewapp(true)}
                                    >
                                        Add Application
                                    </button>
                                </div>
                            </div>

                            <div className="card-body px-0">
                                <div className="responsive-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>App Name</th>
                                                <th>Type</th>
                                                <th>Domain</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentApps.length > 0 ? (
                                                currentApps.map((app) => (
                                                    <tr key={app.id}>
                                                        <td>{app.id}</td>
                                                        <td>{app.appName}</td>
                                                        <td>{app.type}</td>
                                                        <td>{app.domain}</td>
                                                        <td style={{ width: '200px' }}>
                                                            <div style={{ width: '200px' }} className="truncated-text">
                                                                {app.description}
                                                            </div>
                                                        </td>
                                                        <td>{app.status}</td>
                                                        <td>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => handleDesign(app)}
                                                                style={{ marginRight: '5px' }}
                                                            >
                                                                Design
                                                            </button>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => handleView(app)}
                                                                style={{ marginRight: '5px' }}
                                                            >
                                                                View
                                                            </button>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => handleEdit(app)}
                                                                style={{ marginRight: '5px' }}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={() => handleDelete(app.id)}
                                                                style={{ marginRight: '5px' }}
                                                            >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center text-muted">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                {/* Pagination */}
                                <Paginationnew
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(appData.length / itemsPerPage)}
                                    handlePageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {showAddNewApp && (
                <>
                    <AddingNewApplication setShowaddnewapp={setShowaddnewapp} getdata={getdata} appData={selectappData} edit={appEdit} />
                </>
            )}
        </>
    );
};

export default ApplicationsList;
