import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const EditTrackerModal = ({ show, handleClose, trackerData, branches, username, companyId }) => {
    console.log('EditTrackerModal:', trackerData);
    const [editTracker, setEditTracker] = useState({
        roleName: '',
        branchId: '',
        deviceType: '',
        ipTracking: 'no',
        screenshotTracking: 'no',
        screenshotInterval: '',
        locationTracking: 'no',
    });
    const [selectBranchId, setSelectBranchId] = useState('');

    // Pre-fill the form with existing data when the modal is opened
    useEffect(() => {
        if (trackerData) {
            setEditTracker({
                roleName: trackerData[0].name || '',
                branchId: trackerData[0].user_id || '',
                deviceType: trackerData[0].device_type || '',
                ipTracking: trackerData[0].ip_tracking || 'no',
                screenshotTracking: trackerData[0].screenshot_tracking || 'no',
                screenshotInterval: trackerData[0].screenshot_interval || '',
                locationTracking: trackerData[0].location_tracking || 'no',
            });
            setSelectBranchId(trackerData[0].branch_id || '');
        }
    }, [trackerData]);

    const handleChange = async (e) => {
        const { name, value } = e.target;

        // If the branch is changed, validate the new branch
        if (name === 'branchId' && value) {
            const selectedBranch = branches.find(branch => branch.id === parseInt(value));
            if (selectedBranch) {
                const tableSchema = database_id;
                const tableName = "employees";
                const conditions = {
                    'userid': selectedBranch.id
                };
                const hetbranchid = await oneclick.read(tableSchema, tableName, conditions, "", "");
                if (hetbranchid.success) {
                    setSelectBranchId(hetbranchid.data[0].id);
                    setEditTracker((prev) => ({ ...prev, [name]: value }));
                } else {
                    alert(`Selected Staff ID : ${selectedBranch.name} Not Update All Data`);
                }
            }
        } else {
            setEditTracker((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "tracker";
            const data = {
                company_id: trackerData.company_id,
                branch_id: selectBranchId,
                user_id: editTracker.branchId,
                name: editTracker.roleName,
                device_type: editTracker.deviceType,
                ip_tracking: editTracker.ipTracking,
                screenshot_tracking: editTracker.screenshotTracking,
                screenshot_interval: editTracker.screenshotInterval,
                location_tracking: editTracker.locationTracking,
            };

            const update = await oneclick.update(tableSchema, tableName, data, { id: trackerData.id });
            if (update.success) {
                alert(update.message || 'Tracker updated successfully.');
                handleClose(); // Close the modal on successful update
            } else {
                console.error("Error updating tracker:", update.error);
                alert(update.error || 'Error updating tracker.');
            }
        } catch (error) {
            console.error('Error updating tracker:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Tracker for {username}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Branch Display */}
                    <Form.Group controlId="roleBranch">
                        <Form.Label style={{ marginBottom: '0.3rem' }}>Selected Staff</Form.Label>
                        <Form.Text style={{ fontSize: '1.50rem', display: 'block' }}>
                            {username ? (
                                username || 'No staff selected'
                            ) : (
                                'No staff selected'
                            )}
                        </Form.Text>
                    </Form.Group>
                    {/* Device Type */}
                    <Form.Group controlId="deviceType">
                        <Form.Label>Device Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="deviceType"
                            value={editTracker.deviceType}
                            onChange={handleChange}
                        >
                            <option value="">Select Device Type</option>
                            <option value="android">Android</option>
                            <option value="ios">iOS</option>
                            <option value="windows">Windows</option>
                            <option value="mac">Mac</option>
                        </Form.Control>
                    </Form.Group>

                    {/* IP Tracking */}
                    <Form.Group controlId="ipTracking">
                        <Form.Label>IP Tracking</Form.Label>
                        <Form.Control
                            as="select"
                            name="ipTracking"
                            value={editTracker.ipTracking}
                            onChange={handleChange}
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </Form.Control>
                    </Form.Group>

                    {/* Screenshot Tracking */}
                    <Form.Group controlId="screenshotTracking">
                        <Form.Label>Screenshot Tracking</Form.Label>
                        <Form.Control
                            as="select"
                            name="screenshotTracking"
                            value={editTracker.screenshotTracking}
                            onChange={handleChange}
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </Form.Control>
                    </Form.Group>

                    {/* Screenshot Interval (Show only if screenshot tracking is "yes") */}
                    {editTracker.screenshotTracking === 'yes' && (
                        <Form.Group controlId="screenshotInterval">
                            <Form.Label>Screenshot Interval (minutes)</Form.Label>
                            <Form.Control
                                as="select"
                                name="screenshotInterval"
                                value={editTracker.screenshotInterval}
                                onChange={handleChange}
                            >
                                <option value="1">1</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                            </Form.Control>
                        </Form.Group>
                    )}

                    {/* Location Tracking */}
                    <Form.Group controlId="locationTracking">
                        <Form.Label>Location Tracking</Form.Label>
                        <Form.Control
                            as="select"
                            name="locationTracking"
                            value={editTracker.locationTracking}
                            onChange={handleChange}
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Update Tracker
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditTrackerModal;
