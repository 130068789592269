import React, { useEffect } from 'react';

const PayPalPayment = ({ amount }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.paypal.com/sdk/js?client-id=your-paypal-client-id';
    script.addEventListener('load', () => {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: amount,
              },
            }],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            console.log('Payment successful:', details);
          });
        },
        onError: (err) => {
          console.error('Payment failed:', err);
        },
      }).render('#paypal-button-container');
    });
    document.body.appendChild(script);
  }, [amount]);

  return <div id="paypal-button-container"></div>;
};

export default PayPalPayment;
