import React, { useEffect, useState } from 'react';
import { convertCurrency } from '../services/apiService'; 

const ProductPrice = ({ priceInUSD, currencyCode }) => {
  const [convertedPrice, setConvertedPrice] = useState(priceInUSD);

  useEffect(() => {
    const fetchConvertedPrice = async () => {
      const price = await convertCurrency(priceInUSD, currencyCode);
      setConvertedPrice(price);
    };

    fetchConvertedPrice();
  }, [priceInUSD, currencyCode]);

  return convertedPrice;
};

export default ProductPrice;
