import React, { useState, useEffect } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { refreshToken, getuser } from "./services/apiService";
import LoadingScreen from "./utils/LoadingScreen";

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // To handle loading state
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("token");
    console.log('localStorage.getItem("token") :', token);
    const userId = localStorage.getItem("userId");

    // Handle token refresh and user validation
    useEffect(() => {
        const checkAuth = async () => {
            if (!token || !userId) {
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                setIsAuthenticated(false);
                setIsLoading(false);
                return;
            }

            try {

                const userResponse = await getuser(token);
                if (userResponse) {
                    console.log("User is authenticated");
                    setIsAuthenticated(true);
                } else {
                    throw new Error("User validation failed");
                }
            } catch (error) {
                console.error("Authentication error:", error);
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                navigate("/login", { state: { from: location } });
            } finally {
                setIsLoading(false);
            }
        };

        checkAuth();
    }, [token, userId, navigate, location]);

    // Inactivity timer
    useEffect(() => {
        let inactivityTimeout;

        const startInactivityTimer = () => {
            inactivityTimeout = setTimeout(() => {
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                navigate("/login", { state: { from: location } });
            }, 60 * 60 * 1000); // 1 hour of inactivity
        };

        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimeout);
            startInactivityTimer();
        };

        // Attach event listeners for user activity
        document.addEventListener("mousemove", resetInactivityTimer);
        document.addEventListener("keydown", resetInactivityTimer);
        document.addEventListener("scroll", resetInactivityTimer);
        document.addEventListener("click", resetInactivityTimer);

        // Start the timer on mount
        startInactivityTimer();

        return () => {
            // Cleanup listeners and timeout
            document.removeEventListener("mousemove", resetInactivityTimer);
            document.removeEventListener("keydown", resetInactivityTimer);
            document.removeEventListener("scroll", resetInactivityTimer);
            document.removeEventListener("click", resetInactivityTimer);
            clearTimeout(inactivityTimeout);
        };
    }, [navigate, location]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children; // Render protected content if authenticated
};

export default ProtectedRoute;
