import React, { useState, useRef } from 'react';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';
import { OneKlicksFileSDK } from '../../../OneKlicksFileSDK';
import { getuserdata } from '../../../services/apiService';

const AddNewWebsiteModal = ({ showWebsiteModal, setShowWebsiteModal, fetchSeoData }) => {
    const [webTechnology, setWebTechnology] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [favicon, setFavicon] = useState(null);  // Use null initially for file input
    const [otherTechnology, setOtherTechnology] = useState('');

    const webTechnologies = ['React', 'Angular', 'Vue', 'PHP', 'Node.js', 'Other'];

    const fileInputRef = useRef(null); // Ref to handle file input

    const handleFileChange = (e) => {
        const file = e.target.files[0];  // Get the file from the input
        if (file) {
            setFavicon(file);  // Store the file in state
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const technology = webTechnology === 'Other' ? otherTechnology : webTechnology;
        console.log('Website URL:', websiteUrl, 'Technology:', technology);
    
        const tableSchema = database_id;
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        const conditionsUsers = { "login_tockin": token };
    
        try {
            // Retrieve user data
            const employeeData11 = await oneclick.read(tableSchema, "users", conditionsUsers, "", "");
            const { id: user_id, company_id } = employeeData11.data[0] || {}; // Destructure to get user_id and company_id
    
            if (!user_id) {
                alert('User not found. Please log in again.');
                return;
            }
    
            // Retrieve employee data
            const conditionsEmployee = { "userid": user_id };
            const employeeData = await oneclick.read(tableSchema, "employees", conditionsEmployee, "", "");

            // Upload favicon file if it's available
            let uploadedFaviconId = '';
            if (favicon) {
                const uploadicon = await OneKlicksFileSDK.uploadFile(await getuserdata('id'), 'websites/favicon', '', favicon);
                uploadedFaviconId = uploadicon.file.id;
            }
    
            const data = {
                company_id: employeeData?.data[0]?.company_id || company_id,  // Fallback to company_id from user if not found
                branch_id: employeeData?.data[0]?.branch_id || '', // Fallback to empty string if branch_id not found
                website: websiteUrl,
                favicon: uploadedFaviconId,
                technology,
                status: 'Pending',
            };
    
            // Call the create method to add the new website
            const update = await oneclick.create(tableSchema, "seowebsites", data);
    
            if (update.success) {
                alert(update.message || 'Website added successfully.');
                setShowWebsiteModal(false);
                setWebTechnology('');
                setWebsiteUrl('');
                setOtherTechnology('');
                setFavicon(null); // Reset favicon input
                fetchSeoData(); // Fetch the updated data
            } else {
                console.error("Error updating website:", update.error);
                alert(update.error || 'Error updating website.');
            }
        } catch (error) {
            console.error('Error adding website:', error);
            alert('An error occurred while adding the website. Please try again.');
        }
    };
    

    return (
        showWebsiteModal && (
            <div className="modal fade show" style={{ display: 'block', background: '#00000078' }} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add New Website</h5>
                                <button type="button" className="btn-close" onClick={() => setShowWebsiteModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="websiteUrl" className="form-label">Website URL</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        id="websiteUrl"
                                        name="websiteUrl"
                                        value={websiteUrl}
                                        onChange={(e) => setWebsiteUrl(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="favicon" className="form-label">Website Favicon</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="favicon"
                                        name="favicon"
                                        onChange={handleFileChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="webTechnology" className="form-label">Select Web Technology</label>
                                    <select
                                        className="form-control"
                                        id="webTechnology"
                                        name="webTechnology"
                                        value={webTechnology}
                                        onChange={(e) => setWebTechnology(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Technology</option>
                                        {webTechnologies.map((tech) => (
                                            <option key={tech} value={tech}>{tech}</option>
                                        ))}
                                    </select>
                                </div>
                                {webTechnology === 'Other' && (
                                    <div className="mb-3">
                                        <label htmlFor="otherTechnology" className="form-label">Specify Other Technology</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="otherTechnology"
                                            name="otherTechnology"
                                            value={otherTechnology}
                                            onChange={(e) => setOtherTechnology(e.target.value)}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="i-btn primary--btn btn--md" onClick={() => setShowWebsiteModal(false)}>Cancel</button>
                                <button type="submit" className="i-btn btn--md btn--primary">Add Website</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    );
};

export default AddNewWebsiteModal;
