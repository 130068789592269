import React, { useState } from "react";
import { getuserdata } from "../../../services/apiService";
import { oneclick } from "../../../oneclick";
import database_id from "../../../utils/databaseConfig";

const CreateAttributeModel = ({ handleCreateClose, fetchData }) => {
    const [formData, setFormData] = useState({
        attribute_name: "",
        attribute_type: "",
        status: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleCreateAttribute = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const tableSchema = database_id; // Access schema from environment variables
            const tableName = "attribute"; // Define the target table name

            // Construct the data to be added
            const addData = {
                user_id: await getuserdata("id"), // Retrieve user ID dynamically
                company_id: await getuserdata("company_id"), // Retrieve company ID dynamically
                attribute_name: formData.attribute_name, // Form field: Attribute Name
                attribute_type: formData.attribute_type, // Form field: Attribute Type
                status: formData.status, // Form field: Status
            };

            const condition = {
                attribute_name: formData.attribute_name
            }

            // Make the API call using the oneclick.create method
            const addDataResponse = await oneclick.create(tableSchema, tableName, addData, condition);

            // Check if the response is successful
            if (addDataResponse.success) {
                console.log("Attribute created successfully:", addDataResponse.data);

                // Close the modal and reset the form fields
                fetchData();
                handleCreateClose();
                setFormData({
                    attribute_name: "",
                    attribute_type: "",
                    status: "",
                });
            } else {
                alert(addDataResponse.message);
            }
        } catch (err) {
            console.error("Error creating attribute:", err);
            setError(err.message || "Failed to create attribute. Please try again.");
        } finally {
            setLoading(false); // Reset loading state
        }
    };


    return (
        <div className="modal fade show d-block" id="createAttribute" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add New Attribute</h5>
                        <button type="button" className="i-btn bg--lite--danger text--danger btn--sm" onClick={handleCreateClose}>
                            <i className="las la-times"></i>
                        </button>
                    </div>
                    <form onSubmit={handleCreateAttribute}>
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-item mb-3">
                                        <label htmlFor="attribute_name" className="form-label">
                                            Attribute Name <sup className="text--danger">*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="attribute_name"
                                            name="attribute_name"
                                            value={formData.attribute_name}
                                            onChange={handleChange}
                                            placeholder="Enter Attribute Name"
                                            required
                                        />
                                    </div>
                                    <div className="form-item mb-3">
                                        <label htmlFor="attribute_type" className="form-label">
                                            Attribute Type <sup className="text--danger">*</sup>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="attribute_type"
                                            id="attribute_type"
                                            value={formData.attribute_type}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                --Select One Type--
                                            </option>
                                            <option value="1">Date</option>
                                            <option value="2">Boolean</option>
                                            <option value="3">Number</option>
                                            <option value="4">Text</option>
                                        </select>
                                    </div>

                                    <div className="form-item">
                                        <label htmlFor="status" className="form-label">
                                            Status <sup className="text--danger">*</sup>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="status"
                                            id="status"
                                            value={formData.status}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                --Select Status--
                                            </option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <div className="d-flex align-items-center gap-3">
                                <button type="button" className="i-btn danger--btn btn--md" onClick={handleCreateClose}>
                                    Cancel
                                </button>
                                <button type="submit" className="i-btn primary--btn btn--md" disabled={loading}>
                                    {loading ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </div>
                    </form>
                    {error && <div className="text-danger text-center mt-3">{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default CreateAttributeModel;
