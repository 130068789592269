import React, { useState, useEffect } from 'react';
import TopBar from './user/partials/topbar';
import Sidebar from './user/partials/sidebar';
import DynamicSidebar from './utils/DynamicSidebar';
import { useNavigate } from 'react-router-dom';
import { alllanguages, getuser, general_settings, getcurrencies } from './services/apiService';
import { oneclick } from './oneclick';
import LoadingScreen from './utils/LoadingScreen';
import database_id from './utils/databaseConfig';



const profileImage = '/path/to/profile-image.jpg';

export const UserLayout = ({ children }) => {
    const token = localStorage.getItem('token');
    const [languages, setLanguages] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [settings, setSettings] = useState([]);
    const [userdat, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

    useEffect(() => {
        if (!token) {
            navigate('/login');
            return;
        }

        const fetchLanguages = async () => {
            try {
                const response = await alllanguages();
                const userResponse = await getuser(token);
                console.log('UserLayout userResponse:', userResponse);
                const userData = userResponse; // Adjust based on your response structure
                const Setting = await general_settings();
                const gecurrencies = await getcurrencies();

                // Fetch additional user-related data

                const tableSchema = database_id;

                // Fetch user details based on login token
                const userCondition = { login_tockin: token };
                const userDetailsResponse = await oneclick.read(tableSchema, "users", userCondition, '', "", "");
                const userDetails = userDetailsResponse.data[0]; // Adjust based on your response structure

                // Fetch employee data
                const employeeCondition = { userid: userDetails.id };
                const employeesResponse = await oneclick.read(tableSchema, "employees", employeeCondition, '', "", "");
                const employeeData = employeesResponse.data[0] ? employeesResponse.data[0] : ""; // Adjust based on your response structure

                // Fetch role data for the employee
                let roleData;
                if(employeeData){
                    const roleCondition = { id: employeeData.role_id };
                    const rolesResponse = await oneclick.read(tableSchema, "roles", roleCondition, '', "", "");
                    roleData = rolesResponse.data[0]; // Adjust based on your response structure
                }else{
                    roleData = "";
                }


                //this all data send setUser(userdata); hear
                setLanguages(response);
                setUser({ ...userData, employeeData, roleData }); // Merge user data with employee and role data
                setSettings(Setting[0] || {});
                setCurrencies(gecurrencies);
            } catch (err) {
                if (err.message === 'User not found') {
                    localStorage.clear();
                    navigate('/login');
                } else {
                    setError(err.message);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchLanguages();
    }, [token, navigate]);

    if (!token) return null; // Early return moved here
    
    if (error) return <div>Error: {error}</div>;

    const toggleSidebar = () => {
        console.log('toggleSidebar : True');
        setIsSidebarMinimized(!isSidebarMinimized);
    };

    console.log('user dashbore Setting :', settings);

    return (
        <>
            <div className="content" id="main-content">
                <DynamicSidebar settings={settings} userdat={userdat} isMinimized={isSidebarMinimized}/>
                {/* <Sidebar settings={settings} userdat={userdat} isMinimized={isSidebarMinimized}/> */}
                <div className="overlay"></div>
                <div id="mainContent" className={`main_content added ${isSidebarMinimized ? 'minimized' : ''}`}>
                    <TopBar
                        currencies={currencies}
                        languages={languages}
                        userdat={userdat}
                        profileImage={profileImage}
                        settings={settings}
                        toggleSidebar={toggleSidebar}
                        isMinimized={isSidebarMinimized}
                    />
                    <div className="dashboard_container">
                        <div className="inner-content">
                            {children}
                        </div>
                    </div>
                    <footer>
                        <div className="footer-content">
                            <p>© {new Date().getFullYear()} Your Company</p>
                            <div className="footer-right">
                                <span>App-Version: 1.0.0</span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
};
