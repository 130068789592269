import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { oneclick } from '../../../oneclick'; // Import the oneclick API service
import database_id from '../../../utils/databaseConfig';

const DeleteGroupModal = ({ onClose, data, fetchData, type }) => {
  console.log('DeleteGroupModal:', data);
  const handleDelete = async () => {
    const tableSchema = database_id; // Access schema from environment variables
    const tableName = type == 'group' ? 'groups' : 'contacts';
    const condition = { id: data.id }; // The group to delete based on the ID

    try {
      const response = await oneclick.delete(tableSchema, tableName, condition);
      if (response.success) {
        fetchData(); // Fetch updated data after successful deletion
        onClose(); // Close the modal after deleting
      } else {
        alert('Failed to delete group. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting group:', error);
      alert('An error occurred while deleting the group.');
    }
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {type == 'group' ? 'Group' : 'Contact'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this {type == 'group' ? 'Group' : 'Contact'}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteGroupModal;
