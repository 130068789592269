import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { a } from "react-spring";
import { oneclick } from '../../../oneclick';
import database_id from "../../../utils/databaseConfig";
import { getuserdata, hashPassword, sendEmail } from "../../../services/apiService";
import { Password } from "@mui/icons-material";
import TagsInput from "../../../utils/TagsInput";

const AddNewProjectModal = ({ isOpen, closeModal, getprojects, selecydata, setSelectData }) => {
    console.log("Selected Data:", selecydata.project_type);
    const [tags, setTags] = useState([]); // State for tags
    const [projectName, setProjectName] = useState(selecydata.name || "");
    const [description, setDescription] = useState(selecydata.description || "");
    const [projectType, setProjectType] = useState(selecydata.project_type || "");
    const [technologies, setTechnologies] = useState([]);
    const [costType, setCostType] = useState(selecydata.cost_type || "");
    const [recaringType, setrecaringType] = useState(selecydata.recaringType || "");
    const [projectCost, setProjectCost] = useState(selecydata.project_cost || "");
    const [clientEmails, setClientEmails] = useState([]);
    const [newEmail, setNewEmail] = useState("");
    const [projectDivision, setProjectDivision] = useState("");
    const [selectedClients, setSelectedClients] = useState(selecydata.client_id || "");  // Track selected clients
    const [addClientEmail, setAddClientEmail] = useState(false);
    const [user_currency, setUserCurrency] = useState(""); // Default currency can be dynamic   
    const [rawCost, setRawCost] = useState(""); // State to store raw value 
    const [currencyFormat, setCurrencyFormat] = useState("INR"); // Default currency format can be dynamic
    const [status, setStatus] = useState(selecydata.status || "Active");

    const clients = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "clients";
            const conditions = {
                company_id: await getuserdata('company_id'),
            };
            const orderBy = "id DESC";
            const search = {};
            const pagination = {};
            const margedata = [
                {
                    target_label: "users",
                    target_table: "users",
                    target_column: "id",
                    target_value: "user_id"
                }
            ];
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
            console.log("Clients:", response);
            setClientEmails(response.data);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    };



    useEffect(() => {
        if (selecydata.tags) {
            // Remove the curly braces and parse the string as an array
            const tagsString = selecydata.tags.replace(/[{}"]/g, ""); // Remove curly braces and quotes
            const tagsArray = tagsString.split(","); // Split by comma to get individual tags
            console.log("Tags:", tagsArray);
            setTags(tagsArray); // Update the state with the array of tags
        }
        const getdata = async () => {
            const user_currency = await getuserdata('currencyCode');
            setUserCurrency(user_currency || currencyFormat);
        };
        getdata();
        clients();
    }, []);


    const handleEmailAdd = async () => {
        try {
            if (newEmail && !clientEmails.includes(newEmail)) {
                // Add new email to the list and reset input
                setClientEmails([...clientEmails, newEmail]);
                setNewEmail(""); // Reset email input

                const tableSchema = database_id; // Database schema
                const tableName = "users"; // Table name for users

                // Conditions to check if email or phone number exists
                const conditions = {
                    email: newEmail,
                    phone_number: newEmail,
                };
                const orderBy = "id DESC";
                const search = {};
                const pagination = {};
                const margedata = [];

                // Check if the user already exists
                const updata = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
                console.log("handleEmailAdd - Check User:", updata);

                if (!updata.success) {
                    // User doesn't exist, create a new user
                    const password = await hashPassword('1234567890');
                    const userData = {
                        email: newEmail,
                        Password: password.hashedPassword, // Default password (ensure security if used in production)
                    };
                    const conditions = {
                        email: newEmail,
                    };

                    const userResponse = await oneclick.create(tableSchema, tableName, userData, conditions);
                    if (userResponse.success) {
                        console.log("User Created:", userResponse.insertedData.id);

                        const tableName = 'companies';

                        const conditions = {
                            id: await getuserdata('company_id'),
                        }
                        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

                        console.log("Company Data:", response.data[0].smtp_config);
                        // Send email to the new user smtpConfig, email, subject, text

                        const smtpConfig = JSON.parse(response.data[0].smtp_config);
                        const email = newEmail;
                        const subject = "Test Email";
                        const text = "This is a test email sent using Postman.";
                        const sendmail = await sendEmail(smtpConfig, email, subject, text);
                        console.log("Email Sent:", sendmail);
                        // Associate the new user with the client
                        const clientTable = "clients";
                        const clientData = {
                            company_id: await getuserdata("company_id"), // Retrieve the company ID
                            user_id: userResponse.insertedData.id, // Use the new user's ID
                        };

                        const clientResponse = await oneclick.create(tableSchema, clientTable, clientData);
                        console.log("Client Association:", clientResponse);
                        if (clientResponse.success) {
                            console.log("Client Association Successful:", clientResponse);
                        } else {
                            console.error("Failed to Associate Client:", clientResponse.message);
                        }
                    } else {
                        console.error("Failed to Create User:", userResponse.message);
                    }
                } else {
                    console.log("User Already Exists:", updata);
                }
            } else {
                console.warn("Invalid email or email already added.");
            }
        } catch (error) {
            console.error("Error in handleEmailAdd:", error.message);
        }
    };


    const handleProjectTypeChange = (e) => {
        const selectedType = e.target.value;
        setProjectType(selectedType);

        // Set default technologies based on project type
        if (selectedType === "Development") {
            setTechnologies(["ReactJS", "NodeJS", "MySQL"]);
        } else if (selectedType === "Marketing") {
            setTechnologies(["SEO", "PPC", "Content Marketing"]);
        } else if (selectedType === "Servers") {
            setTechnologies(["AWS", "Azure", "Docker"]);
        } else {
            setTechnologies([]);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const tagsnew = JSON.stringify(tags);
        const jsonString = JSON.stringify(tagsnew);

        // Optionally, show a user-friendly log of the data
        console.log("Project Name:", projectName);
        console.log("Description:", description);
        console.log("Project Type:", projectType);
        console.log("Tags:", `"{${tags}}"`);
        console.log("Cost Type:", costType);
        console.log("Project Cost:", projectCost);
        console.log("Selected Clients:", selectedClients);
        console.log("RecaringType:", recaringType);
        console.log("Project Division:", projectDivision);

        const tableSchema = database_id;
        const tableName = "projucts";
        const data = {
            company_id: await getuserdata('company_id'),
            client_id: selectedClients,
            name: projectName,
            description: description,
            project_type: projectType,
            tags: `"{${tags}}"`,
            cost_type: costType,
            project_cost: projectCost,
            recaringType: recaringType,
            division: projectDivision,
            status: status,
        };

        let responce = {};
        if (selecydata.id) {
            const conditions = {
                id: selecydata.id,
            };
            responce = await oneclick.update(tableSchema, tableName, data, conditions);
        } else {
            responce = await oneclick.create(tableSchema, tableName, data, "");
        }

        if (responce.success) {
            console.log("Project Created:", responce);
            setSelectData([]);
        } else {
            console.error("Failed to create project:", responce.message);
            alert("Failed to create project. Please try again.");
        }


        closeModal(); // Close modal after form submission
        setSelectData([]); // Clear selected data
        getprojects(); // Refresh the projects list
    };


    return (
        <div>
            <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="addNewLabel" aria-hidden={!isOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addNewLabel">{!selecydata.id ? 'Add New Project' : 'Update Project'}</h5>
                            <button type="button" className="btn-close" onClick={
                                () => {
                                    if (selecydata.id) {
                                        setSelectData([]);
                                    }
                                    closeModal();
                                }

                            }><i className="fa fa-close"></i></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                {/* Project Name */}
                                <div className='mb-3'>
                                    <label htmlFor="name" className="form-label">Project Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        required
                                    />
                                </div>

                                {/* Project Description */}
                                <div className='mb-3'>
                                    <label htmlFor="name" className="form-label">Description</label>
                                    <textarea
                                        className="form-control"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                    />
                                </div>

                                {/* Project Type */}
                                <div className="mb-3">
                                    <label htmlFor="team" className="form-label">Select Team</label>
                                    <select
                                        value={projectType}
                                        className="form-select"
                                        onChange={handleProjectTypeChange}
                                        required
                                    >
                                        <option value="">Select Type</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="Servers">Servers</option>
                                        <option value="Development">Development</option>
                                    </select>
                                </div>

                                {/* Technologies */}
                                <div className='mb-3'>
                                    <label htmlFor="name" className="form-label" >Projuct Tags</label>
                                    <TagsInput tags={tags} setTags={setTags} />
                                </div>


                                {/* Project Cost Type */}
                                <div className='mb-3'>
                                    <label htmlFor="name" className="form-label" >Cost Type</label>
                                    <select
                                        value={costType}
                                        className="form-select"
                                        onChange={(e) => setCostType(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Cost Type</option>
                                        <option value="Hourly">Hourly</option>
                                        <option value="Fixed">Fixed</option>
                                        <option value="recurring">Recurring</option>
                                    </select>
                                </div>
                                {/* Project Division */}
                                {costType == "recurring" ? (
                                    <>
                                        <div className='mb-3'>
                                            <label htmlFor="name" className="form-label">Recurring Type</label>
                                            <select
                                                value={recaringType}
                                                className="form-select"
                                                onChange={(e) => setrecaringType(e.target.value)}
                                                required
                                            >
                                                <option value="">Select Recurring Type</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Yearly">Yearly</option>
                                            </select>
                                        </div>

                                    </>
                                ) : (
                                    <div className='mb-3'>
                                        <label htmlFor="name" className="form-label">Project Division ( Working Days )</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={projectDivision}
                                            onChange={(e) => setProjectDivision(e.target.value)}
                                            required
                                        />
                                    </div>
                                )}



                                {/* Project Cost */}
                                <div className='mb-3'>
                                    {costType == "recurring" ? (
                                        <label htmlFor="name" className="form-label">Project Cost For {recaringType}</label>
                                    ) : (
                                        <label htmlFor="name" className="form-label">Project Cost {costType == "Hourly" ? 'For Hourly' : 'For Fixed'}</label>
                                    )}

                                    <input
                                        type="number"
                                        value={projectCost}
                                        className="form-control"
                                        onChange={(e) => {
                                            setProjectCost(e.target.value); // Update formatted value
                                        }
                                        }
                                        required
                                    />
                                </div>

                                {/* Select Client Emails */}
                                <div className="row mb-3">
                                    <div className='col-lg-10 col-md-10 col-sm-10 '>
                                        <label htmlFor="name" className="form-label" >Select Clients</label>
                                        <select
                                            value={selectedClients}
                                            className="form-select"
                                            isSearchable
                                            onChange={(e) => setSelectedClients(e.target.value)}
                                        >
                                            {clientEmails.map((email, index) => (
                                                <option key={index} value={email.user_id}>
                                                    {email.users?.name == null ? email.users?.email : email.users?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-lg-2 d-flex align-items-end col-md-2 col-sm-2' style={{ flexDirection: 'row-reverse' }}>
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            setAddClientEmail(!addClientEmail);
                                        }}>
                                            {addClientEmail ? 'Close' : 'Add'}
                                        </button>
                                    </div>
                                </div>


                                {/* Add Client Email */}
                                {addClientEmail && (
                                    <div className='mb-3 row'>
                                        <div className='col-lg-8 col-md-8 col-sm-8'>
                                            <label htmlFor="name" className="form-label">Client Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                value={newEmail}
                                                onChange={(e) => setNewEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-lg-4 d-flex align-items-end col-md-4 col-sm-4' style={{ flexDirection: 'row-reverse' }}>
                                            <button type="button" className="btn btn-primary"
                                                onClick={() => {
                                                    if (newEmail.trim() === "") {
                                                        alert("Please enter an email address");
                                                        return;
                                                    }
                                                    setAddClientEmail(false);
                                                    handleEmailAdd();
                                                }}>
                                                Add Email
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {/* Submit Button */}
                                <div className="mb-3">
                                    <label htmlFor="status" className="form-label">Status</label>
                                    <select
                                        value={status}
                                        className="form-select"
                                        onChange={(e) => setStatus(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Proposal">Proposal</option>
                                        <option value="Starting">Starting</option>
                                        <option value="Design">Design</option>
                                        <option value="Completed">Completed</option>
                                        <option value="processing">Processing</option>
                                        <option value="Pending">Pending</option>

                                    </select>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                onClick={
                                    () => {
                                        if (selecydata.id) {
                                            setSelectData([]);
                                        }
                                        closeModal();
                                    }
                                }
                            >Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleFormSubmit}>{selecydata ? 'Update Projuct' : 'Add Projuct'}</button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default AddNewProjectModal;
