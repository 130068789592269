import React, { useState } from "react";
import "./TagsInput.css"; // Optional: Add your styles here

const TagsInput = ({ tags, setTags }) => {
  const [inputValue, setInputValue] = useState(""); // State for input value

  // Handle adding a tag
  const handleAddTag = () => {
    if (inputValue.trim() && !tags.includes(inputValue.trim())) {
      setTags([...tags, inputValue.trim()]);
      setInputValue(""); // Clear the input field
    }
  };

  // Handle removing a tag
  const handleRemoveTag = (index) => {
    setTags(tags.filter((_, i) => i !== index)); // Remove the tag by index
  };

  // Handle key press in the input field
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleAddTag();
    }
  };

  return (
    <div className="tags-input-container">
      <div className="tags-list">
        {tags.map((tag, index) => (
          <div key={index} className="tag">
            {tag}
            <button
              type="button"
              className="remove-tag"
              onClick={() => handleRemoveTag(index)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Type a keyword and press Enter"
        className="tags-input"
      />
      <button type="button" onClick={handleAddTag} className="add-tag-button">
        Add
      </button>
    </div>
  );
};

export default TagsInput;
