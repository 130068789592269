import React, { useState } from "react";

const BeeFreeCredentialsSetup = () => {
  // State management for form fields
  const [status, setStatus] = useState("1");
  const [clientId, setClientId] = useState("b2369021-3e95-4ca4-a8c8-2ed3e2531865");
  const [clientSecret, setClientSecret] = useState("uL3UKV8V4RLv77vodnNTM8e93np9OYsS5P2mJ0373Nt9ghbwoRbn");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    const formData = {
      status,
      client_id: clientId,
      client_secret: clientSecret,
    };

    console.log("Form submitted:", formData);

    // Submit the formData to the server as needed
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                Bee Free Credentials Setup
                <sup className="pointer" title="To setup bee free auth">
                  <a href="https://developers.beefree.io/" target="_blank" rel="noopener noreferrer">
                    <svg
                      className="svg-inline--fa fa-circle-info"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-info"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="20"  // set the width here
                      height="20" // set the height here
                    >
                      <path
                        fill="currentColor"
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32 0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                      ></path>
                    </svg>
                  </a>
                </sup>
              </h4>
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
                <input type="hidden" name="_token" value="JY3trr9nM6W48gliidWmcyKnfJ6EQAqNc5C4oefq" />
                <div className="form-wrapper">
                  <div className="row g-4">
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="status">Status <sup className="text--danger">*</sup></label>
                      <select
                        className="form-select"
                        name="bee_plugin[status]"
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="1">ON</option>
                        <option value="2">OFF</option>
                      </select>
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="client_id">Client Id <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        name="bee_plugin[client_id]"
                        id="client_id"
                        className="form-control"
                        value={clientId}
                        onChange={(e) => setClientId(e.target.value)}
                        placeholder="********"
                        required
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="client_secret">Client Secret <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        name="bee_plugin[client_secret]"
                        id="client_secret"
                        className="form-control"
                        value={clientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                        placeholder="********"
                        required
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="i-btn primary--btn btn--lg">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BeeFreeCredentialsSetup;
