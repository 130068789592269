import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getImageBaseUrl, general_settings } from '../services/apiService';
import DynamicSEO from '../components/layouts/DynamicSEO';

const Dashboard = () => {
  const [featureContent, setFeatureContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageBaseUrl, setImageBaseUrl] = useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        const content = await general_settings();
        console.log('API Response:', content); // Log the response
        setFeatureContent(content[0] || {});
        setImageBaseUrl(getImageBaseUrl());
      } catch (error) {
        console.error('Failed to load data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  // Construct the favicon URL only if featureContent.favicon is available
  const faviconURL = featureContent.favicon
    ? `${imageBaseUrl}/assets/file/images/logoIcon/${featureContent.favicon}`
    : null;
  
  console.log('Favicon URL:', faviconURL);
  const user = {
    name: 'John Doe',
    credit: 150,
    email_credit: 200,
    whatsapp_credit: 120,
  };


    useEffect(() => {
        // Set image base URL when the component mounts
        setImageBaseUrl(getImageBaseUrl());
    }, []);

  const logs = {
    sms: {
      all: 500,
      success: 450,
      pending: 30,
      failed: 20,
    },
    email: {
      all: 400,
      success: 380,
      pending: 10,
      failed: 10,
    },
    whats_app: {
      all: 300,
      success: 290,
      pending: 5,
      failed: 5,
    },
  };

  const transactions = [
    { id: 1, trx: 'TRX123', details: 'Payment for Plan', amount: 100, created_at: '2024-08-05' },
    { id: 2, trx: 'TRX124', details: 'Purchase of Credits', amount: -50, created_at: '2024-08-04' },
  ];

  const general = {
    site_logo: '/path/to/logo.png',
    app_version: '1.0.0',
    schedule_at: '2024-08-06',
    phone: '+1234567890',
    email: 'support@example.com',
    country: 'USA',
    address: '123 Main Street, Anytown, USA',
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <section>
      {/* Only render DynamicSEO when faviconURL is not null */}
      {faviconURL && (
        <DynamicSEO
          title="OneKlicks - Cloud Services"
          description="OneKlicks offers the best cloud services for businesses of all sizes."
          keywords="cloud hosting, cloud services, cloud solutions"
          favicon={faviconURL}
          ogTitle="OneKlicks Cloud Services"
          ogDescription="Leading provider of cloud services and hosting solutions."
          ogImage="/images/cloud-service.png"
          twitterCard="summary_large_image"
        />
      )}
      <div className="row g-4">
        <div className="col-md-6">
          <div className="card banner-card plan-card ravi">
            <h3>Welcome Back {user.name}</h3>
            <p className='ravinn'>If you ever feel the need to upgrade any of your plan, simply click here to explore your options.</p>
            <Link to="/user/plan/create" className="i-btn primary--btn btn--lg">
              Upgrade Plan
            </Link>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card banner-card email-card ravi">
            <h3>Send Email for Production</h3>
            <p className='ravinn'>Efficient Production Email Notification: Streamlining Communications Seamlessly and Effectively</p>
            <Link to="/user/manage/email/send" className="i-btn primary--btn btn--lg">
              Send Mail
            </Link>
          </div>
        </div>


        <div className="col-md-6 col-xl-3">
          <div className="card user-credit-card">
            <div className="card-body">
              <div className="user-credit-content">
                <div className="remaining-credit">
                  <h4>{user.credit}</h4>
                  <p className="pb-3 pt-2">Projects</p>
                  <Link to="/user/plan/create" className="i-btn btn--sm info--btn">
                    Add New
                  </Link>
                </div>

                <div className="icon text--secondary">
                  <img src={`${imageBaseUrl}/assets/file/images/logoIcon/12148682.png`} style={{width: '100px'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xl-3">
          <div className="card user-credit-card">
            <div className="card-body">
              <div className="user-credit-content">
                <div className="remaining-credit">
                  <h4>{user.credit}</h4>
                  <p className="pb-3 pt-2">Database</p>
                  <Link to="/user/plan/create" className="i-btn btn--sm info--btn">
                    Add New
                  </Link>
                </div>

                <div className="icon text--secondary">
                  <img src={`${imageBaseUrl}/assets/file/images/logoIcon/database_PNG5.png`} style={{width: '100px'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xl-3">
          <div className="card user-credit-card">
            <div className="card-body">
              <div className="user-credit-content">
                <div className="remaining-credit">
                  <h4>{user.credit}</h4>
                  <p className="pb-3 pt-2">Staff</p>
                  <Link to="/user/plan/create" className="i-btn btn--sm info--btn">
                    Add New
                  </Link>
                </div>

                <div className="icon text--secondary">
                  <img src={`${imageBaseUrl}/assets/file/images/logoIcon/aistaff.png`} style={{width: '100px'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-6 col-xl-3">
          <div className="card user-credit-card">
            <div className="card-body">
              <div className="user-credit-content">
                <div className="remaining-credit">
                  <h4>{user.credit}</h4>
                  <p className="pb-3 pt-2">Projects Tasks</p>
                  <Link to="/user/plan/create" className="i-btn btn--sm info--btn">
                    Add New
                  </Link>
                </div>

                <div className="icon text--secondary">
                  <img src={`${imageBaseUrl}/assets/file/images/logoIcon/taskimg.png`} style={{width: '100px'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xl-4">
          <div className="card user-credit-card">
            <div className="card-body">
              <div className="user-credit-content">
                <div className="remaining-credit">
                  <h4>{user.credit}</h4>
                  <p className="pb-3 pt-2">Remaining SMS Credit</p>
                  <Link to="/user/plan/create" className="i-btn btn--sm info--btn">
                    Buy Credit
                  </Link>
                </div>

                <div className="icon text--secondary">
                  <img src={`${imageBaseUrl}/assets/file/images/frontend/sms-marketing-icon-new-message-or-email-icon-speech-bubble-with-text-3d-render-png.webp`} style={{width: '100px'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xl-4">
          <div className="card user-credit-card">
            <div className="card-body">
              <div className="user-credit-content">
                <div className="remaining-credit">
                  <h4>{user.email_credit}</h4>
                  <p className="pb-3 pt-2">Remaining Email Credit</p>
                  <Link to="/user/plan/create" className="i-btn btn--sm info--btn">
                    Buy Credit
                  </Link>
                </div>

                <div className="icon text--danger">
                  <img src={`${imageBaseUrl}/assets/file/images/frontend/email-3d-icon-png.webp`} style={{width: '100px'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xl-4">
          <div className="card user-credit-card">
            <div className="card-body">
              <div className="user-credit-content">
                <div className="remaining-credit">
                  <h4>{user.whatsapp_credit}</h4>
                  <p className="pb-3 pt-2">Remaining Whatsapp Credit</p>
                  <Link to="/user/plan/create" className="i-btn btn--sm info--btn">
                    Buy Credit
                  </Link>
                </div>

                <div className="icon text--success">
                  <img src={`${imageBaseUrl}/assets/file/images/frontend/free-whatsapp-logo-5476204-4602455.webp`} style={{width: '100px'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row g-4 dash-cards">
            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="info-card">
                  <div className="info-card-top">
                    <div className="info-card-header">
                      <h4>SMS Statistics</h4>
                      <span>
                        <i className="las la-comment"></i>
                      </span>
                    </div>
                  </div>

                  <div className="info-card-content">
                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--info text--info">
                          <i className="las la-address-book"></i>
                        </span>

                        <div>
                          <h4>{logs.sms.all}</h4>
                          <p>Total</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--success text--success">
                          <i className="las la-check-double"></i>
                        </span>

                        <div>
                          <h4>{logs.sms.success}</h4>
                          <p>Success</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--warning text--warning ">
                          <i className="las la-hourglass-half"></i>
                        </span>
                        <div>
                          <h4>{logs.sms.pending}</h4>
                          <p>Pending</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--danger text--danger">
                          <i className="las la-times-circle"></i>
                        </span>
                        <div>
                          <h4>{logs.sms.failed}</h4>
                          <p>Failed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="info-card">
                  <div className="info-card-top">
                    <div className="info-card-header">
                      <h4>Email Statistics</h4>

                      <span>
                        <i className="las la-envelope-open"></i>
                      </span>
                    </div>
                  </div>

                  <div className="info-card-content">
                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--info text--info">
                          <i className="las la-address-book"></i>
                        </span>

                        <div>
                          <h4>{logs.email.all}</h4>
                          <p>Total</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--success text--success">
                          <i className="las la-check-double"></i>
                        </span>

                        <div>
                          <h4>{logs.email.success}</h4>
                          <p>Success</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--warning text--warning ">
                          <i className="las la-hourglass-half"></i>
                        </span>
                        <div>
                          <h4>{logs.email.pending}</h4>
                          <p>Pending</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--danger text--danger">
                          <i className="las la-times-circle"></i>
                        </span>
                        <div>
                          <h4>{logs.email.failed}</h4>
                          <p>Failed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="info-card">
                  <div className="info-card-top">
                    <div className="info-card-header">
                      <h4>WhatsApp Statistics</h4>
                      <span>
                        <i className="lab la-whatsapp"></i>
                      </span>
                    </div>
                  </div>

                  <div className="info-card-content">
                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--info text--info">
                          <i className="las la-address-book"></i>
                        </span>

                        <div>
                          <h4>{logs.whats_app.all}</h4>
                          <p>Total</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--success text--success">
                          <i className="las la-check-double"></i>
                        </span>

                        <div>
                          <h4>{logs.whats_app.success}</h4>
                          <p>Success</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--warning text--warning ">
                          <i className="las la-hourglass-half"></i>
                        </span>
                        <div>
                          <h4>{logs.whats_app.pending}</h4>
                          <p>Pending</p>
                        </div>
                      </div>
                    </div>

                    <div className="info-inner-card">
                      <div className="info-inner-content">
                        <span className="bg--lite--danger text--danger">
                          <i className="las la-times-circle"></i>
                        </span>
                        <div>
                          <h4>{logs.whats_app.failed}</h4>
                          <p>Failed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-4 mt-1">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="info-card-header">
                    <h5 className="mb-0">Recent Transactions</h5>
                  </div>

                  <table className="table mb-0 mt-3">
                    <thead>
                      <tr>
                        <th>Trx</th>
                        <th>Details</th>
                        <th>Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.length === 0 ? (
                        <tr>
                          <td colSpan="100%">No transaction found</td>
                        </tr>
                      ) : (
                        transactions.map((trx) => (
                          <tr key={trx.id}>
                            <td>{trx.trx}</td>
                            <td>{trx.details}</td>
                            <td className="fw-bold">
                              {trx.amount > 0 ? (
                                <span className="text-success">+ {trx.amount}</span>
                              ) : (
                                <span className="text-danger">{trx.amount}</span>
                              )}
                            </td>
                            <td>{formatDate(trx.created_at)}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="info-card-header">
                    <h5 className="mb-0">Our Services</h5>
                  </div>

                  <div className="mt-3">
                    <Link
                      to="#0"
                      className="text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#addPlan"
                    >
                      <div className="dash-services">
                        <div className="left text--info">
                          <i className="las la-chart-area"></i>
                        </div>

                        <div className="right">
                          <h6>Buy Credit</h6>
                          <p>Top Up your Account Balance</p>
                        </div>
                      </div>
                    </Link>

                    <Link
                      to="#0"
                      className="text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#addPlan"
                    >
                      <div className="dash-services">
                        <div className="left text--success">
                          <i className="las la-cogs"></i>
                        </div>

                        <div className="right">
                          <h6>Api Service</h6>
                          <p>Get your api and start to work</p>
                        </div>
                      </div>
                    </Link>

                    <Link
                      to="#0"
                      className="text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#addPlan"
                    >
                      <div className="dash-services">
                        <div className="left text--secondary">
                          <i className="las la-cart-arrow-down"></i>
                        </div>

                        <div className="right">
                          <h6>Shop Items</h6>
                          <p>Get the Best Items in the Market</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="info-card-header">
                    <h5 className="mb-0">Latest Update</h5>
                  </div>

                  <div className="blog">
                    <img
                      src={general.site_logo}
                      alt="General Update"
                      className="img-fluid"
                    />
                    <div className="blog-body">
                      <h5>{general.app_version}</h5>
                      <p className="mb-0">{general.schedule_at}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="info-card-header">
                    <h5 className="mb-0">Contact Info</h5>
                  </div>

                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Phone</span>
                      <span>{general.phone}</span>
                    </li>

                    <li className="list-group-item d-flex justify-content-between">
                      <span>Email</span>
                      <span>{general.email}</span>
                    </li>

                    <li className="list-group-item d-flex justify-content-between">
                      <span>Country</span>
                      <span>{general.country}</span>
                    </li>

                    <li className="list-group-item d-flex justify-content-between">
                      <span>Address</span>
                      <span>{general.address}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Manage</h5>
                  <Link
                    to="/user/manage"
                    className="i-btn primary--btn btn--lg"
                  >
                    Manage Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
