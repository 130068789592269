// import React, { useState } from 'react';
// import axios from 'axios';

// const EmployeeEditForm = () => {
//     const [userId, setUserId] = useState('3');
//     const [root, setRoot] = useState('myimages');
//     const [file, setFile] = useState(null);
//     const [message, setMessage] = useState('');

//     // Handle file selection
//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//         console.log('File selected:', event.target.files[0]);
//     };

//     // Handle form submission
//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         if (!userId || !root || !file) {
//             setMessage('Please fill all fields and select a file.');
//             return;
//         }

//         const formData = new FormData();
//         formData.append('userId', userId);
//         formData.append('root', root);
//         formData.append('file', file);

//         try {
//             const response = await axios.post(`${apiUrl}/api/files/uploads`, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             });

//             console.log('Upload response:', response.data);
//             setMessage(response.data.message || 'File uploaded successfully!');
//         } catch (error) {
//             console.error('Error uploading file:', error);
//             setMessage(error.response?.data?.error || 'An error occurred during file upload.');
//         }
//     };

//     return (
//         <div>
//             <h2>File Upload</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>
//                         User ID:
//                         <input
//                             type="text"
//                             value={userId}
//                             onChange={(e) => setUserId(e.target.value)}
//                             required
//                         />
//                     </label>
//                 </div>
//                 <div>
//                     <label>
//                         Root:
//                         <input
//                             type="text"
//                             value={root}
//                             onChange={(e) => setRoot(e.target.value)}
//                             required
//                         />
//                     </label>
//                 </div>
//                 <div>
//                     <label>
//                         File:
//                         <input
//                             type="file"
//                             onChange={handleFileChange}
//                             required
//                         />
//                     </label>
//                 </div>
//                 <button type="submit">Upload</button>
//             </form>
//             {message && <p>{message}</p>}
//         </div>
//     );
// };

// export default EmployeeEditForm;




import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { oneclick } from '../../oneclick';
import { OneKlicksFileSDK } from '../../OneKlicksFileSDK';
import { getImageBaseUrl } from '../../services/apiService';
import database_id from '../../utils/databaseConfig';


const EmployeeEditForm = () => {

    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

    useEffect(() => {
        // Set image base URL when the component mounts
        setImageBaseUrl(getImageBaseUrl());
    }, []);
    const { employeeId, companyid } = useParams(); // Get employeeId from URL
    const navigate = useNavigate();
    const tableSchema = database_id;
    const countries = "countries";
    const states = "states";
    const cities = "cities";
    const roles = "roles";
    const branches = "branches";
    const employees = "employees";
    const users = "users";

    const [allCountries, setAllCountries] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [allBranchs, setAllBranchs] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [allroles, setAllroles] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState('');
    const [selectedStateId, setSelectedStateId] = useState('');
    const [selectedBranchId, setSelectedBranchId] = useState('');
    const [selectedCityId, setSelectedCityId] = useState('');
    const [selectedRoleId, setSelectedRoleId] = useState('');

    const [files, setFiles] = useState({
        aadhar_file: null,
        pan_file: null,
        passport_photo: null,
        bank_proof: null,
        salary_slips: null,
        signature: null
    });


    // Utility function to check if the file is an image
    const isImageFile = (fileUrl) => {
        const imageExtensions = ['.png', '.jpg', '.jpeg'];
        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        return imageExtensions.includes(`.${fileExtension}`);
    };

    // State to manage the visibility of the additional time fields
    const [showCheckinDetails, setShowCheckinDetails] = useState(false);
    const [showCheckoutDetails, setShowCheckoutDetails] = useState(false);

    // Define the initial employee state with all fields
    const [employeeData, setEmployeeData] = useState({
        fullName: '',
        email: '',
        phone_number: '',
        role_id: '', // Role field
        branch_id: '',
        address: {
            country: '',
            city: '',
            state: '',
            zip: ''
        },
        guardians: [], // Initialize guardians as an empty array
        bank_details: {
            bank_name: '',
            account_number: '',
            ifsc_code: ''
        }, // Bank Details
        basic_salary: '',
        provident_fund: '',
        esi: '',
        transport_allowance: '',
        currencyCode: 'INR',
        checkin_time: '',
        checkout_time: '',
        before_checkin_time: '',
        after_checkin_time: '',
        before_checkout_time: '',
        after_checkout_time: '',
        aadhar_file: '', 
        pan_file: '', 
        passport_photo: '', 
        bank_proof: '',
        salary_slips: '',
        aadhar_file_old: '', 
        pan_file_old: '', 
        passport_photo_old: '', 
        bank_proof_old: '',
        salary_slips_old: '',
        pan_number: '',
        aadhare_number: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const orderBy = "id DESC";

                const country_idorder = "country_id DESC";

                const city_idorder = "city_id DESC";

                const state_idorder = "state_id DESC";

                // Fetch all countries
                const Conditionsemployees = { "userid": employeeId };
                const emaployeData110 = await oneclick.read(tableSchema, employees, Conditionsemployees, orderBy, "");
                const emaployeData11 = emaployeData110.data;

                // Transform the fetched data to match the initial state structure
                if (emaployeData11) {
                    const aadharFile11 = await oneclick.read(tableSchema, "files", { 'id': emaployeData11[0].aadhar_file }, "", "");
                    const aadharFile = aadharFile11.data;
                    const panFile11 = await oneclick.read(tableSchema, "files", { 'id': emaployeData11[0].pan_file }, "", "");
                    const panFile = panFile11.data;
                    const passportPhoto11 = await oneclick.read(tableSchema, "files", { 'id': emaployeData11[0].passport_photo }, "", "");
                    const passportPhoto = passportPhoto11.data;
                    const bankProof11 = await oneclick.read(tableSchema, "files", { 'id': emaployeData11[0].bank_proof }, "", "");
                    const bankProof = bankProof11.data;
                    const salarySlips11 = await oneclick.read(tableSchema, "files", { 'id': emaployeData11[0].salary_slips }, "", "");
                    const salarySlips = salarySlips11.data;
                    setEmployeeData({
                        fullName: emaployeData11[0].full_name || '',
                        email: emaployeData11[0].email || '',
                        phone_number: emaployeData11[0].phone_number || '',
                        role_id: emaployeData11[0].role_id || '',
                        branch_id: emaployeData11[0].branch_id || '',
                        address: {
                            country: emaployeData11[0].address_country || '',
                            city: emaployeData11[0].address_city || '',
                            state: emaployeData11[0].address_state || '',
                            zip: emaployeData11[0].address_zip || ''
                        },
                        guardians: emaployeData11[0]?.guardians
                            ? (typeof emaployeData11[0].guardians === "string"
                                ? JSON.parse(emaployeData11[0].guardians)
                                : emaployeData11[0].guardians)
                            : [],
                        bank_details: {
                            bank_name: emaployeData11[0].bank_name || '',
                            account_number: emaployeData11[0].account_number || '',
                            ifsc_code: emaployeData11[0].ifsc_code || ''
                        },
                        basic_salary: emaployeData11[0].basic_salary || '',
                        provident_fund: emaployeData11[0].provident_fund || '',
                        esi: emaployeData11[0].esi || '',
                        transport_allowance: emaployeData11[0].transport_allowance || '',
                        currencyCode: emaployeData11[0].currency_code || 'INR',
                        checkin_time: emaployeData11[0].checkin_time || '',
                        checkout_time: emaployeData11[0].checkout_time || '',
                        before_checkin_time: emaployeData11[0].before_checkin_time || '',
                        after_checkin_time: emaployeData11[0].after_checkin_time || '',
                        before_checkout_time: emaployeData11[0].before_checkout_time || '',
                        after_checkout_time: emaployeData11[0].after_checkout_time || '',
                        aadhare_number: emaployeData11[0].aadhare_number || '',
                        pan_number: emaployeData11[0].pan_number || '',
                        aadhar_file_old: aadharFile && aadharFile.length > 0 && aadharFile[0]?.fileName 
                            ? `uploads/3/company/emaploye/images/${aadharFile[0].fileName}` 
                            : '',
                        pan_file_old: panFile && panFile.length > 0 && panFile[0]?.fileName 
                            ? `uploads/3/company/emaploye/images/${panFile[0].fileName}` 
                            : '',
                        passport_photo_old: passportPhoto && passportPhoto.length > 0 && passportPhoto[0]?.fileName 
                            ? `uploads/3/company/emaploye/images/${passportPhoto[0].fileName}` 
                            : '',
                        bank_proof_old: bankProof && bankProof.length > 0 && bankProof[0]?.fileName 
                            ? `uploads/3/company/emaploye/images/${bankProof[0].fileName}` 
                            : '',
                        salary_slips_old: salarySlips && salarySlips.length > 0 && salarySlips[0]?.fileName 
                            ? `uploads/3/company/emaploye/images/${salarySlips[0].fileName}` 
                            : '',

                        aadhar_file: emaployeData11[0].aadhar_file || '',  // Using string interpolation to construct the file URL
                        pan_file: emaployeData11[0].pan_file || '',
                        passport_photo: emaployeData11[0].passport_photo || '',
                        bank_proof: emaployeData11[0].bank_proof || '',
                        salary_slips: emaployeData11[0].salary_slips || ''
                    });

                    setSelectedBranchId(emaployeData11[0].branch_id || '');
                    setSelectedCountryId(emaployeData11[0].address_country || '');
                    setSelectedStateId(emaployeData11[0].address_state || '');
                    setSelectedCityId(emaployeData11[0].address_city || '');
                    setSelectedRoleId(emaployeData11[0].role_id || '');
                    //Set check-in and check-out visibility based on the fetched data
                    if (
                        emaployeData11.length > 0 &&
                        (emaployeData11[0].before_checkin_time || emaployeData11[0].after_checkin_time)
                    ) {
                        setShowCheckinDetails(true);
                    }

                    if (
                        emaployeData11.length > 0 &&
                        (emaployeData11[0].before_checkout_time || emaployeData11[0].after_checkout_time)
                    ) {
                        setShowCheckoutDetails(true);
                    }
                }

                console.log('emaployeData :', emaployeData11);

                // Fetch all countries
                const countriesData = await oneclick.read(tableSchema, countries, "", country_idorder, "");
                setAllCountries(countriesData.data);

                // Fetch branches based on company ID
                const Conditionsbranches = { "company_id": companyid };
                const AllBrancheslist = await oneclick.read(tableSchema, branches, Conditionsbranches, orderBy, "");
                setAllBranchs(AllBrancheslist.data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Add selectedBranchId to dependencies

    // Fetch roles when branch is selected
    useEffect(() => {
        if (selectedBranchId) {
            const fetchRoles = async () => {
                const Conditionsroles = { "branch_id": selectedBranchId };
                const rolesData = await oneclick.read(tableSchema, roles, Conditionsroles, "id DESC", "");
                setAllroles(rolesData.data);
            };
            fetchRoles();
        }
    }, [selectedBranchId]); // Runs only when selectedBranchId changes

    // Fetch states when country is selected
    useEffect(() => {
        if (selectedCountryId) {
            const fetchStates = async () => {
                const statesConditions = { "country_id": selectedCountryId };
                const statesData = await oneclick.read(tableSchema, states, statesConditions, "state_id DESC", "");
                setAllStates(statesData.data);
            };
            fetchStates();
        }
    }, [selectedCountryId]); // Runs only when selectedCountryId changes

    // Fetch cities when state is selected
    useEffect(() => {
        if (selectedStateId) {
            const fetchCities = async () => {
                const citiesConditions = { "state_id": selectedStateId };
                const citiesData = await oneclick.read(tableSchema, cities, citiesConditions, "city_id DESC", "");
                setAllCities(citiesData.data);
            };
            fetchCities();
        }
    }, [selectedStateId]); // Runs only when selectedStateId changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Handle nested state updates for address and bank details
        if (name.includes('address.')) {
            const key = name.split('.')[1]; // Get the nested key (city, state, zip)
            setEmployeeData((prevData) => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    [key]: value
                }
            }));
        } else if (name.includes('bank_details.')) {
            const key = name.split('.')[1]; // Get the nested key (bank_name, account_number, ifsc_code)
            setEmployeeData((prevData) => ({
                ...prevData,
                bank_details: {
                    ...prevData.bank_details,
                    [key]: value
                }
            }));
        } else {
            setEmployeeData({
                ...employeeData,
                [name]: value
            });
        }
    };


    // Handler for Branch Select
    const handleBranchChange = async (e) => {
        const branchId = e.target.value;
        setSelectedBranchId(branchId);
        setAllroles([]); // Clear roles when branch changes
        setEmployeeData({
            ...employeeData,
            branch_id: branchId
        });

        if (branchId) {
            const orderBy = "id DESC";
            const Conditionsroles = { "branch_id": branchId };
            const rolesData = await oneclick.read(tableSchema, "roles", Conditionsroles, orderBy, "");
            setAllroles(rolesData.data);
        }
    };

    // Handler for Country Select
    const handleCountryChange = async (e) => {
        const countryId = e.target.value;
        setSelectedCountryId(countryId);
        setAllStates([]); // Clear states when country changes

        if (countryId) {
            const statesConditions = { "country_id": countryId };
            const statesData = await oneclick.read(tableSchema, "states", statesConditions, "state_id DESC", "");
            setAllStates(statesData.data);
        }
    };

    // Handler for State Select
    const handleStateChange = async (e) => {
        const stateId = e.target.value;
        setSelectedStateId(stateId);
        setAllCities([]); // Clear cities when state changes

        if (stateId) {
            const citiesConditions = { "state_id": stateId };
            const citiesData = await oneclick.read(tableSchema, "cities", citiesConditions, "city_id DESC", "");
            setAllCities(citiesData.data);
        }
    };

    // Handle button clicks to toggle visibility of additional fields
    const handleCheckinToggle = () => {
        setShowCheckinDetails(prev => !prev);
    };

    const handleCheckoutToggle = () => {
        setShowCheckoutDetails(prev => !prev);
    };


    const handleGuardianChange = (index, e) => {
        const { name, value } = e.target;
        const guardians = [...employeeData.guardians];
        guardians[index] = {
            ...guardians[index],
            [name]: value
        };
        setEmployeeData({ ...employeeData, guardians });
    };

    const addGuardian = () => {
        setEmployeeData((prevData) => ({
            ...prevData,
            guardians: [...prevData.guardians, { name: '', phone_number: '', relation: '' }]
        }));
    };

    const removeGuardian = (index) => {
        const guardians = [...employeeData.guardians];
        guardians.splice(index, 1);
        setEmployeeData({ ...employeeData, guardians });
    };

    // Handle file change for multiple inputs
    const handleFileChange = (event, fileType) => {
        const file = event.target.files[0];
        setFiles(prevFiles => ({
            ...prevFiles,
            [fileType]: file
        }));
        console.log(`${fileType} selected:`, file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        for (const guardian of employeeData.guardians) {
            if (!guardian.name || !guardian.phone_number || !guardian.relation) {
                alert('All guardian fields must be filled out!');
                return;
            }
            if (guardian.phone_number.length < 10 || guardian.phone_number.length > 15) {
                alert('Phone number must be between 10 to 15 digits long.');
                return;
            }
            if (!/^\d+$/.test(guardian.phone_number)) {
                alert('Phone number must contain only digits.');
                return;
            }
        }

        try {
            const token = localStorage.getItem('token'); // Retrieve token from localStorage
        
            // Get the logged-in user ID from the token
            const ConditionsUsers = { "login_tockin": token };
            const emaployeData11 = await oneclick.read(tableSchema, users, ConditionsUsers, "", "");
            const user_id = emaployeData11.data[0].id;
        
            // Define file upload directory root
            const root = "company/emaploye/images";
        
            // Initialize variables for uploaded file paths
            let uploadaadhar_file = null;
            let uploadpan_file = null;
            let uploadpassport_photo = null;
            let uploadbank_proof = null;
            let uploadsalary_slips = null;
        
            // Check if each file exists and upload it
            if (files.aadhar_file) {
                uploadaadhar_file = await OneKlicksFileSDK.uploadFile(user_id, root, files.aadhar_file);
            }
        
            if (files.pan_file) {
                uploadpan_file = await OneKlicksFileSDK.uploadFile(user_id, root, files.pan_file);
            }
        
            if (files.passport_photo) {
                uploadpassport_photo = await OneKlicksFileSDK.uploadFile(user_id, root, files.passport_photo);
            }
        
            if (files.bank_proof) {
                uploadbank_proof = await OneKlicksFileSDK.uploadFile(user_id, root, files.bank_proof);
            }
        
            if (files.salary_slips) {
                uploadsalary_slips = await OneKlicksFileSDK.uploadFile(user_id, root, files.salary_slips);
            }
        
            console.log('File Upload Results:', {
                aadhar: uploadaadhar_file ? uploadaadhar_file.file.id : 'No Aadhar file uploaded',
                pan: uploadpan_file ? uploadpan_file.file.id : 'No Pan file uploaded',
                passport: uploadpassport_photo ? uploadpassport_photo.file.id : 'No Passport photo uploaded',
                bank: uploadbank_proof ? uploadbank_proof.file.id : 'No Bank proof uploaded',
                salary: uploadsalary_slips ? uploadsalary_slips.file.id : 'No Salary slips uploaded'
            });
        
            // Create employee data payload
            const employeesupdatedata = {
                full_name: employeeData.fullName,
                email: employeeData.email,
                phone_number: employeeData.phoneNumber,
                company_id: employeeData.companyId,
                role_id: employeeData.roleId,
                branch_id: selectedBranchId,
                address_country: selectedCountryId,
                address_city: employeeData.address.city,
                address_state: selectedStateId,
                address_zip: employeeData.address.zip,
                guardians: JSON.stringify(employeeData.guardians),
                bank_name: employeeData.bank_details.bank_name,
                account_number: employeeData.bank_details.account_number,
                ifsc_code: employeeData.bank_details.ifsc_code,
                basic_salary: employeeData.basic_salary,
                provident_fund: employeeData.provident_fund,
                esi: employeeData.esi,
                transport_allowance: employeeData.transport_allowance,
                currency_code: employeeData.currencyCode,
                checkin_time: employeeData.checkin_time,
                checkout_time: employeeData.checkout_time,
                before_checkin_time: employeeData.before_checkin_time,
                after_checkin_time: employeeData.after_checkin_time,
                before_checkout_time: employeeData.before_checkout_time,
                after_checkout_time: employeeData.after_checkout_time,
                // Include uploaded file paths, check if files were uploaded, otherwise use existing paths
                aadhar_file: uploadaadhar_file ? uploadaadhar_file.file.id : employeeData.aadhar_file,
                pan_file: uploadpan_file ? uploadpan_file.file.id : employeeData.pan_file,
                passport_photo: uploadpassport_photo ? uploadpassport_photo.file.id : employeeData.passport_photo,
                bank_proof: uploadbank_proof ? uploadbank_proof.file.id : employeeData.bank_proof,
                salary_slips: uploadsalary_slips ? uploadsalary_slips.file.id : employeeData.salary_slips,
                aadhare_number: employeeData.aadhare_number,
                pan_number: employeeData.pan_number
            };
        
            const Conditionsemployeesupdate = { "userid": employeeId };
        
            // Call API to update employee details
            const response = await oneclick.update(tableSchema, employees, employeesupdatedata, Conditionsemployeesupdate);
        
            if (response.success) {
                alert('Employee details updated successfully');
                navigate('/hrm&management/staff'); // Navigate back to staff list after success
            } else {
                alert('Error updating employee details');
            }
        
        } catch (error) {
            console.error('Error updating employee details:', error);
        }        
    };

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
        setShowModal(true);
    };



    return (
        <div className="dashboard_container">
            <div className="inner-content">
                <section>
                    <div className="row g-4">
                        <div className="col">
                            <div className="card">
                                <div className="card-header mb-20">
                                    <h4 className="card-title">Update Employee Details</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        {/* Personal Details Section */}
                                        <h5>Personal Details</h5>
                                        <div className="form-wrapper">
                                            <div className="row g-4">
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="fullName" className="form-label">Full Name <sup className="text--danger">*</sup></label>
                                                        <input type="text" name="fullName" id="fullName" className="form-control" placeholder="Enter Full Name" required value={employeeData.fullName} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="email" className="form-label">Email <sup className="text--danger">*</sup></label>
                                                        <input type="email" name="email" id="email" className="form-control" placeholder="Enter Email" required value={employeeData.email} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="phone_number" className="form-label">Phone Number <sup className="text--danger">*</sup></label>
                                                        <input type="tel" name="phone_number" id="phone_number" className="form-control" placeholder="Enter Phone Number" required value={employeeData.phone_number} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="branch_id" className="form-label">
                                                            Branch <sup className="text--danger">*</sup>
                                                        </label>
                                                        <select
                                                            name="branch_id"
                                                            id="branch_id"
                                                            className="form-select"
                                                            value={employeeData.branch_id}
                                                            onChange={(e) => {
                                                                handleBranchChange(e); // Call the branch change handler
                                                                handleChange(e); // Call the other change handler
                                                            }} // Wrapper function to call both handlers
                                                        >
                                                            <option value="">Select Branch</option>
                                                            {allBranchs.map(branch => (
                                                                <option key={branch.id} value={branch.id}>
                                                                    {branch.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="role_id" className="form-label">
                                                            Role <sup className="text--danger">*</sup>
                                                        </label>
                                                        <select
                                                            name="role_id"
                                                            id="role_id"
                                                            className="form-select"
                                                            value={employeeData.role_id || ""} // Ensure it defaults to empty if undefined
                                                            onChange={handleChange} // Generic change handler for employee data
                                                            disabled={!selectedBranchId}
                                                        >
                                                            <option value="">Select Role</option>
                                                            {allroles.map(role => (
                                                                <option key={role.id} value={role.id}>
                                                                    {role.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>


                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="address.country" className="form-label">
                                                            Country <sup className="text--danger">*</sup>
                                                        </label>
                                                        <select
                                                            name="address.country"
                                                            id="address.country"
                                                            className="form-control"
                                                            value={employeeData.address.country || ""} // Ensure it defaults to empty if undefined
                                                            onChange={(e) => {
                                                                handleCountryChange(e); // Call the branch change handler
                                                                handleChange(e); // Call the other change handler
                                                            }} // Wrapper function to call both handlers
                                                        >
                                                            <option value="">Select Country</option>
                                                            {allCountries.map(country => (
                                                                <option key={country.country_id} value={country.country_id}>
                                                                    {country.country_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="address.state" className="form-label">
                                                            State <sup className="text--danger">*</sup>
                                                        </label>
                                                        <select
                                                            name="address.state"
                                                            id="address.state"
                                                            className="form-control"
                                                            value={employeeData.address.state || ""} // Ensure it defaults to empty if undefined
                                                            onChange={(e) => {
                                                                handleStateChange(e); // Call the branch change handler
                                                                handleChange(e); // Call the other change handler
                                                            }} // Wrapper function to call both handlers
                                                            disabled={!selectedCountryId}
                                                        >
                                                            <option value="">Select State</option>
                                                            {allStates.map(state => (
                                                                <option key={state.state_id} value={state.state_id}>
                                                                    {state.state_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="address.city" className="form-label">
                                                            City <sup className="text--danger">*</sup>
                                                        </label>
                                                        <select
                                                            name="address.city"
                                                            id="address.city"
                                                            className="form-control"
                                                            value={employeeData.address.city || ""} // Ensure it defaults to empty if undefined
                                                            onChange={(e) => {
                                                                handleChange(e); // Call the other change handler
                                                            }} // Wrapper function to call both handlers
                                                            disabled={!selectedStateId}
                                                        >
                                                            <option value="">Select City</option>
                                                            {allCities.map(city => (
                                                                <option key={city.city_id} value={city.city_id}>
                                                                    {city.city_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="address.zip" className="form-label">Zip <sup className="text--danger">*</sup></label>
                                                        <input type="text" name="address.zip" id="address.zip" className="form-control" placeholder="Enter Zip" required value={employeeData.address.zip} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="row g-4">
                                                    <div className="col-lg-4">
                                                        <div className="form-item">
                                                            <label htmlFor="aadhare_number" className="form-label">
                                                                Aadhar Number <sup className="text--danger">*</sup>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="aadhare_number"
                                                                id="aadhare_number"
                                                                className="form-control"
                                                                placeholder="Enter Aadhar Number"
                                                                required
                                                                value={employeeData.aadhare_number}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="col-lg-12">
                                                                <div className="form-item">
                                                                    <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="file"
                                                                        name="aadhar_file"
                                                                        id="aadhar_file"
                                                                        className="form-control mt-2"
                                                                        required={!employeeData.aadhar_file_old} // Required if aadhar_file is empty
                                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                                        onChange={(e) => handleFileChange(e, 'aadhar_file')}
                                                                    />
                                                                    
                                                                    {/* Right side preview of the existing file */}
                                                                    {employeeData.aadhar_file_old && (
                                                                        <div className="file-preview ms-3">
                                                                            {isImageFile(employeeData.aadhar_file_old) ? (
                                                                                <img
                                                                                    src={`${imageBaseUrl}${employeeData.aadhar_file_old}`}  // Use string interpolation for the URL
                                                                                    alt="Aadhar File Preview"
                                                                                    style={{ width: '100px', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                                                                    onClick={() => handleImageClick(`${imageBaseUrl}${employeeData.aadhar_file_old}`)}  // Open modal on image click
                                                                                />
                                                                            ) : (
                                                                                <a
                                                                                    href={`${imageBaseUrl}${employeeData.aadhar_file_old}`}  // Use the full URL for non-image files too
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className="btn btn-link"
                                                                                >
                                                                                    View Existing File
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="form-item">
                                                            <label htmlFor="pan_number" className="form-label">
                                                                PAN Number <sup className="text--danger">*</sup>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="pan_number"
                                                                id="pan_number"
                                                                className="form-control"
                                                                placeholder="Enter PAN Number"
                                                                value={employeeData.pan_number}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="col-lg-12">
                                                                <div className="form-item">
                                                                    <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="file"
                                                                        name="pan_file"
                                                                        id="pan_file"
                                                                        required={!employeeData.pan_file_old} // Required if aadhar_file is empty
                                                                        className="form-control mt-2"
                                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                                        onChange={(e) => handleFileChange(e, 'pan_file')}
                                                                    />
                                                                    
                                                                    {/* Right side preview of the existing file */}
                                                                    {employeeData.pan_file_old && (
                                                                        <div className="file-preview ms-3">
                                                                            {isImageFile(employeeData.pan_file_old) ? (
                                                                                <img
                                                                                    src={`${imageBaseUrl}${employeeData.pan_file_old}`}  // Use string interpolation for the URL
                                                                                    alt="Aadhar File Preview"
                                                                                    style={{ width: '100px', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                                                                    onClick={() => handleImageClick(`${imageBaseUrl}${employeeData.pan_file_old}`)}  // Open modal on image click
                                                                                />
                                                                            ) : (
                                                                                <a
                                                                                    href={`${imageBaseUrl}${employeeData.pan_file_old}`}  // Use the full URL for non-image files too
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className="btn btn-link"
                                                                                >
                                                                                    View Existing File
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                                </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="form-item">
                                                            <label htmlFor="passport_photo" className="form-label">
                                                                Passport Size Photo <sup className="text--danger">*</sup>
                                                            </label>
                                                            
                                                            <div className="col-lg-12">
                                                                <div className="form-item">
                                                                    <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="file"
                                                                        name="passport_photo"
                                                                        id="passport_photo"
                                                                        className="form-control"
                                                                        accept="image/*"
                                                                        required={!employeeData.passport_photo_old} // Required if aadhar_file is empty
                                                                        onChange={(e) => handleFileChange(e, 'passport_photo')}
                                                                    />
                                                                    
                                                                    {/* Right side preview of the existing file */}
                                                                    {employeeData.passport_photo_old && (
                                                                        <div className="file-preview ms-3">
                                                                            {isImageFile(employeeData.passport_photo_old) ? (
                                                                                <img
                                                                                    src={`${imageBaseUrl}${employeeData.passport_photo_old}`}  // Use string interpolation for the URL
                                                                                    alt="Aadhar File Preview"
                                                                                    style={{ width: '100px', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                                                                    onClick={() => handleImageClick(`${imageBaseUrl}${employeeData.passport_photo_old}`)}  // Open modal on image click
                                                                                />
                                                                            ) : (
                                                                                <a
                                                                                    href={`${imageBaseUrl}${employeeData.passport_photo_old}`}  // Use the full URL for non-image files too
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className="btn btn-link"
                                                                                >
                                                                                    View Existing File
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {/* Guardian Details Section */}
                                        <h5 className="mt-4">Guardian Details</h5>
                                        {employeeData.guardians.map((guardian, index) => (
                                            <div key={index} className="form-wrapper mb-3">
                                                <div className="row g-4">
                                                    <div className="col-lg-4">
                                                        <div className="form-item">
                                                            <label htmlFor={`guardian.name.${index}`} className="form-label">Guardian Name <sup className="text--danger">*</sup></label>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                id={`guardian.name.${index}`}
                                                                className="form-control"
                                                                placeholder="Enter Guardian Name"
                                                                required
                                                                value={guardian.name}
                                                                onChange={(e) => handleGuardianChange(index, e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-item">
                                                            <label htmlFor={`guardian.phone_number.${index}`} className="form-label">Guardian Phone <sup className="text--danger">*</sup></label>
                                                            <input
                                                                type="tel"
                                                                name="phone_number"
                                                                id={`guardian.phone_number.${index}`}
                                                                className="form-control"
                                                                placeholder="Enter Guardian Phone"
                                                                required
                                                                value={guardian.phone_number}
                                                                onChange={(e) => handleGuardianChange(index, e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-item">
                                                            <label htmlFor={`guardian.relation.${index}`} className="form-label">Guardian Relation <sup className="text--danger">*</sup></label>
                                                            <input
                                                                type="text"
                                                                name="relation"
                                                                id={`guardian.relation.${index}`}
                                                                className="form-control"
                                                                placeholder="Enter Guardian Relation"
                                                                required
                                                                value={guardian.relation}
                                                                onChange={(e) => handleGuardianChange(index, e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-end">
                                                        <button type="button" className="btn btn-danger" onClick={() => removeGuardian(index)}>Remove Guardian</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <button type="button" className="btn btn-secondary" onClick={addGuardian}>Add Guardian</button>

                                        {/* Salary Details Section */}
                                        <h5 className="mt-4">Salary Details</h5>
                                        <div className="form-wrapper">
                                            <div className="row g-4">
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="bank_details.bank_name" className="form-label">Bank Name <sup className="text--danger">*</sup></label>
                                                        <input type="text" name="bank_details.bank_name" id="bank_details.bank_name" className="form-control" placeholder="Enter Bank Name" required value={employeeData.bank_details.bank_name} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="bank_details.account_number" className="form-label">Account Number <sup className="text--danger">*</sup></label>
                                                        <input type="text" name="bank_details.account_number" id="bank_details.account_number" className="form-control" placeholder="Enter Account Number" required value={employeeData.bank_details.account_number} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="bank_details.ifsc_code" className="form-label">IFSC Code <sup className="text--danger">*</sup></label>
                                                        <input type="text" name="bank_details.ifsc_code" id="bank_details.ifsc_code" className="form-control" placeholder="Enter IFSC Code" required value={employeeData.bank_details.ifsc_code} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="basic_salary" className="form-label">Basic Salary <sup className="text--danger">*</sup></label>
                                                        <input type="number" name="basic_salary" id="basic_salary" className="form-control" placeholder="Enter Basic Salary" required value={employeeData.basic_salary} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="provident_fund" className="form-label">Provident Fund <sup className="text--danger">*</sup></label>
                                                        <input type="number" name="provident_fund" id="provident_fund" className="form-control" placeholder="Enter Provident Fund" required value={employeeData.provident_fund} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="esi" className="form-label">ESI <sup className="text--danger">*</sup></label>
                                                        <input type="number" name="esi" id="esi" className="form-control" placeholder="Enter ESI" required value={employeeData.esi} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="transport_allowance" className="form-label">Transport Allowance <sup className="text--danger">*</sup></label>
                                                        <input type="number" name="transport_allowance" id="transport_allowance" className="form-control" placeholder="Enter Transport Allowance" required value={employeeData.transport_allowance} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="currencyCode" className="form-label">Currency Code <sup className="text--danger">*</sup></label>
                                                        <input type="text" name="currencyCode" id="currencyCode" className="form-control" value={employeeData.currencyCode} readOnly />
                                                    </div>
                                                </div>
                                                {/* Bank Proof Upload */}
                                                <div className="col-lg-4">
                                                    <div className="form-item">
                                                        <label htmlFor="bank_proof" className="form-label">
                                                            Bank Proof
                                                        </label>
                                                            <div className="col-lg-12">
                                                                <div className="form-item">
                                                                    <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="file"
                                                                        name="bank_proof"
                                                                        id="bank_proof"
                                                                        className="form-control"
                                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                                        required={!employeeData.bank_proof_old} // Required if aadhar_file is empty
                                                                        multiple // Allow multiple file uploads
                                                                        onChange={(e) => handleFileChange(e, 'bank_proof')}
                                                                    />
                                                                    
                                                                    {/* Right side preview of the existing file */}
                                                                    {employeeData.bank_proof_old && (
                                                                        <div className="file-preview ms-3">
                                                                            {isImageFile(employeeData.bank_proof_old) ? (
                                                                                <img
                                                                                    src={`${imageBaseUrl}${employeeData.bank_proof_old}`}  // Use string interpolation for the URL
                                                                                    alt="Aadhar File Preview"
                                                                                    style={{ width: '100px', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                                                                    onClick={() => handleImageClick(`${imageBaseUrl}${employeeData.bank_proof_old}`)}  // Open modal on image click
                                                                                />
                                                                            ) : (
                                                                                <a
                                                                                    href={`${imageBaseUrl}${employeeData.bank_proof_old}`}  // Use the full URL for non-image files too
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className="btn btn-link"
                                                                                >
                                                                                    View Existing File
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                {/* Last 3 Months Salary Slip Upload */}
                                                <div className="col-lg-4">
                                                    <div className="form-item">
                                                        <label htmlFor="salary_slips" className="form-label">
                                                            Last 3 Months Salary Slips
                                                        </label>
                                                        <div className="col-lg-12">
                                                                <div className="form-item">
                                                                    <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="file"
                                                                        name="salary_slips"
                                                                        id="salary_slips"
                                                                        className="form-control"
                                                                        required={!employeeData.salary_slips_old} // Required if aadhar_file is empty
                                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                                        multiple // Allow multiple file uploads
                                                                        onChange={(e) => handleFileChange(e, 'salary_slips')}
                                                                    />
                                                                    
                                                                    {/* Right side preview of the existing file */}
                                                                    {employeeData.salary_slips_old && (
                                                                        <div className="file-preview ms-3">
                                                                            {isImageFile(employeeData.salary_slips_old) ? (
                                                                                <img
                                                                                    src={`${imageBaseUrl}${employeeData.salary_slips_old}`}  // Use string interpolation for the URL
                                                                                    alt="Aadhar File Preview"
                                                                                    style={{ width: '100px', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                                                                                    onClick={() => handleImageClick(`${imageBaseUrl}${employeeData.salary_slips_old}`)}  // Open modal on image click
                                                                                />
                                                                            ) : (
                                                                                <a
                                                                                    href={`${imageBaseUrl}${employeeData.salary_slips_old}`}  // Use the full URL for non-image files too
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className="btn btn-link"
                                                                                >
                                                                                    View Existing File
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Salary Details Section */}
                                        <h5 className="mt-4">Login Details</h5>
                                        <div className="form-wrapper">
                                            <div className="row g-4">
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="checkin_time" className="form-label">
                                                            Check-in Time <sup className="text--danger">*</sup>
                                                        </label>
                                                        <div className='row col-lg-12'>
                                                            <div className='col-lg-9'>
                                                                <input
                                                                    type="time"
                                                                    name="checkin_time"
                                                                    id="checkin_time"
                                                                    className="form-control col-lg-10"
                                                                    required
                                                                    value={employeeData.checkin_time}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            <button type="button" className="col-lg-3 btn btn-primary" onClick={handleCheckinToggle}>
                                                                Advonce
                                                            </button>
                                                        </div>
                                                        {showCheckinDetails && (
                                                            <div className="row g-4">
                                                                <div className="col-lg-6">
                                                                    <div className="form-item">
                                                                        <label htmlFor="before_checkin_time" className="form-label">
                                                                            Before Check-in Time
                                                                        </label>
                                                                        <input
                                                                            type="time"
                                                                            name="before_checkin_time"
                                                                            id="before_checkin_time"
                                                                            className="form-control"
                                                                            value={employeeData.before_checkin_time}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="form-item">
                                                                        <label htmlFor="after_checkin_time" className="form-label">
                                                                            After Check-in Time
                                                                        </label>
                                                                        <input
                                                                            type="time"
                                                                            name="after_checkin_time"
                                                                            id="after_checkin_time"
                                                                            className="form-control"
                                                                            value={employeeData.after_checkin_time}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-item">
                                                        <label htmlFor="checkout_time" className="form-label">
                                                            Check-out Time <sup className="text--danger">*</sup>
                                                        </label>
                                                        <div className='row col-lg-12'>
                                                            <div className='col-lg-9'>
                                                                <input
                                                                    type="time"
                                                                    name="checkout_time"
                                                                    id="checkout_time"
                                                                    className="form-control col-lg-10"
                                                                    required
                                                                    value={employeeData.checkout_time}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            <button type="button" className="col-lg-3 btn btn-primary" onClick={handleCheckoutToggle}>
                                                                Advonce
                                                            </button>
                                                        </div>
                                                        {showCheckoutDetails && (
                                                            <div className="row g-4">
                                                                <div className="col-lg-6">
                                                                    <div className="form-item">
                                                                        <label htmlFor="before_checkout_time" className="form-label">
                                                                            Before Check-out Time
                                                                        </label>
                                                                        <input
                                                                            type="time"
                                                                            name="before_checkout_time"
                                                                            id="before_checkout_time"
                                                                            className="form-control"
                                                                            value={employeeData.before_checkout_time}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="form-item">
                                                                        <label htmlFor="after_checkout_time" className="form-label">
                                                                            After Check-out Time
                                                                        </label>
                                                                        <input
                                                                            type="time"
                                                                            name="after_checkout_time"
                                                                            id="after_checkout_time"
                                                                            className="form-control"
                                                                            value={employeeData.after_checkout_time}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Update Employee</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Modal for showing the full image */}
                {showModal && (
                    <div className="modal show d-block" tabIndex="-1" onClick={() => setShowModal(false)} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">File Preview</h5>
                                    <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                                </div>
                                <div className="modal-body text-center">
                                    <img src={modalImage} alt="Aadhar File" style={{ width: '100%', height: 'auto' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmployeeEditForm;
