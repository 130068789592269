import React, { useState } from 'react';

const SmsForm = () => {
    const [audienceType, setAudienceType] = useState('single');
    const [number, setNumber] = useState('');
    const [group, setGroup] = useState([]);
    const [file, setFile] = useState(null);
    const [androidGateway, setAndroidGateway] = useState(-1);
    const [scheduleDate, setScheduleDate] = useState('');
    const [smsType, setSmsType] = useState('plain');
    const [message, setMessage] = useState('');
    const [template, setTemplate] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log({
            audienceType,
            number,
            group,
            file,
            androidGateway,
            scheduleDate,
            smsType,
            message,
            template,
        });
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Send SMS</h4>
                        <div
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            data-bs-title="Suggestions Note"
                            title=""
                        >
                            <button className="i-btn info--btn btn--sm d-xl-none info-note-btn">
                                <i className="las la-info-circle"></i>
                            </button>
                        </div>
                    </div>

                    <div className="card-body position-relative">
                        <form onSubmit={handleFormSubmit}>
                            <input type="hidden" name="channel" value="sms" />
                            <div className="row g-4">
                                <div className="col-xl-9 order-xl-1 order-2">
                                    <div className="form-wrapper">
                                        <h6 className="form-wrapper-title">Set Target Audience</h6>
                                        <div className="file-tab">
                                            <ul
                                                className="nav nav-tabs mb-3 gap-2"
                                                id="myTabContent"
                                                role="tablist"
                                            >
                                                <li className="nav-item single-audience" role="presentation">
                                                    <button
                                                        className={`nav-link ${audienceType === 'single' ? 'active' : ''}`}
                                                        onClick={() => setAudienceType('single')}
                                                        type="button"
                                                    >
                                                        <i className="las la-user"></i> Single Audience
                                                    </button>
                                                </li>
                                                <li className="nav-item group-audience" role="presentation">
                                                    <button
                                                        className={`nav-link ${audienceType === 'group' ? 'active' : ''}`}
                                                        onClick={() => setAudienceType('group')}
                                                        type="button"
                                                    >
                                                        <i className="las la-users"></i> Group Audience
                                                    </button>
                                                </li>
                                                <li className="nav-item import-file" role="presentation">
                                                    <button
                                                        className={`nav-link ${audienceType === 'file' ? 'active' : ''}`}
                                                        onClick={() => setAudienceType('file')}
                                                        type="button"
                                                    >
                                                        <i className="las la-file-import"></i> Import File
                                                    </button>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="myTabContent">
                                                {audienceType === 'single' && (
                                                    <div className="tab-pane fade show active">
                                                        <div className="form-item">
                                                            <label htmlFor="number" className="form-label">
                                                                Single Input
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={number}
                                                                onChange={(e) => setNumber(e.target.value)}
                                                                name="number"
                                                                id="number"
                                                                placeholder="Enter with country code 91XXXXXXXXX"
                                                                aria-label="number"
                                                            />
                                                            <div className="form-text">Put single or search from save file</div>
                                                        </div>
                                                    </div>
                                                )}

                                                {audienceType === 'group' && (
                                                    <div className="tab-pane fade show active">
                                                        <div className="form-item">
                                                            <label className="form-label" htmlFor="group_id">
                                                                From Group
                                                            </label>
                                                            <select
                                                                className="form-select"
                                                                name="group_id[]"
                                                                id="group_id"
                                                                multiple
                                                                value={group}
                                                                onChange={(e) =>
                                                                    setGroup(Array.from(e.target.selectedOptions, option => option.value))
                                                                }
                                                            >
                                                                <option value="" disabled>
                                                                    Select One
                                                                </option>
                                                                <option value="1">Demo Group</option>
                                                            </select>
                                                            <div className="form-text">
                                                                You can select single or multiple groups
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {audienceType === 'file' && (
                                                    <div className="tab-pane fade show active">
                                                        <div className="form-item">
                                                            <label className="form-label" htmlFor="file">
                                                                Import File
                                                                <span id="contact_file_name"></span>
                                                            </label>
                                                            <div className="upload-filed">
                                                                <input
                                                                    type="file"
                                                                    name="file"
                                                                    id="file"
                                                                    onChange={(e) => setFile(e.target.files[0])}
                                                                />
                                                                <label htmlFor="file">
                                                                    <div className="d-flex align-items-center gap-3">
                                                                        <span className="upload-drop-file">
                                                                            {/* Add SVG or icon here */}
                                                                        </span>
                                                                        <span className="upload-browse">Upload File Here </span>
                                                                    </div>
                                                                </label>
                                                            </div>

                                                            <div className="form-text mt-3">
                                                                Download Sample:
                                                                <a
                                                                    href="http://localhost/getoneclick/demo/file/download/csv/sms"
                                                                    className="badge badge--primary"
                                                                >
                                                                    {/* Add FontAwesome download icon here */}
                                                                    csv
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-wrapper select-android-gateway mt-3">
                                        <h6 className="form-wrapper-title" title="If left unselected then a random sim will be choosen">
                                            Select Android Gateway
                                        </h6>
                                        <div className="android-gateway">
                                            <label htmlFor="android_gateways_id" className="form-label">
                                                Android Gateway
                                            </label>
                                            <select
                                                className="form-select"
                                                name="android_gateways_id"
                                                id="android_gateways_id"
                                                value={androidGateway}
                                                onChange={(e) => setAndroidGateway(e.target.value)}
                                            >
                                                <option value="-1">Automatic</option>
                                                <option value="2">RAVINDRA_MALLE</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-wrapper">
                                        <h6 className="form-wrapper-title">Schedule and message</h6>
                                        <div className="row g-4">
                                            <div className="col-md-6">
                                                <div className="form-item">
                                                    <label className="form-label" htmlFor="smsTypeText">
                                                        Select SMS Type <sup className="text-danger">*</sup>
                                                    </label>
                                                    <div className="radio-buttons-container message-type">
                                                        <div className="radio-button">
                                                            <input
                                                                className="radio-button-input"
                                                                type="radio"
                                                                name="sms_type"
                                                                id="smsTypeText"
                                                                value="plain"
                                                                checked={smsType === 'plain'}
                                                                onChange={() => setSmsType('plain')}
                                                            />
                                                            <label htmlFor="smsTypeText" className="radio-button-label">
                                                                <span className="radio-button-custom"></span>
                                                                Text
                                                            </label>
                                                        </div>

                                                        <div className="radio-button">
                                                            <input
                                                                className="radio-button-input"
                                                                type="radio"
                                                                name="sms_type"
                                                                id="smsTypeUnicode"
                                                                value="unicode"
                                                                checked={smsType === 'unicode'}
                                                                onChange={() => setSmsType('unicode')}
                                                            />
                                                            <label htmlFor="smsTypeUnicode" className="radio-button-label">
                                                                <span className="radio-button-custom"></span>
                                                                Unicode
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-item">
                                                    <label htmlFor="schedule-date" className="form-label">
                                                        Schedule Date
                                                    </label>
                                                    <input
                                                        type="datetime-local"
                                                        value={scheduleDate}
                                                        onChange={(e) => setScheduleDate(e.target.value)}
                                                        name="schedule_date"
                                                        id="schedule-date"
                                                        className="form-control schedule-date"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-item">
                                                    <label className="form-label" htmlFor="message">
                                                        Write Message <sup className="text-danger">*</sup>
                                                    </label>
                                                    <div className="input-group">
                                                        <textarea
                                                            name="message"
                                                            id="message"
                                                            className="form-control"
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            required
                                                            rows="4"
                                                            placeholder="Write Your Message"
                                                        ></textarea>
                                                     </div>
                                                    <button
                                                        className="input-group-text primary--btn text-white border-0"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#template-data"
                                                        type="button"
                                                        style={{ marginLeft: 'auto', marginTop: '12px' }}
                                                        >
                                                        Use Template
                                                        </button>
                                                   </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-wrapper">
                                        <button type="submit" className="i-btn primary--btn btn--lg">
                                            Submit
                                        </button>
                                    </div>
                                </div>

                                <div className="note-container col-xl-3 order-xl-2 order-1 d-xl-block d-none">
                                    <div className="note">
                                        <h6>Suggestions Note</h6>
                                        <div className="note-body">
                                            {/* Add suggestion notes here */}
                                            By selecting the 'Single Audience' input field, you can enter a valid phone number with a country code (For Example: 91 xxxxxxxxxx). In order to send or schedule an SMS, continue filling up the rest of the form.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {/* Modal for SMS template */}
            <div
                className="modal fade"
                id="template-data"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">SMS Template</h5>
                            <button
                                type="button"
                                className="i-btn bg--lite--danger text--danger btn--sm"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="las la-times"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-item">
                                <label htmlFor="template" className="form-label">
                                    Select Template
                                </label>
                                <select
                                    className="form-select"
                                    name="template"
                                    id="template"
                                    value={template}
                                    onChange={(e) => setTemplate(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>
                                        Select One
                                    </option>
                                    <option value="This template can be used when creating a new message for SMS or Whatsapp">
                                        Demo Admin SMS Template
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="i-btn danger--btn btn--md"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmsForm;
