import React, { useEffect, useState } from "react";
import { oneclick } from "../../../oneclick";
import { Link } from "react-router-dom";
import database_id from "../../../utils/databaseConfig";
import { getuserdata } from "../../../services/apiService";

const CloudTableList = ({ searchinput, filter, currentPage, setTotalPages }) => {

    const [qrTableList, setQRTableList] = useState([]);
    const itemsPerPage = 10;

    useEffect(() => {
        getQRTableList();
    }, [searchinput, filter, currentPage]);

    const getQRTableList = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "whatsapp_cloud_device";
            const conditions = {
                company_id: await getuserdata('company_id'),
                ...(filter ? { status: filter } : {}),
            };
            const orderBy = "id DESC";

            const search = searchinput ? { name: searchinput } : {}; // Search condition
            const pagination = {
                page: currentPage,
                limit: 10,
            };
            const margedata = {};
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

            if (response.success) {
                console.log("QRTableList:", response);
                setQRTableList(response.data);
                setTotalPages(response.totalPages);
            } else {
                console.error("Error in getQRTableList:", response.message);
            }
        } catch (error) {
            console.error("Exception in getQRTableList:", error);
        }
    };

    return (
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Business Account Name</th>
                    <th>Templates</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {qrTableList.length === 0 ? (
                    <tr>
                        <td colSpan="7" className="text-center">No data found</td>
                    </tr>
                ) : (
                    qrTableList.map((item, index) => (
                        <tr key={index}>
                            <td data-label="ID" className="">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                            <td data-label="Session Name" className="maxtable">{item.portfolioname}</td>

                            <td data-label="Templates">
                                <Link to="https://xsender.igensolutionsltd.com/admin/template/whatsapp/288" class="badge badge--primary p-2">
                                    <span class="i-badge info-solid pill">
                                        View All (0)  <i class="ri-eye-line ms-1"></i>
                                    </span>
                                </Link>
                            </td>
                            <td data-label="Status">
                                <span className={`badge badge--${item.status === 'Active' ? 'success' : 'danger'}`}>
                                    {item.status}
                                </span>
                            </td>
                            <td data-label="Actions">
                                <div className="button-group" style={{ display: 'flex', gap: '10px' }}>
                                    <button className="i-btn primary--btn btn--md">Edit</button>
                                    <button className="i-btn danger--btn btn--md">Delete</button>
                                </div>
                            </td>
                        </tr>
                    ))
                )}

            </tbody>
        </table>
    );
};

export default CloudTableList;