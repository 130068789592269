import React, { useState, useEffect } from "react";


const GatewayDelete = ({ }) => {

    return (
        <div className="modal fade" id="gatewayDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <form action="http://localhost/getoneclick/admin/sms/gateways/delete" method="GET">
                        <input type="hidden" name="_token" value="gmRCaCzjB6Q13HtAZPOABlD8x06Q0ynqo1nnJ3fh" />
                        <input type="hidden" name="id" value="" />
                        <div className="modal_body2">
                            <div className="modal_icon2">
                                <i className="las la-trash"></i>
                            </div>
                            <div className="modal_text2 mt-4">
                                <h5>Are you sure to delete this gateway</h5>
                            </div>
                        </div>
                        <div className="modal_button2 modal-footer">
                            <div className="d-flex align-items-center justify-content-center gap-3">
                                <button type="button" className="i-btn danger--btn btn--md" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="i-btn primary--btn btn--md">Delete</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default GatewayDelete;