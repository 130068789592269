import React, { useState, useEffect } from 'react';
import CreateAttributeModel from './Components/CreateAttributeModel';
import UpdateAttributeModel from './Components/UpdateAttributeModel';
import DeleteAttributeModel from './Components/DeleteAttributeModel';
import { getuserdata } from '../../services/apiService';
import { oneclick } from '../../oneclick';
import database_id from '../../utils/databaseConfig';

const ContactAttributeList = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [totalContacts, setTotalContacts] = useState(0);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');

  const handleCreateClose = () => setShowCreateModal(false);
  const handleCreateShow = () => setShowCreateModal(true);
  const handleUpdateClose = () => setShowUpdateModal(false);
  const handleUpdateShow = () => setShowUpdateModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);
  const handleDeleteShow = () => setShowDeleteModal(true);

  // Fetch contacts based on search and filter

  const fetchData = async () => {
    console.log('ContactAttributeList fetchData :', statusFilter);
    const tableSchema = database_id;
    const tableName = 'attribute';

    const filterConditions = {
      attribute_name: searchTerm
    };
    let condition
    if (statusFilter === 'all') {
      condition = {
        company_id: await getuserdata('company_id'),
      };
    } else if (statusFilter === 'Active') {
      condition = {
        company_id: await getuserdata('company_id'),
        status: 'Active',
      };
    } else if (statusFilter === 'Inactive') {
      condition = {
        company_id: await getuserdata('company_id'),
        status: 'Inactive',
      };
    }


    const pagination = { page: currentPage, limit: contactsPerPage };

    const data = await oneclick.read(
      tableSchema,
      tableName,
      condition,
      'id DESC',
      filterConditions,
      pagination
    );

    if (statusFilter === 'all') {
      console.log('Conditions DATA :', data);
    } else{
      console.log('filterConditions DATA :', data);
    }

    setContacts(data.data);
    setTotalContacts(data.totalCount);  // Assuming `totalCount` is available from the response
    setFilteredContacts(data.data);
  };

  // Filter contacts based on search and status
  useEffect(() => {
    fetchData();
  }, [searchTerm, statusFilter, currentPage]);

  // Handling search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); 

    setCurrentPage(1); // Reset to first page on search
  };

  // Handling filter change
  const handleFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const attributeTypeMap = {
    1: 'Date',
    2: 'Boolean',
    3: 'Number',
    4: 'Text'
  };

  const [selectedContact, setSelectedContact] = useState(null);

  const handleDeleteAttribute = (data) => {
    setSelectedContact(data);
    setShowDeleteModal(true);
  }
  const handleEditeAttribute = (data) => {
    setSelectedContact(data);
    setShowUpdateModal(true);
  }

  const handleStatusChange = async (contact) => {
    const tableSchema = database_id;
    const tableName = 'attribute';
    const condition = { id: contact.id };

    // Toggle status: If it’s 'Active', set it to 'Inactive', otherwise set it to 'Active'
    const updatedStatus = contact.status === 'Active' ? 'Inactive' : 'Active';

    const data = {
      status: updatedStatus,  // Use the toggled status
    };

    try {
      // Call the API to update the status
      const updatedata = await oneclick.update(tableSchema, tableName, data, condition);


      fetchData();

      // Set the flash message
      setFlashMessage(`Status for ${contact.attribute_name} updated to ${updatedStatus}`);

      // Show the flash message
      setShowFlashMessage(true);

      // Hide the flash message after 3 seconds
      setTimeout(() => setShowFlashMessage(false), 3000);
    } catch (error) {
      console.error("Error updating status:", error);
      setFlashMessage("Error updating status. Please try again.");
      setShowFlashMessage(true);
      setTimeout(() => setShowFlashMessage(false), 3000);
    }
  };


  // Pagination handling
  const totalPages = Math.ceil(totalContacts / contactsPerPage);
  const pageNumbers = [...Array(totalPages).keys()].map(i => i + 1);

  return (
    <div className="dashboard_container">
      <section>
        <div className="card mb-4">
          <div className="card-header">
            <h4 className="card-title">Contact Attribute List</h4>
          </div>

          <div className="card-filter">
            <div className="filter-form">
              <div className="filter-item">
                <select
                  name="status"
                  className="form-select"
                  value={statusFilter}
                  onChange={handleFilterChange}
                >
                  <option value="all">All</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>

              <div className="filter-item">
                <input
                  type="text"
                  autoComplete="off"
                  name="search"
                  placeholder="Search with Attribute Name"
                  className="form-control"
                  id="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>

              <div className="filter-action">
                <button className="i-btn info--btn btn--md" type="button" onClick={fetchData}>
                  <i className="fas fa-search"></i> Search
                </button>
                <button
                  className="i-btn primary--btn btn--md text-white"
                  type="button"
                  onClick={handleCreateShow}
                >
                  <i className="fa fa-plus"></i> Add New
                </button>
              </div>
            </div>
          </div>

          <div className="card-body px-0">
            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Attribute Name</th>
                    <th>Attribute Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredContacts.length == 0 ? (
                    <tr>
                      <td colSpan="5" className="text-center">No contacts found</td>
                    </tr>
                  ) : (
                    filteredContacts.map((contact) => (
                      <tr key={contact.id}>
                        <td data-label="ID">{contact.id}</td>
                        <td data-label="Attribute Name">{contact.attribute_name}</td>
                        <td data-label="Attribute Type">
                          <span className="badge badge--info">
                            {attributeTypeMap[contact.attribute_type] || 'Unknown'}
                          </span>
                        </td>
                        <td className="text-center" data-label="Status">
                          <div className="d-flex justify-content-md-start justify-content-end">
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="attribute_status"
                                checked={contact.status == 'Active'}
                                onClick={() => handleStatusChange(contact)} // Trigger modal
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </td>
                        <td data-label="Action">
                          <div className="d-flex align-items-center gap-3">
                            <button
                              className="i-btn primary--btn btn--sm"
                              onClick={() => { handleEditeAttribute(contact) }}
                            >
                              <i className="las la-pen"></i>
                            </button>
                            <button
                              className="i-btn danger--btn btn--sm"
                              onClick={() => { handleDeleteAttribute(contact) }}
                            >
                              <i className="las la-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Pagination */}
          <div className="pagination-container">
            <ul className="pagination">
              {pageNumbers.map(number => (
                <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => setCurrentPage(number)}>
                    {number}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      {/* Modals */}
      {showCreateModal && <CreateAttributeModel handleCreateClose={handleCreateClose} fetchData={fetchData} />}
      {showUpdateModal && <UpdateAttributeModel handleUpdateClose={handleUpdateClose} data={selectedContact} fetchData={fetchData} />}
      {showDeleteModal && <DeleteAttributeModel handleDeleteClose={handleDeleteClose} data={selectedContact} fetchData={fetchData} />}

      {/* Flash Message */}
      {showFlashMessage && (
        <div className="flash-message">
          {flashMessage}
        </div>
      )}
    </div>
  );
};

export default ContactAttributeList;
