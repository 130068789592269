import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paginationnew from "../../utils/Paginationnew";
import AddNewProjectModal from "./Compontes/AddNewProjuctModel";
import { getuserdata } from "../../services/apiService";
import { oneclick } from "../../oneclick";
import database_id from "../../utils/databaseConfig";
import Tags from "../../utils/Tags";
import ImageComponent from "../../utils/ImageComponent";
import { encodeBase64 } from "../../services/apiService";


const ProjectList = () => {
    const [projects, setProjects] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [status, setStatus] = useState('');
    const [filterDepartment, setFilterDepartment] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(3); // For pagination demo
    const [emailData, setEmailData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [selecydata, setSelectData] = useState([]);
    const [showAddProjuctModal, setShowAddProjuctModal] = useState(false);

    const navigate = useNavigate();

    const getprojects = async () => {
        try {
            const tableSchema = database_id;
            const tableName = 'projucts';
            const conditions = {
                company_id: await getuserdata('company_id'),
            };
            const orderBy = "id DESC";
            const search = {};
            const pagination = { page: currentPage, limit: 10 };
            const margedata = [
                {
                    target_label: "users",
                    target_table: "users",
                    target_column: "id",
                    target_value: "user_id"
                }
            ];
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

            console.log('Projects:', response);
            if (response.success) {
                setProjects(response.data);
                setTotalPages(response.totalPages);
            } else {
                console.error('Failed to fetch projects:', response.message);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    useEffect(() => {
        getprojects();

        const demoEmailData = [
            { id: 1, name: "Development" },
            { id: 2, name: "Marketing" },
            { id: 3, name: "Sales" },
        ];

        setProjects(projects);
        setTeamData(projects); // For simplicity, using the same data for teams
        setEmailData(demoEmailData);
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted with search:", searchInput, "status:", status, "department:", filterDepartment);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        console.log("Changed to page:", page);
    };

    const handleEdit = (email) => {
        console.log("Edit clicked for:", email);
        setSelectData(email);
        setShowAddProjuctModal(true);
    };

    const handleDeleteData = async (id) => {
        console.log("Delete clicked for ID:", id);
        alert("Are you sure you want to delete this project?");
        const tableSchema = database_id;
        const tableName = 'projucts';
        const conditions = { id: id };
        const response = await oneclick.delete(tableSchema, tableName, conditions);
        if (response.success) {
            getprojects();
        } else {    
            console.error('Failed to delete project:', response.message);
        }

    };

    const handleTeamView = (email) => {
        console.log("Team view clicked for:", email);
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Projuct List</h4>
                        </div>

                        <div className="card-filter">
                            <form onSubmit={handleFormSubmit}>
                                <div className="filter-form">
                                    <div className="filter-item">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            name="search"
                                            placeholder="Search With Projuct Name"
                                            className="form-control"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                        />
                                    </div>

                                    <div className="filter-item">
                                        <select
                                            name="status"
                                            className="form-select"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="DeActive">DeActive</option>
                                            <option value="Completed">Completed</option>
                                            <option value="Ongoing">Ongoing</option>
                                        </select>
                                    </div>

                                    <div className="filter-item">
                                        <select
                                            name="department"
                                            className="form-select"
                                            value={filterDepartment}
                                            onChange={(e) => setFilterDepartment(e.target.value)}
                                        >
                                            <option value="">Select Department</option>
                                            {emailData.length > 0 ? (
                                                emailData.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option disabled>No available</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="filter-action">
                                        <button
                                            type="button"
                                            className="i-btn danger--btn btn--md"
                                            onClick={() => { setSearchInput(''); setStatus(''); setCurrentPage(1); setFilterDepartment('') }}
                                        >
                                            Reset
                                        </button>

                                        <button
                                            type="button"
                                            className="i-btn primary--btn btn--md text-white"
                                            onClick={() => setShowAddProjuctModal(true)}
                                        >
                                            Add New Project
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="card-body px-0">
                            <div className="responsive-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Tags</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projects.length > 0 ? (
                                            projects.map((email, index) => (
                                                <tr key={email.id} 
                                                    onClick={() => navigate(`/projucts/ProjuctDeatails/${encodeBase64(email.id)}`)} // Use navigate for dynamic routing
                                                    style={{ cursor: 'pointer' }}>
                                                    <td data-label="ID">{(currentPage - 1) * 10 + index + 1}</td>
                                                    <td data-label="Logo" className="maxtable">
                                                        {email.logo ? (
                                                            <ImageComponent fileid={email.image} width={50} height={50} borderRadius={50} boxShadow="" />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    borderRadius: "50%",
                                                                    background: "#ccc",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                {email.name ? email.name.substring(0, 3).toUpperCase() : "N/A"}
                                                            </div>
                                                        )}

                                                    </td>
                                                    <td data-label="Name" className="maxtable" >{email.name}</td>
                                                    <td data-label="Tags" className="maxtable">
                                                        {email.tags ? (
                                                            email.tags
                                                                .replace(/[{}"]/g, "")  // Remove curly braces and quotes
                                                                .split(",")             // Split by commas
                                                                .map((tag, index) => (
                                                                    <span key={index} className="tagdata">
                                                                        {tag.trim()}  {/* Remove any extra spaces */}
                                                                    </span>
                                                                ))
                                                        ) : (
                                                            <span>No tags</span>
                                                        )}

                                                    </td>


                                                    <td data-label="Description" className="maxtable" >{email.description}</td>
                                                    <td data-label="Status" className="maxtable">
                                                        <span
                                                            className={`badge badge--${email.status === "Active"
                                                                    ? "success"
                                                                    : email.status === "Proposal"
                                                                        ? "info"
                                                                        : email.status === "Starting"
                                                                            ? "warning"
                                                                            : email.status === "Design"
                                                                                ? "primary"
                                                                                : "danger"
                                                                }`}
                                                        >
                                                            {email.status || "No status"}
                                                        </span>
                                                    </td>

                                                    <td data-label="Actions" className="d-flex gap-2">
                                                        <button
                                                            className="i-btn primary--btn btn--md"
                                                            onClick={() => handleEdit(email)}
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            className="i-btn danger--btn btn--md"
                                                            onClick={() => handleDeleteData(email.id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-muted text-center" colSpan="7">
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    {/* Pagination placeholder */}
                    <Paginationnew currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </div>
            </section>
            {showAddProjuctModal && <AddNewProjectModal isOpen={showAddProjuctModal} closeModal={() => setShowAddProjuctModal(false)} getprojects={getprojects} selecydata={selecydata} setSelectData={setSelectData}/>}
        </div>
    );
};

export default ProjectList;
