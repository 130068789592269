// src/App.js
import React, { useEffect, useState } from 'react';
import { Route, Routes, Router } from 'react-router-dom';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getColors } from './config/colorConfig'; // Correct import path
import Home from './components/screens/Home';
import About from './components/screens/About';
import Features from './components/screens/Features';
import Pricing from './components/screens/Pricing';
import Faq from './components/screens/Faq';
import Login from './components/screens/Login';
import ForgotPassword from './components/screens/Forgot-Password';
import UpdatePassword from './components/screens/Update-Password';
import Verify from './components/screens/Verify';
import Register from './components/screens/Register';
import Dashboard from './user/Dashbord';
import ProtectedRoute from './ProtectedRoute';
import CreateHosting from './user/Cloud/CreateHosting';
import CreateVps from './user/Cloud/CreateVps';
import HistrySharedHost from './user/Cloud/HistrySharedHost';
import CloudStorage from './user/Cloud/CloudStorage';
import SeodataAdd from './user/seo/seodataadd';
import BuyOrRenewPlans from './user/BuyOrRenewPlans';
import Settings from './user/Settings/setting';
import WebhookParameterSetup from './user/Settings/WebhockConfiguration';
import RecaptchaSettings from './user/Settings/Recaptcha';
import GoogleAuthCredentials from './user/Settings/GoogleLogin';
import BeeFreeCredentialsSetup from './user/Settings/BeePlugin';
import CurrencyManagement from './user/Settings/Currencies';
import LoginPageContentForm from './user/Settings/LoginSection';
import LanguageDashboard from './user/Settings/LanguageManagement';
import AboutSection from './user/Frontend_Sections/AboutSection';
import BannerSection from './user/Frontend_Sections/BannerSection';
import BlogSection from './user/Frontend_Sections/blogs';
import ClientSection from './user/Frontend_Sections/ClientSection';
import CTASection from './user/Frontend_Sections/CTASection';
import FAQSection from './user/Frontend_Sections/FAQSection';
import FeatureSection from './user/Frontend_Sections/FeatureSection';
import FooterSection from './user/Frontend_Sections/FooterSection';
import HostingPlans from './user/Frontend_Sections/HostingPlans';
import TeamMembersForm from './user/Frontend_Sections/TeamMumbers';
import OverviewSectionForm from './user/Frontend_Sections/OverviewSection';
import PolicyPages from './user/Frontend_Sections/PolicyPages';
import PricingPlanSection from './user/Frontend_Sections/PricingPlanSection';
import ProcessSection from './user/Frontend_Sections/ProcessSection';
import Socialicon from './user/Frontend_Sections/Socialicon';
import ProjuctsSection from './user/Frontend_Sections/Projuctes';
import MembershipPlans from './user/Membership_Management/MembershipPlans';
import UserManagement from './user/Membership_Management/UserManagement';
import ManageContactGroups from './user/Contact_Management/group';
import ContactDetails from './user/Contact_Management/ContactDetails';
import ContactAttribute from './user/Contact_Management/ContactAttribute';
import Send from './user/Communications_Hub/SMSMessagess/Send';
import History from './user/Communications_Hub/SMSMessagess/History';
import SMSCampaign from './user/Marketing_&_Campaign/SMSCampaign';
import WhatAppCampaign from './user/Marketing_&_Campaign/WhatsAppCampaign';
import EmailCampaign from './user/Marketing_&_Campaign/EmailCampaign';
import SMSWhatsapp from './user/Message_Templates/SMSWhatsapp';
import EmailTemplate from './user/Message_Templates/EmailTemplate';
import SMS from './user/Communications_Gateway/SMS';
import WhatsApp from './user/Communications_Gateway/WhatsApp';
import Email from './user/Communications_Gateway/Email';
import EmailsList from './user/CloudMails/MailList';
import EmailsTemplate from './user/CloudMails/Mails';
import APIKeyGeneration from './user/Supports_Developer_Options/APIKeyGeneration';
//Ravindra bro chesadu 16-09-2024
import SettingsHrm from './user/HRM/Settings';
import Staff from './user/HRM/Staff';
import PayRoll from './user/HRM/Pay_roll';
import BranchList from './user/HRM/Branches';
import CompanySetup from './user/Company/Company_setup';
import EditBranch from './user/HRM/Edite';
import RealTimeDataBase from './user/RealTimeData/RealTimeDataBase';
import RealTimeTables from './user/RealTimeData/RealTimeTables';
import RealTimeColumns from './user/RealTimeData/RealTimeColumns';
import SubscriptionPlanForm from './user/Membership_Management/SubscriptionPlanForm';
import UserDashboard from './user/Membership_Management/UserDashboard';
import EmployeeEditForm from './user/HRM/EmployeeEditForm';
import Tracker from './user/HRM/Tracker';
import Roles from './user/HRM/Roles';
import Permissions from './user/Settings/Permissionss';
import DomainByying from './user/Domain_Management/DomainBuying';
import MenuGroups from './user/Settings/MenuGroups';
import MenuListScreen from './user/Settings/MenuList';
import SubMenuListScreen from './user/Settings/SubMenuList';
import AddContactForm from './user/Contact_Management/Contactaddnew';

import DesignPage from './user/DesignPage/DesignPage';
import EmailTemplateCreate from './user/Message_Templates/EmailTemplateCreate';
import WebSitesList from './user/seo/WebSitelist';
import DedicatedHost from './user/Cloud/DedicatedHost';
import SocketDataRetriever from './user/SocketDataRetriever';
import PrivacyPolicyEditor from './user/Settings/PrivacyPolicyEditor';
import TermsConditionsEditor from './user/Settings/TermsConditionsEditor';
import TermsAndConditions from './components/screens/TermsAndConditions';
import PrivacyPolicy from './components/screens/PrivacyPolicy';
import InvoiceTemplate from './user/Invoices/Invoicespage';
import DataFetchComponent from './user/Test/DataFetchComponent';
import SqlEditor from './user/RealTimeData/SqlEditor';
import ApplicationsList from './user/Applications/ApplicationsList';

import ElementModel from './user/Frontend_Sections/ElementModel';
import HostingDetails from './user/Cloud/HostingDetails';

//projuctes rotes
import ProjectList from './user/Projucts/ProjuctList';


// import ProfileGeneratorScreen from './user/Test/ProfileGeneratorScreen';

//design page 
import DesidnpageLayout from './user/DesignPage/DesidnpageLayout';
// import ApiTestr from './user/Test/ApiTest';
import { isTokenExpired } from './utils/isTokenExpired';
import './assets/theme/global/css/line-awesome.min.css';
import './assets/theme/global/css/font_bootstrap-icons.css';
import './assets/theme/frontend/css/all.min.css';
import './assets/theme/frontend/css/default.css';
import './assets/theme/frontend/css/style.css';
import './assets/theme/frontend/css/media.css';
import { useNavigate } from 'react-router-dom';
import useTokenRefresher from './utils/useTokenRefresher';
import useIdleTimeout from './hooks/useIdleTimeout'; // Adjust the path accordingly
import AIDepartment from './user/AiStaff/AIDepartment';
import AiTeam from './user/AiStaff/AiTeam';
import AiStaffs from './user/AiStaff/AiStaffs';
import ChatWithAi from './user/AiStaff/ChatWithAi';
import TeamOverView from './user/AiStaff/TeamOverView';
import AiBugReport from './user/AiStaff/AiBugfix';
import Webhook from './user/Communications_Gateway/Webhook';
import AiStaffTraining from './user/AiStaff/AiStaffTrining';
import WebChat from './WebChat/WebChat';

import ProjuctDeatails from './user/Projucts/ProjuctDeatails';

import InvoiceList from './user/Billings/invoices';
import UnPaidInvoiceList from './user/Billings/UnPaidInvoices';
import PaidInvoiceList from './user/Billings/PaidInvoices';

import { MainLayout } from './MainLayout';
import { UserLayout } from './UserLayout';
import { AuthLayout } from './MainLayout';


import { getAuthToken, clearAuthToken } from './utils/AuthenticatedApp';

import { fetchSettings } from './services/apiService'; // Import your API function

function App() {


  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSettings = async () => {
      const settingsData = await fetchSettings();
      setSettings(settingsData[0] || {});
      setLoading(false);
    };

    getSettings();
  }, []);

  return (

      <Routes>
        <Route path="/" element={<MainLayout><Home /></MainLayout>} />  
        {/* <Route path="/ApiTestr" element={<ApiTestr />} />   */}
        <Route path="/about" element={<MainLayout><About /></MainLayout>} />
        <Route path="/features" element={<MainLayout><Features /></MainLayout>} />
        <Route path="/pricing" element={<MainLayout><Pricing /></MainLayout>} />
        <Route path="/faq" element={<MainLayout><Faq /></MainLayout>} />
        <Route path="/privacy-policy" element={<MainLayout><PrivacyPolicy /></MainLayout>} />
        <Route path="/terms-and-conditions" element={<MainLayout><TermsAndConditions /></MainLayout>} />

        <Route path="/login" element={<AuthLayout settings={settings}><Login /></AuthLayout>} />
        <Route path="/forgot-password" element={<AuthLayout settings={settings}><ForgotPassword /></AuthLayout>} />
        <Route path="/verify" element={<AuthLayout settings={settings}><Verify /></AuthLayout>} />
        <Route path="/UpdatePassword" element={<AuthLayout settings={settings}><UpdatePassword /></AuthLayout>} />
        <Route path="/register" element={<AuthLayout settings={settings}><Register /></AuthLayout>} />
        <Route path="/dashboard" element={<ProtectedRoute> <UserLayout><Dashboard /></UserLayout></ProtectedRoute>} />
        <Route path="/shared-hosting-create" element={<ProtectedRoute> <UserLayout><CreateHosting /></UserLayout></ProtectedRoute>} />
        <Route path="/shared-hosting-details/:planedata" element={<ProtectedRoute> <UserLayout><HostingDetails /></UserLayout></ProtectedRoute>} />
        <Route path="/vps_server" element={<ProtectedRoute> <UserLayout><CreateVps /></UserLayout></ProtectedRoute>} />
        <Route path="/shared-hosting-history" element={<ProtectedRoute> <UserLayout><HistrySharedHost /></UserLayout></ProtectedRoute>} />
        <Route path="/CloudStorage" element={<ProtectedRoute> <UserLayout><CloudStorage /></UserLayout></ProtectedRoute>} />
        <Route path="/seo_data_add/:showwebsites" element={<ProtectedRoute> <UserLayout><SeodataAdd /></UserLayout></ProtectedRoute>} />
        <Route path="/buy_or_renew_plans" element={<ProtectedRoute> <UserLayout><BuyOrRenewPlans /></UserLayout></ProtectedRoute>} />
        <Route path="/subscriptions-logs" element={<ProtectedRoute> <UserLayout><SeodataAdd /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting" element={<ProtectedRoute> <UserLayout><Settings /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/webhook/config" element={<ProtectedRoute> <UserLayout><WebhookParameterSetup /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/recaptcha" element={<ProtectedRoute> <UserLayout><RecaptchaSettings /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/social-login" element={<ProtectedRoute> <UserLayout><GoogleAuthCredentials /></UserLayout></ProtectedRoute>} />
        <Route path="/bee-plugin" element={<ProtectedRoute> <UserLayout><BeeFreeCredentialsSetup /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/currencies" element={<ProtectedRoute> <UserLayout><CurrencyManagement /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/frontend/section" element={<ProtectedRoute> <UserLayout><LoginPageContentForm /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/frontend/permissions" element={<ProtectedRoute> <UserLayout><Permissions /></UserLayout></ProtectedRoute>} />
        <Route path="/languages" element={<ProtectedRoute> <UserLayout><LanguageDashboard /></UserLayout></ProtectedRoute>} />
        <Route path="/frontend_sections/:section_name" element={<ProtectedRoute> <UserLayout><AboutSection /></UserLayout></ProtectedRoute>} />
        <Route path="/frontend_sections/elementblade/:section_name/:elementdata/:code/:data/:id" element={<ProtectedRoute> <UserLayout><ElementModel /></UserLayout></ProtectedRoute>} />
        <Route path="/membership_plans" element={<ProtectedRoute> <UserLayout><MembershipPlans /></UserLayout></ProtectedRoute>} />
        {/*  Ravi bro doing this work 16-09-2024 */}
        <Route path="/hrm&management/branches" element={<ProtectedRoute> <UserLayout><BranchList /></UserLayout></ProtectedRoute>} />
        <Route path="/hrm&management/staff" element={<ProtectedRoute> <UserLayout><Staff /></UserLayout></ProtectedRoute>} />
        <Route path="/hrm&management/pay_roll" element={<ProtectedRoute> <UserLayout><PayRoll /></UserLayout></ProtectedRoute>} />
        <Route path="/hrm&management/settings" element={<ProtectedRoute> <UserLayout><SettingsHrm /></UserLayout></ProtectedRoute>} />
        <Route path="/company_setup" element={<ProtectedRoute> <UserLayout><CompanySetup /></UserLayout></ProtectedRoute>} />
        <Route path="/branches/edit" element={<ProtectedRoute> <UserLayout><EditBranch /></UserLayout></ProtectedRoute>} />
        <Route path="/hrm&management/staff/edit/:employeeId/:companyid" element={<ProtectedRoute> <UserLayout><EmployeeEditForm /></UserLayout></ProtectedRoute>} />
        <Route path="/hrm&management/tracker" element={<ProtectedRoute> <UserLayout><Tracker /></UserLayout></ProtectedRoute>} />
        <Route path="/hrm&management/roles" element={<ProtectedRoute> <UserLayout><Roles /></UserLayout></ProtectedRoute>} />
        <Route path="/real_time_database" element={<ProtectedRoute> <UserLayout><RealTimeDataBase /></UserLayout></ProtectedRoute>} />
        <Route path="/real_time_database/tables/:myData/:server/:username/:password" element={<ProtectedRoute> <UserLayout><RealTimeTables /></UserLayout></ProtectedRoute>} />
        <Route path="/real_time_database/columns/:encodedatabasename/:encodeserver/:encodeusername/:encodepassword/:encodetablename" element={<ProtectedRoute> <UserLayout><RealTimeColumns /></UserLayout></ProtectedRoute>} />
        <Route path="/users/detail" element={<ProtectedRoute> <UserLayout><UserDashboard /></UserLayout></ProtectedRoute>} />
        {/*  Ravi bro doing this work 16-09-2024*/}
        <Route path="/user_management" element={<ProtectedRoute> <UserLayout><UserManagement /></UserLayout></ProtectedRoute>} />
        <Route path="/group" element={<ProtectedRoute> <UserLayout><ManageContactGroups /></UserLayout></ProtectedRoute>} />
        <Route path="/contact_details" element={<ProtectedRoute> <UserLayout><ContactDetails /></UserLayout></ProtectedRoute>} />
        <Route path="/contact_attribute" element={<ProtectedRoute> <UserLayout><ContactAttribute /></UserLayout></ProtectedRoute>} />
        <Route path="/sms_send" element={<ProtectedRoute> <UserLayout><Send /></UserLayout></ProtectedRoute>} />
        <Route path="/sms_history" element={<ProtectedRoute> <UserLayout><History /></UserLayout></ProtectedRoute>} />
        <Route path="/sms_campaign" element={<ProtectedRoute> <UserLayout><SMSCampaign /></UserLayout></ProtectedRoute>} />
        <Route path="/whatsapp_campaign" element={<ProtectedRoute> <UserLayout><WhatAppCampaign /></UserLayout></ProtectedRoute>} />
        <Route path="/email_campaign" element={<ProtectedRoute> <UserLayout><EmailCampaign /></UserLayout></ProtectedRoute>} />
        <Route path="/sms_whatsapp" element={<ProtectedRoute> <UserLayout><SMSWhatsapp /></UserLayout></ProtectedRoute>} />
        <Route path="/email_template" element={<ProtectedRoute> <UserLayout><EmailTemplate /></UserLayout></ProtectedRoute>} />
        <Route path="/communications_gateway_sms" element={<ProtectedRoute> <UserLayout><SMS /></UserLayout></ProtectedRoute>} />
        <Route path="/communications_gateway_whatsapp" element={<ProtectedRoute> <UserLayout><WhatsApp /></UserLayout></ProtectedRoute>} />
        <Route path="/communications_gateway_email" element={<ProtectedRoute> <UserLayout><Email /></UserLayout></ProtectedRoute>} />
        <Route path="/api_key_generation" element={<ProtectedRoute> <UserLayout><APIKeyGeneration /></UserLayout></ProtectedRoute>} />
        <Route path="/plans/create" element={<ProtectedRoute> <UserLayout><SubscriptionPlanForm /></UserLayout></ProtectedRoute>} />
        <Route path="/domain_buying" element={<ProtectedRoute> <UserLayout><DomainByying /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/frontend/menugroup" element={<ProtectedRoute> <UserLayout><MenuGroups /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/frontend/menulist" element={<ProtectedRoute> <UserLayout><MenuListScreen /></UserLayout></ProtectedRoute>} />
        <Route path="/general/setting/frontend/submenulist" element={<ProtectedRoute> <UserLayout><SubMenuListScreen /></UserLayout></ProtectedRoute>} />

        <Route path="/EmailTemplateCreate" element={<ProtectedRoute> <UserLayout><EmailTemplateCreate /></UserLayout></ProtectedRoute>} />
        <Route path="/WebSitesList" element={<ProtectedRoute> <UserLayout><WebSitesList /></UserLayout></ProtectedRoute>} />
        <Route path="/dedicatedHost" element={<ProtectedRoute> <UserLayout><DedicatedHost /></UserLayout></ProtectedRoute>} />
        <Route path="/SocketDataRetriever" element={<ProtectedRoute> <UserLayout><SocketDataRetriever /></UserLayout></ProtectedRoute>} />
        <Route path="/admin/privacy_policy" element={<ProtectedRoute> <UserLayout><PrivacyPolicyEditor /></UserLayout></ProtectedRoute>} />
        <Route path="/admin/terms_and_conditions" element={<ProtectedRoute> <UserLayout><TermsConditionsEditor /></UserLayout></ProtectedRoute>} />
        <Route path="/billings/invoice" element={<ProtectedRoute> <UserLayout><InvoiceList /></UserLayout></ProtectedRoute>} />
        <Route path="/billing/unpaidinvoices" element={<ProtectedRoute> <UserLayout><UnPaidInvoiceList /></UserLayout></ProtectedRoute>} />
        <Route path="/billing/paidinvoices" element={<ProtectedRoute> <UserLayout><PaidInvoiceList /></UserLayout></ProtectedRoute>} />
        <Route path="/cloudmails/maillist" element={<ProtectedRoute> <UserLayout><EmailsList /></UserLayout></ProtectedRoute>} />
        <Route path="/cloudmails/mails" element={<ProtectedRoute> <UserLayout><EmailsTemplate /></UserLayout></ProtectedRoute>} />
        <Route path="/invoices" element={<ProtectedRoute> <UserLayout><InvoiceTemplate /></UserLayout></ProtectedRoute>} />
        <Route path="/contactaddnew" element={<ProtectedRoute> <UserLayout><AddContactForm /></UserLayout></ProtectedRoute>} />        {/* Add other routes here   */}
        <Route path="/dataFetchComponent" element={<ProtectedRoute> <UserLayout><DataFetchComponent /></UserLayout></ProtectedRoute>} />        {/* Add other routes here   */}
        <Route path="/SqlEditor/:encodedatabasename/:encodeserver/:encodeusername/:encodepassword/:encodetablename" element={<ProtectedRoute> <UserLayout><SqlEditor /></UserLayout></ProtectedRoute>} />        {/* Add other routes here   */}
        <Route path="/application/applicationslist" element={<ProtectedRoute> <UserLayout><ApplicationsList /></UserLayout></ProtectedRoute>} />        {/* Add other routes here   */}
        <Route path="/webhook" element={<ProtectedRoute> <UserLayout><Webhook /></UserLayout></ProtectedRoute>} />
        {/* Add other routes here   ApplicationsList*/}

        {/* <Route path="/ProfileGeneratorScreen" element={<ProtectedRoute> <UserLayout><ProfileGeneratorScreen /></UserLayout></ProtectedRoute>} /> */}
        {/* Ai Roots hear* /}
        {/* Ai Roots hear ChatWithAi*/}
        <Route path="/ai/department" element={<ProtectedRoute> <UserLayout><AIDepartment /></UserLayout></ProtectedRoute>} />        {/* Add other routes here   */}
        <Route path="/ai/teams" element={<ProtectedRoute> <UserLayout><AiTeam /></UserLayout></ProtectedRoute>} />
        <Route path="/ai/teams" element={<ProtectedRoute> <UserLayout><TeamOverView /></UserLayout></ProtectedRoute>} />
        <Route path="/ai/teams/teamoverview/:teamdata" element={<ProtectedRoute> <UserLayout><TeamOverView /></UserLayout></ProtectedRoute>} />
        <Route path="/ai/staff" element={<ProtectedRoute> <UserLayout><AiStaffs /></UserLayout></ProtectedRoute>} />
        <Route path="/ai/chat/:staffid/:screen/:chatingid" element={<ProtectedRoute> <UserLayout><ChatWithAi /></UserLayout></ProtectedRoute>} />
        <Route path="/ai/chatfullscreen/:staffid/:screen/:chatingid" element={<ProtectedRoute><ChatWithAi /></ProtectedRoute>} />
        <Route path="/ai/bugfix" element={<ProtectedRoute> <UserLayout><AiBugReport /></UserLayout></ProtectedRoute>} />
        <Route path="/ai/AiStaffTraining/:teamdata" element={<ProtectedRoute> <UserLayout><AiStaffTraining /></UserLayout></ProtectedRoute>} />


        {/* projucts Leout*/}

        <Route path="/projucts" element={<ProtectedRoute> <UserLayout><ProjectList /></UserLayout></ProtectedRoute>} />
        <Route path="/projucts/ProjuctDeatails/:id" element={<ProtectedRoute> <UserLayout><ProjuctDeatails /></UserLayout></ProtectedRoute>} />
        
        {/* Open chat Leout*/}
        <Route path="/webchat" element={<WebChat />} />
        {/* Design Pages Leout*/}
        <Route path="/DesignPage/:applicationid" element={<ProtectedRoute> <DesidnpageLayout><DesignPage /></DesidnpageLayout></ProtectedRoute>} />
        {/* Add other routes here  SqlEditor*/}
      </Routes>
  );
}
if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
  console.warn = () => { };
  console.error = () => { };
}
export default App;
