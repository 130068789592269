import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import Select from 'react-select'; // Ensure you have react-select installed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faUser, faHeart } from '@fortawesome/free-solid-svg-icons'; // Example icons
import IconPicker from './IconPicker';

const MenuAddModal = ({ show, handleClose, onSubmit, groups, permissions }) => {

    console.log('MANU CREATE permissions', permissions);
    const [menuName, setMenuName] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [icon, setIon] = useState('');
    const [link, setLink] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]); // State for selected permissions
    const [loading, setLoading] = useState(false); // Loading state
    const [selectedPermissionsName, setSelectedPermissionsName] = useState([]); // State for selected permission names


    // Create options for the select dropdown
    const permissionOptions = permissions.flatMap(data =>
        JSON.parse(data.permissions).map(subPermission => ({
            value: subPermission.subName,
            label: `${data.name} - ${subPermission.subName}`,
            permission: subPermission.permissions
        }))
    );

    const handlePermissionTypesChange = (selectedOptions) => {
        // Log selected options for debugging
        console.log('selectedOptions:', selectedOptions);


        const selectedNames = selectedOptions.map(option => option.label); // Get the names of each permission
        // Extract selected permission IDs from selected options
        const selectedIds = selectedOptions.flatMap(option => {
            // Check if option.permission is an array before mapping
            if (Array.isArray(option.permission)) {
                return option.permission.map(perm => perm.id); // Get the ID of each permission
            }
            return [];
        });

        // Assign the selected IDs to selectedPermissions
        setSelectedPermissions(selectedIds);
        setSelectedPermissionsName(selectedNames);

        // Show alert with selected permission IDs
        alert(`Selected Permission IDs: ${selectedIds.join(', ')}`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (menuName.trim() === '' || selectedGroup === '' || !imageFile || selectedPermissions.length === 0) {
            alert('All fields are required!');
            return;
        }

        setLoading(true); // Set loading state
        try {
            // Call the onSubmit function passed from the parent with an object
            await onSubmit({
                menuName,
                selectedGroup,
                imageFile,
                icon,
                link,
                selectedPermissions,
                selectedPermissionsName
            });
            console.log('MENU CREATE DATA SEND :', menuName, selectedGroup, imageFile, link, selectedPermissions);

            // Reset fields
            setMenuName('');
            setSelectedGroup('');
            setImageFile(null);
            setLink('');
            setSelectedPermissions([]); // Reset selected permissions
            handleClose(); // Close the modal
        } catch (error) {
            console.error("Error submitting menu:", error);
            alert('Error adding menu. Please try again.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formMenuName">
                        <Form.Label>Menu Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter menu name"
                            value={menuName}
                            onChange={(e) => setMenuName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSelectGroup">
                        <Form.Label>Select Group</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                        >
                            <option value="">Select a group</option>
                            {groups.map((group) => (
                                <option key={group.id} value={group.id}>
                                    {group.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formImageUpload">
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImageFile(e.target.files[0])}
                        />
                        {imageFile && <p>Selected file: {imageFile.name}</p>} {/* Image preview */}
                    </Form.Group>
                    <Form.Group controlId="formImageUpload">
                        <Form.Label>Select Icon</Form.Label>
                        <IconPicker icon={icon} setIcon={setIon} />

                    </Form.Group>
                    <Form.Group controlId="formLink">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter menu link"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSelectPermissions">
                        <Form.Label>Select Permissions</Form.Label>
                        <Select
                            options={permissionOptions}
                            isMulti
                            onChange={handlePermissionTypesChange}
                            placeholder="Select Permission Types"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={loading}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Adding...' : 'Add Menu'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MenuAddModal;
