import React, { useEffect, useState } from "react";
import ImageComponent from "../../../../utils/ImageComponent";
import CodeEditor from "../../../../utils/CodeEditorNew";
import { oneclick } from "../../../../oneclick";
import database_id from "../../../../utils/databaseConfig";
import axios from "axios";

const Ai_ResponceChat = ({ message, staffdata, index, teamimage }) => {
    console.log('message :', message);
    console.log('staffdata :', staffdata);

    const [staffImage, setStaffImage] = useState('');
    const [loading, setLoading] = useState(false);

    const getstaffimage = async (id) => {
        console.log('getstaffimage :', id);
        const tableSchema = database_id;
        const tableName = "ai_staff";
        const conditions = { 
            id: id };
        const orderBy = "id DESC";
        const search = {};
        const pagination = {};
        const margedata = {};
        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

        console.log('response staff data :', response);
        if (response.data && response.data.length > 0) {
            console.log('response staff data :', response.data[0].profile_image);
            return response.data[0].profile_image;
        }
        return '';
    };

    useEffect(() => {
        if (message.sender_id !== 'AI') {
            setLoading(true);
            getstaffimage(message.sender_id).then(image => {
                setStaffImage(image);
                setLoading(false);
            });
        }
    }, [message.sender_id]);

    return (
        <div key={index} className={`message sender col-lg-12 col-md-11 col-sm-11`}>
            <div className="d-flex senderchat col-lg-11 col-md-12 col-sm-11">
                <div style={{ width: '50px', height: '50px' }}>
                    {message.sender_id === 'AI' ? (
                        <ImageComponent
                            fileid={teamimage}
                            width={50}
                            height={50}
                            borderRadius={50}
                            boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                        />
                    ) : (
                        <ImageComponent
                            fileid={staffImage}
                            width={50}
                            height={50}
                            borderRadius={50}
                            boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                        />
                    )}
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10">
                    <div>
                        <div className="message-bubblesender">
                            {message.text.split(/```/).map((block, index) => {
                                if (index % 2 === 0) {
                                    const imagePattern = /!\[.*?\]\((.*?)\)/;
                                    const linkPattern = /\[.*?\]\((http[s]?:\/\/.*?\.(.*?))\)/;

                                    const imageMatch = block.match(imagePattern);
                                    if (imageMatch) {
                                        const imageUrl = imageMatch[1];
                                        const cleanedText = block.replace(imagePattern, "").trim();

                                        return (
                                            <div key={index}>
                                                {cleanedText && <p className="message-text">{cleanedText}</p>}
                                                <img
                                                    src={imageUrl}
                                                    alt="Image"
                                                    style={{
                                                        width: "150px",
                                                        height: "150px",
                                                        borderRadius: "10px",
                                                        marginTop: "10px",
                                                    }}
                                                />
                                            </div>
                                        );
                                    }

                                    const linkMatch = block.match(linkPattern);
                                    if (linkMatch) {
                                        const imageUrl = linkMatch[1];
                                        return (
                                            <div key={index}>
                                                <p className="message-text">{block.replace(linkPattern, "").trim()}</p>
                                                <img
                                                    src={imageUrl}
                                                    alt="Image"
                                                    style={{
                                                        width: "150px",
                                                        height: "150px",
                                                        borderRadius: "10px",
                                                        marginTop: "10px",
                                                    }}
                                                />
                                            </div>
                                        );
                                    }

                                    return (
                                        <p key={index} className="message-text">
                                            {block.trim()}
                                        </p>
                                    );
                                } else {
                                    const blockParts = block.trim().split("\n");
                                    const language = blockParts[0].toLowerCase();
                                    const code = blockParts.slice(1).join("\n").trim();
                                    return (
                                        <div key={index} className="code-block-container">
                                            <CodeEditor message={`\`\`\`${language} \n ${code} \`\`\``} language={language} />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <p style={{ marginLeft: '25px', textAlign: 'left' }}>{message.time}</p>
                </div>
            </div>
        </div>
    );
};

export default Ai_ResponceChat;
