import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import Select from 'react-select'; // Ensure you have react-select installed
import ImageComponent from '../../../utils/ImageComponent'; // Adjust the import path based on your structure
import { oneclick } from '../../../oneclick';
import { OneKlicksFileSDK } from '../../../OneKlicksFileSDK';
import database_id from '../../../utils/databaseConfig';
import { set } from 'date-fns';
import IconPicker from './IconPicker';

const EditMenuModal = ({ show, handleClose, onSubmit, groups, permissions, menuData }) => {
    const [menuName, setMenuName] = useState('');
    const [icon, setIcon] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [link, setLink] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPermissionsName, setSelectedPermissionsName] = useState([]);
    const [oldImage, setOldImage] = useState('');

    // Create options for the select dropdown
    const permissionOptions = permissions.flatMap(data =>
        JSON.parse(data.permissions).map(subPermission => ({
            value: subPermission.subName,
            label: `${data.name} - ${subPermission.subName}`,
            permission: subPermission.permissions
        }))
    );

    // Populate the form with existing data when the modal opens
    useEffect(() => {
        if (menuData) {
            setMenuName(menuData.name || '');
            setSelectedGroup(menuData.groupId || '');
            setLink(menuData.link || '');
            const initialSelectedPermissions = menuData.permissions || [];
            setSelectedPermissions(initialSelectedPermissions);
            const initialSelectedPermissionNames = menuData.permissionsName || [];
            setSelectedPermissionsName(initialSelectedPermissionNames);
            setOldImage(menuData.icon || '');
            setIcon(menuData.newicon || '');
        }
    }, [menuData]);

    const handlePermissionTypesChange = (selectedOptions) => {
        const selectedNames = selectedOptions.map(option => option.label);
        const selectedIds = selectedOptions.flatMap(option => {
            if (Array.isArray(option.permission)) {
                return option.permission.map(perm => perm.id);
            }
            return [];
        });

        setSelectedPermissions(selectedIds);
        setSelectedPermissionsName(selectedNames);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validate required fields
        if (menuName.trim() === '' || selectedGroup === '' || selectedPermissions.length === 0) {
            alert('All fields are required!');
            return;
        }
    
        setLoading(true);
        try {
            const tableSchema = database_id;
            const tableName = "menulist";
            let imageid;
            const token = localStorage.getItem('token'); // Retrieve token from localStorage
            
            // Ensure token exists
            if (!token) {
                alert('User is not authenticated. Please log in again.');
                return;
            }
    
            const ConditionsUsers = { "login_tockin": token };
            const emaployeData11 = await oneclick.read(tableSchema, "users", ConditionsUsers, "", "");
            
            // Handle case where user data is not returned
            if (!emaployeData11.data || emaployeData11.data.length === 0) {
                alert('User data not found. Please try again.');
                return;
            }
    
            const user_id = emaployeData11.data[0].id;
    
            // Handle image upload if a new file is selected
            if (imageFile) {
                const root = "images/icon"; // Replace with the appropriate root path
                const uploadResult = await OneKlicksFileSDK.uploadFile(user_id, root, imageFile);
                console.log('MENU ICON UPLOAD :', uploadResult);
                imageid = uploadResult.file.id; // Return the upload result
            }
    
            // Prepare data for the update
            const data = {
                name: menuName,
                groupId: selectedGroup,
                link: link,
                permissions: Array.isArray(selectedPermissions) 
                ? selectedPermissions.join(', ') // Only join if it's an array
                : selectedPermissions, // If it's not an array, use it directly
                icon: imageid ? imageid : oldImage, // Use the old image if no new file is uploaded
                newicon: icon,
                permissionsName: Array.isArray(selectedPermissionsName) 
                    ? selectedPermissionsName.join(', ') // Only join if it's an array
                    : selectedPermissionsName // If it's not an array, use it directly
            };
    
            // Update condition based on the provided menuData
            const conditions = { "id": menuData.id }; // Assuming menuData contains the id for the update
            const update = await oneclick.update(tableSchema, tableName, data, conditions);
            
            // Check if update was successful
            if (update.success) {
                alert('Menu updated successfully.');
                handleClose(); // Close the modal after successful update
            } else {
                console.error("Error updating menu:", update.error);
                alert(update.error || 'Error updating menu.');
            }
        } catch (error) {
            console.error("Error editing menu:", error);
            alert('Error editing menu. Please try again.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };
    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formMenuName">
                        <Form.Label>Menu Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter menu name"
                            value={menuName}
                            onChange={(e) => setMenuName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSelectGroup">
                        <Form.Label>Select Group</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                        >
                            <option value="">Select a group</option>
                            {groups.map((group) => (
                                <option key={group.id} value={group.id}>
                                    {group.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formImageUpload">
                        <Form.Label>Upload New Image (Optional)</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImageFile(e.target.files[0])}
                        />
                        {imageFile && <p>Selected file: {imageFile.name}</p>} {/* New Image preview */}
                        {oldImage && (
                            <ImageComponent
                                fileid={oldImage}
                                width={50}
                                height={50}
                                borderRadius={0}
                                boxShadow={'0px 0px 0px black'}
                            />
                        )} {/* Show old image */}
                    </Form.Group>
                    <Form.Group controlId="formImageUpload">
                        <Form.Label>Select Icon</Form.Label>
                        <IconPicker icon={icon} setIcon={setIcon} />

                    </Form.Group>
                    <Form.Group controlId="formLink">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter menu link"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSelectPermissions">
                        <Form.Label>Select Permissions</Form.Label>
                        <Select
                            options={permissionOptions}
                            isMulti
                            value={permissionOptions.filter(option => selectedPermissionsName.includes(option.label))}
                            onChange={handlePermissionTypesChange}
                            placeholder="Select Permission Types"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={loading}>
                    Close
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Saving...' : 'Save Changes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditMenuModal;
