import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditWebsiteModal = ({ showModal, handleModalClose, isEdit, initialData, handleSave }) => {
    console.log('EditWebsiteModal initialData', initialData);
  // Initialize form values, either from initialData (if editing) or as empty fields (if adding)
  const [formValues, setFormValues] = useState({});

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(formValues);  // Call parent save function with form data
    handleModalClose();      // Close modal after saving
  };

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Edit SEO Data' : 'Add New SEO Data'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formWebsite">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="text"
              name="website"
              className="mb-3"
              value={formValues.website}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            {isEdit ? 'Update' : 'Add'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditWebsiteModal;
