import React from 'react';
import useImageUrlBuild from './ImageUrlBuild';

const ImageComponent = ({ fileid, width, height, borderRadius, boxShadow, color, imagesize }) => {
    const { fileUrl, loading, error } = useImageUrlBuild(fileid);

    let displayWidth = width;
    let displayHeight = height;

    if (imagesize) {
        // Extract dimensions from imagesize
        console.log('imagesize:', imagesize);
        const size = imagesize.split('x').map(Number);
        const [originalWidth, originalHeight] = size;

        // Calculate aspect ratio
        const aspectRatio = originalWidth / originalHeight;

        // Determine the smaller dimension to fit within the provided width and height
        displayWidth = width;
        displayHeight = Math.min(height, width / aspectRatio);
    }


    if (loading) return <p></p>;
    if (error) return <p>{error}</p>;

    // Determine filter based on the color prop
    const imageFilter = color ? `invert(1) sepia(1) saturate(0) brightness(100)` : 'none';

    return (
        <img
            src={fileUrl}
            alt="Image"
            style={{
                width: `${displayWidth}px`,
                height: `${displayHeight}px`,
                borderRadius: `${borderRadius}%`,
                boxShadow,
                filter: imageFilter, // Apply filter based on the color prop
                objectFit: 'cover', // Ensures the image fits well in the given dimensions
            }}
        />
    );
};

export default ImageComponent;
