import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AddDatabaseModal from './Components/AddDatabaseModal';
import AddUserModal from './Components/AddUserModal';
import ConnectModal from './Components/ConnectModal';
import DatabaseTable from './Components/DatabaseTable';
import ConnectDatabase from './Components/ConnectDatabase';
import UsersTable from './Components/UsersTable';
import { oneclick } from '../../oneclick';
import { getuserdata } from '../../services/apiService';
import { use } from 'react';
import database_id from '../../utils/databaseConfig';

const RealTimeDataBase = () => {
    const [searchState, setSearchState] = useState({ searchTerm: '', searchUsers: '' });
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [showUserData, setShowUserData] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showCreateDatabaseModal, setShowCreateDatabaseModal] = useState(false);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const [showConnectModal, setShowConnectModal] = useState(false);
    const [showConnectDatabaseModal, setShowConnectDatabaseModal] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalusers, settotalusers] = useState({});
    const [totaldatabases, settotaldatabases] = useState({});
    const [totalPages, setTotalPages] = useState(1);

    const tableSchema = database_id;

    useEffect(() => {
        setLoading(true);
        startPolling();

        return () => {
            if (oneclick.pollingInterval) {
                clearInterval(oneclick.pollingInterval);
            }
        };
    }, [searchState, showUserData, currentPage]);

    const startPolling = async () => {

        try {
            const user_id = await getuserdata("id");
            const company_id = await getuserdata("company_id");

            if (!user_id || !company_id) {
                console.error('User ID or Company ID not found.');
                setLoading(false);
                return;
            }

            const tableName = showUserData ? 'db_users' : 'db_databases';
            const conditions = { user_id, company_id };
            const searchKey = showUserData ? searchState.searchUsers : searchState.searchTerm;
            const search = searchKey ? (showUserData ? { db_username: searchKey } : { db_name: searchKey }) : {};
            const orderBy = 'id DESC';

            const callback = (data) => {
                if (data && data.success) {
                    const { data: fetchedData, totalPages: fetchedTotalPages } = data;
                    if (showUserData) {
                        setUserData(fetchedData);
                    } else {
                        setData(fetchedData);
                        const filteredData = fetchedData.filter(item => item.db_uid === null);
                        // Step 2: Iterate over the filtered data and make API requests
                        const updatePromises = filteredData.map(async (item) => {
                            try {
                                // Generate a timestamp for db_uid
                                const uniqueDbUid = `${Date.now()}_${item.id}`;
                        
                                // Data to be updated
                                const data = {
                                    db_uid: uniqueDbUid // Assign the generated timestamp
                                };
                        
                                // Define the condition based on your requirements
                                const condition = {
                                    id: item.id // Example: Update based on the item's ID
                                };
                        
                                // Perform the update
                                const response = await oneclick.update(tableSchema, "db_databases", data, condition);
                        
                                console.log(`Updated item ID ${item.id}:`, response.data);
                                return response.data; // Return the updated item data
                            } catch (error) {
                                console.error(`Error updating item ID ${item.id}:`, error);
                                throw error;
                            }
                        });
                    }
                    setTotalPages(fetchedTotalPages);
                } else {
                    console.error('Failed to fetch data:', data);
                }
                setLoading(false); // Hide loading indicator
            };

            const margedata = [
                {
                    "target_table": "db_users",
                    "target_column": "id",
                    "target_value": "dbuser_id",
                    "target_label": "db_user_data"
                }
            ];

            const pagination = { page: currentPage, limit: 20 }; // Define page size
            oneclick.startLongPolling(
                tableSchema,
                tableName,
                conditions,
                orderBy,
                search,
                callback,
                pagination,
                margedata
            );
            const userdatatotal = await oneclick.read(
                tableSchema,
                'db_users',
                conditions,
                orderBy,
                search,
                "",
                ""
            );
            if (userdatatotal.success) {
                settotalusers(userdatatotal.data);
            }
            const userdatabasesdata = await oneclick.read(
                tableSchema,
                'db_databases',
                conditions,
                orderBy,
                search,
                "",
                ""
            );
            if (userdatabasesdata.success) {
                settotaldatabases(userdatabasesdata.data);
            }
        } catch (error) {
            console.error('Error during polling:', error);
        } finally {
            setLoading(false); // Ensure loading indicator is hidden
        }
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchState((prev) => ({ ...prev, [name]: value }));
    };

    const handleToggleUserData = () => {
        setShowUserData((prev) => !prev);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // State to store visibility of passwords (all initially hidden)
    const [passwordVisibility, setPasswordVisibility] = useState([]);

    // Toggle the password visibility for a specific row
    const togglePasswordVisibility = (index) => {
        const updatedVisibility = [...passwordVisibility];
        updatedVisibility[index] = !updatedVisibility[index];
        setPasswordVisibility(updatedVisibility);
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="card mb-4">
                    <div className="card-header">
                        <h4 className="card-title">Realtime Database</h4>
                    </div>
                    <div className="card-filter">
                        <form>
                            <div className="filter-form">
                                <div className="filter-item">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        name={showUserData ? 'searchUsers' : 'searchTerm'}
                                        placeholder={showUserData ? 'Search user data' : 'Search database name'}
                                        className="form-control"
                                        value={showUserData ? searchState.searchUsers : searchState.searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <div className="filter-action">
                                    <button
                                        className="i-btn primary--btn btn--md text-white"
                                        type="button"
                                        onClick={handleToggleUserData}
                                    >
                                        {showUserData ? (
                                            <i class='fas fa-toggle-off'></i>)
                                            : (<i class='fas fa-toggle-on'></i>)
                                        }
                                        {showUserData ? 'Show Databases' : 'Show Users'}
                                    </button>
                                    <button
                                        className="i-btn primary--btn btn--md text-white"
                                        type="button"
                                        onClick={() => setShowConnectDatabaseModal(true)}
                                    > <i class='fas fa-tools'></i>
                                        Connect Databases
                                    </button>
                                    <button
                                        className="i-btn danger--btn btn--md text-white"
                                        type="button"
                                        onClick={() => setShowConnectModal(true)}
                                    >
                                        Connect
                                    </button>
                                    <button
                                        type="button"
                                        className="i-btn primary--btn btn--md text-white"
                                        onClick={() => setShowCreateDatabaseModal(true)}
                                    >
                                        Create Database
                                    </button>
                                    <button
                                        type="button"
                                        className="i-btn primary--btn btn--md text-white"
                                        onClick={() => setShowCreateUserModal(true)}
                                    >
                                        Create User
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    {showUserData ? (
                        <UsersTable
                            currentData={userData}
                            passwordVisibility={passwordVisibility}
                            togglePasswordVisibility={togglePasswordVisibility}
                            Link={Link}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                            currentPage={currentPage}
                            loading={loading}
                        />
                    ) : (
                        <DatabaseTable
                            currentData={data}
                            passwordVisibility={passwordVisibility}
                            togglePasswordVisibility={togglePasswordVisibility}
                            Link={Link}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                            currentPage={currentPage}
                            loading={loading}
                        />
                    )}
                </div>
            </section>

            {/* Modals */}
            <AddDatabaseModal show={showCreateDatabaseModal} handleClose={() => setShowCreateDatabaseModal(false)} />
            <AddUserModal show={showCreateUserModal} handleClose={() => setShowCreateUserModal(false)} />
            <ConnectModal show={showConnectModal} handleClose={() => setShowConnectModal(false)} databases={totaldatabases} users={totalusers} />
            <ConnectDatabase show={showConnectDatabaseModal} handleClose={() => setShowConnectDatabaseModal(false)} />
        </div>
    );
};

export default RealTimeDataBase;
