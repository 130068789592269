import React, { useEffect, useState } from 'react';
import { Button, Table, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import AddBranchModal from "./Components/AddBranchModal"; // Adjust path as needed
import { getcompanydata, getbranchdata } from "../../services/apiService";
import AddRoleModal from "./Components/AddRoleModal"; // Adjust path as needed
import { oneclick } from '../../oneclick';
import EditRoleModal from "./Components/EditRoleModal"; // Adjust path as needed
import database_id from '../../utils/databaseConfig';

const Roles = () => {
    const [branches, setBranches] = useState([]);
    const [branches11, setBranches11] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [allpermissionsdata, setallpermissions] = useState({});
    const [showAddModal, setShowAddModal] = useState(false); // Modal visibility
    const [showAddModal11, setShowAddModal11] = useState(false); // Modal visibility
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Items per page
    const [filteredBranches, setFilteredBranches] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // For search functionality

    const navigate = useNavigate(); // Initialize navigate


    // Fetching company, branch, and employee data
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const token = localStorage.getItem('token');
        const newcompanydata = await getcompanydata(token);
        setCompanyData(newcompanydata);
        console.log('COMPANY DATA:', newcompanydata);
    
        const tableSchema = database_id;
        const tableNameBranches = "branches";
        const tableNameRoles = "roles";
        const tableNameEmployees = "employees";
    
        const conditions = { "company_id": newcompanydata.id };
        const orderBy = "id DESC";
    
        // Fetch all roles
        const allroles11 = await oneclick.read(tableSchema, tableNameRoles, conditions, orderBy, "");
        const allroles = allroles11.data;
    
        // Fetch all branches
        const allBranches11 = await oneclick.read(tableSchema, tableNameBranches, conditions, orderBy, "");
        const allBranches = allBranches11.data;
        console.log('allBranches:', allBranches);
        setBranches11(allBranches);
    
        // Fetch all employees
        const allEmployees11 = await oneclick.read(tableSchema, tableNameEmployees, conditions, orderBy, "");
        const allEmployees = allEmployees11.data;

        // Fetch all employees
        const allpermissions = await oneclick.read(tableSchema, "permissions", "", orderBy, "");
        const allpermissionsdata = allpermissions.data;

        setallpermissions(allpermissionsdata);

    

        console.log('allpermissionsdata', allpermissionsdata);
    
        // Combine roles with branch data and calculate employee count for each role
        const rolesWithBranchAndEmployeeData = allroles.map(role => {
            const branchDATA = allBranches.find(branch => String(branch.id) === String(role.branch_id));
    
            console.log('ROLE branch_id:', role.branch_id);
            console.log('BRANCH IDs:', allBranches.map(branch => branch.id));
            console.log('FILTERS:', branchDATA);
    
            // Count employees associated with the current role_id
            const employeeCount = allEmployees.filter(employee => employee.role_id === role.id).length;
    
            // Check if permission data is valid before parsing
            const permissionsData = allpermissionsdata.permissions ? JSON.parse(allpermissionsdata.permissions) : [];

            //permissionsData some data 
    
            return { 
                ...role, 
                branchDATA, 
                employeeCount
            };
        });
    
        setBranches(rolesWithBranchAndEmployeeData);
        setFilteredBranches(rolesWithBranchAndEmployeeData);
    };
    

    // Handle search
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        // Filter branches based on role name or branch name
        const filtered = branches.filter(branch =>
            branch.name.toLowerCase().includes(value) || branch.branch?.name.toLowerCase().includes(value)
        );
        setFilteredBranches(filtered);
        setCurrentPage(1); // Reset to first page when searching
    };

    // Calculate total pages
    const totalPages = Math.ceil(filteredBranches.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null); // Store selected role for editing

    const handleEditClick = (role) => {
        setSelectedRole(role); // Set the role to be edited
        setShowEditModal(true); // Open the edit modal
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setSelectedRole(null); // Clear the selected role
        fetchData(); // Refresh the data
    };


    // Open AddBranchModal
    const handleAddBranch = () => {
        setShowAddModal(true);
    };

    const handleAddRole = () => {
        setShowAddModal11(true);
    };

    const handleCloseModal = () => {
        setShowAddModal(false);
    };

    const handleCloseModal11 = () => {
        setShowAddModal11(false);
        fetchData();
    };


    // Get current branches for the page
    const currentBranches = filteredBranches.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const deleteRole = async (roleId) => {
        const token = localStorage.getItem('token');
        const tableSchema = database_id;
        const tableNameRoles = "roles";
        const deleteCondition = { id: roleId };
    
        const response = await oneclick.delete(tableSchema, tableNameRoles, deleteCondition);
        if (response.success) {
            alert('Role deleted successfully.');
            fetchData(); // Refresh the data after deletion
        } else {
            alert(response.error || 'Error deleting role.');
        }
    };

    return (
        <div className="dashboard_container">
            <div className="inner-content">
                <section>
                    <div className="card mb-4">
                        <h4 className="card-title" style={{marginTop: '10px',marginLeft: '18px'}}>Role List</h4>
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div style={{ flex: 1, marginRight: '10px' }}>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Search with User, Email"
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </div>
                            <Button className="btn btn-primary" onClick={handleAddRole}>Add New Role</Button>
                        </div>

                        <div className="card-body px-0">
                            

                            <div className="responsive-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Role Name</th>
                                            <th>Branch Name</th>
                                            <th>No. Staff</th>
                                            <th>Permissions</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {branches.length === 0 ? ( // Check if there are no permissions
                                            <tr>
                                                <td colSpan={4} style={{ textAlign: 'center' }}>
                                                    No data found
                                                </td>
                                            </tr>
                                        ) : currentBranches.map((branch, index) => (
                                            <tr key={branch.id}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{branch.name}</td> {/* Role name */}
                                                <td>{branch.branchDATA?.name}</td> {/* Branch name */}
                                                <td>{branch.employeeCount}</td> {/* Employee count for this role */}
                                                <td>
                                                    {Array.isArray(allpermissionsdata) && allpermissionsdata.length > 0 ? (
                                                        allpermissionsdata.map((permissionData, index) => {
                                                            // Parse the permissions string into an object
                                                            const parsedPermissions = JSON.parse(permissionData.permissions);

                                                            // Filter permGroup to only include groups with matching permissions
                                                            const filteredPermGroups = parsedPermissions.filter(permGroup =>
                                                                permGroup.permissions.some(perm => (branch.permission || []).includes(perm.id))
                                                            );
                                                            
                                                            // Only show permission groups if there are any matches
                                                            return filteredPermGroups.length > 0 ? (
                                                                <div key={index}>
                                                                    <strong>{permissionData.name}:</strong>
                                                                    {filteredPermGroups.map((permGroup, permIndex) => (
                                                                        <div key={permIndex} style={{marginBottom: '5px'}}>
                                                                            <strong style={{ marginLeft: '10px' }}>{permGroup.subName} : </strong>
                                                                            {permGroup.permissions.map((perm, permInnerIndex) => {
                                                                                const highlightIds = branch.permission || []; // Default to empty array if undefined
                                                                                const isHighlighted = highlightIds.includes(perm.id); // Check if perm.id exists in branch.permission

                                                                                return isHighlighted ? (
                                                                                    <span
                                                                                        key={permInnerIndex}
                                                                                        style={{
                                                                                            backgroundColor: 'var(--primary-color)',
                                                                                            color: 'white',
                                                                                            padding: '2px 5px',
                                                                                            borderRadius: '4px',
                                                                                            marginRight: '5px',
                                                                                        }}
                                                                                    >
                                                                                        {perm.name}
                                                                                        {permInnerIndex < permGroup.permissions.length - 1 ? ' ' : ''}
                                                                                    </span>
                                                                                ) : null;
                                                                            })}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : null; // Hide if no matching permissions in the group
                                                        })
                                                    ) : (
                                                        'No Permissions'
                                                    )}
                                                </td>

                                                <td style={{ gap: '10px', display: 'flex' }}>
                                                    <Button variant="primary" onClick={() => handleEditClick(branch)}>Edit</Button>
                                                    <Button variant="danger" onClick={() => deleteRole(branch.id)}>Delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>

                            {/* Pagination Controls */}
                            <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Button
                                        key={index + 1}
                                        variant="outline-primary"
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{ margin: '0 5px' }}
                                        active={currentPage === index + 1}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Add New Role Modal */}
                {showAddModal11 && <AddRoleModal show={showAddModal11} handleClose={handleCloseModal11} companyId={companyData.id} branches={branches11} />}
                {showEditModal && selectedRole && (
                <EditRoleModal
                    show={showEditModal}
                    handleClose={handleCloseEditModal}
                    roleData={selectedRole} // Pass the selected role data
                    branches={branches11} // Pass branches data
                />
            )}
            </div>
        </div>
    );
};

export default Roles;
