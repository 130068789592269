import React from "react";
import { TailSpin } from "react-loader-spinner"; // Optional: If using react-loader-spinner

const LoadingScreen = () => {
  return (
    <div style={styles.loaderContainer}>
      {/* Optional: Spinner from react-loader-spinner */}
      <TailSpin height="80" width="80" color="#260055" ariaLabel="loading" />
      
      {/* Simple loading text */}
      <p style={styles.loadingText}>Loading...</p>
    </div>
  );
};

// Inline styles
const styles = {
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: "#f9f9f9",
  },
  loadingText: {
    marginTop: "20px",
    fontSize: "20px",
    color: "#333",
    fontFamily: "Arial, sans-serif",
  },
};

export default LoadingScreen;
