import React, { useEffect, useState } from 'react';
import { Button, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { getcompanydata } from "../../services/apiService";
import AddPermissionsModal from './Component/AddPermissionsModal';
import EditPermissionsModal from './Component/EditPermissionsModal';
import { oneclick } from '../../oneclick';
import database_id from '../../utils/databaseConfig';

const Permissions = () => {
    const [permissionsData, setPermissionsData] = useState([]);
    const [companyData, setCompanyData] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState({});

    const navigate = useNavigate(); // Initialize navigate

    // Function to fetch company and permissions data
    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const newcompanydata = await getcompanydata(token);
            setCompanyData(newcompanydata);
            console.log('COMPANY DATA:', newcompanydata);

            const tableSchema = database_id;
            const tableNamePermissions = "permissions";
            const conditions = { "company_id": newcompanydata.id };
            const orderBy = "id DESC";

            // Fetch permissions
            const allPermissions11 = await oneclick.read(tableSchema, tableNamePermissions, "", orderBy, "");
            const allPermissions = allPermissions11.data;
            console.log('allPermissions:', allPermissions);

            setPermissionsData(allPermissions); // Store fetched permissions
        } catch (error) {
            console.error('Error fetching data:', error); // Log the error
            alert('Error fetching permissions data.'); // Optional: Notify user
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchData();
    }, []);

    // Handle opening the edit modal
    const handleEditPermission = (permission) => {
        setSelectedPermission(permission);
        setShowEditModal(true);
        console.log('handleEditPermission:', selectedPermission);
    };

    // Close modal function and refresh data
    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setSelectedPermission(); // Reset selected permission
        fetchData(); // Re-fetch permissions data
    };

    // Close Add Permissions Modal and refresh data
    const handleCloseAddModal = () => {
        setShowAddModal(false);
        fetchData(); // Re-fetch permissions data
    };

    // Handle search
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        // Filter permissions based on permission name
        const filtered = permissionsData.filter(permission =>
            permission.name.toLowerCase().includes(value)
        );
        setPermissionsData(filtered);
        setCurrentPage(1); // Reset to first page when searching
    };

    // Calculate total pages
    const totalPages = Math.ceil(permissionsData.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    // Get current permissions for the page
    const currentPermissions = permissionsData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Handle permission deletion
    const handleDeletePermission = async (id) => {
        if (window.confirm("Are you sure you want to delete this permission?")) {
            const token = localStorage.getItem('token');
            const tableSchema = database_id;
            const tableName = "permissions";
            const condition = { 'id': id };

            try {
                const update = await oneclick.delete(tableSchema, tableName, condition);
                if (update.success) {
                    fetchData(); // Re-fetch permissions data
                } else {
                    alert('Error deleting permission: ' + update.message);
                }
            } catch (error) {
                console.error('Error deleting permission:', error);
                alert('Error deleting permission.');
            }
        }
    };

    return (
        <div className="dashboard_container">
            <div className="inner-content">
                <section>
                    <div className="card mb-4">
                        <h4 className="card-title" style={{marginTop: '10px',marginLeft: '18px'}}>Permissions List</h4>
                        <div className="card-header d-flex justify-content-between align-items-center">
                            
                            <div style={{ flex: 1, marginRight: '10px' }}>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Search with User, Email"
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </div>
                            <Button className="btn btn-primary" onClick={() => setShowAddModal(true)}>Add New Permission</Button>
                        </div>

                        <div className="card-body px-0">
                            

                            <div className="responsive-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Permission Name</th>
                                            <th>Permissions</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {permissionsData.length === 0 ? ( // Check if there are no permissions
                                            <tr>
                                                <td colSpan={4} style={{ textAlign: 'center' }}>
                                                    No data found
                                                </td>
                                            </tr>
                                        ) : currentPermissions.map((permission, index) => (
                                            <tr key={permission.id}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{permission.name}</td>
                                                <td>
                                                    {Array.isArray(JSON.parse(permission.permissions)) ? (
                                                        JSON.parse(permission.permissions).map((perm, index) => (
                                                            <div key={index}>
                                                                <strong>{perm.subName} : </strong> 
                                                                {perm.permissions && perm.permissions.length > 0 ? (
                                                                    Object.values(perm.permissions).map((child, childIndex) => (
                                                                        <span key={childIndex}>{child.name}</span>
                                                                    )).reduce((prev, curr) => [prev, ', ', curr]) // To format as "View, Edit, Delete"
                                                                ) : (
                                                                    'No Permissions'
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        'No Permissions'
                                                    )}
                                                </td>

                                                <td style={{ gap: '10px', display: 'flex' }}>
                                                    <Button variant="primary" onClick={() => handleEditPermission(permission)}>Edit</Button>
                                                    <Button variant="danger" onClick={() => handleDeletePermission(permission.id)} className="btn-danger">Delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination Controls */}
                            <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Button
                                        key={index + 1}
                                        variant="outline-primary"
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{ margin: '0 5px' }}
                                        active={currentPage === index + 1}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Modals */}
            <AddPermissionsModal
                show={showAddModal}
                handleClose={handleCloseAddModal}
            />
            <EditPermissionsModal
                show={showEditModal}
                handleClose={handleCloseEditModal}
                permissionData={selectedPermission} // Ensure permission is always an object
            />
        </div>
    );
};

export default Permissions;
