// UpdateCurrencyModal.js
import React from 'react';

const UpdateCurrencyModal = ({ generaldata, showModal, handleClose, currency, handleUpdateCurrency }) => {
  if (!showModal) return null;

  return (
    <div className="modal fade show" id="update-currency" tabIndex="-1" role="dialog" aria-modal="true" style={{ display: 'block', background: '#00000078' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleUpdateCurrency}>
            <input type="hidden" name="_token" value="your_csrf_token_here" />
            <input type="hidden" name="id" value={currency?.id} />
            <div className="modal-body">
              <div className="card">
                <div className="card-header bg--lite--violet">
                  <div className="card-title text-center text--light">Update Currency</div>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name <sup className="text--danger">*</sup></label>
                    <input type="text" className="form-control" id="name" name="name" defaultValue={currency?.name} placeholder="Enter Name" required />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="symbol" className="form-label">Symbol <sup className="text--danger">*</sup></label>
                    <input type="text" className="form-control" id="symbol" name="symbol" defaultValue={currency?.symbol} placeholder="Enter Symbol" required />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="rate" className="form-label">Exchange Rate <sup className="text--danger">*</sup></label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">1 {generaldata.currency_name} = </span>
                      <input 
                        type="text" 
                        id="rate" 
                        name="rate" 
                        className="form-control" 
                        defaultValue={currency.rate} // Parse the rate safely
                        placeholder="0.00" 
                        aria-label="Username" 
                        aria-describedby="basic-addon1" 
                        required 
                    />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">Status <sup className="text--danger">*</sup></label>
                    <select className="form-control" name="status" id="status" defaultValue={currency?.status === '1' ? '1' : '2'} required>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="i-btn primary--btn btn--md" onClick={handleClose}>Cancel</button>
              <button type="submit" className="i-btn success--btn btn--md">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateCurrencyModal;
