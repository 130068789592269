import React, { useEffect, useState } from "react";
import { oneclick } from "../../../oneclick";
import database_id from "../../../utils/databaseConfig";
import { getuserdata } from "../../../services/apiService";
import SocketConnection from "../../../utils/Socketio";
import { a } from "react-spring";

const QRTableList = ({ searchinput, filter, currentPage, setTotalPages, setShowQR, setSelectQRData, loadqrdata }) => {

    const [qrTableList, setQRTableList] = useState([]);
    const itemsPerPage = 10;


    useEffect(() => {
        getQRTableList();
    }, [searchinput, filter, currentPage, loadqrdata]);

    const getQRTableList = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "whatsapp_qr_device";
            const conditions = {
                company_id: await getuserdata('company_id'),
                ...(filter && { status: filter }),
            };
            const orderBy = "id DESC";

            const search = searchinput && { name: searchinput }; // Search condition
            const pagination = {
                page: currentPage,
                limit: 10,
            };
            const margedata = {};
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

            if (response.success) {
                console.log("QRTableList:", response);
                setQRTableList(response.data);
                setTotalPages(response.totalPages);
            } else {
                console.error("Error in getQRTableList:", response.message);
            }
        } catch (error) {
            console.error("Exception in getQRTableList:", error);
        }
    };

    const updateStatus = async (datavalues) => {
        const tableSchema = database_id;
        const tableName = "whatsapp_qr_device";
        const data = { status: 'inactive' };
        const condition = { device_name: datavalues.device_name };
        const updateStatus = await oneclick.update(tableSchema, tableName, data, condition);
        if (updateStatus.success) {
            console.log("Status Updated Successfully");
            alert("Status Updated Successfully");
            getQRTableList();
        } else {
            console.error("Error in updateStatus:", updateStatus.message);  
        }
    };

    const disconnectClient = (SocketConnection, data) => {
        SocketConnection.emit('disconnectClient', { clientId: data.device_name });
        SocketConnection.on(`disconnectClient_${data.device_name}`, (response) => {
            console.log(`disconnectClient_${data.device_name} :`, response);
            if (response.success) {
                updateStatus(data);
            }else{
                alert(response.error);
                console.error("Error in disconnectClient:", response.error);
            }
        });
    };

    return (
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Session Name</th>
                    <th>WhatsApp Number</th>
                    <th>Minimum Delay</th>
                    <th>Maximum Delay</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {qrTableList.length === 0 ? (
                    <tr>
                        <td colSpan="7" className="text-center">No data found</td>
                    </tr>
                ) : (
                    qrTableList.map((item, index) => (
                        <tr key={index}>
                            <td data-label="ID" className="">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                            <td data-label="Session Name" className="maxtable">{item.device_name}</td>
                            <td data-label="WhatsApp Number" className="maxtable">{item.whatsappNumber}</td>
                            <td data-label="Minimum Delay" className="maxtable">{item.min_delay}</td>
                            <td data-label="Maximum Delay" className="maxtable">{item.max_delay}</td>
                            <td data-label="Status">
                                <span className={`badge badge--${item.status === 'inactive' ? 'danger' : 'success'}`}>
                                    {item.status}
                                </span>
                            </td>
                            <td data-label="Actions">
                                <div className="button-group" style={{ display: 'flex', gap: '10px' }}>
                                    {item.status === 'inactive' ? (
                                        <button className="i-btn success--btn btn--md" onClick={() => {
                                            setShowQR(true);
                                            setSelectQRData(item);
                                        }}>
                                            <i className="fa fa-qrcode"></i>
                                        </button>
                                    ) : (
                                        <button className="i-btn success--btn btn--md" onClick={() => disconnectClient(SocketConnection, item)}>
                                            <i class="ri-wifi-off-fill"></i>
                                        </button>
                                    )}

                                    <button className="i-btn primary--btn btn--md">Edit</button>
                                    <button className="i-btn danger--btn btn--md">Delete</button>
                                </div>
                            </td>
                        </tr>
                    ))
                )}

            </tbody>
        </table>
    );
};

export default QRTableList;