import React, { useEffect, useState } from 'react';
import { OverviewContent, getImageBaseUrl } from '../../services/apiService';

const OverviewSection = () => {
    const [featureContent, setFeatureContent] = useState([]);
    const [totalUsers, setTotalUsers] = useState('');
    const [totalSubscribedUsers, setTotalSubscribedUsers] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

    useEffect(() => {
        setImageBaseUrl(getImageBaseUrl());
        const loadData = async () => {
            try {
                const content = await OverviewContent();

                setFeatureContent(content);
            } catch (error) {
                console.error('Failed to load data:', error);
                setError('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);


    // Destructuring
    const [firstContent] = featureContent;
    const { section_value: { heading = '', sub_heading = '', btn_left_name = '', btn_left_url = '', btn_right_name = '', btn_right_url = '', overview_image ='', overview_min_image ='', description ='', background_image = '' } = {} } = firstContent || {};
    return (
        <section className="get-start pt-100 pb-100">
            <div className="get-start-bg">
                <img src={`${imageBaseUrl}/assets/file/images/frontend/${background_image}`} alt="Background" />
            </div>

            <div className="container">
                <div className="get-start-container">
                    <div className="get-start-content">
                        <div className="section-header dark">
                            <div className="section-header-left">
                                <span className="sub-title">{sub_heading}</span>
                                <h3 className="section-title">
                                    {heading}
                                </h3>
                            </div>

                            <div className="section-header-right">
                                <p className="title-description">
                                    {description}
                                </p>
                            </div>
                        </div>

                        <div className="mt-4 d-flex align-items-center justify-content-center flex-wrap gap-4">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={btn_left_url}
                                className="ig-btn btn--lg btn--primary"
                            >
                                {btn_left_name}
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={btn_right_url}
                                className="ig-btn btn--lg btn--white-outline"
                            >
                                {btn_right_name}
                            </a>
                        </div>
                    </div>
                    <div className="get-start-dash">
                        <div>
                            <img src={`${imageBaseUrl}/assets/file/images/frontend/${overview_image}`} alt="Background" />
                        </div>
                        <div className="get-start-dash-sm">
                            <img src={`${imageBaseUrl}/assets/file/images/frontend/${overview_min_image}`} alt="Background" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;
