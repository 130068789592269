import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { general_settings, HeroSectionUsers, registerUser, getImageBaseUrl } from '../../services/apiService'; // Update import to your registration API function

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../assets/theme/global/css/line-awesome.min.css';
import '../../assets/theme/global/css/font_bootstrap-icons.css';
import '../../assets/theme/frontend/css/all.min.css';
import '../../assets/theme/frontend/css/default.css';
import '../../assets/theme/auth/css/style.css';
import '../../assets/theme/frontend/css/media.css';

const defaultImage = require('../../assets/file/images/default.jpg');

const Register = () => {
  const [name, setName] = useState(''); // Added name state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Added confirmPassword state
  const [featureContent, setFeatureContent] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password view
  const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setImageBaseUrl(getImageBaseUrl());
    // Check if token is present in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      // Redirect to dashboard if token exists
      navigate('/dashboard');
    } else {
      // If no token, load data
      const loadData = async () => {
        try {
          const content = await general_settings();
          const userscontent = await HeroSectionUsers();
          setFeatureContent(content[0] || {});
          setUsers(userscontent);

          console.log('users load data:', userscontent);
        } catch (error) {
          console.error('Failed to load data:', users);
          setError('Failed to load data');
        } finally {
          setLoading(false);
        }
      };

      loadData();
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (password !== confirmPassword) {
        toast.error('Passwords do not match', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        setIsSubmitting(false);
        return;
    }

    try {
        const data = await registerUser(name, email, password); // Update to your registration function

        if (data.success) {
            console.log('Registration successful:', data);
            // Display success toast message
            toast.success('Registration successful! Please check your email to verify your account.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            // Redirect to login page after successful registration
            localStorage.setItem('token', data.token);
            navigate('/login');
        } else {
            console.error('Registration failed:', data.message); // Corrected from 'error' to 'data.message'
            // Display error toast message
            toast.error(`Registration failed: ${data.message}`, { // Added message dynamically
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    } catch (error) {
        
        // Display error toast message
        const newerror = error;
        console.error('Registration failed new :', newerror);
        toast.error(`Registration failed: ${error.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    } finally {
        setIsSubmitting(false);
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="login-page-container">
      <div className="container-fluid p-0">
        <div className="row g-0 overflow-hidden">
          <div className="col-xl-5 col-lg-6">
            <div className="login-left-section d-flex align-items-center justify-content-center">
              <div className="form-container">
                <div className="mb-5">
                  <a href="/" className="site-logo">
                    <img src={`${imageBaseUrl}/assets/file/images/logoIcon/${featureContent.panel_logo}`} className="logo-sm" alt="Site Logo" />
                  </a>
                  <h4>Register With <span className="site--title">{featureContent.site_title || 'Default Title'}</span></h4>
                </div>

                <form onSubmit={handleSubmit} id="register-form">
                  <input type="hidden" name="_token" value="YOUR_CSRF_TOKEN" />
                  
                  <div className="my-3">
                    <label htmlFor="name" className="form-label d-block">Name</label>
                    <div className="input-field">
                      <span><i className="las la-user"></i></span>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        placeholder="Enter your name"
                        id="name"
                        aria-describedby="nameHelp"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="my-3">
                    <label htmlFor="email" className="form-label d-block">Email address</label>
                    <div className="input-field">
                      <span><i className="las la-envelope"></i></span>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Enter your email"
                        id="email"
                        aria-describedby="emailHelp"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="password" className="form-label d-block">Password</label>
                    <div className="input-field">
                      <span><i className="las la-lock"></i></span>
                      <input
                        type={showPassword ? 'text' : 'password'} // Toggle between password and text
                        name="password"
                        placeholder="Enter your password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                        {showPassword ? <i className="las la-eye-slash"></i> : <i className="las la-eye"></i>}
                      </span>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="confirm-password" className="form-label d-block">Confirm Password</label>
                    <div className="input-field">
                      <span><i className="las la-lock"></i></span>
                      <input
                        type={showPassword ? 'text' : 'password'} // Toggle between password and text
                        name="confirm_password"
                        placeholder="Confirm your password"
                        id="confirm-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                        {showPassword ? <i className="las la-eye-slash"></i> : <i className="las la-eye"></i>}
                      </span>
                    </div>
                  </div>

                  <div>
                    <button className="btn btn-md btn--primary w-100" type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Loading...' : 'Register'}
                    </button>
                  </div>

                  <div className="mb-4">
                    <Link to="/login">Already have an account? Login</Link>
                  </div>
                </form>

                <p className="text-center mt-4">
                  New To <Link to="/register">Sign Up!</Link>
                </p>

                <div className="mt-5"></div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6">
            <div className="login-right-section responsive-padding bg-purple d-flex align-items-center justify-content-center">
              <div className="login-right-content">
                <h1>Welcome to {featureContent.site_title || 'Getone Click'}</h1>
                <p>{featureContent.description || 'Default description'}</p>
                <div className="users">
                  Our mass SMS and Email service provide you to reach more clients and engage, and also you can fill your target with potential customers based on different types of products and services. So why wait? If you don't have an account, sign up quickly and start today with the best and cheapest SMS cost!
                </div>
                <div className="avatar-group">
                  {users.slice(0, 10).map((user, index) => (
                    <div className="avatar-group-item" key={index}>
                      <img
                        src={user.image ? `assets/file/images/user/profile/${user.image}` : defaultImage}
                        alt={user.name || 'User Avatar'}
                        className="w-100 h-100"
                      />
                    </div>
                  ))}
                  <div className="avatar-group-item">
                    <span>{users.length}+ </span>
                  </div>
                </div>
                <span>Over 1K people using this app. Smooth SMS and Email Marketing tools</span>
                <div className="text-start mt-5">
                  <a href="/" className="btn btn-sm btn--primary d-flex align-items-center justify-content-center gap-2 lh-1 back-to-home">
                    <i className="las la-long-arrow-alt-left fs-3"></i> Back To Home
                  </a>
                </div>
              </div>
              <div className="user-login-bg">
                <img src="https://img.freepik.com/free-vector/watercolor-stains-abstract-background_23-2149107181.jpg?w=1380&amp;t=st=1697876324~exp=1697876924~hmac=e56ceb0be0b7f2e52411a3b44051683e739d9a769e5a39321506a064ef9b7a4b" alt="Background" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Include ToastContainer */}
      <ToastContainer
        position="top-right" // Ensure this is set to a string
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Register;
