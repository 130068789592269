import React, { useState, useEffect } from 'react';
import { general_settings, HeroSectionsocialIconsContent, getImageBaseUrl } from '../../services/apiService';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [data, setData] = useState(null);
    const [featureContent, setFeatureContent] = useState({});
    const [socialIconsContent, setsocialIconsContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSticky, setIsSticky] = useState(false);
    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

    useEffect(() => {
        setImageBaseUrl(getImageBaseUrl());
        const loadData = async () => {
          try {
            const content = await general_settings();
            const socialIconsnew = await HeroSectionsocialIconsContent();
            setFeatureContent(content[0] || {}); // Assuming content is an array, and we want the first item
            setsocialIconsContent(socialIconsnew);
            console.log('Logo load data:', content);
          } catch (error) {
            console.error('Failed to load data:', error);
            setError('Failed to load data');
          } finally {
          }
        };
    
        loadData();
      }, []);
    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row g-0 justify-content-center">
                        <div className="col-xl-7 col-lg-8">
                            <div className="footer-item">
                                <div className="footer-info">
                                    <a href="#" className="footer-log">
                                        <img src={`${imageBaseUrl}/assets/file/images/logoIcon/${featureContent.panel_logo}`} alt="Site Logo" />
                                    </a>
                                    <p>Ensuring reliability and transparency, our footer displays real-time server status, location, and backup policy.</p>
                                </div>

                                <div className="d-flex align-items-center justify-content-center gap-2 mt-5">
                                    {socialIconsContent.map((icon, index) => (
                                        <a key={index} vtarget="_blank" rel="noopener noreferrer" href={icon.section_value.url} className="footer-social">
                                            <span className="" dangerouslySetInnerHTML={{ __html: icon.section_value.social_icon }} />
                                        </a>
                                    ))}
                                </div>

                                <ul className="mt-5 footer-menus">
                                    <li>
                                        <Link to="/" className="footer-menu">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about" className="footer-menu">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/features" className="footer-menu">Features</Link>
                                    </li>
                                    <li>
                                        <Link to="/pricing" className="footer-menu">Pricing</Link>
                                    </li>
                                    <li>
                                        <Link to="/faq" className="footer-menu">Faq's</Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <div className="d-flex align-items-center flex-column-reverse flex-md-row justify-content-between text-center gap-4">
                        <div className="copyright">
                            <p>© All Rights Reserved | {featureContent.copyright}</p>
                        </div>

                        <ul className="footer-menus">
                            <li>
                                <Link rel="noopener noreferrer" to="/privacy-policy" className="footer-menu">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link rel="noopener noreferrer" to="/terms-and-conditions" className="footer-menu">
                                    Terms and Conditions
                                </Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

            <div className="footer-bg">
                <img src={`${imageBaseUrl}/assets/file/images/frontend/65d73a5559d611708603989.png`} alt="Footer Background" />
            </div>
        </footer>
    );
};

export default Footer;
