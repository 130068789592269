import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import { oneclick } from "../../oneclick";
import database_id from "../../utils/databaseConfig";
import ImageComponent from "../../utils/ImageComponent";
import AddAiStaff from "./Components/AddAiStaff";
import { decodeBase64, encodeBase64 } from "../../services/apiService";
import Paginationnew from "../../utils/Paginationnew";
import ChatContainer from "./Components/ChatContainer";
import AiTriningChat from "./Components/AiTriningChat";

const AiStaffTrining = ({}) => {

    const [team, setTeam] = useState([]);
    const [staff, setStaff] = useState([]);
    const [training, setTraining] = useState([]);
    const [totalPagestraining, setTotalPagestraining] = useState(1);
    const [searchinput, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [filterdeportment, setfilterdeportment] = useState('');
    const [showAdddepartmentModal, setShowAdddepartmentModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [emailData, setEmailData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [userprofile, setUserprofile] = useState({});
    const [profile_image, setProfile_image] = useState('');
    const { teamdata } = useParams(); // Retrieve the URL parameter
    const [selecttabe, setSelecttabe] = useState('teamoverview');
    const [showAddStaffModal, setShowAddStaffModal] = useState(false);
    const itemsPerPage = 10;
    const [selectdepartment, setSelectdepartment] = useState('');
    const [showEditdepartmentModal, setShowEditdepartmentModal] = useState(false);
    const [typingstaff, settypingstaff] = useState('');


    useEffect(() => {
        fetchTeam();
        fetchStaff();
        fetchTeamDepartment();
        trainingdata();
    }, []);

    const trainingdata = async () => {
        try {
            setIsLoading(true);
            const tableSchema = database_id;
            const tableName = 'ai_training';
            const conditions = { team_id: decodeBase64(teamdata) };
            const orderBy = "id ASC";
            const search = {};
            const pagination = { page: currentPage, limit: itemsPerPage };
            const margedata = [];

            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
            if (response.success) {
                setTraining(response.data);
                setTotalPagestraining(response.totalPages);
            } else {
                console.error('Error fetching training:', response.error);
                alert('Sorry, we could not fetch the training data.');
            }
        } catch (error) {
            console.error('Error fetching training:', error);
            alert('An unexpected error occurred while fetching the training data.');
        } finally {
            setIsLoading(false);
        }
    };



    const fetchTeam = async () => {

        setIsLoading(true);

        const tableSchema = database_id;
        const tableName = 'ai_teams';
        const conditions = { id: decodeBase64(teamdata) };
        const orderBy = "id ASC";
        const search = {};
        const pagination = {};
        const margedata = [
            {
                target_label: "ai_team_department",
                target_table: "ai_staff",
                target_column: "team_id",
                target_value: "id"
            }
        ];

        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

        console.log('response:', response);
        if (response.success) {
            setTeam(response.data[0]);
            setIsLoading(false);
        } else {
            console.error('Error fetching team:', response.error);
            setIsLoading(false);
            alert('Sorry, we could not fetch the team.');
        }
    };

    const fetchTeamDepartment = async () => {
        setIsLoading(true);

        const tableSchema = database_id;
        const tableName = 'departments';
        const conditions = { id: team.department_id };
        const orderBy = "id ASC";
        const search = {};
        const pagination = {};
        const margedata = [];

        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

        console.log('response department:', response);
        if (response.success) {
            setEmailData(response.data);
            setIsLoading(false);
        } else {
            console.error('Error fetching team department:', response.error);
            setIsLoading(false);
            alert('Sorry, we could not fetch the team department.');
        }
    };

    const fetchStaff = async () => {

        setIsLoading(true);

        const tableSchema = database_id;
        const tableName = 'ai_staff';
        const conditions = { team_id: decodeBase64(teamdata) };
        const orderBy = "id ASC";
        const search = {};
        const pagination = {};
        const margedata = [
            {
                "target_label": "images",
                "target_table": "files",
                "target_column": "id",
                "target_value": "profile_image"
            },
            {
                "target_label": "departments",
                "target_table": "departments",
                "target_column": "id",
                "target_value": "department_id"
            },
            {
                "target_label": "team",
                "target_table": "ai_teams",
                "target_column": "id",
                "target_value": "team_id"
            }
        ];


        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

        console.log('response:', response);
        if (response.success) {
            setStaff(response.data);
            setIsLoading(false);
        } else {
            console.error('Error fetching staff:', response.error);
            setIsLoading(false);
            alert('Sorry, we could not fetch the staff.');
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        fetchStaff();
    };

    const handleEdit = (data) => {
        console.log(`Edit campaign with: ${data}`);
        setSelectdepartment(data);
        console.log('Select Department:', selectdepartment);
        setShowEditdepartmentModal(true);
    }

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const navigate = useNavigate();

    const handletrining = () => {
        console.log('Training');

        // Redirect to the training page
        navigate(`/ai/AiStaffTraining/${encodeBase64(team.id)}`);
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <ImageComponent fileid={team.image} width={50} height={50} borderRadius={50} boxShadow="" />
                                </div>
                                <br />
                                <div>
                                    <h4 className="card-title" style={{ marginLeft: '10px' }}>{team.name}</h4>
                                    <p className="card-subtitle" style={{ marginLeft: '10px', fontSize: '15px' }}>No. Staff _ {staff.length}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>
                                                <button
                                                    type="button"
                                                    className={`btn ${selecttabe == 'staffoverview' && 'primary--btn text-white'} btn--md text-black`}
                                                    onClick={() => setSelecttabe('staffoverview')}
                                                >
                                                    Staff Overview
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className={`btn ${selecttabe == 'teamtraining' && 'primary--btn text-white'} btn--md text-black`}
                                                    onClick={() => setSelecttabe('teamtraining')}
                                                >
                                                    Team Training
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className={`btn ${selecttabe == 'teamstafftests' && 'primary--btn text-white'} btn--md text-black`}
                                                    onClick={() => setSelecttabe('teamstafftests')}
                                                >
                                                    Team Staff Tests
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>

                        {selecttabe == 'staffoverview' && (
                            <div className="card-body px-0">
                                <div className="responsive-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Profile</th>
                                                <th>Name</th>
                                                <th>Team</th>
                                                <th>Department</th>
                                                <th>Job Description</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {staff.length > 0 ? (
                                                staff.map((email, index) => (
                                                    <tr
                                                        key={email.id}
                                                        onClick={() => navigate(`/ai/chat/${encodeBase64(email.id)}/full/false`)} // Use navigate for dynamic routing
                                                        style={{ cursor: 'pointer' }} // Make row clickable
                                                    >
                                                        <td data-label="ID" className="maxtable">
                                                            {(currentPage - 1) * itemsPerPage + index + 1}
                                                        </td>
                                                        <td className="maxtable" style={{ paddingLeft: '15px', paddingRight: '10px' }}>
                                                            <ImageComponent
                                                                fileid={email.images?.id || 0} // Fallback if `email.images` is undefined
                                                                width={50}
                                                                height={50}
                                                                borderRadius={50}
                                                                boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                                            />
                                                        </td>
                                                        <td data-label="Name" className="maxtable">
                                                            {email.first_name} {email.last_name}
                                                        </td>
                                                        <td data-label="Team" className="maxtable">
                                                            {email.team?.name || "N/A"} {/* Handle null `team` */}
                                                        </td>
                                                        <td data-label="Department" className="maxtable">
                                                            {email.departments?.name || "N/A"} {/* Handle null `departments` */}
                                                        </td>
                                                        <td data-label="Description" className="maxtable">
                                                            {email.job_description}
                                                        </td>
                                                        <td data-label="Status" className="maxtable">
                                                            <span
                                                                className={`badge badge--${email.status === "Active" ? "success" : "danger"}`}
                                                            >
                                                                {email.status}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className="text-muted text-center" colSpan="8">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                                <Paginationnew currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />

                            </div>
                        )}

                        {selecttabe == 'teamtraining' && (
                            <div className="card-body">
                                <div className="table-responsive d-flex ">
                                    <div className="col-md-4 col-lg-4 col-sm-12" style={{ paddingRight: '10px' }}>
                                        {staff.length > 0 ? (
                                            staff.map((email, index) => (
                                                <div className="card md-3" style={{ marginBottom: '10px' }}>
                                                    <div className="card-body d-flex justify-content-between" style={{ padding: '10px' }}>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <ImageComponent
                                                                    fileid={email.images?.id || 0} // Fallback if `email.images` is undefined
                                                                    width={40}
                                                                    height={40}
                                                                    borderRadius={50}
                                                                    boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
                                                                />
                                                            </div>
                                                            <div>
                                                                <h4 className="card-title" style={{ marginLeft: '10px', fontSize: '15px' }}>{email.first_name} {email.last_name}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <button
                                                                type="button"
                                                                className="btn primary--btn text-white btn--md"
                                                                style={{ fontSize: '12px' }}
                                                                onClick={handletrining}
                                                            >
                                                                Reply
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-muted text-center">
                                                No Data Found
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-8 col-lg-8 col-sm-12">
                                        <AiTriningChat teamdata={teamdata} staff={staff} settypingstaff={settypingstaff} department_id={team.department_id} teamimage={team.image} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            {showAddStaffModal && (
                <AddAiStaff show={showAddStaffModal} hide={() => setShowAddStaffModal(false)} fetchEmailData={fetchStaff} Department={emailData} TeamData={team} />
            )}
        </div>
    );

};

export default AiStaffTrining;