import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Import Form for the dropdown
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { useSpring, animated } from '@react-spring/web'; // Import react-spring
import { HTML5Backend } from 'react-dnd-html5-backend';
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const DraggableGroupItem = ({ group, index, moveGroup }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'groupItem',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveGroup(draggedItem.index, index);
                draggedItem.index = index;
            }
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'groupItem',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    // Apply spring animation to the group item
    const springProps = useSpring({
        opacity: isDragging ? 0.5 : 1, // Fade out when dragging
        transform: isDragging ? 'scale(0.95)' : 'scale(1)', // Slightly scale down when dragging
        config: { tension: 250, friction: 20 }
    });

    // Attach drag and drop to the ref
    drag(drop(ref));

    return (
        <animated.div
            ref={ref}
            style={{
                ...springProps,
                padding: '10px',
                border: '1px solid gray',
                marginBottom: '5px',
                backgroundColor: isDragging ? '#ffcccb' : '#f0f0f0', // Change color while dragging
                cursor: 'move',
                userSelect: 'none'
            }}
        >
            {group.name}
        </animated.div>
    );
};

const MenuOrderModal = ({ show, handleClose, menus, allgroups, updateGroupOrder }) => {
    const [groupList, setGroupList] = useState(menus);
    const [selectedGroupId, setSelectedGroupId] = useState(null); // State to hold the selected group's ID

    // Reset the group list to the original when the modal opens
    useEffect(() => {
        setGroupList(menus);
    }, [menus]);

    const moveGroup = (fromIndex, toIndex) => {
        const updatedList = [...groupList];
        const [movedGroup] = updatedList.splice(fromIndex, 1); // Remove the item from the list
        updatedList.splice(toIndex, 0, movedGroup); // Insert the item at the new index
        setGroupList(updatedList);
    };

    const handleGroupChange = (e) => {
        const groupId = e.target.value;
        setSelectedGroupId(groupId); // Set the selected group ID
    };

    // Filter menus based on selected group
    const filteredMenus = selectedGroupId 
        ? groupList.filter(menu => menu.groupId === selectedGroupId) 
        : groupList;

    // Update function to save the new order to the database
    const handleSave = async () => {
        const tableSchema = database_id;
        const tableName = "menulist";

        // Prepare the new order
        const updatedGroupOrder = filteredMenus.map((group, index) => ({
            id: group.id,
            name: group.name,
            order_by: index + 1 // New position, starting from 1
        }));

        // Update each group position in the database
        for (let group of updatedGroupOrder) {
            const Conditions = { "id": group.id };
            const data = {
                order_by: group.order_by
            };
            await oneclick.update(tableSchema, tableName, data, Conditions);
        }

        handleClose(); // Close modal after saving
    };

    const handleReset = () => {
        setGroupList(menus); // Reset to original group order
    };

    return (
        <Modal show={show} onHide={() => { handleClose(); handleReset(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Reorder Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Dropdown to select a group */}
                <Form.Group>
                    <Form.Label>Select Group</Form.Label>
                    <Form.Control 
                        as="select" 
                        className='mb-4'
                        value={selectedGroupId || ""} 
                        onChange={handleGroupChange}
                    >
                        <option value="">Select Group</option>
                        {allgroups.map((group) => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                {/* Render the filtered menu items */}
                <DndProvider backend={HTML5Backend}>
                    {filteredMenus.map((group, index) => (
                        <DraggableGroupItem
                            key={group.id}
                            index={index}
                            group={group}
                            moveGroup={moveGroup}
                        />
                    ))}
                </DndProvider>

                {/* Display group names, IDs, and positions */}
                <div style={{ marginTop: '20px' }}>
                    <h5>Current Order:</h5>
                    <ul>
                        {filteredMenus.map((group, index) => (
                            <li key={group.id}>
                                {`Name: ${group.name}, ID: ${group.id}, Position: ${index + 1}`}
                            </li>
                        ))}
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { handleClose(); handleReset(); }}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Order
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MenuOrderModal;
