import React, { useState, useEffect } from 'react';
import { oneclick } from '../../../oneclick';
import { OneKlicksFileSDK } from '../../../OneKlicksFileSDK';
import { getuserdata, uploadFilelink } from '../../../services/apiService';
import { generateRandomProfiles } from '../../Test/ProfileGeneratorScreen';
import { TailSpin } from "react-loader-spinner"; // Optional: If using react-loader-spinner
import database_id from '../../../utils/databaseConfig';

const AddAiStaff = ({ show, hide, fetchEmailData, Department, TeamData }) => {
    console.log('Department :', Department);
    console.log('TeamData :', TeamData);
    const [filterdeportment, setfilterdeportment] = useState('');
    const [filteredTeams, setFilteredTeams] = useState([]); // State to hold filtered teams
    const [selectTeams, setSelectTeams] = useState(''); // State to hold filtered teams
    const [loading, setLoading] = useState(false)
    const [departmentData, setDepartmentData] = useState({
        name: '',
        no_staff: '',
        no_females: '',
        no_males: '',
        description: '',
        image: null,
        status: '',
    });
    const [successModalVisible, setSuccessModalVisible] = useState(false);

    useEffect(() => {
        // Filter teams whenever the department changes
        if (filterdeportment) {
            setSelectTeams('');
            const filtered = TeamData.filter(team => team.department_id === filterdeportment);
            setFilteredTeams(filtered);
        } else {
            setSelectTeams('');
            setFilteredTeams([]); // Reset if no department is selected
        }
    }, [filterdeportment, TeamData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDepartmentData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setDepartmentData((prevData) => ({
            ...prevData,
            image: file,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccessModalVisible(true);

        try {
            // Prepare form data for department details

            const tableSchema = database_id;
            const tableName = 'ai_staff';
            const userId = await getuserdata('id');
            const company_id = await getuserdata('company_id');
            const root = 'ai/staff/profile/images';


            // Generate random profiles
            const generatedProfiles = await generateRandomProfiles(
                departmentData.no_staff,
                departmentData.no_females,
                departmentData.no_males,
                filterdeportment,
                selectTeams,
                departmentData.status
            );

            console.log('generatedProfiles :', generatedProfiles);

            //Upload each profile and its associated image
            for (const profile of generatedProfiles) {
                const profileImageFormData = new FormData();
                const profileImageUploadResponse = await uploadFilelink(userId, root, "", profile.profile_image);


                const profileDataToUpload = {
                    user_id: userId,
                    company_id: company_id,
                    first_name: profile.first_name,
                    last_name: profile.last_name,
                    email: profile.email,
                    Password: profile.Password,
                    gender: profile.gender,
                    age: profile.age,
                    position: profile.position,
                    department_id: filterdeportment,
                    team_id: selectTeams,
                    job_description: departmentData.description,
                    status: profile.status,
                    profile_image: profileImageUploadResponse.file.id,
                    height: profile.height,
                    weight: profile.weight,
                    skin_tone: profile.skinTone,
                    hobbies: profile.hobbies,
                    goals: profile.goals,
                    favorites: profile.favorites,
                    created_by: profile.created_by,
                    phone_number: profile.phone_number,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                };

                const profileConditions = {
                    email: profile.email,
                };

                const profileUploadResponse = await oneclick.create(
                    tableSchema,
                    'ai_staff', // Replace with your actual table name
                    profileDataToUpload,
                    profileConditions
                );

                console.log('profileUploadResponse :', profileUploadResponse);

                if (profileUploadResponse.success) {
                    hide();
                    fetchEmailData();
                    setLoading(false);
                    console.log('Error uploading profile:', profileUploadResponse.message);
                }else{
                    setLoading(false);
                    console.error('Error uploading profile:', profileUploadResponse.message);
                }
            }

            setSuccessModalVisible(false);
        } catch (error) {
            setLoading(false);
            setSuccessModalVisible(false);
            console.error('Error processing submission:', error);
            alert('An error occurred. Please try again.');
        }
    };


    const hideSuccessModal = () => {
        setSuccessModalVisible(false);
        hide();
        fetchEmailData();
        setDepartmentData({
            name: '',
            description: '',
            image: null,
            status: '',
        });
    };

    return (
        <div>
            <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="addNewLabel" aria-hidden={!show}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addNewLabel">Add Staff</h5>
                            <button type="button" className="btn-close" onClick={hide}><i className="fa fa-close"></i></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="department" className="form-label">Select Department</label>
                                    <div className="filter-item">
                                        <select
                                            name="department"
                                            className="form-select"
                                            value={filterdeportment}
                                            required
                                            onChange={(e) => setfilterdeportment(e.target.value)}
                                        >
                                            <option value="">Select Department</option>
                                            {Department.length > 0 ? (
                                                Department.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option disabled>No available</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="team" className="form-label">Select Team</label>
                                    <div className="filter-item">
                                        <select
                                            name="team"
                                            className="form-select"
                                            required
                                            value={selectTeams}
                                            onChange={(e) => setSelectTeams(e.target.value)}
                                        >
                                            <option value="">Select Team</option>
                                            {filteredTeams.length > 0 ? (
                                                filteredTeams.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option disabled>No available</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className='col-lg-4'>
                                        <label htmlFor="name" className="form-label">No. Staff</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="no_staff"
                                            name="no_staff"
                                            value={departmentData.no_staff}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className='col-lg-4'>
                                        <label htmlFor="name" className="form-label">No. Females</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="no_females"
                                            name="no_females"
                                            value={departmentData.no_females}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className='col-lg-4'>
                                        <label htmlFor="name" className="form-label">No. Males</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="no_males"
                                            name="no_males"
                                            value={departmentData.no_males}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Job Description</label>
                                    <textarea
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        value={departmentData.description}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="status" className="form-label">Status</label>
                                    <select
                                        className="form-select"
                                        name="status"
                                        value={departmentData.status}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled>Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <div className="modal-footer" style={{ paddingRight: '0px' }}>
                                    <button type="submit" className="btn btn-primary">
                                        {loading ? 'Loading...' : 'Add Staff'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {successModalVisible && (
                <div
                    className="modal fade show"
                    style={{ display: 'block' }}
                    aria-labelledby="successModalLabel"
                    aria-hidden="false"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="successModalLabel">Success</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={hideSuccessModal}
                                    disabled={loading} // Disable button during loading
                                ></button>
                            </div>
                            <div className="modal-body" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                {loading ? (
                                    // <div className="spinner-border text-primary" role="status">
                                    <TailSpin height="80" width="80" color="#260055" ariaLabel="loading" />
                                    // </div>
                                ) : (
                                    "Staff added successfully!"
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={hideSuccessModal}
                                    disabled={loading} // Disable button during loading
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default AddAiStaff;