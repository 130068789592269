import React, { useEffect, useState, useRef } from 'react';
import { TeamSectionContent, TeamSectionElements, getImageBaseUrl } from '../../services/apiService';
import '../../assets/theme/frontend/css/TeamSection.css';
import { oneclick } from '../../oneclick';
import ImageComponent from '../../utils/ImageComponent';
import database_id from '../../utils/databaseConfig';
import useImageUrlBuild from '../../utils/ImageUrlBuild';

const TeamSection = () => {
  const [featureContent, setFeatureContent] = useState([]);
  const [featureElements, setFeatureElements] = useState([]);
  const [aistaffdata , setAistaffdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

  // Hook call must be at the top level
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setImageBaseUrl(getImageBaseUrl());
    const loadData = async () => {
      try {
        console.log('Fetching feature content and elements...');
        const content = await TeamSectionContent();
        const elements = await TeamSectionElements();
        getAistaff();

        console.log('Feature content:', content);
        console.log('Feature elements:', elements);

        setFeatureContent(content);
        setFeatureElements(elements);
      } catch (error) {
        console.error('Failed to load data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const getAistaff = async () => {
    const tableSchema = database_id;
    const tableName = 'ai_staff';
    const conditions = {};
    const orderBy = "id DESC";
    const search = {};
    const pagination = {page: 1, limit: 10};
    const margedata = {};

    const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

    console.log('AI Staff:', response);
    if(response.success){
      setAistaffdata(response.data);
    }

  };

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    container.scrollBy({
      left: direction === 'left' ? -200 : 200,
      behavior: 'smooth'
    });
  };

  if (loading) {
    console.log('Loading data...');
    
  }

  if (error) {
    console.error('Error encountered:', error);
  }

  if (!featureContent.length || !featureElements.length) {
    console.warn('No data available');
  }

  const [firstContent] = featureContent;
  const {
    section_value: {
      sub_heading = '',
      heading = '',
      description = ''
    } = {}
  } = firstContent || {};

  return (
    <section id="features" className="pt-100 pb-100">
      <div className="container">
        <div className="text-center section-header section-header-two align-items-center">
          <span className="sub-title">{sub_heading}</span>
          <h3 className="section-title">{heading}</h3>
          <p className="title-description">{description}</p>
        </div>

        <div className="feature-items position-relative">
          <button
            id="scroll-left"
            className="scroll-button"
            aria-label="Scroll left" style={{ width: '44px', borderRadius: '35px' }}
            onClick={() => scroll('left')}
          >
            &lt;
          </button>
          <div
            className="scrolling-wrapper"
            ref={scrollContainerRef}
          >
            {aistaffdata.map((element, index) => (
              <div className="scroll-item" key={index}>
                <div className="team-member-card">
                  {/* <img className="card-image" src={`${useImageUrlBuild(element.profile_image)}`} alt={element.profile_image} /> */}
                  <ImageComponent fileid={element.profile_image} width={200} height={250} borderRadius={0} boxShadow="" />
                  <div className="social-media-icons">
                    {/* <div className="social-media-icons1">
                      <a href={element.section_value.facebook_link} className="social-icon" aria-label="Facebook">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href={element.section_value.instagram_link} className="social-icon" aria-label="Instagram">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href={element.section_value.linkedin_link} className="social-icon" aria-label="LinkedIn">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="info-boxx text-center align-items-center">
                  <h3 className="name" style={{color: '#260055', width: '100%',textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>
                    <a href="#"  style={{color: '#260055'}}>
                      {element.first_name} {element.last_name}
                    </a>
                  </h3>
                  <span className="designation" dangerouslySetInnerHTML={{ __html: element.designation }} />
                  <br />
                  <div style={{width: '100%',textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>
                    <i className="fas fa-mobile-alt"></i> {element.phone_number}
                  </div>
                  <div style={{width: '100%',textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>
                    <i className="far fa-envelope"></i> {element.email}
                  </div>
                  <br />
                  
                </div>
              </div>
            ))}
          </div>
          <button
            id="scroll-right"
            className="scroll-button"
            aria-label="Scroll right" style={{ width: '44px', borderRadius: '35px' }}
            onClick={() => scroll('right')}
          >
            &gt;
          </button>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
