import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import { oneclick } from '../oneclick';
import { getImageBaseUrl, getuserdata } from '../services/apiService';
import ImageComponent from './ImageComponent';
import LoadingScreen from './LoadingScreen';
import database_id from './databaseConfig';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";

const DynamicSidebar = ({ settings, userdat, isMinimized }) => {
    const location = useLocation();
    const [showInput, setShowInput] = useState(false);
    const navigate = useNavigate();
    const [openMenuId, setOpenMenuId] = useState({});
    const [menuData, setMenuData] = useState([]);
    const [imageBaseUrl, setImageBaseUrl] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadinglast, setLoadinglast] = useState(false);
    const [error, setError] = useState(null);
    const [smallmenu, setSmallmenu] = useState(false);

    const sidebarBgColor = `#${settings.primary_color || 'ffffff'}`;

    const handleToggle = (submenuId) => {
        const newOpenMenuId = openMenuId === submenuId ? null : submenuId;
        setOpenMenuId(newOpenMenuId);
        localStorage.setItem('openMenuId', newOpenMenuId); // Persist open state in localStorage
    };

    const parseMenuData = (data) => {
        if (typeof data == 'string') {
            try {
                return JSON.parse(data);
            } catch (e) {
                console.error('Error parsing menu data:', e);
                return [];
            }
        }
        return data || [];
    };

    const fetchMenuData = async () => {
        setError(null);
        try {
            const tableSchema = database_id;
            setImageBaseUrl(getImageBaseUrl());

            const conditions = {
                status: 1
            }

            //tableSchema, tableName, conditions, orderBy, search, pagination, margedata
            const menugroups = await oneclick.read(tableSchema, "menugroup", conditions, '', "");
            const menulists = await oneclick.read(tableSchema, "menulist", conditions, '', "");
            const submenus = await oneclick.read(tableSchema, "submenu", conditions, '', "");

            const combinedMenuData = menugroups.data.map(group => ({
                ...group,
                memudata: menulists.data
                    .filter(menu => menu.groupId == group.id)
                    .map(menu => ({
                        ...menu,
                        submenu: submenus.data.filter(sub => sub.menuId == menu.id)
                    }))
            })).sort((a, b) => a.order_by - b.order_by);

            setMenuData(combinedMenuData);

            if (!openMenuId) {
                setOpenMenuId(findOpenMenuId(combinedMenuData));
            }
        } catch (err) {
            console.error('Failed to load menu data:', err);
            setError('Failed to load menu data');
        } finally {
            setLoading(false);
            setLoadinglast(true);
        }
    };

    const findOpenMenuId = (combinedMenuData) => {
        for (const menu of combinedMenuData) {
            if (menu.link == location.pathname) return menu.id;
            for (const submenu of parseMenuData(menu.memudata)) {
                if (submenu.link == location.pathname) {
                    return menu.id; // If a submenu is active, open the parent menu
                }
                if (submenu.submenu) {
                    for (const nestedSub of submenu.submenu) {
                        if (nestedSub.link == location.pathname) {
                            return menu.id; // If nested submenu is active, open the parent menu
                        }
                    }
                }
            }
        }
        return null;
    };

    useEffect(() => {
        fetchMenuData();
        console.log('isMinimized :', isMinimized);
        if (isMinimized == true) {
            setSmallmenu(true);
        } else {
            setSmallmenu(false);
        }

    }, [isMinimized]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const hasPermission = (item) => {
        if (!userdat.roleData) return true;

        let permissions;
        try {
            permissions = JSON.parse(userdat.roleData.permission);
        } catch (e) {
            console.error('Error parsing permissions:', e);
            return false;
        }

        const itemPermissions = parseMenuData(item.permissions);
        return itemPermissions.some(perm => permissions.includes(perm));
    };

    const filterMenuItems = useCallback(async (menu) => {
        // Show admin menus only to users with the admin role
        const usertypedata = await getuserdata('systam_user_type');
        if (menu.type == 'admin') {
            console.log('userdat.usertype:', usertypedata);
            return usertypedata == 'admin';
        }

        if (menu.type == 'user') {
            return usertypedata == 'user';
        }

        const submenus = parseMenuData(menu.memudata);
        const isMenuMatch = menu.name?.toLowerCase().includes(searchQuery) && hasPermission(menu);
        const isSubmenuMatch = submenus.some(sub => sub.name?.toLowerCase().includes(searchQuery) && hasPermission(sub));
        return isMenuMatch || isSubmenuMatch;
    }, [searchQuery, userdat]);

    const filteredMenuData = useMemo(() => {
        return menuData.filter(filterMenuItems);
    }, [menuData, filterMenuItems]);


    if (error) return <div>{error}</div>;

    const handleMenuClick = (submenu) => {
        if (submenu.link) {
            navigate(submenu.link);
            localStorage.setItem('openMenuId', ""); // Persist open state in localStorage
        } else {
            console.log('handleMenuClick', submenu.id);
            handleToggle(submenu.id);
        }
    };

    return (
        <>
            {loading == true ? (
                <>

                </>
            ) : (
                <>
                    <aside className={`sidebar ${isMinimized == true ? 'isMinimized' : ''}`} id="sidebar" style={{ display: isMinimized == true ? '' : '' }}>
                        <div className="sidebar-top" >


                            {isMinimized == true ? (
                                // Render nothing when isMinimized is true
                                <>
                                    <img
                                        src="/assets/file/images/logoIcon/667cfa61ba7801719466593.png"
                                        alt="Site Logo"
                                        className="logo-lghhh"
                                        style={{
                                            maxWidth: '60px',
                                            verticalAlign: 'middle',
                                            marginLeft: '10px',
                                            marginTop: '12px',
                                        }}
                                    />
                                </>
                            ) : (
                                // Render the logo when isMinimized is false
                                <div className="site-logo" >
                                    <Link to="/dashboard" className="ajax-link">
                                        <img
                                            src={`${imageBaseUrl}/assets/file/images/logoIcon/${settings.panel_logo}`}
                                            alt="Site Logo"
                                            className="logo-lg"
                                            style={{
                                                maxWidth: '248px',
                                                marginLeft: '-40px',
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                        <img
                                            src={`${imageBaseUrl}/assets/file/images/logoIcon/${settings.panel_logo}`}
                                            alt="Site Icon"
                                            className="logo-sm"
                                            style={{
                                                display: 'none', // Hide the small logo when isMinimized is false
                                            }}
                                        />
                                    </Link>
                                </div>
                            )}


                            {isMinimized == true ? (
                                <>
                                    <div
                                        className="menu-search-container"
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <i
                                            className="fas fa-search"
                                            style={{
                                                fontSize: "24px",
                                                color: "white",
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                            }}
                                        ></i>
                                    </div>

                                </>
                            ) : (
                                <>
                                    <div className="menu-search-container" >
                                        <input
                                            className="form-control menu-search"
                                            placeholder="Search Here"
                                            type="search"
                                            name="search"
                                            id="searchMenu"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </>
                            )}



                        </div>

                        <div className="sidebar-menu-container" data-simplebar="true">
                            <ul className="sidebar-menu">
                                {filteredMenuData.map(menu => {
                                    const subMenus = parseMenuData(menu.memudata);
                                    return (
                                        <React.Fragment key={menu.id}>
                                            {hasPermission(menu) && (
                                                <>
                                                    <li className="sidebar-menu-title">{isMinimized == true ? ('.....') : (menu.name)}</li>
                                                    {subMenus.filter(submenu => submenu.name?.toLowerCase().includes(searchQuery) && hasPermission(submenu)).map(submenu => (
                                                        <li className="sidebar-menu-item" key={submenu.id}>
                                                            {submenu.link ? (
                                                                <button
                                                                    className={`sidebar-menu-link ${location.pathname == submenu.link ? 'active' : ''}`}
                                                                    onClick={() => handleMenuClick(submenu)}
                                                                >
                                                                    <span>
                                                                        {!submenu.newicon ?
                                                                            (
                                                                                <>
                                                                                    {submenu.icon && <ImageComponent fileid={submenu.icon} width={20} height={20} borderRadius={0} boxShadow={'0px 0px 0px black'} color={'#ffffff'} />}
                                                                                </>

                                                                            ) : (
                                                                                <>
                                                                                    <FontAwesomeIcon icon={solidIcons[submenu.newicon]} style={{ fontSize: '20px', color: 'white', marginRight: '10px' }} />
                                                                                </>
                                                                            )
                                                                        }
                                                                    </span>
                                                                    <p>{isMinimized == true ? ('') : (submenu.name)}</p>
                                                                </button>
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        className={`sidebar-menu-link ${openMenuId == submenu.id || submenu.submenu?.some(subItem => location.pathname == subItem.subMenuLink) ? 'active' : ''}`}
                                                                        onClick={() => handleToggle(submenu.id)}
                                                                        aria-expanded={openMenuId == submenu.id || submenu.submenu?.some(subItem => location.pathname == subItem.subMenuLink)}
                                                                        aria-label={`Toggle submenu ${submenu.name}`}
                                                                    >
                                                                        <span>
                                                                            {!submenu.newicon ?
                                                                                (
                                                                                    <>
                                                                                        {submenu.icon && <ImageComponent fileid={submenu.icon} width={20} height={20} borderRadius={0} boxShadow={'0px 0px 0px black'} color={'#ffffff'} />}
                                                                                    </>

                                                                                ) : (
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={solidIcons[submenu.newicon]} style={{ fontSize: '20px', color: 'white', marginRight: '10px' }} />
                                                                                    </>
                                                                                )
                                                                            }

                                                                        </span>
                                                                        <p>
                                                                            {isMinimized == true ? ('') : (submenu.name)}
                                                                            <small>
                                                                                <i className={`las la-angle-${openMenuId == submenu.id || submenu.submenu?.some(subItem => location.pathname == subItem.subMenuLink) ? 'up' : 'down'}`} />
                                                                            </small>
                                                                        </p>
                                                                    </button>
                                                                    {submenu.submenu && submenu.submenu.length > 0 && (
                                                                        <Collapse in={openMenuId == submenu.id || submenu.submenu?.some(subItem => location.pathname == subItem.subMenuLink)}>
                                                                            <div className='side-menu-dropdown'>
                                                                                <ul className="sub-menu">
                                                                                    {submenu.submenu.filter(subItem => subItem.name?.toLowerCase().includes(searchQuery) && hasPermission(subItem)).map(subItem => (
                                                                                        <li key={subItem.id} className={`sub-menu-item ${location.pathname == subItem.subMenuLink ? 'active' : ''}`}>
                                                                                            <Link to={subItem.subMenuLink} className="sidebar-menu-link" >
                                                                                                <p>{subItem.name}</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </Collapse>
                                                                    )}
                                                                </>
                                                            )}
                                                        </li>
                                                    ))}
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </ul>
                        </div>
                    </aside>
                </>
            )}
        </>
    );
};

export default DynamicSidebar;
