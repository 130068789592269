import React from 'react';
import { useDrop } from 'react-dnd';

const Canvas = ({ elements, setElements, setSelectedElement }) => {
    // Handle dropping an element on the canvas
    const [, dropRef] = useDrop({
        accept: "element",
        drop: (item) => {
            const newElement = { ...item, id: Date.now(), style: {} };
            setElements((prev) => [...prev, newElement]);
        },
    });

    // Handle selecting an element
    const handleSelectElement = (id) => {
        const element = elements.find((el) => el.id === id);
        setSelectedElement(element);
    };

    return (
        <div
            ref={dropRef}
            style={{
                height: "500px",
                border: "1px dashed #ccc",
                backgroundColor: "#f9f9f9",
                position: "relative",
            }}
        >
            {elements.map((element) => (
                <div
                    key={element.id}
                    onClick={() => handleSelectElement(element.id)}
                    style={{
                        ...element.style,
                        margin: "10px 0",
                        padding: "10px",
                        border: "1px solid #ccc",
                    }}
                >
                    {element.name}
                </div>
            ))}
        </div>
    );
};

export default Canvas;
