import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { oneclick } from '../../../oneclick';
import database_id from '../../../utils/databaseConfig';

const EditPermissionsModal = ({ show, handleClose, permissionData }) => {
    const generateTimestampId = () => {
        return new Date().getTime().toString(); // Generate a unique timestamp
    };

    const initialPermissions = () => {
        try {
            // Parse the permissions data if it's a valid JSON string
            const parsedPermissions = permissionData?.permissions ? JSON.parse(permissionData.permissions) : [];
            return parsedPermissions.map((perm) => ({
                id: generateTimestampId(), // Update ID to timestamp
                subName: perm.subName || '', // Ensure subName is initialized
                permissions: perm.permissions.map(child => ({
                    id: generateTimestampId(), // Update child ID to timestamp
                    name: child.name || '', // Ensure child name is initialized
                })),
            }));
        } catch (error) {
            console.error("Error parsing permissions:", error);
            return [{
                id: generateTimestampId(), // Default ID for the first permission
                subName: '',
                permissions: [{ id: generateTimestampId(), name: "" }]
            }];
        }
    };

    const [editedRole, setEditedRole] = useState({
        permissionsName: '',
        permissionsNametype: '',
        permissions: []
    });

    // Handle state updates when permissionData changes or modal is shown
    useEffect(() => {
        if (show && permissionData) {
            setEditedRole({
                permissionsName: permissionData?.name || '',
                permissionsNametype: permissionData?.permissionsNametype || '',
                permissions: initialPermissions(),
            });
        } else if (!show) {
            // Reset state when the modal is closed
            setEditedRole({
                permissionsName: '',
                permissionsNametype: '',
                permissions: []
            });
        }
    }, [show, permissionData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedRole((prev) => ({ ...prev, [name]: value }));
    };

    // Handle change for subName of permissions
    const handlePermissionSubNameChange = (index, event) => {
        const updatedPermissions = [...editedRole.permissions];
        updatedPermissions[index].subName = event.target.value;
        setEditedRole({ ...editedRole, permissions: updatedPermissions });
    };

    // Handle change for children of a subName
    const handleChildPermissionChange = (permIndex, childIndex, event) => {
        const updatedPermissions = [...editedRole.permissions];
        updatedPermissions[permIndex].permissions[childIndex].name = event.target.value; // Update child name correctly
        setEditedRole({ ...editedRole, permissions: updatedPermissions });
    };

    // Add new permission subName
    const handleAddPermissionSubName = () => {
        setEditedRole((prev) => ({
            ...prev,
            permissions: [
                ...prev.permissions,
                { id: generateTimestampId(), subName: '', permissions: [{ id: generateTimestampId(), name: "" }] }
            ]
        }));
    };

    // Remove permission subName
    const handleRemovePermissionSubName = (index) => {
        const updatedPermissions = editedRole.permissions.filter((_, i) => i !== index);
        setEditedRole({ ...editedRole, permissions: updatedPermissions });
    };

    // Add new child permission for a specific subName
    const handleAddChildPermission = (permIndex) => {
        const updatedPermissions = [...editedRole.permissions];
        updatedPermissions[permIndex].permissions.push({ id: generateTimestampId(), name: '' });
        setEditedRole({ ...editedRole, permissions: updatedPermissions });
    };

    // Handle removing a child permission
    const handleRemoveChildPermission = (permIndex, childIndex) => {
        const updatedPermissions = [...editedRole.permissions];
        updatedPermissions[permIndex].permissions.splice(childIndex, 1); // Remove the child permission
        setEditedRole({ ...editedRole, permissions: updatedPermissions });
    };

    const handleSubmit = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "permissions";
            const data = {
                name: editedRole.permissionsName,
                permissionsNametype: editedRole.permissionsNametype,
                permissions: JSON.stringify(editedRole.permissions) // Serialize the nested structure
            };
            const condition = { id: permissionData?.id };

            const update = await oneclick.update(tableSchema, tableName, data, condition); // Assuming you have an update method
            if (update.success) {
                alert(update.message || 'Permissions updated successfully.');
                handleClose(); // Close the modal on successful submit
            } else {
                console.error("Error updating permissions:", update.error);
                alert(update.error || 'Error updating permissions.');
            }
        } catch (error) {
            console.error('Error updating permissions:', error);
            alert('An unexpected error occurred while updating permissions.');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Permissions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="permissionsName">
                        <Form.Label>Permissions Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="permissionsName"
                            value={editedRole.permissionsName}
                            onChange={handleChange}
                            required
                        />
                        <Form.Label>Permissions Type</Form.Label>
                        <Form.Select
                            name="permissionsNametype"
                            value={editedRole.permissionsNametype}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Permission</option> {/* Default empty option */}
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </Form.Select>
                    </Form.Group>

                    {/* Dynamic Permission Inputs */}
                    {editedRole.permissions && editedRole.permissions.length > 0 ? (
                        editedRole.permissions.map((permission, permIndex) => (
                            <div key={permIndex} style={{ marginBottom: '20px' }}>
                                <Form.Label>Permissions Sub Name</Form.Label>
                                <div className="d-flex align-items-center mb-2" style={{ gap: '10px' }}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter permission sub name"
                                        value={permission.subName}
                                        onChange={(e) => handlePermissionSubNameChange(permIndex, e)}
                                    />
                                    {editedRole.permissions.length > 1 && (
                                        <Button
                                            variant="danger"
                                            className="ml-2"
                                            onClick={() => handleRemovePermissionSubName(permIndex)}
                                        >
                                            <i className="fa fa-close"></i>
                                        </Button>
                                    )}
                                </div>

                                <Form.Label>Child Permissions</Form.Label>
                                {permission.permissions && permission.permissions.length > 0 ? (
                                    permission.permissions.map((child, childIndex) => (
                                        <div key={child.id} className="d-flex align-items-center mb-2" style={{ gap: '10px' }}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter child permission name"
                                                value={child.name}
                                                onChange={(e) => handleChildPermissionChange(permIndex, childIndex, e)}
                                            />
                                            <Button
                                                variant="danger"
                                                onClick={() => handleRemoveChildPermission(permIndex, childIndex)} // Call to remove child permission
                                            >
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        </div>
                                    ))
                                ) : (
                                    <div>No child permissions available</div>
                                )}
                                <Button variant="success" onClick={() => handleAddChildPermission(permIndex)}>
                                    + Add Child Permission
                                </Button>
                            </div>
                        ))
                    ) : (
                        <div>No permissions available</div>
                    )}

                    <Button variant="success" className="mt-2" onClick={handleAddPermissionSubName}>
                        + Add Permission Sub Name
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Update Permissions
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditPermissionsModal;
