import React, { useEffect, useState } from "react";
import { oneclick } from "../../oneclick"; // Adjust the import based on your project structure
import DOMPurify from "dompurify";
import database_id from "../../utils/databaseConfig";

const TermsAndConditions = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "frontend_sections";
            const conditions = { id: "27" }; // Use appropriate ID for Terms and Conditions
            const response = await oneclick.read(tableSchema, tableName, conditions, "", "");
            const sanitizedContent = DOMPurify.sanitize(response.data[0].section_value);
            setContent(sanitizedContent);
        } catch (error) {
            console.error("Failed to load data:", error);
        }
    };

    return (
        <div style={styles.container}>
            <section className="breadcrumb-banner">
                <div className="container">
                    <div className="breadcrumb-content text-center">
                        <h2 style={styles.title}>Terms and Conditions</h2>
                        <div className="d-inline-block mt-4">
                            <div
                                style={styles.content}
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const styles = {
    container: {
        margin: "0 auto",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        maxWidth: "800px",
    },
    title: {
        fontSize: "2em",
        color: "#333",
        marginBottom: "20px",
    },
    content: {
        fontSize: "1em",
        lineHeight: "1.6",
        color: "#555",
    },
};

export default TermsAndConditions;
