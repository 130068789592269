import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import { oneclick } from '../../../oneclick';
import { DomainVerification } from '../../../services/apiService';
import database_id from '../../../utils/databaseConfig';

const DomainVerificationForm = ({ onHide, selectedWebsite }) => {
    const [uniqueCode, setUniqueCode] = useState('');
    const [filename, setFilename] = useState('');
    const [isFileGenerated, setIsFileGenerated] = useState(false);
    const [error, setError] = useState('');
    const [verificationMessage, setVerificationMessage] = useState(''); // State to show verification message
    const [loading, setLoading] = useState(false); // State to show loading indicator

    // Function to generate a unique code
    const generateUniqueCode = async () => {
        try {
            const code = `VERIFICATION_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
            setUniqueCode(code);

            const tableSchema = database_id;
            const data = { code };
            const condition = { id: selectedWebsite.id };

            await oneclick.update(tableSchema, "seowebsites", data, condition);
            return code;
        } catch (err) {
            setError('Failed to generate unique code. Please try again.');
            console.error('Error generating unique code:', err);
        }
    };

    // Function to generate the filename based on the selected website
    const generateFilename = () => {
        const domainWithoutProtocol = selectedWebsite.website.replace(/^(https?:\/\/)?/, '');
        const baseFilename = `${domainWithoutProtocol.replace(/\./g, '_')}-verification.txt`;
        setFilename(baseFilename);
    };

    // Generate the filename on mount or when the selected website changes
    useEffect(() => {
        generateFilename();
    }, [selectedWebsite]);


    // Function to check if the verification file contains the correct unique code
    const verifyFileExists = async () => {
        setLoading(true); // Start loading
        console.log('Starting verification process...');

        try {
            // Call the DomainVerification function
            const sanitizedDomain = selectedWebsite.website.replace(/^(https?:\/\/)?/, '');
            console.log('Calling DomainVerification for:', sanitizedDomain, filename);
            
            const response = await DomainVerification(sanitizedDomain, filename);
            console.log('verifyFileExists response:', response);

            // Validate response structure
            if (response && response.uniqueCode) {
                const uniqueCodeFromResponse = response.uniqueCode; // Read unique code directly from response
                console.log('Unique Code from Response:', uniqueCodeFromResponse);

                console.log('Unique code is present. Proceeding to database check...');
                const tableSchema = database_id;

                // Prepare data and condition for the database update
                const data = { status: "active" };
                const condition = { id: selectedWebsite.id };

                // Update the database
                console.log('Updating database with condition:', condition);
                const updatedata = await oneclick.update(tableSchema, "seowebsites", data, condition);
                console.log('Update response:', updatedata);

                // Check if the update was successful
                if (updatedata.success) {
                    setVerificationMessage('Verification successful! The unique code was found in the file.');
                    onHide(); // Close the modal after successful verification
                    console.log('Verification successful and database updated.');
                } else {
                    setVerificationMessage('Verification failed. Could not update the database.');
                    console.error('Database update failed, no data returned:', updatedata);
                }
            } else {
                setVerificationMessage('Verification failed. The unique code was not found in the response.');
                console.warn('No unique code found in the response.');
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error during verification:', error);
            setVerificationMessage('Verification failed. Could not connect to the website.');
        }

        setLoading(false); // Stop loading
        console.log('Verification process completed.');
    };




    // const verifyFileExists = async () => {
    //     setLoading(true); // Start loading
    //     try {
    //         const response = await DomainVerification(selectedWebsite.website.replace(/^(https?:\/\/)?/, ''), filename);
    //         const data = await response.json();
    //         console.log(data);
    //     } catch (error) {
    //         console.error('Error verifying domain:', error);
    //     }
    // };


    return (
        <Container className="my-4">
            <Row>
                <Col md={12} className="mx-auto">
                    <h3 className="text-center">Verify Your Website</h3>
                    <p className="text-muted text-center">
                        To verify ownership of <strong>{selectedWebsite.website}</strong>, please download and upload the verification file.
                    </p>

                    {error && <Alert variant="danger">{error}</Alert>}

                    <Form>
                        <Form.Group controlId="fileUpload" className="text-center">
                            <p>
                                <strong>Step 1:</strong> Download the verification file:
                                <br />
                                <a href={`https://${selectedWebsite.website}/oneklicks/${filename}`} target="_blank" rel="noopener noreferrer">
                                    {selectedWebsite.website}/oneklicks/{filename}
                                </a>
                            </p>
                            <p><strong>Step 2:</strong> Upload the file to the specified location on your website's root directory.</p>

                            <Button
                                variant="primary"
                                onClick={async () => {
                                    const code = await generateUniqueCode();
                                    if (code) {
                                        const text = `Verification for ${selectedWebsite.website}\nUnique Code: ${code}`;
                                        const blob = new Blob([text], { type: 'text/plain' });
                                        const url = URL.createObjectURL(blob);
                                        const a = document.createElement('a');
                                        a.href = url;
                                        a.download = filename;
                                        a.click();
                                        setIsFileGenerated(true); // Mark file as generated
                                    }
                                }}
                            >
                                {isFileGenerated ? 'Re-Download File' : 'Download File'}
                            </Button>
                        </Form.Group>

                        {isFileGenerated && (
                            <Alert variant="success" className="mt-3 text-center">
                                Verification file generated! Please upload it to your website and click "Verify Now."
                            </Alert>
                        )}

                        <div className="text-center mt-4">
                            <Button variant="success" onClick={verifyFileExists} disabled={loading}>
                                {loading ? 'Verifying...' : 'Verify Now'}
                            </Button>
                        </div>

                        {verificationMessage && (
                            <Alert variant={verificationMessage.includes('successful') ? 'success' : 'danger'} className="mt-3 text-center">
                                {verificationMessage}
                            </Alert>
                        )}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default DomainVerificationForm;
    

