import React from 'react';
import { Helmet } from 'react-helmet';

const DynamicSEO = ({
  title,
  description,
  keywords,
  favicon,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  twitterCard = 'summary_large_image', // Default is large image
  twitterTitle,
  twitterDescription,
  twitterImage,
  author,
  charset = 'UTF-8',
  viewport = 'width=device-width, initial-scale=1',
  googleAnalytics,
  fbPixel,
  customScripts = '',
}) => {
  return (
    <Helmet>
      {/* Charset */}
      <meta charSet={charset} />

      {/* Viewport */}
      <meta name="viewport" content={viewport} />

      {/* Dynamic Title */}
      <title>{title}</title>

      {/* Dynamic Meta Description */}
      <meta name="description" content={description} />

      {/* Dynamic Meta Keywords */}
      <meta name="keywords" content={keywords} />

      {/* Dynamic Favicon */}
      <link rel="icon" href={favicon} type="image/x-icon" />

      {/* Open Graph Meta Tags (for social media sharing) */}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta property="og:locale" content="en_US" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={twitterTitle || ogTitle} />
      <meta name="twitter:description" content={twitterDescription || ogDescription} />
      <meta name="twitter:image" content={twitterImage || ogImage} />
      <meta name="twitter:site" content="@your_twitter_handle" /> {/* Optional */}
      <meta name="twitter:creator" content="@your_twitter_handle" /> {/* Optional */}

      {/* Author Meta Tag */}
      <meta name="author" content={author} />

      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />

      {/* Additional Meta Tags (for mobile apps, etc.) */}
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />

      {/* Google Analytics Script */}
      {googleAnalytics && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics}`}
        ></script>
      )}
      {googleAnalytics && (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${googleAnalytics}');
          `}
        </script>
      )}

      {/* Facebook Pixel Script */}
      {fbPixel && (
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${fbPixel}');
            fbq('track', 'PageView');
          `}
        </script>
      )}

      {/* Custom Scripts */}
      {customScripts && (
        <script type="text/javascript">
          {customScripts}
        </script>
      )}

      {/* Optional: JSON-LD Structured Data for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": title,
          "description": description,
          "url": ogUrl,
          "image": ogImage,
          "publisher": {
            "@type": "Organization",
            "name": author,
          },
        })}
      </script>
    </Helmet>
  );
};

export default DynamicSEO;
