import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/theme/global/css/bootstrap.min.css';
import '../../assets/theme/global/css/all.min.css';
import '../../assets/theme/global/css/line-awesome.min.css';
import '../../assets/theme/global/css/font_bootstrap-icons.css';
import '../../assets/theme/global/css/toastr.css';
import '../../assets/theme/global/css/plugin.css';
import '../../assets/theme/admin/css/select2.min.css';
import '../../assets/theme/admin/css/apexcharts.css';
import '../../assets/theme/admin/css/datepicker/datepicker.min.css';
import '../../assets/theme/admin/css/simplebar.css';
import '../../assets/theme/admin/css/style.css';
import '../../assets/theme/admin/css/responsive.css';
import '../../assets/theme/admin/flag-icons/flag-icons.css';
import Preloader from '../../components/Preloader'; // Import Preloader component
import { updateUserCurrency, getImageBaseUrl } from '../../services/apiService'; // Adjust API service paths

const TopBar = ({ currencies, languages, userdat, profileImage, settings, toggleSidebar, isMinimized }) => {
    const [currency, setCurrency] = useState({ code: userdat.currencyCode, symbol: userdat.symbol });
    const [language, setLanguage] = useState('');
    const [activeDropdown, setActiveDropdown] = useState(null); // Dropdown state management
    const [loading, setLoading] = useState(true); // Manage loading state for Preloader
    const navigate = useNavigate();
    const [imageBaseUrl, setImageBaseUrl] = useState(''); // Image base URL state

    useEffect(() => {
        // Set image base URL and mock data loading delay
        setImageBaseUrl(getImageBaseUrl());
        setTimeout(() => setLoading(false), 1000); // Simulate data load (adjust time as per your needs)
    }, []);

    useEffect(() => {
        // Redirect to login if no token is present
        const token = localStorage.getItem('token');
        if (!token) navigate('/login');

        if (languages && languages.length > 0) {
            const defaultLanguage = languages.find(lang => lang.is_default === 1);
            setLanguage(defaultLanguage ? defaultLanguage.flag : languages[0].flag);
        }
    }, [languages, navigate]);

    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('.header345');
            if (header) { // Check if the header exists
                if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
                    header.classList.add('sticky');
                } else {
                    header.classList.remove('sticky');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const changeCurrency = (code, symbol) => {
        setCurrency({ code, symbol });
        const login_token = localStorage.getItem('token');
        updateUserCurrency(login_token, code, symbol);
        window.location.reload(); // Reload to apply changes
    };

    const changeLanguage = (lang) => {
        setLanguage(lang);
        // Add language change logic here
    };

    const toggleDropdown = (dropdown) => {
        setActiveDropdown(prevDropdown => (prevDropdown === dropdown ? null : dropdown));
    };

    return (
        <>
            {loading && <Preloader />} {/* Preloader will be visible until loading is false */}
            <Helmet>
                {/* Preload font */}
                <link
                    rel="preload"
                    href="/static/media/la-solid-900.3efd5ba15a279377d224.woff2"
                    as="font"
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
            </Helmet>
            {!loading && ( // Display header only when loading is complete
                <header className="header345">
                    <div className="header_sub_content">
                        <div className="topbar-left">
                            <div className="sidebar-controller">
                                <button className="sidebar-control-btn" onClick={toggleSidebar}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M2 5a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm19 6H3a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2zm-9 7H3a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2z" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="topbar-right">
                            <div className="profile_notification">
                                <Link to="/" target="_blank" rel="noopener noreferrer" className="header-icon-btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.002 46.002" className="icon">
                                        <g>
                                            <path d="M0 20c0-4.4 3.6-8 8-8h30c4.4 0 8 3.6 8 8v6c0 4.4-3.6 8-8 8H8c-4.4 0-8-3.6-8-8v-6z" />
                                        </g>
                                    </svg>
                                </Link>
                                <ul>
                                    <li className="dropdown-language drop-down">
                                        {/* Currency Selector */}
                                        <select
                                            className="form-select"
                                            style={{
                                                background: 'white',
                                                width: '95px',
                                                fontSize: '15px',
                                                boxShadow: '2px 2px 10px -6px black',
                                                cursor: 'pointer',
                                            }}
                                            value={currency.code} // Bind the current currency code
                                            onChange={(e) => {
                                                const selectedCurrency = currencies.find(curr => curr.code === e.target.value);
                                                if (selectedCurrency) {
                                                    changeCurrency(selectedCurrency.code, selectedCurrency.symbol);
                                                }
                                            }}
                                        >
                                            {/* Options for currencies */}
                                            {currencies.map(curr => (
                                                <option key={curr.code} value={curr.code}>
                                                    {curr.name} {curr.symbol}
                                                </option>
                                            ))}
                                        </select>
                                    </li>

                                    <li className="dropdown-language drop-down">
                                        <a
                                            className="dropdown-toggle hide-arrow header-icon-btn"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleDropdown('language');
                                            }}
                                        >
                                            <img
                                                src={`/assets/theme/admin/flag-icons/flags/1x1/${language}.svg`}
                                                alt={`${language} flag`}
                                                className="flag-icon flag-icon-squared rounded-circle"
                                                style={{ width: '1em', height: '18px' }}
                                            />
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" style={{ display: activeDropdown === 'language' ? 'block' : 'none', marginTop: '152px' }}>
                                            {languages.map(lang => (
                                                <li key={lang.code}>
                                                    <a
                                                        className={`dropdown-item ${language === lang.code ? 'selected' : ''}`}
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            changeLanguage(lang.code);
                                                        }}
                                                    >
                                                        <img
                                                            src={`/assets/theme/admin/flag-icons/flags/1x1/${lang.flag}.svg`}
                                                            alt={`${lang.name} flag`}
                                                            className="flag-icon flag-icon-squared rounded-circle fs-4 me-1"
                                                            style={{ width: '1em', height: '18px' }}
                                                        />
                                                        <span className="align-middle">{lang.name}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>

                                    <li className="profile-dropdown drop-down">
                                        <div className="pointer dropdown-toggle d--flex align--center" onClick={() => toggleDropdown('profile')}>
                                            <span className="profile-nav-img">
                                                <img src={`${imageBaseUrl}/assets/file/images/user/profile/${userdat.image}`} alt="Profile" />
                                            </span>
                                            <p className="ms-1 hide_small admin--profile--notification">{userdat.name}</p>
                                        </div>
                                        <ul className="dropdown-menu drop_down_width" style={{ display: activeDropdown === 'profile' ? 'block' : 'none', marginTop: '178px' }}>
                                            <li>
                                                <a className="dropdown-item" href="/user/profile">
                                                    <i className="me-1 fas fa-user"></i>
                                                    Profile
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default navigation to handle cleanup first
                                                        localStorage.removeItem('token'); // Remove the token
                                                        localStorage.removeItem('userId'); // Remove userId
                                                        localStorage.clear(); // Optional: Clear all localStorage if needed

                                                        // Redirect to the logout page or login page
                                                        window.location.href = '/login';
                                                    }}
                                                >
                                                    <i className="me-1 fas fa-sign-out-alt"></i>
                                                    Logout
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            )}
        </>
    );
};

export default TopBar;
