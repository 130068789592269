const mockData = Array.from({ length: 30 }, (_, i) => ({
    id: i + 1,
    tableName: `Table_${i + 1}`,  // Corrected to use backticks for template literals
    rows: Math.floor(Math.random() * 100),
    type: "InnoDB",
    collation: "utf8_general_ci",
    size: `${Math.floor(Math.random() * 10) + 1}MB`,  // Wrapped size with backticks
    overhead: `${Math.floor(Math.random() * 10)}KB`   // Wrapped overhead with backticks
  }));
  
  export default mockData;