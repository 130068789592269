import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { general_settings, HeroSectionUsers, UserVerify, getImageBaseUrl } from '../../services/apiService'; // Import your API function
import { useLocation } from 'react-router-dom';

import '../../assets/theme/global/css/line-awesome.min.css';
import '../../assets/theme/global/css/font_bootstrap-icons.css';
import '../../assets/theme/frontend/css/all.min.css';
import '../../assets/theme/frontend/css/default.css';
import '../../assets/theme/auth/css/style.css';
import '../../assets/theme/frontend/css/media.css';

const defaultImage = require('../../assets/file/images/default.jpg');


const Verify = () => {
    const location = useLocation();
    const email = location.state?.email || '';

    // Now you can use the email in your Verify component
    console.log('Email from login page:', email);
    const [otp, setotp] = useState('');
    const [featureContent, setFeatureContent] = useState({});
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for toggling password view
    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

    // Access the environment variable
  

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const navigate = useNavigate();

    useEffect(() => {
        setImageBaseUrl(getImageBaseUrl());
        // Check if token is present in localStorage
        const token = localStorage.getItem('token');
        if (token) {
            // Redirect to dashboard if token exists
            navigate('/dashboard');
        } else {
            // If no token, load data
            const loadData = async () => {
                try {
                    const content = await general_settings();
                    const userscontent = await HeroSectionUsers();
                    setFeatureContent(content[0] || {});
                    setUsers(userscontent);

                    console.log('users load data:', userscontent);
                } catch (error) {
                    console.error('Failed to load data:', users);
                    setError('Failed to load data');
                } finally {
                    setLoading(false);
                }
            };

            loadData();
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const data = await UserVerify(email, otp);
            console.log('OTP Verify Is Done:', data);

            navigate('/UpdatePassword', { state: { email } }); // Pass email to verify page
        } catch (error) {
            console.error('OTP Verify Is failed:', error);
            setError('Invalid OTP Verify');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }


    return (
        <div className="login-page-container">
            <div className="container-fluid p-0">
                <div className="row g-0 overflow-hidden">
                    <div className="col-xl-5 col-lg-6">
                        <div className="login-left-section d-flex align-items-center justify-content-center">
                            <div className="form-container">
                                <div className="mb-5">
                                    <a href="/" className="site-logo">
                                        <img src={`${imageBaseUrl}/assets/file/images/logoIcon/${featureContent.panel_logo}`} className="logo-sm" alt="Site Logo" />
                                    </a>
                                    <h4>Sign In with <span className="site--title">{featureContent.site_title || 'Default Title'}</span></h4>
                                </div>

                                <form onSubmit={handleSubmit} id="login-form">
                                    <input type="hidden" name="_token" value="YOUR_CSRF_TOKEN" />
                                    <div className="my-3">
                                        <label htmlFor="user" className="form-label d-block">Verify your Email</label>
                                        <div className="input-field">
                                            <span><i className="las la-envelope"></i></span>
                                            <input
                                                type="text"
                                                name="otp"
                                                value={otp}
                                                placeholder="Give your OTP"
                                                id="user"
                                                aria-describedby="emailHelp"
                                                onChange={(e) => setotp(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button className="btn btn-md btn--primary w-100" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? 'Loading...' : 'Submit'}
                                        </button>
                                    </div>

                                    
                                </form>

                                <p className="text-center mt-4">
                                    New To <Link to="/register">Sign Up!</Link>
                                </p>

                                <div className="mt-5"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="login-right-section responsive-padding bg-purple d-flex align-items-center justify-content-center">
                            <div className="login-right-content">
                                <h1>Welcome to {featureContent.site_title || 'Getone Click'}</h1>
                                <p>{featureContent.description || 'Default description'}</p>
                                <div className="users">
                                    Our mass SMS and Email service provide you to reach more client engage, and also you can fill your target with the potential customer on the basis of different types of products and services which is you want to reach your client door. So why late if no account, sign up quickly and get your expect to plan and start from today with the best and cheap SMS cost!
                                </div>
                                <div className="avatar-group">
                                    {users.slice(0, 10).map((user, index) => (
                                        <div className="avatar-group-item" key={index}>
                                            <img
                                                src={user.image ? `assets/file/images/user/profile/${user.image}` : defaultImage}
                                                alt={user.name || 'User Avatar'}
                                                className="w-100 h-100"
                                            />
                                        </div>
                                    ))}
                                    <div className="avatar-group-item">
                                        <span>{users.length}+ </span>
                                    </div>
                                </div>
                                <span>Over 1K people using this app. Smooth SMS and Email Marketing tools</span>
                                <div className="text-start mt-5">
                                    <a href="/" className="btn btn-sm btn--primary d-flex align-items-center justify-content-center gap-2 lh-1 back-to-home">
                                        <i className="las la-long-arrow-alt-left fs-3"></i> Back To Home
                                    </a>
                                </div>
                            </div>
                            <div className="user-login-bg">
                                <img src="https://img.freepik.com/free-vector/watercolor-stains-abstract-background_23-2149107181.jpg?w=1380&amp;t=st=1697876324~exp=1697876924~hmac=e56ceb0be0b7f2e52411a3b44051683e739d9a769e5a39321506a064ef9b7a4b" alt="Background" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Verify;
