import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { oneclick } from '../../oneclick';
import ProductPrice from '../../utils/Price';
import { useNavigate } from 'react-router-dom';
import LiveDataComponent from '../Test/LiveDataComponent';
import database_id from '../../utils/databaseConfig';

const MembershipPlans = () => {
    const navigate = useNavigate();
    const [planedata, setPlanedata] = useState([]); // Update from plans to planedata
    const [userdata, setUserdata] = useState([]); // Update from plans to planedata
    const [apiKey, setApiKey] = useState('');
    const loginToken = localStorage.getItem('token');
    const [showamount, setshowamount] = useState(false);

    // Access the tableSchema from environment variables
    const tableSchema = database_id;

    useEffect(() => {
        const tableName = "pricing_plans";
        const tableName1 = "users";
        const orderBy = "created_at DESC";
        const conditions = {
            "login_tockin": loginToken,
        };

        // Ensure the callback is a valid function
        const fetchData = (data) => {
            if (data) {
                console.log('planedata checking :', data);
                setPlanedata(data.data); // Update with fetched data
            } else {
                console.log("No plan data found.");
            }
        };

        const fetchData1 = (data) => {
            if (data) {
                setUserdata(data.data[0]); // Update with fetched data
                setshowamount(true);
            } else {
                console.log("No plan data found.");
            }
        };

        if (loginToken) {
            // Start long polling with a valid callback function
            oneclick.startLongPolling(tableSchema, tableName, "", orderBy, "", fetchData, "");
            oneclick.startLongPolling(tableSchema, tableName1, conditions, orderBy, "", fetchData1, "");
        } else {
            console.warn('No login token found. User is not logged in.');
        }

        // Stop long polling when the component unmounts
        return () => {
            oneclick.stopLongPolling();
        };
    }, [loginToken, tableSchema]);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRecommendedModal, setShowRecommendedModal] = useState(false); // For recommended confirmation modal
    const [selectedPlan, setSelectedPlan] = useState(null); // For the plan being edited or deleted
    const [currentPage, setCurrentPage] = useState(1);
    const [plansPerPage] = useState(10);
    const [recommendedTogglePlan, setRecommendedTogglePlan] = useState(null); // For tracking the recommended toggle

    const handlePageChange = (page) => setCurrentPage(page);

    const handleCloseCreateModal = () => setShowCreateModal(false);

    const handleCreateModal = () => {
        navigate('/plans/create'); // Navigate to the desired route
    };

    const handleUpdateModal = (plan) => {
        setSelectedPlan(plan); // Set the plan to be edited
        setShowUpdateModal(true); // Open the edit modal
    };
    const handleCloseUpdateModal = () => setShowUpdateModal(false);

    const handleDeleteModal = (plan) => {
        setSelectedPlan(plan); // Set the plan to be deleted
        setShowDeleteModal(true); // Open the delete confirmation modal
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleRecommendedChange = (plan) => {
        console.log('handleRecommendedChange:', plan);
        setRecommendedTogglePlan(plan); // Set the plan for the recommended change
        setShowRecommendedModal(true); // Show the confirmation modal
    };

    // const handleRecommendedConfirm = () => {
    //     setPlanedata(planedata.map(plan =>
    //         plan.id === recommendedTogglePlan.id ? { ...plan, recommended: !plan.recommended } : plan
    //     ));
    //     setShowRecommendedModal(false);
    // };
    const handleRecommendedConfirm = async () => {
        try {
            // Update locally first for immediate UI feedback
            const updatedPlanedata = planedata.map(plan =>
                plan.id === recommendedTogglePlan.id ? { ...plan, recommended_status: !plan.recommended_status } : plan
            );
            setPlanedata(updatedPlanedata);
    
            // Prepare data for the API request
            const data = { recommended_status: recommendedTogglePlan.recommended_status == 1 ? 2 : 1 }; // 1 for true, 2 for false
            const condition = { id: recommendedTogglePlan.id };

            const tableName = "pricing_plans";
    
            // Make the API call to update the database
            await oneclick.update(tableSchema, tableName, data, condition);
    
            // Close the modal after the API call is successful
            setShowRecommendedModal(false);
        } catch (error) {
            console.error("Failed to update recommendation status:", error);
            // Handle error (e.g., show error message to user)
        }
    };
    

    const handleCloseRecommendedModal = () => setShowRecommendedModal(false);

    const handleDeleteConfirm = () => {
        setPlanedata(planedata.filter(plan => plan.id !== selectedPlan.id)); // Delete the selected plan
        handleCloseDeleteModal();
    };

    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        const updatedPlans = planedata.map(plan => plan.id === selectedPlan.id ? selectedPlan : plan);
        setPlanedata(updatedPlans); // Update the plan with new data
        handleCloseUpdateModal();
    };

    const handleChange = (e) => {
        setSelectedPlan({ ...selectedPlan, [e.target.name]: e.target.value }); // Handle form changes
    };

    const indexOfLastPlan = currentPage * plansPerPage;
    const indexOfFirstPlan = indexOfLastPlan - plansPerPage;
    const currentPlans = planedata.slice(indexOfFirstPlan, indexOfLastPlan); // Use planedata for slicing
    const totalPages = Math.ceil(planedata.length / plansPerPage); // Use planedata for pagination

    return (
        <div className="dashboard_container">
            <section className="mt-3">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Membership Plan</h4>
                                    <button
                                        className="i-btn primary--btn btn--md text-white"
                                        title="Create New Plan"
                                        onClick={handleCreateModal}
                                    >
                                        Add New
                                    </button>
                                </div>
                                <div className="card-body px-0">
                                    <div className="responsive-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th>SMS Credit</th>
                                                    <th>Email Credit</th>
                                                    <th>WhatsApp Credit</th>
                                                    <th>Duration</th>
                                                    <th>Status</th>
                                                    <th>Recommended</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPlans.map((plan) => (
                                                    <tr key={plan.id}>
                                                        <td>{plan.name}</td>
                                                        <td>
                                                            {showamount ? (
                                                                <>
                                                                    {/* Use ProductPrice component for currency conversion */}
                                                                    <ProductPrice
                                                                        priceInUSD={plan.amount}
                                                                        currencyCode={userdata.currencyCode} // Pass the currency code
                                                                    /> 
                                                                    {userdata.currencyCode}
                                                                </>
                                                            ) : (
                                                                '...'
                                                            )}
                                                        </td>

                                                        <td>{plan.sms && JSON.parse(plan.sms).credits} Sending Credit</td>
                                                        <td>{plan.email && JSON.parse(plan.email).credits} Credit</td>
                                                        <td>{plan.whatsapp && JSON.parse(plan.whatsapp).credits} Credit</td>
                                                        <td>{plan.duration}</td>
                                                        <td>
                                                            <span className={`badge ${plan.status == '1' ? 'badge--success' : 'badge--danger'}`}>
                                                                {plan.status == '1' ? 'Active' : 'Inactive'} {/* Show 'Active' if status is '1', otherwise 'Inactive' */}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={plan.recommended_status == 1} // checked if recommended_status is 1
                                                                    onChange={() => handleRecommendedChange(plan)}
                                                                />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <button className="i-btn primary--btn btn--sm" onClick={() => handleUpdateModal(plan)}>
                                                                Edit
                                                            </button>
                                                            <button className="i-btn danger--btn btn--sm" onClick={() => handleDeleteModal(plan)} style={{ marginLeft: '54px', marginTop: '-30px' }}>
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <Button
                                                key={index + 1}
                                                variant="outline-primary"
                                                onClick={() => handlePageChange(index + 1)}
                                                style={{ margin: '0 5px' }}
                                                active={currentPage === index + 1}
                                            >
                                                {index + 1}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <LiveDataComponent />

            {/* Edit Modal */}
            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Plan</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleUpdateSubmit}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Plan Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={selectedPlan?.name || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="text"
                                name="amount"
                                value={selectedPlan?.amount || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        {/* Add more form fields as needed */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseUpdateModal}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this plan?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Recommended Modal */}
            <Modal show={showRecommendedModal} onHide={handleCloseRecommendedModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Toggle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to {recommendedTogglePlan?.recommended ? 'turn off' : 'turn on'} the recommended status for this plan?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseRecommendedModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleRecommendedConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MembershipPlans;
