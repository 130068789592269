import React, { useState } from 'react';

function OverviewSectionForm() {
  const [formData, setFormData] = useState({
    sub_heading: 'QUICK ACTION',
    heading: 'Fair And Simple Pricing For All.',
    description: 'Select a plan that suits your budget and requirements from the following.',
    btn_left_name: 'Get Start Now',
    btn_left_url: 'http://localhost/xsender/login',
    btn_right_name: 'Try For Free',
    btn_right_url: 'http://localhost/xsender/login',
    images: {
      background_image: null,
      overview_image: null,
      overview_min_image: null
    }
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData(prevData => ({
        ...prevData,
        images: {
          ...prevData.images,
          [name]: files[0]
        }
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (key === 'images') {
        for (const imageKey in formData.images) {
          if (formData.images[imageKey]) {
            formDataToSend.append(`images[${imageKey}]`, formData.images[imageKey]);
          }
        }
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    fetch('http://localhost/getoneclick/admin/frontend/section/save/content/over_view', {
      method: 'POST',
      body: formDataToSend,
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="card mb-3">
          <div className="card-header">
            <h4 className="card-title">Overview Section</h4>
          </div>
          <div className="card-body">
            <div className="form-wrapper">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <input type="hidden" name="_token" value="1ujOHKblmvhxCW697CwYQlPogKUq8ozCDp5GDJeg" />
                <input type="hidden" name="content_type" value="fixed_content" />
                <div className="row g-4 mb-4">
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="background_image" className="form-label">Background image <sup className="text--danger">*</sup></label>
                      <input
                        type="file"
                        className="form-control"
                        id="background_image"
                        name="background_image"
                        onChange={handleChange}
                      />
                      <small>File formats supported: jpeg, jpg, png. The image will be resized to 1900x550 pixels.
                        <a href="http://localhost/getoneclick/assets/file/images/frontend/65d73a89292f11708604041.png" target="__blank" rel="noopener noreferrer">view image</a>
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="overview_image" className="form-label">Overview image <sup className="text--danger">*</sup></label>
                      <input
                        type="file"
                        className="form-control"
                        id="overview_image"
                        name="overview_image"
                        onChange={handleChange}
                      />
                      <small>File formats supported: jpeg, jpg, png. The image will be resized to 1910x910 pixels.
                        <a href="http://localhost/getoneclick/assets/file/images/frontend/663db460965951715319904.png" target="__blank" rel="noopener noreferrer">view image</a>
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="overview_min_image" className="form-label">Overview min image <sup className="text--danger">*</sup></label>
                      <input
                        type="file"
                        className="form-control"
                        id="overview_min_image"
                        name="overview_min_image"
                        onChange={handleChange}
                      />
                      <small>File formats supported: jpeg, jpg, png. The image will be resized to 800x510 pixels.
                        <a href="http://localhost/getoneclick/assets/file/images/frontend/663db460e50861715319904.png" target="__blank" rel="noopener noreferrer">view image</a>
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="sub_heading" className="form-label">Sub heading <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        id="sub_heading"
                        name="sub_heading"
                        value={formData.sub_heading}
                        placeholder="Sub heading"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="heading" className="form-label">Heading <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        id="heading"
                        name="heading"
                        value={formData.heading}
                        placeholder="Heading"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="description" className="form-label">Description <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        id="description"
                        name="description"
                        value={formData.description}
                        placeholder="Description"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="btn_left_name" className="form-label">Btn left name <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        id="btn_left_name"
                        name="btn_left_name"
                        value={formData.btn_left_name}
                        placeholder="Btn left name"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="btn_left_url" className="form-label">Btn left url <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        id="btn_left_url"
                        name="btn_left_url"
                        value={formData.btn_left_url}
                        placeholder="Btn left url"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="btn_right_name" className="form-label">Btn right name <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        id="btn_right_name"
                        name="btn_right_name"
                        value={formData.btn_right_name}
                        placeholder="Btn right name"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="btn_right_url" className="form-label">Btn right url <sup className="text--danger">*</sup></label>
                      <input
                        type="text"
                        className="form-control"
                        id="btn_right_url"
                        name="btn_right_url"
                        value={formData.btn_right_url}
                        placeholder="Btn right url"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="i-btn primary--btn btn--md">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <DeleteModal />
    </div>
  );
}

function DeleteModal() {
  return (
    <div className="modal fade" id="delete-element" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <form action="http://localhost/getoneclick/admin/frontend/section/element/delete" method="POST">
            <input type="hidden" name="_token" value="1ujOHKblmvhxCW697CwYQlPogKUq8ozCDp5GDJeg" />
            <input type="hidden" name="element_id" value="" />
            <div className="modal_body2">
              <div className="modal_icon2">
                <i className="las la-trash"></i>
              </div>
              <div className="modal_text2 mt-3">
                <h6>Are you sure to delete this section element</h6>
              </div>
            </div>
            <div className="modal_button2 modal-footer">
              <div className="d-flex align-items-center justify-content-center gap-3">
                <button type="button" className="i-btn primary--btn btn--md" data-bs-dismiss="modal">Cancel</button>
                <button type="submit" className="i-btn danger--btn btn--md">Delete</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OverviewSectionForm;
