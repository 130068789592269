import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; 

const EmailTemplates = () => {
    const [templates, setTemplates] = useState([]); // Store email templates data
    const [deleteId, setDeleteId] = useState(null); // Store the ID of the template to delete

    // Fetch email templates when the component mounts
    useEffect(() => {
        fetchTemplates();
    }, []);

    // Function to fetch email templates from the backend
    const fetchTemplates = async () => {
        // Example fetch request to get templates (replace with actual API)
        const response = await fetch('/user/email/templates'); // Use actual URL
        const data = await response.json();
        setTemplates(data);
    };

    // Function to handle deleting a template
    const handleDelete = async (id) => {
        setDeleteId(id); // Set the ID of the template to delete
    };

    // Function to confirm the deletion of a template
    const confirmDelete = async () => {
        // Make an API request to delete the template
        await fetch(`/user/email/templates/delete/${deleteId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: deleteId }),
        });

        // After deletion, refetch the templates to update the list
        fetchTemplates();
        setDeleteId(null); // Clear the delete ID after deletion
    };

    return (
        <div className="dashboard_container">
            <section className="mt-3">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title"> Email Templates</h4>
                        <div>
                            <Link to="/EmailTemplateCreate" className="i-btn btn--primary btn--md">
                                <i className="las la-plus"></i>Create Template
                            </Link>
                        </div>
                    </div>
                    <div className="card-body px-0">
                        <div className="responsive-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th> Name</th>
                                        <th> Provider</th>
                                        <th> Status</th>
                                        <th> Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {templates.length > 0 ? (
                                        templates.map((template) => (
                                            <tr key={template.id}>
                                                <td>{template.name}</td>
                                                <td>{template.provider}</td>
                                                <td>{template.status}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => handleDelete(template.id)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#delete"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-muted text-center" colSpan="100%">
                                                No Data Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

            {/* Delete Modal */}
            <div className="modal fade" id="delete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form>
                            <input type="hidden" name="id" value={deleteId} />
                            <div className="modal_body2">
                                <div className="modal_icon2">
                                    <i className="las la-trash"></i>
                                </div>
                                <div className="modal_text2 mt-3">
                                    <h6>Are you sure to delete this Template</h6>
                                </div>
                            </div>
                            <div className="modal_button2 modal-footer">
                                <div className="d-flex align-items-center justify-content-center gap-3">
                                    <button
                                        type="button"
                                        className="i-btn primary--btn btn--md"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="i-btn danger--btn btn--md"
                                        onClick={confirmDelete}
                                        data-bs-dismiss="modal"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailTemplates;
