import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import { oneclick } from "../../oneclick";
import database_id from "../../utils/databaseConfig";

const PrivacyPolicyEditor = () => {
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [saveMessage, setSaveMessage] = useState("");

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        try {
            const tableSchema = database_id;
            const tableName = "frontend_sections";
            const conditions = { id: "26" };
            const response = await oneclick.read(tableSchema, tableName, conditions, "", "");
            const decodedText = decodeHtml(response.data[0].section_value);
            setText(decodedText);
        } catch (error) {
            console.error("Failed to load data:", error);
            alert("Failed to load Privacy Policy data.");
        } finally {
            setIsLoading(false);
        }
    };

    const decodeHtml = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        return doc.documentElement.textContent || "";
    };

    const handleTextChange = (value) => {
        setText(value);
    };

    const handleSaveChanges = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "frontend_sections";
            const data = { section_value: text };
            const conditions = { id: "26" };
            await oneclick.update(tableSchema, tableName, data, conditions);
            setSaveMessage("Privacy Policy Updated Successfully");
            setTimeout(() => setSaveMessage(""), 3000); // Clear message after 3 seconds
        } catch (error) {
            console.error("Failed to update data:", error);
            alert("Failed to update Privacy Policy.");
        }
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            [{ font: [] }],
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["link", "image"],
            ["clean"],
        ],
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "color",
        "background",
        "script",
        "list",
        "indent",
        "align",
        "link",
        "image",
    ];

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Update Privacy Policy</h1>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <ReactQuill
                        value={text}
                        onChange={handleTextChange}
                        modules={modules}
                        formats={formats}
                        style={styles.editor}
                        placeholder="Write or paste your Privacy Policy here..."
                    />
                    <div
                        style={styles.preview}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
                    ></div>
                    <button style={styles.button} onClick={handleSaveChanges}>
                        Save Changes
                    </button>
                    {saveMessage && <p style={styles.saveMessage}>{saveMessage}</p>}
                </>
            )}
        </div>
    );
};

const styles = {
    container: {
        margin: "0 auto",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
    },
    title: {
        fontSize: "2em",
        color: "#333",
    },
    editor: {
        minHeight: "300px",
        marginBottom: "20px",
    },
    preview: {
        border: "1px solid #ddd",
        padding: "20px",
        marginTop: "20px",
        borderRadius: "5px",
        backgroundColor: "#f9f9f9",
    },
    button: {
        padding: "10px 20px",
        fontSize: "1em",
        backgroundColor: "#4CAF50",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
    },
    saveMessage: {
        color: "green",
        fontWeight: "bold",
        marginTop: "10px",
    },
};

export default PrivacyPolicyEditor;
