import React, { useState } from "react";
import { oneclick } from "../../../oneclick";
import database_id from "../../../utils/databaseConfig";
import { getuserdata } from "../../../services/apiService";
import { Link } from "react-router-dom";

const AddNewApi = ({ show, hide, setLoadQRData }) => {
    const [type, setType] = useState("cloud");
    const [device_name, setDeviceName] = useState("");
    const [min_delay, setMinDelay] = useState("");
    const [max_delay, setMaxDelay] = useState("");
    const [portfolioname, setPortfolioName] = useState("");
    const [user_access_token, setUserAccessToken] = useState("");
    const [phone_number_id, setPhoneNumberId] = useState("");
    const [whatsapp_business_account_id, setWhatsappBusinessAccountId] = useState("");
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (min_delay > max_delay) {
            alert("Minimum delay should be less than maximum delay");
            return;
        }

        if (type === "qr") {
            if (device_name === "" || min_delay === "" || max_delay === "") {
                alert("Please fill all the fields");
                return;
            }

            // Generate a dynamic number and format the device name
            const dynamicNumber = Math.floor(1000000 + Math.random() * 9000000);
            const formattedDeviceName = `${dynamicNumber}_${device_name.replace(/\s+/g, '_')}`;
            const tableSchema = database_id;
            const tableName = "whatsapp_qr_device";
            const data = {
                user_id: await getuserdata('id'),
                company_id: await getuserdata('company_id'),
                device_name: formattedDeviceName,
                min_delay,
                max_delay,
                status: "inactive",
            };
            const conditions = {};
            const response = await oneclick.create(tableSchema, tableName, data, '');
            console.log("response", response);

            if (response.success) {
                alert("Device added successfully");
                hide();
                setLoadQRData(true);
            } else {
                alert("Failed to add device");
            }
        } else {
            if (
                portfolioname === "" ||
                user_access_token === "" ||
                phone_number_id === "" ||
                whatsapp_business_account_id === ""
            ) {
                alert("Please fill all the fields");
                return;
            }
            const tableSchema = database_id;
            const tableName = "whatsapp_cloud_device";
            const data = {
                user_id: await getuserdata('id'),
                company_id: await getuserdata('company_id'),
                portfolioname,
                user_access_token,
                phone_number_id,
                whatsapp_business_account_id,
                status: "inactive",
            };
            const conditions = {};
            const response = await oneclick.create(tableSchema, tableName, data, conditions);
            console.log("response", response);
            if (response.success) {
                alert("Device added successfully");
                hide();
                setLoadQRData(true);
            } else {
                alert("Failed to add device");
            }
        }
        console.log("Form submitted");
    };

    return (
        <div>
            <div
                className={`modal fade ${show ? "show" : ""}`}
                style={{ display: show ? "block" : "none" }}
                tabIndex="-1"
                aria-labelledby="addNewLabel"
                aria-hidden={!show}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addNewLabel">
                                Add New WhatsApp Api
                            </h5>
                            <button type="button" className="btn-close" onClick={hide}>
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Select Api Type
                                    </label>
                                    <div className="filter-item">
                                        <select
                                            name="status"
                                            className="form-select"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            <option value="">Select Type</option>
                                            <option value="qr">QR</option>
                                            <option value="cloud">Cloud</option>
                                        </select>
                                    </div>
                                </div>

                                {type === "qr" ? (
                                    <div className="">
                                        <div className="row g-4">
                                            <div className="col-lg-12">
                                                <div className="form-inner">
                                                    <label
                                                        htmlFor="name"
                                                        className="form-label"
                                                    >
                                                        Session/Device Name
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value={device_name}
                                                        placeholder="Enter Whatsapp Session Name"
                                                        className="form-control"
                                                        aria-label="name"
                                                        onChange={(e) => setDeviceName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-inner">
                                                    <label
                                                        htmlFor="min_delay"
                                                        className="form-label"
                                                    >
                                                        Minimum Delay
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="min_delay"
                                                        name="min_delay"
                                                        value={min_delay}
                                                        placeholder="Enter Minimum Delay For This Device"
                                                        className="form-control"
                                                        aria-label="min_delay"
                                                        onChange={(e) => setMinDelay(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-inner">
                                                    <label
                                                        htmlFor="max_delay"
                                                        className="form-label"
                                                    >
                                                        Maximum Delay
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="max_delay"
                                                        name="max_delay"
                                                        value={max_delay}
                                                        placeholder="Enter Maximum Delay For This Device"
                                                        className="form-control"
                                                        aria-label="max_delay"
                                                        onChange={(e) => setMaxDelay(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12 mb-4">
                                                <label htmlFor="name">
                                                    Business Portfolio Name{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control mt-2"
                                                    name="name"
                                                    id="name"
                                                    value={portfolioname}
                                                    placeholder="Add a name for your Business Portfolio"
                                                    autoComplete="true"
                                                    onChange={(e) => setPortfolioName(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="user_access_token">
                                                    User Access Token{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="user_access_token"
                                                    className="form-control mt-2"
                                                    name="credentials[user_access_token]"
                                                    placeholder="Enter the User Access Token"
                                                    value={user_access_token}
                                                    onChange={(e) => setUserAccessToken(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="phone_number_id">
                                                    Phone Number Id{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="phone_number_id"
                                                    className="form-control mt-2"
                                                    name="credentials[phone_number_id]"
                                                    placeholder="Enter the Phone Number Id"
                                                    value={phone_number_id}
                                                    onChange={(e) => setPhoneNumberId(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="whatsapp_business_account_id">
                                                    Whatsapp Business Account Id{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="whatsapp_business_account_id"
                                                    className="form-control mt-2"
                                                    name="credentials[whatsapp_business_account_id]"
                                                    placeholder="Enter the Whatsapp Business Account Id"
                                                    value={whatsapp_business_account_id}
                                                    onChange={(e) =>
                                                        setWhatsappBusinessAccountId(e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <small>
                                                    Now to set up your webhook please click here
                                                    to collect credentials:{" "}
                                                    <Link
                                                        className="fw-bold text-dark text-decoration-underline"
                                                        to="/webhook"
                                                        rel="noreferrer"
                                                    >
                                                        Webhook Configuration
                                                    </Link>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="modal-footer" style={{ paddingRight: "0px" }}>
                                    <button type="submit" className="btn btn-primary">
                                        {loading ? "Adding..." : "Add New Api"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNewApi;
