import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { oneclick } from '../../../oneclick'; // Adjust import for your oneclick API service
import { bulkEmptyOrDropTables, bulkDeleteRows } from '../../../services/apiService';
import database_id from '../../../utils/databaseConfig';

const BulkActionModal = ({ onClose, selectedRows, fetchData, setAllChecked, type, ip, username, password, dbName, setCheckedrows, tablenamenew }) => {
  const [status, setStatus] = useState('active');
  const [action, setAction] = useState('empty'); // Add action state to track 'empty' or 'drop'

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value); // Set action to 'empty' or 'drop'
  };

  const handleApplyAction = async () => {
    try {
      if (type === "realtimetables") {
        const tables = selectedRows;
        // Set status based on action (empty or drop)
        const actionStatus = action === 'empty' ? 'empty' : 'drop';

        const response = await bulkEmptyOrDropTables(ip, username, password, dbName, tables, actionStatus);
        if (response.success) {
          alert(response.message);
          fetchData(); // Re-fetch the data
          setAllChecked(false);
          onClose(); // Close the modal
          setCheckedrows([]);
        } else {
          alert(response.message);
        }
      } else if (type === "realtimetablescolumns") {
        const rows = selectedRows;


        const response = await bulkDeleteRows(ip, username, password, dbName, tablenamenew, rows);
        if (response.success) {
          alert(response.message);
          fetchData(); // Re-fetch the data
          setAllChecked(false);
          onClose(); // Close the modal
          setCheckedrows([]);
        } else {
          alert(response.message);
        }
      } else {
        if (!selectedRows || selectedRows.length === 0) {
          alert('No rows selected');
          return;
        }

        // Prepare the updated data for the selected groups
        const updatedData = {
          status: status === 'active' ? '1' : '2', // Assuming '1' for Active and '2' for Inactive
        };

        const tableSchema = database_id;
        const tableName = type === 'group' ? 'groups' : 'contacts';

        // Loop through selected rows and update them
        const updatePromises = selectedRows.map((rowId) => {
          console.log('Updating group with ID:', rowId);
          const condition = { id: rowId }; // Update based on group id
          return oneclick.update(tableSchema, tableName, updatedData, condition);
        });

        const results = await Promise.all(updatePromises);

        if (results.every(result => result.success)) {
          alert('Status updated successfully for selected groups!');
          fetchData(); // Re-fetch the data
          setAllChecked(false);
          onClose(); // Close the modal
        } else {
          alert('Failed to update some groups. Please try again.');
        }
      }

    } catch (error) {
      console.error('Error updating groups:', error);
      alert('An error occurred while updating the groups.');
    }
  };

  const handleDeleteAction = async () => {
    try {
      if (!selectedRows || selectedRows.length === 0) {
        alert('No rows selected');
        return;
      }

      const tableSchema = database_id;
      const tableName = type === 'group' ? 'groups' : 'contacts';

      // Loop through selected rows and delete them
      const deletePromises = selectedRows.map((rowId) => {
        console.log('Deleting group with ID:', rowId);
        const condition = { id: rowId }; // Delete based on group id
        return oneclick.delete(tableSchema, tableName, condition);
      });

      const results = await Promise.all(deletePromises);

      if (results.every(result => result.success)) {
        alert('Groups deleted successfully!');
        fetchData(); // Re-fetch the data
        onClose(); // Close the modal
      } else {
        alert('Failed to delete some groups. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting groups:', error);
      alert('An error occurred while deleting the groups.');
    }
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Bulk Actions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === 'realtimetables' ? (
          <>
            <p>Select the action you want to perform on the selected tables:</p>
            <ul>
              {selectedRows.map((table, index) => (
                <li key={index}>{table}</li> // Display each table name
              ))}
            </ul>
            <Form>
              <Form.Group controlId="actionSelect">
                <Form.Label>Action</Form.Label>
                <Form.Control as="select" value={action} onChange={handleActionChange} required>
                  <option value="empty">Empty</option>
                  <option value="drop">Drop</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </>
        ) : type === 'realtimetablescolumns' ? (
          <>
            <p>Select the action you want to perform on the selected {tablenamenew} : Data</p>
            <ul>
              {selectedRows.map((column, index) => (
                <li key={index}>{column}</li> // Display each column name
              ))}
            </ul>
            <Form>
            </Form>
          </>
        ) : (
          <>
            <p>Select the action you want to perform on selected {type === 'group' ? 'Group' : 'Contact'}.</p>
            <Form>
              <Form.Group controlId="updateStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" value={status} onChange={handleStatusChange} required>
                  {type === 'realtimetables' ? (
                    <>
                      <option value="empty">Empty</option>
                      <option value="drop">Drop</option>
                    </>
                  ) : (
                    <>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </>
                  )}
                </Form.Control>
              </Form.Group>
            </Form>
          </>

        )}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        {type === 'realtimetables' ? (
          <>
            <Button variant="primary" onClick={handleApplyAction}>
              Update
            </Button>
          </>
        ) : type === 'realtimetablescolumns' ? (
          <>
            <Button variant="primary" onClick={handleApplyAction}>
              Delete
            </Button>
          </>
        ) : (

          <>
            <Button variant="primary" onClick={handleApplyAction}>
              Apply Status
            </Button>
            <Button variant="danger" onClick={handleDeleteAction}>
              Delete
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BulkActionModal;
