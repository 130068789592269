import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getallcurrencies, general_settings, createCurrency, updateCurrency, deleteCurrency } from '../../services/apiService';
import AddCurrencyModal from './Component/AddCurrencyModal';
import UpdateCurrencyModal from './Component/UpdateCurrencyModal';

const CurrencyManagement = () => {
  const [currencies, setCurrencies] = useState([]);
  const [filteredCurrencies, setFilteredCurrencies] = useState([]);
  const [generaldata, setGeneralData] = useState({});
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [dataLoad, setDataLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Fetch the currencies on component mount
  useEffect(() => {
    const fetchData = async () => {
      if (dataLoad) {
        try {
          const settings = await general_settings();
          setGeneralData(settings[0] || {}); // Set general data

          const currencyData = await getallcurrencies();
          setCurrencies(currencyData.data);
          setFilteredCurrencies(currencyData.data);
          setTotalPages(Math.ceil(currencyData.data.length / 20)); // Set total pages
        } catch (error) {
          console.error('Error fetching currencies:', error);
        }
      }
    };

    fetchData();
  }, [dataLoad]);

  // Filter currencies based on the search term
  useEffect(() => {
    const filtered = currencies.filter(currency =>
      currency.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      currency.symbol.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCurrencies(filtered);
    setTotalPages(Math.ceil(filtered.length / 20)); // Update total pages based on filtered results
    setCurrentPage(1); // Reset to first page on search
  }, [searchTerm, currencies]);

  // Handle creating a new currency
  const handleCreateCurrency = async (newCurrencyData) => {
    try {
      const newCurrency = await createCurrency(newCurrencyData.symbol, newCurrencyData.name, newCurrencyData.symbol, newCurrencyData.rate, newCurrencyData.status);
      setCurrencies(prevCurrencies => [...prevCurrencies, newCurrency]);
      setFilteredCurrencies(prevFiltered => [...prevFiltered, newCurrency]);
      setShowCreateModal(false);
    } catch (error) {
      console.error('Error creating currency:', error);
    }
  };

  // Handle pagination
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Function to handle updating an existing currency
  const handleUpdateCurrency = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updatedCurrencyData = {
      id: formData.get('id'), // Make sure to include the ID in the request body
      code: currentCurrency.code, // If you have a code
      name: formData.get('name'),
      symbol: formData.get('symbol'),
      rate: formData.get('rate'), // Send the rate as a string
      status: formData.get('status') === '1' ? '1' : '2'
    };

    try {
      const response = await updateCurrency(updatedCurrencyData); // Call the updated API method
      // Update the local state
      setCurrencies(prevCurrencies =>
        prevCurrencies.map(currency =>
          currency.id === currentCurrency.id ? { ...currency, ...updatedCurrencyData } : currency
        )
      );
      setFilteredCurrencies(prevFiltered =>
        prevFiltered.map(currency =>
          currency.id === currentCurrency.id ? { ...currency, ...updatedCurrencyData } : currency
        )
      );
      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating currency:', error);
    }
  };

  const handleDeleteCurrency = async (currencyId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this currency?');
    if (!confirmDelete) return;

    try {
      await deleteCurrency(currencyId); // Call the delete API using DELETE
      setCurrencies(prevCurrencies => prevCurrencies.filter(currency => currency.id !== currencyId)); // Update local state
      setFilteredCurrencies(prevFiltered => prevFiltered.filter(currency => currency.id !== currencyId)); // Update the filtered currencies
      alert('Currency deleted successfully.');
    } catch (error) {
      console.error('Error deleting currency:', error);
      alert('An error occurred while deleting the currency.');
    }
  };

  // Calculate start and end index for pagination
  const itemsPerPage = 20;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className="dashboard_container">
      <section>
        <div className="card mb-4">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h4 className="card-title">Currencies List</h4>
            <div style={{ display: 'flex' }}>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control"
                style={{ width: '250px', marginRight: '10px' }}
              />
              <button
                className="i-btn primary--btn btn--md text-white"
                onClick={openModal}
                title="Create New Currency"
              >
                Add New
              </button>
            </div>
          </div>
          <div className="card-body px-0">
            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Symbol</th>
                    <th>Rate</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCurrencies.slice(startIndex, endIndex).map(currency => (
                    <tr key={currency.id}>
                      <td>{currency.name}</td>
                      <td>{currency.symbol}</td>
                      <td>1 {generaldata.currency_name} = {currency.rate} {currency.name}</td>
                      <td data-label="Status">
                        <span className={`badge ${currency.status == '1' ? 'badge--success' : 'badge--danger'}`}>
                          {currency.status == '1' ? 'Active' : 'Inactive'}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                          <button
                            className="i-btn primary--btn btn--sm currency-data"
                            onClick={() => { setCurrentCurrency(currency); setShowUpdateModal(true); }}
                          >
                            <i className="las la-pen"></i>
                          </button>
                          <button
                              className="i-btn danger--btn btn--sm currency-data"
                              onClick={() => handleDeleteCurrency(currency.id)} // Call the delete handler
                          >
                              <i className="las la-trash"></i>
                          </button>
                        </div>
                      </td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Pagination Controls */}
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <div className="pagination-controls">
            <button
              className="btn btn-primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button
              className="btn btn-primary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <AddCurrencyModal
          generaldata={generaldata}
          isOpen={isModalOpen}
          closeModal={closeModal}
          onCreateCurrency={handleCreateCurrency} // Pass the callback
        />
      )}

      {/* Update Currency Modal */}
      <UpdateCurrencyModal
        generaldata={generaldata}
        showModal={showUpdateModal}
        handleClose={() => setShowUpdateModal(false)}
        currency={currentCurrency}
        handleUpdateCurrency={handleUpdateCurrency} // Pass the callback to the modal
      />
    </div>
  );
};

export default CurrencyManagement;

