import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import { oneclick } from "../../oneclick";
import database_id from "../../utils/databaseConfig";
import ImageComponent from "../../utils/ImageComponent";
import AddAiStaff from "./Components/AddAiStaff";
import { decodeBase64, encodeBase64 } from "../../services/apiService";

const TeamOverView = () => {

    const [team, setTeam] = useState([]);
    const [staff, setStaff] = useState([]);
    const [searchinput, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [filterdeportment, setfilterdeportment] = useState('');
    const [showAdddepartmentModal, setShowAdddepartmentModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [emailData, setEmailData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [userprofile, setUserprofile] = useState({});
    const [profile_image, setProfile_image] = useState('');
    const { teamdata } = useParams(); // Retrieve the URL parameter
    const [selecttabe, setSelecttabe] = useState('teamoverview');
    const [showAddStaffModal, setShowAddStaffModal] = useState(false);

    useEffect(() => {
        fetchTeam();
        fetchStaff();
        fetchTeamDepartment();
    }, []);


    const fetchTeam = async () => {

        setIsLoading(true);

        const tableSchema = database_id;
        const tableName = 'ai_teams';
        const conditions = { id: decodeBase64(teamdata) };
        const orderBy = "id ASC";
        const search = {};
        const pagination = {};
        const margedata = [
            {
                target_label: "ai_team_department",
                target_table: "ai_staff",
                target_column: "team_id",
                target_value: "id"
            }
        ];

        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

        console.log('response:', response);
        if (response.success) {
            setTeam(response.data[0]);
            setIsLoading(false);
        } else {
            console.error('Error fetching team:', response.error);
            setIsLoading(false);
            alert('Sorry, we could not fetch the team.');
        }
    };

    const fetchTeamDepartment = async () => {   
        setIsLoading(true);

        const tableSchema = database_id;
        const tableName = 'departments';
        const conditions = { id: team.department_id };
        const orderBy = "id ASC";
        const search = {};
        const pagination = {};
        const margedata = [];

        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

        console.log('response department:', response);
        if (response.success) {
            setEmailData(response.data);
            setIsLoading(false);
        } else {
            console.error('Error fetching team department:', response.error);
            setIsLoading(false);
            alert('Sorry, we could not fetch the team department.');
        }
    };

    const fetchStaff = async () => {
        
        setIsLoading(true);

        const tableSchema = database_id;
        const tableName = 'ai_staff';
        const conditions = { team_id: decodeBase64(teamdata) };
        const orderBy = "id ASC";
        const search = {};
        const pagination = {};
        const margedata = [];

        const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);

        console.log('response:', response);
        if (response.success) {
            setStaff(response.data);
            setIsLoading(false);
        } else {
            console.error('Error fetching staff:', response.error);
            setIsLoading(false);
            alert('Sorry, we could not fetch the staff.');
        }
    };

    const handleFormSubmit = (e) => {   
        e.preventDefault();
        fetchStaff();
    };

    const navigate = useNavigate();

    const handletrining = () => {
        console.log('Training');

        // Redirect to the training page
        navigate(`/ai/AiStaffTraining/${encodeBase64(team.id)}`);
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <ImageComponent fileid={team.image} width={50} height={50} borderRadius={50} boxShadow="" />
                                </div>
                                <br />
                                <div>
                                    <h4 className="card-title" style={{marginLeft: '10px'}}>{team.name}</h4>
                                    <p className="card-subtitle" style={{marginLeft: '10px', fontSize: '15px'}}>No. Staff _ {staff.length}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-filter">
                            <form onSubmit={handleFormSubmit}>
                                <div className="filter-form">
                                    <div className="filter-item">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            name="search"
                                            placeholder="Search With Username"
                                            className="form-control"
                                            value={searchinput}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>

                                    <div className="filter-item">
                                        <select
                                            name="status"
                                            className="form-select"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="DeActive">DeActive</option>
                                            <option value="Completed">Completed</option>
                                            <option value="Ongoing">Ongoing</option>
                                        </select>
                                    </div>

                                    <div className="filter-item">
                                        <select
                                            name="status"
                                            className="form-select"
                                            value={filterdeportment}
                                            onChange={(e) => setfilterdeportment(e.target.value)}
                                        >
                                            <option value="">Select Department</option>
                                            {emailData.length > 0 ? (
                                                emailData.map((data) => {
                                                    return (
                                                        <option key={data.id} value={data.id}>
                                                            {data.name} {/* Display only the domain name */}
                                                        </option>
                                                    );
                                                })
                                            ) : (
                                                <option disabled>No available</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="filter-action">
                                        <button
                                            type="button"
                                            className="i-btn primary--btn btn--md"
                                            onClick={() => handletrining()}
                                        >
                                            Training
                                        </button>

                                        {/* Add New Button */}
                                        <button
                                            type="button"
                                            className="i-btn primary--btn btn--md text-white"
                                            onClick={() => setShowAddStaffModal(true)}
                                        >
                                            Add New Staff
                                        </button>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>
                                                <button
                                                    type="button"
                                                    className={`btn ${selecttabe == 'teamoverview' && 'primary--btn text-white' } btn--md text-black`}
                                                    onClick={() => setSelecttabe('teamoverview')}
                                                >
                                                    Team Overview
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className={`btn ${selecttabe == 'teamfeedback' && 'primary--btn text-white' } btn--md text-black`}
                                                    onClick={() => setSelecttabe('teamfeedback')}
                                                >
                                                    Team Feedback
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className={`btn ${selecttabe == 'teamtrainingdata' && 'primary--btn text-white' } btn--md text-black`}
                                                    onClick={() => setSelecttabe('teamtrainingdata')}
                                                >
                                                    Team Training Data
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    type="button"
                                                    className={`btn ${selecttabe == 'teamstafftests' && 'primary--btn text-white' } btn--md text-black`}
                                                    onClick={() => setSelecttabe('teamstafftests')}
                                                >
                                                    Team Staff Tests
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showAddStaffModal && (
                <AddAiStaff show={showAddStaffModal} hide={() => setShowAddStaffModal(false)} fetchEmailData={fetchStaff} Department={emailData} TeamData={team} />
            )}
        </div>
    );

};

export default TeamOverView;