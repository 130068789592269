import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { oneclick } from '../../oneclick';
import ProductPrice from '../../utils/Price';
import { suspendCpanelAccount, encodeBase64 } from '../../services/apiService';
import database_id from '../../utils/databaseConfig';

const HistrySharedHost = ({ title, errors, data = { search: '' } }) => {
    const [search, setSearch] = useState(data.search || '');
    const [contactData, setContactData] = useState([]);
    const [userdata, setUserdata] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [contactsPerPage] = useState(10);
    const [paymentDetails, setpaymentDetails] = useState({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    // Fetch contact data only once on component mount
    useEffect(() => {
        const fetchContacts = async () => {
            setLoading(true);
            const loginToken = localStorage.getItem('token');
            const tableSchema = database_id;
            const tableName = "users";
            const conditions = { "login_tockin": loginToken };
            const orderBy = "created_at DESC";
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy);
            const userId = response.data[0]?.id; // Optional chaining for safety

            setUserdata(response.data[0]);

            const tableName1 = "hostings";
            const conditions1 = { "user_id": userId };
            const orderBy1 = "created_at DESC";
            const response1 = await oneclick.read(tableSchema, tableName1, conditions1, orderBy1);


            console.log('Fetched hosting data:', response1);

            if (response1.success) {
                const newdata = response1.data || [];
                console.log('No hosting data found');
                // Handle WHM account suspension
                await handleAccountSuspension(newdata);

                setContactData(newdata);
                setFilteredContacts(newdata); // Initialize filtered contacts with fetched data
                setLoading(false);
            }
        };

        fetchContacts();
    }, []);

    const handleAccountSuspension = async (hostings) => {
        const suspendedHostings = hostings.filter(hosting => hosting.status === 0);

        for (let hosting of suspendedHostings) {
            try {
                if (hosting.account_status === 'Suspended') {
                    console.log(`Account ${hosting.username} is already suspended. Skipping...`);
                    continue;
                }

                const whmUsername = process.env.REACT_APP_WHM_USERNAME;
                const whmPassword = process.env.REACT_APP_WHM_PASSWORD;
                const whmHost = process.env.REACT_APP_WHM_HOST;
                const username = hosting.username;
                const reason = "Overdue payments";

                await suspendCpanelAccount(whmHost, whmUsername, whmPassword, username, reason);
                console.log("Suspended WHM account for:", username);
            } catch (error) {
                console.error("Error suspending WHM account for:", hosting.username, error);
            }
        }
    };

    // Update filtered contacts based on search input
    useEffect(() => {
        const filtered = contactData.filter(contact =>
            contact.domain.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredContacts(filtered);
        setCurrentPage(1); // Reset to first page when search changes
    }, [search, contactData]);

    // Pagination logic
    const indexOfLastContact = currentPage * contactsPerPage;
    const indexOfFirstContact = indexOfLastContact - contactsPerPage;
    const currentContacts = filteredContacts.slice(indexOfFirstContact, indexOfLastContact);
    const totalPages = Math.ceil(filteredContacts.length / contactsPerPage);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleReset = () => {
        setSearch('');
        navigate('/user/contact/index');
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Define the handle function
    // const handleCpanelLogin = () => {

    //     const loginUrl = `https://${cpanelHost}:2083/login/?user=${username}&pass=${password}`;
    //     window.open(loginUrl, '_blank', 'noopener,noreferrer');
    // };

    const handleCpanelLogin = async (hostingdata) => {
        const tableSchema = database_id;
        const tableName = "services";
        const conditions = {
            services_id: hostingdata.id,
            payment_id: ""
        };

        const response = await oneclick.read(tableSchema, tableName, conditions, "", "");


        setpaymentDetails({
            // amount: response.data[0].today_bill, 
            // username, 
            // email, 
            // redirectlink, 
            // user_id, 
            // domain, 
            // password, 
            // newPlan, 
            // cpanel_username, 
            // servicesid, 
            // USERcurrencyCode, 
            // hostingid 
        });

    };

    const handleDeleteContact = (username) => {
        setContactData(prevContacts => prevContacts.filter(contact => contact.username !== username));
        setFilteredContacts(prevContacts => prevContacts.filter(contact => contact.username !== username));
    };

    const handleDownloadInvoice = (id) => {
        window.location.href = `/user/invoices/download/${id}`;
    };

    return (
        <section>
            <div className="card mb-4">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>

                <div className="card-filter">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="filter-form">
                            <div className="filter-item">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Type Domain"
                                    className="form-control"
                                    id="search"
                                    value={search}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            <div className="filter-action">
                                <button className="i-btn info--btn btn--md" type="button">
                                    <i className="fas fa-search"></i> Search
                                </button>
                                <button
                                    className="i-btn danger--btn btn--md text-white"
                                    type="button"
                                    onClick={handleReset}
                                >
                                    <i className="las la-sync"></i> Reset
                                </button>
                                <Link
                                    to="/shared-hosting-create"
                                    className="i-btn primary--btn btn--md text-white"
                                    title="Add New Contact"
                                >
                                    <i className="fa fa-plus"></i> Add New
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="card-body px-0">
                    <div className="responsive-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sl No.</th>
                                    <th>Domain</th>
                                    <th>Package</th>
                                    <th>Sharing</th>
                                    <th>Status</th>
                                    <th>Next Bill</th>
                                    <th>Bill Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-muted text-center" colSpan="8">
                                            <i className="fa fa-spinner fa-spin"></i> Loading...
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {currentContacts.length ? currentContacts.map((contact, index) => (
                                            <tr key={contact.id}>
                                                <td>{index + 1 + indexOfFirstContact}</td>
                                                <td>
                                                    <a href={`https://www.${contact.domain}`} className="badge badge--primary p-2" target="_blank">
                                                        www.{contact.domain}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href={`/user/contact/group/${contact.product_id}`} className="badge badge--primary p-2">
                                                        {contact.plan_name}
                                                    </a>
                                                </td>
                                                <td>{contact.account_share || <span className="badge badge--info">N/A</span>}</td>
                                                <td>
                                                    {contact.status === 0 && <span className="badge badge--danger">Pending</span>}
                                                    {contact.status === 1 && <span className="badge badge--success">Run</span>}
                                                    {contact.status === 2 && <span className="badge badge--danger">Suspend</span>}
                                                    {contact.status === 3 && <span className="badge badge--info" style={{ background: 'black', color: 'white' }}>Terminate</span>}
                                                    {contact.status === 4 && <span className="badge badge--info" style={{ background: 'black', color: 'white' }}>Delete</span>}
                                                </td>
                                                <td>{contact.next_billdate || <span className="badge badge--info">N/A</span>}</td>
                                                <td>
                                                    <ProductPrice
                                                        priceInUSD={contact.plan_amount}
                                                        currencyCode={userdata.currencyCode}
                                                    />&nbsp;
                                                    <span className="badge badge--info">{userdata.currencyCode}</span>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                                                        {contact.status === 1 ? (
                                                            <a
                                                                className="i-btn info--btn btn--sm"
                                                                href={`https://${contact.cpanel_host}:2083/login/?user=${contact.username}&pass=${contact.password}`}
                                                                title="Login cPanel"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="fa fa-sign-in"></i>
                                                            </a>
                                                        ) : (
                                                            <button
                                                                className="i-btn info--btn btn--sm"
                                                                onClick={() => handleCpanelLogin(contact)}
                                                                title="Login cPanel"
                                                            >
                                                                <i className="fa fa-credit-card"></i> {/* Pay Icon */}
                                                            </button>
                                                        )}

                                                        <Link
                                                            className="i-btn danger--btn btn--sm"
                                                            to={`/shared-hosting-details/${encodeBase64(contact.id)}`}
                                                            style={{ backgroundColor: '#299cdb' }}
                                                            title="Download Invoice"
                                                        >
                                                            <i className="fa fa-download"></i>
                                                        </Link>
                                                        {/* <a
                                                    className="i-btn danger--btn btn--sm"
                                                    href="#"
                                                    onClick={() => handleDeleteContact(contact.username)}
                                                    title="Delete Contact"
                                                >
                                                    <i className="las la-trash"></i>
                                                </a> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td className="text-muted text-center" colSpan="8">
                                                    <i className="fa fa-box-open"></i> No Data Available
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
                {Array.from({ length: totalPages }, (_, index) => (
                    <Button key={index + 1} variant="outline-primary" onClick={() => handlePageChange(index + 1)} style={{ margin: '0 5px' }} active={currentPage === index + 1}>
                        {index + 1}
                    </Button>
                ))}
            </div>
        </section>
    );
};

export default HistrySharedHost;
