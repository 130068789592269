import React, { useState } from "react";

const RecaptchaSettings = () => {
  // State management for form fields
  const [captchaWithRegistration, setCaptchaWithRegistration] = useState(false);
  const [captchaWithLogin, setCaptchaWithLogin] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState("####");
  const [recaptchaSecret, setRecaptchaSecret] = useState("###");
  const [recaptchaStatus, setRecaptchaStatus] = useState("2");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic here
    const formData = {
      captchaWithRegistration,
      captchaWithLogin,
      recaptchaKey,
      recaptchaSecret,
      recaptchaStatus,
    };

    console.log("Form submitted:", formData);

    // You can submit the formData to the server as needed
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="container-fluid p-0">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Recaptcha Settings</h4>
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-wrapper">
                  <h6 className="form-wrapper-title">Applicability</h6>
                  <div className="row g-4">
                    <div className="col-xl-6 col-md-6">
                      <div className="switch-container">
                        <label
                          className="form-check-label"
                          htmlFor="captcha_with_registration"
                        >
                          Captcha With Registration
                        </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={captchaWithRegistration}
                            onChange={(e) =>
                              setCaptchaWithRegistration(e.target.checked)
                            }
                            id="captcha_with_registration"
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6">
                      <div className="switch-container">
                        <label
                          className="form-check-label"
                          htmlFor="captcha_with_login"
                        >
                          Captcha With Login
                        </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={captchaWithLogin}
                            onChange={(e) =>
                              setCaptchaWithLogin(e.target.checked)
                            }
                            id="captcha_with_login"
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-wrapper">
                  <h6 className="form-wrapper-title">Credentials</h6>
                  <div className="row g-4">
                    <div className="col-md-6">
                      <div className="form-item">
                        <label htmlFor="recaptcha_key" className="form-label">
                          Key <sup className="text--danger">*</sup>
                        </label>
                        <input
                          type="text"
                          name="recaptcha_key"
                          id="recaptcha_key"
                          className="form-control"
                          value={recaptchaKey}
                          onChange={(e) => setRecaptchaKey(e.target.value)}
                          placeholder="Enter reCaptcha Key"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-item">
                        <label
                          htmlFor="recaptcha_secret"
                          className="form-label"
                        >
                          Secret Key <sup className="text--danger">*</sup>
                        </label>
                        <input
                          type="text"
                          name="recaptcha_secret"
                          id="recaptcha_secret"
                          className="form-control"
                          value={recaptchaSecret}
                          onChange={(e) => setRecaptchaSecret(e.target.value)}
                          placeholder="Enter reCaptcha Secret Key"
                          required
                        />
                      </div>
                    </div>

                    <div className="col">
                      <div className="form-item">
                        <label
                          htmlFor="recaptcha_status"
                          className="form-label"
                        >
                          Status <sup className="text--danger">*</sup>
                        </label>
                        <select
                          className="form-select"
                          id="recaptcha_status"
                          name="recaptcha_status"
                          value={recaptchaStatus}
                          onChange={(e) => setRecaptchaStatus(e.target.value)}
                          required
                        >
                          <option value="1">Active</option>
                          <option value="2">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="i-btn primary--btn btn--lg">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RecaptchaSettings;
