import axios from 'axios';
import apiUrl from '../utils/apiConfig';

const appmode = process.env.REACT_APP_MODE; 
const API_URL = appmode == 'LIVE' ? process.env.REACT_APP_IMAGE_BASE_LIVEURL : process.env.REACT_APP_IMAGE_BASE_LOCALURL;

const api_key = process.env.REACT_APP_API_KEY;
const token = localStorage.getItem('token'); // Example: get token from localStorage
const headers = {'x-api-key': api_key};

export const SharedHostingPlanes = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/cloud/gethostingplans`, {headers});
    console.log('API Plans Response:', response); // Log the response
    return response;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const GetRegion = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/cloud/getregion`, {headers});
    console.log('API Plans Response:', response); // Log the response
    return response;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const CloudVpsPlanes = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/cloud/getvpsplans`, {headers});
    console.log('API Plans Response:', response); // Log the response
    return response;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};