import React, { useState } from 'react';

// Sample data for invoices
const invoiceData = [
  { id: 1, invoiceNumber: 'INV-001', customer: 'John Doe', amount: 250, status: 'paid' },
  { id: 2, invoiceNumber: 'INV-002', customer: 'Jane Smith', amount: 150, status: 'unpaid' },
  { id: 3, invoiceNumber: 'INV-003', customer: 'Robert Johnson', amount: 300, status: 'paid' },
  { id: 4, invoiceNumber: 'INV-004', customer: 'Emily Davis', amount: 400, status: 'unpaid' },
  { id: 5, invoiceNumber: 'INV-005', customer: 'Alice Cooper', amount: 200, status: 'paid' },
  { id: 6, invoiceNumber: 'INV-006', customer: 'Bob Marley', amount: 450, status: 'paid' },
  { id: 7, invoiceNumber: 'INV-007', customer: 'Charlie Brown', amount: 350, status: 'paid' },
  { id: 8, invoiceNumber: 'INV-008', customer: 'Diana Prince', amount: 500, status: 'paid' },
  { id: 9, invoiceNumber: 'INV-009', customer: 'Evan Peters', amount: 600, status: 'paid' },
  { id: 10, invoiceNumber: 'INV-010', customer: 'Fiona Apple', amount: 700, status: 'paid' },
  // Add more invoices here if needed
];

const PaidInvoiceList = () => {
  const [status, setStatus] = useState('paid'); // Default to 'paid'
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const invoicesPerPage = 10; // Set to 10 invoices per page
  const [filteredInvoices, setFilteredInvoices] = useState(
    invoiceData.filter((invoice) => invoice.status === 'paid') // Initial filter for paid invoices
  );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    filterInvoices(e.target.value, status);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    filterInvoices(search, e.target.value);
  };

  const filterInvoices = (searchTerm, statusFilter) => {
    let filtered = invoiceData.filter(
      (invoice) =>
        (statusFilter === 'all' || invoice.status === statusFilter) &&
        (invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          invoice.customer.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredInvoices(filtered);
    setCurrentPage(1); // Reset to the first page whenever filtering
  };

  const handleReset = () => {
    setStatus('paid'); // Reset to 'paid'
    setSearch('');
    setFilteredInvoices(invoiceData.filter((invoice) => invoice.status === 'paid')); // Reset to paid invoices
    setCurrentPage(1); // Reset to first page
  };

  const handleView = (id) => {
    console.log(`Viewing invoice ${id}`);
  };

  const handleDownload = (id) => {
    console.log(`Downloading invoice ${id}`);
  };

  // Pagination Logic
  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
  const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);

  // Function to change the page
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="card mb-4">
          <div className="card-header">
            <h4 className="card-title">Paid Invoice List</h4> {/* Changed title */}
          </div>
          <div className="card-filter">
            <form>
              <div className="filter-form">
                <div className="filter-item">
                  <select
                    name="status"
                    className="form-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="all">All</option>
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                  </select>
                </div>
                <div className="filter-item">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search by Invoice Number or Customer Name"
                    className="form-control"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="filter-action">
                  <button
                    className="i-btn danger--btn btn--md text-white"
                    type="button"
                    onClick={handleReset}
                  >
                    <i className="las la-sync"></i> Reset
                  </button>
                  <button className="i-btn primary--btn btn--md text-white" type="button">
                    <i className="fa fa-plus"></i> Add New
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="card-body px-0">
            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Invoice Number</th>
                    <th>Customer Name</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentInvoices.length > 0 ? (
                    currentInvoices.map((invoice, index) => (
                      <tr key={invoice.id}>
                        <td data-label="Sl.No">{index + indexOfFirstInvoice + 1}</td> {/* Serial Number */}
                        <td data-label="Invoice Number">{invoice.invoiceNumber}</td>
                        <td data-label="Customer Name">{invoice.customer}</td>
                        <td data-label="Amount">₹{invoice.amount}</td> {/* Indian Rupee Symbol */}
                        <td data-label="Status">
                          <span className="badge badge--success">{invoice.status}</span>
                        </td>
                        <td data-label="Action">
                          <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                            <button className="i-btn primary--btn btn--sm" onClick={() => handleView(invoice.id)}>
                              View
                            </button>
                            <button className="i-btn info--btn btn--sm" onClick={() => handleDownload(invoice.id)}>
                              Download
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No paid invoices found</td> {/* Adjusted colspan for Sl.No */}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Pagination Controls */}
          <div className="pagination" style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginRight: '17px' }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                type="button"
                className={`btn btn-outline-primary ${currentPage === index + 1 ? 'active' : ''}`}
                style={{ margin: '0px 5px' }}
                onClick={() => changePage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaidInvoiceList;
