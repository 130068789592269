import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css'; // Optional theme
import 'codemirror/mode/javascript/javascript'; // JavaScript syntax highlighting
import 'codemirror/mode/xml/xml'; // HTML syntax highlighting
import 'codemirror/mode/php/php'; // PHP syntax highlighting

const CodeEditor = ({ message, language }) => {
    console.log('message:', message);
    console.log('language:', language);

    // Adjust the regular expression to handle language detection for multiple code blocks
    const extractCode = (text, language) => {
        // Escape backticks within the template literal by using '\\`'
        const regex = new RegExp(`\\\`\`\s*${language}\s*([\\s\\S]+?)\\\`\`\``, 'g');
        console.log('regex:', regex);
        const matches = [...text.matchAll(regex)];
        console.log('matches:', matches);
        return matches.length > 0 ? matches.map(match => match[1].trim()) : null;
    };

    const codeBlocks = extractCode(message, language);

    console.log('codeBlocks:', codeBlocks);

    if (!codeBlocks || codeBlocks.length === 0) {
        return <p>No {language} code found.</p>;
    }

    // Dynamically set CodeMirror mode based on the language prop
    const getModeForLanguage = (language) => {
        console.log('getModeForLanguage language:', language);
        switch (language.toLowerCase()) {
            case 'html':
            case 'xml':
                return 'xml'; // HTML and XML both use the XML mode in CodeMirror
            case 'javascript':
                return 'javascript';
            case 'php':
                return 'php';
            default:
                return 'plaintext'; // Default mode if language isn't recognized
        }
    };

    return (
        <div className="code-editor-container">
            {codeBlocks.map((code, index) => (
                <CodeMirror
                    key={index}
                    value={code}
                    options={{
                        mode: getModeForLanguage(language), // Dynamically set the language mode
                        theme: 'material',
                        lineNumbers: true,
                        readOnly: true,
                    }}
                />
            ))}
        </div>
    );
};

export default CodeEditor;
