import React, { useState } from 'react';

const ChatingModel = ({ sidebarshow }) => {

    const [newMessage, setNewMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return;
        console.log(newMessage);
        setNewMessage('');
    };

    const handleFileUpload = (e) => {
        console.log(e.target.files[0]);
    };

    const handleVoiceInput = () => {
        setIsRecording(!isRecording);
        console.log('Voice input...');
    };

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '10px', height: '100vh' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ padding: '10px', background: 'white', width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1>What can I help with?</h1>
                </div>
            </div>
            <div className={`col-lg-${sidebarshow ? '9' : '12'} col-md-${sidebarshow ? '9' : '12'} col-sm-12 chat_bottons`} style={{ padding: '10px' }}>
                <div className="chat-input d-flex align-items-center" style={{ marginTop: '10px', padding: '10px', background: 'white' }}>
                    <button
                        onClick={handleVoiceInput}
                        className="btn btn-primary"
                        style={{
                            borderRadius: '50%',
                            padding: '10px 15px',
                            backgroundColor: isRecording ? 'red' : '#4CAF50',
                            color: 'white',
                            marginRight: '10px',
                        }}
                    >
                        <i className="fa fa-microphone" style={{ fontSize: '18px' }}></i>
                    </button>
                    <input
                        type="text"
                        value={newMessage}
                        onChange={handleInputChange}
                        placeholder="Type a message..."
                        className="form-control"
                        style={{
                            borderRadius: '20px',
                            padding: '10px',
                            border: '1px solid #ccc',
                            marginRight: '10px',
                        }}
                    />
                    <label className="btn btn-primary" style={{ borderRadius: '50%', padding: '10px 15px', marginRight: '10px' }}>
                        <i className="fa fa-paperclip" style={{ fontSize: '18px' }}></i>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileUpload}
                        />
                    </label>
                    <button
                        onClick={handleSendMessage}
                        className="btn btn-primary"
                        style={{
                            borderRadius: '50%',
                            padding: '10px 15px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                        }}
                    >
                        <i className="fa fa-paper-plane" style={{ fontSize: '18px' }}></i>
                    </button>
                </div>
            </div>
        </>
    );

};

export default ChatingModel;
