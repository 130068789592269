import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Function to check if a string is valid JSON
const isJsonString = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

// Function to parse stringified fields dynamically
const parseStringifiedFields = (item) => {
  const parsedItem = { ...item };

  // Loop through the keys and parse the value if it's a stringified JSON
  Object.keys(parsedItem).forEach((key) => {
    if (typeof parsedItem[key] === 'string' && isJsonString(parsedItem[key])) {
      parsedItem[key] = JSON.parse(parsedItem[key]);
    }
  });

  return parsedItem;
};

const JsonCodeDisplay = ({ executionResult }) => {
  // Parse all stringified fields dynamically
  const resultWithParsedFields = executionResult.map(parseStringifiedFields);

  return (
    <SyntaxHighlighter
      language="json"
      style={vscDarkPlus}
      customStyle={{
        background: "black",
        padding: "10px",
        borderRadius: "5px",
        overflowX: "auto",
      }}
    >
      {JSON.stringify(resultWithParsedFields, null, 2)}
    </SyntaxHighlighter>
  );
};

export default JsonCodeDisplay;
