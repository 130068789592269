import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import apiUrl from '../../utils/apiConfig';
import database_id from '../../utils/databaseConfig';

const socket = io(apiUrl); // Ensure this matches your server URL

const LiveDataComponent = () => {
    const [liveData, setLiveData] = useState(null);
    const [userData, setUserData] = useState(null);

    const tableSchema = database_id;

    const tableName = "users";
    const orderBy = "created_at DESC";

    const requestData = {
        tableSchema,
        tableName,
        conditions: "",
        orderBy,
        search: ""
    };

    useEffect(() => {
        // Listen for live data
        socket.on('liveData', (data) => {
            console.log('Received live data:', data);
            setLiveData(data);
        });

        socket.emit('DataRead', requestData);

        // Listen for user data response
        socket.on('GetDataRead', (data) => {
            console.log('Received user data:', data);
            setUserData(data);
        });

        return () => {
            socket.off('liveData');
            socket.off('GetDataRead');
        };
    }, []);

    const requestDataRead = () => {
        const requestData = { /* your request data here */ };
        socket.emit('DataRead', requestData); // Emit DataRead event to server
    };

    return (
        <div>
            <h1>Live Data</h1>
            {liveData ? (
                <div>
                    <p>Message: {liveData.message}</p>
                    <p>Timestamp: {liveData.timestamp.toString()}</p>
                </div>
            ) : (
                <p>No live data received yet.</p>
            )}
            
            <h2>User Data</h2>
            <button onClick={requestDataRead}>Request User Data</button>
            {userData ? (
                <div>
                    <p>User Data: {JSON.stringify(userData)}</p>
                </div>
            ) : (
                <p>No user data received yet.</p>
            )}
        </div>
    );
};

export default LiveDataComponent;
