import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { addNewBranch } from '../../../services/apiService'; // Adjust the import path as needed

const AddBranchModal = ({ show, handleClose, companyId }) => {
  const [newBranch, setNewBranch] = useState({
    name: '',
    city: '',
    address1: '',
    address2: '',
    startTime: '',
    closeTime: '',
    company_id: companyId || '', // Set company_id from props or default to empty string
  });

  useEffect(() => {
    // If the companyId prop changes, update the company_id in newBranch
    setNewBranch((prev) => ({ ...prev, company_id: companyId || '' }));
  }, [companyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBranch((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      // Make an API call to submit data
      await addNewBranch(newBranch);
      console.log('Branch added successfully');
      handleClose(); // Close the modal on successful submit
    } catch (error) {
      console.error('Error adding branch:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="branchName">
            <Form.Label>Branch Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={newBranch.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="branchCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={newBranch.city}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="branchAddress1">
            <Form.Label>Address Line 1</Form.Label>
            <Form.Control
              type="text"
              name="address1"
              value={newBranch.address1}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="branchAddress2">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              type="text"
              name="address2"
              value={newBranch.address2}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="branchStartTime">
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="time"
              name="startTime"
              value={newBranch.startTime}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="branchCloseTime">
            <Form.Label>Close Time</Form.Label>
            <Form.Control
              type="time"
              name="closeTime"
              value={newBranch.closeTime}
              onChange={handleChange}
            />
          </Form.Group>
          {/* No input field for company_id */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Add Branch
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBranchModal;
