import Reac, { useEffect, useState, useRef } from "react";
import ImageComponent from "../../../utils/ImageComponent";
import { FaEdit } from 'react-icons/fa'; // Edit icon (you can use any icon of your choice)
import { OneKlicksFileSDK } from "../../../OneKlicksFileSDK";
import { getuserdata } from "../../../services/apiService";
import { oneclick } from "../../../oneclick";
import database_id from "../../../utils/databaseConfig";
import { a } from "react-spring";

const ProjuctOverview = ({ projuct }) => {
    const [imagePreview, setImagePreview] = useState(""); // Store the preview of the image
    const fileInputRef = useRef(null); // Ref to the hidden file input

    // Handle file input change
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setImagePreview(URL.createObjectURL(file)); // Update the image preview
            
            const userId = await getuserdata('id'); // User ID
            const root = 'projucts'; // Root folder for the file
            const urllink = ''; // URL link for the file
            const update = await OneKlicksFileSDK.uploadFile(userId, root, urllink, file);
            console.log('LOGO update :', update);
            if(update.success){
                console.log('File uploaded successfully!');
                const tableSchema = database_id;
                const tableName = 'projucts';
                const data = { 
                    logo: update.file.id 
                };
                const condition = { 
                    id: projuct.id 
                };
                const updateLogo = await oneclick.update(tableSchema, tableName, data, condition);
                console.log(updateLogo);
                if(updateLogo.success){
                    console.log('Logo updated successfully!');
                    alert('Logo updated successfully!');
                }
            }
            // You can also handle the upload here, for example, sending the file to the server
        }
    };

    // Trigger file input click programmatically
    const handleClickEdit = () => {
        fileInputRef.current.click(); // Click the hidden file input
    };



    return (
        <div className="dashboard_container">
            <div className="d-flex ">
                <div className="card col-md-6 col-lg-6 col-sm-12">
                    <div className="d-flex justify-content-between card-header">
                        <div>
                            <h4>{projuct.name}</h4>
                        </div>
                        <div>
                            <div className="d-flex">
                                {imagePreview ? (
                                    <img src={imagePreview} alt="logo" style={{ width: '70px', height: '70px', borderRadius: '50%', boxShadow: '' }} />
                                ) : (
                                    <ImageComponent fileid={projuct.logo} width={70} height={70} borderRadius={50} boxShadow="" />
                                )}
                                {/* Hidden file input */}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: "none" }} // Hide the file input
                                />

                                <FaEdit
                                    onClick={handleClickEdit}
                                    style={{ cursor: 'pointer', marginLeft: '2px', height: '11px', top: '21px', position: 'absolute' }}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h5>Project Details</h5>
                                <p>Project Description</p>
                            </div>
                            <div>
                                <h5>Project Details</h5>
                                <p>Project Description</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <h4>Project Name</h4>
                    </div>
                </div>
                <div className="card col-md-6 col-lg-6 col-sm-12">
                    <div className="card-header">
                        <h4>Project Name</h4>
                    </div>
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h5>Project Details</h5>
                                <p>Project Description</p>
                            </div>
                            <div>
                                <h5>Project Details</h5>
                                <p>Project Description</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <h4>Project Name</h4>
                    </div>
                    <div className="card-footer">
                        <h4>Project Name</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjuctOverview;