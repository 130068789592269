import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import Select from 'react-select'; // Ensure you have react-select installed

const SubMenuAddModal = ({ show, handleClose, onSubmit, groups, allMenus, permissions}) => {
    console.log('SubMenuAddModal:', allMenus);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [filteredMenus, setFilteredMenus] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [subMenuName, setSubMenuName] = useState('');
    const [subMenuLink, setSubMenuLink] = useState(''); // State for the submenu link
    const [selectedPermissions, setSelectedPermissions] = useState([]); // State for selected permissions
    const [selectedPermissionsName, setSelectedPermissionsName] = useState([]);

    useEffect(() => {
        if (selectedGroup) {
            // Filter menus based on the selected group
            const menusForGroup = allMenus.filter(menu => menu.groupId == selectedGroup.value);
            setFilteredMenus(menusForGroup);
            console.log('setFilteredMenus', menusForGroup);
        } else {
            setFilteredMenus([]);
        }
    }, [selectedGroup, allMenus]);

    // Create options for the select dropdown
    const permissionOptions = permissions.flatMap(data =>
        JSON.parse(data.permissions).map(subPermission => ({
            value: subPermission.subName,
            label: `${data.name} - ${subPermission.subName}`,
            permission: subPermission.permissions
        }))
    );

    const handlePermissionTypesChange = (selectedOptions) => {
        // Log selected options for debugging
        console.log('selectedOptions:', selectedOptions);

        const selectedNames = selectedOptions.map(option => option.label); // Get the names of each permission

        // Extract selected permission IDs from selected options
        const selectedIds = selectedOptions.flatMap(option => {
            // Check if option.permission is an array before mapping
            if (Array.isArray(option.permission)) {
                return option.permission.map(perm => perm.id); // Get the ID of each permission
            }
            return [];
        });

        // Assign the selected IDs to selectedPermissions
        setSelectedPermissions(selectedIds);
        setSelectedPermissionsName(selectedNames);

        // Show alert with selected permission IDs
        alert(`Selected Permission IDs: ${selectedIds.join(', ')}`);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedGroup || !selectedMenu || subMenuName.trim() === '' || subMenuLink.trim() === '' ||  selectedPermissions.length === 0 ) {
            alert('All fields are required!');
            return;
        }
        // Call the onSubmit function passed from the parent
        console.log('SubMenuAddModal DATA SEND', selectedGroup.value, selectedMenu.value, subMenuName, subMenuLink, selectedPermissions)
        onSubmit({
            selectedGroup: selectedGroup.value,
            selectedMenu: selectedMenu.value,
            subMenuName,
            subMenuLink,
            selectedPermissions,
            selectedPermissionsName
        });
        setSelectedGroup(null); // Reset the selected group
        setSelectedMenu(null); // Reset the selected menu
        setSubMenuName(''); // Reset the input field
        setSubMenuLink(''); // Reset the submenu link field
        handleClose(); // Close the modal
    };

    // Create options for the select dropdown
    const groupOptions = groups.map(group => ({
        value: group.id, // Assuming group.id contains the group ID
        label: group.name // Assuming group.name contains the group name
    }));

    const menuOptions = filteredMenus.map(menu => ({
        value: menu.id, // Assuming menu.id contains the menu ID
        label: menu.name // Assuming menu.name contains the menu name
    }));

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New SubMenu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formSelectGroup">
                        <Form.Label>Select Group</Form.Label>
                        <Select
                            options={groupOptions}
                            onChange={setSelectedGroup}
                            placeholder="Select a group"
                            isClearable
                        />
                    </Form.Group>
                    <Form.Group controlId="formSelectMenu">
                        <Form.Label>Select Menu</Form.Label>
                        <Select
                            options={menuOptions}
                            onChange={setSelectedMenu}
                            placeholder="Select a menu"
                            isClearable
                            isDisabled={!selectedGroup} // Disable until a group is selected
                        />
                    </Form.Group>
                    <Form.Group controlId="formSubMenuName">
                        <Form.Label>SubMenu Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter sub menu name"
                            value={subMenuName}
                            onChange={(e) => setSubMenuName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formSubMenuLink">
                        <Form.Label>SubMenu Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter sub menu link"
                            value={subMenuLink}
                            onChange={(e) => setSubMenuLink(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formSelectPermissions">
                        <Form.Label>Select Permissions</Form.Label>
                        <Select
                            options={permissionOptions}
                            isMulti
                            onChange={handlePermissionTypesChange}
                            placeholder="Select Permission Types"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Add SubMenu
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SubMenuAddModal;
