import React from "react";

const Webhook = () => {
    const handleCopyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy);
        alert("Text copied to clipboard: " + textToCopy);
    };

    const handleGenerateToken = () => {
        console.log("Generate token button clicked!");
        // Simulate token generation logic
        const newToken = Math.random().toString(36).substring(2, 15);
        document.getElementById("verify_token").value = newToken;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        console.log("Form submitted!");
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">
                                WhatsApp Webhook Parameter Setup
                                <sup className="pointer" title="To setup google auth">
                                    <a href="#">
                                        <svg
                                            className="svg-inline--fa fa-circle-info"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="circle-info"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                            style={{ marginLeft: "5px", width: "15px" }}
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                                            ></path>
                                        </svg>
                                    </a>
                                </sup>
                            </h4>
                        </div>

                        <div className="card-body">
                            <form onSubmit={handleFormSubmit}>
                                <div className="form-wrapper">
                                    <div className="row g-4">
                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="verify_token">
                                                Add A Verify Token For Webhook <span className="text-danger">*</span>
                                            </label>

                                            <div className="input-group mt-2">
                                                <input
                                                    title="Make sure to copy this same verify token in your Business Account 'Webhook Configuration'"
                                                    type="text"
                                                    className="form-control"
                                                    name="verify_token"
                                                    id="verify_token"
                                                    defaultValue="bRkIDxaqyrrvnPyuKo0zBdx7OFKgH4X7"
                                                    placeholder="Enter A Token For Webhook"
                                                />

                                                <span
                                                    className="input-group-text generate-token cursor-pointer"
                                                    onClick={handleGenerateToken}
                                                >
                                                    <i className="bi bi-arrow-repeat fs-4 text--success"></i>
                                                </span>

                                                <span
                                                    className="input-group-text copy-text cursor-pointer"
                                                    onClick={() =>
                                                        handleCopyText(
                                                            document.getElementById("verify_token").value
                                                        )
                                                    }
                                                >
                                                    <i class="fa fa-copy"></i>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="callback_url">
                                                Add A CallBack URL For Webhook <span className="text-danger">*</span>
                                            </label>
                                            <div className="input-group mt-2">
                                                <input
                                                    readOnly
                                                    title="Make sure to copy this same call back url in your Business Account 'Webhook Configuration'"
                                                    type="text"
                                                    className="form-control"
                                                    name="callback_url"
                                                    id="callback_url"
                                                    defaultValue="http://localhost/getoneclick/webhook"
                                                />
                                                <span
                                                    className="input-group-text copy-text cursor-pointer"
                                                    onClick={() =>
                                                        handleCopyText(
                                                            document.getElementById("callback_url").value
                                                        )
                                                    }
                                                >
                                                    <i class="fa fa-copy"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn btn-primary">Save Webhook</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Webhook;
