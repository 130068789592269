import React, { useState } from 'react';
import { createTable } from '../../../services/apiService';
import { Button, Modal, Spinner } from 'react-bootstrap';

const CreateTableModal = ({ show, handleClose, ip, username, password, dbName, getTables, addcolumns, tableNameold }) => {
    console.log('CreateTableModal :', addcolumns);
    const [tableName, setTableName] = useState('');
    const [columnCount, setColumnCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const newColumns = [];

    newColumns.push({
        name: `name_0`,
        type: 'INT', // Default type is INT
        length: '',
        default: 'NULL', // Set all columns to NULL by default
        collation: '',
        attributes: '',
        isNull: true,
        index: 'none', // Default index
        autoIncrement: false,
        comments: ''
    });

    const [columns, setColumns] = useState(addcolumns === true ? newColumns : []);
    const [step, setStep] = useState(addcolumns === true ? 2 : 1);
    const [error, setError] = useState({ table: '', columns: [] });

    const handleNext = () => {
        const newColumns = [];

        // Add user-defined columns
        for (let i = 0; i < columnCount; i++) {
            newColumns.push({
                name: `name_${i}`,
                type: 'INT', // Default type is INT
                length: '',
                default: 'NULL', // Set all columns to NULL by default
                collation: '',
                attributes: '',
                isNull: true,
                index: 'none', // Default index
                autoIncrement: false,
                comments: ''
            });
        }

        setColumns(newColumns);
        setStep(addcolumns === true ? 2 : 1);
    };

    const handleCloseModal = () => {
        setStep(addcolumns === true ? 2 : 1);
        setTableName('');
        setColumnCount(1);
        setColumns([]);
        setError({ table: '', columns: [] });
        handleClose();
    };

    const handleColumnChange = (index, field, value) => {
        const newColumns = [...columns];
        newColumns[index][field] = value;
        // Auto-select 'PRIMARY' index when 'AUTO_INCREMENT' is enabled
        if (field === 'autoIncrement' && value) {
            newColumns[index]['index'] = 'primary'; // Set index to 'primary'
        }
        setColumns(newColumns);
    };

    const validateTableName = (name) => {
        const tableNameRegex = /^[a-zA-Z0-9_]+$/; // Allow only letters, numbers, and underscores
        if (!tableNameRegex.test(name) || name.trim() === '') {
            setError((prev) => ({ ...prev, table: 'Table name must contain only letters, numbers, or underscores and cannot be empty.' }));
        } else {
            setError((prev) => ({ ...prev, table: '' })); // Clear error message if valid
        }
    };

    const validateColumnName = (name, index) => {
        const columnNameRegex = /^[a-zA-Z0-9_]+$/; // Allow only letters, numbers, and underscores

        // Check for invalid column names like "id", "created_at", and "updated_at"
        const invalidNames = ['id', 'created_at', 'updated_at'];
        if (invalidNames.includes(name)) {
            alert(`The column name cannot be "${name}". Please choose a different name.`);
            setError((prev) => {
                const newErrors = [...prev.columns];
                newErrors[index] = `Column name cannot be "${name}".`;
                return { ...prev, columns: newErrors };
            });
        }
        // Validate column name
        else if (!columnNameRegex.test(name) || name.trim() === '') {
            setError((prev) => {
                const newErrors = [...prev.columns];
                newErrors[index] = 'Column name must contain only letters, numbers, or underscores and cannot be empty.';
                return { ...prev, columns: newErrors };
            });
        } else {
            setError((prev) => {
                const newErrors = [...prev.columns];
                newErrors[index] = '';
                return { ...prev, columns: newErrors };
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (error.table || error.columns.some(err => err)) return; // Prevent submission if there's an error

        try {
            setLoading(true); // Show loading state
            // Make the API call to submit the table creation request
            const addnecolum = addcolumns === true ? true : false ;
            const sendtable = tableNameold === "" ? tableName : tableNameold;
            const response = await createTable(ip, username, password, dbName, sendtable, columns, addnecolum); // Replace with your API endpoint
            console.log('Table created successfully:', response.data);
            if (response.success) {

                getTables();
                setColumns([]);
                handleCloseModal(); // Close the modal after successful creation
                alert(response.message);
            } else {
                alert(response.message);
            }
        } catch (err) {
            console.error('Error creating table:', err);
            alert('Failed to create table. Please try again.');
        } finally {
            setLoading(false); // Hide loading state
        }
    };

    const addColumn = () => {
        setColumns([
            ...columns,
            {
                name: `name_${columns.length}`,
                type: 'INT', // Default type is INT
                length: '',
                default: 'NULL', // Default to NULL for all columns
                collation: '',
                attributes: '',
                isNull: true,
                index: 'none', // Default index
                autoIncrement: false,
                comments: ''
            }
        ]);
    };

    const removeColumn = (index) => {
        setColumns(columns.filter((_, i) => i !== index)); // Remove column by index
    };

    const getDefaultOptions = (type) => {
        switch (type) {
            case 'INT':
                return ['0', 'NULL'];
            case 'TEXT':
                return ['NULL'];
            case 'LONGTEXT':
                return ['NULL'];
            default:
                return [];
        }
    };

    if (!show) return null;

    return (
        <div className="custom-modal-overlay" style={{ display: show ? '' : 'none' }}>
            <div className="custom-modal" style={{ maxWidth: step === 1 ? '30%' : '50%' }}>
                <div className="custom-modal-header">
                    {step === 1 ? <h4>Create Table</h4> : <h4>{tableName}</h4>}
                    <button className="btn-close" onClick={handleCloseModal}>×</button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="custom-modal-body">
                        {step === 1 ? (
                            <div className="step-one">
                                <div className="mb-3">
                                    <label className="form-label">Table Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        required
                                        value={tableName}
                                        onChange={(e) => {
                                            setTableName(e.target.value);
                                            validateTableName(e.target.value); // Validate on change
                                        }}
                                    />
                                    {error.table && <div className="text-danger">{error.table}</div>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Column Count</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="1"
                                        required
                                        value={columnCount}
                                        onChange={(e) => setColumnCount(e.target.value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="step-two">
                                <h5>Columns</h5>
                                {columns.map((column, index) => (
                                    <div className="row mb-3" style={{ '--bs-gutter-x': '0rem', display: 'flex', gap: '16px' }} key={index}>
                                        <div className="col">
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                className="form-control"
                                                value={column.name}
                                                onChange={(e) => {
                                                    handleColumnChange(index, 'name', e.target.value);
                                                    validateColumnName(e.target.value, index); // Validate on change
                                                }}
                                                required
                                            />
                                            {error.columns[index] && <div className="text-danger">{error.columns[index]}</div>}
                                        </div>
                                        <div className="col">
                                            <select
                                                className="form-control"
                                                value={column.type}
                                                onChange={(e) => {
                                                    handleColumnChange(index, 'type', e.target.value);
                                                    handleColumnChange(index, 'default', ''); // Reset default when type changes
                                                }}
                                            >
                                                <option value="INT">INT</option>
                                                <option value="TEXT">TEXT</option>
                                                <option value="LONGTEXT">LONGTEXT</option>
                                            </select>
                                        </div>
                                        <div className="col">
                                            <select
                                                className="form-control"
                                                value={column.default}
                                                onChange={(e) => handleColumnChange(index, 'default', e.target.value)}
                                            >
                                                {getDefaultOptions(column.type).map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <Button variant="danger" onClick={() => removeColumn(index)}>
                                                Remove
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                                <Button variant="secondary" onClick={addColumn}>Add Column</Button>
                            </div>
                        )}
                    </div>
                    <div className="custom-modal-footer">
                        {step === 1 ? (
                            <Button type="button" onClick={handleNext}>Next</Button>
                        ) : (
                            <>
                                <Button type="button" class="btn btn-secondary" onClick={handleCloseModal}>Cancel</Button>
                                <Button type="submit" disabled={loading} style={{ marginLeft: '10px' }}>
                                    {loading ? (
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    ) : (
                                        addcolumns === true ? "Add Column" : "Create Table"
                                    )}
                                    {loading ? " Creating..." : ""}
                                </Button>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateTableModal;
