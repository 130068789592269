import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const LanguageDashboard = () => {
  const [languages, setLanguages] = useState([
    { id: 29, name: 'Bangla', code: 'bn', isDefault: false },
    { id: 1, name: 'English', code: 'en', isDefault: true },
  ]);

  const [selectedLanguage, setSelectedLanguage] = useState({ name: '', code: '' });
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedLanguage({ ...selectedLanguage, [name]: value });
  };

  // Show modals
  const handleShowCreateModal = () => setShowCreateModal(true);
  const handleShowUpdateModal = (language) => {
    setSelectedLanguage(language);
    setShowUpdateModal(true);
  };
  const handleShowDeleteModal = (language) => {
    setSelectedLanguage(language);
    setShowDeleteModal(true);
  };

  // Hide modals
  const handleCloseModal = () => {
    setShowCreateModal(false);
    setShowUpdateModal(false);
    setShowDeleteModal(false);
  };

  // Handle form submission
  const handleCreateLanguage = (e) => {
    e.preventDefault();
    // Add logic to submit to the backend and update state
    setLanguages([...languages, selectedLanguage]);
    handleCloseModal();
  };

  const handleUpdateLanguage = (e) => {
    e.preventDefault();
    // Update logic for the language
    const updatedLanguages = languages.map(lang => 
      lang.id === selectedLanguage.id ? selectedLanguage : lang
    );
    setLanguages(updatedLanguages);
    handleCloseModal();
  };

  const handleDeleteLanguage = (e) => {
    e.preventDefault();
    // Delete logic for the language
    setLanguages(languages.filter(lang => lang.id !== selectedLanguage.id));
    handleCloseModal();
  };

  return (
    <div className="dashboard_container">
      <section>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Manage Language</h4>
            <div className="d-flex justify-content-end">
              <form
                action="/set-default-language" // Placeholder for backend call
                method="POST"
                className="form-inline float-sm-right text-end"
              >
                <div className="input-group">
                  <select className="form-select" name="id" required>
                    {languages.map(lang => (
                      <option key={lang.id} value={lang.id}>
                        {lang.name.toUpperCase()}
                      </option>
                    ))}
                  </select>
                  <button
                    className="i-btn info--btn btn--md"
                    id="basic-addon2"
                    type="submit"
                  >
                    Set Default Language
                  </button>
                </div>
              </form>
              <button className="i-btn primary--btn btn--md text-white" style={{ marginLeft: "20px" }}>
                <i className="fas fa-plus" style={{ marginRight: "3px" }}></i> 
                Add New
             </button>

            </div>
          </div>

          <div className="card-body px-0">
            <div className="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {languages.map(language => (
                    <tr key={language.id}>
                      <td>
                        {language.name}
                      </td>
                      <td>{language.code}</td>
                      <td>
                        {language.isDefault ? (
                          <span className="badge badge--success">Default</span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </td>
                      <td>
                        <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                          <button
                            className="i-btn primary--btn btn--sm"
                            onClick={() => handleShowUpdateModal(language)}
                          >
                            Edit
                          </button>
                          <button
      className="i-btn danger--btn btn--sm"
      onClick={() => handleShowDeleteModal(language)}
      aria-label="Delete" // Add an aria-label for accessibility
    >
      <FontAwesomeIcon icon={faTrash} />
    </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Create Modal */}
      {showCreateModal && (
        <Modal title="Add New Language" onClose={handleCloseModal}>
          <form onSubmit={handleCreateLanguage}>
            <div className="form-group">
              <label>Language Name</label>
              <input
                type="text"
                name="name"
                value={selectedLanguage.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Language Code</label>
              <input
                type="text"
                name="code"
                value={selectedLanguage.code}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="i-btn" onClick={handleCloseModal}>
                Cancel
              </button>
              <button type="submit" className="i-btn success--btn">
                Submit
              </button>
            </div>
          </form>
        </Modal>
      )}

      {/* Update Modal */}
      {showUpdateModal && (
        <Modal title="Update Language" onClose={handleCloseModal}>
          <form onSubmit={handleUpdateLanguage}>
            <div className="form-group">
              <label>Language Name</label>
              <input
                type="text"
                name="name"
                value={selectedLanguage.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Language Code</label>
              <input
                type="text"
                name="code"
                value={selectedLanguage.code}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="i-btn" onClick={handleCloseModal}>
                Cancel
              </button>
              <button type="submit" className="i-btn success--btn">
                Submit
              </button>
            </div>
          </form>
        </Modal>
      )}

      {/* Delete Modal */}
      {showDeleteModal && (
        <Modal title="Delete Language" onClose={handleCloseModal}>
          <p>Are you sure you want to delete {selectedLanguage.name}?</p>
          <div className="modal-footer">
            <button type="button" className="i-btn" onClick={handleCloseModal}>
              Cancel
            </button>
            <button type="button" className="i-btn danger--btn" onClick={handleDeleteLanguage}>
              Delete
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

// Modal Component
const Modal = ({ title, children, onClose }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="modal-header">
          <h5>{title}</h5>
          <button onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default LanguageDashboard;
