import React, { useState, useEffect } from 'react';
import AddAIDepartment from './Components/AddAIDepartment';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min';
import AddNewWebsiteModal from '../seo/Component/AddNewWebsiteModal';
import EditAITeam from './Components/EditAiTeams';
import AddAiTeam from './Components/AddAiTeam';
import EditAIDepartment from './Components/EditAIDepartment';
import { Link, useNavigate } from 'react-router-dom';
import { oneclick } from '../../oneclick';
import { getuserdata } from '../../services/apiService';
import { ImageUrlBuild } from '../../utils/ImageUrlBuild';
import ImageComponent from '../../utils/ImageComponent';
import Paginationnew from '../../utils/Paginationnew';
import { set } from 'date-fns';
import database_id from '../../utils/databaseConfig';
import { encodeBase64 } from '../../services/apiService';

const AiTeam = () => {
    const [searchinput, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [filterdeportment, setfilterdeportment] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10;

    const [emailToDelete, setEmailToDelete] = useState(null);
    const [emailToEdit, setEmailToEdit] = useState(null);
    const [editedEmail, setEditedEmail] = useState({ username: '', mail: '', status: '', domain: '' });
    const [newEmail, setNewEmail] = useState({ username: '', domain: '', status: '' });
    const [emailData, setEmailData] = useState([]);
    const [TeamData, setTeamData] = useState([]);
    const [showWebsiteModal, setShowWebsiteModal] = useState(false);
    const [showAdddepartmentModal, setShowAdddepartmentModal] = useState(false);
    const [showEditdepartmentModal, setShowEditdepartmentModal] = useState(false);
    const [showDeletedepartmentModal, setShowDeletdepartmentModal] = useState(false);
    const [showWebsitesTable, setShowWebsitesTable] = useState(false);
    const [selectdepartment, setSelectdepartment] = useState({});

    const navigate = useNavigate();

    const fetchEmailData = async () => {
        const tableSchema = database_id;
        const tableName = 'departments';

        // Fetch company ID once
        const companyId = await getuserdata('company_id');

        // Define conditions based on status
        const conditions = {
            company_id: companyId,
        };

        const orderBy = 'id DESC';
        const pagination = { page: currentPage || 1, limit: 100000 };

        // Define search logic
        const search = {};

        const margedata = [
            {
                "target_label": "images",
                "target_table": "files",
                "target_column": "id",
                "target_value": "image"
            }
        ];

        try {
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
            if (response.success) {
                console.log('Email data fetched:', response.data);
                setEmailData(response.data);
            } else {
                console.error('Error fetching email data:', response.message);
                setEmailData([]);
            }
        } catch (error) {
            console.error('Error during fetchEmailData:', error);
            setEmailData([]);
        }
    };

    const fetchTeamData = async () => {
        const tableSchema = database_id;
        const tableName = 'ai_teams';
    
        // Fetch company ID once
        const companyId = await getuserdata('company_id');
    
        // Define conditions based on status and department
        const conditions = {
            company_id: companyId,
            ...(status && { status }), // Conditionally add status if it exists
            ...(filterdeportment && { department_id: filterdeportment }), // Conditionally add department if it exists
        };
    
        const orderBy = 'id DESC';
        const pagination = { page: currentPage || 1, limit: itemsPerPage };
    
        // Define search logic
        const search = searchinput ? { name: searchinput } : {}; // Search condition
    
        const margedata = [
            {
                "target_label": "images",
                "target_table": "files",
                "target_column": "id",
                "target_value": "image"
            },
            {
                "target_label": "departments",
                "target_table": "departments",
                "target_column": "id",
                "target_value": "department_id"
            }
        ];
    
        try {
            const response = await oneclick.read(tableSchema, tableName, conditions, orderBy, search, pagination, margedata);
            if (response.success) {
                console.log('Team data fetched:', response.data);
                setTeamData(response.data);
                setTotalPages(response.totalPages);
            } else {
                console.error('Error fetching team data:', response.message);
                setTeamData([]); // Handle no data
            }
        } catch (error) {
            console.error('Error during fetchTeamData:', error);
            setTeamData([]); // Handle error
        }
    };

    // Fetch data on component mount and when dependencies change
    useEffect(() => {
        fetchEmailData();
        fetchTeamData();
    }, []);

    useEffect(() => {
        fetchTeamData();
    }, [searchinput, status, currentPage, filterdeportment]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
    };

    const handleDeletedata = (id) => {
        setEmailToDelete(id);
        setShowDeletdepartmentModal(true);
    };
    const handleDelete = async (id) => {
        console.log(`Delete department with id: ${id}`);
        const tableSchema = database_id;
        const tableName = 'ai_teams';
        const conditions = {
            id: id,
        };

        try {
            const response = await oneclick.delete(tableSchema, tableName, conditions);
            if (response.success) {
                console.log('Department deleted successfully:', response);
                fetchEmailData(); // Refresh the data
                setEmailToDelete(null); // Clear the email to delete
                // Dismiss the modal using DOM manipulation
                const deleteModal = document.getElementById('delete');
                deleteModal.classList.remove('show');
                deleteModal.style.display = 'none';
                document.body.classList.remove('modal-open');
                document.querySelector('.modal-backdrop')?.remove();
            } else {
                console.error('Failed to delete department:', response.message);
                alert('Error: Unable to delete the department.'); // Optionally notify the user
            }
        } catch (error) {
            console.error('Error deleting department:', error);
            alert('Error: Something went wrong while deleting the department.');
        }
    };

    const handleEdit = (data) => {
        console.log(`Edit campaign with: ${data}`);
        setSelectdepartment(data);
        console.log('Select Department:', selectdepartment);
        setShowEditdepartmentModal(true);
    };

    const handleEditSubmit = () => {
        console.log(`Edit campaign with id: ${emailToEdit}`, editedEmail);
        setEmailToEdit(null);
    };

    const handleLogin = (mail) => {
        console.log(`Login with email: ${mail}`);
    };

    const handleAddEmail = (email) => {
        const newEmailEntry = { id: emailData.length + 1, ...email, mail: `${email.username}@${email.domain}` };
        setEmailData([...emailData, newEmailEntry]);
        setNewEmail({ username: '', domain: '', status: '' }); // Reset newEmail state

        // Close the modal manually
        const modal = document.getElementById('addNew');
        const modalInstance = window.bootstrap.Modal.getInstance(modal); // Use window.bootstrap here
        if (modalInstance) {
            modalInstance.hide();
        }
    };


    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handeleshowallwebsites = () => {
        navigate('/seo_data_add/true'); // If no 'from' state exists, navigate to dashboard
    };

    const handleteamview = (data) => {
        console.log('Team Data:', data);
        navigate(`/ai/teams/teamoverview/${encodeBase64(data.id)}`);
    };

    return (
        <div className="dashboard_container">
            <section>
                <div className="container-fluid p-0">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Ai Teams List</h4>
                        </div>

                        <div className="card-filter">
                            <form onSubmit={handleFormSubmit}>
                                <div className="filter-form">
                                    <div className="filter-item">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            name="search"
                                            placeholder="Search With Username"
                                            className="form-control"
                                            value={searchinput}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>

                                    <div className="filter-item">
                                        <select
                                            name="status"
                                            className="form-select"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="DeActive">DeActive</option>
                                            <option value="Completed">Completed</option>
                                            <option value="Ongoing">Ongoing</option>
                                        </select>
                                    </div>

                                    <div className="filter-item">
                                        <select
                                            name="status"
                                            className="form-select"
                                            value={filterdeportment}
                                            onChange={(e) => setfilterdeportment(e.target.value)}
                                        >
                                            <option value="">Select Department</option>
                                            {emailData.length > 0 ? (
                                                emailData.map((data) => {
                                                    return (
                                                        <option key={data.id} value={data.id}>
                                                            {data.name} {/* Display only the domain name */}
                                                        </option>
                                                    );
                                                })
                                            ) : (
                                                <option disabled>No available</option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="filter-action">
                                        <button
                                            type="button"
                                            className="i-btn danger--btn btn--md"
                                            onClick={() => { setSearch(''); setStatus(''); setCurrentPage(1); setfilterdeportment('') }}
                                        >
                                            Reset
                                        </button>

                                        {/* Add New Button */}
                                        <button
                                            type="button"
                                            className="i-btn primary--btn btn--md text-white"
                                            onClick={() => setShowAdddepartmentModal(true)}
                                        >
                                            Add New Team
                                        </button>

                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="card-body px-0">
                            <div className="responsive-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Department</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {TeamData.length > 0 ? (
                                            TeamData.map((email, index) => (
                                                <tr key={email.id} onClick={() => handleteamview(email)} style={{ cursor: 'pointer' }} // Make row clickable
                                                >
                                                    <td data-label="ID" >{(currentPage - 1) * itemsPerPage + index + 1}</td> {/* Updated ID */}
                                                    <td data-label="Image" style={{paddingLeft: '15px', paddingRight: '10px'}}>
                                                        <ImageComponent fileid={email.images.id} width={50} height={50} borderRadius={50} boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" />
                                                    </td>
                                                    <td data-label="Name" className="maxtable">{email.name}</td>
                                                    <td data-label="Department" className="maxtable">{email.departments.name}</td>
                                                    <td data-label="Description" className="maxtable"> 
                                                            {email.description}
                                                    </td>
                                                    <td data-label="Status">
                                                        <span className={`badge badge--${email.status == "Active" ? 'success': 'danger'}`}>{email.status}</span>
                                                        
                                                    </td>
                                                    <td data-label="Actions">
                                                        <div className="button-group" style={{ display: 'flex', gap: '10px' }}>
                                                            <button
                                                                className="i-btn primary--btn btn--md"
                                                                onClick={() => handleEdit(email)}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                className="i-btn danger--btn btn--md"
                                                                onClick={() => handleDeletedata(email.id)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-muted text-center" colSpan="6">
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Paginationnew currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />

                        </div>

                    </div>
                </div>
            </section>

            {/* Add New Website Modal */}
            <AddNewWebsiteModal showWebsiteModal={showWebsiteModal} setShowWebsiteModal={() => setShowWebsiteModal(false)} />
            {/* Modal for adding new email */}
            {showAdddepartmentModal && (
                <AddAiTeam show={showAdddepartmentModal} hide={() => setShowAdddepartmentModal(false)} fetchEmailData={fetchTeamData} emailData={emailData}/>
            )}

            {/* Modal for editing email */}
            {showEditdepartmentModal && (
                <EditAITeam show={showEditdepartmentModal} hide={() => setShowEditdepartmentModal(false)} initialDepartmentData={selectdepartment} fetchEmailData={fetchTeamData} emailData={emailData}/>
            )}
            {/* Modal for editing email */}


            {/* Modal for deleting email */}
            {showDeletedepartmentModal && (
                <div className={`modal fade ${showDeletedepartmentModal ? 'show' : ''}`} style={{ display: showDeletedepartmentModal ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="addNewLabel" aria-hidden={!showDeletedepartmentModal}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModalLabel">Delete Team</h5>
                                <button type="button" className="btn-close" onClick={() => setShowDeletdepartmentModal(false)}><i className="fa fa-close"></i></button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete this team?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowDeletdepartmentModal(false)}>Close</button>
                                <button type="button" className="btn btn-danger" onClick={() => handleDelete(emailToDelete)} >Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AiTeam;
