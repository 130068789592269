// InvoiceTemplate.js
import React, { useRef } from 'react';
import ContactInfoSection from './ContactInfoSection';
import apiUrl from '../../utils/apiConfig';

const InvoiceTemplate = () => {
    const invoiceData = {
        logoUrl: `${apiUrl}/assets/file/images/logoIcon/667cfa61cbda01719466593.png`, // Path to logo image
        backgroundImage: '/assets/file/images/logoIcon/backgroundinvoice.jpeg', // Path to background image
        companyName: 'Oneklicks',
        jobTitle: 'Cloud Service Providers ',
        invoiceTitle: 'INVOICE',
        recipient: {
            name: 'Ketut Susilo',
            address: '123 Anywhere St., Any City, ST 12345'
        },
        date: '25 June 2022',
        invoiceNumber: '12345',
        items: [
            { description: 'Consultation', quantity: 1, price: 300, total: 300 },
            { description: 'Project Draft', quantity: 1, price: 2000, total: 2000 },
            { description: 'Implementation', quantity: 1, price: 2500, total: 2500 },
            { description: 'Additional Supplies', quantity: 1, price: 750, total: 750 },
            { description: 'Monthly meeting', quantity: 1, price: 2400, total: 2400 }
        ],
        subtotal: 7950,
        tax: 795,
        discount: 500,
        grandTotal: 8245,
        paymentDetails: {
            bankName: 'Wardiere Inc.',
            accountNumber: '123-456-7890'
        },
        administrator: {
            name: 'Adeline Palmerston',
            email: 'hello@reallygreatsite.com',
            phone: '123-456-7890',
            address: '123 Anywhere St., Any City'
        },
        terms: 'Please send payment within 30 days of receiving this invoice. There will be a 10% interest charge per month on late invoices.'
    };

    const { logoUrl, backgroundImage, companyName, jobTitle, invoiceTitle, recipient, date, invoiceNumber, items, subtotal, tax, discount, grandTotal, paymentDetails, administrator, terms } = invoiceData;

    const invoiceRef = useRef(); // Reference for the invoice section

    // Print function to only print the invoice content
    // const handlePrint = () => {
    //     const printContent = invoiceRef.current.innerHTML;
    //     const originalContent = document.body.innerHTML;
        
    //     document.body.innerHTML = printContent; // Replace body content with invoice content
    //     window.print(); // Trigger print
    //     document.body.innerHTML = originalContent; // Restore original content after printing
    // };

    const handlePrint = () => {
        window.print();
    };
    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif', color: '#333',background: 'white' }}>
            {/* Print Button */}
            <button onClick={handlePrint} style={{ marginBottom: '20px', padding: '10px', background: '#5271ff', color: 'white', border: 'none', cursor: 'pointer' }}>
                Print Invoice
            </button>

            <div ref={invoiceRef} className='incoice-CONTENT'>
                {/* Background Image */}
                
                {/* Header */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <img src={logoUrl} alt="Logo" style={{ width: '250px' }} />
                    <div style={{ textAlign: 'right' }}>
                        <h1>{companyName}</h1>
                        <p>{jobTitle}</p>
                    </div>
                </div>

                {/* Invoice Title and Recipient */}
                <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', color: '#333' }}>
                    <div className='d-flex'>
                        <div className='invoiceboder'></div>
                        <h2 style={{ fontSize: '24px', color: '#4A4A4A', marginLeft: '10px' }}>{invoiceTitle}</h2>
                    </div>


                </section>

                {/* Invoice Details */}
                <section style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', color: '#555' }}>
                    <div>
                        <p><strong>To:</strong> {recipient.name}</p>
                        <p>{recipient.address}</p>
                    </div>
                    <div>
                        <p><strong>Date:</strong> {date}</p>
                        <p><strong>Invoice No:</strong> {invoiceNumber}</p>
                    </div>
                </section>

                {/* Items Table */}
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#F5F5F5' }}>
                            <th style={{ border: '1px solid #E0E0E0', padding: '10px', textAlign: 'left' }}>Description</th>
                            <th style={{ border: '1px solid #E0E0E0', padding: '10px', textAlign: 'right' }}>Qty</th>
                            <th style={{ border: '1px solid #E0E0E0', padding: '10px', textAlign: 'right' }}>Price</th>
                            <th style={{ border: '1px solid #E0E0E0', padding: '10px', textAlign: 'right' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #E0E0E0' }}>{item.description}</td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #E0E0E0', textAlign: 'right' }}>{item.quantity}</td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #E0E0E0', textAlign: 'right' }}>${item.price.toFixed(2)}</td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #E0E0E0', textAlign: 'right' }}>${item.total.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='row d-flex'>
                    {/* Totals Section */}
                    <div className='col-lg-6'>
                        {/* Payment Information */}
                        <section style={{ marginBottom: '20px', color: '#555' }}>
                            <p><strong style={{ fontSize: 'large' }}>Payment Method:</strong></p>
                            <p style={{ fontSize: 'large' }}>Bank Name: {paymentDetails.bankName}</p>
                            <p style={{ fontSize: 'large' }}>Account Number: {paymentDetails.accountNumber}</p>
                        </section>
                    </div>
                    <div className='col-lg-6'>
                        <section style={{ textAlign: 'right', marginBottom: '20px' }}>
                            <p><strong>Sub Total:</strong> ${subtotal.toFixed(2)}</p>
                            <p><strong>Tax 10%:</strong> ${tax.toFixed(2)}</p>
                            <p><strong>Discount:</strong> -${discount.toFixed(2)}</p>
                            <p style={{ fontSize: '18px', color: '#fff', background: '#5271ff', padding: '9px' }}><strong>Grand Total:</strong> ${grandTotal.toFixed(2)}</p>
                        </section>
                    </div>


                </div>

                <div className='row1 d-flex'>
                    {/* Totals Section */}
                    <div className='col-lg-6'>
                        {/* Terms and Conditions */}
                        <section style={{ marginBottom: '20px', color: '#4A4A4A', }}>
                            <p><strong style={{ fontSize: 'large' }}>Terms and Conditions:</strong></p>
                            <p style={{ fontSize: 'large' }}>{terms}</p>
                        </section>
                    </div>
                    <div className='col-lg-6'>

                        <section style={{ marginBottom: '20px', color: '#4A4A4A', textAlign: 'center', fontSize: 'large' }}>
                            <img src='https://cdn.prod.website-files.com/61d7de73eec437f52da6d699/62161cf7328ad280841f653f_esignature-signature.png' style={{ width: '200px', height: 'auto' }} />
                            <p><strong>Adeline Palmerston</strong></p>
                            <p><strong style={{ color: '#5271ff' }}>Administrator</strong></p>
                        </section>
                    </div>


                </div>
                <div>
                    <hr className='invoice-underline'></hr>
                </div>
                <ContactInfoSection />
            </div>







        </div>
    );
};

export default InvoiceTemplate;
