import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import Select from 'react-select'; // Import react-select
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome
import { faUpload } from '@fortawesome/free-solid-svg-icons'; // Import upload icon
import { oneclick } from '../../../oneclick';
import { OneKlicksFileSDK } from '../../../OneKlicksFileSDK';
import database_id from '../../../utils/databaseConfig';

const AddMenuModal = ({ show, handleClose, companyId, permissionsdata }) => {
    const generateTimestampId = () => {
        return new Date().getTime().toString(); // Generate a unique timestamp
    };

    const [newRole, setNewRole] = useState({
        permissions: [
            {
                id: generateTimestampId(),
                subName: '',
                icon: null,
                link: '',
                permissions: [{ id: generateTimestampId(), name: '', link: '', permissionTypes: [], permissionTypesids: [] }] // Changed to an array
            }
        ]
    });
    const [selectedPermissions, setSelectedPermissions] = useState([]); // State for selected permissions
    const [selectedPermissionsName, setSelectedPermissionsName] = useState([]); // State for selected permission names

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewRole((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubNameChange = (index, event) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[index].subName = event.target.value;
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handlelinkChange = (index, event) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[index].link = event.target.value;
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handleChildPermissionChange = (permIndex, childIndex, event) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[permIndex].permissions[childIndex].name = event.target.value;
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handleChildPermissionLinkChange = (permIndex, childIndex, event) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[permIndex].permissions[childIndex].link = event.target.value;
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handlePermissionTypesChange = (permIndex, childIndex, selectedOptions) => {
        const updatedPermissions = [...newRole.permissions];
        
        // Log selected options for debugging
        console.log('selectedOptions:', selectedOptions);
    
        // Extract selected permission IDs from selected options
        const selectedIdss = selectedOptions.flatMap(option => {
            // Check if option.permission is an array before mapping
            if (Array.isArray(option.permission)) {
                return option.permission.map(perm => perm.id); // Get the id of each permission
            }
            // If it's not an array, return an empty array
            return [];
        });
    
        // Extract selected option values (if needed)
        const selectedIds = selectedOptions.map(option => option.value); // This would be the name or label of the selected options
    
        // Assign the selected IDs to permissionTypes
        updatedPermissions[permIndex].permissions[childIndex].permissionTypesids = selectedIdss;
        updatedPermissions[permIndex].permissions[childIndex].permissionTypes = selectedIds;
    
        // Show alert with selected permission IDs
        alert(`Selected Permission IDs: ${selectedIdss.join(', ')}`);
    
        // Update state
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };
    
    

    const handleIconUpload = (index, event) => {
        const file = event.target.files[0];
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[index].icon = file; // Store file as icon
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handleAddPermissionSubName = () => {
        setNewRole((prev) => ({
            ...prev,
            permissions: [...prev.permissions, { id: generateTimestampId(), subName: '', icon: null, permissions: [{ id: generateTimestampId(), name: '', link: '', permissionTypes: [] }] }]
        }));
    };

    const handleAddChildPermission = (permIndex) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[permIndex].permissions.push({ id: generateTimestampId(), name: '', link: '', permissionTypes: [] });
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handleRemovePermissionSubName = (index) => {
        const updatedPermissions = newRole.permissions.filter((_, i) => i !== index);
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handleRemoveChildPermission = (permIndex, childIndex) => {
        const updatedPermissions = [...newRole.permissions];
        updatedPermissions[permIndex].permissions = updatedPermissions[permIndex].permissions.filter((_, i) => i !== childIndex);
        setNewRole({ ...newRole, permissions: updatedPermissions });
    };

    const handleSubmit = async () => {
        try {
            const tableSchema = database_id;
            const tableName = "menudata";

            const token = localStorage.getItem('token'); // Retrieve token from localStorage
            const ConditionsUsers = { "login_tockin": token };
            const emaployeData11 = await oneclick.read(tableSchema, "users", ConditionsUsers, "", "");
            const user_id = emaployeData11.data[0].id;
    
            // Create an array to store promises for file uploads
            const uploadPromises = newRole.permissions.map(async (permission) => {
                // Check if an icon file is present for upload
                if (permission.icon) {
                    const root = "images/icon"; // Replace with the appropriate root path
                    // Upload the icon file
                    const uploadResult = await OneKlicksFileSDK.uploadFile(user_id, root, permission.icon);
                    return uploadResult; // Return the upload result
                }
                return { success: true, file: null }; // Return success if no file to upload
            });

            // Wait for all file uploads to complete
            const uploadResults = await Promise.all(uploadPromises);

            // Check for any errors in uploads
            const uploadErrors = uploadResults.filter(result => !result.file); // Change this check

            if (uploadErrors.length > 0) {
                alert('Error uploading some files. Please check your files.');
                return;
            }

            // Format data to match the desired structure
            const formattedPermissions = newRole.permissions.map((permission, index) => ({
                id: permission.id,
                subName: permission.subName,
                link: permission.link,
                icon: uploadResults[index].file ? uploadResults[index].file.id : null, // Construct URL from the uploaded file information
                submenu: permission.permissions.map((child) => ({
                    id: child.id,
                    name: child.name,
                    link: child.link,
                    permissionTypesids: child.permissionTypesids // Send the permissionTypes array
                }))
            }));

            const data = {
                name: newRole.permissionsName,
                memudata: JSON.stringify(formattedPermissions)
            };

            const update = await oneclick.create(tableSchema, tableName, data);
            if (update.success) {
                alert(update.message || 'Permissions added successfully.');
                handleClose(); // Close the modal on successful submit
                resetForm(); // Reset form data
            } else {
                console.error("Error updating permissions:", update.error);
                alert(update.error || 'Error updating permissions.');
            }
        } catch (error) {
            console.error('Error adding permissions:', error);
        }
    };

    const resetForm = () => {
        setNewRole({ permissions: [{ id: generateTimestampId(), subName: '', icon: null, permissions: [{ id: generateTimestampId(), name: '', link: '', permissionTypes: [] }] }] });
    };

    // Create options for the select dropdown
    const permissionOptions = permissionsdata.flatMap(data => 
        JSON.parse(data.permissions).map(subPermission => ({
            value: subPermission.subName,
            label: `${data.name} - ${subPermission.subName}`,
            permission: subPermission.permissions
        }))
    );

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Permissions Name (was Role Name) */}
                    <Form.Group controlId="permissionsName">
                        <Form.Label>Menu Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="permissionsName"
                            value={newRole.permissionsName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    {/* Dynamic Permission Inputs with Sub Names and Child Permissions */}
                    {newRole.permissions.map((permission, permIndex) => (
                        <div key={permIndex} style={{ marginBottom: '20px' }}>
                            <hr />
                            <Form.Label>Menu Sub Name</Form.Label>
                            <div className="d-flex align-items-center mb-2" style={{ gap: '10px' }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter permission sub name"
                                    value={permission.subName}
                                    onChange={(e) => handleSubNameChange(permIndex, e)}
                                />
                                
                                <Form.Control
                                    type="file"
                                    onChange={(e) => handleIconUpload(permIndex, e)}
                                />
                                {newRole.permissions.length > 1 && (
                                    <Button
                                        variant="danger"
                                        className="ml-2"
                                        onClick={() => handleRemovePermissionSubName(permIndex)}
                                    >
                                        <i className="fa fa-close"></i>
                                    </Button>
                                )}
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter menu link"
                                value={permission.link}
                                onChange={(e) => handlelinkChange(permIndex,e)}
                            />

                            <Form.Label>Child Menu</Form.Label>
                            {permission.permissions.map((child, childIndex) => (
                                <div key={childIndex} className="align-items-center mb-2" style={{ gap: '10px' }}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter child permission"
                                        className='mb-2'
                                        value={child.name}
                                        onChange={(e) => handleChildPermissionChange(permIndex, childIndex, e)}
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter menu link"
                                        value={child.link}
                                        className='mb-2'
                                        onChange={(e) => handleChildPermissionLinkChange(permIndex, childIndex, e)}
                                    />

                                    <div className='d-flex justify-content-between align-items-center mb-2'>
                                        <Select
                                            isMulti
                                            options={permissionOptions}
                                            value={child.permissionTypes.map(type => ({
                                                value: type,
                                                label: permissionOptions.find(option => option.value === type)?.label,
                                                permission: permission
                                            }))} // Map to include labels
                                            onChange={(selectedOptions) => handlePermissionTypesChange(permIndex, childIndex, selectedOptions)}
                                            className={`mb-2 ${permission.permissions.length > 1 ? 'col-lg-10' : 'col-lg-12'}`}
                                            placeholder="Select Permission Types"
                                            style={{ marginRight: '10px' }}
                                        />
                                        {permission.permissions.length > 1 && (
                                            <Button
                                                variant="danger"
                                                className="ml-2"
                                                onClick={() => handleRemoveChildPermission(permIndex, childIndex)}
                                                style={{ marginTop: '-8px' }} // Adjusted vertical alignment
                                            >
                                                <i className="fa fa-close"></i>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            ))}

                            <Button variant="success" className="mt-2" onClick={() => handleAddChildPermission(permIndex)}>
                                + Add Menu Item
                            </Button>
                        </div>
                    ))}

                    <Button variant="success" className="mt-2" onClick={handleAddPermissionSubName}>
                        + Add SubMenu
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { resetForm(); handleClose(); }}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Add Permissions
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddMenuModal;

